/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useFileUploadContext } from "./fileUploadContext";
import { useAlertContext } from "../alert/alertContext";
import FileUploadInput from "./fileUploadInput";

function FileUploadComponent({
  imgUrl,
  handleFilePath,
}: {
  handleFilePath: (filePath: string) => void;
  imgUrl?: string | null;
}) {
  const { setAlertProps } = useAlertContext();

  const {
    handleOpenFileSelect,
    file,
    uploadImage,
    setFile,
    filePath,
    setFilePath,
    hiddenFileInput,
    handleChange,
  } = useFileUploadContext();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!file) {
      setPreviewUrl(undefined);
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);

    const maxFileSize = 10000000;

    if (file && file.size > maxFileSize) {
      setPreviewUrl(undefined);
      setFile(null);
      setFilePath("");
      setAlertProps({
        color: "red",
        message: "File size must be less than 10 MB",
      });
      setPreviewUrl(undefined);
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    }

    return () => {
      // Set the file to null when the component unmounts
      setFile(null);
      setFilePath("");
    };
  }, [file]);

  useEffect(() => {
    if (filePath) {
      handleFilePath(filePath);
      setFilePath("");
    }
  }, [filePath]);

  return (
    <div className="flex flex-col items-center justify-end h-full gap-5">
      <FileUploadInput
        handleChange={handleChange}
        hiddenFileInput={hiddenFileInput}
      />
      {previewUrl && (
        <img height={100} width={100} src={previewUrl || ""} alt="upload" />
      )}
      {!previewUrl && imgUrl && (
        <img height={100} width={100} src={imgUrl || ""} alt="hero" />
      )}
      {!previewUrl && (
        <Button
          onClick={handleOpenFileSelect}
          color={previewUrl ? "green" : "deep-purple"}
        >
          {imgUrl ? "Change image" : "Upload image"}
        </Button>
      )}
      {previewUrl && (
        <div className="flex flex-row gap-1 mt-2">
          <Button
            className="w-24"
            size="sm"
            onClick={() => {
              if (file) {
                uploadImage({
                  file,
                  additionalData: {
                    location: "campaign-image",
                  },
                });
              }
            }}
            disabled={!file}
            color="green"
          >
            Save
          </Button>
          <Button
            className="w-24"
            size="sm"
            variant="outlined"
            onClick={() => {
              setPreviewUrl(undefined);
              setFile(null);
            }}
            color="gray"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

export default FileUploadComponent;
