import { Card, CardBody, Typography } from "@material-tailwind/react";
import { BookOpen } from "../icons/icons";

function SmsContentCard() {
  return (
    <Card className="w-1/2">
      <CardBody>
        <BookOpen className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Content
        </Typography>
        <Typography>
          Create sms and email content to send when the AI response is not
          needed or for the first outreach. They are used as the first message
          sent to a lead who signs up for a campaign or as follow up messages to
          nudge leads to respond.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default SmsContentCard;
