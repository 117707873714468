import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  CampaignJourneyRow,
  CampaignJourneyUpdate,
} from "./campaignJourney.type";

export function getCampaignJourneys(
  companyId: string
): Promise<CampaignJourneyRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignJourney +
    "/" +
    companyId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CampaignJourneyRow[]>(url, request);
}

export function updateCampaignJourney({
  campaign_id,
  x_position,
  y_position,
}: CampaignJourneyUpdate) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignJourney +
    "/" +
    campaign_id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify({ x_position, y_position }),
  });
  return fetchData<CampaignJourneyRow>(url, request);
}

export function createCampaignJourney({
  campaign_id,
  company_id,
  x_position,
  y_position,
}: {
  campaign_id: string;
  company_id: string;
  x_position: number;
  y_position: number;
}): Promise<CampaignJourneyRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaignJourney;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ campaign_id, company_id, x_position, y_position }),
  });
  return fetchData<CampaignJourneyRow>(url, request);
}
