import { TabConfig } from "../tab/plentiTab";
import FlowCreatedCards from "./flowCreatedCards";
import FlowPrebuiltCards from "./flowPrebuiltCards";

function FlowCardsContainer({ activeTab }: { activeTab: TabConfig }) {
  return (
    <div>
      {activeTab.value === "prebuilt" ? (
        <FlowPrebuiltCards />
      ) : (
        <FlowCreatedCards />
      )}
    </div>
  );
}

export default FlowCardsContainer;
