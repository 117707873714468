/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAlertContext } from "../alert/alertContext";
import {
  CreateNewReimbursement,
  CreateOcrMutation,
  UseDeleteOcrMutation,
  UseGetReimbursementByIdMutation,
  UseUpdateOcrMutation,
  UseUpdateReimbursementMutation,
} from "../reimbursement/reimbursementContext.types";

import { createOcrWithValues, updateOcrById } from "../../api/receiptOcr.api";
import { ReceiptOcrRow } from "../../api/receiptOcr.type";
import {
  createReimbursement,
  deleteReimbursement,
  findPotentialDuplicates,
  getReimbursementById,
  getReimbursementByOcrId,
  getReimbursements,
  updateReimbursements,
} from "../../api/reimbursement.api";
import { ReimbursementRow } from "../../api/reimbursement.type";

export const ReimbursementContextWrapper = (props: any) => {
  const session = useSession();
  const queryClient = useQueryClient();
  const { setAlertProps } = useAlertContext();
  const navigate = useNavigate();

  const [selectedReimbursement, setSelectedReimbursement] =
    useState<Partial<ReimbursementRow>>();

  const [reimbursementOcr, setReimbursementOcr] = useState<ReceiptOcrRow>();

  const [reimbursementErrorMessage, setErrorMessage] = useState<string>("");

  const [dateTimeTotalCheck, setDateTimeTotalCheck] = useState<boolean>(false);
  const [locationAndDayCheck, setLocationAndDayCheck] =
    useState<boolean>(false);

  const [
    potentialDuplicateReimbursements,
    setPotentialDuplicateReimbursements,
  ] = useState<ReimbursementRow[]>([]);

  const { data: reimbursements } = useQuery({
    queryKey: "reimbursement",
    queryFn: () => getReimbursements(),
    enabled: !!session,
  });

  const [activeReimbursement, setActiveReimbursement] =
    useState<ReimbursementRow>();

  const useFindPotentialDuplicateReimbursements = useMutation(
    findPotentialDuplicates,
    {
      onSuccess: async (res) => {
        setLocationAndDayCheck(true);
        setPotentialDuplicateReimbursements((prevState) =>
          prevState?.concat(res)
        );
      },
    }
  );

  const { mutate: createNewReimbursement } = useMutation(createReimbursement, {
    onSuccess: async (res) => {
      useGetReimbursementById.mutate({ id: res.id });
      queryClient.invalidateQueries("reimbursement");
      setAlertProps({
        message: `Reimbursement created`,
        color: "green",
      });
      // window.open(`/reimbursement/${res.id}`, "_blank");
    },
    onError(error: Error) {
      setErrorMessage(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: updateReimbursement } = useMutation(updateReimbursements, {
    onSuccess: async (res) => {
      useGetReimbursementById.mutate({ id: res.id });
      queryClient.invalidateQueries("reimbursement");
      setAlertProps({
        message: `Reimbursement updated`,
        color: "green",
      });
    },
    onError(error: Error) {
      setErrorMessage(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const useDeleteReimbursementById = useMutation(deleteReimbursement, {
    onSuccess: async (res) => {
      setAlertProps({
        message: `Reimbursement deleted`,
        color: "green",
      });
      navigate("/reimbursement");
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const useGetReimbursementById = useMutation(getReimbursementById, {
    onSuccess: async (res) => {
      setActiveReimbursement(res);

      if (res.location_id) {
        useFindPotentialDuplicateReimbursements.mutate(res.id);
      }
    },
  });

  const useUpdateOcrById = useMutation(updateOcrById, {
    onSuccess: async (res) => {
      useGetReimbursementById.mutate({ id: activeReimbursement?.id || "" });
      queryClient.invalidateQueries("reimbursement");
      setAlertProps({
        message: `Ocr updated`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: createOcr } = useMutation(createOcrWithValues, {
    onSuccess: async (res) => {
      useGetReimbursementById.mutate({ id: activeReimbursement?.id || "" });
      queryClient.invalidateQueries("reimbursement");
      setAlertProps({
        message: `Ocr updated`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const useGetReimbursementByOcrId = useMutation(getReimbursementByOcrId, {
    onSuccess: async (res) => {
      setDateTimeTotalCheck(true);
      setPotentialDuplicateReimbursements((prevState) =>
        prevState?.concat(res)
      );
    },
  });

  useEffect(() => {
    // If potential duplicate is listed than get that reimbursement to compare
    if (
      activeReimbursement &&
      activeReimbursement.receipt_ocr_reimbursement &&
      activeReimbursement.receipt_ocr_reimbursement[0] &&
      activeReimbursement.receipt_ocr_reimbursement[0].receipt_ocr
    ) {
      const { potential_duplicate_id } =
        activeReimbursement.receipt_ocr_reimbursement[0].receipt_ocr;
      if (potential_duplicate_id) {
        useGetReimbursementByOcrId.mutate(potential_duplicate_id);
      }

      const ocr =
        (activeReimbursement.receipt_ocr_reimbursement &&
          activeReimbursement.receipt_ocr_reimbursement[0] &&
          activeReimbursement.receipt_ocr_reimbursement[0].receipt_ocr) ||
        activeReimbursement.receipt_ocr;
      if (ocr) {
        setReimbursementOcr(ocr);
      }
    }
  }, [activeReimbursement]);

  const value = {
    reimbursements,
    updateReimbursement,
    setSelectedReimbursement,
    reimbursementOcr,
    selectedReimbursement,
    activeReimbursement,
    useGetReimbursementById,
    reimbursementErrorMessage,
    potentialDuplicateReimbursements,
    useUpdateOcrById,
    dateTimeTotalCheck,
    locationAndDayCheck,
    useDeleteReimbursementById,
    setActiveReimbursement,
    createNewReimbursement,
    createOcr,
  };

  return (
    <ReimbursementContext.Provider value={value}>
      {props.children}
    </ReimbursementContext.Provider>
  );
};

export const ReimbursementContext = createContext({
  reimbursements: [] as ReimbursementRow[] | undefined,
  updateReimbursement: {} as UseUpdateReimbursementMutation,
  setSelectedReimbursement: (
    reimbursement: Partial<ReimbursementRow> | undefined
  ) => {},
  reimbursementOcr: {} as ReceiptOcrRow | undefined,
  selectedReimbursement: {} as Partial<ReimbursementRow> | undefined,
  activeReimbursement: {} as ReimbursementRow | undefined,
  useGetReimbursementById: {} as UseGetReimbursementByIdMutation,
  reimbursementErrorMessage: "" as string,
  potentialDuplicateReimbursements: [] as ReimbursementRow[] | undefined,
  useUpdateOcrById: {} as UseUpdateOcrMutation,
  dateTimeTotalCheck: false as boolean,
  locationAndDayCheck: false as boolean,
  useDeleteReimbursementById: {} as UseDeleteOcrMutation,
  setActiveReimbursement: (reimbursement: ReimbursementRow | undefined) => {},
  createNewReimbursement: {} as CreateNewReimbursement,
  createOcr: {} as CreateOcrMutation,
});

export const useReimbursementContext = () => useContext(ReimbursementContext);
