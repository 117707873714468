import { useQuery } from "react-query";
import { getCampaignJourneys } from "../../../api/campaignJourney.api";

export const useLoadCampaignJourneys = ({
  companyId,
}: {
  companyId: string;
}) => {
  const { data: campaignJourneys, isLoading } = useQuery({
    queryKey: "campaignJourneys",
    queryFn: () => getCampaignJourneys(companyId),
    enabled: !!companyId,
  });

  return { campaignJourneys, isLoading };
};
