/* eslint-disable react/jsx-pascal-case */
import { SetStateAction, useState } from "react";
import PlentiTabs, { TabConfig } from "../tab/plentiTab";
import SegmentsHeader from "./SegmentsHeader";
import CreateSegment from "./CreateSegment";
import SegmentList from "./SegmentList";
import {
  SegmentContextWrapper,
  useSegmentContext,
} from "./hooks/SegmentContext";

const tabs: TabConfig[] = [
  {
    label: "Create segments",
    value: "create",
  },
  {
    label: "Your segments",
    value: "your",
  },
];

function _SegmentsContainer() {
  const { setSegmentResponse, setActiveSegment } = useSegmentContext();

  const [activeTab, setActiveTab] = useState<TabConfig>(tabs[0]);

  function handleSetActiveTab(tab: SetStateAction<TabConfig>) {
    setSegmentResponse(undefined);
    setActiveSegment(undefined);
    setActiveTab(tab);
  }

  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <SegmentsHeader activeTab={activeTab} setActiveTab={handleSetActiveTab} />
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
      />
      {activeTab.value === "create" && (
        <CreateSegment setActiveTab={handleSetActiveTab} />
      )}
      {activeTab.value === "your" && <SegmentList />}
    </div>
  );
}

const SegmentsContainer = () => {
  return (
    <SegmentContextWrapper>
      <_SegmentsContainer />
    </SegmentContextWrapper>
  );
};

export default SegmentsContainer;
