import { Bars3Icon } from "@heroicons/react/24/solid";
import { useState } from "react";
import useOutsideMouseMove from "../hooks/useOutsideMouseMove";
import FabrkConfigMenu from "./FabrkConfigMenu";
import FabrkMenu from "./FabrkMenu";

function FabrkMenuContainer() {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState({
    type: "",
    name: "",
  });

  const handleSelect = (type: string, name: string) => {
    setIsOpen(true);
    setSelectedMenu({ type, name });
  };

  useOutsideMouseMove(setSelectedMenu);

  return (
    <div className="h-full fixed top-0 left-0 z-50">
      <button
        className="lg:hidden p-4 text-white absolute top-4 left-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bars3Icon className="h-6 w-6" />
      </button>
      <FabrkMenu
        onSelect={handleSelect}
        isOpen={isOpen}
        toggleMenu={() => {
          setIsOpen(!isOpen);
        }}
        selectedMenu={selectedMenu}
      />
      {selectedMenu?.type && isOpen && (
        <FabrkConfigMenu
          type={selectedMenu.type}
          selected={selectedMenu.name}
        />
      )}
    </div>
  );
}

export default FabrkMenuContainer;
