import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  CampaignConversationMetrics,
  RecentConversationMessages,
} from "./conversation.type";

export function getCampaignConversationMetrics(): Promise<
  CampaignConversationMetrics[]
> {
  let url = process.env.REACT_APP_API_URL + ApiEndpoints.conversation;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CampaignConversationMetrics[]>(url, request);
}

export function getRecentConversations(page: number = 1) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.conversation +
    "/recent/" +
    page;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<{
    count: number;
    conversations: RecentConversationMessages[];
  }>(url, request);
}
