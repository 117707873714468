import { useQuery } from "react-query";
import { getCampaignConversationMetrics } from "../../../api/conversation.api";

export const useLoadConversationMetrics = () => {
  const { data: conversationMetrics, isLoading } = useQuery({
    queryKey: "conversationMetrics",
    queryFn: () => getCampaignConversationMetrics(),
  });

  return { conversationMetrics, isLoading };
};
