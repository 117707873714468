import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { UserSmsReadRow } from "./userSmsRead.type";

export function getUserSmsReads(): Promise<UserSmsReadRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.userSmsRead;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<UserSmsReadRow[]>(url, request);
}

export function createUserSmsRead({
  smsMessageIds,
  leadId,
}: {
  smsMessageIds: string[];
  leadId: string;
}): Promise<UserSmsReadRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.userSmsRead;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ smsMessageIds, leadId }),
  });

  return fetchData<UserSmsReadRow>(url, request);
}
