import React from "react";
// Import your loading GIF here

const PlentiLoadingIndicator = ({ className }: { className?: string }) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src="/loading-in-and-out.gif" alt="Loading..." />
    </div>
  );
};

export default PlentiLoadingIndicator;
