import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  LeadInsert,
  LeadRow,
  LeadWithRelations,
  LeadsWithPageAndCount,
} from "../api/lead.type";
import { LeadCampaignsWithPageAndCount } from "./leadCampaign.type";
import { BulkConfirmationRequest } from "./location.type";

export function getLeads({
  page,
  campaignId,
}: {
  page: number;
  campaignId?: string;
}): Promise<LeadsWithPageAndCount> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.lead +
    `?page=${page}` +
    (campaignId ? `&campaignId=${campaignId}` : "");

  const request = setHeaderOptions({ method: "GET" });

  // @ts-ignore
  return fetch(url, request)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((res: { data: LeadCampaignsWithPageAndCount }) => {
      const { data } = res;
      const leads = data?.data?.map((leadCampaign) => {
        return {
          ...leadCampaign.lead,
          user_message_count: leadCampaign.user_message_count,
          assistant_message_count: leadCampaign.assistant_message_count,
          campaign: leadCampaign.campaign,
        };
      });
      return {
        ...data,
        data: leads,
      };
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export function getLinkedLeads(): Promise<LeadRow[]> {
  const url = process.env.REACT_APP_API_URL + "/lead/linked";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<LeadRow[]>(url, request);
}

export function createTestLead(lead: LeadInsert | null): Promise<LeadRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/create-test";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(lead),
  });

  return fetchData<LeadRow>(url, request);
}

export function createWebhookTest({
  campaignId,
  phone,
}: {
  campaignId: string;
  phone: string;
}): Promise<LeadRow> {
  const url = process.env.REACT_APP_API_URL + `/lead/klaviyo/${campaignId}`;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ phone, is_test: true }),
  };

  return fetchData<LeadRow>(url, request);
}

export function createLead(lead: LeadInsert): Promise<LeadRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(lead),
  };

  return fetchData<LeadRow>(url, request);
}

export function updateLead(lead: Partial<LeadRow>): Promise<LeadWithRelations> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/" + lead.id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(lead),
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function getLeadById(id: string): Promise<LeadWithRelations> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/" + id;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadWithRelations>(url, request);
}

export function getTestLeadsByCampaign(campaignId: string): Promise<LeadRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/test/" + campaignId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadRow[]>(url, request);
}

export function searchLeads(param: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.lead +
    "/search/" +
    encodeURIComponent(param);

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadsWithPageAndCount>(url, request);
}

export function addBulkLeads(data: BulkConfirmationRequest) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/bulk";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(data),
  });

  return fetchData<LeadRow[]>(url, request);
}
