import { Typography } from "@material-tailwind/react";
import { DnsValidationResponse } from "../../api/emailDns.type";
import PlentiFormLabel from "../forms/PlentiFormLabel";

function EmailDnsValidationResponse({
  validationResponse,
}: {
  validationResponse?: DnsValidationResponse | null;
}) {
  if (!validationResponse) {
    return null;
  }
  return (
    <div>
      <PlentiFormLabel>
        {validationResponse.valid === false ? "Invalid" : "Valid"}
      </PlentiFormLabel>
      <Typography variant="small" color="red" className="font-normal">
        {validationResponse.reason}
      </Typography>
    </div>
  );
}

export default EmailDnsValidationResponse;
