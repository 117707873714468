import { createContext, useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  addProduct,
  deleteProductById,
  getProducts,
  updateProductById,
} from "../../api/product.api";
import { ProductRow } from "../../api/product.type";
import { useAlertContext } from "../alert/alertContext";
import {
  CreateProduct,
  DeleteProduct,
  UpdateProduct,
} from "./ProductContext.type";

export const ProductContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const queryClient = useQueryClient();

  const [productToDelete, setProductToDelete] = useState<Partial<ProductRow>>(
    {}
  );

  const { data: products } = useQuery({
    queryKey: "products",
    queryFn: () => getProducts(),
  });

  const { mutate: deleteProduct } = useMutation(deleteProductById, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      setAlertProps({
        message: "Product deleted",
        color: "green",
      });
    },
    onError: (error: Error) => {
      setAlertProps({
        message: "Error deleting product: " + error.message,
        color: "red",
      });
    },
  });

  const { mutate: updateProduct } = useMutation(updateProductById, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      setAlertProps({
        message: "Product updated",
        color: "green",
      });
    },
    onError: (error: Error) => {
      setAlertProps({
        message: "Error updating product: " + error.message,
        color: "red",
      });
    },
  });

  const { mutate: createProduct } = useMutation(addProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      setAlertProps({
        message: "Product created",
        color: "green",
      });
    },
    onError: (error: Error) => {
      setAlertProps({
        message: "Error creating product: " + error.message,
        color: "red",
      });
    },
  });

  const value = {
    products,
    setProductToDelete,
    productToDelete,
    deleteProduct,
    updateProduct,
    createProduct,
  };

  return (
    <ProductContext.Provider value={value}>
      {props.children}
    </ProductContext.Provider>
  );
};

export const ProductContext = createContext({
  products: [] as ProductRow[] | undefined,
  setProductToDelete: (product: Partial<ProductRow>) => {},
  productToDelete: {} as Partial<ProductRow>,
  deleteProduct: {} as DeleteProduct,
  updateProduct: {} as UpdateProduct,
  createProduct: {} as CreateProduct,
});

export const useProductContext = () => useContext(ProductContext);
