/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "react-query";
import { getDailyLeadCountByCampaign } from "../../../api/leadCampaign.api";

export const useLoadLeadCountsByCampaign = (param: Param) => {
  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ["leadCountsByCampaign", param?.campaignId],
    queryFn: () => getDailyLeadCountByCampaign(param?.campaignId),
    select: (res) => {
      let total = 0;
      for (const item of res) {
        total += item.lead_campaign_count;
      }

      return {
        total,
        data: res,
      };
    },
    enabled: param?.campaignId ? true : false,
  });

  return {
    isLoading,
    data,
    error,
    isFetching,
  };
};

interface Param {
  campaignId: string;
}
