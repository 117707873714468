import { CreateMergeTag } from "./mergeTag.type";

export type AiAgentRow = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  function_name: string;
  type: string;
  slug: string;
};

export type AiAgentCreate = {
  name: string;
  description?: string;
  function_name?: string;
  type: string;
  files?: string[];
  company_id?: string;
  mergeTags?: CreateMergeTag[];
};

export enum AgentTypes {
  apiCall = "apiCall",
  custom = "custom",
  freeText = "freeText",
  fileSearch = "fileSearch",
  dataAnalysis = "dataAnalysis",
}

export type ApiCallAgentHeader = {
  parameter: string;
  value: string;
};
