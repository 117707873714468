import { Typography } from "@material-tailwind/react";
import { PaymentRow } from "../../api/payment.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";

function PaymentListItem({
  payment,
  classes,
  index,
}: {
  payment: PaymentRow;
  classes: string;
  index: number;
}) {
  if (!payment) {
    return null;
  }
  const { amount, created_at, last4, payment_method_type, status } = payment;
  return (
    <tr key={index}>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          {created_at
            ? formatTimeStampToHumanReadableShortDateTime(created_at)
            : "N/A"}
        </Typography>
      </td>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          {payment_method_type
            ? snakeCaseToTitleCase(payment_method_type)
            : "N/A"}
        </Typography>
      </td>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          **** **** **** {last4 || "N/A"}
        </Typography>
      </td>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          {amount ? parseCentsToDollars(amount) : "N/A"}
        </Typography>
      </td>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          {status ? snakeCaseToTitleCase(status) : "N/A"}
        </Typography>
      </td>
    </tr>
  );
}

export default PaymentListItem;
