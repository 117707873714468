import { ApiEndpoints } from "../api/apiEndpoints";
import { ProductInsert, ProductRow } from "../api/product.type";
import { fetchData, setHeaderOptions } from "./apiHelpers";

export function getProductByCompany(companyId: string): Promise<ProductRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.product +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };
  return fetchData<ProductRow[]>(url, request);
}

export function getProducts(): Promise<ProductRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.product;

  const request = setHeaderOptions({ method: "GET" });
  return fetchData<ProductRow[]>(url, request);
}

export function deleteProductById(id: string): Promise<void> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.product + "/" + id;

  const request = setHeaderOptions({ method: "DELETE" });
  return fetchData<void>(url, request);
}

export function addProduct(product: ProductInsert): Promise<ProductRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.product;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(product),
  });
  return fetchData<ProductRow>(url, request);
}

export function updateProductById(
  product: Partial<ProductRow>
): Promise<ProductRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.product + "/" + product.id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(product),
  });
  return fetchData<ProductRow>(url, request);
}
