import { LeadContextWrapper } from "../components/lead/leadContext";
import ViewLeadContainer from "../components/lead/viewLead";
import { useParams } from "react-router-dom";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewLead = () => {
  mixpanelTrackEvent("View lead page");

  const params = useParams();

  return (
    <LeadContextWrapper>
      <ViewLeadContainer id={params.id} />
    </LeadContextWrapper>
  );
};

export default ViewLead;
