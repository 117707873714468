import { Typography } from "@material-tailwind/react";
import { PromptRow } from "../../api/prompt.type";

function CampaignPromptDisplay({ prompt }: { prompt: PromptRow }) {
  return (
    <div className="p-6 bg-white shadow-md rounded-lg mb-10">
      <Typography variant="lead" color="gray">
        Campaign Prompt
      </Typography>
      <Typography variant="small" color="gray" className="mb-2">
        This is how the prompt is presented to the AI
      </Typography>
      <div className="flex flex-col gap-2">
        <p>You are talking to the user regarding a promotion.</p>
        {prompt.products && (
          <p>
            The promotion is for <strong>{prompt.products}</strong>.
          </p>
        )}
        {prompt.retailers && (
          <p>
            It available to redeem at{" "}
            <strong>{prompt.retailers || "No retailers listed"}</strong>, so you
            should only show them these retailer locations.
          </p>
        )}
        {prompt.discount_code && (
          <p>
            If there is no location then <strong>{prompt.discount_code}</strong>
          </p>
        )}
        {prompt.promotion_details && (
          <p>
            Here are the promotion details:{" "}
            <strong>{prompt.promotion_details}</strong>
          </p>
        )}
        {prompt.redemption_amount && (
          <p>
            The amount of times the user can redeem this promotion is:{" "}
            <strong>{prompt.redemption_amount}</strong>
          </p>
        )}
        {prompt.promotion_end_date && (
          <p>
            The promotion ends in: <strong>{prompt.promotion_end_date}</strong>
          </p>
        )}
        {(prompt.promotion_exceptions || prompt.name) && (
          <p>
            Here are some exceptions to the promotion:{" "}
            <strong>
              {prompt.promotion_exceptions} {prompt.not_qualify}
            </strong>
          </p>
        )}
        <p>
          To process payment for this reimbursement you need to know the
          required info:
        </p>
        <ol className="list-decimal pl-6">
          <li>
            The users first name, last name and email address. If they request
            to be paid through Paypal you can ask for their payment handle
          </li>
          {(prompt.redeem_requirements ||
            prompt.redeem_requirements_details) && (
            <li>
              You need these required redemption info:{" "}
              <strong>
                {prompt.redeem_requirements}{" "}
                {prompt.redeem_requirements_details}
              </strong>
            </li>
          )}
          <li>
            Once these steps are complete you can tell the user we will process
            the reimbursement.
          </li>
        </ol>
        <p>
          It's important that you do not tell the user that you can process the
          reimbursement until you receive the required information.
        </p>
        <p>{prompt.other}</p>
        <p>
          <strong>{prompt.feedback_question}</strong>
        </p>
      </div>
    </div>
  );
}

export default CampaignPromptDisplay;
