import { Button } from "@material-tailwind/react";
import { SegmentRunResponse } from "../../api/segments.type";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import SegmentResponseTable from "./SegmentResponseTable";

function SegmentResponse({
  segmentResponse,
  resetSegmentFilter,
  setActiveSegment,
  removeSegment,
  loading,
}: {
  segmentResponse: SegmentRunResponse;
  resetSegmentFilter: () => void;
  setActiveSegment: (segment: any) => void;
  removeSegment: (segmentId: string) => void;
  loading: boolean;
}) {
  return (
    <div className="w-full">
      {loading && <PlentiLogoLoading className="h-24 w-24 mx-auto" />}
      {!loading && (
        <div className="mt-6 mx-auto w-56 gap-2 flex flex-row justify-around">
          <Button
            variant="filled"
            color="cyan"
            onClick={() => {
              resetSegmentFilter();
              setActiveSegment(undefined);
            }}
          >
            Show All
          </Button>
          {segmentResponse.segment && (
            <Button
              variant="filled"
              color="red"
              onClick={() => {
                if (segmentResponse?.segment?.id) {
                  removeSegment(segmentResponse?.segment?.id);
                }
              }}
            >
              Delete
            </Button>
          )}
        </div>
      )}
      <SegmentResponseTable
        segmentResponse={segmentResponse}
        loading={loading}
      />
    </div>
  );
}

export default SegmentResponse;
