import { Card, CardBody, Typography } from "@material-tailwind/react";
import { MapIcon } from "../icons/icons";

function LocationBulkCard() {
  return (
    <Card className="mt-6 w-2/3">
      <CardBody>
        <MapIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Bulk Upload
        </Typography>
        <Typography>
          You can upload a cvs file with a list of locations. Please make sure
          the csv has the following required columns: Name, address, city,
          state, zip. Phone number and chain are optional. Bulk upload uses AI
          to assist in the upload.
        </Typography>
      </CardBody>
    </Card>
  );
}

export default LocationBulkCard;
