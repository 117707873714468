import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { handlePageNav } from "../table/handlePagination";
import TableWithSearch from "../table/tableWithSearch";

import { CampaignConversationMetrics } from "../../api/conversation.type";
import PlentiLoadingIndicator from "../loadingIndicator/PlentiLogoLoading";
import CampaignMetricsTableList from "./CampaignMetricsTableList";
import { useLoadConversationMetrics } from "./hooks/useLoadConversationMetrics";

const TABLE_HEAD = [
  { label: "Name" },
  { label: "Created at" },
  { label: "Total Leads" },
  {
    label: "Total Reimbursement Amount",
  },
  {
    label: "Reimbursement Rate",
  },
  {
    label: "Time to First Response",
  },
  {
    label: "Average Response Time",
  },
  {
    label: "Response Rate",
  },
];

function CampaignMetricsTable() {
  const navigate = useNavigate();
  const { conversationMetrics, isLoading } = useLoadConversationMetrics();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  function handleCampaignClick(campaignId: string) {
    navigate(`/campaign/${campaignId}`);
  }
  function baseFilter() {
    return (campaign: CampaignConversationMetrics, index: number) => index < 10;
  }

  const [campaignFilterFunction, setCampaignFilterFunction] =
    useState<(campaign: CampaignConversationMetrics, index: number) => boolean>(
      baseFilter
    );
  return (
    <Fragment>
      {isLoading ? (
        <PlentiLoadingIndicator className="h-24 w-24 mx-auto" />
      ) : (
        <div className="max-w-full gap-4">
          {conversationMetrics && conversationMetrics.length > 0 && (
            <TableWithSearch
              tableHead={TABLE_HEAD}
              type="campaign metrics"
              rowCount={
                conversationMetrics && conversationMetrics.length > 0
                  ? conversationMetrics.length
                  : null
              }
              rowsPerPage={10}
              currentPage={currentPage}
              handleNextPage={() =>
                handlePageNav({
                  currentPage,
                  pageSetFunction: setCurrentPage,
                  rowsPerPage,
                  setFilterFunction: setCampaignFilterFunction,
                  advancePage: true,
                })
              }
              handlePreviousPage={() =>
                handlePageNav({
                  currentPage,
                  pageSetFunction: setCurrentPage,
                  rowsPerPage,
                  setFilterFunction: setCampaignFilterFunction,
                  advancePage: false,
                })
              }
            >
              <CampaignMetricsTableList
                values={conversationMetrics}
                handleContentClick={handleCampaignClick}
                filterFunction={campaignFilterFunction}
              />
            </TableWithSearch>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default CampaignMetricsTable;
