import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";

function SkeletonTableList({ columnCount = 5 }: { columnCount: number }) {
  const items = Array.from({ length: 10 }, (_, i) => i);
  const columns = Array.from({ length: columnCount }, (_, i) => i);
  return (
    <Fragment>
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
        return (
          <tr key={index} className="animate-pulse even:bg-blue-gray-50/50">
            {columns.map((column, index) => {
              return (
                <td className={classes} key={index}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {""}
                      </Typography>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </Fragment>
  );
}

export default SkeletonTableList;
