import { Typography } from "@material-tailwind/react";
import CheezyPoofs from "../cheezyPoofs/cheezyPoofs";
import LoginLayout from "../layout/LoginLayout";
import { useUserContext } from "../user/userContext";
import { PreUserContextWrapper } from "./PreUserContext";

const RegisterThankYou = () => {
  const { user } = useUserContext();

  return (
    <PreUserContextWrapper>
      <LoginLayout>
        <div className=" m-10 p-8 rounded-lg shadow-md w-96">
          <Typography variant="h3" className="my-4">
            Thank you {user?.first_name}. Your account is almost ready!
          </Typography>
          <Typography variant="small" color="gray" className="mb-4">
            Please confirm your email address. Check your email for a
            confirmation link.
          </Typography>
        </div>
        <div className="hidden md:block">
          <CheezyPoofs />
        </div>
      </LoginLayout>
    </PreUserContextWrapper>
  );
};

export default RegisterThankYou;
