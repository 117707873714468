import AddButton from "../buttons/addButton";
import { TabConfig } from "../tab/plentiTab";
import SegmentsHeaderCard from "./SegmentsHeaderCard";

function SegmentsHeader({
  activeTab,
  setActiveTab,
}: {
  activeTab: TabConfig;
  setActiveTab: (tab: TabConfig) => void;
}) {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-end">
        <SegmentsHeaderCard />
        <div>
          {activeTab.value === "your" && (
            <AddButton
              iconClass="h-4 w-4"
              onClick={() => {
                setActiveTab({ label: "Create segments", value: "create" });
              }}
              color="green"
              size="lg"
              variant="outlined"
            >
              Add Segment
            </AddButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default SegmentsHeader;
