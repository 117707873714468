import { Input } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { useCompanyContext } from "../company/companyContext";
import { usePromptContext } from "../prompt/promptContext";

import { PromptInsert } from "../../api/prompt.type";
import { emptyCampaignPrompt } from "./promptContext.types";

function AddPromptContainer() {
  const navigate = useNavigate();
  const { selectedCompany } = useCompanyContext();
  const { createPrompt } = usePromptContext();
  const [newPrompt, setNewPrompt] =
    useState<Partial<PromptInsert>>(emptyCampaignPrompt);

  function onChange(e: any, key: string) {
    e.preventDefault();
    setNewPrompt((prevState) => {
      return {
        ...prevState,
        [key]: e.target.value,
      };
    });
  }

  return (
    <div>
      {" "}
      <BreadcrumbsFromConfig
        config={[
          {
            name: "Prompts",
            href: "/prompt",
            active: false,
          },
          {
            name: "Add",
            href: "/prompt/add",
            active: true,
          },
        ]}
      />
      <div className="mt-4 flex w-9/12 flex-col gap-4 rounded-lg bg-gray-100 p-5">
        <div>
          <p className="text-xs text-gray-500">Name</p>
          {/* @ts-ignore */}
          <Input
            variant="static"
            value={newPrompt?.name}
            onChange={(e) => onChange(e, "name")}
            className="focus:border-t-0 focus:ring-0"
          />
        </div>
        <div>
          <p className="text-xs text-gray-500">Text</p>
          <textarea
            className="text-md leading-5.6 ease-soft block w-9/12 flex-grow appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-primary-500 focus:outline-none"
            rows={8}
            value={newPrompt?.text}
            onChange={(e) => onChange(e, "text")}
          />
        </div>
        <div className="max-w-lg">
          <ButtonSecondary
            onClick={() => {
              if (newPrompt) {
                createPrompt({
                  ...newPrompt,
                  company_id: selectedCompany?.id ? selectedCompany.id : "",
                  type: "campaign",
                });
                navigate("/prompt");
              }
            }}
            size="lg"
          >
            Submit
          </ButtonSecondary>
        </div>
      </div>
    </div>
  );
}

export default AddPromptContainer;
