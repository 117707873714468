import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { OpenAi } from "../../icons/openAiSvg";
import { EasterEggIcon, EmbedIcon } from "../../icons/icons";

export const fabrkPresets = [
  {
    text: "What is Fabrk?",
    icon: <QuestionMarkCircleIcon className="h-6 w-6" color="yellow" />,
  },
  {
    text: "Isn't this just a gpt wrapper?",
    icon: <OpenAi className="h-6 w-6" fill="blue" />,
  },
  {
    text: "How can I use this on my website?",
    icon: <EmbedIcon className="h-6 w-6" fill="orange" />,
  },
  {
    text: "Where is the easter egg?",
    icon: <EasterEggIcon className="h-6 w-6" fill="pink" />,
  },
];
