import { Button, Typography } from "@material-tailwind/react";

import { usePayoutContext } from "../reimbursement/payoutContext";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import SetAccountType from "../reimbursement/setAccountType";

import { parseCentsToDollars } from "../../utils/currencyManipulation";

function ConfirmPayoutDetails({
  setOpenPopover,
}: {
  setOpenPopover: (value: boolean) => void;
}) {
  const { activeReimbursement } = useReimbursementContext();
  const { useCreatePayout } = usePayoutContext();

  function handleSubmit() {
    if (activeReimbursement?.id) {
      useCreatePayout.mutate(activeReimbursement?.id);
      setOpenPopover(false);
    }
  }

  return (
    <div>
      <Typography variant="h6" color="blue-gray" className="mb-6">
        Confirm Payout Details
      </Typography>

      <div className="mb-4 flex flex-col items-center gap-2">
        <SetAccountType
          updatedPaymentAccountType={
            activeReimbursement?.payment_account_type as string
          }
        />

        <div>
          <p className="text-center text-xs text-gray-500">Amount</p>

          <Typography variant="small" color="blue-gray" className="font-normal">
            {parseCentsToDollars(
              activeReimbursement?.reimbursement_amount as number
            )}
          </Typography>
        </div>

        {activeReimbursement?.payment_account_type &&
        activeReimbursement.payment_account_type ? (
          <Button variant="gradient" onClick={handleSubmit} color="green">
            Submit
          </Button>
        ) : (
          <Typography variant="small" color="blue-gray" className="font-normal">
            Missing info. Please update to submit
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ConfirmPayoutDetails;
