import { useParams } from "react-router-dom";
import { PromptContextWrapper } from "../components/prompt/promptContext";
import ViewPromptContainer from "../components/prompt/ViewPromptContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewPrompt = () => {
  mixpanelTrackEvent("View prompt page");

  const params = useParams();

  return (
    <PromptContextWrapper id={params.id}>
      <ViewPromptContainer id={params.id} />
    </PromptContextWrapper>
  );
};

export default ViewPrompt;
