import AddCompanyContainer from "../components/company/addCompany";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const AddCompany = () => {
  mixpanelTrackEvent("Add company");

  return <AddCompanyContainer />;
};

export default AddCompany;
