import AddAgentTypeContainer from "../components/agents/AddAgentTypeContainer";
import { AiAgentContextWrapper } from "../components/agents/hooks/AiAgentContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const AddAgentType = () => {
  mixpanelTrackEvent("Add agent type");

  return (
    <AiAgentContextWrapper>
      <AddAgentTypeContainer />
    </AiAgentContextWrapper>
  );
};

export default AddAgentType;
