import { useState } from "react";
import PlentiTabs from "../tab/plentiTab";
import AddProductForm from "./AddProductForm";
import ProductCard from "./ProductCard";
import ProductTable from "./ProductTable";

const tabs = [
  {
    label: "Products",
    value: "list",
  },
  {
    label: "Add Product",
    value: "add",
  },
];

function ProductContainer() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <ProductCard />
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab.value === "list" && <ProductTable />}
      {activeTab.value === "add" && <AddProductForm />}
    </div>
  );
}

export default ProductContainer;
