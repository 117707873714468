import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  CampaignDraftType,
  CampaignForPaths,
  CampaignInsert,
  CampaignRow,
  CampaignUpdate,
} from "./campaign.type";
import { toLowerCaseAndReplaceSpacesWithHyphens } from "../utils/stringManipulation";

export function getCampaigns(): Promise<CampaignRow[]> {
  const url = process.env.REACT_APP_API_URL + "/campaign";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CampaignRow[]>(url, request);
}

export function getTemplateCampaigns(): Promise<CampaignRow[]> {
  const url = process.env.REACT_APP_API_URL + "/campaign/templates";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CampaignRow[]>(url, request);
}

export function updateCampaignApiCall(
  updateCampaign: Partial<CampaignUpdate>
): Promise<CampaignRow> {
  const url = process.env.REACT_APP_API_URL + `/campaign/${updateCampaign.id}`;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(updateCampaign),
  });

  return fetchData<CampaignRow>(url, request);
}

export function createCampaign(
  campaign: Partial<CampaignInsert> | null
): Promise<CampaignRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaign + "/create";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(campaign),
  });
  return fetchData<CampaignRow[]>(url, request);
}

export function getCampaignById(id: string): Promise<CampaignRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.campaign + "/dashboard/" + id;

  const request = setHeaderOptions({
    method: "GET",
  });
  return fetchData<CampaignRow>(url, request);
}

export function duplicateCampaign(existingCampaignId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaign +
    "/duplicate/" +
    existingCampaignId;

  const request = setHeaderOptions({
    method: "PUT",
  });

  return fetchData<CampaignRow>(url, request);
}

export function getCampaignsForPaths(): Promise<CampaignForPaths[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaign + "/public";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetch(request.url, request)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((res: { data: CampaignRow[] }) => {
      const { data } = res;

      return data
        .filter((item) => item.company && item.company.slug)
        .map((campaign) => {
          const { name, company } = campaign;
          if (!company || !company.slug) {
            throw new Error("Campaign does not have a company or slug");
          }

          return {
            params: {
              campaign: toLowerCaseAndReplaceSpacesWithHyphens(name),
              company: company.slug,
              companyId: company.id,
              campaignId: campaign.id,
            },
          };
        });
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export function getCampaignByName(name: string): Promise<CampaignRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.campaign + `/name/${name}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CampaignRow>(request.url, request);
}

export function getDefaultCampaign(companyId: string): Promise<CampaignRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaign +
    `/default/${companyId}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CampaignRow>(request.url, request);
}

export function createDraftCampaign({
  type,
  companyId,
}: {
  type: CampaignDraftType;
  companyId?: string;
}): Promise<CampaignRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaign + "/draft";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ type, ...{ companyId } }),
  });

  return fetchData<CampaignRow>(url, request);
}
