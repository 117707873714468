import {
  ArrowDownOnSquareStackIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/24/outline";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { Typography } from "@material-tailwind/react";
import { AgentTypes } from "../../api/aiAgent.type";
import SelectCompany from "../company/selectCompany";
import { UploadMany } from "../icons/icons";
import { useUserContext } from "../user/userContext";
import AddAgentCard from "./AddAgentCard";

export const agentTypes = [
  {
    type: AgentTypes.apiCall,
    label: "API Call",
    description:
      "Create an agent that makes an API call to pull data into or out of chat conversations.",
    icon: <ArrowLongUpIcon className="h-6 w-6 " />,
  },

  {
    type: AgentTypes.freeText,
    label: "Free Text",
    description:
      "Create an agent that allows you to input text into chat conversations.",
    icon: <DocumentTextIcon className="h-6 w-6 " />,
  },
  {
    type: AgentTypes.fileSearch,
    label: "File Search",
    description:
      "Create an agent that allows you to upload files that the AI can search and reference in chat conversations.",
    icon: <UploadMany className="h-6 w-6 " />,
  },
  {
    type: AgentTypes.dataAnalysis,
    label: "Data Analysis",
    description:
      "An agent that analyzes data in chat conversations. You can upload spreadsheets and other data sources to analyze.",
    icon: <ArrowDownOnSquareStackIcon className="h-6 w-6 " />,
  },
];

function AddAgentContainer() {
  const { user } = useUserContext();

  return (
    <div className="p-5">
      <div className="flex flex-col gap-5 mt-5">
        <Typography variant="h4" className="mb-4 text-slate-500">
          Prebuilt Agents
        </Typography>
        {user?.type === "admin" && (
          <div className="max-w-md">
            {" "}
            <SelectCompany />
          </div>
        )}
        <div className="max-w-[1000px] grid xl:grid-cols-2 grid-cols-1  justify-items-center">
          {agentTypes?.map((agent, index) => {
            return (
              <div key={index} className="p-2">
                <AddAgentCard
                  title={agent.label}
                  description={agent.description}
                  icon={agent.icon}
                  type={agent.type}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AddAgentContainer;
