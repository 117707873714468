import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Button, Typography } from "@material-tailwind/react";
import { useCustomerContext } from "./customerContex";

function DefaultPaymentItem({ paymentMethodId }: { paymentMethodId: string }) {
  const { customer, useUpdateCustomer } = useCustomerContext();

  function handleUpdateCustomer({
    default_payment_method,
    backup_payment_method,
  }: {
    default_payment_method?: string;
    backup_payment_method?: string;
  }) {
    useUpdateCustomer.mutate({
      ...(default_payment_method && { default_payment_method }),
      ...(backup_payment_method && { backup_payment_method }),
    });
  }

  const isDefault = customer?.default_payment_method === paymentMethodId;
  const isBackup = customer?.backup_payment_method === paymentMethodId;

  if (isDefault) {
    return (
      <Typography
        variant="small"
        color="green"
        className="font-medium bg-green-100 bg-opacity-50 hover:bg-opacity-70 rounded-full px-2 py-1 flex items-center w-fit gap-2"
      >
        <CheckCircleIcon className="h-4 w-4 " /> Primary payment method
      </Typography>
    );
  } else if (isBackup) {
    return (
      <Typography
        variant="small"
        color="blue"
        className="font-medium bg-blue-100 bg-opacity-50 hover:bg-opacity-70 rounded-full px-2 py-1 flex items-center w-fit gap-2"
      >
        <CheckCircleIcon className="h-4 w-4 " /> Backup payment method
      </Typography>
    );
  } else {
    return (
      <div className="flex w-max gap-4">
        <Button
          size="sm"
          color="green"
          onClick={() => {
            handleUpdateCustomer({
              default_payment_method: paymentMethodId,
            });
          }}
        >
          Set default
        </Button>
        <Button
          size="sm"
          color="blue"
          onClick={() => {
            handleUpdateCustomer({
              backup_payment_method: paymentMethodId,
            });
          }}
        >
          Set backup
        </Button>
      </div>
    );
  }
}

export default DefaultPaymentItem;
