/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-tailwind/react";
import { useEffect } from "react";
import { useCompanyContext } from "../company/companyContext";
import SelectCompany from "../company/selectCompany";
import PlentiLoadingIndicator from "../loadingIndicator/PlentiLogoLoading";
import { useFileUploadContext } from "./fileUploadContext";
import FileUploadInput from "./fileUploadInput";

function BulkUploadForm({
  tableName,
  children,
}: {
  tableName: string;
  children: React.ReactNode;
}) {
  const {
    handleOpenFileSelect,
    file,
    setFile,
    hiddenFileInput,
    handleChange,
    uploadCsv,
    csvUploadResponse,
    loading,
  } = useFileUploadContext();

  const { selectedCompany } = useCompanyContext();

  async function handleFile(newFile: File) {
    if (!selectedCompany?.id || !newFile) {
      return;
    }
    uploadCsv({
      file: newFile,
      additionalData: { location: tableName, companyId: selectedCompany.id },
    });

    setFile(null);
  }

  useEffect(() => {
    if (file) {
      handleFile(file);
    }
  }, [file]);

  return (
    <div>
      <div className="flex flex-col gap-10 mt-4">
        <SelectCompany />

        {loading ? (
          <div>
            <PlentiLoadingIndicator className="w-24 h-24 m-auto" />
          </div>
        ) : (
          <div className="m-auto gap-2">
            {!csvUploadResponse && (
              <Button
                color={csvUploadResponse ? "green" : "deep-purple"}
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenFileSelect(e);
                }}
              >
                {csvUploadResponse ? "Upload data" : "Select File"}
              </Button>
            )}
          </div>
        )}

        {csvUploadResponse && <div>{children}</div>}
      </div>
      <FileUploadInput
        handleChange={handleChange}
        hiddenFileInput={hiddenFileInput}
        accept=".csv"
      />
    </div>
  );
}

export default BulkUploadForm;
