import ViewSmsContentById from "../components/smsContent/viewSmsContent";
import { useParams } from "react-router-dom";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewSmsContent = () => {
  mixpanelTrackEvent("View sms content page");

  const params = useParams();
  return <ViewSmsContentById id={params.id as string} />;
};

export default ViewSmsContent;
