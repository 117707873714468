import React from "react";
import { Months } from "../forms/selectMonth";
import { numericMonthDayYearToString } from "../../utils/parseTimeStamp";
import { centsToUsDollars } from "../../utils/currencyManipulation";

function TooltipDay({
  selectedMonth,
  category,
}: {
  selectedMonth: Months | undefined;
  category?: any;
}) {
  return (
    <p className="text-tremor-content">
      {category.dataKey} -{" "}
      {selectedMonth &&
        numericMonthDayYearToString(
          selectedMonth?.month,
          category.payload.day,
          selectedMonth?.year
        )}
    </p>
  );
}

export const TooltipDailyCurrency = ({
  selectedMonth,
  category,
  idx,
}: {
  selectedMonth: Months | undefined;
  category?: any;
  idx?: number;
}) => {
  // You can use category and idx props here
  // For example:
  return (
    <div className="space-y-1" key={idx}>
      <TooltipDay selectedMonth={selectedMonth} category={category} />
      <p className="font-medium text-tremor-content-emphasis">
        {centsToUsDollars(category.value)}
      </p>
    </div>
  );
};

export const TooltipDailyCount = ({
  selectedMonth,
  category,
  idx,
}: {
  selectedMonth: Months | undefined;
  category?: any;
  idx?: number;
}) => {
  return (
    <div className="space-y-1" key={idx}>
      <TooltipDay selectedMonth={selectedMonth} category={category} />
      <p className="font-medium text-tremor-content-emphasis">
        {category.value}
      </p>
    </div>
  );
};

const DailyUsageCustomTooltip = ({
  payload,
  active,
  children,
}: {
  payload: any;
  active: any;
  children: any;
}) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      {payload.map((category: any, idx: number) => (
        <div key={idx} className="flex flex-1 space-x-2.5">
          <div
            className={`w-1 flex flex-col bg-${category.color}-500 rounded`}
          />
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { category, idx })
          )}
        </div>
      ))}
    </div>
  );
};

export default DailyUsageCustomTooltip;
