import { ArrowLongUpIcon, CalculatorIcon } from "@heroicons/react/24/outline";
import { CustomIcon } from "../../icons/customSvg";
import { DollarIcon, MapIcon, UploadMany } from "../../icons/icons";
import { ShopifyBag } from "../../icons/shopifyBag";

export const agents = [
  {
    name: "File Search",
    icon: <UploadMany className="h-6 w-6 " />,
    slug: "file_search",
  },
  {
    name: "Data Analysis",
    icon: <CalculatorIcon className="h-6 w-6 " />,
    slug: "data_analysis",
  },
  {
    name: "API Call",
    icon: <ArrowLongUpIcon className="h-6 w-6 " />,
    slug: "api_call",
  },
  { name: "Location", icon: <MapIcon className="h-6 w-6" /> },

  {
    name: "Shopify",
    icon: <ShopifyBag className="h-6 w-6" />,
    slug: "shopify-agent",
  },

  {
    name: "Money",
    icon: <DollarIcon className="h-6 w-6 " />,
    slug: "reimbursement-agent",
  },

  {
    name: "Custom",
    icon: <CustomIcon className="h-6 w-6" fillColor="white" />,
    slug: "custom-agent",
  },
];
