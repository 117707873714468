/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import {
  getImageMetadataByFilter,
  getImageMetadataById,
} from "../../../api/imageMetadata.api";
import {
  ImageMetadataRow,
  ImageMetadataFilterValues,
  FilteredImagesResponse,
} from "../../../api/imageMetadata.type";
import { useSearchParams } from "react-router-dom";

export const ImageMetadataContextWrapper = (props: any) => {
  let [searchParams] = useSearchParams();

  const type = searchParams.get("type");
  const campaign_id = searchParams.get("campaign_id");
  const lead_id = searchParams.get("lead_id");

  const [selectedType, setSelectedType] = useState<string | null>(type || null);
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(
    campaign_id || null
  );

  const [selectedLead, setSelectedLead] = useState<string | null>(
    lead_id || null
  );

  const {
    mutate: getImage,
    isLoading: isImageLoading,
    data: imageMetadata,
  } = useMutation(getImageMetadataById, {
    onSuccess: async (res) => {},
    onError(error: Error) {
      console.log(error);
    },
  });

  const {
    mutate: getFilteredImages,
    isLoading,
    data: filteredImages,
  } = useMutation(getImageMetadataByFilter, {
    onSuccess: async (res) => {},
    onError(error: Error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (searchParams) {
      if (type || campaign_id || lead_id) {
        getFilteredImages({
          ...(type && {
            type,
          }),
          ...(campaign_id && {
            campaign_id,
          }),
          ...(lead_id && {
            lead_id,
          }),
        });
      } else {
        getFilteredImages({});
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedCampaign || selectedLead || selectedType) {
      getFilteredImages({
        ...(selectedCampaign && {
          campaign_id: selectedCampaign,
        }),
        ...(selectedLead && {
          lead_id: selectedLead,
        }),
        ...(selectedType && {
          type: selectedType,
        }),
      });
    }
  }, [selectedCampaign, selectedLead, selectedType]);

  const value = {
    getFilteredImages,
    isLoading: isImageLoading || isLoading,
    filteredImages,
    selectedCampaign,
    selectedLead,
    selectedType,
    setSelectedCampaign,
    setSelectedLead,
    setSelectedType,
    imageMetadata,
    getImage,
  };

  return (
    <ImageMetadataContext.Provider value={value}>
      {props.children}
    </ImageMetadataContext.Provider>
  );
};

export const ImageMetadataContext = createContext({
  getFilteredImages: {} as UseMutateFunction<
    FilteredImagesResponse,
    Error,
    ImageMetadataFilterValues | undefined,
    unknown
  >,
  isLoading: false,
  filteredImages: {} as FilteredImagesResponse | undefined,
  selectedCampaign: null as string | null,
  selectedLead: null as string | null,
  selectedType: null as string | null,
  setSelectedCampaign: (campaign: string) => {},
  setSelectedLead: (lead: string) => {},
  setSelectedType: (type: string) => {},
  imageMetadata: {} as ImageMetadataRow | undefined,
  getImage: {} as UseMutateFunction<ImageMetadataRow, Error, string, unknown>,
});

export const useImageMetadataContext = () => useContext(ImageMetadataContext);
