import { useQuery } from "react-query";
import { getEmailDns } from "../../../api/emailDns.api";

export const useLoadEmailDns = () => {
  const {
    data: emailDns,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: "emailDns",
    queryFn: () => getEmailDns(),
  });

  return { emailDns, isLoading, refetch };
};
