import { Fragment } from "react";

import { useTryContext } from "../try/tryContext";

function DivCurve({ smallScreen }: { smallScreen?: boolean }) {
  const { companyConfig } = useTryContext();

  return (
    <Fragment>
      {companyConfig && companyConfig?.color_one && (
        <div
          className={
            smallScreen
              ? "relative inset-x-0 bottom-0 h-8 bg-contain bg-bottom bg-no-repeat md:hidden"
              : "relative inset-x-0 bottom-0 hidden h-8 bg-contain bg-bottom bg-no-repeat md:block"
          }
          style={{
            backgroundColor: companyConfig && companyConfig?.color_one,
            backgroundImage:
              "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 10' preserveAspectRatio='none'><path d='M0 10 C25 0 75 0 100 10' fill='%23ffffff'/></svg>\")",
          }}
        />
      )}
    </Fragment>
  );
}

export default DivCurve;
