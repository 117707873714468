/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmailListContainer from "../emailTemplates/EmailListContainer";
import PlentiTabs from "../tab/plentiTab";
import NavToAddButton from "./NavToAddButton";
import SmsContentCard from "./SmsContentCard";
import SmsContentOverview from "./SmsContentOverview";
import SmsContentTable from "./SmsContentTable";

const tabs = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Sms List ",
    value: "sms_list",
  },
  {
    label: "Email List ",
    value: "email_list",
  },
];

function ContentContainer() {
  let [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const { value } = activeTab;

  useEffect(() => {
    if (tab === "email") {
      setActiveTab(tabs[2]);
    }
  }, [tab]);

  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <div className="flex justify-between items-end">
        <SmsContentCard />
        <NavToAddButton />
      </div>
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {value === "overview" && <SmsContentOverview />}
      {value === "sms_list" && <SmsContentTable />}
      {value === "email_list" && <EmailListContainer />}
    </div>
  );
}

export default ContentContainer;
