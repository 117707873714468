import { ArrowDownOnSquareStackIcon } from "@heroicons/react/24/outline";
import { ExtractionSchemaCampaignRow } from "../../../api/extractionSchema.type";
import { Checkbox, Typography } from "@material-tailwind/react";

function DataExtractionMenuItem({
  dataExtraction,
}: {
  dataExtraction?: ExtractionSchemaCampaignRow[] | null;
}) {
  return (
    <div className="grid grid-cols-4  items-center cursor-pointer">
      <ArrowDownOnSquareStackIcon className="h-6 w-6" />
      <Typography className="col-span-2">Data Extraction</Typography>
      <Checkbox
        className="bg-gray-800 p-1"
        labelProps={{ style: { padding: "1px" } }}
        containerProps={{
          className: "p-1",
        }}
        crossOrigin={"true"}
        color="orange"
        checked={dataExtraction && dataExtraction?.length > 0 ? true : false}
      />
    </div>
  );
}

export default DataExtractionMenuItem;
