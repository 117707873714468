import { Typography } from "@material-tailwind/react";
import FabrkChatPresets from "./FabrkChatPresets";
import FabrkInput from "./FabrkInput";

function FabrkInputContainer({ view }: { view: string }) {
  return (
    <main
      className="flex flex-col items-center text-center px-6 min-h-screen"
      style={{ marginTop: "calc(100vh / 4)" }}
    >
      {view === "header" && (
        <div>
          <Typography
            className="text-4xl sm:text-5xl md:text-4xl font-bold tracking-wider text-white"
            variant="h3"
            style={{
              fontFamily: "dm-mono-regular",
            }}
          >
            Custom AI Chatbots
          </Typography>
          <Typography
            className="text-lg mt-4 text-white"
            style={{
              fontFamily: "dm-mono-light",
            }}
          >
            Woven seamlessly into your business
          </Typography>
        </div>
      )}

      <div className="mt-8 w-full max-w-2xl flex flex-col gap-5">
        <FabrkInput view={view} />
        {view === "header" && <FabrkChatPresets />}
      </div>
    </main>
  );
}

export default FabrkInputContainer;
