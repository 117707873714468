function EmailDisplay({
  html,
  logoUrl,
}: {
  html: string | undefined;
  logoUrl: string | undefined;
}) {
  return (
    <div
      style={{
        backgroundColor: "#dcdcdc", // Gray background for the outer wrapper
        padding: "0 10px", // Horizontal padding for the outer wrapper
        height: "100%", // Ensures the outer wrapper fills the viewport
      }}
    >
      <div
        className="max-w-[600px]"
        style={{
          backgroundColor: "#ffffff", // White background for the inner div
          margin: "0 auto", // Centers the inner div within the outer wrapper
          padding: "10px", // Padding inside the inner div
          height: "100%", // Ensures the inner div fills the outer wrapper
        }}
      >
        <table data-type="image" width="100%">
          <tbody>
            <tr>
              <td
                style={{
                  fontSize: "6px",
                  lineHeight: "10px",
                  padding: "0px 0px 0px 0px",
                }}
                valign="top"
                align="center"
              >
                <img
                  className="max-width"
                  style={{
                    display: "block",
                    color: "#000000",
                    textDecoration: "none",
                    fontFamily: "Helvetica, arial, sans-serif",
                    fontSize: "16px",
                  }}
                  width="140"
                  alt=""
                  data-proportionally-constrained="true"
                  data-responsive="false"
                  src={logoUrl || ""}
                  height="40"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%">
          <div dangerouslySetInnerHTML={{ __html: html || "" }}></div>{" "}
        </table>
      </div>
    </div>
  );
}

export default EmailDisplay;
