import { Button } from "@material-tailwind/react";

import { useMutation } from "react-query";
import { rechargeCustomer } from "../../api/customer.api";
import { CustomerRow } from "../../api/customer.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { useAlertContext } from "../alert/alertContext";
import ValueWithLabel from "../forms/valueWithLabel";

function TriggerRechargeForm({
  customer,
  setOpen,
}: {
  customer: CustomerRow;
  setOpen: (value: boolean) => void;
}) {
  const { company, min_balance, max_balance, balance, default_payment_method } =
    customer;

  const { setAlertProps } = useAlertContext();

  const rechargeAmount = max_balance - balance;

  const useRechargeCustomer = useMutation(rechargeCustomer, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Customer recharged",
        color: "green",
      });
      setOpen(false);
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
      setOpen(false);
    },
  });

  return (
    <div className="p-5">
      <div className="mt-4 grid grid-cols-5 max-w-lg gap-4 rounded-lg items-center ">
        <ValueWithLabel
          otherProps={{ className: "col-span-2" }}
          label="Company name"
          value={company?.name as string}
        />
        <ValueWithLabel
          otherProps={{ className: "col-span-3" }}
          label="Default payment method"
          value={default_payment_method as string}
        />
        <ValueWithLabel
          otherProps={{ className: "col-span-2" }}
          label="Current balance"
          value={balance ? parseCentsToDollars(balance) : "N/A"}
        />
        <ValueWithLabel
          otherProps={{ className: "col-span-2" }}
          label="Min balance"
          value={min_balance ? parseCentsToDollars(min_balance) : "N/A"}
        />
        <ValueWithLabel
          label="Max balance"
          value={max_balance ? parseCentsToDollars(max_balance) : "N/A"}
        />

        <ValueWithLabel
          label="Max balance"
          value={max_balance ? parseCentsToDollars(max_balance) : "N/A"}
        />
        <div className="place-self-center">-</div>
        <ValueWithLabel
          label="Balance"
          value={balance ? parseCentsToDollars(balance) : "N/A"}
        />
        <div className="place-self-center">=</div>
        <ValueWithLabel
          label="Recharge amount"
          value={rechargeAmount ? parseCentsToDollars(rechargeAmount) : "N/A"}
        />
      </div>
      <Button
        size="md"
        color="green"
        fullWidth={false}
        type="submit"
        className="mt-4"
        onClick={() => useRechargeCustomer.mutate(customer.id)}
      >
        Recharge {parseCentsToDollars(rechargeAmount)}
      </Button>
    </div>
  );
}

export default TriggerRechargeForm;
