import AdminCompanyView from "../company/adminCompanyView";
import { useCompanyContext } from "../company/companyContext";
import { useUserContext } from "../user/userContext";
import StandardCompanyContainer from "./StandardCompanyContainer";

function CompanyContainer() {
  const { user } = useUserContext();
  const { selectedCompany } = useCompanyContext();

  return (
    <div className="flex flex-col gap-4 p-5">
      {user && user.type === "admin" && <AdminCompanyView />}
      {user && user.type === "standard" && selectedCompany && (
        <StandardCompanyContainer id={selectedCompany.id} />
      )}
    </div>
  );
}

export default CompanyContainer;
