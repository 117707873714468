/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import PlentiTabs from "../tab/plentiTab";
import ConversationListContainer from "./ConversationListContainer";
import ConversationsHeader from "./conversationsHeader";
import LeadTable from "./leadTable";
import { useMessageOverviewContext } from "./messageOverviewContext";

const ConversationsContainer: React.FC = () => {
  const location = useLocation();

  const { unread } = useMessageOverviewContext();

  const tabs = [
    {
      label: "Conversations",
      value: "conversations",
      badgeValue: unread ? unread : 0,
    },
    {
      label: "Leads",
      value: "leads",
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId");

  useEffect(() => {
    if (campaignId) {
      setActiveTab(tabs[1]);
    }
  }, [campaignId]);

  return (
    <div className="p-5 flex flex-col gap-5">
      {location.state && (
        <BreadcrumbsFromConfig
          config={[
            {
              name: "leads",
              href: location.pathname,
              active: true,
            },
          ]}
        />
      )}
      <div className="w-full flex flex-col ">
        <ConversationsHeader />
        <PlentiTabs
          tabConfig={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab.value === "conversations" ? (
          <ConversationListContainer />
        ) : (
          <LeadTable />
        )}
      </div>
    </div>
  );
};

export default ConversationsContainer;
