/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useAlertContext } from "../alert/alertContext";
import { useUserContext } from "../user/userContext";

import {
  createCompany,
  getCompanies,
  getCompanyById,
  getCompanyByUserId,
  getCompanyConfigById,
  signupCreateCompany,
  updateCompany,
  updateCompanyConfigApiCall,
} from "../../api/company.api";
import { CompanyConfigRow, CompanyRow } from "../../api/company.type";
import { CompanyUsersRow } from "../../api/companyUser";
import {
  createCompanyUser,
  getAllCompanyUsers,
} from "../../api/companyUser.api";
import {
  AddCompanyUser,
  CreateNewCompanyMutation,
  GetCompanyByIdMutation,
  GetCompanyConfig,
  GetCompanyUsers,
  SignupCreateCompanyMutation,
  UpdateCompanyConfig,
  UpdateCompanyMutation,
} from "./companyContext.types";

export const CompanyContextWrapper = (props: any) => {
  const queryClient = useQueryClient();

  const { setAlertProps } = useAlertContext();

  const [selectedCompany, setSelectedCompany] = useState<CompanyRow | null>(
    null
  );

  const [companyUsers, setCompanyUsers] = useState<CompanyUsersRow[] | []>([]);

  const [selectedCompanyConfig, setSelectedCompanyConfig] =
    useState<CompanyConfigRow>();

  const { user } = useUserContext();

  const { data: companies } = useQuery({
    queryKey: "companies",
    queryFn: () => getCompanies(),
    enabled: !!user,
  });

  const [companiesByUser, setCompaniesByUser] = useState<CompanyRow[] | []>([]);

  const useGetCompanyConfig = useMutation(getCompanyConfigById, {
    onSuccess: async (res) => {
      setSelectedCompanyConfig(res);
    },
  });

  const { mutate: getCompanyUsers } = useMutation(getAllCompanyUsers, {
    onSuccess: async (res) => {
      setCompanyUsers(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: addCompanyUser } = useMutation(createCompanyUser, {
    onSuccess: async (res) => {
      getCompanyUsers(selectedCompany?.id as string);
      setAlertProps({
        message: `User invited to your company's Plenti account`,
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useGetCompanyById = useMutation(getCompanyById, {
    onSuccess: async (res) => {
      setSelectedCompany(res);
    },
  });

  const useGetCompaniesByUser = useMutation(getCompanyByUserId, {
    onSuccess: async (res) => {
      setCompaniesByUser(res);
    },
  });

  const useCreateNewCompany = useMutation(createCompany, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("companies");
      setAlertProps({
        message: `Company created`,
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: createCompanyFromSignup } = useMutation(signupCreateCompany, {
    onSuccess: async (res) => {},
    onError(error: Error) {
      console.log(error);
    },
  });

  const useUpdateCompany = useMutation(updateCompany, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("companies");
    },
  });
  const { isLoading: isConfigUpdateLoading, mutate: updateCompanyConfig } =
    useMutation(updateCompanyConfigApiCall, {
      onSuccess: async (res) => {
        setSelectedCompanyConfig(res);
        useGetCompanyById.mutate(selectedCompany?.id as string);
        queryClient.invalidateQueries("companies");
        setAlertProps({
          message: `Company updated`,
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    });

  useEffect(() => {
    if (selectedCompany && selectedCompany.id) {
      useGetCompanyConfig.mutate(selectedCompany.id);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!selectedCompany && companiesByUser && companiesByUser.length > 0) {
      setSelectedCompany(companiesByUser[0]);
    }
  }, [companiesByUser]);

  useEffect(() => {
    if (user && user.id && (!companiesByUser || companiesByUser.length === 0)) {
      useGetCompaniesByUser.mutate(user.id);
    }
  }, [user]);

  const value = {
    getCompanyUsers,
    companyUsers,
    companies,
    useCreateNewCompany,
    selectedCompany,
    setSelectedCompany,
    useUpdateCompany,
    selectedCompanyConfig,
    updateCompanyConfig,
    useGetCompanyConfig,
    useGetCompanyById,
    isConfigUpdateLoading,
    createCompanyFromSignup,
    addCompanyUser,
  };

  return (
    <CompanyContext.Provider value={value}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export const CompanyContext = createContext({
  getCompanyUsers: {} as GetCompanyUsers,
  companyUsers: [] as CompanyUsersRow[] | [],
  addCompanyUser: {} as AddCompanyUser,
  companies: [] as CompanyRow[] | undefined,
  useCreateNewCompany: {} as CreateNewCompanyMutation,
  selectedCompany: {} as CompanyRow | null,
  setSelectedCompany: {} as React.Dispatch<
    React.SetStateAction<CompanyRow | null>
  >,
  useUpdateCompany: {} as UpdateCompanyMutation,
  selectedCompanyConfig: {} as CompanyConfigRow | undefined,
  updateCompanyConfig: {} as UpdateCompanyConfig,
  useGetCompanyConfig: {} as GetCompanyConfig,
  useGetCompanyById: {} as GetCompanyByIdMutation,
  isConfigUpdateLoading: false,
  createCompanyFromSignup: {} as SignupCreateCompanyMutation,
});

export const useCompanyContext = () => useContext(CompanyContext);
