import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { FontRow } from "./font.type";

export function getFonts(): Promise<FontRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.font;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<FontRow[]>(url, request);
}
