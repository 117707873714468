/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSuffix,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { CampaignSmsContentRow } from "../../api/campaignSmsContent.type";
import { SmsContentRow } from "../../api/smsContent.type";
import { useCampaignContentContext } from "../campaignSmsContent/campaignSmsContentContext";
import GenericDialog from "../dialog/genericDialog";
import { DeleteIcon } from "../icons/icons";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import UpdateSmsContentContainer from "../smsContent/updateSmsContent";
import SmsContentListItem from "./SmsContentListItem";

function SmsContentList({
  campaignSpecificContent,
}: {
  campaignSpecificContent: CampaignSmsContentRow[] | undefined;
}) {
  const [open, setOpen] = useState(false);

  const [orderUpdated, setOrderUpdated] = useState(false);
  const { useDeleteCampaignSmsContent, generating, updateContent } =
    useCampaignContentContext();

  const [dragList, setDragList] = useState<CampaignSmsContentRow[]>(
    campaignSpecificContent || []
  );

  const [draggedItem, setDraggedItem] = useState<CampaignSmsContentRow | null>(
    null
  );

  const [smsContent, setSmsContent] = useState<SmsContentRow>();

  function handleOpenEdit(smsContent: SmsContentRow) {
    setSmsContent(smsContent);
    setOpen(true);
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();

    //@ts-ignore
    const target = e.target.closest('[draggable="true"]');
    if (target && draggedItem) {
      const targetId = target.getAttribute("data-id");
      const targetIndex = dragList?.findIndex((item) => item.id === targetId);

      const newList = dragList?.filter((item) => item.id !== draggedItem.id);
      newList.splice(targetIndex, 0, draggedItem);
      setDragList(newList);
    }
  }

  function handleDragStart(
    e: React.DragEvent<HTMLDivElement>,
    item: CampaignSmsContentRow
  ) {
    e.dataTransfer.setData("id", item.id);
    setDraggedItem(item);
  }

  function handleDrop(e: any) {
    e.preventDefault();
    setDraggedItem(null);
  }

  useEffect(() => {
    if (campaignSpecificContent) {
      setDragList(campaignSpecificContent);
    }
  }, [campaignSpecificContent]);

  function isOrderUpdated(
    listOne: CampaignSmsContentRow[],
    listTwo: CampaignSmsContentRow[]
  ) {
    if (listOne.length !== listTwo.length) {
      return true;
    }

    for (let i = 0; i < listOne.length; i++) {
      if (listOne[i].id !== listTwo[i].id) {
        return true;
      }
    }

    return false;
  }

  function handleUpdateOrder() {
    const updatedList = dragList?.map((item, index) => {
      return {
        id: item.id,
        send_order: index + 1,
      };
    });

    updateContent(updatedList);
  }

  useEffect(() => {
    if (
      dragList?.length > 0 &&
      isOrderUpdated(
        dragList,
        campaignSpecificContent as CampaignSmsContentRow[]
      )
    ) {
      setOrderUpdated(true);
    } else {
      setOrderUpdated(false);
    }
  }, [dragList]);

  return (
    <div className="w-full">
      {smsContent && (
        <GenericDialog
          title="Edit Message Content"
          open={open}
          setOpen={setOpen}
        >
          <UpdateSmsContentContainer smsContent={smsContent} />
        </GenericDialog>
      )}
      <List onDrop={handleDrop} onDragOver={handleDragOver}>
        {dragList?.map((campaignSmsContent, index) => {
          const { sms_content, id, send_order } = campaignSmsContent;
          return (
            <div key={id}>
              <ListItem
                onClick={() => handleOpenEdit(sms_content)}
                key={id}
                ripple={false}
                className="py-1 pr-1 pl-4"
                draggable={true}
                onDragStart={(e) => handleDragStart(e, campaignSmsContent)}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                data-id={id}
              >
                <SmsContentListItem
                  sms_content={sms_content}
                  send_order={send_order}
                  index={id}
                  orderUpdated={orderUpdated}
                />
                <ListItemSuffix>
                  <IconButton
                    variant="text"
                    color="blue-gray"
                    onClick={(e) => {
                      e.stopPropagation();
                      useDeleteCampaignSmsContent.mutate({
                        campaignSmsContentId: id,
                      });
                    }}
                  >
                    <DeleteIcon className="w-4 w-4" />
                  </IconButton>
                </ListItemSuffix>
              </ListItem>
            </div>
          );
        })}
        {generating && (
          <ListItem>
            <div className="flex flex-cols gap-2">
              <PlentiLogoLoading className="h-12 w-12 " />
              <p>Generating content...</p>
            </div>
          </ListItem>
        )}
      </List>
      {orderUpdated && (
        <div className="mt-6 flex justify-end gap-2">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() =>
              setDragList(campaignSpecificContent as CampaignSmsContentRow[])
            }
          >
            Cancel
          </Button>

          <Button
            size="sm"
            color="green"
            fullWidth={false}
            onClick={handleUpdateOrder}
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
}

export default SmsContentList;
