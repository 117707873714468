import { AiAgentCreate, AiAgentRow } from "./aiAgent.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  CompanyAiFunctionRow,
  CompanyAiFunctionUpdate,
} from "./companyAiFunction.type";

export function getCompanyAiFunctionsByCompany(
  companyId: string
): Promise<CompanyAiFunctionRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyAiFunction +
    "/company/" +
    companyId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<CompanyAiFunctionRow[]>(url, request);
}

export function updateCompanyAiFunction({
  id,
  values,
}: {
  id: string;
  values: CompanyAiFunctionUpdate;
}): Promise<CompanyAiFunctionRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.companyAiFunction + "/" + id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<CompanyAiFunctionRow>(url, request);
}

export function createCompanyAiAgent(
  values: AiAgentCreate
): Promise<AiAgentRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.companyAiFunction;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ ...values }),
  });

  return fetchData<AiAgentRow>(url, request);
}
