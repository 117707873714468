import { Typography } from "@material-tailwind/react";
import FabrkMenuDivider from "./FabrkMenuDivider";
import FileDetails from "./FileDetails";

function AgentDetails({
  detail,
  idx,
  files,
}: {
  detail: { ai_function: { name: string; type: string } };
  idx: number;
  files?: { file_store: { url: string } }[];
}) {
  return (
    <div key={idx} className="flex flex-col gap-2">
      <Typography variant="small">Agent Name:</Typography>
      <Typography className="font-bold">{detail.ai_function.name}</Typography>
      <Typography variant="small">Agent Type:</Typography>
      <Typography className="font-bold">{detail.ai_function.type}</Typography>
      {files && files?.length > 0 && (
        <Typography className="mt-5" variant="small">
          Files:
        </Typography>
      )}
      <FabrkMenuDivider />
      <div className="flex flex-col gap-5">
        {files?.map((file, index) => {
          return <FileDetails file={file} index={index} />;
        })}
      </div>
    </div>
  );
}

export default AgentDetails;
