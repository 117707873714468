/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialContent,
  SpeedDialHandler,
  Typography,
} from "@material-tailwind/react";

import { useEffect } from "react";
import { useDemoContext } from "../demo/demoContext";
import { useFileUploadContext } from "../forms/fileUploadContext";
import { GlobalIcon, UploadMany, UserCircle } from "../icons/icons";
import { useMessageContext } from "../lead/messageContext";
import FileUploadInput from "../forms/fileUploadInput";

export function SpeedDialForChat() {
  const { toggleLeadMode, activeLead } = useMessageContext();
  const {
    handleOpenFileSelect,
    file,
    uploadImage,
    setFile,
    filePath,
    setFilePath,
    hiddenFileInput,
    handleChange,
  } = useFileUploadContext();

  const { handleSendTestSms } = useDemoContext();

  useEffect(() => {
    if (file && !filePath) {
      uploadImage({
        file,
        additionalData: { location: "test-images" },
      });

      setFile(null);
    }
  }, [file]);

  useEffect(() => {
    if (filePath) {
      handleSendTestSms("", filePath);
      setFilePath("");
      setFile(null);
    }
  }, [filePath]);

  return (
    <SpeedDial>
      <SpeedDialHandler>
        <IconButton size="lg" color="gray" className="rounded-full">
          <PlusIcon className="h-5 w-5 transition-transform group-hover:rotate-45" />
        </IconButton>
      </SpeedDialHandler>
      <SpeedDialContent>
        <button
          onClick={(e) => {
            e.preventDefault();
            toggleLeadMode();
          }}
        >
          <SpeedDialAction className="relative h-20 w-20 p-2">
            {activeLead && activeLead.sms_mode === "manual" ? (
              <UserCircle className="h-5 w-5" />
            ) : (
              <GlobalIcon className="h-5 w-5" />
            )}
            <Typography color="blue-gray" className="text-xs font-normal">
              Toggle mode
            </Typography>
          </SpeedDialAction>
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();

            handleOpenFileSelect(e);
          }}
        >
          <SpeedDialAction className="relative h-20 w-20 p-2">
            <UploadMany className="h-5 w-5" />
            <Typography color="blue-gray" className="text-xs font-normal">
              Image upload{" "}
            </Typography>
          </SpeedDialAction>
        </button>
      </SpeedDialContent>
      <FileUploadInput
        handleChange={handleChange}
        hiddenFileInput={hiddenFileInput}
      />
    </SpeedDial>
  );
}
