import { Anthropic } from "../../icons/anthropicSvg";
import { GoogleLogo } from "../../icons/googleSvg";
import { MetaLogo } from "../../icons/metaSvg";
import { OpenAi } from "../../icons/openAiSvg";

export const models = [
  {
    provider: "OpenAI",
    models: ["GPT-4o,GPT-4"],
    icon: <OpenAi className="h-6 w-6" />,
    slug: "openai",
  },
  {
    provider: "Anthropic",
    models: ["Claude 3 Opus"],
    icon: <Anthropic className="h-6 w-6" />,
    slug: "anthropic",
  },
  {
    provider: "Google",
    models: ["Gemini Ultra"],
    icon: <GoogleLogo className="h-6 h-6" />,
    slug: "google",
  },
  {
    provider: "Meta",
    models: ["Llama 3"],
    icon: <MetaLogo className="h-6 h-6" />,
    slug: "meta",
  },
];
