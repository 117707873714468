function ViewImage({ title, src }: { title: string; src?: string | null }) {
  return (
    <div>
      <p className="text-xs text-gray-500">{title}</p>
      {src ? (
        <img height={100} width={100} src={src || ""} alt="logo" />
      ) : (
        <p className="text-slate-700">No media</p>
      )}
    </div>
  );
}

export default ViewImage;
