/* eslint-disable react-hooks/exhaustive-deps */
import { Card, LineChart, Title } from "@tremor/react";
import { FC, useState } from "react";

import { MetricCard } from "../../../analyticsComponents/metricCard";
import { useCampaignContext } from "../../../campaign/campaignContext";
import DownloadButton from "../../../downloadCsv/DownloadButton";
import SetCampaignAndDate from "../../setCampaignAndDate";
import { DataCohort, DataReimbursements } from "../types/data.types";
import { dataFormatter } from "../utils/dataFormatter";

interface Props {
  data: Partial<DataReimbursements> | undefined;
  dataTotal: string | number | undefined;
  dataCohort: DataCohort | undefined;
  selectedCampaign?: { id: string; name: string } | null;
}

const ReimbursementCohortChart: FC<Props> = ({
  data,
  dataTotal,
  dataCohort,
  selectedCampaign,
}) => {
  const { campaigns } = useCampaignContext();
  const [download, setDownload] = useState(false);

  return (
    <div>
      <div className="mb-4 w-full space-y-6">
        <div className="mb-8 flex items-center justify-between ">
          <SetCampaignAndDate campaigns={campaigns} />
          {dataCohort && (
            <DownloadButton
              downloadData={{
                csvData: dataCohort.downloadData.csvData || [],
                headers: dataCohort.downloadData.headers,
                fileName: "reimbursement-analytics.csv",
              }}
              download={download}
              setDownload={setDownload}
            />
          )}
        </div>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
        <MetricCard title="Total Reimbursements Created" value={data?.total} />
        <MetricCard title="Total Reimbursements Paid" value={data?.paid} />
        <MetricCard
          title="Total Reimbursement Paid Amount"
          value={data?.paidAmount}
        />
        <MetricCard
          title="Average Reimbursement Rate"
          value={dataCohort?.average ? `${dataCohort.average}%` : "N/A"}
        />
        <MetricCard
          title="Paid In Date Range"
          value={dataCohort?.paid || data?.paid}
        />
        <MetricCard
          title="Total Paid In Date Range"
          value={dataTotal || data?.paidAmount}
        />
      </div>
      <Card className="mt-4">
        <Title>
          Daily reimbursements{" "}
          {selectedCampaign?.name
            ? `- ${selectedCampaign.name}`
            : " - Select a campaign to see daily totals"}
        </Title>
        {dataCohort?.chart && selectedCampaign && (
          <LineChart
            className="mt-6"
            data={dataCohort?.chart}
            index="date"
            categories={["Percent reimbursed", "Average", "Sign ups"]}
            valueFormatter={dataFormatter}
            colors={["cyan", "blue", "green"]}
            yAxisWidth={40}
          />
        )}
      </Card>
    </div>
  );
};

export default ReimbursementCohortChart;
