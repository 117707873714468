import { useEffect, useState } from "react";
import { CampaignJourneyContextWrapper } from "../reactFlow/hooks/CampaignJourneyContext";
import { CampaignTransitionContextWrapper } from "../reactFlow/hooks/CampaignTransitionFlowContext";
import PlentiTabs from "../tab/plentiTab";
import CampaignOverview from "./CampaignOverview";
import CampaignHeader from "./campaignHeader";
import CampaignList from "./campaignList";
import JourneysContainer from "./journeys/JourneysContainer";
import { useSearchParams } from "react-router-dom";

const tabs = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "List",
    value: "list",
  },
  {
    label: "Journeys",
    value: "journeys",
  },
];

function CampaignContainer() {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const { value } = activeTab;

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab) {
      if (tab === "journeys") {
        const journeyTab = tabs.find((t) => t.value === "journeys");
        setActiveTab(journeyTab || tabs[0]);
      }
    }
  }, [tab]);

  return (
    <div className="w-full flex flex-col p-5 gap-10">
      {value !== "journeys" && <CampaignHeader />}
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {value === "overview" && <CampaignOverview />}
      {value === "list" && <CampaignList />}
      {value === "journeys" && (
        <CampaignJourneyContextWrapper>
          <CampaignTransitionContextWrapper>
            <JourneysContainer />
          </CampaignTransitionContextWrapper>
        </CampaignJourneyContextWrapper>
      )}
    </div>
  );
}

export default CampaignContainer;
