import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  CreateExtractionSchema,
  ExtractionSchemaCampaignRow,
  ExtractionSchemaRow,
} from "./extractionSchema.type";

export function getExtractionSchemas(): Promise<ExtractionSchemaRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.extractionSchema;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<ExtractionSchemaRow[]>(url, request);
}

export function createExtractionSchema(values: CreateExtractionSchema) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.extractionSchema;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<ExtractionSchemaRow>(url, request);
}

export function deleteExtractionSchema(id: string) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.extractionSchema + "/" + id;

  const request = setHeaderOptions({ method: "DELETE" });

  return fetchData<ExtractionSchemaRow>(url, request);
}

export function getExtractionSchemaCampaigns(campaignId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchemaCampaign +
    "/campaign/" +
    campaignId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<ExtractionSchemaCampaignRow[]>(url, request);
}

export function createCampaignExtractionSchema({
  campaignId,
  extractionSchemaId,
}: {
  campaignId: string;
  extractionSchemaId: string;
}) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.extractionSchemaCampaign;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ campaignId, extractionSchemaId }),
  });

  return fetchData<ExtractionSchemaCampaignRow>(url, request);
}

export function deleteCampaignExtractionSchema(
  extractionSchemaCampaignId: string
) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchemaCampaign +
    "/" +
    extractionSchemaCampaignId;

  const request = setHeaderOptions({
    method: "DELETE",
  });

  return fetchData<ExtractionSchemaCampaignRow>(url, request);
}
