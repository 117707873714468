import { IconButton, Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { useCompanyContext } from "../company/companyContext";
import { findCompanyById } from "../company/companyHelpers";
import { EditIcon } from "../icons/icons";

import { CompanyRow } from "../../api/company.type";
import { SmsContentRow } from "../../api/smsContent.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";

function SmsContentTableList({
  content,
  handleContentClick,
  filterFunction,
}: {
  content: SmsContentRow[] | undefined;
  handleContentClick: (contentId: string) => void;
  filterFunction?: (prompt: SmsContentRow, index: number) => boolean;
}) {
  const { companies } = useCompanyContext();

  function mapCampaignToRow(content: SmsContentRow): {
    id: string;
    createdAt: string;
    mediaUrl: string | null;
    company: CompanyRow | null | undefined;
    campaignName: string;
    content: string;
    name: string;
  } {
    const values = {
      id: content.id,
      createdAt: content.created_at
        ? formatTimeStampToHumanReadableShortDateTime(content.created_at)
        : "N/A",
      content: content.content ? content.content.slice(0, 30) + "..." : "N/A",
      mediaUrl: content.media_url ? content.media_url : null,
      company:
        content.company_id && companies && companies.length > 0
          ? findCompanyById(companies, content.company_id)
          : null,
      campaignName:
        content.campaign_id && content.campaign ? content.campaign.name : "N/A",
      name: content.name ? content.name : "N/A",
    };

    return values;
  }

  return (
    <Fragment>
      {content &&
        content
          .filter((lead, index) =>
            filterFunction ? filterFunction(lead, index) : true
          )
          .map((item) => mapCampaignToRow(item))
          .map(
            (
              { id, createdAt, mediaUrl, campaignName, content, name, company },
              index
            ) => {
              const isLast = index === content.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={id} className="even:bg-blue-gray-50/50">
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {createdAt}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {name}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    {company?.company_config &&
                    company?.company_config[0] &&
                    company?.company_config[0].logo_image_url ? (
                      <img
                        height={50}
                        width={50}
                        src={company.company_config[0].logo_image_url}
                        alt="logo"
                      />
                    ) : (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {company?.name || "N/A"}
                      </Typography>
                    )}
                  </td>
                  <td className={classes}>
                    {" "}
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {campaignName}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {content}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    {mediaUrl ? (
                      <img height={75} width={75} src={mediaUrl} alt="logo" />
                    ) : (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        N/A
                      </Typography>
                    )}
                  </td>

                  <td className={classes}>
                    <IconButton
                      onClick={() => handleContentClick(id)}
                      variant="text"
                      color="blue-gray"
                    >
                      <EditIcon className="h-4 w-4" />
                    </IconButton>
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default SmsContentTableList;
