import { useState } from "react";
import PlentiTabs from "../tab/plentiTab";
import FlowCardsContainer from "./flowCardsContainer";
import FlowHeader from "./flowHeader";

const tabs = [
  {
    label: "Prebuilt flows",
    value: "prebuilt",
  },
  {
    label: "Your flows",
    value: "your",
  },
];

function FlowContainer() {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <FlowHeader />
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <FlowCardsContainer activeTab={activeTab} />
    </div>
  );
}

export default FlowContainer;
