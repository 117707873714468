import { Button, Textarea } from "@material-tailwind/react";

import { useSmsContentContext } from "./smsContentContext";

import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { SmsContentRow } from "../../api/smsContent.type";
import SelectCompany from "../company/selectCompany";
import { PlentiInput } from "../forms/plentiInput";
import { StarIcon } from "@heroicons/react/24/solid";
import PlentiFormLabel from "../forms/PlentiFormLabel";

export type UpdateSmsContentFormProps = {
  name: string | null;
  content: string | null;
  media_url: string | null;
};

function UpdateSmsContentContainer({
  smsContent,
}: {
  smsContent: SmsContentRow;
}) {
  const { updateContent } = useSmsContentContext();

  const content = useMemo(() => {
    return smsContent;
  }, [smsContent]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      name: content?.name || null,
      content: content?.content || null,
      media_url: content?.media_url || null,
    },
  });

  const onSubmit: SubmitHandler<UpdateSmsContentFormProps> = (
    data: UpdateSmsContentFormProps
  ) => {
    updateContent({
      id: smsContent?.id as string,
      values: data,
    });
    reset();
  };

  return (
    <div className="mt-4 flex flex-col max-w-md gap-4 rounded-lg p-5">
      <div>
        <PlentiFormLabel>AI Generated</PlentiFormLabel>
        {smsContent.type === "ai_generated" ? (
          <div className="flex flex-row items-center">
            <StarIcon className="w-6 h-6" color="orange" />
            Yes
          </div>
        ) : (
          "No"
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PlentiInput
          register={register}
          errors={errors}
          label="Name"
          name="name"
        />
        {smsContent.type !== "ai_generated" && (
          <PlentiInput
            register={register}
            errors={errors}
            label="Media URL"
            name="media_url"
            required={false}
          />
        )}

        <Textarea
          {...register("content")}
          label={
            smsContent.type && smsContent.type === "ai_generated"
              ? "Context passed to AI"
              : "Content"
          }
          className="focus:border-t-0 focus:ring-0"
        />
        <SelectCompany />
        <Button
          size="sm"
          color="green"
          fullWidth={false}
          disabled={!isDirty}
          type="submit"
          className="mt-4"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default UpdateSmsContentContainer;
