/* eslint-disable react-hooks/exhaustive-deps */
import {
  User,
  useSession,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getErrorFromHash } from "../account/accountContainer";

export const notProtectedSlugs = [
  "/signup",
  "/register",
  "/send-password-reset-email",
  "/register/thank-you",
  "/fabrk",
];

export const AuthContextWrapper = (props: any) => {
  const supabase = useSupabaseClient();
  const navigate = useNavigate();
  const location = useLocation();
  const session = useSession();
  const [signupUser, setSignupUser] = useState<User | null>(null);
  const [loginError, setLoginError] = useState<string>("");
  const [passwordResetInstructions, setPasswordResetInstructions] =
    useState<string>("");

  async function handleLogout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    await supabase.auth.signOut();
    navigate("/");
  }

  useEffect(() => {
    const foundToken = localStorage.getItem("access_token");
    const foundRefreshToken = localStorage.getItem("refresh_token");

    if (
      !foundToken &&
      !foundRefreshToken &&
      session?.access_token &&
      session?.refresh_token
    ) {
      localStorage.setItem("access_token", session.access_token);
      localStorage.setItem("refresh_token", session.refresh_token);
    }
  }, [session]);

  async function signIn(data: { email: string; password: string }) {
    const { error } = await supabase.auth.signInWithPassword({
      email: data.email,
      password: data.password,
    });
    if (error) {
      setLoginError(error.message);
    }
  }

  async function signUp(data: { email: string; password: string }) {
    try {
      const { error, data: response } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          emailRedirectTo: `${process.env.REACT_APP_BASE_URL}`,
        },
      });

      if (response) {
        setSignupUser(response.user);
        setLoginError(
          `You have successfully signed up. Please check your email for a confirmation link.`
        );
      }

      if (error) {
        setLoginError(
          `There was an error signing up. Please try again or reset your password.`
        );
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async function resetPassword(email: string) {
    const { error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      setLoginError(
        `There was an error resetting your password. Please try again.`
      );
    } else {
      setPasswordResetInstructions(
        "Check your email for the password reset link."
      );
    }
  }

  useEffect(() => {
    if (!session) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        if (session && session?.access_token && session?.refresh_token) {
          localStorage.setItem("access_token", session.access_token);
          localStorage.setItem("refresh_token", session.refresh_token);
        } else {
          if (
            notProtectedSlugs.includes(location.pathname) ||
            location.pathname === "/"
          ) {
            return;
          } else {
            navigate("/");
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const error = getErrorFromHash(window?.location?.hash);
    if (error && error?.error_description) {
      setLoginError(error?.error_description);
    }
  }, [window?.location?.hash]);

  const value = {
    signIn,
    handleLogout,
    loginError,
    resetPassword,
    passwordResetInstructions,
    signUp,
    signupUser,
    setSignupUser,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const AuthContext = createContext({
  signIn: (data: { email: string; password: string }) => {},
  handleLogout: () => {},
  loginError: "",
  resetPassword: (email: string) => {},
  passwordResetInstructions: "",
  signUp: (data: { email: string; password: string }) => {},
  signupUser: null as User | null,
  setSignupUser: (user: User | null) => {},
});

export const useAuthContext = () => useContext(AuthContext);
