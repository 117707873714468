import { useParams } from "react-router-dom";
import ViewAgentContainer from "../components/agents/ViewAgentContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";
import { AgentContextWrapper } from "../components/agents/AgentContext";

const ViewAgent = () => {
  mixpanelTrackEvent("View agent page");

  const params = useParams();

  return (
    <AgentContextWrapper slug={params.slug}>
      <ViewAgentContainer />
    </AgentContextWrapper>
  );
};

export default ViewAgent;
