import { CompanyConfigRow, CompanyRow } from "../../api/company.type";

function TryLogo({
  company,
  companyConfig,
}: {
  company?: CompanyRow;
  companyConfig?: CompanyConfigRow;
}) {
  return (
    <a
      href={companyConfig?.logo_link_url || "#"}
      target="_blank"
      className="mx-auto mb-10 block h-16 w-48 p-4"
      rel="noreferrer"
    >
      {companyConfig?.logo_image_url ? (
        <img
          width={90}
          height={90}
          className="mx-auto"
          src={companyConfig?.logo_image_url}
          alt="logo"
        />
      ) : (
        <h1 className="text-center text-4xl font-extrabold uppercase text-gray-900">
          {company?.name}
        </h1>
      )}
    </a>
  );
}

export default TryLogo;
