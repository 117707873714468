import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CreatePreUserRow, PreUserRow, UpdatePreUserRow } from "./preUser.type";

export function getPreUsers(): Promise<PreUserRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.preUser;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData(url, request);
}

export function addPreUser(values: CreatePreUserRow) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.preUser;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  };

  return fetchData<PreUserRow>(url, request);
}

export function updatePreUserById(values: UpdatePreUserRow) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.preUser + `/${values.id}`;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  };

  return fetchData<PreUserRow>(url, request);
}
