import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { LocationCount } from "../../api/location.type";

export function getWebhookLink(
  comLocationTableListpanyId: string,
  locationId: string
) {
  return `${process.env.REACT_APP_API_URL}/lead/klaviyo/${locationId}`;
}

function AnalyticsLocationTableList({
  locations,
}: {
  locations: LocationCount[] | undefined;
}) {
  function mapCampaignToRow(location: LocationCount): {
    count: number;
    name: string;
    address: string;
    city: string;
    state: string;
  } {
    const values = {
      count: location?.location_count ? Number(location?.location_count) : 0,
      name: location?.location?.name ? location?.location?.name : "N/A",
      address: location?.location?.address_line_one
        ? location?.location?.address_line_one
        : "N/A",
      city: location?.location?.city ? location?.location?.city : "N/A",
      state: location?.location?.state ? location?.location?.state : "N/A",
    };

    return values;
  }
  return (
    <Fragment>
      {locations &&
        locations
          .map((item) => mapCampaignToRow(item))
          .map(({ name, count, address, city, state }, index) => {
            const isLast = index === locations.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {count}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {name}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {address}
                  </Typography>
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {city}
                  </Typography>
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {state}
                  </Typography>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default AnalyticsLocationTableList;
