import { Select, SelectItem } from "@tremor/react";
import { useEffect, useState } from "react";
import { LeadRow } from "../../api/lead.type";

function SelectLead({
  leads,
  setSelectedLead,
  selectedLead,
}: {
  leads: LeadRow[] | undefined;
  setSelectedLead: any;
  selectedLead: LeadRow | null;
}) {
  const [leadId, setSelectedLeadId] = useState<string>("");

  useEffect(() => {
    if (selectedLead) {
      setSelectedLeadId(selectedLead.id);
    }
  }, [selectedLead, leads]);

  return (
    <div>
      {leads && leads.length > 0 && (
        <Select
          value={leadId}
          onChange={(e: any) => {
            const found = leads?.find((c: LeadRow) => c.id === e);
            if (found) {
              setSelectedLead(found);
            }
          }}
        >
          {leads &&
            leads?.map((lead: LeadRow) => {
              return (
                <SelectItem key={lead.id} value={lead.id}>
                  {lead.id}
                </SelectItem>
              );
            })}
        </Select>
      )}
    </div>
  );
}

export default SelectLead;
