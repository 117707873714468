export function calculateAverage(arr: number[]): number {
  if (!arr.length) return 0;
  const sum = arr.reduce((a, b) => a + b, 0);
  const avg = sum / arr.length || 0;
  return Number((avg * 100).toFixed(0));
}

export function calcPercentage(part: number, total: number): number {
  if (!total) return 0;
  return Number(((part / total) * 100).toFixed(0));
}

export function roundDecimalToWholeNumber(value: number): number {
  if (!value) return 0;
  return Number((value * 100).toFixed(0));
}

export type Stats = {
  average: number;
  standardDeviation: number;
};

export type DeviationRange = {
  min: number;
  max: number;
};

export type DeviationCount = {
  range: DeviationRange;
  count: number;
};

export function countDeviations(
  data: number[],
  stats: Stats
): DeviationCount[] {
  const { average, standardDeviation } = stats;

  // Define actual numeric ranges based on standard deviations
  const ranges = [
    { min: Number.NEGATIVE_INFINITY, max: average - 2 * standardDeviation },
    { min: average - 2 * standardDeviation, max: average - standardDeviation },
    { min: average - standardDeviation, max: average },
    { min: average, max: average + standardDeviation },
    { min: average + standardDeviation, max: average + 2 * standardDeviation },
    { min: average + 2 * standardDeviation, max: Number.POSITIVE_INFINITY },
  ];

  // Initialize counts with actual numeric ranges
  const counts: DeviationCount[] = ranges.map((range) => ({
    range: { min: range.min, max: range.max },
    count: 0,
  }));

  data.forEach((value) => {
    const deviation = (value - average) / standardDeviation;

    if (deviation <= -2) counts[0].count++;
    else if (deviation > -2 && deviation <= -1) counts[1].count++;
    else if (deviation > -1 && deviation < 0) counts[2].count++;
    else if (deviation >= 0 && deviation < 1) counts[3].count++;
    else if (deviation >= 1 && deviation <= 2) counts[4].count++;
    else if (deviation > 2) counts[5].count++;
  });

  return counts;
}
