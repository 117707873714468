/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useCompanyContext } from "../company/companyContext";
import DateDetailsDetails from "../forms/createAtDetails";
import ValueWithLabel from "../forms/valueWithLabel";

function CompanyDetailsContainer() {
  const { selectedCompany, selectedCompanyConfig } = useCompanyContext();

  if (!selectedCompany) {
    return <div>Error: No Company</div>;
  }

  if (!selectedCompanyConfig) {
    return <div>Error: No Company Config</div>;
  }

  const { id, created_at, name } = selectedCompany;

  return (
    <div className="max-w-2xl">
      <div className="my-4 grid w-full gap-10">
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-row justify-between gap-2">
            <ValueWithLabel label="Name" value={name} />
          </div>
          <div className="grid grid-cols-2">
            <DateDetailsDetails date={created_at} title="Created" />

            <ValueWithLabel label="Id" value={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetailsContainer;
