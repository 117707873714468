/* eslint-disable react/jsx-pascal-case */
import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CampaignSmsContentRow } from "../../api/campaignSmsContent.type";
import { SegmentsRow } from "../../api/segments.type";
import { SmsContentRow } from "../../api/smsContent.type";
import { ButtonPrimary } from "../buttons/ButtonPrimary";
import MultiSegmentSelect from "../segments/MultiSegmentSelect";
import { leadSegments } from "../segments/filterSegments";
import { useSegmentContext } from "../segments/hooks/SegmentContext";
import SelectSmsContent from "../smsContent/SelectSmsContent";

function AddSegmentsContainer({
  campaignId,
  smsContent,
  campaignDefaultSmsContent,
  setOpen,
}: {
  campaignId: string;
  smsContent: SmsContentRow[] | undefined;
  campaignDefaultSmsContent: CampaignSmsContentRow | undefined;
  setOpen: (open: boolean) => void;
}) {
  const { segments, addSegments } = useSegmentContext();
  const [filteredSegments, setFilteredSegments] = useState<SegmentsRow[]>([]);
  const [selectedSegments, setSelectedSegments] = useState<SegmentsRow[]>([]);
  const [selectedSmsContent, setSelectedSmsContent] = useState<SmsContentRow>();

  const [contentToSend, setContentToSend] = useState<string>("");

  useEffect(() => {
    if (segments) {
      setFilteredSegments(leadSegments(segments));
    }
  }, [segments]);

  function handleAddSegments() {
    addSegments({
      segmentIds: selectedSegments.map((s) => s.id),
      campaignId,
      ...(contentToSend !== "none" && { smsContentId: selectedSmsContent?.id }),
    });
    setOpen(false);
  }

  return (
    <div className="flex flex-col gap-4">
      <Typography variant="paragraph">
        Segments are lead lists with specific criteria. You can add these lists
        to this campaign.{" "}
      </Typography>
      {!filteredSegments ||
        (filteredSegments.length === 0 && (
          <div>
            <Typography variant="paragraph">No segments available</Typography>

            <Link
              to="/segments"
              onClick={() => setOpen(false)}
              className="text-primary-300"
            >
              Create a segment
            </Link>
          </div>
        ))}

      {filteredSegments && filteredSegments.length > 0 && (
        <MultiSegmentSelect
          segments={filteredSegments}
          setSelectedSegments={setSelectedSegments}
          selectedSegments={selectedSegments}
        />
      )}

      {selectedSegments && selectedSegments.length > 0 && (
        <div className="flex flex-col gap-4">
          <Typography variant="paragraph">
            Do you want to send the leads in these segments notification of this
            campaign?{" "}
          </Typography>
          <div className="flex flex-row gap-2">
            <Button
              onClick={() => {
                setSelectedSmsContent(campaignDefaultSmsContent?.sms_content);
                setContentToSend("default");
              }}
              variant="outlined"
              color="cyan"
            >
              Send default
            </Button>
            <Button
              onClick={() => setContentToSend("select")}
              variant="outlined"
              color="deep-purple"
            >
              Select message
            </Button>
            <Button
              onClick={() => setContentToSend("none")}
              variant="outlined"
              color="light-green"
            >
              Don't send
            </Button>
          </div>
        </div>
      )}
      {contentToSend === "default" && (
        <div>
          <p className="text-xs text-gray-500"> Default message to send:</p>
          <p>
            {" "}
            {campaignDefaultSmsContent?.sms_content?.name} -{" "}
            {campaignDefaultSmsContent?.sms_content?.content.substring(0, 40)}
            ...
          </p>
        </div>
      )}
      {contentToSend === "select" && (
        <SelectSmsContent
          smsContent={smsContent || []}
          setSelectedSmsContent={setSelectedSmsContent}
          selectedSmsContent={selectedSmsContent as SmsContentRow}
        />
      )}
      {contentToSend === "none" && (
        <p className="text-xs text-gray-500">
          No message will be sent to the leads. Follow up messages will still be
          sent to these leads.
        </p>
      )}
      {filteredSegments && filteredSegments.length > 0 && (
        <ButtonPrimary onClick={handleAddSegments} disabled={!contentToSend}>
          Submit
        </ButtonPrimary>
      )}
    </div>
  );
}

export default AddSegmentsContainer;
