/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useFontContext } from "../font/fontContext";
import { Option, Select, Typography } from "@material-tailwind/react";
import { FontRow } from "../../api/font.type";

function SelectFont({
  startingFont,
  handleSelected,
}: {
  handleSelected: (font: FontRow) => void;
  startingFont?: FontRow;
}) {
  const { fonts } = useFontContext();

  const [selectedFont, setSelectedFont] = useState<FontRow>();

  useEffect(() => {
    if (startingFont) {
      setSelectedFont(startingFont);
    }
  }, [startingFont]);

  useEffect(() => {
    if (selectedFont) {
      handleSelected(selectedFont);
    }
  }, [selectedFont]);

  return (
    <div>
      <Select
        label="Select a font"
        value={selectedFont?.id}
        onChange={(e: any) => {
          const found = fonts?.find((c) => c.id === e);
          if (found) {
            setSelectedFont(found);
          }
        }}
      >
        {/* @ts-ignore */}
        {fonts &&
          fonts?.map((font) => {
            if (!font) return null;
            return (
              <Option key={font.id} value={font.id}>
                <Typography className={`font-${font.tailwind_name}`}>
                  {font.name}
                </Typography>
              </Option>
            );
          })}
      </Select>
    </div>
  );
}

export default SelectFont;
