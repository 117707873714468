import dayjs from "dayjs";
import { create } from "zustand";
import { CampaignRow } from "../../../api/campaign.type";

export const useSetCampaignAndDate = create<Store>((set) => ({
  date: {
    from: dayjs().add(-6, "month").toDate(),
    to: dayjs().toDate(),
  },
  selectedCampaign: undefined,
  actions: {
    updateDate: (date) => set({ date }),
    updateSelectedCampaign: (selectedCampaign) => set({ selectedCampaign }),
  },
}));

interface DateRange {
  from?: Date;
  to?: Date;
}

interface Store {
  date?: DateRange;
  selectedCampaign?: CampaignRow;
  actions: {
    updateDate: (date?: DateRange) => void;
    updateSelectedCampaign: (selectedCampaign?: CampaignRow) => void;
  };
}
