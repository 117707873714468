import { useParams } from "react-router-dom";
import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import ShopifyIntegration from "../components/company/ShopifyIntegration";
import { mixpanelTrackEvent } from "../utils/mixpanel";
import EmailIntegrationContainer from "../components/emailTemplates/EmailIntegrationContainer";

const Integrations = () => {
  mixpanelTrackEvent("Integrations page");

  const params = useParams();

  return (
    <CampaignContextWrapper>
      <div className="p-5">
        {params.name === "shopify" && <ShopifyIntegration />}
        {params.name === "email" && <EmailIntegrationContainer />}
      </div>
    </CampaignContextWrapper>
  );
};

export default Integrations;
