import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { QuestionTypes } from "../../api/extractionSchema.type";
import AddButton from "../buttons/addButton";
import EditSchema from "./EditSchema";

function ExtractionSchemaListAddNewHeader({
  schema,
  addSchema,
  setAddSchema,
}: {
  schema: any;
  addSchema: any;
  setAddSchema: any;
}) {
  return (
    <div>
      <div className="flex flex-row justify-between gap-2 ">
        <Typography variant="h6">Available data to extract</Typography>
        <AddButton
          iconClass="h-4 w-4"
          color="green"
          variant="outlined"
          onClick={() => {
            if (schema) {
              setAddSchema(true);
            } else {
              setAddSchema(false);
            }
          }}
        >
          Add new data to extract
        </AddButton>
      </div>
      <Divider className="my-1" />
      {addSchema && (
        <EditSchema
          schema={[
            {
              id: "",
              type: QuestionTypes.TEXT,
              question: "",
              required: false,
            },
          ]}
          setIsEdit={setAddSchema}
        />
      )}
    </div>
  );
}

export default ExtractionSchemaListAddNewHeader;
