import { Chip, Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { LinkToId } from "../table/linkToId";

import { CampaignRow } from "../../api/campaign.type";
import { ReimbursementRow } from "../../api/reimbursement.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { useCampaignContext } from "../campaign/campaignContext";

export type ReimbursementTableListProps = {
  reimbursements: ReimbursementRow[] | undefined;
  handleContentClick?: (id: string) => void;
  filterFunction?: (reimbursement: ReimbursementRow, index: number) => boolean;
  sortFunction?: (a: ReimbursementRow, b: ReimbursementRow) => number;
};

function ReimbursementTableList({
  reimbursements,
  filterFunction,
  sortFunction,
}: ReimbursementTableListProps) {
  const { campaigns } = useCampaignContext();

  function mapReimbursementToRow(reimbursement: ReimbursementRow): {
    id: string;
    campaignName: string;
    createdAt: string;
    status: string;
    amount: number;
    payoutHandle: string;
    validSku: string;
  } {
    if (!reimbursement) {
      return {
        id: "",
        campaignName: "",
        createdAt: "",
        status: "",
        amount: 0,
        payoutHandle: "",
        validSku: "",
      };
    }
    const values = {
      createdAt: reimbursement.created_at
        ? formatTimeStampToHumanReadableShortDateTime(reimbursement.created_at)
        : "N/A",
      id: reimbursement.id,
      status: reimbursement.status ? reimbursement.status : "N/A",
      campaignName:
        reimbursement?.campaign_id && campaigns
          ? campaigns?.find(
              (c: CampaignRow) => c.id === reimbursement.campaign_id
            )?.name || "N/A"
          : "N/A",
      amount: reimbursement.reimbursement_amount
        ? reimbursement.reimbursement_amount
        : reimbursement.payment_amount
        ? reimbursement.payment_amount
        : 0,
      payoutHandle: reimbursement.payment_account_identifier
        ? `${reimbursement.payment_account_type}: ${reimbursement.payment_account_identifier}`
        : "N/A",
      validSku: reimbursement.valid_sku ? reimbursement.valid_sku : "N/A",
    };

    return values;
  }

  function getStatusColor(status: string) {
    switch (status) {
      case "paid":
        return "green";
      case "invalid":
        return "red";
      case "duplicate":
        return "yellow";
      default:
        return "blue-gray";
    }
  }

  return (
    <Fragment>
      {reimbursements &&
        reimbursements
          .sort((a, b) => (sortFunction ? sortFunction(a, b) : 0))
          .filter((reimbursement, index) =>
            filterFunction ? filterFunction(reimbursement, index) : true
          )
          .map((item) => mapReimbursementToRow(item))
          .map(({ id, createdAt, status, amount, campaignName }, index) => {
            const isLast = index === reimbursements.length - 1;
            const classes = isLast ? "p-2" : "p-2 border-b border-blue-gray-50";

            return (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex max-w-xs flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {createdAt}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {campaignName}
                  </Typography>
                </td>
                <td className={classes}>
                  <div className="w-max">
                    {status && (
                      <Chip
                        size="sm"
                        variant="ghost"
                        value={status}
                        color={getStatusColor(status)}
                      />
                    )}
                  </div>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color={getStatusColor(status)}
                    className="font-normal"
                  >
                    {parseCentsToDollars(amount)}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal opacity-70"
                  >
                    <LinkToId
                      type="Chat link"
                      id={id}
                      baseUrl="/reimbursement/"
                    />
                  </Typography>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default ReimbursementTableList;
