import { ReimbursementRow } from "../../api/reimbursement.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";

export function getStatusColor(status: string) {
  switch (status) {
    case "paid":
      return "green";
    case "invalid":
      return "red";
    case "duplicate":
      return "yellow";
    case "pending":
      return "orange";
    default:
      return "blue-gray";
  }
}

export function getTotalReimbursementAmount(
  reimbursements: ReimbursementRow[]
) {
  if (!reimbursements) return "$0.00";
  const totalCents = reimbursements.reduce((acc, curr) => {
    return (
      acc +
      (curr.reimbursement_amount && curr.status === "paid"
        ? Number(curr.reimbursement_amount)
        : 0)
    );
  }, 0);

  return parseCentsToDollars(totalCents);
}
