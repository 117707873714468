import { CompanyRow } from "../../api/company.type";

export function findCompanyById(
  companies: CompanyRow[] | undefined,
  companyId: string
): CompanyRow | undefined {
  return companies?.find((company) => company.id === companyId);
}

export function getUniqueValuesForTableHeadFilter(
  values: any[],
  companies: CompanyRow[] | undefined
) {
  const uniqueValues = ["All"] as string[];

  if (!values) {
    return uniqueValues;
  }

  if (!companies || companies.length === 0) {
    return uniqueValues;
  }

  for (let i = 0; i < values.length; i++) {
    const f = values[i];

    const foundCompany = findCompanyById(companies, f.company_id);
    if (!foundCompany) {
      continue;
    }
    if (
      f.company_id &&
      !uniqueValues.find((item) => item === foundCompany.name) &&
      companies &&
      companies.length > 0
    ) {
      uniqueValues.push(foundCompany?.name);
    }
  }
  return uniqueValues;
}
