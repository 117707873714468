import { Typography, Radio, Input } from "@material-tailwind/react";

function MergeTagsList({
  mergeTags,
  mergeTagsDefaults,
  setMergeTagsDefaults,
}: {
  mergeTags: string[];
  mergeTagsDefaults: Array<{ tag: string; value: string } | undefined>;
  setMergeTagsDefaults: React.Dispatch<
    React.SetStateAction<
      {
        tag: string;
        value: string;
      }[]
    >
  >;
}) {
  return (
    <div className="border border-blue-gray-100 rounded-lg p-5">
      {mergeTags.map((tag, index) => {
        return (
          <div key={index} className="grid grid-cols-4">
            <Typography>{tag}</Typography>
            <Radio
              color="green"
              crossOrigin={true}
              name={index.toString()}
              label="set default"
              checked={mergeTagsDefaults[index] ? true : false}
              onClick={() => {
                if (mergeTagsDefaults[index]) {
                  const newMergeTagsDefaults = [...mergeTagsDefaults];
                  // @ts-ignore
                  newMergeTagsDefaults[index] = undefined;
                  // @ts-ignore
                  setMergeTagsDefaults(newMergeTagsDefaults);
                  return;
                }

                const newMergeTagsDefaults = [...mergeTagsDefaults];
                newMergeTagsDefaults[index] = { tag: tag, value: "" };
                setMergeTagsDefaults(
                  newMergeTagsDefaults as React.SetStateAction<
                    { tag: string; value: string }[]
                  >
                );
              }}
            />
            {mergeTagsDefaults[index] && (
              <Input
                onChange={(e) => {
                  const newMergeTagsDefaults = [...mergeTagsDefaults];
                  newMergeTagsDefaults[index] = {
                    tag: tag,
                    value: e.target.value,
                  };
                  // @ts-ignore
                  setMergeTagsDefaults(newMergeTagsDefaults);
                }}
                crossOrigin={"true"}
                label={`${tag} default value`}
                variant="outlined"
                className="focus:border-t-0 focus:ring-0"
                value={
                  mergeTagsDefaults.find((t) => t?.tag === tag)?.value || ""
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default MergeTagsList;
