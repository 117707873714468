import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Typography } from "@material-tailwind/react";

export function FormattedLink({ text, to }: { text: string; to: string }) {
  return (
    <Fragment>
      <Link className="text-primary-400" to={to}>
        <Typography variant="small" className="font-normal">
          {text}
        </Typography>
      </Link>
    </Fragment>
  );
}
