import { useNavigate } from "react-router-dom";
import AddButton from "../buttons/addButton";
import AgentAccordion from "./agentAccordion";
import AgentCard from "./agentCard";

function AgentsContainer() {
  const navigate = useNavigate();
  return (
    <div className="p-5">
      <div className="flex flex-row justify-between">
        <AgentCard />
        <div>
          <AddButton
            iconClass="h-4 w-4"
            onClick={() => {
              navigate("/agent/add");
            }}
            color="green"
            size="lg"
            variant="outlined"
          >
            Add new agent
          </AddButton>
        </div>
      </div>
      <div className="mt-10">
        <AgentAccordion />
      </div>
    </div>
  );
}

export default AgentsContainer;
