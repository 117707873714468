export default function HalfOffIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 126 147.5"
      enable-background="new 0 0 126 118"
      className={className}
    >
      <g>
        <rect
          x="32.974"
          y="78.081"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -44.6037 51.6502)"
          fillRule="evenodd"
          clipRule="evenodd"
          width="14.142"
          height="3.171"
        />
        <rect
          x="24.46"
          y="86.595"
          transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -21.7699 178.3156)"
          fillRule="evenodd"
          clipRule="evenodd"
          width="3.171"
          height="14.142"
        />
        <rect
          x="46.974"
          y="64.081"
          transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 138.6945 73.8839)"
          fillRule="evenodd"
          clipRule="evenodd"
          width="14.142"
          height="3.171"
        />
        <rect
          x="60.974"
          y="50.081"
          transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 152.6945 40.0849)"
          fillRule="evenodd"
          clipRule="evenodd"
          width="14.142"
          height="3.171"
        />
        <rect
          x="73.974"
          y="37.081"
          transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 165.6945 8.7001)"
          fillRule="evenodd"
          clipRule="evenodd"
          width="14.142"
          height="3.171"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.559,74.253L13.371,50.069c-1.714-1.707-1.704-4.168-1.704-6.544v-0.567   l0.007-26.143c0-3.391,2.767-6.149,6.155-6.149l26.139,0.004c0,0,5.067-0.104,8.574,3.402l22.724,22.475l3.183-3.182L56.263,11.427   c-5.543-5.543-12.309-5.16-12.309-5.16l-26.12,0.025c-5.873,0-10.411,4.715-10.411,10.587l0.243,26.081v0.559   c0,2.83-0.266,6.708,2.779,9.741l24.051,24.178L37.559,74.253z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.356,63.28l-22.01-22.015l-3.183,3.182l22.014,22.018   c0.96,0.958,1.489,2.233,1.489,3.59s-0.528,2.635-1.488,3.592l-30.521,30.531c-0.959,0.957-2.234,1.488-3.591,1.488   s-2.632-0.531-3.591-1.488L45.457,82.154l-3.182,3.183l22.018,22.023c1.815,1.812,4.22,2.807,6.773,2.807s4.958-0.995,6.77-2.803   l30.525-30.535c1.808-1.802,2.806-4.209,2.806-6.773C111.167,67.495,110.168,65.088,108.356,63.28z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.028,22.026c-3.424,3.424-3.424,8.999-0.001,12.426   c1.662,1.659,3.865,2.574,6.211,2.574s4.55-0.915,6.208-2.574c3.421-3.426,3.424-9,0.001-12.426   c-1.657-1.658-3.862-2.574-6.207-2.574C26.892,19.452,24.686,20.366,23.028,22.026z M32.262,31.272   c-0.819,0.82-1.865,1.254-3.024,1.254s-2.207-0.436-3.032-1.259c-1.666-1.669-1.667-4.389,0.004-6.06   c0.821-0.823,1.869-1.256,3.031-1.256c1.141,0,2.215,0.446,3.023,1.255C33.934,26.878,33.933,29.599,32.262,31.272z"
        />
        <rect
          x="93.46"
          y="17.595"
          transform="matrix(0.7071 0.7071 -0.7071 0.7071 45.28 -59.9826)"
          fillRule="evenodd"
          clipRule="evenodd"
          width="3.171"
          height="14.142"
        />
      </g>
    </svg>
  );
}
