/* eslint-disable react-hooks/exhaustive-deps */
import { Chip } from "@material-tailwind/react";
import { useState } from "react";
import { ExtractionSchemaRow } from "../../api/extractionSchema.type";
import { PlusIcon } from "../icons/icons";
import ExtractionSchemaList from "./ExtractionSchemaList";
import ExtractionSchemaListAddNewHeader from "./ExtractionSchemaListAddNewHeader";

function ExtractionSchemaListContainer({
  setSelectedSchema,
  schema,
}: {
  schema: ExtractionSchemaRow[] | undefined;
  setSelectedSchema?: (schema: ExtractionSchemaRow) => void;
}) {
  const [addSchema, setAddSchema] = useState(false);

  return (
    <div className="max-w-2xl">
      <ExtractionSchemaListAddNewHeader
        schema={schema}
        setAddSchema={setAddSchema}
        addSchema={addSchema}
      />
      <ExtractionSchemaList
        setSelectedSchema={setSelectedSchema}
        schema={schema}
      >
        <Chip
          color="blue"
          value="Add"
          className="flex items-center "
          icon={<PlusIcon className="w-4 h-4" />}
        />
      </ExtractionSchemaList>
    </div>
  );
}

export default ExtractionSchemaListContainer;
