import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  RecurringPatternRow,
  RecurringPatternUpdate,
} from "./recurringPattern.type";

export function getRecurringPatternByCampaign(
  campaignId: string
): Promise<RecurringPatternRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.recurringPattern +
    "/campaign/" +
    campaignId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<RecurringPatternRow>(url, request);
}

export function updateRecurringPatternByCampaign({
  campaignId,
  values,
}: {
  campaignId: string;
  values: RecurringPatternUpdate;
}) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.recurringPattern +
    "/campaign/" +
    campaignId;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<RecurringPatternRow>(url, request);
}

export function getAbandonedCartRecurringPattern(
  campaignId: string
): Promise<RecurringPatternRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.recurringPattern +
    "/abandoned-cart/" +
    campaignId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<RecurringPatternRow>(url, request);
}
