import { createContext, useContext } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { AiAgentCreate, AiAgentRow } from "../../../api/aiAgent.type";
import { createCompanyAiAgent } from "../../../api/companyAiFunction.api";
import { useAlertContext } from "../../alert/alertContext";

export const AiAgentContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const { mutate: createAgent } = useMutation(createCompanyAiAgent, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Agent created",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });
  const value = { createAgent };

  return (
    <AiAgentContext.Provider value={value}>
      {props.children}
    </AiAgentContext.Provider>
  );
};

export const AiAgentContext = createContext({
  createAgent: {} as UseMutateFunction<
    AiAgentRow,
    Error,
    AiAgentCreate,
    unknown
  >,
});

export const useAiAgentContext = () => useContext(AiAgentContext);
