import { AiAgentRow } from "../../api/aiAgent.type";

const excludedAgentList = [
  "getFormLinkTool",
  "aiCreateLeadCampaignTool",
  "setPaymentAccountTypeAndIdentifier",
  "updateLeadEmailAndName",
];

export function filterAgents(agent: AiAgentRow, campaignAiFunctions?: any[]) {
  if (!agent) return false;

  const isIncluded = campaignAiFunctions?.find((cf) => {
    if (!cf.ai_function) return false;
    return cf.ai_function.id === agent.id;
  })
    ? true
    : false;

  const isExcluded = excludedAgentList.find((excluded) => {
    return excluded === agent.function_name;
  });

  return !isIncluded && !isExcluded;
}
