/* eslint-disable react/jsx-pascal-case */
import { Fragment } from "react";
import FileUploadComponent from "./fileUploadComponent";
import {
  FileUploadContextWrapper,
  useFileUploadContext,
} from "./fileUploadContext";
import FileUploadInput from "./fileUploadInput";

function _FileUploadComponentWithContext({
  imgUrl,
  handleFilePath,
}: {
  handleFilePath: (filePath: string) => void;
  imgUrl?: string | null;
}) {
  const { hiddenFileInput, handleChange } = useFileUploadContext();
  return (
    <Fragment>
      <FileUploadComponent imgUrl={imgUrl} handleFilePath={handleFilePath} />
      <FileUploadInput
        handleChange={handleChange}
        hiddenFileInput={hiddenFileInput}
      />
    </Fragment>
  );
}

function FileUploadComponentWithContext({
  imgUrl,
  handleFilePath,
}: {
  handleFilePath: (filePath: string) => void;
  imgUrl?: string | null;
}) {
  return (
    <FileUploadContextWrapper>
      <_FileUploadComponentWithContext
        imgUrl={imgUrl}
        handleFilePath={handleFilePath}
      />
    </FileUploadContextWrapper>
  );
}

export default FileUploadComponentWithContext;
