/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import EditButton from "../buttons/editButton";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import LearnMoreButton from "../link/LearnMoreButton";
import { LinkToId } from "../table/linkToId";
import { useCompanyContext } from "./companyContext";
import { useCampaignContext } from "../campaign/campaignContext";
import SelectCampaign from "../campaign/selectCampaign";
import { CampaignRow } from "../../api/campaign.type";
import InfoPopover from "../InfoPopover/infoPopover";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { useMutation } from "react-query";
import {
  getAbandonedCartRecurringPattern,
  updateRecurringPatternByCampaign,
} from "../../api/recurringPattern.api";
import GenericSelect from "../forms/GenericSelect";
import { ExternalLink } from "../icons/icons";
import { Link, useLocation } from "react-router-dom";

type IntegrationsFormProps = {
  shopifyId: string;
};

function ShopifyIntegration() {
  const location = useLocation();

  const { selectedCompany, selectedCompanyConfig, updateCompanyConfig } =
    useCompanyContext();
  const [isEdit, setIsEdit] = useState(false);
  const { campaigns, updateCampaign } = useCampaignContext();

  const [shopifyAbandonedCartCampaign, setShopifyAbandonedCartCampaign] =
    useState<CampaignRow>();

  const { mutate: getAbandonedCartWaitTime, data: waitTime } = useMutation(
    getAbandonedCartRecurringPattern
  );

  const { mutate: updateWaitTime } = useMutation(
    updateRecurringPatternByCampaign,
    {
      onSuccess: () => {
        getAbandonedCartWaitTime(shopifyAbandonedCartCampaign?.id as string);
      },
    }
  );

  const waitTimeOptions = [
    "5 minutes",
    "10 minutes",
    "30 minutes",
    "1 hour",
    "6 hours",
    "12 hours",
    "1 day",
    "2 days",
  ];

  const [selectedWaitTime, setSelectedWaitTime] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      shopifyId: selectedCompanyConfig?.shopify_id || "",
    },
  });

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
      const found = campaigns.find(
        (c: CampaignRow) => c.shopify_abandoned_cart
      );
      if (found) {
        setShopifyAbandonedCartCampaign(found);
        getAbandonedCartWaitTime(found.id);
      }
    }
  }, [campaigns]);

  useEffect(() => {
    if (waitTime) {
      const unit = waitTime.unit;
      const separator = waitTime.separator;
      setSelectedWaitTime(`${separator} ${unit}`);
    }
  }, [waitTime]);

  if (!selectedCompany) {
    return <div>Error: No Company</div>;
  }

  if (!selectedCompanyConfig) {
    return <div>Error: No Company Config</div>;
  }

  const onSubmit: SubmitHandler<IntegrationsFormProps> = (
    data: IntegrationsFormProps
  ) => {
    const values = {
      shopify_id: data.shopifyId,
    };

    if (shopifyAbandonedCartCampaign) {
      updateCampaign({
        id: shopifyAbandonedCartCampaign.id,
        shopify_abandoned_cart: true,
        company_id: selectedCompany.id,
      });
    }

    if (selectedWaitTime) {
      const unit = selectedWaitTime.split(" ")[1];
      const separator = parseInt(selectedWaitTime.split(" ")[0]);

      updateWaitTime({
        campaignId: shopifyAbandonedCartCampaign?.id as string,
        values: {
          ...(waitTime?.id && { id: waitTime.id }),
          company_id: selectedCompany.id,
          name: "Abandoned Cart Wait Time",
          separator,
          unit,
          campaign_id: shopifyAbandonedCartCampaign?.id,
        },
      });
    }

    updateCompanyConfig({
      id: selectedCompanyConfig.id,
      values,
    });
    setIsEdit(false);
    reset();
  };

  return (
    <div className="max-w-2xl">
      <div className="my-4 grid w-full gap-10">
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-between">
              <Typography variant="h6">Shopify</Typography>
              <EditButton
                iconClass="h-4 w-4"
                text="Edit"
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              />
            </div>
            <Divider className="my-1" />
            <div className="my-4 grid w-full gap-10">
              <div className="flex flex-row justify-between">
                <LinkToId
                  type="Link to Plenti's Shopify app"
                  id={"https://apps.shopify.com/plenti-ai-1"}
                  baseUrl={""}
                />
                <LearnMoreButton
                  variant="text"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch?v=3bulq8Xlx5s",
                      "_blank"
                    )
                  }
                />
              </div>
              {!isEdit ? (
                <div className="flex flex-row justify-between">
                  <ValueWithLabel
                    label="Shopify ID"
                    value={selectedCompanyConfig.shopify_id || "N/A"}
                  />
                </div>
              ) : (
                <PlentiInput
                  register={register}
                  name="shopifyId"
                  label="Shopify Id"
                  errors={errors}
                  required={false}
                />
              )}
              <div>
                <div className="flex justify-between">
                  <Typography variant="h6">Abandoned Cart Config</Typography>
                  <EditButton
                    iconClass="h-4 w-4"
                    text="Edit"
                    onClick={() => {
                      setIsEdit(!isEdit);
                    }}
                  />
                </div>
                <Divider className="my-1" />
              </div>
              {isEdit ? (
                <div className="flex flex-col gap-1">
                  <PlentiFormLabel>Abandoned Cart Campaign</PlentiFormLabel>
                  <SelectCampaign
                    campaigns={campaigns}
                    selectedCampaign={shopifyAbandonedCartCampaign}
                    setSelectedCampaign={setShopifyAbandonedCartCampaign}
                  />
                </div>
              ) : (
                <div className="relative w-fit">
                  <div className="flex flex-row items-end gap-2">
                    <ValueWithLabel
                      label="Abandoned Cart Campaign"
                      value={shopifyAbandonedCartCampaign?.name || "N/A"}
                    />
                    <Link
                      className="mb-1"
                      to={`/campaign/${shopifyAbandonedCartCampaign?.id}`}
                      state={{
                        name: "Integrations",
                        href: location.pathname,
                        active: false,
                      }}
                    >
                      <ExternalLink className="h-4 w-4 " />
                    </Link>
                  </div>
                  <div className="absolute top-[-12px] right-[-15px]">
                    <InfoPopover>
                      <p className="text-slate-700">
                        If you have linked your Shopify store, this campaign
                        will be the default campaign when customers opt in to
                        receive marketing messages.
                      </p>
                    </InfoPopover>
                  </div>
                </div>
              )}
              {isEdit ? (
                <div className="flex flex-col gap-1">
                  <PlentiFormLabel>Wait Time</PlentiFormLabel>
                  <GenericSelect
                    values={waitTimeOptions}
                    setSelected={setSelectedWaitTime}
                    selected={selectedWaitTime}
                  />
                </div>
              ) : (
                <div className="relative w-fit">
                  <ValueWithLabel
                    label="Wait Time"
                    value={selectedWaitTime || "N/A"}
                  />
                  <div className="absolute top-[-12px] right-[-15px]">
                    <InfoPopover>
                      <p className="text-slate-700">
                        This is the time between when a customer abandons their
                        cart and when they receive the first text message.
                      </p>
                    </InfoPopover>
                  </div>
                </div>
              )}
            </div>
            {isEdit && (
              <div className="mt-6 flex justify-end gap-2">
                <Button
                  size="sm"
                  variant="outlined"
                  color="gray"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="green"
                  fullWidth={false}
                  disabled={!isEdit}
                  type="submit"
                >
                  Update
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ShopifyIntegration;
