import { Chip, Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { useCompanyContext } from "../company/companyContext";
import { findCompanyById } from "../company/companyHelpers";
import { LinkToId } from "../table/linkToId";
import SkeletonTableList from "../table/skeletonTableList";

import { CompanyRow } from "../../api/company.type";
import { ReimbursementRow } from "../../api/reimbursement.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { getStatusColor } from "./reimbursementHelpers";

export type ReimbursementAdminTableListProps = {
  reimbursements: ReimbursementRow[] | undefined;
  handleContentClick?: (id: string) => void;
  filterFunction?: (reimbursement: ReimbursementRow, index: number) => boolean;
  sortFunction?: (a: ReimbursementRow, b: ReimbursementRow) => number;
};

function ReimbursementAdminTableList({
  reimbursements,
  filterFunction,
  sortFunction,
}: ReimbursementAdminTableListProps) {
  const { companies } = useCompanyContext();

  function mapReimbursementToRow(reimbursement: ReimbursementRow): {
    id: string;
    company: CompanyRow | null | undefined;
    campaignName: string;
    createdAt: string;
    status: string;
    amount: number;
    phone: string;
    payoutHandle: string;
    aiDetermination: string;
    validSku: string;
    ocrId: string | null;
    type: string;
  } {
    if (!reimbursement) return {} as any;

    const values = {
      createdAt: reimbursement?.created_at
        ? formatTimeStampToHumanReadableShortDateTime(reimbursement?.created_at)
        : "N/A",
      id: reimbursement?.id,
      company:
        reimbursement?.campaign?.company_id && companies && companies.length > 0
          ? findCompanyById(companies, reimbursement.campaign.company_id)
          : null,
      campaignName: reimbursement?.campaign?.name || "N/A",
      status: reimbursement?.status ? reimbursement?.status : "pending",
      amount: reimbursement?.reimbursement_amount
        ? reimbursement?.reimbursement_amount
        : reimbursement?.payment_amount
        ? reimbursement?.payment_amount
        : reimbursement?.receipt_ocr?.raw_total_amount
        ? Number(reimbursement?.receipt_ocr.raw_total_amount)
        : 0,
      phone: reimbursement.lead ? reimbursement.lead.phone : "N/A",
      payoutHandle: reimbursement.payment_account_type
        ? reimbursement.payment_account_type
        : "N/A",
      aiDetermination: reimbursement?.ai_determination
        ? reimbursement?.ai_determination
        : "N/A",
      validSku: reimbursement?.valid_sku ? reimbursement?.valid_sku : "N/A",
      ocrId: reimbursement?.receipt_ocr_id || null,
      type: reimbursement?.type || "N/A",
    };

    return values;
  }

  return (
    <Fragment>
      {reimbursements && reimbursements.length > 0 ? (
        reimbursements
          .sort((a, b) => (sortFunction ? sortFunction(a, b) : 0))
          .filter((reimbursement, index) =>
            filterFunction ? filterFunction(reimbursement, index) : true
          )
          .map((item) => mapReimbursementToRow(item))
          .map(
            (
              {
                id,
                createdAt,
                status,
                amount,
                phone,
                payoutHandle,
                company,
                validSku,
                campaignName,
                ocrId,
                type,
              },
              index
            ) => {
              const isLast = index === reimbursements.length - 1;
              const classes = isLast
                ? "p-2"
                : "p-2 border-b border-blue-gray-50";

              return (
                <tr key={index} className="even:bg-blue-gray-50/50">
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex max-w-xs flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {createdAt}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {campaignName}
                        </Typography>

                        <LinkToId
                          type="reimbursement"
                          id={id}
                          baseUrl="/reimbursement/"
                        />

                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {ocrId && (
                            <LinkToId type="ocr" id={ocrId} baseUrl="/ocr/" />
                          )}
                        </Typography>
                      </div>
                    </div>
                  </td>

                  <td className={classes}>
                    {company?.company_config &&
                    company?.company_config[0] &&
                    company?.company_config[0].logo_image_url ? (
                      <img
                        height={50}
                        width={50}
                        src={company.company_config[0].logo_image_url}
                        alt="logo"
                      />
                    ) : (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {company?.name || "N/A"}
                      </Typography>
                    )}
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {campaignName}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        Type: {type}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="w-max">
                      {status && (
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={status}
                          color={getStatusColor(status)}
                        />
                      )}
                    </div>
                  </td>

                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {parseCentsToDollars(amount)} / {validSku}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal opacity-70"
                      >
                        {payoutHandle}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {phone}
                      </Typography>
                    </div>
                  </td>
                </tr>
              );
            }
          )
      ) : (
        <SkeletonTableList columnCount={5} />
      )}
    </Fragment>
  );
}

export default ReimbursementAdminTableList;
