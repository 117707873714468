import { FlowContextWrapper } from "../components/flow/flowContext";
import AddFlowContainer from "../components/flow/addFlowContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

function AddFlow() {
  mixpanelTrackEvent("Add flow");

  return (
    <FlowContextWrapper>
      <AddFlowContainer />
    </FlowContextWrapper>
  );
}

export default AddFlow;
