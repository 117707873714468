import { SegmentsRow } from "../../api/segments.type";

export const leadAndCampaignSegments = (segments: SegmentsRow[]) => {
  return segments.filter(
    (s) =>
      s &&
      s.query_return_type === "array" &&
      (s.query_return_table_name === "lead" ||
        s.query_return_table_name === "lead_campaign" ||
        s.query_return_table_name === "campaign")
  );
};

export const leadSegments = (segments: SegmentsRow[]) => {
  return segments.filter(
    (s) =>
      s &&
      s.query_return_type === "array" &&
      (s.query_return_table_name === "lead" ||
        s.query_return_table_name === "lead_campaign")
  );
};
