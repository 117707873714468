/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getRecentConversations } from "../../api/conversation.api";
import { getConversations } from "../../api/sms.api";
import { ConversationsOverview, SmsMessage } from "../../api/sms.type";
import { createUserSmsRead, getUserSmsReads } from "../../api/userSmsRead.api";
import { UserSmsReadRow } from "../../api/userSmsRead.type";
import { useUserContext } from "../user/userContext";
import { CreateUserReadRow } from "./MessageOverviewContext.type";
import { RecentConversationMessages } from "../../api/conversation.type";

export const MessageOverviewContextWrapper = (props: any) => {
  const { user } = useUserContext();

  const [activeConversation, setActiveConversation] = useState<SmsMessage[]>();
  const [userMessageReads, setUserMessageReads] = useState<UserSmsReadRow[]>();

  const [page, setPage] = useState<number>(0);

  const [recentConvos, setRecentConvos] = useState<
    RecentConversationMessages[]
  >([]);

  const [unread, setUnread] = useState<number>(0);

  const [totalConversations, setTotalConversations] = useState<number>(0);

  const { data: conversations } = useQuery({
    queryKey: "conversations",
    queryFn: () => getConversations(),
    enabled: user && user.id ? true : false,
  });

  const { mutate: getRecentConvos } = useMutation(getRecentConversations, {
    onSuccess: async (res) => {
      setTotalConversations(res.count);
      const combined = recentConvos.concat(
        res.conversations as RecentConversationMessages[]
      );

      const unique = combined.filter(
        (v, i, a) => a.findIndex((t) => t.leadId === v.leadId) === i
      );
      setRecentConvos(unique);
      setActiveConversation(res.conversations[0].messages as SmsMessage[]);
      return res;
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: getReads, isLoading: getReadLoading } = useMutation(
    getUserSmsReads,
    {
      onSuccess: async (res) => {
        setUserMessageReads(res);
      },
      onError(error: Error) {
        console.log(error);
      },
    }
  );

  const { mutate: addRead } = useMutation(createUserSmsRead, {
    onSuccess: async (res) => {
      getReads();
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (user && user.id) {
      getRecentConvos(page);
      getReads();
    }
  }, [user]);

  useEffect(() => {
    if (page > 0 && totalConversations > recentConvos.length) {
      getRecentConvos(page);
    }
  }, [page]);

  useEffect(() => {
    if (activeConversation && !getReadLoading) {
      addRead({
        smsMessageIds: activeConversation.map((message) => message.id),
        leadId: activeConversation[0].lead_id as string,
      });
    }
  }, [activeConversation]);

  useEffect(() => {
    if (
      userMessageReads &&
      userMessageReads.length > 0 &&
      recentConvos &&
      recentConvos.length > 0
    ) {
      const unread = recentConvos.filter((convo) => {
        const found = userMessageReads.find(
          (read) => read.lead_id === convo.leadId
        );
        return !found || !found.read;
      });
      setUnread(unread.length);
    }
  }, [userMessageReads]);

  const value = {
    conversations,
    recentConvos,
    setActiveConversation,
    activeConversation,
    userMessageReads,
    addRead,
    loading: getReadLoading,
    setPage,
    totalConversations,
    unread,
  };

  return (
    <MessageOverviewContext.Provider value={value}>
      {props.children}
    </MessageOverviewContext.Provider>
  );
};

export const MessageOverviewContext = createContext({
  conversations: {} as ConversationsOverview | undefined,
  recentConvos: [] as RecentConversationMessages[] | undefined,
  setActiveConversation: {} as any,
  activeConversation: [] as SmsMessage[] | undefined,
  userMessageReads: [] as UserSmsReadRow[] | undefined,
  addRead: {} as CreateUserReadRow,
  loading: false,
  setPage: {} as any,
  totalConversations: 0,
  unread: 0,
});

export const useMessageOverviewContext = () =>
  useContext(MessageOverviewContext);
