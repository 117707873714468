import { IconButton, Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { SegmentsRow } from "../../api/segments.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { useSegmentContext } from "./hooks/SegmentContext";
import { DeleteIcon } from "../icons/icons";

function SegmentTableList({
  segments,
  filterFunction,
}: {
  segments: SegmentsRow[] | undefined;
  filterFunction?: (segment: SegmentsRow, index: number) => boolean;
}) {
  const { handleRunSegment, handleRemoveSegment } = useSegmentContext();

  function mapFeedbackToRow(segment: SegmentsRow): {
    id: string;
    name: string;
    description: string;
    query: string;
    createdAt: string;
  } {
    const values = {
      id: segment.id,
      name: segment?.name ? segment?.name : "N/A",
      description: segment.description ? segment.description : "N/A",
      query: segment.query ? segment.query : "N/A",
      createdAt: segment.created_at
        ? formatTimeStampToHumanReadableShortDateTime(segment.created_at)
        : "N/A",
    };

    return values;
  }

  return (
    <Fragment>
      {segments &&
        segments
          .filter((segment, index) =>
            filterFunction ? filterFunction(segment, index) : true
          )
          .map((item) => mapFeedbackToRow(item))
          .map((segment, index) => {
            const { id, name, description, createdAt, query } = segment;
            const isLast = index === segments.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={id}>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {name}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal opacity-70"
                      >
                        {createdAt}
                      </Typography>
                    </div>
                  </div>
                </td>

                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {description}
                  </Typography>
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {query}
                  </Typography>
                </td>
                <td className={classes}>
                  <ButtonSecondary
                    onClick={() => {
                      handleRunSegment(segment);
                    }}
                  >
                    Run segment
                  </ButtonSecondary>
                </td>
                <td className={classes}>
                  <IconButton
                    onClick={() => handleRemoveSegment(id)}
                    variant="text"
                    color="red"
                  >
                    <DeleteIcon className="h-4 w-4" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default SegmentTableList;
