import { Card, CardBody, Typography } from "@material-tailwind/react";
import { ChatBubbleWithWritingIcon } from "../icons/icons";

function FeedbackCard() {
  return (
    <Card className="w-1/2">
      <CardBody>
        <ChatBubbleWithWritingIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Feedback and Chat Data
        </Typography>
        <Typography>
          You can use Plenti's data extraction agents to extract data from the
          chats. To do this create a data schema and assign it to a campaign.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default FeedbackCard;
