import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { CampaignRow } from "../../api/campaign.type";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";

function AddExtractionSchemaCampaignHeader({
  campaign,
  existingSchema,
}: {
  existingSchema: boolean;
  campaign?: CampaignRow | null;
}) {
  return (
    <div className="flex flex-col gap-2">
      <BreadcrumbsFromConfig
        config={[
          {
            name: "Campaign",
            href: `/campaign/${campaign?.id}`,
            active: false,
          },
          {
            name: "Add",
            href: "/agent/extraction/add",
            active: true,
          },
        ]}
      />
      <Typography variant="h3" className="my-4 text-slate-500">
        {existingSchema ? "View/edit" : "Add"} data to extract{" "}
        {campaign?.name ? `for ${campaign?.name}` : ""}
      </Typography>
      <Typography variant="paragraph" className="max-w-2xl">
        Add data to extract from the chats. To do this create a data schema with
        the data you want to extract and assign it to a campaign.
      </Typography>
      <Divider />
    </div>
  );
}

export default AddExtractionSchemaCampaignHeader;
