import { Card, CardBody, Typography } from "@material-tailwind/react";
import { CakeIcon } from "../icons/icons";

function ProductCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <CakeIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Products
        </Typography>
        <Typography>
          Here you can view and manage your products. You can add new products
          or edit existing ones.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default ProductCard;
