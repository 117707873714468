import { useQuery } from "react-query";
import { getImageMetadata } from "../../../api/imageMetadata.api";

export const useLoadImageMetadata = () => {
  const { data: imageMetadata, isLoading } = useQuery({
    queryKey: "imageMetadata",
    queryFn: () => getImageMetadata(),
  });

  return { imageMetadata, isLoading };
};
