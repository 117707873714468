/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getLeadCampaignsByLeadId } from "../../../api/leadCampaign.api";
import { LeadCampaignsWithCampaignAgentAndData } from "../../../api/leadCampaign.type";

export const FabrkCampaignContextWrapper = ({
  leadId,
  children,
}: {
  leadId?: string;
  children: React.ReactNode;
}) => {
  const [activeLeadCampaign, setActiveLeadCampaign] =
    useState<LeadCampaignsWithCampaignAgentAndData | null>();

  const { mutate: getLeadCampaigns, data: leadCampaigns } = useMutation(
    getLeadCampaignsByLeadId,
    {
      onSuccess: async (res) => {
        if (res.length > 0 && !activeLeadCampaign) {
          setActiveLeadCampaign(res[0]);
        }
      },
      onError(error: Error) {},
    }
  );

  useEffect(() => {
    if (leadId) {
      getLeadCampaigns(leadId);
    }
  }, [leadId]);

  const value = { leadCampaigns, activeLeadCampaign };

  return (
    <FabrkCampaignContext.Provider value={value}>
      {children}
    </FabrkCampaignContext.Provider>
  );
};

export const FabrkCampaignContext = createContext({
  leadCampaigns: {} as LeadCampaignsWithCampaignAgentAndData[] | undefined,
  activeLeadCampaign: {} as
    | LeadCampaignsWithCampaignAgentAndData
    | null
    | undefined,
});

export const useFabrkCampaignContext = () => useContext(FabrkCampaignContext);
