import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Button, Checkbox, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { getFileNameFromPath } from "../../utils/stringManipulation";
import { useFileUploadContext } from "../forms/fileUploadContext";
import GenericTable from "../table/GenericTable";
import CsvHeadersDisplay from "./CsvHeadersDisplay";
import LoadingBarContainer from "./LoadingBarContainer";

type BulkLoadingIndicatorProps = {
  initialTime: number;
  label: string;
};

function CsvDataReview({
  children,
  handleConfirmation,
  additionalCheck,
}: {
  children: React.ReactNode;
  handleConfirmation: any;
  additionalCheck: boolean;
}) {
  const { csvUploadResponse, reset } = useFileUploadContext();
  const [headersConfirmed, setHeadersConfirmed] = useState<boolean>(false);
  const [dataConfirmed, setDataConfirmed] = useState<boolean>(false);

  const [bulkLoading, setBulkLoading] = useState<BulkLoadingIndicatorProps[]>(
    []
  );

  return (
    <div className=" flex flex-col gap-10">
      {bulkLoading.map((i) => {
        return (
          <LoadingBarContainer initialTime={i.initialTime} label={i.label} />
        );
      })}
      <div className="flex flex-row gap-2">
        <InformationCircleIcon
          className="h-6 w-6 inline-block mr-2"
          color="green"
        />
        <div>
          <Typography variant="h5">
            Please review the data below. Once confirmed correct, click Upload
            Data
          </Typography>
          <Typography variant="paragraph">
            What happens next? The data will be uploaded to the database, we
            will check for duplicates and update your data. This may take a few
            minutes.
          </Typography>
        </div>
      </div>
      <div className="flex flex-row gap-2 ml-20">
        <Button
          color={csvUploadResponse ? "green" : "deep-purple"}
          disabled={!additionalCheck || !headersConfirmed || !dataConfirmed}
          onClick={(e) => {
            e.preventDefault();

            handleConfirmation({
              path: csvUploadResponse?.path,
              data: csvUploadResponse?.json.data,
              headers: csvUploadResponse?.json.headers,
            });

            setBulkLoading((prevState: any) => [
              ...prevState,
              {
                initialTime: (csvUploadResponse?.json.data.length * 60) / 1000,
                label: getFileNameFromPath(csvUploadResponse?.path || ""),
              },
            ]);
            reset();
          }}
        >
          Upload data
        </Button>
        {csvUploadResponse && (
          <Button color="deep-purple" onClick={reset}>
            Clear
          </Button>
        )}
      </div>
      {children}
      <div className="flex flex-row gap-5 items-start">
        <Checkbox
          color="green"
          crossOrigin={true}
          checked={headersConfirmed}
          onChange={() => setHeadersConfirmed((prevState) => !prevState)}
        />
        <CsvHeadersDisplay data={csvUploadResponse?.json?.headers} />
      </div>

      <div className="max-w-[850px] flex flex-row gap-5 items-start">
        <Checkbox
          color="green"
          crossOrigin={true}
          checked={dataConfirmed}
          onChange={() => setDataConfirmed((prevState) => !prevState)}
        />
        <GenericTable
          type={"The parsed data from the CSV file"}
          structure={csvUploadResponse?.json?.data[0]}
          data={csvUploadResponse?.json?.data}
          rowsPerPage={10}
        />
      </div>
    </div>
  );
}

export default CsvDataReview;
