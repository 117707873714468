import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ExtractionResponseContextWrapper } from "../agents/ExtractionResponseContext";
import PlentiTabs from "../tab/plentiTab";
import ExtractionResponseList from "./ExtractionResponseList";
import FeedbackHeader from "./FeedbackHeader";
import FeedbackList from "./FeedbackList";
import ChatImageContainer from "./ChatImageContainer";

const tabs = [
  {
    label: "Feedback",
    value: "feedback",
  },
  {
    label: "Chat Data",
    value: "chat_responses",
  },
  {
    label: "Images",
    value: "images",
  },
];

function FeedbackContainer() {
  let [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState(
    tab && tabs.find((t) => t.value === tab)
      ? tabs.find((t) => t.value === tab)
      : tabs[0]
  );

  if (!activeTab) {
    return null;
  }

  const { value } = activeTab;

  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <FeedbackHeader />
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {value === "feedback" && <FeedbackList />}
      {value === "chat_responses" && (
        <ExtractionResponseContextWrapper>
          <ExtractionResponseList />
        </ExtractionResponseContextWrapper>
      )}
      {value === "images" && <ChatImageContainer />}
    </div>
  );
}

export default FeedbackContainer;
