import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { useSession } from "@supabase/auth-helpers-react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { useAuthContext } from "../auth/authContext";
import { useCompanyContext } from "../company/companyContext";
import { BarsIcon, UserCircle } from "../icons/icons";
import { useDashboardContext } from "../layout/dashboardContext";
import { useUserContext } from "../user/userContext";

function DashboardHeader({
  setIsHidden,
  isHidden,
}: {
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
  isHidden: boolean;
}) {
  const { selectedCompanyConfig } = useCompanyContext();
  const { handleLogout } = useAuthContext();
  const session = useSession();
  const { user } = useUserContext();
  const { headerItem, isHidden: headerHidden } = useDashboardContext();
  return (
    <div className="sticky top-0 z-10 bg-white border-b w-full ">
      <div className="flex flex-col items-stretch md:flex-row">
        <div className="flex h-16 w-full items-center justify-between ">
          <Link to="/" className="flex shrink-0 items-center gap-4">
            <div className="flex flex-row items-center gap-2 m-2">
              {selectedCompanyConfig &&
                selectedCompanyConfig.logo_image_url && (
                  <img
                    height={50}
                    width={50}
                    src={selectedCompanyConfig.logo_image_url}
                    alt="logo"
                  />
                )}
            </div>
          </Link>
          {headerItem && headerHidden && headerItem}

          {session && (
            <Fragment>
              <div className="flex flex-row items-center gap-2 mr-4">
                <IconButton
                  variant="outlined"
                  className="visible px-6 py-3 md:invisible"
                  onClick={() => setIsHidden(!isHidden)}
                >
                  <BarsIcon className="m-2 h-4 w-4" />
                </IconButton>
                <Menu placement="bottom">
                  <MenuHandler>
                    <Button
                      variant="outlined"
                      className="flex items-center gap-2"
                    >
                      <UserCircle className="h-4 w-4" />
                    </Button>
                  </MenuHandler>
                  <MenuList>
                    {user?.email && <MenuItem>{user?.email}</MenuItem>}
                    <Link to="/account">
                      <MenuItem>Account</MenuItem>
                    </Link>

                    <Link to="/billing">
                      <MenuItem>Billing</MenuItem>
                    </Link>

                    <MenuItem onClick={handleLogout}>Log out</MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;

//
