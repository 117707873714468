/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useState } from "react";

import { PencilIcon } from "../icons/icons";
import { useCampaignContext } from "./campaignContext";

import InfoPopover from "../InfoPopover/infoPopover";
import { useLoadAiFunction } from "../agents/hooks/useLoadAiAgents";
import { filterAgents } from "../campaignAiFunction/agentFilter";
import { useCampaignAiFunctionContext } from "../campaignAiFunction/campaignAiFunctionContext";
import GenericDialog from "../dialog/genericDialog";
import CampaignAgentList from "./CampaignAgentList";
import LoyaltyAgentDialog from "./loyaltyAgentDialog";

function CampaignAgentsContainer() {
  const { activeCampaign } = useCampaignContext();

  const [open, setOpen] = useState(false);

  const { campaignAiFunctions, addFunction, informLeads } =
    useCampaignAiFunctionContext();

  const { agents } = useLoadAiFunction();

  function handleAddAgent(id: string, inform = false) {
    addFunction({
      campaignId: activeCampaign?.id as string,
      aiFunctionId: id,
    });
    if (inform) {
      informLeads({
        campaignId: activeCampaign?.id as string,
        aiFunctionId: id,
      });
    }
  }
  const [aiFunctionId, setAiFunctionId] = useState<string>("");

  function handleOpenAddLoyaltyAgentDialog(aiFunctionId: string) {
    setOpen(true);
    setAiFunctionId(aiFunctionId);
  }

  return (
    <div className="w-full">
      <GenericDialog
        title="Spread the word about your Loyalty Program"
        open={open}
        setOpen={setOpen}
      >
        <LoyaltyAgentDialog
          handleSubmit={(previousLeads) => {
            setOpen(false);
            handleAddAgent(aiFunctionId, previousLeads);
          }}
        />
      </GenericDialog>
      {activeCampaign && (
        <Fragment>
          <div className="relative flex justify-between">
            <div className="relative">
              <Typography variant="h6">Agents</Typography>
              <div className="absolute top-[-12px] right-[-15px]">
                <InfoPopover>
                  <p className="text-slate-700">
                    Agents give campaigns superpowers. These are the agents that
                    are active on this campaign.
                  </p>
                </InfoPopover>
              </div>
            </div>
            <Menu placement="bottom">
              <MenuHandler>
                <Button
                  className="flex items-center gap-2 p-1"
                  variant="outlined"
                  color="gray"
                  size="sm"
                >
                  <PencilIcon className="h=4 w-4" />
                  Add
                </Button>
              </MenuHandler>
              <MenuList>
                {agents
                  ?.filter((a) => filterAgents(a, campaignAiFunctions))
                  .map((agent) => (
                    <MenuItem
                      key={agent.id}
                      onClick={() => {
                        if (agent.name === "Loyalty agent") {
                          handleOpenAddLoyaltyAgentDialog(agent.id);
                        } else {
                          handleAddAgent(agent.id);
                        }
                      }}
                    >
                      {agent.name}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </div>
          <Divider className="my-1" />
          <div className="my-4 grid w-full gap-2">
            <CampaignAgentList />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default CampaignAgentsContainer;
