/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { Progress, Typography } from "@material-tailwind/react";
import { useEffect } from "react";
import {
  TimerContextContextWrapper,
  useTimerContextContext,
} from "./TimerContext";

function _LoadingBarContainer({
  initialTime,
  label,
}: {
  initialTime: number;
  label?: string;
}) {
  const { seconds, setSeconds } = useTimerContextContext();

  useEffect(() => {
    if (initialTime) {
      setSeconds(initialTime);
    }
  }, [initialTime]);

  const progressPercentage = ((initialTime - seconds) / initialTime) * 100;

  return (
    <div>
      {label && seconds > 0 && (
        <Typography variant="paragraph">{label}</Typography>
      )}
      {seconds > 0 ? (
        <Progress value={progressPercentage} color="green" />
      ) : null}
    </div>
  );
}

const LoadingBarContainer = ({
  initialTime,
  label,
}: {
  initialTime: number;
  label?: string;
}) => {
  return (
    <TimerContextContextWrapper initialTime={initialTime}>
      <_LoadingBarContainer initialTime={initialTime} label={label} />
    </TimerContextContextWrapper>
  );
};

export default LoadingBarContainer;
