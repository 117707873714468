import { IconButton, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";

import { useCompanyContext } from "../company/companyContext";
import { findCompanyById } from "../company/companyHelpers";
import ConfirmDialog from "../dialog/confirmDialog";
import {
  DeleteIcon,
  EditIcon,
  GlobalIcon,
  MegaphoneIcon,
  ServerStackIcon,
} from "../icons/icons";
import { usePromptContext } from "./promptContext";

import { CompanyRow } from "../../api/company.type";
import { PromptRow } from "../../api/prompt.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";

function PromptTableList({
  prompts,
  handleContentClick,
  filterFunction,
}: {
  prompts: PromptRow[] | undefined;
  handleContentClick: (id: string) => void;
  filterFunction?: (prompt: PromptRow, index: number) => boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { companies } = useCompanyContext();
  const { setPromptToDelete, promptToDelete, deletePrompt } =
    usePromptContext();

  function handleDeleteClick(prompt: Partial<PromptRow>) {
    setPromptToDelete(prompt);
    setIsOpen(true);
  }

  function mapPromptsToRow(prompt: PromptRow): {
    id: string;
    name: string;
    createdAt: string;
    text: string;
    version: number;
    type: string;
    company: CompanyRow | null | undefined;
    campaignName: string | null | undefined;
  } {
    const values = {
      id: prompt.id,
      name: prompt?.name ? prompt?.name : "N/A",
      createdAt: prompt.created_at
        ? formatTimeStampToHumanReadableShortDateTime(prompt.created_at)
        : "N/A",
      text: prompt.text ? prompt.text.slice(0, 25) + "..." : "N/A",
      version: prompt?.version ? prompt?.version : 0,
      type: prompt?.type ? prompt?.type : "N/A",
      company:
        prompt.company_id && companies && companies.length > 0
          ? findCompanyById(companies, prompt.company_id)
          : null,
      campaignName:
        prompt.campaign && prompt.campaign[0] ? prompt.campaign[0].name : null,
    };

    return values;
  }

  return (
    <Fragment>
      <ConfirmDialog
        isOpen={isOpen}
        handleOpen={() => setIsOpen(!isOpen)}
        title="Confirm delete prompt"
        body={`Delete prompt: ${promptToDelete?.name}?`}
        handleConfirm={() => {
          deletePrompt({
            id: promptToDelete?.id ? promptToDelete.id : "",
          });
          setIsOpen(!isOpen);
        }}
      />
      {prompts &&
        prompts.length > 0 &&
        prompts
          .filter((lead, index) =>
            filterFunction ? filterFunction(lead, index) : true
          )
          .map((item) => mapPromptsToRow(item))
          .map((prompt, index) => {
            const {
              id,
              name,
              createdAt,
              version,
              company,
              campaignName,
              type,
            } = prompt;
            const isLast = index === prompts.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {name}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {createdAt}
                    </Typography>
                  </div>
                </td>

                <td className={classes}>
                  {company?.company_config &&
                  company?.company_config[0] &&
                  company?.company_config[0].logo_image_url ? (
                    <img
                      height={50}
                      width={50}
                      src={company.company_config[0].logo_image_url}
                      alt="logo"
                    />
                  ) : (
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {company?.name || "N/A"}
                    </Typography>
                  )}
                </td>
                <td className={classes}>
                  {type === "system" ? (
                    <ServerStackIcon className="h-6 w-6" />
                  ) : type === "company" ? (
                    <GlobalIcon className="h-6 w-6" />
                  ) : (
                    <MegaphoneIcon className="h-6 w-6" />
                  )}
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {campaignName}
                  </Typography>
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {version}
                  </Typography>
                </td>

                <td className={classes}>
                  <IconButton
                    onClick={() => handleContentClick(id)}
                    variant="text"
                    color="blue-gray"
                  >
                    <EditIcon className="h-4 w-4" />
                  </IconButton>
                </td>
                <td className={classes}>
                  <IconButton
                    onClick={() => handleDeleteClick(prompt)}
                    variant="text"
                    color="red"
                  >
                    <DeleteIcon className="h-4 w-4" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default PromptTableList;
