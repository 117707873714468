import { Breadcrumbs } from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";

type BreadcrumbsConfig = {
  name: string;
  href: string;
  active: boolean;
};

function updateBreadcrumbs(
  breadcrumbs: BreadcrumbsConfig[] | null,
  previousLocation: BreadcrumbsConfig | null
): BreadcrumbsConfig[] {
  // Return the original breadcrumbs if previousLocation is null
  if (!previousLocation && breadcrumbs) {
    return breadcrumbs;
  }

  if (breadcrumbs && previousLocation) {
    // Find the index of the active breadcrumb
    const activeIndex = breadcrumbs.findIndex(
      (breadcrumb) => breadcrumb.active
    );

    // If there's no active breadcrumb, return an array with only the previousLocation
    if (activeIndex === -1) {
      return [
        {
          name: previousLocation.name,
          href: previousLocation.href,
          active: false,
        },
      ];
    }

    // Replace everything before the active breadcrumb with the previousLocation
    const updatedBreadcrumbs = [
      {
        name: previousLocation.name,
        href: previousLocation.href,
        active: false,
      },
      ...breadcrumbs.slice(activeIndex),
    ];

    return updatedBreadcrumbs;
  } else if (!breadcrumbs && previousLocation) {
    return [
      {
        name: previousLocation.name,
        href: previousLocation.href,
        active: false,
      },
    ];
  } else {
    return [];
  }
}

export type BreadcrumbsProps = {
  config: BreadcrumbsConfig[] | null;
};

function BreadcrumbsFromConfig({ config }: BreadcrumbsProps) {
  const location = useLocation();

  const updateConfig = updateBreadcrumbs(config, location.state);

  return (
    <Breadcrumbs>
      {updateConfig.map((item, index) => {
        const { active } = item;
        return (
          <div key={index} className={active ? "" : "opacity-60"}>
            <Link to={item.href}>{item.name}</Link>
          </div>
        );
      })}
    </Breadcrumbs>
  );
}

export default BreadcrumbsFromConfig;
