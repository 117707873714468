/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";

import { ExtractionResponseRow } from "../../api/extractionResponse.type";
import { useExtractionResponseContext } from "../agents/ExtractionResponseContext";
import {
  handleFilterValue,
  setHeadFilters,
} from "../table/setTableHeadWithFilters";
import ResponseTableList from "./ResponseTableList";
import { mapResponsesToCsvData } from "./hooks/mapResponsesToCsv";
import { convertDateToRawDate } from "../../utils/parseTimeStamp";

function ExtractionResponseList() {
  const { responses } = useExtractionResponseContext();

  const [filteredResponse, setFilteredResponse] = useState<
    ExtractionResponseRow[]
  >([]);
  const TABLE_HEAD = [
    { label: "Created at" },
    { label: "Campaign", columnName: "campaign.name" },
    { label: "Lead ID", columnName: "lead_id" },
    { label: "Schema Name", columnName: "extraction_schema.name" },
    { label: "Question ID", columnName: "question_id" },
    { label: "Question" },
    { label: "Answer" },
  ];
  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  function baseFilter() {
    return (response: ExtractionResponseRow, index: number) => index < 10;
  }
  const [responseFilterFunction, setResponseFilterFunction] =
    useState<(lead: ExtractionResponseRow, index: number) => boolean>(
      baseFilter
    );

  useEffect(() => {
    setHeadFilters(
      responses,
      filteredResponse,
      setFilteredResponse,
      setTableHead,
      TABLE_HEAD
    );
  }, [responses]);

  const downloadData = mapResponsesToCsvData(filteredResponse);

  return (
    <div className="flex flex-col gap-4">
      {filteredResponse && filteredResponse.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          downloadData={{
            headers: downloadData.headers,
            csvData: downloadData.csvData,
            fileName: `responses-${convertDateToRawDate(new Date())}.csv`,
          }}
          type="response"
          rowCount={filteredResponse ? filteredResponse?.length : 0}
          rowsPerPage={10}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              responses,
              setFilteredResponse,
              setResponseFilterFunction,
              baseFilter
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setResponseFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setResponseFilterFunction,
              advancePage: false,
            })
          }
        >
          <ResponseTableList
            response={responses}
            filterFunction={responseFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ExtractionResponseList;
