import { useState } from "react";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@tremor/react";
import PaymentHistory from "./PaymentHistory";
import AddFunds from "./addFunds";
import AutoRecharge from "./autoRecharge";
import BillingHeader from "./billingHeader";
import BillingOverview from "./billingOverview";
import PaymentMethods from "./paymentMethods";

function BillingContainer() {
  const [index, setIndex] = useState(0);
  const [isAddPayment, setIsAddPayment] = useState(false);

  function handleAddPayment() {
    setIndex(2);
    setIsAddPayment(!isAddPayment);
  }

  return (
    <div className="w-full flex flex-col p-5 gap-5">
      <BillingHeader handleAddPayment={handleAddPayment} />
      <TabGroup index={index}>
        <TabList className="mt-8">
          <Tab
            onClick={() => {
              setIndex(0);
              setIsAddPayment(false);
            }}
          >
            Billing overview
          </Tab>
          <Tab
            onClick={() => {
              setIndex(1);
              setIsAddPayment(false);
            }}
          >
            Auto recharge
          </Tab>
          <Tab onClick={() => setIndex(2)}>Payment methods</Tab>
          <Tab onClick={() => setIndex(3)}>Add funds</Tab>
          <Tab onClick={() => setIndex(4)}>Payments history</Tab>
        </TabList>
        <TabPanels className="mt-5">
          <TabPanel>
            <BillingOverview setIndex={setIndex} />
          </TabPanel>
          <TabPanel>
            <AutoRecharge setIndex={setIndex} />
          </TabPanel>
          <TabPanel>
            <PaymentMethods
              setIsAddPayment={setIsAddPayment}
              isAddPayment={isAddPayment}
              setIndex={setIndex}
            />
          </TabPanel>
          <TabPanel>
            <AddFunds handleAddPayment={handleAddPayment} setIndex={setIndex} />
          </TabPanel>
          <TabPanel>
            <PaymentHistory />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}

export default BillingContainer;
