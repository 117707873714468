import React from "react";

const Scroll = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <div style={{ overflowY: "scroll", height: "40vh" }}>{props.children}</div>
  );
};

export default Scroll;
