import { CampaignAiFunctionRow } from "../../../api/campaignAiFunction.type";
import { LeadCampaignsWithCampaignAgentAndData } from "../../../api/leadCampaign.type";
import AgentDetails from "./AgentDetails";

function FabrkAgentMenuDetails({
  details,
  activeLeadCampaign,
}: {
  details?: CampaignAiFunctionRow[];
  activeLeadCampaign?: LeadCampaignsWithCampaignAgentAndData | null;
}) {
  return (
    <div>
      {details?.map((detail, idx) => {
        let files;
        files = activeLeadCampaign?.files?.filter(
          (file) =>
            file.ai_function_id ===
            details?.find((detail) => detail.ai_function.id)?.ai_function_id
        );
        return <AgentDetails detail={detail} idx={idx} files={files} />;
      })}
    </div>
  );
}

export default FabrkAgentMenuDetails;
