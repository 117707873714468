import { CustomerBalanceDailyRollupByPeriod } from "../../api/customerBalance.type";

export function mapDailyRollup(
  rollup: CustomerBalanceDailyRollupByPeriod[] | undefined
) {
  if (!rollup || rollup.length === 0) {
    return [];
  }

  return rollup
    .sort((a, b) => {
      return a.day - b.day;
    })
    .map((u) => {
      return {
        day: u.day ? u.day : "",
        beginning_balance: u.beginning_balance ? u.beginning_balance / 100 : 0,
        ending_balance: u.ending_balance ? u.ending_balance / 100 : 0,
        sms_contribution: u.total_sms ? u.total_sms / 100 : 0,
        reimbursement_contribution: u.total_reimbursement
          ? u.total_reimbursement / 100
          : 0,
        payment_contribution: u.total_payment ? u.total_payment / 100 : 0,
        sms_count: u.sms_count,
        reimbursement_count: u.reimbursement_count,
      };
    });
}
