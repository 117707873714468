import { DailyUsageRow } from "../../api/dailyUsage.type";
import { ReimbursementUsageRow } from "../../api/usage.type";
import { LeadCampaignCounts } from "./analyticsContext.types";

export const smsCost = 8;
export const smsPlentiRevenue = 3;
export const reimbursementPlentiRevenue = 70;

export function getSmsRevenue(dailyUsage: DailyUsageRow[] | undefined) {
  if (!dailyUsage) return 0;
  let dailyUsageTally = 0;
  dailyUsage?.forEach((usage) => {
    const smsCount = usage.sms_balance / smsCost;
    const smsRevenue = smsCount * smsPlentiRevenue;
    dailyUsageTally += smsRevenue;
  });
  return dailyUsageTally;
}

export function getReimbursementRevenue(
  reimbursementUsage: ReimbursementUsageRow[] | undefined
) {
  if (!reimbursementUsage) return 0;
  let reimbursementRevenue = 0;
  reimbursementUsage?.forEach((reimbursement) => {
    reimbursementRevenue += reimbursement.plenti_cost;
  });
  return reimbursementRevenue;
}

export function flattenLeadCampaignCounts(
  leadCampaignCounts: LeadCampaignCounts | undefined,
  startDate: Date | null,
  stopDate: Date | null,
  campaignName?: string
  // @ts-ignore
): Array<{ day: string; [campaignName: string]: number }> {
  if (!leadCampaignCounts) return [];

  let flattenedData: {
    [day: string]: { day: any; [campaignName: string]: number };
  } = {};

  for (const key in leadCampaignCounts) {
    if (leadCampaignCounts.hasOwnProperty(key)) {
      leadCampaignCounts[key].forEach(
        ({ day, campaign_name, lead_campaign_count }) => {
          if (campaignName && campaignName !== campaign_name) return;
          if (startDate && stopDate) {
            if (
              new Date(day) < new Date(startDate) ||
              new Date(day) > new Date(stopDate)
            ) {
              return;
            }
          }
          if (!flattenedData[day]) {
            // @ts-ignore
            flattenedData[day] = { day };
          }

          if (!campaign_name) {
            campaign_name = "All";
          }

          flattenedData[day][campaign_name] =
            (flattenedData[day][campaign_name] || 0) + lead_campaign_count;
        }
      );
    }
  }

  return Object.values(flattenedData);
}

export function countTotalForCampaign(
  campaignDataArray: LeadCampaignCounts[],
  campaignName: string
): number {
  if (!campaignDataArray) return 0;
  let total = 0;

  for (const data of campaignDataArray) {
    if (data[campaignName] !== undefined) {
      total += Number(data[campaignName]);
    } else if (data["Total"] !== undefined) {
      total += Number(data["Total"]);
    }
  }

  return total;
}
