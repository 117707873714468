import { Button } from '@material-tailwind/react';

function LocationActionButtons({
  classes,
  id,
  handleMarkCorrect,
}: {
  classes: string;
  id: string;
  handleMarkCorrect: (id: string) => void;
}) {
  return (
    <td className={classes}>
      <div className='flex gap-2'>
        <Button
          onClick={() => handleMarkCorrect(id)}
          size='sm'
          color='green'
          fullWidth={false}
        >
          Mark Correct
        </Button>
      </div>
    </td>
  );
}

export default LocationActionButtons;
