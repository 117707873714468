import { Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

function LoginFooterLink() {
  const navigate = useNavigate();

  return (
    <Typography variant="small" color="gray">
      Have an account?{" "}
      <span
        style={{
          color: "#3B82F6",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Login
      </span>
    </Typography>
  );
}

export default LoginFooterLink;
