import { Card, CardBody, Typography } from "@material-tailwind/react";
import { SegmentRunResponse } from "../../api/segments.type";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import GenericTable from "../table/GenericTable";
import DataDisplay from "./DataDisplay";

function SegmentResponseTable({
  segmentResponse,
  loading,
}: {
  segmentResponse: SegmentRunResponse;
  loading: boolean;
}) {
  return (
    <div>
      {segmentResponse?.segment?.query_return_type === "string" && !loading && (
        <Card className="mt-6 w-96 mx-auto">
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Run Response
            </Typography>
            <Typography variant="small" className="mb-4">
              {segmentResponse?.segment?.name}
            </Typography>
            <DataDisplay dataArray={segmentResponse?.runResponse} />
          </CardBody>
        </Card>
      )}
      {!loading &&
        segmentResponse?.segment?.query_return_type === "array" &&
        segmentResponse?.runResponse &&
        segmentResponse?.runResponse.length > 0 && (
          <div className="max-w-[850px]">
            <GenericTable
              type={
                segmentResponse?.segment?.query_return_table_name
                  ? snakeCaseToTitleCase(
                      segmentResponse?.segment?.query_return_table_name
                    )
                  : segmentResponse.segment.name
              }
              structure={segmentResponse?.runResponse[0]}
              data={segmentResponse?.runResponse}
              rowsPerPage={10}
            />
          </div>
        )}
    </div>
  );
}

export default SegmentResponseTable;
