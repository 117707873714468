import { useQuery } from "react-query";
import { getMergeTags } from "../../../api/mergeTag.api";

export const useLoadMergeTags = () => {
  const { data: mergeTags, isLoading } = useQuery({
    queryKey: "margeTags",
    queryFn: () => getMergeTags(),
  });

  return { mergeTags, isLoading };
};
