import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export const DashboardContextWrapper = (props: any) => {
  const [headerItem, setHeaderItem] = useState<ReactElement | null>(null);

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check if the page has been scrolled (you can adjust the threshold as needed)
      const scrollThreshold = 100; // Adjust this value as needed

      if (window.scrollY > scrollThreshold) {
        setIsHidden(true);
      } else if (window.scrollY < scrollThreshold) {
        setIsHidden(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const value = {
    headerItem,
    setHeaderItem,
    isHidden,
  };

  return (
    <DashboardContext.Provider value={value}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export const DashboardContext = createContext({
  headerItem: null as ReactElement | null,
  setHeaderItem: (item: ReactElement | null) => {},
  isHidden: false,
});

export const useDashboardContext = () => useContext(DashboardContext);
