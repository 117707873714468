import ConversationFormContainer from "./ConversationFormContainer";
import ConversationList from "./ConversationList";

function ConversationListContainer() {
  return (
    <div className="grid grid-cols-3">
      <ConversationList />
      <div className="col-span-2">
        <ConversationFormContainer />
      </div>
    </div>
  );
}

export default ConversationListContainer;
