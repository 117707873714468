import DivCurve from "../try/divCurve";
import { useTryContext } from "../try/tryContext";
import TryHeroImage from "../try/tryHeroImage";
import TryLogo from "../try/tryLogo";

function TryHeader(props: { children: React.ReactNode }) {
  const { companyConfig, company, forDemo } = useTryContext();
  return (
    <div
      style={{
        backgroundColor: companyConfig?.color_one || "#fff",
      }}
    >
      <TryLogo company={company} companyConfig={companyConfig} />
      <div
        className={
          forDemo
            ? "flex flex-col-reverse justify-center"
            : "flex flex-col-reverse justify-center md:flex-row md:justify-evenly md:gap-8"
        }
      >
        {props.children}
        <TryHeroImage />
      </div>
      <DivCurve smallScreen={false} />
    </div>
  );
}

export default TryHeader;
