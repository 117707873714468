import { Fragment } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { PromptRow, PromptUpdate } from "../../api/prompt.type";
import { PlentiInput } from "../forms/plentiInput";
import { PlentiTextArea } from "../forms/plentiTextArea";
import ValueWithLabel from "../forms/valueWithLabel";
import CompanyPromptDisplay from "./CompanyPromptDisplay";
import { emptyCompanyPrompt, promptToForm } from "./promptContext.types";

function CompanyPromptDetails({
  activePrompt,
  isEdit,
  register,
  errors,
}: {
  activePrompt: PromptRow;
  isEdit: boolean;
  register: UseFormRegister<PromptUpdate>;
  errors?: FieldErrors<PromptUpdate>;
}) {
  return (
    <div>
      <CompanyPromptDisplay prompt={activePrompt} />
      <div className="my-4 grid grid-cols-2 w-full gap-10">
        {promptToForm(activePrompt as PromptUpdate, emptyCompanyPrompt).map(
          (formValue, index) => (
            <div
              key={index}
              className={formValue.isTextArea ? "col-span-2" : "col-span-1"}
            >
              {!isEdit ? (
                <ValueWithLabel
                  label={formValue.label}
                  value={formValue.value}
                />
              ) : (
                <Fragment>
                  {!formValue.isTextArea ? (
                    <PlentiInput
                      key={index}
                      register={register}
                      errors={errors}
                      label={formValue.label}
                      name={formValue.name}
                      required={false}
                    />
                  ) : (
                    <PlentiTextArea
                      key={index}
                      register={register}
                      errors={errors}
                      label={formValue.label}
                      name={formValue.name}
                      required={false}
                    />
                  )}
                </Fragment>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default CompanyPromptDetails;
