import { MultiSelect, MultiSelectItem } from "@tremor/react";

import { CampaignRow } from "../../api/campaign.type";
import { Fragment } from "react";
import { Typography } from "@material-tailwind/react";

function MultiCampaignSelect({
  campaigns,
  setSelectedCampaigns,
  selectedCampaigns,
}: {
  campaigns: CampaignRow[] | undefined;
  setSelectedCampaigns: (campaign: CampaignRow[]) => void;
  selectedCampaigns: CampaignRow[];
}) {
  return (
    <div>
      {campaigns && campaigns.length > 0 && (
        <div>
          <Typography variant="h5" className="mb-4 text-slate-500">
            Campaigns -{" "}
            {selectedCampaigns.find((campaign) => campaign.id === "all")
              ? selectedCampaigns.length - 1
              : selectedCampaigns.length}{" "}
            selected
          </Typography>
          <MultiSelect
            placeholder="Select Campaigns"
            value={selectedCampaigns.map((campaign) => campaign.id)}
            onValueChange={(selectedIds: any) => {
              if (selectedIds.includes("all")) {
                if (campaigns?.length === selectedCampaigns.length) {
                  setSelectedCampaigns([]);
                  return;
                } else {
                  setSelectedCampaigns(
                    //@ts-ignore
                    campaigns.concat({
                      id: "all",
                    }) || []
                  );
                  return;
                }
              } else {
                if (selectedCampaigns.length >= campaigns?.length) {
                  setSelectedCampaigns([]);
                  return;
                }
              }

              // Map the selected IDs back to the campaign objects
              const updatedSelectedCampaigns = campaigns?.filter(
                (c: CampaignRow) => selectedIds.includes(c.id)
              );

              setSelectedCampaigns(updatedSelectedCampaigns || []);
            }}
          >
            <Fragment>
              {
                <MultiSelectItem
                  value="all"
                  defaultChecked={
                    selectedCampaigns.length === campaigns?.length
                  }
                  aria-checked={selectedCampaigns.length === campaigns?.length}
                >
                  All
                </MultiSelectItem>
              }
              {campaigns &&
                campaigns?.map((campaign: CampaignRow) => {
                  return (
                    <MultiSelectItem key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </MultiSelectItem>
                  );
                })}
            </Fragment>
          </MultiSelect>
        </div>
      )}
    </div>
  );
}

export default MultiCampaignSelect;
