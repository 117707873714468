import AnalyticsLocationTableList from "../analytics/analyticsLocationTableList";
import { getTotalCounts } from "../analytics/locationChart";
import TableWithSearch from "../table/tableWithSearch";

import { LocationCount } from "../../api/location.type";

const TABLE_HEAD = [
  { label: "Count" },
  { label: "Name" },
  { label: "Address" },
  { label: "City" },
  { label: "State" },
];

function LocationTable({
  locationCounts,
}: {
  locationCounts: LocationCount[];
}) {
  return (
    <TableWithSearch
      tableHead={TABLE_HEAD}
      type="Locations"
      rowCount={getTotalCounts(locationCounts)}
      rowsPerPage={10}
    >
      <AnalyticsLocationTableList locations={locationCounts} />
    </TableWithSearch>
  );
}

export default LocationTable;
