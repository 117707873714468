import { ApiEndpoints } from "../api/apiEndpoints";
import { SmsMessage } from "../api/sms.type";

export function getMessagesByCampaign(
  campaign_id: string
): Promise<SmsMessage[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.sms +
    "/message-tags/" +
    campaign_id;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetch(request.url, request)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((res: { data: SmsMessage[] }) => {
      const { data } = res;
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
