import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReimbursementChartByCampaign } from "../analytics/reimbursementChart/containers/ReimbursementChartByCampaign";
import { ReimbursementChartByCohort } from "../analytics/reimbursementChart/containers/ReimbursementChartByCohort";
import { ReimbursementChartByMonth } from "../analytics/reimbursementChart/containers/ReimbursementChartByMonth";
import PlentiTabs from "../tab/plentiTab";
import { useUserContext } from "../user/userContext";
import ReimbursementCard from "./ReimbursementCard";
import ReimbursementsAdminDashboard from "./reimbursementsAdminDashboard";
import ReimbursementsDashboard from "./reimbursementsDashboard";

const tabs = [
  {
    label: "Overview",
    value: "overview",
    explanation:
      "This is your overview of reimbursements. You can see the total amount of reimbursements, total paid, and campaign specific reimbursements.",
  },
  {
    label: "Campaigns",
    value: "campaigns",
    explanation: "This is your overview of campaign specific reimbursements.",
  },
  {
    label: "Cohort",
    value: "cohort",
    explanation:
      "This is your overview of reimbursements by lead cohort. This is the number of leads that have an reimbursement per day. For example, for the leads that sign up on day one, what percent of them have created an reimbursement. This is a good way to see how your reimbursements are performing over time (and unrelated to lead growth).",
  },
  {
    label: "List",
    value: "list",
  },
];

function ReimbursementContainer() {
  const { user } = useUserContext();
  const [activeTab, setActiveTab] = useState(
    user && user?.type === "admin" ? tabs[3] : tabs[0]
  );

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab) {
      const active = tabs.find((t) => t.value === tab);
      if (active) {
        setActiveTab(active);
      }
    }
  }, [tab]);

  return (
    <div className="p-5 flex flex-col p-5 gap-10">
      <ReimbursementCard explanation={activeTab?.explanation} />
      <PlentiTabs
        tabConfig={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab?.value === "overview" && <ReimbursementChartByMonth />}

      {activeTab?.value === "campaigns" && <ReimbursementChartByCampaign />}
      {activeTab?.value === "cohort" && <ReimbursementChartByCohort />}
      {activeTab?.value === "list" && (
        <div>
          {user && user?.type === "admin" ? (
            <ReimbursementsAdminDashboard />
          ) : (
            <ReimbursementsDashboard />
          )}
        </div>
      )}
    </div>
  );
}

export default ReimbursementContainer;
