import { Route, Routes } from "react-router-dom";
import "./styles/globals.css";

import Register from "./components/auth/RegisterPage";
import SendPasswordResetPage from "./components/auth/SendPasswordResetPage";
import SignUp from "./components/auth/SignUpPage";
import GoogleTagManager from "./components/googleTagManager/googleTagManager";
import LayoutAccount from "./components/layout/DashboardLayout";
import { DashboardContextWrapper } from "./components/layout/dashboardContext";
import Product from "./routes/Product";
import Segments from "./routes/Segments";
import Account from "./routes/account";
import AddCampaign from "./routes/addCampaign";
import AddCompany from "./routes/addCompany";
import AddExtractionSchemaCampaign from "./routes/addExtractionSchemaCampaign";
import AddFlow from "./routes/addFlow";
import AddProduct from "./routes/addProduct";
import AddPrompt from "./routes/addPrompt";
import AddSmsContent from "./routes/addSmsContent";
import Admin from "./routes/admin";
import Agents from "./routes/agents";
import Analytics from "./routes/analytics";
import Billing from "./routes/billing";
import Campaign from "./routes/campaign";
import Company from "./routes/company";
import CampaignDemo from "./routes/demo";
import Feedback from "./routes/feedback";
import Flow from "./routes/flow";
import Leads from "./routes/lead";
import Locations from "./routes/locations";
import Ocr from "./routes/ocr";
import Prompt from "./routes/prompts";
import Reimbursements from "./routes/reimbursements";
import { ResetPasswordRoute } from "./routes/resetPassword";
import Root from "./routes/root";
import SmsContent from "./routes/smsContent";
import ViewEmail from "./routes/viewAdminEmail";
import ViewAgent from "./routes/viewAgent";
import ViewCampaign from "./routes/viewCampaign";
import ViewCompany from "./routes/viewCompany";
import ViewLead from "./routes/viewLead";
import ViewLeadChat from "./routes/viewLeadChat";
import ViewOcr from "./routes/viewOcr";
import ViewPrompt from "./routes/viewPrompt";
import ViewReimbursement from "./routes/viewReimbursement";
import ViewSmsContent from "./routes/viewSmsContent";
import RegisterThankYou from "./components/auth/RegisterThankYouPage";
import LocationsBulkUpload from "./routes/locationsBulkUpload";
import LeadBulkContainer from "./components/lead/LeadBulkUploadContainer";

import Integrations from "./routes/Integrations";
import AddAgent from "./routes/addAgent";
import AddAgentType from "./routes/addAgentType";
import Fabrk from "./components/fabrk/FabrkContainer";
import ViewImage from "./routes/ViewImage";

function App() {
  return (
    <DashboardContextWrapper>
      <LayoutAccount>
        <div className="App">
          <Routes>
            <Route path="/" element={<Root />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/thank-you" element={<RegisterThankYou />} />
            <Route path="/password-reset" element={<ResetPasswordRoute />} />
            <Route
              path="/send-password-reset-email"
              element={<SendPasswordResetPage />}
            />

            <Route path="/reimbursements" element={<Reimbursements />} />
            <Route path="/reimbursement/*">
              <Route path=":id" element={<ViewReimbursement />}></Route>
            </Route>
            <Route path="/account" element={<Account />} />
            <Route path="/fabrk" element={<Fabrk />} />

            <Route path="/analytics" element={<Analytics />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/agents" element={<Agents />} />
            <Route
              path="/agent/extraction/add"
              element={<AddExtractionSchemaCampaign />}
            />
            <Route path="/agent/*">
              <Route path=":slug" element={<ViewAgent />}></Route>
            </Route>
            <Route path="/agent/add" element={<AddAgent />} />
            <Route path="/agent/add/*">
              <Route path=":type" element={<AddAgentType />} />
            </Route>

            <Route path="/campaign" element={<Campaign />} />
            <Route path="/campaign/add" element={<AddCampaign />} />

            <Route path="/campaign/*">
              <Route path=":id" element={<ViewCampaign />}></Route>
              <Route path=":id/demo" element={<CampaignDemo />} />
            </Route>
            <Route path="/integrations/*">
              <Route path=":name" element={<Integrations />} />
            </Route>
            <Route path="/image/*">
              <Route path=":id" element={<ViewImage />}></Route>
            </Route>

            <Route path="/company" element={<Company />} />
            <Route path="/company/*">
              <Route path=":id" element={<ViewCompany />}></Route>
            </Route>
            <Route path="/company/add" element={<AddCompany />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/lead/*">
              <Route path=":id" element={<ViewLead />} />
            </Route>
            <Route path="/lead/chat/*">
              <Route path=":id" element={<ViewLeadChat />} />
            </Route>
            <Route path="/leads/bulk-upload" element={<LeadBulkContainer />} />
            <Route path="/locations" element={<Locations />} />
            <Route
              path="/locations/bulk-upload"
              element={<LocationsBulkUpload />}
            />
            <Route path="/ocr" element={<Ocr />} />
            <Route path="/ocr/*">
              <Route path=":id" element={<ViewOcr />} />
            </Route>
            <Route path="/prompt" element={<Prompt />} />
            <Route path="/content" element={<SmsContent />} />
            <Route path="/content/sms/*">
              <Route path=":id" element={<ViewSmsContent />} />
            </Route>
            <Route path="/content/email/*">
              <Route path=":id" element={<ViewEmail />} />
            </Route>
            <Route path="/content/add" element={<AddSmsContent />} />

            <Route path="/content/sms/add" element={<AddSmsContent />} />
            <Route path="/content/email/add" element={<ViewEmail />} />

            <Route path="/prompt/add" element={<AddPrompt />} />
            <Route path="/prompt/*">
              <Route path=":id" element={<ViewPrompt />} />
            </Route>
            <Route path="/segments" element={<Segments />} />

            <Route path="/segments/add" element={<AddPrompt />} />
            <Route path="/segments/*">
              <Route path=":id" element={<ViewPrompt />} />
            </Route>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/email" element={<ViewEmail />} />
            <Route path="/admin/email/*">
              <Route path=":id" element={<ViewEmail />} />
            </Route>

            <Route path="/flow" element={<Flow />} />

            <Route path="/flow/add" element={<AddFlow />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product/add" element={<AddProduct />} />
          </Routes>
          <GoogleTagManager />
        </div>
      </LayoutAccount>
    </DashboardContextWrapper>
  );
}

export default App;
