import { Fragment, useRef } from "react";

import ChatHistoryItem, { LoadingMessage } from "../lead/chatItem";
import { SpeedDialForChat } from "../speedDial/speedDialForChat";

import { LeadWithRelations } from "../../api/lead.type";
import { SmsMessage } from "../../api/sms.type";
import { useFileUploadContext } from "../forms/fileUploadContext";
import FileUploadInput from "../forms/fileUploadInput";
import ChatInput from "./ChatInput";
import { ChatUserType } from "../../api/leadContext.types";

export type ChatFormProps = {
  handleSubmitMessage: (message: string) => void;
  loading: boolean;
  messages?: Partial<SmsMessage>[];
  activeLead: LeadWithRelations | null;
  showSystemMessages?: boolean;
  showChatInput?: boolean;
};

function ChatForm({
  activeLead,
  handleSubmitMessage,
  messages,
  loading,
  showSystemMessages = false,
  showChatInput = true,
}: ChatFormProps) {
  const { hiddenFileInput, handleChange } = useFileUploadContext();

  const messagesEndRef = useRef(null);

  return (
    <div data-cy="chat-form">
      <div className="relative h-[35rem] w-full overflow-y-auto rounded-2xl p-6">
        {messages &&
          messages.length > 0 &&
          messages
            .filter((m) => {
              if (
                showSystemMessages ||
                m.role === ChatUserType.user ||
                m.role === ChatUserType.assistant
              ) {
                return true;
              } else {
                return false;
              }
            })
            .map((item: Partial<SmsMessage>, index: any) => {
              return (
                <Fragment key={index}>
                  <ChatHistoryItem
                    message={item}
                    messagesEndRef={messagesEndRef}
                  />
                </Fragment>
              );
            })}
        {loading && <LoadingMessage />}
      </div>

      {showChatInput && (
        <div className="border-black/12.5 flex w-full flex-row items-center gap-2 rounded-b-2xl border-t-0 border-solid p-6">
          <ChatInput
            handleSubmitMessage={handleSubmitMessage}
            activeLead={activeLead}
          />
          <div className="shrink">
            <SpeedDialForChat />
          </div>
        </div>
      )}
      <FileUploadInput
        handleChange={handleChange}
        hiddenFileInput={hiddenFileInput}
      />
    </div>
  );
}

export default ChatForm;
