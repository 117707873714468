import { formatTimeStampToHumanReadableDateTimeSeconds } from "../../utils/parseTimeStamp";
import { ChatUserType } from "../../api/leadContext.types";
import Markdown from "react-markdown";

export function AssistantMessage({
  content,
  date,
  role,
  children,
}: {
  content: string;
  date: string;
  role: ChatUserType;
  children?: any;
}) {
  return (
    <div className="-mx-3 mb-6 flex flex-wrap justify-start">
      <div className="flex-0 w-auto max-w-full px-3">
        <div className="shadow-soft-xl relative flex min-w-0 flex-col break-words rounded-2xl border-0 bg-white bg-clip-border">
          <div className="flex-auto px-4 py-2">
            <small className="flex items-center title-case leading-normal opacity-60">
              {role}
            </small>
            <Markdown>{content}</Markdown>
            {children}
            <div className="flex items-center text-sm leading-normal opacity-60">
              <small>
                {formatTimeStampToHumanReadableDateTimeSeconds(date)}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
