import { Alert } from "@material-tailwind/react";
import { useAlertContext } from "./alertContext";

const FloatingAlert = () => {
  const { alertProps } = useAlertContext();
  const { color, message } = alertProps;

  return alertProps && message && color ? (
    <div className="fixed bottom-0 left-0 right-0 flex flex-col gap-2 p-4">
      <Alert color={color} variant="filled">
        {alertProps.message}
      </Alert>
    </div>
  ) : null;
};

export default FloatingAlert;
