import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CustomerBalanceDailyRollupByPeriod } from "./customerBalance.type";

export function getCustomerBalanceRollup({
  companyId,
  startDate,
  endDate,
}: {
  companyId: string;
  startDate: string;
  endDate: string;
}): Promise<CustomerBalanceDailyRollupByPeriod[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.customerBalance +
    "/" +
    companyId +
    `?startDate=${startDate}&endDate=${endDate}`;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CustomerBalanceDailyRollupByPeriod[]>(url, request);
}
