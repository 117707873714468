/* eslint-disable react-hooks/exhaustive-deps */
import { PencilIcon, StarIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useEffect, useState } from "react";

import { useCampaignContentContext } from "../campaignSmsContent/campaignSmsContentContext";
import ValueWithLabel from "../forms/valueWithLabel";
import { MailIcon, PhoneIcon, PlusIcon } from "../icons/icons";
import { useCampaignContext } from "./campaignContext";

import { useLocation, useNavigate } from "react-router-dom";
import {
  EmailTemplateRow,
  isEmailTemplateRow,
} from "../../api/emailTemplate.type";
import { SmsContentRow } from "../../api/smsContent.type";
import InfoPopover from "../InfoPopover/infoPopover";
import ContentMenuItem from "../campaignSmsContent/ContentMenuItem";
import GenericDialog from "../dialog/genericDialog";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import PlentiUpdateOrCancelButtons from "../forms/PlentiUpdateOrCancelButtons";
import AddSmsContentContainer from "../smsContent/addSmsContent";
import ViewImage from "../table/viewImage";

function CampaignContentContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { activeCampaign } = useCampaignContext();

  const [open, setOpen] = useState<boolean>(false);

  const {
    emailContent,
    smsContent,
    campaignDefaultSmsContent,
    updateDefault,
    selectedContent,
    setSelectedContent,
  } = useCampaignContentContext();

  const [isAddContent, setIsAddContent] = useState<boolean>(false);

  function handleSubmit() {
    const isEmailTemplate = isEmailTemplateRow(selectedContent);
    updateDefault({
      companyId: activeCampaign?.company_id as string,
      campaignId: activeCampaign?.id as string,
      ...(selectedContent?.type === "ai_generated" && {
        isAiGenerated: true,
      }),
      ...(isEmailTemplate
        ? {
            emailTemplateId: selectedContent?.id,
          }
        : {
            smsContentId: selectedContent?.id,
          }),
    });
    setSelectedContent(undefined);
  }

  useEffect(() => {
    if (isAddContent) {
      setOpen(true);
    }
  }, [isAddContent]);

  const combinedContent: (SmsContentRow | EmailTemplateRow)[] =
    activeCampaign?.delivery_method === "email_sms" &&
    emailContent &&
    emailContent.length > 0 &&
    smsContent
      ? // @ts-ignore
        smsContent.concat(emailContent)
      : activeCampaign?.delivery_method === "email"
      ? emailContent ?? []
      : smsContent ?? [];

  return (
    <div className="w-full" id="default-content">
      <GenericDialog title="Add SMS Content" open={open} setOpen={setOpen}>
        <AddSmsContentContainer
          campaignId={activeCampaign?.id as string}
          isDefault={true}
        />
      </GenericDialog>
      {activeCampaign && (
        <Fragment>
          <div className="relative flex justify-between">
            <div className="relative">
              <Typography variant="h6">Message Content</Typography>
              <div className="absolute top-[-12px] right-[-15px]">
                <InfoPopover>
                  <p className="text-slate-700">
                    This is the first text message that is sent when a user
                    signs up on the try page.
                  </p>
                </InfoPopover>
              </div>
            </div>
            <Menu placement="bottom">
              <div className="flex flex-row gap-2">
                <MenuHandler>
                  <Button
                    className="flex items-center gap-2 p-1"
                    variant="outlined"
                    color="gray"
                    size="sm"
                  >
                    <PencilIcon className="h=4 w-4" />
                    {campaignDefaultSmsContent ? "Change" : "Add"}
                  </Button>
                </MenuHandler>
                <MenuList>
                  {combinedContent.map((content) => {
                    return (
                      <MenuItem
                        onClick={() => setSelectedContent(content)}
                        key={content?.id}
                      >
                        <ContentMenuItem content={content} />
                      </MenuItem>
                    );
                  })}
                  <MenuItem
                    onClick={() => {
                      setIsAddContent(true);
                      setOpen(true);
                    }}
                    className="flex flex-row items-center"
                  >
                    <PlusIcon className="w-4 w-4" /> Add new
                  </MenuItem>
                </MenuList>
                <Button
                  className="flex items-center gap-2 p-1"
                  variant="outlined"
                  color="gray"
                  size="sm"
                  onClick={() => {
                    const slug = campaignDefaultSmsContent?.sms_content
                      ? `/content/sms/${campaignDefaultSmsContent?.sms_content?.id}`
                      : `/content/email/${campaignDefaultSmsContent?.email_template?.id}`;

                    navigate(slug, {
                      state: {
                        name: "Campaign",
                        href: location.pathname,
                        active: false,
                      },
                    });
                  }}
                >
                  <PencilIcon className="h=4 w-4" />
                  edit
                </Button>
              </div>
            </Menu>
          </div>
          <Divider className="my-1" />
          <div className="my-4 grid w-full  gap-10">
            {(selectedContent?.id || campaignDefaultSmsContent?.id) && (
              <div className="flex flex-col gap-6">
                <div className="grid grid-cols-3  ">
                  <ValueWithLabel
                    label={
                      // @ts-ignore
                      selectedContent?.subject ||
                      campaignDefaultSmsContent?.email_template?.subject
                        ? "Subject"
                        : "Name"
                    }
                    value={
                      selectedContent?.name ||
                      // @ts-ignore
                      selectedContent?.subject ||
                      campaignDefaultSmsContent?.sms_content?.name ||
                      campaignDefaultSmsContent?.email_template?.subject ||
                      ""
                    }
                  />
                  <div>
                    <PlentiFormLabel>Message Type</PlentiFormLabel>
                    {(selectedContent && isEmailTemplateRow(selectedContent)) ||
                    (campaignDefaultSmsContent &&
                      isEmailTemplateRow(
                        campaignDefaultSmsContent.email_template
                      )) ? (
                      <MailIcon className="h-8 w-8 ml-4" />
                    ) : (
                      <PhoneIcon className="h-8 w-8 ml-4" />
                    )}
                  </div>
                  <div>
                    <div>
                      <div className="w-fit relative">
                        <PlentiFormLabel>AI Generated</PlentiFormLabel>
                        <div className="absolute top-[-15px] right-[-15px]">
                          <InfoPopover>
                            <p className="text-slate-700">
                              Messages can be generated by AI at the time it is
                              sent. The AI will use the context provided to
                              generate a response. This is useful for passing in
                              context that may be specific to the user, campaign
                              or available in the future. The campaign's prompt
                              and agents are also available to the AI.
                            </p>
                          </InfoPopover>
                        </div>
                      </div>

                      {campaignDefaultSmsContent?.is_ai_generated ? (
                        <div className="flex flex-row items-center">
                          <StarIcon className="w-6 h-6" color="orange" />
                          Yes
                        </div>
                      ) : (
                        "No"
                      )}
                    </div>
                  </div>
                </div>
                <ValueWithLabel
                  label={
                    campaignDefaultSmsContent?.is_ai_generated
                      ? "Context passed to AI"
                      : "Content"
                  }
                  value={
                    selectedContent?.content ||
                    campaignDefaultSmsContent?.sms_content?.content ||
                    campaignDefaultSmsContent?.email_template?.content ||
                    ""
                  }
                />
              </div>
            )}

            {(selectedContent?.id || campaignDefaultSmsContent?.id) &&
              !campaignDefaultSmsContent?.is_ai_generated && (
                <div>
                  <ViewImage
                    title="First message image"
                    src={
                      (selectedContent &&
                        ((selectedContent as SmsContentRow)
                          ?.media_url as string)) ||
                      (campaignDefaultSmsContent?.sms_content
                        ?.media_url as string)
                    }
                  />
                </div>
              )}
          </div>

          {selectedContent?.id && (
            <PlentiUpdateOrCancelButtons
              isEdit={selectedContent.id ? true : false}
              handleCancel={() => setSelectedContent(undefined)}
              handleSubmit={handleSubmit}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

export default CampaignContentContainer;
