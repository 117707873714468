import { UseMutateFunction, UseMutationResult } from "react-query";

import { LeadRow, LeadsWithPageAndCount } from "./lead.type";
import { ToolCall } from "./openAi.type";
import { BulkConfirmationRequest } from "./location.type";

export type UseGetLeadsByFilter = UseMutationResult<
  LeadRow[],
  unknown,
  void,
  unknown
>;

export type UseSearchLeads = UseMutationResult<
  LeadsWithPageAndCount,
  any,
  string,
  unknown
>;

export type UseGetLead = UseMutationResult<LeadRow, unknown, string, unknown>;

export interface ChatMessage {
  role: ChatUserType;
  content: string;
  media_url?: string;
  tool_call_id?: string;
  tool_calls?: ToolCall[];
}

export enum ChatUserType {
  system = "system",
  user = "user",
  assistant = "assistant",
  tool = "tool",
  function = "function",
}

export type UseUpdateLead = UseMutateFunction<
  LeadRow,
  Error,
  Partial<LeadRow>,
  unknown
>;

export type AddBulkLeads = UseMutateFunction<
  LeadRow[],
  any,
  BulkConfirmationRequest,
  unknown
>;
