function FabrkMenuDivider() {
  return (
    <div
      className="my-1 w-full"
      style={{
        height: "1px",
        backgroundColor: "rgb(62, 73, 174)",
        opacity: 0.7,
      }}
    ></div>
  );
}

export default FabrkMenuDivider;
