import { Button, Typography } from "@material-tailwind/react";
import { DatePicker, Divider } from "@tremor/react";
import { useState } from "react";

import EditButton from "../buttons/editButton";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";

import {
  convertDateToRawDate,
  yearMonthDayToMonthDayYear,
} from "../../utils/parseTimeStamp";

function OcrDetails() {
  const [isEdit, setIsEdit] = useState(false);
  const { useUpdateOcrById, reimbursementOcr, createOcr, activeReimbursement } =
    useReimbursementContext();
  const [ocrDate, setOcrDate] = useState<Date | undefined>(
    reimbursementOcr?.raw_date
      ? new Date(yearMonthDayToMonthDayYear(reimbursementOcr?.raw_date))
      : undefined
  );

  function updateOcr() {
    if (!reimbursementOcr || !reimbursementOcr.id) {
      createOcr({
        raw_date: ocrDate ? convertDateToRawDate(ocrDate) : undefined,
        reimbursement_id: activeReimbursement?.id as string,
      });
    }

    useUpdateOcrById.mutate({
      ocrId: reimbursementOcr?.id as string,
      values: {
        id: reimbursementOcr?.id as string,
        raw_date: ocrDate ? convertDateToRawDate(ocrDate) : undefined,
      },
    });
    setIsEdit(false);
  }

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Typography variant="h6">Ocr</Typography>
        <EditButton
          iconClass="h-4 w-4"
          text="Edit ocr"
          onClick={() => {
            setIsEdit(!isEdit);
          }}
        />
      </div>
      <Divider className="my-1" />
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs text-gray-500">Ocr reimbursement date</p>
          {!isEdit ? (
            <p className="text-slate-700">
              {yearMonthDayToMonthDayYear(reimbursementOcr?.raw_date as string)}
            </p>
          ) : (
            <DatePicker
              value={ocrDate}
              className="mb-2 max-w-sm overflow-visible"
              onValueChange={(value) => {
                setOcrDate(value ? value : undefined);
              }}
            />
          )}
        </div>
        {isEdit && (
          <div className="mt-6 flex justify-end gap-2">
            <Button
              size="sm"
              variant="outlined"
              color="gray"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="green"
              fullWidth={false}
              disabled={!isEdit}
              onClick={updateOcr}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default OcrDetails;
