function PlentiFormLabel({
  children,
  ...props
}: {
  children: React.ReactNode;
  [key: string]: any;
}) {
  return <p className="text-xs text-gray-500">{children}</p>;
}

export default PlentiFormLabel;
