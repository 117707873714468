import { Typography } from "@material-tailwind/react";
import { useFontContext } from "../font/fontContext";

function AdminFonts() {
  const { fonts } = useFontContext();

  return (
    <div>
      <h1>Admin Fonts</h1>
      <Typography variant="h4" className="font-Sofia-Pro">
        Sofia Pro
      </Typography>
      <Typography variant="h4" className="font-Agbalumo">
        Agbalumo
      </Typography>
      <Typography variant="h4" className="font-Mono">
        Mono
      </Typography>
      <Typography variant="h4" className="font-Sans">
        Sans
      </Typography>
      <Typography variant="h4" className="font-Sans-serif">
        Sans serif
      </Typography>
      <Typography variant="h4" className="font-Lato">
        Lato
      </Typography>
      <Typography variant="h4" className="font-Adieu-Regular">
        Adieu Regular
      </Typography>
      <Typography variant="h4" className="font-F37GingerSoft-Regular">
        Ginger soft
      </Typography>
      {fonts?.map((font) => {
        const className = `font-${font.tailwind_name}`;
        return (
          <Typography className={className}>{font.tailwind_name}</Typography>
        );
      })}
    </div>
  );
}

export default AdminFonts;
