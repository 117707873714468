/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";

import { DailyUsageRow } from "../../api/dailyUsage.type";
import { UsageRow } from "../../api/usage.type";
import { useLoadDailyUsage } from "./hooks/useLoadDailyUsage";

export const BillingContextWrapper = (props: any) => {
  const { data: dailyUsage } = useLoadDailyUsage();

  const [monthlyUsage, setMonthlyUsage] = useState<UsageRow[] | undefined>([]);

  useEffect(() => {
    if (dailyUsage) {
      const monthlyUsage: UsageRow[] = [];

      for (const curr of dailyUsage) {
        const found = monthlyUsage.find(
          (u) => u.month === curr.month && u.year === curr.year
        );

        if (found) {
          found.sms_balance += curr.sms_balance;
          found.reimbursement_balance += curr.reimbursement_balance;
        } else {
          monthlyUsage.push({ ...curr });
        }
      }

      setMonthlyUsage(monthlyUsage);
    }
  }, [dailyUsage]);

  const value = { dailyUsage, monthlyUsage };

  return (
    <BillingContext.Provider value={value}>
      {props.children}
    </BillingContext.Provider>
  );
};

export const BillingContext = createContext({
  dailyUsage: [] as DailyUsageRow[] | undefined,
  monthlyUsage: [] as UsageRow[] | undefined,
});

export const useBillingContext = () => useContext(BillingContext);
