/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { EmailTemplateContextWrapper } from "./EmailTemplateContext";
import EmailList from "./EmailList";

function EmailListContainer() {
  return (
    <div className="flex flex-col ">
      <EmailList />
    </div>
  );
}

const Email = () => {
  return (
    <EmailTemplateContextWrapper>
      <EmailListContainer />
    </EmailTemplateContextWrapper>
  );
};

export default Email;
