export function CustomIcon({
  className,
  fillColor,
}: {
  className: string;
  fillColor: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 248 310"
      className={className}
    >
      <path
        fill={fillColor}
        d="M162.7,119.2l13.3-14c18.8,8.1,38.2,4.5,52.6-9.8c15.1-15.1,17.8-41.1,13.9-58.7c-0.3-1.4-1.3-2.5-2.7-2.9  c-1.4-0.4-2.8,0-3.8,1l-33.4,33.5c-0.7,0.7-1.5,1-2.5,1c-0.9,0-1.8-0.4-2.5-1l-17-17.1c-1.4-1.4-1.4-3.6,0-5l33.6-33.8  c1-1,1.4-2.4,1-3.8c-0.4-1.4-1.5-2.4-2.8-2.7c-18-4.2-44.3-1.7-59.5,13.5C138.5,33.6,135,53.2,143,72l-14.3,13L63.3,19.3  c-0.6-0.6-1.3-0.9-2-1.1L8.6,8.4C7.3,8.1,6,8.6,5,9.5c-0.9,1-1.2,2.3-0.9,3.6L17,62.9c0.2,0.7,0.5,1.3,1,1.8l63.8,64.1L44,159.9  c-1.7,1.4-1.9,3.8-0.5,5.4c0.8,0.9,1.9,1.4,3,1.4c0.9,0,1.7-0.3,2.5-0.9l38.3-31.6l29.4,29.6l-63.5,68.9c-1.5,1.2-14,9.9-31.1-7.2  c-16.6-16.8-7.4-28.9-6.2-30.2L28.5,184c1.6-1.4,1.7-3.9,0.3-5.5c-1.4-1.6-3.9-1.7-5.5-0.3l-12.9,11.4c-0.1,0.1-0.2,0.2-0.3,0.3  c-5.7,6.2-11.8,22.8,6.3,41c9.9,9.9,18.9,12.8,26,12.8c9.6,0,15.7-5.2,15.8-5.3c0.1-0.1,0.2-0.2,0.3-0.3l63.5-68.8l64.9,65.3  c6,6.1,14.1,9.4,22.6,9.4c0,0,0,0,0,0c8.5,0,16.6-3.3,22.6-9.4c12.5-12.5,12.5-32.9,0-45.4L162.7,119.2z M150.3,75.8  c1.3-1.2,1.6-3,0.9-4.6c-8.1-16.6-5.4-34,7.1-46.5c8.2-8.2,25.3-15.1,45.1-12.6l-28.4,28.5c-4.4,4.4-4.4,11.5,0,15.9l17,17.1  c2.1,2.1,4.9,3.3,8,3.3h0c3,0,5.8-1.2,8-3.3l28-28.1c2,19.4-4.9,36.2-13,44.3c-12.5,12.6-29.8,15.3-46.3,7.1  c-1.5-0.7-3.3-0.4-4.5,0.8l-15.1,15.8l-23-23.1L150.3,75.8z M226.8,229.1c-4.6,4.6-10.7,7.1-17.1,7.1h0c-6.5,0-12.5-2.5-17.1-7.1  L24.3,59.9L13.1,17.1l45.5,8.5l168.2,169.1C236.3,204.1,236.3,219.6,226.8,229.1z"
      />
      <path d="M55.4,50.8c-1.5-1.5-4-1.5-5.5,0c-1.5,1.5-1.5,4,0,5.5L200,207c0.8,0.8,1.8,1.1,2.7,1.1c1,0,2-0.4,2.7-1.1  c1.5-1.5,1.5-4,0-5.5L55.4,50.8z" />
    </svg>
  );
}
