import { Card } from "@material-tailwind/react";
import React from "react";

function SearchList({
  items,
  handleSelect,
}: {
  items: any[];
  handleSelect: (value: any) => void;
}) {
  const filtered = items
    .filter((_, index) => index < 100)
    .map((item) => (
      <Card onClick={() => handleSelect(item)} key={item.id}>
        {item.name}
      </Card>
    ));
  return <div className="p-2">{filtered}</div>;
}

export default SearchList;
