function PlentiCurrencyInput({
  amount,
  setAmount,
}: {
  amount: string;
  setAmount: (amount: string) => void;
}) {
  const handleChange = (e: any) => {
    // This regex allows values that are empty (to clear the input)
    // or numeric (integer or floating point numbers).
    if (e.target.value === "" || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
      setAmount(e.target.value);
    }
  };
  return (
    <div className="relative w-full">
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-600">
        $
      </span>
      <input
        type="text" // Changed to text
        inputMode="decimal" // Brings up the numeric keyboard on mobile devices
        pattern="[0-9]*"
        value={amount}
        onChange={(e) => handleChange(e)}
        className="pl-8 pr-3 py-3 rounded-lg text-md leading-5 text-gray-900 focus:outline-none focus:ring-0 h-10"
      />
    </div>
  );
}

export default PlentiCurrencyInput;
