export const MasterCardSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      height="24px"
      width="36px"
      viewBox="0 0 36 24"
      fill="none"
      className={className}
    >
      <path
        d="M21.958 5.48444H14.0442V18.5132H21.958V5.48444Z"
        fill="#FF5F00"
      ></path>
      <path
        d="M14.8593 12C14.8583 10.7454 15.1405 9.5071 15.6844 8.37869C16.2283 7.25027 17.0198 6.26135 17.9988 5.48677C16.7862 4.52617 15.3299 3.92883 13.7965 3.76303C12.263 3.59722 10.7141 3.86963 9.32698 4.54912C7.93982 5.22861 6.7703 6.28777 5.95208 7.60555C5.13386 8.92333 4.69995 10.4466 4.69995 12.0012C4.69995 13.5557 5.13386 15.079 5.95208 16.3968C6.7703 17.7145 7.93982 18.7737 9.32698 19.4532C10.7141 20.1327 12.263 20.4051 13.7965 20.2393C15.3299 20.0735 16.7862 19.4761 17.9988 18.5155C17.0195 17.7407 16.2278 16.7514 15.6839 15.6226C15.14 14.4937 14.858 13.2549 14.8593 12Z"
        fill="#EB001B"
      ></path>
      <path
        d="M30.5135 17.1347V16.8677H30.6277V16.8124H30.356V16.8677H30.4633V17.1347H30.5135ZM31.0409 17.1347V16.8124H30.9587L30.8628 17.0426L30.7669 16.8124H30.6847V17.1347H30.7441V16.8907L30.8332 17.1001H30.8948L30.9839 16.8907V17.1347H31.0409Z"
        fill="#F79E1B"
      ></path>
      <path
        d="M31.299 12C31.299 13.5547 30.865 15.0781 30.0466 16.3959C29.2282 17.7137 28.0585 18.7728 26.6712 19.4522C25.2839 20.1316 23.7349 20.4039 22.2013 20.2378C20.6677 20.0718 19.2114 19.4741 17.9989 18.5133C18.9776 17.738 19.7688 16.7488 20.3129 15.6203C20.8569 14.4918 21.1396 13.2535 21.1396 11.9989C21.1396 10.7442 20.8569 9.50591 20.3129 8.37743C19.7688 7.24895 18.9776 6.25976 17.9989 5.48449C19.2114 4.52359 20.6677 3.92597 22.2013 3.75992C23.7349 3.59387 25.2839 3.86611 26.6712 4.5455C28.0585 5.2249 29.2282 6.28404 30.0466 7.60186C30.865 8.91969 31.299 10.443 31.299 11.9977V12Z"
        fill="#F79E1B"
      ></path>
    </svg>
  );
};
