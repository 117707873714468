/* eslint-disable react/jsx-pascal-case */
import FabrkInputContainer from "./input/FabrkInputContainer";
import FabrkMenuContainer from "./layout/FabrkMenuContainer";
import { FabrkCampaignContextWrapper } from "./hooks/FabrkCampaignContext";
import {
  FabrkMessageContextWrapper,
  useFabrkMessageContext,
} from "./hooks/FabrkMessageContext";
import {
  SessionContextWrapper,
  useSessionContext,
} from "./hooks/FabrkSessionContext";
import MessageViewContainer from "./messageView/MessageViewContainer";
import FabrkRightMenuContainer from "./layout/FabrkRightMenuContainer";

function _Fabrk() {
  const { messages } = useFabrkMessageContext();

  return (
    <div className="flex min-h-screen bg-[#171715] text-[#fdfcf3]">
      <FabrkMenuContainer />
      <div className="flex-1 flex flex-col">
        <MessageViewContainer />
        <FabrkInputContainer
          view={messages && messages.length > 0 ? "message" : "header"}
        />
        <footer className="flex justify-center items-center mt-16"></footer>
        <FabrkRightMenuContainer />
      </div>
    </div>
  );
}

const _FabrkWithSession = () => {
  const { session } = useSessionContext();
  return (
    <FabrkCampaignContextWrapper leadId={session?.lead_id}>
      <FabrkMessageContextWrapper leadId={session?.lead_id}>
        <_Fabrk />
      </FabrkMessageContextWrapper>
    </FabrkCampaignContextWrapper>
  );
};

const Fabrk = () => {
  return (
    <SessionContextWrapper>
      <_FabrkWithSession />
    </SessionContextWrapper>
  );
};

export default Fabrk;
