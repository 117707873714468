/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AuthProps } from ".";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useAuthContext } from "./authContext";

declare global {
  interface Window {
    google?: any;
  }
}

const GoogleSignIn = ({ viewType }: AuthProps) => {
  const supabase = useSupabaseClient();
  const { setSignupUser } = useAuthContext();

  useEffect(() => {
    // Load the Google's GSI client library script dynamically
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize Google Sign-In
      window.google?.accounts.id.initialize({
        client_id:
          "1012580486912-ocm3ig5ghucc73uhpn8u30b8dinp5i70.apps.googleusercontent.com", // Replace with your client ID
        callback: handleCredentialResponse,
      });

      // Render the Google Sign-In button
      window.google?.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          text: viewType === "Login" ? "signin_with" : "signup_with",
        } // Customization attributes
      );

      // Display the One Tap dialog
      window.google?.accounts.id.prompt();
    };

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [window]);

  // Function to handle the response
  async function handleCredentialResponse(response: any) {
    const { error, data } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: response.credential,
    });

    if (error) {
      console.log(error);
    }
    if (data) {
      setSignupUser(data.user);
    }
  }

  return <div id="buttonDiv" style={{ borderRadius: "40px" }}></div>;
};

export default GoogleSignIn;
