import { Card, CardBody, Typography } from "@material-tailwind/react";
import { ShareIcon } from "../icons/icons";

function FlowHeaderCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <ShareIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Flows
        </Typography>
        <Typography>
          Flows allow you to send messages to your customers manually or based
          on their actions. Flows are different from campaigns. Flows are
          triggered manually by you (for example - for a one-off announcement)
          or by a customer's action. Campaigns are geared towards a specific
          goal - like driving reimbursements for a BOGO offer.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default FlowHeaderCard;
