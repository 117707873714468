/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { SegmentsRow } from "../../api/segments.type";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch from "../table/tableWithSearch";
import SegmentResponse from "./SegmentResponse";
import SegmentTableList from "./SegmentTableList";
import { useSegmentContext } from "./hooks/SegmentContext";

const tableHead = [
  { label: "Name / Created at" },
  { label: "Description" },
  { label: "Query" },
  {
    label: "Run",
  },
  {
    label: "Remove",
  },
];

function SegmentList() {
  const {
    activeSegment,
    segmentResponse,
    setActiveSegment,
    segments,
    handleRemoveSegment,
    loading,
  } = useSegmentContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredSegments, setFilteredSegments] = useState<SegmentsRow[]>([]);
  const rowsPerPage = 10;
  function baseFilter() {
    return (segment: SegmentsRow, index: number) => index < 10;
  }
  const [segmentFilterFunction, setSegmentFilterFunction] =
    useState<(segment: SegmentsRow, index: number) => boolean>(baseFilter);

  function resetSegmentFilter() {
    setSegmentFilterFunction(baseFilter);
    setFilteredSegments(segments ? segments.slice(0, 10) : []);
  }

  useEffect(() => {
    if (activeSegment) {
      setSegmentFilterFunction(
        (segment: SegmentsRow, index: number) => segment.id === activeSegment.id
      );
      setFilteredSegments(
        segments?.filter((segment) => segment.id === activeSegment.id) || []
      );
    } else {
      setSegmentFilterFunction(baseFilter);
      setFilteredSegments(segments ? segments.slice(0, 10) : []);
    }
  }, [activeSegment, segments]);

  return (
    <div className="flex flex-col gap-4">
      {!filteredSegments ||
        (filteredSegments.length === 0 && (
          <div className="flex flex-col gap-4">
            <p className="text-2xl">You have no segments yet.</p>
            <Typography variant="paragraph" className="max-w-2xl">
              Create a segment by clicking on the "Add Segment" button.
            </Typography>
          </div>
        ))}
      {filteredSegments && filteredSegments.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          type="Segments"
          rowCount={
            filteredSegments && filteredSegments.length > 0
              ? filteredSegments?.length
              : 0
          }
          rowsPerPage={10}
          currentPage={currentPage}
          additionalButtons={
            <div>
              {activeSegment && (
                <Button
                  variant="text"
                  color="cyan"
                  onClick={() => {
                    setSegmentFilterFunction(baseFilter);
                    setFilteredSegments(segments ? segments.slice(0, 10) : []);
                    setActiveSegment(undefined);
                  }}
                >
                  Show All
                </Button>
              )}
            </div>
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setSegmentFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setSegmentFilterFunction,
              advancePage: false,
            })
          }
        >
          <SegmentTableList
            segments={filteredSegments}
            filterFunction={segmentFilterFunction}
          />
        </TableWithSearch>
      )}
      {segmentResponse && activeSegment && (
        <SegmentResponse
          segmentResponse={segmentResponse}
          resetSegmentFilter={resetSegmentFilter}
          setActiveSegment={setActiveSegment}
          removeSegment={handleRemoveSegment}
          loading={loading}
        />
      )}
    </div>
  );
}

export default SegmentList;
