type ButtonProps = {
  fullWidth?: boolean;
  fullWidthOnMobile?: boolean;
  size?: "sm" | "md" | "lg";
  children: React.ReactNode;
  disabled?: boolean;
  loadingContent?: React.ReactNode;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
};
const COMMON_CLASSES =
  "relative rounded-md transition-all drop-shadow font-medium";

const getClasses = (
  size: string | undefined,
  fullWidth: boolean | undefined,
  fullWidthOnMobile: boolean | undefined
) => {
  const normalizedSize = size || "sm";
  let padding;
  let fontSize;
  if (normalizedSize === "sm") {
    padding = "px-3 py-1";
    fontSize = "text-sm";
  } else if (normalizedSize === "md") {
    padding = "px-4 py-2";
    fontSize = "text-md";
  } else if (normalizedSize === "lg") {
    padding = "px-8 py-3";
    fontSize = "text-lg";
  }
  let classes = `${COMMON_CLASSES} ${padding} ${fontSize} ${
    fullWidth ? "w-full" : ""
  }`;
  if (fullWidthOnMobile) {
    classes += " w-full md:w-fit";
  }
  return classes;
};

export const ButtonPrimary = ({
  fullWidth,
  fullWidthOnMobile,
  size,
  children,
  disabled,
  loadingContent,
  isLoading,
  ...otherProps
}: ButtonProps) => {
  const classes = getClasses(size, fullWidth, fullWidthOnMobile);
  return (
    <button
      className={`${
        disabled
          ? "bg-blue-200 text-slate-600"
          : "bg-blue-300 text-white hover:bg-primary-600"
      } ${classes}`}
      disabled={disabled}
      {...otherProps}
    >
      <div
        className={`${
          isLoading ? "invisible" : "visible"
        } flex items-center justify-center gap-2 tracking-wide`}
      >
        {children}
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          {loadingContent}
        </div>
      )}
    </button>
  );
};

export const ButtonSecondary = ({
  fullWidth,
  fullWidthOnMobile,
  size,
  children,
  ...otherProps
}: ButtonProps) => {
  const classes = getClasses(size, fullWidth, fullWidthOnMobile);
  return (
    <button
      className={`bg-white text-primary-600 hover:bg-primary-400  hover:text-white ${classes}`}
      {...otherProps}
    >
      {children}
    </button>
  );
};
