import { IconButton, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import ReimbursementByLeadItem from "./reimbursementByLeadItem";

import { color } from "@material-tailwind/react/types/components/button";
import { ReimbursementRow } from "../../api/reimbursement.type";

export type ReimbursementType = {
  name: string;
  type: string;
  color: color;
  icon: JSX.Element;
  explanation: string;
};

function CampaignTypeDetails({
  reimbursementType,
  activeReimbursement,
}: {
  reimbursementType: ReimbursementType;
  activeReimbursement: ReimbursementRow;
}) {
  const { name, color, icon, explanation, type } = reimbursementType;
  return (
    <div className="grid grid-cols-2 place-items-center">
      <div key={name} className="flex flex-row gap-5 place-items-center">
        <IconButton color={color}>{icon}</IconButton>
        <Typography variant="h5">{name}</Typography>
      </div>
      <Typography variant="paragraph">{explanation}</Typography>
      {type === "referral" && activeReimbursement.referral_reimbursement && (
        <div>
          <Divider className="my-2" />
          <p className="text-xs text-gray-500">Completed referral</p>
          <ReimbursementByLeadItem
            key={activeReimbursement.id}
            reimbursement={activeReimbursement.referral_reimbursement}
          />
        </div>
      )}
    </div>
  );
}

export default CampaignTypeDetails;
