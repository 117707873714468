import { Divider } from "@tremor/react";
import LeadsChart from "../analytics/leadsChart";
import LocationChart from "../analytics/locationChart";
import MessageChart from "../analytics/messageChart";
import SetCampaignAndDate from "../analytics/setCampaignAndDate";
import { MetricCard } from "../analyticsComponents/metricCard";
import { useLoadCampaigns } from "../campaign/hooks/useLoadCampaigns";
import { useDashboardContext } from "../layout/dashboardContext";
import { LeadContextWrapper } from "../lead/leadContext";
import { useUserContext } from "../user/userContext";
import AnalyticsCard from "./AnalyticsCard";
import AdminAnalytics from "./adminAnalytics";
import { LeadAnalyticsContextWrapper } from "./leadAnalyticsContext";
import { ReimbursementChartByCampaign } from "./reimbursementChart/containers/ReimbursementChartByCampaign";
import { useSetCampaignAndDate } from "./setCampaignAndDate/useSetCampaignAndDate";

function AnalyticsContainer() {
  const { user } = useUserContext();
  const { campaigns } = useLoadCampaigns();
  const { isHidden } = useDashboardContext();
  const { selectedCampaign } = useSetCampaignAndDate();

  return (
    <LeadContextWrapper>
      <div className="sticky top-16 z-10 bg-white border-b w-full ">
        <div className="flex flex-col items-stretch md:flex-row">
          {isHidden && (
            <div className="p-4 flex flex-1 justify-end">
              <SetCampaignAndDate campaigns={campaigns} />
            </div>
          )}
        </div>
      </div>
      <div className="p-5">
        <AnalyticsCard />
        <Divider />
        {user && user?.type === "admin" && <AdminAnalytics />}
        <h2 className="mb-4 text-slate-500">Campaigns</h2>
        <div className="flex flex-col gap-4  justify-between lg:flex-row">
          <div className="w-full max-w-sm">
            <MetricCard title={"Campaigns"} value={campaigns?.length || 1} />
          </div>
          {!isHidden && (
            <div>
              <SetCampaignAndDate campaigns={campaigns} />
            </div>
          )}
        </div>
        <Divider />
        <h2 className="mb-4 text-slate-500">Leads</h2>
        <LeadAnalyticsContextWrapper selectedCampaign={selectedCampaign}>
          <LeadsChart />
        </LeadAnalyticsContextWrapper>
        <Divider />
        <h2 className="mb-4 text-slate-500">Reimbursements</h2>
        <ReimbursementChartByCampaign />
        <Divider />
        <h2 className="mb-4 text-slate-500">Messages</h2>
        <MessageChart />
        <Divider />
        <h2 className="mb-4 text-slate-500">Locations</h2>
        <LocationChart />
        <Divider />
      </div>
    </LeadContextWrapper>
  );
}

export default AnalyticsContainer;
