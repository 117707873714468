/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from "@material-tailwind/react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";

import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApiCallAgentHeader } from "../../api/aiAgent.type";
import { CreateMergeTag } from "../../api/mergeTag.type";
import { extractPlaceholders } from "../../utils/stringManipulation";
import SelectCompany from "../company/selectCompany";
import GenericSelect from "../forms/GenericSelect";
import { PlentiInput } from "../forms/plentiInput";
import AddHeaders from "./AddHeaders";
import MergeTagsList from "./MergeTagsList";
import { useAiAgentContext } from "./hooks/AiAgentContext";

type ApiCallAgentProps = {
  name: string;
  url: string;
  description: string;
};

function AddApiCallAgent() {
  const { createAgent } = useAiAgentContext();

  const verbs = ["GET", "POST", "PUT", "DELETE"];
  const [selectedVerb, setSelectedVerb] = useState<string>("GET" as string);

  const [addHeaders, setAddHeaders] = useState<boolean>(false);

  const [headers, setHeaders] = useState<ApiCallAgentHeader[]>([]);

  const [mergeTags, setMergeTags] = useState<string[]>([]);
  const [mergeTagsDefaults, setMergeTagsDefaults] = useState<
    {
      tag: string;
      value: string;
    }[]
  >([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      name: "Api Call Agent",
      url: "",
      description: "",
    },
  });

  const onSubmit: SubmitHandler<ApiCallAgentProps> = (
    data: ApiCallAgentProps
  ) => {
    const headersToMergeTags: CreateMergeTag[] = headers.map((header) => {
      return {
        tag: header.parameter,
        default_value: header.value,
        table_column: "headers",
      };
    });
    const verbToMergeTags = {
      tag: "verb",
      default_value: selectedVerb,
      table_column: "verb",
    };

    const urlToMergeTags = {
      tag: "url",
      default_value: data.url,
      table_column: "url",
    };

    const mappedMergeTabs = mergeTagsDefaults.map((tag, index) => {
      return {
        tag: mergeTags[index],
        default_value: tag?.value || null,
        table_column: "url_params",
      };
    });

    const concatTags: CreateMergeTag[] = [
      ...headersToMergeTags,
      verbToMergeTags,
      urlToMergeTags,
      ...mappedMergeTabs,
    ];

    createAgent({
      name: data.name,
      type: "api_call",
      mergeTags: concatTags,
      description: data.description,
    });

    reset();
  };

  useEffect(() => {
    if (watch("url")) {
      const url = watch("url");
      console.log(extractPlaceholders(url));
      setMergeTags(extractPlaceholders(url));
    }
  }, [watch("url")]);

  return (
    <div className="p-5 ">
      <div className="flex justify-between">
        <BreadcrumbsFromConfig
          config={[
            {
              name: "Add Agent",
              href: "/agent/add",
              active: false,
            },
            {
              name: "API Call Agent",
              href: "/agent/add/apiCall",
              active: true,
            },
          ]}
        />
        <Button variant="outlined" onClick={() => setAddHeaders(!addHeaders)}>
          Add Headers
        </Button>
        {/* <LearnMoreButton
          onClick={() => {
            window.open("https://youtu.be/QE7ie0nWj6I", "_blank");
          }}
          text=" Tutorial"
          variant="text"
        /> */}
      </div>
      <div className="mt-4 flex flex-col gap-4 rounded-lg ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <div>
              <div className="max-w-md">
                <div className="flex justify-between">
                  <Typography variant="h6">Add API Call Agent</Typography>
                </div>

                <Divider className="my-1" />
              </div>
              <div className="flex flex-col gap-2">
                <PlentiInput
                  register={register}
                  errors={errors}
                  label="Name"
                  name="name"
                />
                <PlentiInput
                  register={register}
                  errors={errors}
                  label="Url"
                  name="url"
                />
                <PlentiInput
                  register={register}
                  errors={errors}
                  label="Description"
                  name="description"
                />

                <GenericSelect
                  values={verbs}
                  setSelected={setSelectedVerb}
                  selected={selectedVerb}
                />

                <SelectCompany />
              </div>
            </div>
            <div className="flex flex-col gap-5">
              {mergeTags && mergeTags.length > 0 && (
                <div>
                  {" "}
                  <div className="flex justify-between">
                    <Typography variant="h6">Merge Tags</Typography>
                  </div>
                  <Divider className="my-1" />
                  <MergeTagsList
                    mergeTags={mergeTags}
                    mergeTagsDefaults={mergeTagsDefaults}
                    setMergeTagsDefaults={setMergeTagsDefaults}
                  />
                </div>
              )}
              {addHeaders && (
                <div>
                  <div className="flex justify-between">
                    <Typography variant="h6">Headers</Typography>
                  </div>
                  <Divider className="my-1" />
                  <AddHeaders headers={headers} setHeaders={setHeaders} />
                </div>
              )}
            </div>
            <Button
              size="sm"
              color="green"
              fullWidth={false}
              disabled={!isDirty}
              type="submit"
              className="mt-4"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddApiCallAgent;
