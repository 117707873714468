/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  createCampaignTransition,
  getCampaignTransitionByCampaignId,
  removeCampaignTransition,
} from "../../../api/campaignTransition.api";
import { CampaignTransitionRow } from "../../../api/campaignTransition.type";
import { useAlertContext } from "../../alert/alertContext";
import {
  CreateCampaignTransition,
  GetCampaignTransitionByCampaign,
  RemoveCampaignTransition,
} from "./CampaignTransitionFlow.types";

export const CampaignTransitionContextWrapper = ({
  children,
  campaignId,
}: {
  children: React.ReactNode;
  campaignId?: string;
}) => {
  const queryClient = useQueryClient();

  const { setAlertProps } = useAlertContext();
  const [campaignTransitions, setCampaignTransitions] = useState<
    CampaignTransitionRow[]
  >([]);

  const { mutate: getCampaignTransitionByCampaign } = useMutation(
    getCampaignTransitionByCampaignId,
    {
      onSuccess: async (res) => {
        setCampaignTransitions(res);
      },
      onError(error: Error) {
        console.log(error);
      },
    }
  );

  const { mutate: createTransition } = useMutation(createCampaignTransition, {
    onSuccess: async (res) => {
      getCampaignTransitionByCampaign(campaignId as string);
      queryClient.invalidateQueries("campaignTransitions");

      setAlertProps({
        message: "Transition added",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: removeTransition } = useMutation(removeCampaignTransition, {
    onSuccess: async (res) => {
      getCampaignTransitionByCampaign(campaignId as string);
      queryClient.invalidateQueries("campaignTransitions");

      setAlertProps({
        message: "Transition removed",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (campaignId) {
      getCampaignTransitionByCampaign(campaignId);
    }
  }, [campaignId]);

  const value = {
    getCampaignTransitionByCampaign,
    campaignTransitions,
    createTransition,
    removeTransition,
  };

  return (
    <CampaignTransitionContext.Provider value={value}>
      {children}
    </CampaignTransitionContext.Provider>
  );
};

export const CampaignTransitionContext = createContext({
  getCampaignTransitionByCampaign: {} as GetCampaignTransitionByCampaign,
  campaignTransitions: [] as CampaignTransitionRow[],
  createTransition: {} as CreateCampaignTransition,
  removeTransition: {} as RemoveCampaignTransition,
});

export const useCampaignTransitionContext = () =>
  useContext(CampaignTransitionContext);
