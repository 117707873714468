/* eslint-disable react-hooks/exhaustive-deps */
import { Card, DonutChart, Metric, Text, Title } from "@tremor/react";
import { useEffect, useState } from "react";

import { useAnalyticsContext } from "../analytics/analyticsContext";
import LocationTable from "../analytics/locationTable";
import { useCampaignContext } from "../campaign/campaignContext";

import { LocationCount } from "../../api/location.type";
import { useSetCampaignAndDate } from "./setCampaignAndDate/useSetCampaignAndDate";

export type LocationByName = {
  id: string;
  name: string;
  count: number;
};

export function getTotalCounts(locationCount: LocationCount[]) {
  return locationCount.reduce((acc, curr) => {
    return acc + curr.location_count;
  }, 0);
}

function LocationChart() {
  const { useGetLocationCounts, locationCounts } = useAnalyticsContext();

  const { selectedCampaign } = useSetCampaignAndDate();
  const { campaigns } = useCampaignContext();

  const [multipleLocationsWithTags, setMultipleLocationsWithTags] = useState<
    LocationByName[]
  >([]);
  const [chainLocations, setChainLocations] = useState<LocationByName[]>([]);

  const valueFormatter = (number: number) =>
    `${Intl.NumberFormat("us").format(number).toString()}`;

  function mapChainLocations(locationCount: LocationCount[]) {
    const chainCounts: LocationByName[] = [];
    for (let i = 0; i < locationCount.length; i++) {
      const { location, location_count } = locationCount[i];

      if (location && location.chain_id) {
        if (chainCounts.some((chain) => chain.id === location.chain_id)) {
          const index = chainCounts.findIndex(
            (locationCount) => locationCount.id === location.chain_id
          );
          chainCounts[index].count += location_count;
        } else {
          chainCounts.push({
            id: location?.chain_id || "",
            name: location?.chain?.name || "",
            count: location_count,
          });
        }
      }
    }
    setChainLocations(chainCounts);
  }

  function mapLocationsToNames(
    locationCount: LocationCount[]
  ): LocationByName[] {
    const tags: LocationByName[] = [];

    for (let i = 0; i < locationCount.length; i++) {
      const { location, location_count } = locationCount[i];

      if (location_count > 1) {
        tags.push({
          name: `${location.name} ${location.city}, ${location.state}`,
          count: location_count,
          id: location.id,
        });
      }
    }

    return tags;
  }

  useEffect(() => {
    if (campaigns) {
      useGetLocationCounts.mutate({ campaign_id: campaigns[0].id });
    }
  }, [campaigns]);

  useEffect(() => {
    if (selectedCampaign && campaigns) {
      useGetLocationCounts.mutate({ campaign_id: selectedCampaign.id });
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (locationCounts) {
      const map = mapLocationsToNames(locationCounts);
      setMultipleLocationsWithTags(map);
      mapChainLocations(locationCounts);
    }
  }, [locationCounts]);

  return (
    <div>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <Card className="mx-auto max-w-xs">
          <Text>Total Known Reimbursement Locations</Text>
          {locationCounts && <Metric>{getTotalCounts(locationCounts)}</Metric>}
        </Card>
      </div>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <Card className="mb-8 max-w-lg">
          <Title>Multiple Locations (3 or more)</Title>
          {multipleLocationsWithTags && (
            <DonutChart
              className="mt-6"
              data={multipleLocationsWithTags}
              category="count"
              index="name"
              valueFormatter={valueFormatter}
              colors={["blue", "pink", "red", "rose", "cyan", "amber"]}
              showLabel={false}
            />
          )}
        </Card>
        <Card className="mb-8 max-w-lg">
          <Title>Chains</Title>
          {chainLocations && (
            <DonutChart
              className="mt-6"
              data={chainLocations}
              category="count"
              index="name"
              valueFormatter={valueFormatter}
              colors={["blue", "pink", "red", "rose", "cyan", "amber"]}
            />
          )}
        </Card>
      </div>
      {locationCounts && locationCounts.length > 0 && (
        <LocationTable locationCounts={locationCounts} />
      )}
    </div>
  );
}

export default LocationChart;
