/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from "@material-tailwind/react";
import { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ApiCallAgentHeader } from "../../api/aiAgent.type";
import DeleteButton from "../buttons/DeleteButton";
import AddButton from "../buttons/addButton";

function AddHeaders({
  headers,
  setHeaders,
}: {
  headers: ApiCallAgentHeader[];
  setHeaders: (headers: ApiCallAgentHeader[]) => void;
}) {
  const activeSchema = useMemo(() => {
    return headers;
  }, [headers]);

  const defaultValues = activeSchema.map((item) => {
    return {
      parameter: item.parameter,
      value: item.value,
    };
  });

  const { control, watch } = useForm({
    defaultValues: {
      headers: defaultValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "headers",
  });

  useEffect(() => {
    setHeaders(watch("headers"));
  }, [watch("headers")]);

  return (
    <form>
      <div className="flex flex-col p-5 border border-blue-gray-100 rounded-lg">
        <div>
          {fields?.map((item, index) => {
            return (
              <div key={item.id} className="grid grid-cols-5 gap-2">
                <div className="max-w-24 col-span-2 ">
                  <Controller
                    render={({ field }) => (
                      <div className="mb-2 max-w-sm">
                        <Input
                          {...field}
                          label="Item"
                          variant="outlined"
                          crossOrigin={true}
                          className="focus:border-t-0 focus:ring-0 "
                        />
                      </div>
                    )}
                    name={`headers.${index}.parameter`}
                    control={control}
                  />
                </div>
                <div className="col-span-2 w-md">
                  <Controller
                    render={({ field }) => (
                      <div className="mb-2">
                        <Input
                          {...field}
                          label="Value"
                          variant="outlined"
                          crossOrigin={true}
                          className="focus:border-t-0 focus:ring-0"
                        />
                      </div>
                    )}
                    name={`headers.${index}.value`}
                    control={control}
                  />
                </div>
                <DeleteButton onClick={() => remove(index)} />
              </div>
            );
          })}
          <AddButton
            iconClass="h-4 w-4"
            color="green"
            onClick={() => {
              append({
                parameter: "",
                value: "",
              });
            }}
          >
            Add parameter
          </AddButton>
        </div>
      </div>
    </form>
  );
}

export default AddHeaders;
