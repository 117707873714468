import { IconButton } from "@material-tailwind/react";

import { PaypalLogoSvg } from "../icons/paypalSvg";
import { VenmoLogoSvg } from "../icons/venmoSvg";

function SetAccountType({
  updatedPaymentAccountType,
  setPaymentAccountType,
}: {
  updatedPaymentAccountType: string | undefined;
  setPaymentAccountType?: (value: string) => void;
}) {
  return (
    <div className="items-center">
      <p className="text-xs text-gray-500">Account</p>

      <div className="flex flex-row gap-4">
        <IconButton
          disabled={!setPaymentAccountType}
          ripple={true}
          color={updatedPaymentAccountType === "Venmo" ? "blue" : "gray"}
          variant={updatedPaymentAccountType === "Venmo" ? "filled" : "text"}
          onClick={() =>
            setPaymentAccountType && setPaymentAccountType("Venmo")
          }
        >
          <VenmoLogoSvg className="h-6 w-6 " />
        </IconButton>
        <IconButton
          disabled={!setPaymentAccountType}
          ripple={true}
          color={updatedPaymentAccountType === "Paypal" ? "blue" : "gray"}
          variant={updatedPaymentAccountType === "Paypal" ? "filled" : "text"}
          onClick={() =>
            setPaymentAccountType && setPaymentAccountType("Paypal")
          }
        >
          <PaypalLogoSvg className="h-6 w-6" />
        </IconButton>
      </div>
    </div>
  );
}

export default SetAccountType;
