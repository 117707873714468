import { ReimbursementCohort } from "../../api/leadAnalytics.type";
import { formatFullDateToShortDate } from "../../utils/parseTimeStamp";

export type CsvReimbursementCountData = {
  signupDate: string;
  leadCount: string;
  leadCountReimbursed: string;
};

export const mapReimbursementCountToCsvData = (
  reimbursementCohort: ReimbursementCohort[] | undefined
): {
  csvData: CsvReimbursementCountData[];
  headers: any[];
} => {
  if (!reimbursementCohort) return { csvData: [], headers: [] };
  const csvData = reimbursementCohort.map((cohort) => {
    return {
      signupDate: formatFullDateToShortDate(cohort.signup_date),
      leadCount: cohort.lead_count_signup.toString(),
      leadCountReimbursed: cohort.lead_count_reimbursed.toString(),
    };
  });

  const headers = [
    { label: "Signup Date", key: "signupDate" },
    { label: "Lead Count", key: "leadCount" },
    { label: "Lead Count Reimbursed", key: "leadCountReimbursed" },
  ];

  return {
    csvData,
    headers,
  };
};
