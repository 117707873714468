import { Link } from "react-router-dom";
import { Fragment } from "react";

export function PlentiLink({
  text,
  to,
  state,
}: {
  text: string;
  to: string;
  state?: any;
}) {
  return (
    <Fragment>
      <Link className="text-primary-500" to={to} state={state}>
        {text}
      </Link>
    </Fragment>
  );
}
