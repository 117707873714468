import { Typography } from "@material-tailwind/react";
import { getFileNameFromPath } from "../../../utils/stringManipulation";

function FileDetails({
  file,
  index,
}: {
  file: { file_store: { url: string } };
  index: number;
}) {
  const fileName = getFileNameFromPath(file.file_store.url);
  return (
    <div key={index} className="flex flex-col gap-2">
      <Typography variant="small">File Name:</Typography>
      <Typography className="font-bold">{fileName}</Typography>
      <a
        href={file.file_store.url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-orange-400 underline"
      >
        Download
      </a>
    </div>
  );
}

export default FileDetails;
