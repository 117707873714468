/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

import { useReimbursementContext } from "../reimbursement/reimbursementContext";

import {
  getReimbursementIdentifierMatched,
  getReimbursementsByLead,
} from "../../api/reimbursement.api";
import {
  ReimbursementEmbeddingMatch,
  ReimbursementRow,
} from "../../api/reimbursement.type";

export const DuplicationContextWrapper = (props: any) => {
  const { activeReimbursement } = useReimbursementContext();

  const [reimbursementsByLead, setReimbursementsByLead] = useState<
    ReimbursementRow[]
  >([]);
  const [reimbursementEmbeddingMatches, setReimbursementEmbeddingMatches] =
    useState<ReimbursementEmbeddingMatch[]>();

  const useGetReimbursementsByLead = useMutation(getReimbursementsByLead, {
    onSuccess: async (res) => {
      setReimbursementsByLead(res);
    },
  });

  const useGetReceiptIdentifierMatches = useMutation(
    getReimbursementIdentifierMatched,
    {
      onSuccess: async (res) => {
        setReimbursementEmbeddingMatches(res);
      },
    }
  );

  useEffect(() => {
    if (activeReimbursement && activeReimbursement?.lead_id) {
      useGetReimbursementsByLead.mutate(activeReimbursement?.lead_id);
    }

    if (activeReimbursement && activeReimbursement?.receipt_identifier) {
      useGetReceiptIdentifierMatches.mutate(activeReimbursement?.id);
    }
  }, [activeReimbursement]);

  const value = { reimbursementsByLead, reimbursementEmbeddingMatches };

  return (
    <DuplicationContext.Provider value={value}>
      {props.children}
    </DuplicationContext.Provider>
  );
};

export const DuplicationContext = createContext({
  reimbursementsByLead: [] as ReimbursementRow[],
  reimbursementEmbeddingMatches: [] as
    | ReimbursementEmbeddingMatch[]
    | undefined,
});

export const useDuplicationContext = () => useContext(DuplicationContext);
