import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import SelectCampaign from "../campaign/selectCampaign";
import { CampaignRow } from "../../api/campaign.type";

function CampaignSelectNode({
  data,
  id,
}: {
  id: string;
  data: {
    name: string;
    campaigns: CampaignRow[];
    setSelectedCampaign: (campaign: CampaignRow | undefined) => void;
    selectedCampaign?: CampaignRow;
  };
}) {
  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
      <div className="flex">
        <div className="ml-2">
          <SelectCampaign
            campaigns={data.campaigns}
            setSelectedCampaign={data.setSelectedCampaign}
            selectedCampaign={data.selectedCampaign}
          />
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Top}
        className="w-16 !bg-teal-500"
      />
    </div>
  );
}

export default memo(CampaignSelectNode);
