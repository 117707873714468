import { Typography } from "@material-tailwind/react";
import { useState } from "react";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import { handlePageNav } from "./handlePagination";
import TableWithSearch from "./tableWithSearch";

type TableHeadEntry = {
  label: string;
  key: string;
};

type ArbitraryObject = { [key: string]: any };

function mapObjectKeysToTableHead(obj: ArbitraryObject): TableHeadEntry[] {
  return Object.keys(obj).map((key) => ({
    label: snakeCaseToTitleCase(key),
    key,
  }));
}

function GenericTable({
  type,
  structure,
  rowsPerPage,
  data,
  additionalButtons,
  isDownloadable,
}: {
  type: string;
  structure: any;
  rowsPerPage: any;
  data: any[];
  additionalButtons?: React.ReactNode;
  isDownloadable?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const tableHead = mapObjectKeysToTableHead(structure);
  function baseFilter() {
    return (item: any, index: number) => index < 10;
  }

  const [filterFunction, setFilterFunction] =
    useState<(item: any, index: number) => boolean>(baseFilter);

  return (
    <TableWithSearch
      downloadData={
        isDownloadable
          ? {
              headers: tableHead,
              csvData: data,
              fileName: `${type}.csv`,
            }
          : undefined
      }
      tableHead={tableHead}
      type={type}
      rowsPerPage={rowsPerPage}
      rowCount={data.length ? data.length : 0}
      currentPage={currentPage}
      additionalButtons={additionalButtons}
      handleNextPage={() =>
        handlePageNav({
          currentPage,
          pageSetFunction: setCurrentPage,
          rowsPerPage,
          setFilterFunction: setFilterFunction,
          advancePage: true,
        })
      }
      handlePreviousPage={() =>
        handlePageNav({
          currentPage,
          pageSetFunction: setCurrentPage,
          rowsPerPage,
          setFilterFunction: setFilterFunction,
          advancePage: false,
        })
      }
    >
      {data
        ?.filter((i, index) => filterFunction?.(i, index) ?? true)
        .map((item, index) => {
          const isLast = index === data.length - 1;
          const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
          return (
            <tr key={index} className="even:bg-blue-gray-50/50">
              {Object.keys(item).map((key) => {
                return (
                  <td className={classes} key={key}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item[key]}
                    </Typography>
                  </td>
                );
              })}
            </tr>
          );
        })}
    </TableWithSearch>
  );
}

export default GenericTable;
