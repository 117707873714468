/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { uniqueStrings } from "../../utils/objectManipulation";
import EditButton from "../buttons/editButton";
import { useCompanyContext } from "../company/companyContext";
import GenericSelect from "../forms/GenericSelect";
import PlentiUpdateOrCancelButtons from "../forms/PlentiUpdateOrCancelButtons";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import { useLoadEmailDns } from "./hooks/useLoadEmailDns";

function DefaultEmailForm() {
  const [isEdit, setIsEdit] = useState(false);

  const [uniqueEmailSuffixes, setUniqueEmailSuffixes] = useState<string[]>([]);
  const [selectedEmailSuffix, setSelectedEmailSuffix] = useState<string>("");

  const { emailDns } = useLoadEmailDns();

  const { selectedCompanyConfig, updateCompanyConfig } = useCompanyContext();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      default_email: selectedCompanyConfig?.default_email,
    },
  });

  function onSubmit(data: any) {
    const email = data.default_email.trim() + "@" + selectedEmailSuffix;
    updateCompanyConfig({
      id: selectedCompanyConfig?.id as string,
      values: { default_email: email },
    });
    reset();
    setIsEdit(false);
  }

  useEffect(() => {
    if (emailDns && emailDns.length > 0) {
      const list = [];

      for (let i = 0; i < emailDns.length; i++) {
        if (emailDns[i].valid) {
          const email = emailDns[i].subdomain
            ? `${emailDns[i].subdomain}.${emailDns[i].domain}`
            : emailDns[i].domain;
          list.push(email);
        }
      }

      setUniqueEmailSuffixes(uniqueStrings(list));

      if (uniqueEmailSuffixes?.length > 0) {
        setSelectedEmailSuffix(uniqueEmailSuffixes[0]);
      }
    }
  }, [emailDns]);

  useEffect(() => {
    if (selectedCompanyConfig && !selectedCompanyConfig?.default_email) {
      setIsEdit(true);
    }
  }, [selectedCompanyConfig]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between">
        <div className="flex flex-row gap-2">
          <Typography variant="h6">Default Email</Typography>
        </div>
        <EditButton
          iconClass="h-4 w-4"
          text="Edit"
          onClick={() => {
            setIsEdit(!isEdit);
          }}
        />
      </div>

      <Divider className="my-1" />
      {uniqueEmailSuffixes && uniqueEmailSuffixes?.length > 0 ? (
        <div className="flex flex-row justify-between gap-2 mt-5">
          {!isEdit && selectedCompanyConfig?.default_email ? (
            <ValueWithLabel
              label="Default Email"
              value={selectedCompanyConfig?.default_email as string}
            />
          ) : (
            <div>
              <div className="flex flex-row items-center">
                <PlentiInput
                  register={register}
                  name="default_email"
                  label="Default Email"
                  errors={errors}
                />
                <Typography variant="h5">@</Typography>
                <GenericSelect
                  values={uniqueEmailSuffixes}
                  setSelected={setSelectedEmailSuffix}
                  selected={selectedEmailSuffix}
                />
              </div>
              {watch("default_email") && selectedCompanyConfig && (
                <Typography>
                  {watch("default_email")}@{selectedEmailSuffix}
                </Typography>
              )}
            </div>
          )}
        </div>
      ) : (
        <Typography>
          No valid email DNS records found. Please add email DNS records below.
        </Typography>
      )}
      <PlentiUpdateOrCancelButtons
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        disabled={watch("default_email") && selectedEmailSuffix ? false : true}
      />
    </form>
  );
}

export default DefaultEmailForm;
