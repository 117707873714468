import { useOcrContext } from "../ocr/ocrContext";
import OcrTableList from "../ocr/ocrTableList";
import TableWithSearch from "../table/tableWithSearch";

const TABLE_HEAD = [
  { label: "Amount" },
  { label: "Date/time" },
  { label: "Line items count" },
  { label: "Line items" },
  { label: "Message" },
];

function OcrContainer() {
  const { ocrs } = useOcrContext();

  return (
    <div className="flex flex-col gap-4">
      <TableWithSearch
        tableHead={TABLE_HEAD}
        type="ocr"
        rowCount={ocrs ? ocrs?.length : 0}
        rowsPerPage={10}
      >
        <OcrTableList ocrs={ocrs} />
      </TableWithSearch>
    </div>
  );
}

export default OcrContainer;
