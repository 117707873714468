import { DeliveryMethods } from "../../api/campaign.type";
import InfoPopover from "../InfoPopover/infoPopover";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { MailIcon, PhoneIcon } from "../icons/icons";

function CampaignDeliveryTypeIcons({
  deliveryType,
}: {
  deliveryType: DeliveryMethods | undefined;
}) {
  return (
    <div className="relative w-fit">
      <PlentiFormLabel>Delivery Method</PlentiFormLabel>
      {deliveryType === "email_sms" && (
        <div className="flex flex-row gap-2">
          <PhoneIcon className="w-6 h-6" /> or <MailIcon className="w-6 h-6" />
        </div>
      )}
      {deliveryType === "sms" && (
        <div className="flex flex-row gap-2">
          <PhoneIcon className="w-6 h-6" />
        </div>
      )}
      {deliveryType === "email" && (
        <div className="flex flex-row gap-2">
          <MailIcon className="w-6 h-6" />
        </div>
      )}
      <div className="absolute top-[-12px] right-[-15px]">
        <InfoPopover>
          <p className="text-slate-700">
            {deliveryType === "email_sms"
              ? "This campaign will be delivered via SMS/text message or Email. You can set a backup message content if the user does not have an email or phone number."
              : deliveryType === "email"
              ? "This campaign will be delivered via Email. If the user does not have an email, the campaign message will not be delivered."
              : "This campaign will be delivered via SMS/text message. If the user does not have a phone number, the campaign message will not be delivered"}
          </p>
        </InfoPopover>
      </div>
    </div>
  );
}

export default CampaignDeliveryTypeIcons;
