/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ExtractionSchemaRow } from "../../api/extractionSchema.type";
import { useAlertContext } from "../alert/alertContext";
import { useCampaignContext } from "../campaign/campaignContext";
import AddExtractionSchemaCampaignHeader from "./AddExtractionSchemaCampaignHeader";
import CampaignSchemaAddNewHeader from "./CampaignSchemaAddNewHeader";
import ExtractionSchemaCampaignList from "./ExtractionSchemaCampaignList";
import { useExtractionSchemaContext } from "./ExtractionSchemaContext";
import ExtractionSchemaList from "./ExtractionSchemaListContainer";

function AddExtractionSchemaCampaignContainer() {
  let [searchParams] = useSearchParams();
  const { setAlertProps } = useAlertContext();

  const campaignId = searchParams.get("campaignId");

  const { getCampaign, activeCampaign } = useCampaignContext();

  const {
    schema,
    extractionSchemaCampaigns,
    addSchemaToCampaign,
    removeFromCampaign,
  } = useExtractionSchemaContext();

  const [mappedSchema, setMappedSchema] = useState<ExtractionSchemaRow[]>();

  const mapSchema = () =>
    extractionSchemaCampaigns?.map((esc) => {
      if (!esc) {
        return {
          id: "",
          name: "",
          created_at: "",
          updated_at: "",
          schema: [],
        };
      }
      const { extraction_schema } = esc;
      if (!extraction_schema) {
        return {
          id: "",
          name: "",
          created_at: "",
          updated_at: "",
          schema: [],
        };
      }
      return {
        id: extraction_schema.id,
        name: extraction_schema.name,
        created_at: extraction_schema.created_at,
        updated_at: extraction_schema.updated_at,
        schema: extraction_schema.schema,
      };
    });

  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
    }
  }, [campaignId]);

  useEffect(() => {
    if (extractionSchemaCampaigns) {
      setMappedSchema(mapSchema());
    }
  }, [extractionSchemaCampaigns]);

  function handleSetSelected(value: ExtractionSchemaRow) {
    const foundEsc = extractionSchemaCampaigns?.find(
      (esc) => esc.extraction_schema.id === value.id
    );

    if (foundEsc) {
      setAlertProps({
        message: "This schema is already added to the campaign",
        color: "yellow",
      });

      return;
    }

    addSchemaToCampaign({
      campaignId: campaignId || "",
      extractionSchemaId: value.id,
    });
  }

  function handleRemoveFromCampaign(value: ExtractionSchemaRow) {
    const foundEsc = extractionSchemaCampaigns?.find(
      (esc) => esc.extraction_schema.id === value.id
    );

    if (foundEsc) {
      removeFromCampaign(foundEsc.id);
    }
  }
  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <AddExtractionSchemaCampaignHeader
        campaign={activeCampaign}
        existingSchema={mappedSchema && mappedSchema?.length > 0 ? true : false}
      />

      {campaignId && (
        <div>
          {mappedSchema && mappedSchema.length > 0 ? (
            <ExtractionSchemaCampaignList
              setSelectedSchema={handleRemoveFromCampaign}
              schema={mappedSchema}
            />
          ) : (
            <div className="flex flex-col gap-2">
              <CampaignSchemaAddNewHeader />
              <Typography>
                This campaign does not have any schema. Add one from the list
                below or create a new schema.
              </Typography>
            </div>
          )}
        </div>
      )}
      <ExtractionSchemaList
        setSelectedSchema={handleSetSelected}
        schema={schema}
      />
    </div>
  );
}

export default AddExtractionSchemaCampaignContainer;
