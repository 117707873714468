import { Typography } from "@material-tailwind/react";
import AddButton from "../buttons/addButton";
import { useUserContext } from "../user/userContext";
import SelectCompany from "../company/selectCompany";

function BillingHeader({ handleAddPayment }: { handleAddPayment: () => void }) {
  const { user } = useUserContext();

  return (
    <div className="flex flex-col gap-10">
      <div className="flex justify-between items-start">
        <div className="flex flex-col">
          <Typography variant="h2" className="mb-4 text-slate-500 ">
            Billing
          </Typography>
          <Typography
            variant="paragraph"
            className="max-w-2xl text-Sofia-pro font-heavy subpixel-antialiased"
          >
            Purchase Plenti credits to send messages and reimburse your
            customers. You can also set automatic replenishment of credits.
          </Typography>
        </div>

        <AddButton
          iconClass="h-4 w-4"
          onClick={handleAddPayment}
          color="green"
          size="lg"
          variant="outlined"
        >
          Add Payment Method
        </AddButton>
      </div>
      {user?.type === "admin" && (
        <div className="max-w-md">
          <SelectCompany />
        </div>
      )}
    </div>
  );
}

export default BillingHeader;
