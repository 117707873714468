/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { useLeadContext } from "../lead/leadContext";
import { ReimbursementContextWrapper } from "../reimbursement/reimbursementContext";
import LeadDetailsAdmin from "./LeadDetailsAdmin";

function ViewLeadContainer({ id }: { id: string | undefined | string[] }) {
  const { activeLead, useGetLeadById } = useLeadContext();

  const breadCrumbsConfig = [
    {
      name: "Leads",
      href: "/leads",
      active: false,
    },
    {
      name: "Lead",
      href: `/lead/${id}`,
      active: true,
    },
    {
      name: "Chat",
      href: `/lead/chat/${id}`,
      active: false,
    },
  ];

  useEffect(() => {
    if (id) {
      useGetLeadById.mutate(id as string);
    }
  }, [id]);

  return (
    <ReimbursementContextWrapper>
      <div className="p-5">
        <BreadcrumbsFromConfig config={breadCrumbsConfig} />
        <div className="mt-4">
          <LeadDetailsAdmin lead={activeLead} />
        </div>
      </div>
    </ReimbursementContextWrapper>
  );
}

export default ViewLeadContainer;
