/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import DemoContainer from "../demo/demoContatiner";
import { useDemoContext } from "../demo/demoContext";
import ViewOcrContainer from "../ocr/viewOcr";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import ViewReimbursementDetailsAdmin from "../reimbursement/viewReimbursementDetailsAdmin";

function AdminDemoContainer({ campaignId }: { campaignId: string }) {
  const { leadReimbursement } = useDemoContext();
  const { setActiveReimbursement } = useReimbursementContext();

  useEffect(() => {
    if (leadReimbursement) {
      setActiveReimbursement(leadReimbursement);
    }
  }, [leadReimbursement]);

  return (
    <div className="flex flex-col gap-2">
      <DemoContainer campaignId={campaignId} />
      <div className="p-5">
        {leadReimbursement && (
          <div className="flex flex-col gap-5">
            <div>
              <ViewReimbursementDetailsAdmin
                activeReimbursement={leadReimbursement}
              />
              {leadReimbursement.receipt_ocr && (
                <ViewOcrContainer activeOcr={leadReimbursement.receipt_ocr} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminDemoContainer;
