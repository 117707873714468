export const BrowserSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      fill="white"
      viewBox="0 0 192 192"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke="#000" stroke-width="12">
        <circle cx="96" cy="96" r="74" />
        <ellipse cx="96" cy="96" rx="30" ry="74" />
        <path
          d="m28 72h136m-136 48h136"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
