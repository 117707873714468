/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { useImageMetadataContext } from "../feedback/hooks/ImageMetadataContext";
import ImageDetails from "../feedback/ImageDetails";
import ImageCarousel from "./ImageCarousel";
import { Divider } from "@tremor/react";

function ViewImageContainer({ id }: { id?: string }) {
  const { imageMetadata, getImage } = useImageMetadataContext();

  useEffect(() => {
    if (id) {
      getImage(id);
    }
  }, [id]);

  return (
    <div className="p-5 flex flex-col gap-5">
      <div className="flex justify-between">
        <BreadcrumbsFromConfig
          config={[
            {
              name: "Image Gallery",
              href: "/feedback?tab=images",
              active: false,
            },
            {
              name: "Image Details",
              href: `/images/${id}`,
              active: true,
            },
          ]}
        />
      </div>
      {imageMetadata && (
        <div className="flex flex-col gap-10">
          <ImageDetails image={imageMetadata} />
          <Divider />
          <ImageCarousel images={[imageMetadata]} />
        </div>
      )}
    </div>
  );
}

export default ViewImageContainer;
