import { useNavigate } from "react-router-dom";
import AddButton from "../buttons/addButton";

function NavToAddButton() {
  const navigate = useNavigate();
  return (
    <AddButton
      iconClass="h-4 w-4"
      onClick={() => {
        navigate("/content/add");
      }}
      color="green"
      size="lg"
      variant="outlined"
    >
      Add new content
    </AddButton>
  );
}

export default NavToAddButton;
