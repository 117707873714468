/* eslint-disable react/jsx-pascal-case */
import { Divider } from "@tremor/react";
import GridGallery from "../images/Gallery";
import ImageFilter from "./ImageFilters";
import {
  ImageMetadataContextWrapper,
  useImageMetadataContext,
} from "./hooks/ImageMetadataContext";
import { useLoadImageMetadata } from "./hooks/useLoadImageMetadata";
import { ImageMetadataRow } from "../../api/imageMetadata.type";

function _ChatImageContainer() {
  const { imageMetadata } = useLoadImageMetadata();
  const { filteredImages, isLoading, selectedCampaign, selectedType } =
    useImageMetadataContext();

  return (
    <div>
      <ImageFilter />
      <Divider />

      <div className="w-full">
        {!isLoading && (
          <div>
            {filteredImages && (
              <GridGallery
                images={
                  selectedCampaign || (selectedType && filteredImages?.data)
                    ? filteredImages?.data
                    : (imageMetadata?.filter(
                        (i) => i.media_url
                      ) as ImageMetadataRow[])
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const ChatImageContainer = () => {
  return (
    <ImageMetadataContextWrapper>
      <_ChatImageContainer />
    </ImageMetadataContextWrapper>
  );
};

export default ChatImageContainer;
