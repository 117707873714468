import { Card, Typography } from "@material-tailwind/react";
import { useStripeContext } from "../stripe/stripeContext";
import PaymentMethodListItem from "./paymentMethodListItem";

const TABLE_HEAD = [
  "Account name",
  "Account number",
  "Expiration date ",
  "Billing address",
  "Auto recharge setup",
];

function PaymentMethodList() {
  const { paymentMethods } = useStripeContext();

  return (
    <Card className="h-full w-full ">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paymentMethods &&
            paymentMethods.map((paymentMethod, index) => {
              const isLast = index === paymentMethods.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <PaymentMethodListItem
                  paymentMethod={paymentMethod}
                  classes={classes}
                  index={index}
                />
              );
            })}
        </tbody>
      </table>
    </Card>
  );
}

export default PaymentMethodList;
