import AddSmsContentContainer from "../components/smsContent/addSmsContent";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const AddSmsContent = () => {
  mixpanelTrackEvent("Add sms content");

  return <AddSmsContentContainer />;
};

export default AddSmsContent;
