import { createContext, useContext, useState } from "react";
import { useMutation } from "react-query";
import { addPreUser, updatePreUserById } from "../../api/preUser.api";
import { PreUserRow } from "../../api/preUser.type";
import { CreatePreUser, UpdatePreUser } from "./PreUserContext.types";

export const PreUserContextWrapper = (props: any) => {
  const [createdPreUser, setCreatedPreUser] = useState<PreUserRow>();

  const { mutate: createPreUser } = useMutation(addPreUser, {
    onSuccess: async (res) => {
      setCreatedPreUser(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: updatePreUser } = useMutation(updatePreUserById, {
    onSuccess: async (res) => {
      setCreatedPreUser(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const value = {
    createPreUser,
    updatePreUser,
    createdPreUser,
  };

  return (
    <PreUserContext.Provider value={value}>
      {props.children}
    </PreUserContext.Provider>
  );
};

export const PreUserContext = createContext({
  createPreUser: {} as CreatePreUser,
  updatePreUser: {} as UpdatePreUser,
  createdPreUser: {} as PreUserRow | undefined,
});

export const usePreUserContext = () => useContext(PreUserContext);
