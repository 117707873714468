import AddPromptContainer from "../components/prompt/addPrompt";
import { PromptContextWrapper } from "../components/prompt/promptContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const AddPrompt = () => {
  mixpanelTrackEvent("Add prompt");

  return (
    <PromptContextWrapper>
      <AddPromptContainer />
    </PromptContextWrapper>
  );
};

export default AddPrompt;
