import { ProgressCircle, Text } from "@tremor/react";

function PercentCircle({ value }: { value: number }) {
  return (
    <ProgressCircle value={value} size="md">
      <span className="h-12 w-12 rounded-full bg-primary-500 flex items-center justify-center text-primary-300 font-bold bg-opacity-50">
        <Text className="text-primary-300">{value}%</Text>
      </span>
    </ProgressCircle>
  );
}

export default PercentCircle;
