import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useForm } from "react-hook-form";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { ErrorHelperText } from "../forms/errorHelperText";
import { PlentiInput } from "../forms/plentiInput";
import SignupFooterLink from "./SignupFooterLink";
import { useAuthContext } from "./authContext";

function SendResetPasswordEmail() {
  const { loginError, resetPassword, passwordResetInstructions } =
    useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(data: any) {
    resetPassword(data.email);
  }

  return (
    <div className="bg-white m-10 p-8 h-fit rounded-lg shadow-md ">
      <Typography variant="h4" className="my-4">
        Reset Password
      </Typography>

      <Divider className="p-2" />
      {loginError && (
        <div className="w-72 mb-5">
          <ErrorHelperText text={loginError} />
        </div>
      )}
      {passwordResetInstructions && (
        <Typography variant="small" color="gray" className="mb-4">
          {passwordResetInstructions}
        </Typography>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 w-72">
          {!passwordResetInstructions && (
            <PlentiInput
              register={register}
              errors={errors}
              label="Email"
              name="email"
            />
          )}

          {!passwordResetInstructions && (
            <ButtonSecondary type="submit">
              Send Password Reset Email
            </ButtonSecondary>
          )}
          <SignupFooterLink />
        </div>
      </form>
    </div>
  );
}

export default SendResetPasswordEmail;
