import { FontContextWrapper } from "../components/font/fontContext";
import AdminContainer from "../components/admin/adminContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Admin = () => {
  mixpanelTrackEvent("Admin page");

  return (
    <FontContextWrapper>
      <AdminContainer />
    </FontContextWrapper>
  );
};

export default Admin;
