import { Card, Typography } from "@material-tailwind/react";
import PaymentListItem from "./PaymentListItem";
import { usePaymentContext } from "./hooks/PaymentContext";

const TABLE_HEAD = [
  "Payment Date",
  "Payment Method",
  "Account Number",
  "Payment Amount",
  "Status",
];

function PaymentHistory() {
  const { payments } = usePaymentContext();

  return (
    <Card className="h-full w-full ">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {payments &&
            payments.length > 0 &&
            payments?.map((payment, index) => {
              const isLast = index === payments.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <PaymentListItem
                  payment={payment}
                  classes={classes}
                  index={index}
                />
              );
            })}
        </tbody>
      </table>
    </Card>
  );
}

export default PaymentHistory;
