/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

import {
  GetLocationCountMutation,
  GetReimbursementByCohortMutation,
  GetReimbursementMutation,
} from "../analytics/analyticsContext.types";

import { CampaignRow } from "../../api/campaign.type";
import { getReimbursementsByCohort } from "../../api/leadAnalytics";
import { ReimbursementCohort } from "../../api/leadAnalytics.type";
import { getLocationCounts } from "../../api/location.api";
import { LocationCount } from "../../api/location.type";
import { getReimbursementsByCampaign } from "../../api/reimbursement.api";
import { ReimbursementRow } from "../../api/reimbursement.type";
import { useCampaignContext } from "../campaign/campaignContext";

export const AnalyticsContextWrapper = (props: any) => {
  const { campaigns } = useCampaignContext();
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignRow>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [locationCounts, setLocationCounts] = useState<
    LocationCount[] | undefined
  >([]);

  const [reimbursementsByCohort, setReimbursementsByCohort] =
    useState<ReimbursementCohort[]>();
  const [reimbursements, setReimbursements] = useState<ReimbursementRow[]>([]);

  const useGetReimbursementsByCohort = useMutation(getReimbursementsByCohort, {
    onSuccess: async (res) => {
      setReimbursementsByCohort(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useGetReimbursements = useMutation(getReimbursementsByCampaign, {
    onSuccess: async (res) => {
      setReimbursements(res);
    },
  });

  const useGetLocationCounts = useMutation(getLocationCounts, {
    onSuccess: async (res) => {
      setLocationCounts(res);
    },
  });

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
    }
  }, [campaigns]);

  const value = {
    useGetReimbursementsByCohort,
    reimbursementsByCohort,
    useGetReimbursements,
    reimbursements,
    useGetLocationCounts,
    locationCounts,
    selectedCampaign,
    setSelectedCampaign,
    setStartDate,
    startDate,
    setEndDate,
    endDate,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsContext = createContext({
  useGetReimbursementsByCohort: {} as GetReimbursementByCohortMutation,
  reimbursementsByCohort: [] as ReimbursementCohort[] | undefined,
  useGetReimbursements: {} as GetReimbursementMutation,
  reimbursements: [] as ReimbursementRow[] | undefined,
  useGetLocationCounts: {} as GetLocationCountMutation,
  locationCounts: [] as LocationCount[] | undefined,
  selectedCampaign: {} as CampaignRow | null | undefined,
  setSelectedCampaign: {} as React.Dispatch<
    React.SetStateAction<CampaignRow | undefined>
  >,
  setStartDate: {} as React.Dispatch<Date | null>,
  startDate: new Date() as Date | null,
  setEndDate: {} as React.Dispatch<Date | null>,
  endDate: new Date() as Date | null,
});

export const useAnalyticsContext = () => useContext(AnalyticsContext);
