/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { useCampaignContext } from "../campaign/campaignContext";
import CampaignDetailsContainer from "../campaign/campaignDetailsContainer";
import CampaignLinksContainer from "../campaign/campaignLinksContainer";
import CampaignTryDetailsContainer from "../campaign/campaignTryDetailsContainer";
import {
  CampaignContentContextWrapper,
  useCampaignContentContext,
} from "../campaignSmsContent/campaignSmsContentContext";
import TryHeader from "../try/tryHeader";
import TryHeaderBody from "../try/tryHeaderBody";
import TrySteps from "../try/trySteps";
import CampaignContentContainer from "./CampaignContentContainer";

import { CampaignRow } from "../../api/campaign.type";
import { CompanyRow } from "../../api/company.type";
import { isEmailTemplateRow } from "../../api/emailTemplate.type";
import { SmsContentRow } from "../../api/smsContent.type";
import CampaignAgentsContainer from "../campaign/campaignAgentsContainer";
import CampaignPromptContainer from "../campaign/campaignPromptContainer";
import CampaignSmsContentListContainer from "../campaign/campaignSmsContentListContainer";
import { CampaignAiFunctionContextWrapper } from "../campaignAiFunction/campaignAiFunctionContext";
import DisplayEmailOrSmsContent, {
  DisplayEmailContent,
  DisplaySmsContent,
} from "../campaignSmsContent/DisplayEmailOrSmsContent";
import { FontContextWrapper } from "../font/fontContext";
import PhoneDisplayLayout from "../layout/PhoneDisplayLayout";
import { PromptContextWrapper } from "../prompt/promptContext";
import CampaignFlowContainer from "./CampaignFlowContainer";
import CampaignLeadsContainer from "./CampaignLeadsContainer";
import CampaignFollowupContainer from "./followUp/campaignFollowupContainer";

function _EditCampaign({ id }: { id: string | undefined | string[] }) {
  const { activeCampaign, getCampaign, associatedCompany } =
    useCampaignContext();

  const {
    campaignDefaultSmsContent,
    campaignSpecificContent,
    selectedContent,
  } = useCampaignContentContext();

  useEffect(() => {
    if (id && typeof id === "string") {
      getCampaign(id);
    }
  }, [id]);

  const breadCrumbsConfig = [
    {
      name: "Campaigns",
      href: "/campaign",
      active: false,
    },
    {
      name: "Edit",
      href: `/campaign/${id}`,
      active: true,
    },
  ];

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      // Placeholder for scroll handling logic
      const detailsElement = document.getElementById("details");
      const tryDetailsElement = document.getElementById("try-details");
      const defaultContentElement = document.getElementById("default-content");
      const contentListElement = document.getElementById("content-list");

      const positions = {
        detailsElement: detailsElement?.getBoundingClientRect(),
        tryDetailsElement: tryDetailsElement?.getBoundingClientRect(),
        defaultContentElement: defaultContentElement?.getBoundingClientRect(),
        contentListElement: contentListElement?.getBoundingClientRect(),
      };

      const scrollPosition = window.scrollY;

      if (
        scrollPosition > (positions?.detailsElement?.top ?? 0) &&
        scrollPosition < (positions?.detailsElement?.bottom ?? 0)
      ) {
        setActiveSection("CampaignDetails");
      } else if (
        scrollPosition > (positions?.tryDetailsElement?.top ?? 0) &&
        scrollPosition < (positions?.tryDetailsElement?.bottom ?? 0)
      ) {
        setActiveSection("CampaignTryDetails");
      } else if (
        scrollPosition > (positions.defaultContentElement?.top ?? 0) &&
        scrollPosition < (positions.defaultContentElement?.bottom ?? 0)
      ) {
        setActiveSection("DefaultContent");
      } else if (
        scrollPosition > (positions.contentListElement?.top ?? 0) &&
        scrollPosition < (positions.contentListElement?.bottom ?? 0)
      ) {
        setActiveSection("CampaignContent");
      }

      // Add more conditions for other components
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderStickyContent = () => {
    switch (activeSection) {
      case "CampaignDetails":
        return (
          <Fragment>
            <TryHeader>
              <TryHeaderBody />
            </TryHeader>
            <TrySteps />
          </Fragment>
        );
      case "DefaultContent":
        return (
          <div>
            {selectedContent ? (
              <div>
                {isEmailTemplateRow(selectedContent) ? (
                  <DisplayEmailContent content={selectedContent.content} />
                ) : (
                  <DisplaySmsContent
                    content={[selectedContent] as SmsContentRow[]}
                  />
                )}
              </div>
            ) : (
              <DisplayEmailOrSmsContent
                content={
                  campaignDefaultSmsContent
                    ? [campaignDefaultSmsContent]
                    : undefined
                }
              />
            )}
          </div>
        );
      case "CampaignContent":
        return (
          <DisplayEmailOrSmsContent
            content={
              campaignSpecificContent && campaignDefaultSmsContent
                ? [campaignDefaultSmsContent].concat(campaignSpecificContent)
                : undefined
            }
          />
        );
      default:
        return (
          <Fragment>
            <TryHeader>
              <TryHeaderBody />
            </TryHeader>
            <TrySteps />
          </Fragment>
        );
    }
  };

  return (
    <div className="p-5">
      <BreadcrumbsFromConfig config={breadCrumbsConfig} />
      <div className="mt-10 grid grid-cols-3 gap-10 ">
        <div className="col-span-2 flex flex-col gap-10">
          <CampaignDetailsContainer />
          <CampaignTryDetailsContainer />
          <CampaignLinksContainer />
          <CampaignLeadsContainer />
          <CampaignContentContainer />
          <CampaignFollowupContainer />
          <CampaignSmsContentListContainer />
          <CampaignPromptContainer />
          <CampaignAgentsContainer />
          <CampaignFlowContainer campaignId={activeCampaign?.id as string} />
        </div>

        {activeCampaign && (
          <div
            style={{
              position: "sticky",
              top: "10%",
              height: "fit-content",
            }}
          >
            <PhoneDisplayLayout
              company={associatedCompany as CompanyRow}
              campaign={activeCampaign as CampaignRow}
            >
              {renderStickyContent()}
            </PhoneDisplayLayout>
          </div>
        )}
      </div>
    </div>
  );
}

const EditCampaign = ({ id }: { id: string | undefined | string[] }) => {
  return (
    <CampaignContentContextWrapper>
      <PromptContextWrapper>
        <CampaignAiFunctionContextWrapper>
          <FontContextWrapper>
            <_EditCampaign id={id} />
          </FontContextWrapper>
        </CampaignAiFunctionContextWrapper>
      </PromptContextWrapper>
    </CampaignContentContextWrapper>
  );
};

export default EditCampaign;
