import { Select, Option } from "@material-tailwind/react";
import { useStripeContext } from "../stripe/stripeContext";

function PaymentMethodSelect() {
  const { paymentMethods, selectedPaymentMethod, setSelectedPaymentMethod } =
    useStripeContext();

  return (
    <Select
      label="Payment method"
      value={selectedPaymentMethod ? selectedPaymentMethod.id : undefined}
      onChange={(value) => {
        if (value) {
          const found = paymentMethods?.find((method) => method.id === value);
          if (found) {
            setSelectedPaymentMethod(found);
          }
        }
      }}
    >
      {paymentMethods?.map((method, index) => {
        const name =
          method.type === "card"
            ? method.card.brand
            : method.us_bank_account?.bank_name;

        const last4 =
          method.type === "card"
            ? method.card.last4
            : method.us_bank_account?.last4;
        return (
          <Option key={index} value={method.id} className="capitalize">
            {name} - {last4}
          </Option>
        );
      })}
    </Select>
  );
}

export default PaymentMethodSelect;
