import { createContext, useContext } from "react";
import { PaymentRow } from "../../../api/payment.type";
import { useLoadPayments } from "./useLoadPayments";

export const PaymentContextWrapper = (props: any) => {
  const { payments } = useLoadPayments();

  const value = {
    payments,
  };

  return (
    <PaymentContext.Provider value={value}>
      {props.children}
    </PaymentContext.Provider>
  );
};

export const PaymentContext = createContext({
  payments: [] as PaymentRow[] | undefined,
});

export const usePaymentContext = () => useContext(PaymentContext);
