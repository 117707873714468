/* eslint-disable react-hooks/exhaustive-deps */
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useAlertContext } from "../alert/alertContext";
import {
  AddBulkMutation,
  CreateNewLocationMutation,
  GetLocationsByCompanyMutation,
  SearchLocation,
  UpdateLocationMutation,
} from "../location/locationContext.types";

import {
  addBulkLocations,
  createLocation,
  getChains,
  getLocationByCompany,
  getLocationById,
  getLocations,
  searchForLocation,
  updateCompanyLocation,
} from "../../api/location.api";
import {
  ChainRow,
  LocationRow,
  LocationRowWithCompanyLocation,
} from "../../api/location.type";

export const LocationContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const [activeLocation, setActiveLocation] = useState<LocationRow>();
  const [companyLocations, setCompanyLocations] = useState<LocationRow[]>();
  const session = useSession();
  const queryClient = useQueryClient();

  const { data: chains } = useQuery({
    queryKey: "chains",
    queryFn: () => getChains(),
    enabled: session && session.access_token ? true : false,
  });

  const { data: locations } = useQuery({
    queryKey: "locations",
    queryFn: () => getLocations(),
    enabled: session && session.access_token ? true : false,
  });

  const useGetAllLocations = useMutation(getLocationByCompany, {
    onSuccess: async (res) => {
      setCompanyLocations(res);
    },
  });

  const useUpdateCompanyLocation = useMutation(updateCompanyLocation, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("locations");
      setAlertProps({
        color: "green",
        message: "Updated location status",
      });
    },
    onError: async (err: any) => {
      setAlertProps({
        color: "red",
        message: err.message,
      });
    },
  });

  const useGetLocation = useMutation(getLocationById, {
    onSuccess: async (res) => {
      setActiveLocation(res);
    },
  });

  const { mutate: searchLocation } = useMutation(searchForLocation, {
    onSuccess: async (res) => {
      setCompanyLocations(res);
    },
  });

  const { mutate: confirmBulkData } = useMutation(addBulkLocations, {
    onSuccess: async (res) => {
      setAlertProps({
        message: `Successfully add locations. This may take a couple min to update.`,
        color: "green",
      });
    },
    onError: async (err: any) => {
      setAlertProps({
        color: "red",
        message: err.message,
      });
    },
  });

  const createNewLocation = useMutation(createLocation, {
    onSuccess: async (res) => {
      setAlertProps({
        message: `Successfully created ${res.name}`,
        color: "green",
      });
      queryClient.invalidateQueries("locations");
    },
    onError: async (err: any) => {
      setAlertProps({
        open: true,
        color: "red",
        message: err.message,
      });
    },
  });

  useEffect(() => {
    if (props.id && !activeLocation) {
      useGetLocation.mutate(props.id);
    }
  }, [props]);

  const value = {
    activeLocation,
    useGetAllLocations,
    companyLocations,
    locations,
    chains,
    createNewLocation,
    useUpdateCompanyLocation,
    confirmBulkData,
    searchLocation,
  };

  return (
    <LocationContext.Provider value={value}>
      {props.children}
    </LocationContext.Provider>
  );
};

export const LocationContext = createContext({
  activeLocation: {} as LocationRow | undefined,
  useGetAllLocations: {} as GetLocationsByCompanyMutation,
  companyLocations: [] as LocationRow[] | undefined,
  locations: [] as LocationRowWithCompanyLocation[] | undefined,
  chains: [] as ChainRow[] | undefined,
  createNewLocation: {} as CreateNewLocationMutation,
  useUpdateCompanyLocation: {} as UpdateLocationMutation,
  confirmBulkData: {} as AddBulkMutation,
  searchLocation: {} as SearchLocation,
});

export const useLocationContext = () => useContext(LocationContext);
