/* eslint-disable react/jsx-pascal-case */
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {
  ExtractionResponseContextWrapper,
  useExtractionResponseContext,
} from "../agents/ExtractionResponseContext";
import {
  ExtractionSchemaContextWrapper,
  useExtractionSchemaContext,
} from "../agents/ExtractionSchemaContext";
import AddButton from "../buttons/addButton";
import ValueWithLabel from "../forms/valueWithLabel";
import { LinkToId } from "../table/linkToId";
import { FormattedLink } from "../table/FormattedLink";

function _CampaignExtractionAgentList({ campaignId }: { campaignId: string }) {
  const navigate = useNavigate();

  const { extractionSchemaCampaigns } = useExtractionSchemaContext();
  const { campaignResponses } = useExtractionResponseContext();

  return (
    <div className="p-5 flex flex-col gap-3">
      {extractionSchemaCampaigns && extractionSchemaCampaigns?.length > 0 ? (
        <>
          {extractionSchemaCampaigns.map((extractionSchema) => {
            return (
              <div className="flex flex-row gap-10">
                <ValueWithLabel
                  label="Name of data to extract"
                  value={extractionSchema.extraction_schema.name}
                />
                <div>
                  <p className="text-xs text-gray-500">Link to view/edit</p>
                  <LinkToId
                    type={`Edit ${extractionSchema.extraction_schema.name}`}
                    id={campaignId}
                    baseUrl={`/agent/extraction/add?campaignId=`}
                  />
                </div>
                <div>
                  <p className="text-xs text-gray-500">
                    {campaignResponses && campaignResponses?.length > 0
                      ? `View ${campaignResponses.length} responses`
                      : "No response data yet"}
                  </p>
                  {campaignResponses && campaignResponses?.length > 0 && (
                    <FormattedLink text="View responses " to={`/feedback`} />
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className=" flex flex-row gap-3 items-center">
            <InformationCircleIcon className="h-5 w-5 text-gray-400" />
            <Typography>
              You have not set data to pull out of this campaign.
            </Typography>
          </div>
          <div>
            <AddButton
              onClick={() =>
                navigate(`/agent/extraction/add?campaignId=${campaignId}`)
              }
              iconClass="h-4 w-4 "
              color="green"
            >
              Add data to extract
            </AddButton>
          </div>
        </>
      )}
    </div>
  );
}

const CampaignExtractionAgentList = ({
  campaignId,
}: {
  campaignId: string;
}) => {
  return (
    <ExtractionResponseContextWrapper campaignId={campaignId}>
      <ExtractionSchemaContextWrapper campaignId={campaignId}>
        <_CampaignExtractionAgentList campaignId={campaignId} />
      </ExtractionSchemaContextWrapper>
    </ExtractionResponseContextWrapper>
  );
};

export default CampaignExtractionAgentList;
