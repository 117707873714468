import { FlowContextWrapper } from "../components/flow/flowContext";
import FlowContainer from "../components/flow/flowContainer";
import { SmsContentContextWrapper } from "../components/smsContent/smsContentContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Flow = () => {
  mixpanelTrackEvent("Flow page");

  return (
    <FlowContextWrapper>
      <SmsContentContextWrapper>
        <FlowContainer />
      </SmsContentContextWrapper>
    </FlowContextWrapper>
  );
};

export default Flow;
