import { Divider } from "@tremor/react";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { Typography } from "@material-tailwind/react";

function AddProductHeader() {
  return (
    <div className="flex flex-col gap-2">
      <BreadcrumbsFromConfig
        config={[
          {
            name: "Products",
            href: "/product",
            active: false,
          },
          {
            name: "Add",
            href: "/flow/add",
            active: true,
          },
        ]}
      />
      <Typography variant="h2" className="my-4 text-slate-500">
        Add a product
      </Typography>
      <Typography variant="paragraph" className="max-w-2xl">
        Add a product that your company sells.
      </Typography>
      <Divider />
    </div>
  );
}

export default AddProductHeader;
