import { useState } from "react";
import { CampaignRow } from "../../../../api/campaign.type";
import { useLoadCampaigns } from "../../../campaign/hooks/useLoadCampaigns";
import SelectCampaign from "../../../campaign/selectCampaign";
import { useLoadLeadCountsByCampaign } from "../../../lead/hooks/useLoadLeadCountByCampaign";
import BaseReimbursementChart from "../components/BaseReimbursementChart";
import { useLoadReimbursementByCampaign } from "../hooks/useLoadReimbursementByCampaign";
import { useLoadReimbursements } from "../hooks/useLoadReimbursements";

export const ReimbursementChartByCampaign = () => {
  const { campaigns } = useLoadCampaigns();

  const [selectedCampaign, setSelectedCampaign] = useState<CampaignRow>();

  const { data: total } = useLoadLeadCountsByCampaign({
    campaignId: selectedCampaign?.id || "",
  });

  const { data } = useLoadReimbursements({
    campaignId: selectedCampaign?.id || "",
  });

  const { data: dailyUsage } = useLoadReimbursementByCampaign({
    campaignId: selectedCampaign?.id || "",
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="max-w-xs">
        <SelectCampaign
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          campaigns={campaigns}
        />
      </div>
      <BaseReimbursementChart
        totals={data}
        dailyUsage={dailyUsage}
        leadCount={total?.total}
      />
    </div>
  );
};
