import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { ExtractionResponseRow } from "../../api/extractionResponse.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { FormattedLink } from "../table/FormattedLink";

function ResponseTableList({
  response,
  filterFunction,
}: {
  response: ExtractionResponseRow[] | undefined;
  filterFunction?: (lead: ExtractionResponseRow, index: number) => boolean;
}) {
  function mapResponseToRow(response: ExtractionResponseRow): {
    schemaName: string;
    campaignName: string;
    createdAt: string;
    name: string;
    questionId: string;
    type: string | null;
    answer: string | null;
    leadId: string;
    schemaId: string | null;
    campaignId: string | null;
  } {
    if (!response) {
      return {
        schemaName: "N/A",
        createdAt: "N/A",
        name: "N/A",
        questionId: "N/A",
        type: "N/A",
        answer: "N/A",
        leadId: "N/A",
        campaignName: "N/A",
        campaignId: null,
        schemaId: null,
      };
    }
    const values = {
      createdAt: response.created_at
        ? formatTimeStampToHumanReadableShortDateTime(response.created_at)
        : "N/A",
      leadId: response.lead_id,
      schemaName: response?.extraction_schema?.name,
      name: response.question_name,
      questionId: response.question_id,
      type: response.question_type,
      answer: response.question_answer,
      campaignName: response?.campaign?.name || "N/A",
      campaignId: response?.campaign?.id || null,
      schemaId: response?.extraction_schema?.id || null,
    };

    return values;
  }
  return (
    <Fragment>
      {response &&
        response
          .filter((response, index) =>
            filterFunction ? filterFunction(response, index) : true
          )
          .map((item) => mapResponseToRow(item))
          .map(
            (
              {
                createdAt,
                name,
                questionId,
                schemaName,
                answer,
                leadId,
                campaignName,
                campaignId,
              },
              index
            ) => {
              const isLast = index === response.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={index}>
                  <td className={classes + " max-w-[100px]"}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {createdAt}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <FormattedLink
                      text={campaignName}
                      to={`/campaign/${campaignId}`}
                    />
                  </td>
                  <td className={classes}>
                    <FormattedLink text={leadId} to={`/lead/chat/${leadId}`} />
                  </td>
                  <td className={classes}>
                    <FormattedLink text={schemaName} to="/agent/extraction" />
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {questionId}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {name}
                    </Typography>
                  </td>

                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {answer}
                    </Typography>
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default ResponseTableList;
