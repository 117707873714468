import { Typography } from "@material-tailwind/react";
import { ImageMetadataRow } from "../../api/imageMetadata.type";
import { Divider } from "@tremor/react";
import ValueWithLabel from "../forms/valueWithLabel";
import { CopyToClipboardIconButton } from "../hooks/copyToClipboard";

function ImageDetails({ image }: { image: ImageMetadataRow | null }) {
  return (
    <div>
      <Typography variant="h6">Image Details</Typography>
      <Divider />
      {image && (
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-row gap-2">
            <ValueWithLabel label="id" value={image.id} />
            <CopyToClipboardIconButton text={image.id} />
          </div>
          <div className="flex flex-row gap-2">
            <ValueWithLabel label="Lead id" value={image.lead_id} />
            <CopyToClipboardIconButton text={image.id} />
          </div>
          <div className="flex flex-row gap-2">
            <ValueWithLabel label="Campaign id" value={image.campaign_id} />
            <CopyToClipboardIconButton text={image.campaign_id} />
          </div>
          <a
            className="text-primary-500"
            href={image.media_url}
            target="_blank"
            rel="noreferrer"
          >
            Link
          </a>
          <ValueWithLabel label="Type" value={image.type} />
          <ValueWithLabel label="Description" value={image.description} />
        </div>
      )}
    </div>
  );
}

export default ImageDetails;
