/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import ChatForm from "../lead/chatForm";
import { useMessageContext } from "../lead/messageContext";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import ReimbursementTableList from "../reimbursement/reimbursementTableList";

function ViewReimbursementContainer({
  id,
}: {
  id: string | undefined | string[];
}) {
  const { activeReimbursement, useGetReimbursementById } =
    useReimbursementContext();

  const { activeLead, handleSubmitMessage, messages, loading, setActiveLead } =
    useMessageContext();

  const breadCrumbsConfig = [
    {
      name: "Reimbursement list",
      href: "/reimbursements",
      active: false,
    },
    {
      name: "View",
      href: `/reimbursement/${id}`,
      active: true,
    },
  ];

  useEffect(() => {
    if (id && typeof id === "string" && !activeReimbursement) {
      useGetReimbursementById.mutate({ id });
    }
  }, [id]);

  useEffect(() => {
    if (activeReimbursement?.lead?.id) {
      setActiveLead(activeReimbursement.lead);
    }
  }, [activeReimbursement]);

  return (
    <div className="p-5">
      <BreadcrumbsFromConfig config={breadCrumbsConfig} />

      {activeReimbursement && (
        <div>
          <table className="mt-4  table-auto rounded-lg bg-blue-gray-50/50 text-left md:w-full md:min-w-max ">
            <ReimbursementTableList reimbursements={[activeReimbursement]} />
          </table>
          <div className="mt-10 flex flex-col gap-4 md:flex-row">
            <div>
              {activeReimbursement?.lead?.id && (
                <div className="">
                  <ChatForm
                    activeLead={activeLead}
                    handleSubmitMessage={handleSubmitMessage}
                    messages={messages}
                    loading={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewReimbursementContainer;
