import { Typography } from "@material-tailwind/react";
import { memo } from "react";
import { Handle, Position } from "reactflow";
import { Metrics } from "../../api/conversation.type";
import { roundDecimalToWholeNumber } from "../../utils/calculateAverage";
import { ExternalLink } from "../icons/icons";
import PercentCircle from "../table/PercentCircle";
import { useNavigate } from "react-router-dom";

export const DEFAULT_HANDLE_STYLE = {
  width: 20,
  height: 20,
  bottom: -5,
  borderRadius: 10,
  border: "2px solid #000",
  backgroundColor: "white",
};

function NameNode({
  data,
}: {
  data: {
    name: string;
    id: string;
    metrics?: Metrics;
  };
}) {
  const navigate = useNavigate();

  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 items-center">
          <div className="text-lg font-bold">{data.name}</div>
          {data.id && (
            <div
              onClick={() => {
                navigate(`/campaign/${data.id}`, {
                  state: {
                    name: "Journeys",
                    href: `/campaign?tab=journeys`,
                    active: false,
                  },
                });
              }}
              style={{ cursor: "pointer" }}
            >
              <ExternalLink className="w-4 h-4 text-purple-500" />
            </div>
          )}
        </div>

        <Typography color="gray" className="text-xs text-center">
          Total: {data?.metrics?.totalLeads}
        </Typography>

        <div className="flex flex-row justify-center gap-2 mb-4">
          {data.metrics && (
            <div>
              <Typography color="gray" className="text-xs text-center">
                Reimbursed{" "}
              </Typography>
              <PercentCircle
                value={roundDecimalToWholeNumber(
                  data.metrics.reimbursementPercent
                )}
              />
            </div>
          )}

          {data.metrics && (
            <div className=" flex flex-col">
              <Typography color="gray" className="text-xs text-center">
                Stale{" "}
              </Typography>
              <PercentCircle
                value={roundDecimalToWholeNumber(
                  data.metrics.totalStaleLeads / data.metrics.totalLeads
                )}
              />
            </div>
          )}
        </div>

        <Handle
          type="target"
          position={Position.Top}
          className="w-16 h-3 !bg-purple-500"
          isConnectable={true}
        />

        <Handle
          type="source"
          position={Position.Bottom}
          id="reimbursed"
          style={{ ...DEFAULT_HANDLE_STYLE, left: "40%" }}
          isConnectable={true}
        />
        <Handle
          type="source"
          id="stale"
          position={Position.Bottom}
          style={{
            ...DEFAULT_HANDLE_STYLE,
            left: "60%",
          }}
          isConnectable={true}
        />
      </div>
    </div>
  );
}

export default memo(NameNode);
