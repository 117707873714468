import { Button, ButtonProps } from "@material-tailwind/react";

import { PlusIcon } from "../icons/icons";

function AddButton({
  onClick,
  iconClass,
  ...props
}: {
  onClick: () => void;
  iconClass: string;
} & React.HTMLAttributes<HTMLButtonElement> &
  ButtonProps) {
  return (
    // @ts-ignore
    <Button
      className="flex items-center gap-2 p-2"
      onClick={onClick}
      ripple={true}
      {...props}
    >
      <PlusIcon className={iconClass} />
      {props.children}
    </Button>
  );
}

export default AddButton;
