/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import DefaultEmailForm from "./DefaultEmailForm";
import EmailDnsTable from "./EmailDnsTable";
import { EmailDnsContextWrapper } from "./hooks/EmailDnsContext";

function _EmailIntegrationContainer() {
  return (
    <div>
      <div className="max-w-2xl p-5 m-2">
        <DefaultEmailForm />
      </div>
      <div className="w-full">
        <EmailDnsTable />
      </div>
    </div>
  );
}

const EmailIntegrationContainer = () => {
  return (
    <EmailDnsContextWrapper>
      <_EmailIntegrationContainer />
    </EmailDnsContextWrapper>
  );
};

export default EmailIntegrationContainer;
