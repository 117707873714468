import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import EditCampaign from "../components/campaign/editCampaign";
import { PromptContextWrapper } from "../components/prompt/promptContext";
import { SmsContentContextWrapper } from "../components/smsContent/smsContentContext";
import { useParams } from "react-router-dom";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewCampaign = () => {
  mixpanelTrackEvent("View campaign page");

  const params = useParams();
  return (
    <CampaignContextWrapper>
      <PromptContextWrapper>
        <SmsContentContextWrapper>
          <EditCampaign id={params.id} />
        </SmsContentContextWrapper>
      </PromptContextWrapper>
    </CampaignContextWrapper>
  );
};

export default ViewCampaign;
