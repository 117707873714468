import { DailyUsageRow } from "../../../../api/dailyUsage.type";
import { numericMonthYearToString } from "../../../../utils/parseTimeStamp";
import { DownloadData } from "../../../table/tableWithSearch";

export function mapReimbursementDailyToCsv(
  data: Partial<DailyUsageRow>[],
  month?: number,
  year?: number
): DownloadData {
  const csvData = data.map((row) => {
    return {
      date: `${row.month}/${row.day}/${row.year}`,
      reimbursement_balance: row.reimbursement_balance,
      reimbursement_count: row.reimbursement_count,
    };
  });

  return {
    headers: [
      {
        label: "Date",
        key: "date",
      },
      {
        label: "Reimbursement Balance",
        key: "reimbursement_balance",
      },
      {
        label: "Reimbursement Count",
        key: "reimbursement_count",
      },
    ],
    csvData,
    fileName: `reimbursement-daily${
      month && year ? "-" + numericMonthYearToString(month, year) : ""
    }.csv`,
  };
}
