/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getLeads } from "../../../api/lead.api";
import { LeadWithMessageCount } from "../../../api/lead.type";
import { useUserContext } from "../../user/userContext";

export const useLoadLeads = () => {
  const { user } = useUserContext();
  const [count, setCount] = useState<number>();
  const [leads, setLeads] = useState<LeadWithMessageCount[]>();
  const [currentPage, setCurrentPage] = useState(0);

  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId");

  const { data, refetch, isLoading, isFetching, error } = useQuery({
    queryKey: ["leads", currentPage],
    queryFn: () =>
      getLeads({
        page: currentPage,
        ...(campaignId ? { campaignId } : {}),
      }),
    enabled: user && user.id ? true : false,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      setLeads(data?.data);
      setCount(data?.count);
    }
  }, [data]);

  useEffect(() => {
    if (currentPage) {
      refetch();
    }
  }, [currentPage]);

  return {
    isLoading,
    data,
    error,
    refetch,
    isFetching,
    leads,
    count,
    setLeads,
    setCount,
    setCurrentPage,
    currentPage,
  };
};
