import {
  Button,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";

import { useReimbursementContext } from "../reimbursement/reimbursementContext";

function DeleteReimbursementButton() {
  const { useDeleteReimbursementById, activeReimbursement } =
    useReimbursementContext();
  return (
    <div>
      <Typography className="mb-4">Danger! This is not reversible.</Typography>
      <Popover placement="top">
        <PopoverHandler>
          <Button size="sm" color="red" fullWidth={false}>
            Delete Reimbursement
          </Button>
        </PopoverHandler>
        <PopoverContent className="min-w-fit">
          <Typography variant="h6" color="blue-gray" className="mb-6">
            Confirm Delete Reimbursement
          </Typography>
          <Button
            onClick={() =>
              useDeleteReimbursementById.mutate(
                activeReimbursement?.id as string
              )
            }
            size="sm"
            color="green"
            fullWidth={false}
          >
            Delete Reimbursement
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default DeleteReimbursementButton;
