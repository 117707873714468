import React from "react";
import { FC } from "react";
import { Button, DateRangePicker, Select, SelectItem } from "@tremor/react";
import { CampaignRow } from "../../../api/campaign.type";
import { useSetCampaignAndDate } from "./useSetCampaignAndDate";

const SetCampaignAndDate: FC<Props> = ({ campaigns }) => {
  const { date, selectedCampaign, actions } = useSetCampaignAndDate();

  const handleSelectCampaign = (campaign?: CampaignRow) => () =>
    actions.updateSelectedCampaign(campaign);

  return (
    <div className="flex gap-2 flex-wrap">
      <div>
        <Button
          onClick={handleSelectCampaign(undefined)}
          className="bg-primary-500 hover:bg-primary-600 border-0"
        >
          All
        </Button>
      </div>

      <div className="w-96">
        {campaigns && (
          <Select className="w-96" value={selectedCampaign?.id || ""}>
            {campaigns ? (
              campaigns.map((campaign: any) => {
                return (
                  <SelectItem
                    className="w-96"
                    key={campaign?.id}
                    onClick={handleSelectCampaign(campaign)}
                    value={campaign?.id}
                  >
                    {campaign?.name}
                  </SelectItem>
                );
              })
            ) : (
              <p>No Campaigns</p>
            )}
          </Select>
        )}
      </div>
      <div>
        <DateRangePicker
          className="max-w-sm"
          enableSelect={false}
          value={date}
          onValueChange={({ from, to }) => {
            actions.updateDate({ from, to });
          }}
        />
      </div>
    </div>
  );
};

export default SetCampaignAndDate;

interface Props {
  campaigns: CampaignRow[] | null | undefined;
}
