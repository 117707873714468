import { Button } from "@material-tailwind/react";
import ConversationCard from "./ConversationsCard";
import { useNavigate } from "react-router-dom";
import { UploadMany } from "../icons/icons";

function ConversationsHeader() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-start mb-10">
      <ConversationCard />
      <Button
        color="deep-purple"
        className="flex items-center gap-3"
        onClick={() => navigate("/leads/bulk-upload")}
      >
        <UploadMany className="h-4 w-4" /> Bulk Upload
      </Button>
    </div>
  );
}

export default ConversationsHeader;
