import React from "react";

const DataDisplay = ({ dataArray }: any) => {
  if (!dataArray) {
    return <div>No data to display</div>;
  }
  // Function to render each key-value pair
  const renderData = (dataObject: any) => {
    return Object.entries(dataObject).map(([key, value], index) => (
      <div key={index}>
        <label>
          <strong>{key}:</strong>
        </label>
        {renderValue(value)}
      </div>
    ));
  };

  const renderValue = (value: any) => {
    // Check if the value is an object and not null
    if (typeof value === "object" && value !== null) {
      // Handle object or array value
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    } else {
      // Handle other types (string, number, boolean, etc.)
      return <p>{String(value)}</p>;
    }
  };

  return (
    <div>
      {dataArray.map((dataObject: any, index: number) => (
        <div key={index}>{renderData(dataObject)}</div>
      ))}
    </div>
  );
};

export default DataDisplay;
