/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

import { useCompanyContext } from "../company/companyContext";
import { LeadCampaignCounts } from "./analyticsContext.types";

import { CampaignRow } from "../../api/campaign.type";
import {
  getDailyLeadCountByCampaign,
  getDailyLeadCountByCompany,
} from "../../api/leadCampaign.api";

export const LeadAnalyticsContextWrapper = ({
  selectedCampaign,
  children,
}: {
  selectedCampaign: CampaignRow | undefined | null;
  children: React.ReactNode;
}) => {
  const { selectedCompany } = useCompanyContext();

  const [
    dailyLeadCampaignCountsByCampaign,
    setDailyLeadCampaignCountsByCampaign,
  ] = useState<LeadCampaignCounts>();

  const useGetDailyLeadCounts = useMutation(getDailyLeadCountByCompany, {
    onSuccess: async (res) => {
      setDailyLeadCampaignCountsByCampaign({
        Total: res.map((leadCount) => {
          return {
            ...leadCount,
            campaign_name: "Total",
          };
        }),
      });
    },
  });

  const useGetDailyLeadCountsByCampaign = useMutation(
    getDailyLeadCountByCampaign,
    {
      onSuccess: async (res) => {
        if (res.length > 0 && res[0].campaign_name) {
          const campaignName = res[0].campaign_name;

          setDailyLeadCampaignCountsByCampaign({
            ...dailyLeadCampaignCountsByCampaign,
            [campaignName]: res,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (selectedCompany) {
      useGetDailyLeadCounts.mutate(selectedCompany.id);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCampaign) {
      useGetDailyLeadCountsByCampaign.mutate(selectedCampaign?.id as string);
    }
  }, [selectedCampaign]);

  const value = {
    dailyLeadCampaignCountsByCampaign,
  };

  return (
    <LeadAnalyticsContext.Provider value={value}>
      {children}
    </LeadAnalyticsContext.Provider>
  );
};

export const LeadAnalyticsContext = createContext({
  dailyLeadCampaignCountsByCampaign: {} as LeadCampaignCounts | undefined,
});

export const useLeadAnalyticsContext = () => useContext(LeadAnalyticsContext);
