/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Chip, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { useCompanyContext } from "../company/companyContext";
import { PlentiInput } from "../forms/plentiInput";
import { useUserContext } from "../user/userContext";
import EmailPasswordInputs from "./EmailPasswordInputs";
import { usePreUserContext } from "./PreUserContext";
import { useAuthContext } from "./authContext";

const roleOptions = [
  "Department lead",
  "Executive",
  "Contractor/Freelancer",
  "Team Manager",
  "Individual Contributor",
];

export type RegisterFormProps = {
  first_name: string;
  last_name: string;
  company_name: string;
  logo_link_url: string;
  email: string;
  role: string;
  password: string;
};

function RegisterForm({
  currentStep,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: Function;
}) {
  const { createPreUser, updatePreUser, createdPreUser } = usePreUserContext();

  const { createCompanyFromSignup } = useCompanyContext();

  const [role, setRole] = useState("");

  const navigate = useNavigate();

  const { signupUser, signUp } = useAuthContext();
  const { updateUser } = useUserContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: createdPreUser?.first_name || "",
      last_name: createdPreUser?.last_name || "",
      company_name: createdPreUser?.company_name || "",
      logo_link_url: createdPreUser?.logo_link_url || "",
      role: createdPreUser?.role || "",
      email: "",
      password: "",
    },
  });

  function createOrUpdatePreUser(data: RegisterFormProps) {
    if (createdPreUser) {
      updatePreUser({
        id: createdPreUser.id,
        ...(data.first_name && { first_name: data.first_name.trim() }),
        ...(data.last_name && { last_name: data.last_name.trim() }),
        ...(data.role && { role: data.role }),
      });
    } else {
      createPreUser({
        ...(data.first_name && { first_name: data.first_name.trim() }),
        ...(data.last_name && { last_name: data.last_name.trim() }),
        ...(data.role && { role: data.role }),
      });
    }
    setCurrentStep((preState: number) => preState + 1);
  }

  function createOrUpdateCompany(data: RegisterFormProps) {
    if (createdPreUser) {
      updatePreUser({
        id: createdPreUser.id,
        logo_link_url: data.logo_link_url,
        company_name: data.company_name,
      });
    } else {
      createPreUser({
        logo_link_url: data.logo_link_url,
        company_name: data.company_name,
      });
    }
    setCurrentStep((preState: number) => preState + 1);
  }

  function onSubmit(data: RegisterFormProps) {
    if (currentStep === 0) {
      console.log(data);
      createOrUpdatePreUser(data);
    }
    if (currentStep === 1) {
      createOrUpdateCompany(data);
    }
    if (currentStep === 2) {
      if (!signupUser) {
        signUp({
          email: data.email,
          password: data.password,
        });
      }
    }
  }

  useEffect(() => {
    if (signupUser && createdPreUser) {
      createCompanyFromSignup({
        name: createdPreUser?.company_name,
        logo_link_url: createdPreUser?.logo_link_url,
        userId: signupUser?.id,
      });
      updatePreUser({
        id: createdPreUser.id,
        user_id: signupUser?.id,
      });

      updateUser({
        id: signupUser.id,
        first_name: createdPreUser.first_name,
        last_name: createdPreUser.last_name,
      });
      navigate("/register/thank-you");
    }
  }, [signupUser]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-5 justify-around">
        {currentStep === 0 && (
          <div className="flex flex-col gap-2 mb-10">
            <PlentiInput
              register={register}
              errors={errors}
              label="First Name"
              name="first_name"
            />
            <PlentiInput
              register={register}
              errors={errors}
              label="Last Name"
              name="last_name"
            />
            <Typography variant="small" color="gray" className="my-4">
              What best describes your role at your company?
            </Typography>
            <div className="grid grid-cols-2 gap-2">
              {roleOptions.map((r) => {
                return (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setValue("role", r);
                      setRole(r);
                    }}
                  >
                    <Chip
                      className={
                        r === role
                          ? "capitalize p-2 text-center bg-primary-400"
                          : "capitalize p-2 text-center"
                      }
                      variant={r === role ? "filled" : "outlined"}
                      value={r}
                    />
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {currentStep === 1 && (
          <div className="flex flex-col gap-2 mb-10">
            <PlentiInput
              register={register}
              errors={errors}
              label="Company Name"
              name="company_name"
            />
            <PlentiInput
              register={register}
              errors={errors}
              label="Company URL"
              name="logo_link_url"
            />
          </div>
        )}

        {currentStep === 2 && (
          <div className="flex flex-col gap-5 mb-10">
            <EmailPasswordInputs register={register} errors={errors} />
          </div>
        )}

        {currentStep < 3 && (
          <ButtonSecondary>
            {currentStep === 2 ? "Submit" : "Next"}
          </ButtonSecondary>
        )}
        {currentStep === 3 && (
          <Button color="blue-gray" onClick={() => navigate("/")}>
            Login to Plenti
          </Button>
        )}
      </div>
    </form>
  );
}

export default RegisterForm;
