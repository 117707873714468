import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { ReimbursementCohort } from "../api/leadAnalytics.type";

export function getReimbursementsByCohort({
  startDate,
  endDate,
  campaignId,
}: {
  startDate: string;
  endDate: string;
  campaignId: string;
}): Promise<ReimbursementCohort[]> {
  const url = process.env.REACT_APP_API_URL + "/reimbursement/cohort";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({
      startDate,
      endDate,
      campaignId,
    }),
  });

  return fetchData<ReimbursementCohort[]>(url, request);
}
