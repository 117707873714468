import { Link } from "react-router-dom";
import { Fragment } from "react";

export function LinkToId({
  type,
  id,
  baseUrl,
  state,
}: {
  type: string;
  id?: string | null;
  baseUrl: string;
  state?: any;
}) {
  return (
    <Fragment>
      {id ? (
        <Link className="text-primary-400" to={`${baseUrl}${id}`} state={state}>
          {type}
        </Link>
      ) : (
        <p>N/A</p>
      )}
    </Fragment>
  );
}
