/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { CustomerRow } from "../../api/customer.type";
import { getCustomer, updateCustomer } from "../../api/customer.api";
import { useUserContext } from "../user/userContext";
import { useAlertContext } from "../alert/alertContext";
import {
  GetCustomerBalanceDailyRollupByPeriodMutation,
  UpdateCustomerMutation,
} from "./customerContext.types";
import { getCustomerBalanceRollup } from "../../api/customerBalance.api";
import { CustomerBalanceDailyRollupByPeriod } from "../../api/customerBalance.type";

export const CustomerContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const [customer, setCustomer] = useState<CustomerRow>();
  const { user } = useUserContext();

  const [dailyRollupByPeriod, setDailyRollupByPeriod] =
    useState<CustomerBalanceDailyRollupByPeriod[]>();

  const { mutate: getCustomerUsageDailyRollup } = useMutation(
    getCustomerBalanceRollup,
    {
      onSuccess: async (res) => {
        setDailyRollupByPeriod(res);
      },
      onError(error: Error) {
        console.log(error);
      },
    }
  );

  const useGetCustomer = useMutation(getCustomer, {
    onSuccess: async (res) => {
      setCustomer(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useUpdateCustomer = useMutation(updateCustomer, {
    onSuccess: async (res) => {
      setCustomer(res);
      setAlertProps({
        message: "Payment details updated",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (user) {
      useGetCustomer.mutate();
    }
  }, [user]);

  const value = {
    customer,
    useUpdateCustomer,
    getCustomerUsageDailyRollup,
    dailyRollupByPeriod,
  };

  return (
    <CustomerContext.Provider value={value}>
      {props.children}
    </CustomerContext.Provider>
  );
};

export const CustomerContext = createContext({
  customer: {} as CustomerRow | undefined,
  useUpdateCustomer: {} as UpdateCustomerMutation,
  getCustomerUsageDailyRollup:
    {} as GetCustomerBalanceDailyRollupByPeriodMutation,
  dailyRollupByPeriod: [] as CustomerBalanceDailyRollupByPeriod[] | undefined,
});

export const useCustomerContext = () => useContext(CustomerContext);
