import { Typography } from "@material-tailwind/react";
import { fabrkPresets } from "../config/fabrkPresets";

function FabrkChatPresets() {
  return (
    <div className="grid grid-cols-2 gap-2">
      {fabrkPresets.map((preset, index) => {
        return (
          <div
            key={index}
            className="flex flex-row gap-2 rounded-md text-left p-1 cursor-pointer hover:bg-gray-900 dark:hover:bg-offsetDark transition-all duration-200 ease-in-out"
            style={{
              border: "1px solid rgba(62, 73, 174, 0.5)",
            }}
          >
            {preset.icon}
            <Typography>{preset.text}</Typography>
          </div>
        );
      })}
    </div>
  );
}

export default FabrkChatPresets;
