import { Card, CardBody, Typography } from "@material-tailwind/react";
import { MapIcon } from "../icons/icons";

function LocationCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <MapIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Locations
        </Typography>
        <Typography>
          Add the retail locations where your products are sold. These locations
          are used to give the AI context about where your customers can find
          your products.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default LocationCard;
