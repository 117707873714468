import { useEffect, useRef, useState } from "react";
import { LeadWithRelations } from "../../api/lead.type";
import { PaperAirplaneIcon } from "../icons/icons";

function ChatInput({
  handleSubmitMessage,
  activeLead,
}: {
  handleSubmitMessage: (message: string) => void;
  activeLead: LeadWithRelations | null;
}) {
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);

  const textBoxRef = useRef<HTMLInputElement | null>(null);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleSubmitMessage(value);
    setValue("");
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (activeLead && activeLead.sms_message) {
      const { sms_message: messages } = activeLead;
      if (messages && messages.length > 0) {
        const userMessages = messages.filter(
          (message: any) => message.role === "user"
        );

        if (event.key === "ArrowUp") {
          setIndex((prevState: number) => {
            if (prevState < userMessages.length) {
              return (prevState = prevState + 1);
            } else {
              return prevState;
            }
          });
        }
        if (event.key === "ArrowDown") {
          if (index > 0) {
            setIndex((prevState: number) => (prevState = prevState - 1));
          }
        }
      }
    }
  };

  useEffect(() => {
    if (activeLead && activeLead.sms_message) {
      const { sms_message: messages } = activeLead;
      if (messages && messages.length > 0) {
        const userMessages = messages.filter(
          (message: any) => message.role === "user"
        );
        if (
          textBoxRef.current &&
          userMessages &&
          userMessages.length > 0 &&
          userMessages[index - 1]
        ) {
          textBoxRef.current.value = userMessages[index - 1].content;
          setValue(userMessages[index - 1].content);
        }
      }
    }
  }, [activeLead, index]);

  return (
    <div className="w-full grow gap-2">
      <form onSubmit={onSubmit}>
        <div className="flex w-full flex-row">
          <input
            type="text"
            ref={textBoxRef}
            onKeyDown={handleKeyDown}
            name="message"
            required
            value={value}
            onChange={onChange}
            placeholder="Type here..."
            className="text-md leading-5.6 ease-soft block w-full flex-grow grow appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-primary-500 focus:outline-none"
          />
          <button
            type="submit"
            className="hover:scale-102 active:opacity-85 hover:shadow-soft-xs leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-0 ml-2 inline-block flex-none cursor-pointer rounded-lg border-0 bg-gradient-to-tl from-primary-500 to-primary-700 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all"
          >
            <PaperAirplaneIcon className="h-4 w-4" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatInput;
