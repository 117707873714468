import DivCurve from "../try/divCurve";
import { useTryContext } from "../try/tryContext";
import TryPhoneInput from "../try/tryPhoneInput";
import TryPolicy from "../try/tryPolicy";
import TrySubtitle from "../try/trySubtitle";
import TryTitle from "../try/tryTitle";

function TryHeaderBody() {
  const { companyConfig, company, campaign } = useTryContext();
  return (
    <div className="min-w-lg flex flex-col justify-center gap-4 whitespace-normal md:mt-20">
      <TryTitle />
      <TrySubtitle
        companyConfig={companyConfig}
        campaign={campaign}
        company={company}
      />
      {campaign && campaign?.status === "active" && <TryPhoneInput />}
      {campaign && campaign?.status === "active" && <TryPolicy />}

      <DivCurve smallScreen={true} />
    </div>
  );
}

export default TryHeaderBody;
