import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { ProgressCircle, Text } from "@tremor/react";
import { CampaignConversationMetrics } from "../../api/conversation.type";
import { roundDecimalToWholeNumber } from "../../utils/calculateAverage";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import {
  formatIntervalToHumanReadable,
  yearMonthDayToMonthDayYear,
} from "../../utils/parseTimeStamp";
import { useNavigate } from "react-router-dom";
import PercentCircle from "../table/PercentCircle";

function CampaignMetricsTableList({
  values,
  handleContentClick,
  filterFunction,
}: {
  values: CampaignConversationMetrics[] | undefined;
  handleContentClick: (id: string) => void;
  filterFunction?: (
    metric: CampaignConversationMetrics,
    index: number
  ) => boolean;
}) {
  const navigate = useNavigate();
  function mapCampaignToRow(metric: CampaignConversationMetrics) {
    const { campaignName, metrics, campaignId } = metric;
    const {
      totalLeads,
      totalReimbursementAmount,
      averageResponseInterval,
      averageResponseTime,
      responsePercent,
      reimbursementPercent,
    } = metrics;

    const values = {
      id: campaignId,
      name: campaignName || "N/A",
      createdAt: metrics.startDate
        ? yearMonthDayToMonthDayYear(metrics.startDate)
        : "N/A",
      totalLeads: totalLeads || "N/A",
      totalReimbursementAmount:
        parseCentsToDollars(totalReimbursementAmount) || "N/A",
      averageResponseInterval:
        formatIntervalToHumanReadable(averageResponseInterval) || "N/A",
      averageResponseTime:
        formatIntervalToHumanReadable(averageResponseTime) || "N/A",
      responseRate: roundDecimalToWholeNumber(responsePercent),
      reimbursementRate: roundDecimalToWholeNumber(reimbursementPercent),
    };

    return values;
  }
  return (
    <Fragment>
      {values &&
        values.length > 0 &&
        values
          .filter((item, index) => filterFunction?.(item, index) ?? true)
          .map((item) => mapCampaignToRow(item))
          .map(
            (
              {
                id,
                name,
                createdAt,
                totalLeads,
                totalReimbursementAmount,
                averageResponseInterval,
                averageResponseTime,
                responseRate,
                reimbursementRate,
              },
              index
            ) => {
              const isLast = index === values.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr
                  key={index}
                  className="even:bg-blue-gray-50/50 cursor-pointer hover:bg-blue-gray-50 transition-colors"
                  onClick={() => {
                    navigate(`/campaign/${id}`);
                  }}
                >
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {name}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {createdAt}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {totalLeads}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {totalReimbursementAmount}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <ProgressCircle value={reimbursementRate} size="md">
                      <span className="h-12 w-12 rounded-full bg-primary-500 flex items-center justify-center  text-primary-300 font-bold bg-opacity-50">
                        <Text className="text-primary-300">
                          {reimbursementRate}%{" "}
                        </Text>
                      </span>
                    </ProgressCircle>
                  </td>

                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {averageResponseInterval}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {averageResponseTime}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <PercentCircle value={responseRate} />
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default CampaignMetricsTableList;
