import { createContext, useContext, useEffect, useState } from "react";

export const TimerContextContextWrapper = (props: any) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // Only start the countdown if the initial seconds are greater than 0
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) return prevSeconds - 1;
          clearInterval(interval);
          return 0;
        });
      }, 1000);

      // Clear interval on component unmount
      return () => clearInterval(interval);
    }
  }, [seconds]);

  const value = {
    seconds,
    setSeconds,
  };

  return (
    <TimerContextContext.Provider value={value}>
      {props.children}
    </TimerContextContext.Provider>
  );
};

export const TimerContextContext = createContext({
  seconds: 0,
  setSeconds: (seconds: number) => {},
});

export const useTimerContextContext = () => useContext(TimerContextContext);
