import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { SegmentRunResponse, SegmentsRow } from "./segments.type";

export function getSegments(): Promise<SegmentsRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.segment;

  const request = setHeaderOptions({ method: "GET" });
  return fetchData<SegmentsRow[]>(url, request);
}

export function createSegment(query: string): Promise<any> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.segment;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ query }),
  });
  return fetchData<any>(url, request);
}

export function runSegment(segmentId: string): Promise<SegmentRunResponse> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.segment + "/run/" + segmentId;

  const request = setHeaderOptions({
    method: "PUT",
  });
  return fetchData<SegmentRunResponse>(url, request);
}

export function deleteSegment(segmentId: string): Promise<SegmentsRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.segment + "/" + segmentId;

  const request = setHeaderOptions({
    method: "DELETE",
  });
  return fetchData<SegmentsRow>(url, request);
}

export function addSegmentsToCampaign({
  segmentIds,
  campaignId,
  smsContentId,
}: {
  segmentIds: string[];
  campaignId: string;
  smsContentId?: string;
}) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.segment + "/campaign";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({
      segmentIds,
      campaignId,
      ...(smsContentId && { smsContentId }),
    }),
  });
  return fetchData<any>(url, request);
}
