/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getCustomer,
  getCustomers,
  updateCustomer,
} from "../../api/customer.api";
import { CustomerRow } from "../../api/customer.type";
import { getDailyUsages } from "../../api/dailyUsage.api";
import { DailyUsageRow } from "../../api/dailyUsage.type";
import { getReimbursementUsage } from "../../api/usage.api";
import { ReimbursementUsageRow } from "../../api/usage.type";
import { useAlertContext } from "../alert/alertContext";
import { UpdateCustomerMutation } from "../billing/customerContext.types";
import { useUserContext } from "../user/userContext";

export const AdminCustomerContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const [customer, setCustomer] = useState<CustomerRow>();
  const { user } = useUserContext();

  const { data: customers } = useQuery({
    queryKey: "customers",
    queryFn: () => getCustomers(),
    enabled: user && user.type === "admin" ? true : false,
  });

  const { data: dailyUsage } = useQuery({
    queryKey: "dailyUsage",
    queryFn: () => getDailyUsages(),
    enabled: user && user.type === "admin" ? true : false,
  });

  const { data: reimbursementUsage } = useQuery({
    queryKey: "reimbursementUsage",
    queryFn: () => getReimbursementUsage(),
    enabled: user && user.type === "admin" ? true : false,
  });

  const useGetCustomer = useMutation(getCustomer, {
    onSuccess: async (res) => {
      setCustomer(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useUpdateCustomer = useMutation(updateCustomer, {
    onSuccess: async (res) => {
      setCustomer(res);
      setAlertProps({
        message: "Payment details updated",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (user && user.type === "standard") {
      useGetCustomer.mutate();
    }
  }, [user]);

  const value = {
    customer,
    useUpdateCustomer,
    customers,
    dailyUsage,
    reimbursementUsage,
  };

  return (
    <AdminCustomerContext.Provider value={value}>
      {props.children}
    </AdminCustomerContext.Provider>
  );
};

export const AdminCustomerContext = createContext({
  customer: {} as CustomerRow | undefined,
  useUpdateCustomer: {} as UpdateCustomerMutation,
  customers: [] as CustomerRow[] | undefined,
  dailyUsage: [] as DailyUsageRow[] | undefined,
  reimbursementUsage: [] as ReimbursementUsageRow[] | undefined,
});

export const useAdminCustomerContext = () => useContext(AdminCustomerContext);
