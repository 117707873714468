import PlentiFormLabel from "./PlentiFormLabel";

type ValueWithLabelProps = {
  label: string;
  value: string;
  font?: string;
  otherProps?: any;
};

function ValueWithLabel({
  label,
  value,
  font,
  otherProps,
}: ValueWithLabelProps) {
  return (
    <div {...otherProps}>
      <PlentiFormLabel>{label}</PlentiFormLabel>
      <p className={font ? `text-slate-700 font-${font}` : "text-slate-700"}>
        {value}
      </p>
    </div>
  );
}

export default ValueWithLabel;
