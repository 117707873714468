import { Schema } from "../../api/extractionSchema.type";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import ValueWithLabel from "../forms/valueWithLabel";

function ViewSchema({ schema }: { schema: Schema[] }) {
  return (
    <div className="flex flex-col gap-5 p-5 border-b border-blue-gray-100  w-full">
      {schema?.map((item, index) => {
        const { type, question, id, required } = item;
        return (
          <div key={index} className="grid grid-cols-6 gap-2 ">
            <div>
              <ValueWithLabel label="Id" value={id || "N/A"} />
            </div>

            <ValueWithLabel
              label="Type"
              value={snakeCaseToTitleCase(
                //@ts-ignore
                type === "boolean" ? "yes/no" : type
              )}
            />
            <ValueWithLabel label="Required" value={required ? "Yes" : "No"} />

            <div className="col-span-3 ">
              <ValueWithLabel
                otherProps={{ className: "col-span-2 grow" }}
                label="Question"
                value={question}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ViewSchema;
