import { Button, Typography } from "@material-tailwind/react";
import InfoPopover from "../InfoPopover/infoPopover";
import AddButton from "../buttons/addButton";
import PlentiCurrencyInput from "../forms/plentiCurrencyInput";
import { useStripeContext } from "../stripe/stripeContext";
import PaymentMethodSelect from "./paymentMethodsSelect";

function AddFunds({
  handleAddPayment,
  setIndex,
}: {
  handleAddPayment: () => void;
  setIndex: (index: number) => void;
}) {
  const {
    paymentMethods,
    amount,
    setAmount,
    selectedPaymentMethod,
    amountWithFee,
    confirmPaymentIntent,
    isLoading,
  } = useStripeContext();

  function handleSubmit() {
    confirmPaymentIntent();
    setIndex(4);
  }

  return (
    <div className="max-w-md flex flex-col gap-10">
      {paymentMethods && paymentMethods.length > 0 && (
        <div>
          <Typography
            variant="paragraph"
            className="max-w-2xl text-Sofia-pro font-heavy subpixel-antialiased font-bold"
          >
            Payment amount
          </Typography>

          <div className="max-w-xs">
            <PlentiCurrencyInput
              amount={amount}
              setAmount={(amount) => setAmount(amount)}
            />
          </div>

          <Typography
            variant="small"
            color="gray"
            className="mt-1 flex items-center gap-1 font-normal"
          >
            Enter an amount between $100 and $9,999
          </Typography>
        </div>
      )}
      <div className="flex flex-col gap-2 ">
        {paymentMethods && paymentMethods.length > 0 && (
          <div className="max-w-xs">
            <PaymentMethodSelect />
          </div>
        )}
        <div>
          <AddButton
            iconClass="h-4 w-4"
            onClick={handleAddPayment}
            color="green"
            size="lg"
            variant="text"
          >
            Add new payment method
          </AddButton>
        </div>
      </div>
      {selectedPaymentMethod && (
        <div className="relative w-fit">
          <Typography
            variant="paragraph"
            className="max-w-md text-Sofia-pro font-heavy subpixel-antialiased font-bold"
          >
            Total charge ${amountWithFee}
          </Typography>
          <div className="absolute top-[-12px] right-[-15px]">
            <InfoPopover>
              <p className="text-slate-700">
                Plenti AI charges a small fee to cover the cost of moving money
                - 3% for credit cards and 0.5% for bank transfers.
              </p>
            </InfoPopover>
          </div>
        </div>
      )}
      <Button
        color="green"
        disabled={!selectedPaymentMethod || isLoading}
        onClick={handleSubmit}
      >
        Add {amountWithFee && selectedPaymentMethod ? `$${amount}` : ""} to my
        balance
      </Button>
    </div>
  );
}

export default AddFunds;
