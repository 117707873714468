import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";

import PromptDetailsContainer from "./PromptDetails";

function ViewPromptContainer({ id }: { id: string | undefined | string[] }) {
  const breadCrumbsConfig = [
    {
      name: "Prompts",
      href: "/prompt",
      active: false,
    },
    {
      name: "Edit",
      href: `/prompt/${id}`,
      active: true,
    },
  ];

  return (
    <div className=" p-5 flex flex-col gap-5">
      <BreadcrumbsFromConfig config={breadCrumbsConfig} />
      <PromptDetailsContainer />
    </div>
  );
}

export default ViewPromptContainer;
