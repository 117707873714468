import { Typography } from "@material-tailwind/react";
import { StripePaymentMethod } from "../../api/stripe.type";
import CardIcon from "./cardIcon";
import DefaultPaymentItem from "./defaultPaymentItem";

function PaymentMethodListItem({
  paymentMethod: { card, us_bank_account, type, billing_details, id },
  classes,
  index,
}: {
  paymentMethod: StripePaymentMethod;
  classes: string;
  index: number;
}) {
  let expDate = "";
  let last4 = "";
  if (type === "card") {
    expDate = card?.exp_month + "/" + card?.exp_year;
    last4 = card?.last4;
  } else if (type === "us_bank_account") {
    last4 = us_bank_account?.last4 || "";
  }

  const zipState =
    billing_details?.address?.postal_code && billing_details.address.state
      ? billing_details?.address?.postal_code +
        ", " +
        billing_details.address.state
      : "N/A";

  return (
    <tr key={index}>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          {type === "card" ? (
            <CardIcon card={card} />
          ) : (
            us_bank_account?.bank_name
          )}
        </Typography>
      </td>
      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          **** **** **** {last4 || "N/A"}
        </Typography>
      </td>

      <td className={classes}>
        <Typography variant="small" color="blue-gray" className="font-normal">
          {expDate ? expDate : "N/A"}
        </Typography>
      </td>
      <td className={classes}>
        <div>
          <Typography variant="small" color="blue-gray" className="font-normal">
            {billing_details?.address?.line1}
          </Typography>
          <Typography variant="small" color="blue-gray" className="font-normal">
            {zipState}
          </Typography>
        </div>
      </td>

      <td className={classes}>
        <DefaultPaymentItem paymentMethodId={id} />
      </td>
    </tr>
  );
}

export default PaymentMethodListItem;
