import SegmentsContainer from "../components/segments/SegmentsContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Segments = () => {
  mixpanelTrackEvent("Segments page");

  return <SegmentsContainer />;
};

export default Segments;
