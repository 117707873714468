import { useQuery } from "react-query";
import { getDailyUsages } from "../../../api/dailyUsage.api";

export function useLoadDailyUsage() {
  const { data, isLoading, error } = useQuery({
    queryKey: "dailyUsage",
    queryFn: () => getDailyUsages(),
  });

  return {
    isLoading,
    data,
    error,
  };
}
