import { Link } from "react-router-dom";

import { useCampaignContext } from "../campaign/campaignContext";

import { ReimbursementRow } from "../../api/reimbursement.type";

function ReimbursementByLeadItem({
  reimbursement,
  key,
}: {
  reimbursement: ReimbursementRow;
  key: string;
}) {
  const { campaigns } = useCampaignContext();

  const campaign = campaigns?.find((c) => c.id === reimbursement?.campaign_id);

  return (
    <div key={key}>
      <Link
        className="text-primary-500"
        to={`/reimbursement/${reimbursement?.id}`}
      >
        {campaign?.name || "N/A"} - {reimbursement?.status}
      </Link>
    </div>
  );
}

export default ReimbursementByLeadItem;
