import { Typography } from "@material-tailwind/react";
import { CampaignDraftType } from "../../api/campaign.type";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import SelectCompany from "../company/selectCompany";
import { BogoIcon } from "../icons/bogoSvg";
import { CustomIcon } from "../icons/customSvg";
import { FreeIcon } from "../icons/freeSvg";
import HalfOffIcon from "../icons/halfOffSvg";
import { CartIcon, Library, Share, ShieldCheck } from "../icons/icons";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useUserContext } from "../user/userContext";
import { useCampaignContext } from "./campaignContext";
import NewCampaignCard from "./newCampaignCard";

const smsCards = [
  {
    title: "50% off",
    description:
      "A campaign promoting a 50% off a sku or collection of skus in-stores.",
    icon: <HalfOffIcon className="h-6 w-6" />,
    type: CampaignDraftType.halfOff,
  },
  {
    title: "BOGO",
    description: "A campaign promoting a buy one get one free offer in-stores.",
    icon: <BogoIcon className="h-6 w-6 text-white" />,
    type: CampaignDraftType.bogo,
  },
  {
    title: "Free trial",
    description: "A campaign promoting a free trial offer in-stores.",
    icon: <FreeIcon className="h-6 w-6 text-white" />,
    type: CampaignDraftType.free,
  },
  {
    title: "Loyalty",
    description:
      "A campaign encouraging customers to upload receipts to redeem for an incentive.",
    icon: <ShieldCheck className="h-6 w-6 " />,
    type: CampaignDraftType.loyalty,
  },
  {
    title: "Merchandising",
    description:
      "Incentivize customers to check that products are in stock and look good on the shelves.",
    icon: <Library className="h-6 w-6 " />,
    type: CampaignDraftType.merchandise,
  },
  {
    title: "Referral",
    description:
      "A campaign encouraging customers to send a referral link to friends and family.",
    icon: <Share className="h-6 w-6" />,
    type: CampaignDraftType.referral,
  },
  {
    title: "Abandoned Cart",
    description: "A campaign encouraging customers to complete their purchase.",
    icon: <CartIcon className="h-6 w-6" />,
    type: CampaignDraftType.abandonedCart,
  },
  {
    title: "Custom",
    description:
      "Create your own campaign with a custom goal and message content.",
    icon: <CustomIcon className="h-6 w-6" fillColor="black" />,
    type: CampaignDraftType.custom,
  },
];

function CampaignPrebuiltCards() {
  const { user } = useUserContext();
  const { loading, templateCampaigns } = useCampaignContext();

  return (
    <div className="p-5">
      <BreadcrumbsFromConfig
        config={[
          {
            name: "Campaigns",
            href: "/campaign",
            active: false,
          },
          {
            name: "Add",
            href: "/campaign/add",
            active: true,
          },
        ]}
      />
      {loading ? (
        <PlentiLogoLoading className="h-24 w-24 mx-auto" />
      ) : (
        <div className="flex flex-col gap-5 mt-5">
          <Typography variant="h4" className="mb-4 text-slate-500">
            Prebuilt Campaigns
          </Typography>
          {user?.type === "admin" && (
            <div className="max-w-md">
              {" "}
              <SelectCompany />
            </div>
          )}
          <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
            {templateCampaigns?.map((campaign, index) => {
              const cardMatch = smsCards.find(
                (card) => card.type === campaign.type
              );

              const title = cardMatch ? cardMatch.title : campaign.name;

              const description = cardMatch
                ? cardMatch.description
                : campaign.goal;

              const icon = cardMatch ? (
                cardMatch.icon
              ) : (
                <CustomIcon className="h-6 w-6" fillColor="white" />
              );

              return (
                <div key={index} className="p-2">
                  <NewCampaignCard
                    title={title}
                    description={description}
                    icon={icon}
                    type={campaign.type as CampaignDraftType}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default CampaignPrebuiltCards;
