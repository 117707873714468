import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { LineItemRow, UpdateLineItem } from "./lineItem.type";

export function updateLineItemById({
  id,
  values,
}: UpdateLineItem): Promise<LineItemRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.lineItem + "/" + id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<LineItemRow>(url, request);
}
