import { StripeCard } from "../../api/stripe.type";
import AmexSvg from "../icons/amexSvg";
import { MasterCardSvg } from "../icons/masterCardSvg";
import VisaSvg from "../icons/visaSvg";

function CardIcon({ card }: { card: StripeCard }) {
  if (!card) {
    return <div>N/A</div>;
  }

  switch (card.brand) {
    case "visa":
      return <VisaSvg className="h-8 w-8" />;
    case "mastercard":
      return <MasterCardSvg className="h-8 w-8" />;
    case "amex":
      return <AmexSvg className="h-8 w-8" />;
    default:
      return <div>{card.brand}</div>;
  }
}

export default CardIcon;
