import { Typography } from "@material-tailwind/react";
import GptLocationTesting from "./gptLocationTesting";
import { AdminGptContextWrapper } from "./adminGptContext";

function AdminGptTesting() {
  return (
    <AdminGptContextWrapper>
      <Typography variant="h2" className="mb-10">
        GPT Testing
      </Typography>
      <GptLocationTesting />
    </AdminGptContextWrapper>
  );
}

export default AdminGptTesting;
