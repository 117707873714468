type TwilioMessageDetails = {
  includesImage: boolean;
  text: string;
};

type TwilioCostEstimate = {
  segments: number;
  totalCost: number;
};

export function containsEmoji(text: string): boolean {
  const emojiRegex =
    /(\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]|\ud83e[\udd00-\uddff])/;
  return emojiRegex.test(text);
}

export function calculateTwilioSmsCost(
  details: TwilioMessageDetails
): TwilioCostEstimate {
  const rates = { smsRate: 0.0079, mmsRate: 0.0158 }; // Example rates, adjust as needed

  // Rates per segment
  const { smsRate, mmsRate } = rates;

  const includesEmoji = containsEmoji(details.text);
  const characterCount = details.text.length;

  // Determine encoding and segment size
  const encoding = includesEmoji ? "UCS-2" : "GSM 7-bit";
  const singleSegmentLimit = encoding === "UCS-2" ? 70 : 160;
  const multiSegmentLimit = encoding === "UCS-2" ? 67 : 153;

  // Determine number of segments
  let segments = 1;
  if (characterCount > singleSegmentLimit) {
    segments = Math.ceil(characterCount / multiSegmentLimit);
  }

  // If an image is included, the message is an MMS
  const isMms = details.includesImage;

  // Calculate total cost based on whether it's SMS or MMS
  const totalCost = isMms
    ? (segments * mmsRate).toFixed(3)
    : (segments * smsRate).toFixed(3);

  return {
    segments,
    totalCost: parseFloat(totalCost), // Convert back to number if you need the value as a number
  };
}
