import { Typography } from "@material-tailwind/react";
import { PromptRow } from "../../api/prompt.type";

function CompanyPromptDisplay({ prompt }: { prompt: PromptRow }) {
  return (
    <div className="p-6 bg-white shadow-md rounded-lg mb-10">
      <Typography variant="lead" color="gray">
        Company Prompt
      </Typography>
      <Typography variant="small" color="gray" className="mb-2">
        This is how the prompt is presented to the AI
      </Typography>
      <p>
        Your name is <strong>{prompt.ai_name}</strong>.
        <strong> {prompt.ai_bio}.</strong>
      </p>

      <br />
      <p>
        Description: <strong>{prompt.company_description}</strong>
      </p>
      <p>
        Products: <strong>{prompt.products}</strong>
      </p>
      <p>
        Product notables: <strong>{prompt.product_notables}</strong>
      </p>
      <br />
      <p>
        You are sending text messages with a user, and the messages are below.
        If users ask a question you don't have an answer to, they can find more
        info on{" "}
        <a href={prompt.website_url} target="_blank" rel="noopener noreferrer">
          <strong> {prompt.website_url}</strong>
        </a>{" "}
        which includes flavors and retail locations. They can purchase our
        products online at <strong>{prompt.e_comm_url}</strong>. They can find
        retail locations here{" "}
        <a
          href={prompt.store_locator_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong> {prompt.store_locator_link}</strong>
        </a>
        .
      </p>

      <p>
        If the user asks where they can get the product please ask them for
        their zip code and you can find the closest location.
      </p>
      <br />
      <p>
        If there are other product questions, let the user know they can reach
        out to{" "}
        <a href={`mailto:${prompt.support_email}`}>{prompt.support_email}</a> or
        go to the website{" "}
        <a
          href={`${prompt.website_url}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong> {prompt.website_url}/</strong>
        </a>{" "}
        to answer questions users have regarding things like pricing and
        ingredients. If you do not know an answer to a specific question based
        on available data, you should direct the user to our website, or email
        customer support at{" "}
        <a href={`mailto:${prompt.support_email}`}>
          <strong> {prompt.support_email}</strong>
        </a>
      </p>
      <br />
      <p>
        <strong>{prompt.other}</strong>
      </p>
    </div>
  );
}

export default CompanyPromptDisplay;
