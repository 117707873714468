import {
  BookOpen,
  CakeIcon,
  ChartIcon,
  ChartPieIcon,
  ChatBubbleWithWritingIcon,
  DollarIcon,
  GlobeAlt,
  List,
  MapIcon,
  MegaphoneIcon,
  PhoneIcon,
  RocketIcon,
  ShareIcon,
  UserIcon,
} from "../icons/icons";
import { ShopifyBag } from "../icons/shopifyBag";

export const adminButtons = [
  {
    path: "/admin",
    icon: <PhoneIcon className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Admin",
  },
  {
    path: "/ocr",
    icon: <List className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Ocr",
  },
];

export const navButtons = [
  {
    path: "/",
    icon: <ShopifyBag className="h-6 w-6" />,
    label: "Home",
  },
  {
    label: "Agents",
  },
  {
    path: "/reimbursements",
    icon: <DollarIcon className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Reimbursements",
  },
  {
    path: "/agents",
    icon: <RocketIcon className="h-4 w-4 md:h-6 md:w-6 " />,
    label: "Plenti Agents",
  },
  {
    label: "Messaging",
  },
  {
    path: "/leads",
    icon: <UserIcon className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Conversations",
  },
  {
    path: "/campaign",
    icon: <MegaphoneIcon className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Campaigns",
  },
  {
    path: "/flow",
    icon: <ShareIcon className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Flows",
  },
  {
    path: "/segments",
    icon: <ChartPieIcon className="h-4 w-4 hover:text-white  md:h-6 md:w-6" />,
    label: "Segments",
  },
  {
    path: "/content",
    icon: <BookOpen className="h-4 w-4  md:h-6 md:w-6" />,
    label: "Content",
  },
  {
    label: "Analytics",
  },
  {
    path: "/analytics",
    icon: <ChartIcon className="h-5 w-5 md:h-6 md:w-6" />,
    label: "Dashboard",
  },
  {
    path: "/feedback",
    icon: <ChatBubbleWithWritingIcon className="h-5 w-5  md:h-6 md:w-6" />,
    label: "Feedback & Chat Data",
  },

  {
    label: "Brand",
  },
  {
    path: "/company",
    icon: <GlobeAlt className="h-5 w-5 md:h-6 md:w-6" />,
    label: "Company",
  },
  {
    path: "/locations",
    icon: <MapIcon className="h-5 w-5  md:h-6 md:w-6" />,
    label: "Locations",
  },
  {
    path: "/product",
    icon: <CakeIcon className="h-5 w-5  md:h-6 md:w-6" />,
    label: "Products",
  },
];
