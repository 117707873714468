/* eslint-disable react-hooks/exhaustive-deps */
import { Option, Select, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";

function SelectFontWeight({
  startingWeight,
  handleSelected,
}: {
  handleSelected: (font: number) => void;
  startingWeight?: number;
}) {
  const weights = [100, 200, 300, 400, 500, 600, 700, 800, 900];
  const [selectedWeight, setSelectedWeight] = useState<number>();

  useEffect(() => {
    if (startingWeight) {
      setSelectedWeight(startingWeight);
    }
  }, [startingWeight]);

  useEffect(() => {
    if (selectedWeight) {
      handleSelected(selectedWeight);
    }
  }, [selectedWeight]);

  return (
    <div>
      <Select
        label="Font weight"
        value={String(selectedWeight)}
        onChange={(value: any) => {
          setSelectedWeight(Number(value));
        }}
      >
        {weights &&
          weights?.map((weight, index) => {
            return (
              <Option key={index} value={String(weight)}>
                <Typography>{weight}</Typography>
              </Option>
            );
          })}
      </Select>
    </div>
  );
}

export default SelectFontWeight;
