import { Divider } from "@tremor/react";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { Typography } from "@material-tailwind/react";

function AddFlowHeader() {
  return (
    <div className="flex flex-col gap-2">
      <BreadcrumbsFromConfig
        config={[
          {
            name: "Flows",
            href: "/flow",
            active: false,
          },
          {
            name: "Add",
            href: "/flow/add",
            active: true,
          },
        ]}
      />
      <Typography variant="h2" className="my-4 text-slate-500">
        Add a flow
      </Typography>
      <Typography variant="paragraph" className="max-w-2xl">
        Select a user segment and message template to create a flow.
      </Typography>
      <Divider />
    </div>
  );
}

export default AddFlowHeader;
