import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const LayoutBase = ({ children }: { children: React.ReactNode }) => {
  const currentUrl = window.location.href;

  const isFabrk = currentUrl.includes("fabrk");

  return (
    <div className="landing-cover relative flex flex-col items-center justify-start h-screen">
      <div className="bg-slate-50 bg-transparent bg-cover w-full fixed top-0 left-0 right-0">
        <Link
          to="/"
          className="mt-[15vh] md:mt-[5vh] w-full flex justify-center md:justify-start pl-4 md:pl-8 items-center"
        >
          <img
            src={
              isFabrk
                ? "https://sdknkezpybxgthpealqu.supabase.co/storage/v1/object/public/logos/Untitled_design__11_.png"
                : "https://sdknkezpybxgthpealqu.supabase.co/storage/v1/object/public/logos/Asset%201.png"
            }
            alt="logo"
            className={
              isFabrk
                ? "w-[75px] h-[75px]"
                : "w-[120px] h-[40px] md:w-[110px] md:h-[35px]"
            }
          />
          {isFabrk && <Typography variant="h3">Fabrk AI</Typography>}
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        {children}
      </div>
    </div>
  );
};

export default LayoutBase;
