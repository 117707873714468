import { useNavigate } from "react-router-dom";
import TableWithSearch from "../table/tableWithSearch";
import AdminEmailTableList from "./EmailTableList";
import { useEmailTemplateContext } from "./EmailTemplateContext";

const tableHead = [
  {
    label: "Id",
  },
  {
    label: "Name",
  },
  {
    label: "Created At",
  },
  {
    label: "Updated At",
  },
  {
    label: "Created by",
  },
];

function EmailList() {
  const navigate = useNavigate();

  const { templates } = useEmailTemplateContext();
  function handleAddClick() {
    navigate("/content/email/add");
  }

  return (
    <div>
      <TableWithSearch
        tableHead={tableHead}
        type={"Email Template"}
        rowsPerPage={10}
        handleAddClick={handleAddClick}
      >
        <AdminEmailTableList emailTemplates={templates} />
      </TableWithSearch>
    </div>
  );
}

export default EmailList;
