/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import { useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboardIconButton } from "../hooks/copyToClipboard";
import { useReimbursementContext } from "./reimbursementContext";

function ViewReimbursementLead() {
  const { activeReimbursement } = useReimbursementContext();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-full">
      <div>
        <div>
          <Typography variant="h6">Lead</Typography>
        </div>
        <Divider className="my-2" />
        <div className="flex justify-between">
          <div className="flex flex-col mb-2">
            <p className="text-xs text-gray-500">ID</p>
            <div className="flex flex-row gap-4">
              <p className="text-slate-700">{activeReimbursement?.lead_id}</p>
              <CopyToClipboardIconButton
                text={activeReimbursement?.lead_id as string}
              />
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <Button
              className="flex items-center gap-2 p-1"
              variant="outlined"
              color="gray"
              size="sm"
              onClick={() =>
                navigate(`/lead/${activeReimbursement?.lead_id}`, {
                  state: {
                    name: "Reimbursement",
                    href: location.pathname,
                    active: false,
                  },
                })
              }
            >
              {" "}
              Link to lead
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewReimbursementLead;
