/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";

import ViewReimbursementDetailsAdmin from "../reimbursement/viewReimbursementDetailsAdmin";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import ChatForm from "../lead/chatForm";
import { useMessageContext } from "../lead/messageContext";
import { LocationContextWrapper } from "../location/locationContext";
import DeleteReimbursementButton from "../reimbursement/deleteReimbursementButton";
import { PayoutContextWrapper } from "../reimbursement/payoutContext";
import ReimbursementActionButtons from "../reimbursement/reimbursementActionButtons";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { CampaignContextWrapper } from "../campaign/campaignContext";

function BaseAdminViewReimbursement({
  id,
}: {
  id: string | undefined | string[];
}) {
  const [showSystemMessages, setShowSystemMessages] = useState<boolean>(false);

  const [showAllMessages, setShowAllMessages] = useState<boolean>(false);
  const { activeReimbursement, useGetReimbursementById, updateReimbursement } =
    useReimbursementContext();

  const { activeLead, handleSubmitMessage, messages, loading, setActiveLead } =
    useMessageContext();

  const breadCrumbsConfig = [
    {
      name: "Reimbursement list",
      href: "/reimbursements",
      active: false,
    },
    {
      name: "View",
      href: `/reimbursement/${id}`,
      active: true,
    },
  ];

  useEffect(() => {
    if (id && typeof id === "string" && !activeReimbursement) {
      useGetReimbursementById.mutate({ id });
    }
  }, [id]);

  useEffect(() => {
    if (activeReimbursement?.lead?.id) {
      setActiveLead(activeReimbursement.lead);
    }
  }, [activeReimbursement]);

  return (
    <div className="p-5">
      <BreadcrumbsFromConfig config={breadCrumbsConfig} />

      {activeReimbursement && (
        <div className="mt-2 grid w-full grid-cols-1 gap-6  md:grid-cols-2">
          <div className="flex flex-col gap-10">
            <CampaignContextWrapper>
              <ViewReimbursementDetailsAdmin
                activeReimbursement={activeReimbursement}
              />
            </CampaignContextWrapper>

            <Divider />
            <ReimbursementActionButtons
              id={activeReimbursement?.id as string}
              updateReimbursement={updateReimbursement}
            />

            <div className="maw-w-2xl">
              <Divider />
              <DeleteReimbursementButton />
            </div>
          </div>

          <div className="gap-4 rounded-lg bg-blue-gray-50/50">
            {activeReimbursement?.image_url && (
              <div className="mx-auto text-center max-w-md mb-20">
                <p>Receipt image:</p>
                <img
                  src={activeReimbursement.image_url}
                  alt="reimbursement receipt"
                />
              </div>
            )}
            <div
              className="flex flex-row gap-2 "
              style={{ float: "right", margin: "4px" }}
            >
              <ButtonSecondary
                onClick={() => setShowSystemMessages(!showSystemMessages)}
              >
                {showSystemMessages ? "Hide" : "Show"} system messages
              </ButtonSecondary>
              <ButtonSecondary
                onClick={() => setShowAllMessages(!showAllMessages)}
              >
                {showAllMessages ? "Hide" : "Show"} all messages
              </ButtonSecondary>
            </div>

            {activeReimbursement?.lead?.id && (
              <div className="">
                <ChatForm
                  activeLead={activeLead}
                  handleSubmitMessage={handleSubmitMessage}
                  messages={messages?.filter((m) => {
                    const sameCompany =
                      m.company_id === activeReimbursement?.company_id;
                    return showAllMessages ? true : sameCompany;
                  })}
                  loading={loading}
                  showSystemMessages={showSystemMessages}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function AdminViewReimbursement({ id }: { id: string | undefined | string[] }) {
  const { reimbursementOcr, activeReimbursement } = useReimbursementContext();
  return (
    <div>
      <LocationContextWrapper
        id={activeReimbursement?.location_id || reimbursementOcr?.location_id}
      >
        <PayoutContextWrapper>
          <BaseAdminViewReimbursement id={id} />
        </PayoutContextWrapper>
      </LocationContextWrapper>
    </div>
  );
}

export default AdminViewReimbursement;
