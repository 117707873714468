/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { getAiAgentBySlug } from "../../api/aiAgents.api";
import { useMutation } from "react-query";
import { AiAgentRow } from "../../api/aiAgent.type";

export const AgentContextWrapper = ({
  slug,
  children,
}: {
  slug: string | undefined;
  children: React.ReactNode;
}) => {
  const [agent, setAgent] = useState<AiAgentRow>({} as AiAgentRow);
  const { mutate: getAgent } = useMutation(getAiAgentBySlug, {
    onSuccess: (data) => {
      setAgent(data);
    },
  });

  useEffect(() => {
    if (slug) {
      getAgent(slug);
    }
  }, [slug]);

  const value = {
    agent,
  };

  return (
    <AgentContext.Provider value={value}>{children}</AgentContext.Provider>
  );
};

export const AgentContext = createContext({
  agent: {} as AiAgentRow,
});

export const useAgentContext = () => useContext(AgentContext);
