import { calcPercentage } from "../../../../utils/calculateAverage";
import { ReimbursementCohort } from "../../../../api/leadAnalytics.type";
import { formatFullDateToShortDate } from "../../../../utils/parseTimeStamp";

export function mapReimbursementCounts(
  cohort: ReimbursementCohort,
  Average: number
) {
  const percent = calcPercentage(
    cohort.lead_count_reimbursed,
    cohort.lead_count_signup
  );

  const values = {
    date: formatFullDateToShortDate(cohort.signup_date),
    "Percent reimbursed": percent,
    Average,
    "Sign ups": cohort.lead_count_signup,
  };
  return values;
}
