import { useQuery } from "react-query";
import { getCompanyAiFunctionsByCompany } from "../../../api/companyAiFunction.api";
import { useUserContext } from "../../user/userContext";

export function useLoadCompanyAiFunction({ companyId }: { companyId: string }) {
  const { user } = useUserContext();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: "companyAiFunction",
    queryFn: () => getCompanyAiFunctionsByCompany(companyId),
    enabled: user?.type === "standard" && !!companyId,
  });

  return {
    isLoading,
    data,
    error,
    refetch,
  };
}
