/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import PromptColumnContainer from "./PromptColumDetails";
import PromptOverview from "./PromptOverview";

function PromptDetailsContainer() {
  return (
    <div className="flex flex-col gap-10">
      <PromptOverview />
      <PromptColumnContainer />
    </div>
  );
}

export default PromptDetailsContainer;
