/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCampaignContext } from "../campaign/campaignContext";
import CampaignTableList from "../campaign/campaignTableList";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";

import { CampaignRow } from "../../api/campaign.type";
import {
  handleFilterValue,
  setHeadFilters,
} from "../table/setTableHeadWithFilters";

const TABLE_HEAD = [
  { label: "Name" },
  { label: "Created at" },
  { label: "Company", columnName: "company.name" },
  { label: "Status" },
  { label: "Type" },
  { label: "Edit" },
  { label: "Actions" },
];

function CampaignList() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const { campaigns } = useCampaignContext();
  const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignRow[]>();

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);

  function handleCampaignClick(campaignId: string) {
    navigate(`/campaign/${campaignId}`);
  }
  function baseFilter() {
    return (campaign: CampaignRow, index: number) => index < 10;
  }

  const [campaignFilterFunction, setCampaignFilterFunction] =
    useState<(campaign: CampaignRow, index: number) => boolean>(baseFilter);

  useEffect(() => {
    setHeadFilters(
      campaigns,
      filteredCampaigns,
      setFilteredCampaigns,
      setTableHead,
      TABLE_HEAD
    );
  }, [campaigns]);

  return (
    <div className="max-w-full gap-4">
      {campaigns && campaigns.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          type="campaigns"
          rowCount={
            filteredCampaigns && filteredCampaigns.length > 0
              ? filteredCampaigns.length
              : null
          }
          rowsPerPage={10}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              campaigns,
              setFilteredCampaigns,
              setCampaignFilterFunction,
              baseFilter
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setCampaignFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setCampaignFilterFunction,
              advancePage: false,
            })
          }
        >
          <CampaignTableList
            campaign={filteredCampaigns}
            handleContentClick={handleCampaignClick}
            filterFunction={campaignFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default CampaignList;
