/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-tailwind/react";
import { DownloadData } from "../table/tableWithSearch";
import { DownLoadFileIcon } from "../icons/icons";
import { CSVDownload } from "react-csv";
import { useEffect } from "react";

function DownloadButton({
  downloadData,
  download,
  setDownload,
}: {
  downloadData: DownloadData;
  download: boolean;
  setDownload: (download: boolean) => void;
}) {
  useEffect(() => {
    if (download) {
      setDownload(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      setDownload(false);
    };
  }, []);

  return (
    <div>
      <Button
        className="flex items-center gap-3 bg-primary-500 hover:bg-primary-600"
        size="sm"
        onClick={(e) => {
          e.preventDefault();
          setDownload(true);
        }}
      >
        <DownLoadFileIcon className="h-4 w-4" /> Download CSV
      </Button>
      {download && downloadData && (
        <CSVDownload
          data={downloadData.csvData}
          headers={downloadData.headers}
          filename={downloadData.fileName}
        />
      )}
    </div>
  );
}

export default DownloadButton;
