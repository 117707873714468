/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import InfoPopover from "../InfoPopover/infoPopover";
import EditButton from "../buttons/editButton";
import { useCompanyContext } from "../company/companyContext";
import PlentiUpdateOrCancelButtons from "../forms/PlentiUpdateOrCancelButtons";
import FileUploadComponentWithContext from "../forms/fileUploadComponentWithContext";
import { getDirtyValues } from "../forms/getDirtyValues";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import ViewImage from "../table/viewImage";

type CompanyDefaultTryPageDetailsProps = {
  logo_link_url: string;
  color_one: string;
  color_two: string;
  step_background_color: string;
  disclaimer_color: string;
  subtitle_color: string;
};

function CompanyDefaultTryPageDetails() {
  const { selectedCompanyConfig, updateCompanyConfig, isConfigUpdateLoading } =
    useCompanyContext();

  const [isEdit, setIsEdit] = useState(false);

  const companyConfig = useMemo(() => {
    return selectedCompanyConfig;
  }, [selectedCompanyConfig]);

  const defaultValues = {
    logo_link_url: companyConfig?.logo_link_url as string,
    color_one: companyConfig?.color_one as string,
    color_two: companyConfig?.color_two as string,
    step_background_color: companyConfig?.step_background_color as string,
    disclaimer_color: companyConfig?.disclaimer_color as string,
    subtitle_color: companyConfig?.subtitle_color as string,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit: SubmitHandler<CompanyDefaultTryPageDetailsProps> = (
    data: CompanyDefaultTryPageDetailsProps
  ) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);

    const values = {
      id: companyConfig?.id,
      ...dirtyValues,
    };

    updateCompanyConfig({
      id: companyConfig?.id as string,
      values,
    });

    setIsEdit(false);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [selectedCompanyConfig]);

  if (!companyConfig) {
    return <div>Error: No company config</div>;
  }

  const {
    logo_image_url,
    logo_link_url,
    color_one,
    color_two,
    step_background_color,
    step_one_image_url,
    step_two_image_url,
    step_three_image_url,
    disclaimer_color,
    subtitle_color,
  } = companyConfig;

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <Typography variant="h6">Company Default Try Page Details</Typography>
          <EditButton
            iconClass="h-4 w-4"
            text="Edit"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          />
        </div>

        <Divider className="my-1" />
        <div className="my-4 grid w-full gap-10">
          <div className="flex w-full flex-col gap-6">
            <div className="grid grid-cols-2 gap-4">
              <Fragment>
                {!isEdit ? (
                  <div>
                    {isConfigUpdateLoading ? (
                      <PlentiLogoLoading className="h-12 w-12 mx-auto" />
                    ) : (
                      <ViewImage title="Logo image" src={logo_image_url} />
                    )}
                  </div>
                ) : (
                  <FileUploadComponentWithContext
                    imgUrl={logo_image_url}
                    handleFilePath={(path) => {
                      updateCompanyConfig({
                        id: companyConfig?.id,
                        values: { logo_image_url: path },
                      });
                      setIsEdit(false);
                    }}
                  />
                )}
              </Fragment>
              <Fragment>
                {isEdit ? (
                  <PlentiInput
                    register={register}
                    name="logo_link_url"
                    label="Logo link"
                    errors={errors}
                    required={false}
                  />
                ) : (
                  <ValueWithLabel
                    label="Logo link"
                    value={logo_link_url || ""}
                  />
                )}
              </Fragment>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <Fragment>
                {isEdit ? (
                  <PlentiInput
                    register={register}
                    name="color_one"
                    label="Background color"
                    errors={errors}
                    required={false}
                  />
                ) : (
                  <div className="grid grid-cols-2 gap-1">
                    <ValueWithLabel
                      label="Background color"
                      value={color_one || ""}
                    />
                    <div
                      className={`w-10 h-10`}
                      style={{
                        backgroundColor: color_one || "",
                      }}
                    >
                      {""}
                    </div>
                  </div>
                )}
              </Fragment>
              <Fragment>
                {isEdit ? (
                  <PlentiInput
                    register={register}
                    name="color_two"
                    label="Title text color"
                    errors={errors}
                    required={false}
                  />
                ) : (
                  <div className="grid grid-cols-2 gap-1">
                    <ValueWithLabel
                      label="Title text color"
                      value={color_two || ""}
                    />
                    <div
                      className={`w-10 h-10`}
                      style={{
                        backgroundColor: color_two || "",
                      }}
                    >
                      {""}
                    </div>
                  </div>
                )}
              </Fragment>
              <div className="relative">
                <div className="absolute top-[-12px] right-[-15px]">
                  <InfoPopover>
                    <p className="text-slate-700">
                      This is the background color for all the step circles. You
                      can set either the background color or the images, but not
                      both. If you set both, the images will take precedence. If
                      you want to use images you must set all three.
                    </p>
                  </InfoPopover>
                </div>
                {isEdit ? (
                  <PlentiInput
                    register={register}
                    name="step_background_color"
                    label="Step circle background color"
                    errors={errors}
                    required={false}
                  />
                ) : (
                  <div className="grid grid-cols-2 gap-1">
                    <ValueWithLabel
                      label="Step circle background color"
                      value={step_background_color || ""}
                    />
                    <div
                      className={`w-10 h-10`}
                      style={{
                        backgroundColor: color_one || "",
                      }}
                    ></div>
                  </div>
                )}
              </div>
              <div>
                {isEdit ? (
                  <PlentiInput
                    register={register}
                    name="subtitle_color"
                    label="Subtitle text color"
                    errors={errors}
                    required={false}
                  />
                ) : (
                  <div className="grid grid-cols-2 gap-1">
                    <ValueWithLabel
                      label="Subtitle text color"
                      value={subtitle_color || ""}
                    />
                    <div
                      className={`w-10 h-10`}
                      style={{
                        backgroundColor: subtitle_color || "",
                      }}
                    >
                      {""}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {isEdit ? (
                  <PlentiInput
                    register={register}
                    name="disclaimer_color"
                    label="Disclaimer text color"
                    errors={errors}
                    required={false}
                  />
                ) : (
                  <div className="grid grid-cols-2 gap-1">
                    <ValueWithLabel
                      label="Disclaimer text color"
                      value={disclaimer_color || ""}
                    />
                    <div
                      className={`w-10 h-10`}
                      style={{
                        backgroundColor: disclaimer_color || "",
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-3 gap-4">
              <Fragment>
                {!isEdit ? (
                  <ViewImage title="Step one image" src={step_one_image_url} />
                ) : (
                  <FileUploadComponentWithContext
                    imgUrl={step_one_image_url}
                    handleFilePath={(path) => {
                      updateCompanyConfig({
                        id: companyConfig?.id,
                        values: { step_one_image_url: path },
                      });
                      setIsEdit(false);
                    }}
                  />
                )}
              </Fragment>
              <Fragment>
                {!isEdit ? (
                  <ViewImage title="Step two image" src={step_two_image_url} />
                ) : (
                  <FileUploadComponentWithContext
                    imgUrl={step_two_image_url}
                    handleFilePath={(path) => {
                      updateCompanyConfig({
                        id: companyConfig?.id,
                        values: { step_two_image_url: path },
                      });
                      setIsEdit(false);
                    }}
                  />
                )}
              </Fragment>
              <Fragment>
                {!isEdit ? (
                  <ViewImage
                    title="Step three image"
                    src={step_three_image_url}
                  />
                ) : (
                  <FileUploadComponentWithContext
                    imgUrl={step_three_image_url}
                    handleFilePath={(path) => {
                      updateCompanyConfig({
                        id: companyConfig?.id,
                        values: { step_three_image_url: path },
                      });
                      setIsEdit(false);
                    }}
                  />
                )}
              </Fragment>
            </div>
          </div>
        </div>

        {isEdit && (
          <PlentiUpdateOrCancelButtons isEdit={isEdit} setIsEdit={setIsEdit} />
        )}
      </form>
    </div>
  );
}

export default CompanyDefaultTryPageDetails;
