import { Input } from "@material-tailwind/react";

import { ErrorHelperText } from "./errorHelperText";

export type PlentiInputProps = {
  register: any;
  errors?: any;
  label?: string;
  name: string;
  required?: boolean;
  [key: string]: any;
};

export const PlentiInput = ({
  register,
  errors,
  label,
  name,
  required = true,
  ...props
}: PlentiInputProps) => {
  return (
    <div className="mb-2">
      <Input
        label={label || ""}
        variant="outlined"
        {...register(name, { required })}
        className="focus:border-t-0 focus:ring-0"
        {...props}
      />
      {errors[name] && (
        <ErrorHelperText
          text={errors[name].message || `Label ${label} invalid`}
        />
      )}
    </div>
  );
};
