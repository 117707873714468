const baseApi = process.env.REACT_APP_API_URL;

export const phoneInput = ({
  campaignId,
  companyId,
  companyName,
}: {
  campaignId: string;
  companyId: string;
  companyName: string;
}) =>
  `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Phone Input Form</title>
    <style>
        .mx-auto { margin-left: auto; margin-right: auto; }
        .mt-10 { margin-top: 40px; }
        .gap-5 > * { margin-right: 20px; }
        .flex { display: flex; }
        .flex-col { flex-direction: column; }
        .gap-2 > * { margin-bottom: 10px; }
        /* Additional styles needed for your form */
    </style>
</head>
<body>
    <div class='mx-auto mt-10 flex flex-col gap-5'>
        <div id="formContainer" class='flex flex-col content-end gap-2 '>
            <input type="tel" placeholder="(555) 555-3333" class="phone-input" pattern="\\(\\d{3}\\) \\d{3}-\\d{4}" title="Phone number format: (555) 555-3333" />
            <label class="consent-checkbox" style="font-size: 9px; line-height: 12px;">
                <input type="checkbox" checked/> I consent to receive text messages
            </label>
            <p style="font-size:7px;">By providing your phone number or submitting this form, you agree to receive recurring automated marketing text messages (e.g. promos, cart reminders) from Plenti AI and ${companyName} at the number provided. Consent is not a condition to obtain goods or services. Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. You also agree to our Privacy Policy & Terms.</p>
            <button class="send-btn">Send</button>
        </div>
    </div>

    <script>
        document.querySelector('.send-btn').addEventListener('click', function(event) {
            event.preventDefault();
            var phoneInputValue = document.querySelector('.phone-input').value;
            const formattedNumber = '+' + phoneInputValue.replace(/\\D/g, '').padStart(11, '1');
            var isConsented = document.querySelector('.consent-checkbox input').checked;
            const apiEndpoint = '${baseApi}/lead/create';
            if (formattedNumber && isConsented) {
                fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phone: formattedNumber, is_test: false, campaign_id: "${campaignId}", company_id: "${companyId}", source: "${companyName} landing page" }),
                })
                .then(response => response.json())
                .then(data => {
                    document.getElementById('formContainer').innerHTML = '<p>Thank you for signing up! You will be contacted via text soon!</p>';
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            } else {
                alert('Please enter a phone number and consent to receive text messages.');
            }
        });
    </script>
    <script>
        document.querySelector('.phone-input').addEventListener('input', function(e) {
            var input = e.target.value.replace(/\\D/g, '');
            var formatted = '';
            if (input.length > 6) {
                formatted = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
            } else if (input.length > 3) {
                formatted = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
            } else if (input.length > 0) {
                formatted = '(' + input.substring(0, 3);
            }
            e.target.value = formatted;
        });
    </script>
</body>
</html>
`;
