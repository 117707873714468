import { createContext, useContext } from "react";
import { useMutation } from "react-query";

import { useAlertContext } from "../alert/alertContext";
import { CreatePayoutMutation } from "../reimbursement/payoutContext.types";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";

import { createPayout } from "../../api/payout.api";

export const PayoutContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const { useGetReimbursementById } = useReimbursementContext();
  const useCreatePayout = useMutation(createPayout, {
    onSuccess: async (res) => {
      useGetReimbursementById.mutate({
        id: res?.batch_header?.sender_batch_header?.sender_batch_id,
      });
      setAlertProps({
        message: `Payout created`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const value = { useCreatePayout };

  return (
    <PayoutContext.Provider value={value}>
      {props.children}
    </PayoutContext.Provider>
  );
};

export const PayoutContext = createContext({
  useCreatePayout: {} as CreatePayoutMutation,
});

export const usePayoutContext = () => useContext(PayoutContext);
