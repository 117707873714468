export function FreeIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      className={className}
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x="0"
          y="0"
          width="1"
          height="1"
        />

        <g>
          <g>
            <path d="M50,2.5C23.8,2.5,2.5,23.8,2.5,50S23.8,97.5,50,97.5S97.5,76.2,97.5,50S76.2,2.5,50,2.5z M80.2,73.8l-6.6-6.6      c-0.6,0.2-1.2,0.3-1.8,0.3H61.1l12.7,12.7C67.2,85.4,59,88.5,50,88.5c-21.2,0-38.5-17.3-38.5-38.5c0-9,3.1-17.2,8.3-23.8      l6.6,6.6c0.6-0.2,1.2-0.3,1.8-0.3h10.7L26.2,19.8c6.6-5.2,14.8-8.3,23.8-8.3c21.2,0,38.5,17.3,38.5,38.5      C88.5,59,85.4,67.2,80.2,73.8z" />
          </g>
          <g>
            <path d="M71.8,35.7H28.2c-1.2,0-2.2,1-2.2,2.2v24c0,1.2,1,2.2,2.2,2.2h43.5c1.2,0,2.2-1,2.2-2.2V38C74,36.7,73,35.7,71.8,35.7z       M70.1,53.8C70.1,53.8,70.1,53.8,70.1,53.8c-0.4,0-0.8,0-1.1,0.1c-3.2,0.5-5.6,3.2-5.9,6.4c0,0,0,0.1,0,0.1H36.9      c0,0,0-0.1,0-0.1c-0.2-3.2-2.7-5.8-5.9-6.3c-0.3-0.1-0.7-0.1-1.1-0.1c0,0,0,0,0,0v-7.7c0,0,0,0,0,0c0.4,0,0.7,0,1.1-0.1      c3.2-0.5,5.6-3.1,5.8-6.3c0,0,0-0.1,0-0.1h26.2c0,0,0,0.1,0,0.1c0.3,3.2,2.7,5.8,5.9,6.3c0.3,0.1,0.7,0.1,1.1,0.1c0,0,0,0,0,0      V53.8z" />
          </g>
          <g>
            <circle cx="50" cy="50" r="7.5" />
          </g>
        </g>
      </switch>
    </svg>
  );
}
