import { Card, CardBody, Typography } from "@material-tailwind/react";
import { DollarIcon } from "../icons/icons";

function ReimbursementCard({ explanation }: { explanation?: string }) {
  return (
    <Card className="w-1/2">
      <CardBody>
        <DollarIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Reimbursements
        </Typography>
        <Typography>{explanation}</Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default ReimbursementCard;
