export function getNextItemInObject(obj: any, key: string) {
  const keys = Object.keys(obj);
  const index = obj.findIndex((item: any) => item.name === key);
  const nextKey = keys[index + 1];
  const next = obj[nextKey];

  return next;
}

export function uniqueStrings(strings: string[]): string[] {
  return Array.from(new Set(strings));
}
