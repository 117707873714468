import { useQuery } from "react-query";
import { getSmsContentMetadataTotals } from "../../../api/smsContentMetadata.api";

export const useLoadSmsContentMetadataTotals = () => {
  const { data: contentTotals, isLoading } = useQuery({
    queryKey: "contentTotals",
    queryFn: () => getSmsContentMetadataTotals(),
  });

  return { contentTotals, isLoading };
};
