import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import PlentiCurrencyInput from "../forms/plentiCurrencyInput";
import { useCustomerContext } from "./customerContex";

function AutoRecharge({ setIndex }: { setIndex: (index: number) => void }) {
  const { useUpdateCustomer, customer } = useCustomerContext();

  const [belowAmount, setBelowAmount] = useState<string>(
    customer?.min_balance ? String(customer.min_balance / 100) : "250"
  );
  const [rechargeAmount, setRechargeAmount] = useState<string>(
    customer?.max_balance ? String(customer.max_balance / 100) : "500"
  );

  function handleSubmit() {
    useUpdateCustomer.mutate({
      min_balance: Math.floor(Number(belowAmount) * 100),
      max_balance: Math.floor(Number(rechargeAmount) * 100),
    });
    setIndex(0);
  }

  function handleRemoveAutoRecharge() {
    useUpdateCustomer.mutate({
      min_balance: 0,
      max_balance: 0,
    });
    setIndex(0);
  }

  useEffect(() => {
    if (customer && customer.min_balance > 0 && customer.max_balance > 0) {
      setBelowAmount(String(customer.min_balance / 100));
      setRechargeAmount(String(customer.max_balance / 100));
    }
  }, [customer]);

  return (
    <div className="flex flex-col gap-5">
      <Typography
        variant="paragraph"
        className="max-w-2xl text-Sofia-pro font-heavy subpixel-antialiased"
      >
        Keep your Plenti AI services running smoothly by automatically setting
        your account balance to be recharged when it runs low.
      </Typography>

      <div>
        <Typography
          variant="paragraph"
          className="max-w-2xl text-Sofia-pro font-heavy subpixel-antialiased font-bold"
        >
          When balance goes below
        </Typography>
        <PlentiCurrencyInput
          amount={belowAmount}
          setAmount={(amount) => setBelowAmount(amount)}
        />

        <Typography
          variant="small"
          color="gray"
          className="mt-2 flex items-center gap-1 font-normal"
        >
          Enter an amount between $250 and $4,000
        </Typography>
      </div>
      <div>
        <Typography
          variant="paragraph"
          className="max-w-2xl text-Sofia-pro font-heavy subpixel-antialiased font-bold"
        >
          Bring balance back up to
        </Typography>
        <PlentiCurrencyInput
          amount={rechargeAmount}
          setAmount={(rechargeAmount) => setRechargeAmount(rechargeAmount)}
        />

        <Typography
          variant="small"
          color="gray"
          className="mt-2 flex items-center gap-1 font-normal"
        >
          Enter an amount between $350 and $9,999
        </Typography>
      </div>
      <div className="flex flex-row gap-2">
        <Button color="green" size="md" onClick={handleSubmit}>
          Save
        </Button>
        <Button
          color="red"
          size="md"
          variant="outlined"
          onClick={handleRemoveAutoRecharge}
        >
          Disable auto recharge
        </Button>
      </div>
    </div>
  );
}

export default AutoRecharge;
