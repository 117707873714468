import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { PurchaseCheck, ReceiptOcrRow } from "../api/receiptOcr.type";

export function getOcr(): Promise<ReceiptOcrRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.ocr;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<ReceiptOcrRow[]>(url, request);
}

export function getOcrById(id: string): Promise<ReceiptOcrRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.ocr + "/" + id;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<ReceiptOcrRow>(url, request);
}

export function runPurchaseCheck({
  ocrId,
  campaignId,
}: {
  ocrId: string;
  campaignId: string;
}): Promise<PurchaseCheck> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.ocr +
    "/purchase-check/" +
    ocrId;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ campaignId }),
  };

  return fetchData<PurchaseCheck>(url, request);
}

export function updateOcrById({
  ocrId,
  values,
}: {
  ocrId: string;
  values: Partial<ReceiptOcrRow>;
}): Promise<ReceiptOcrRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.ocr + "/" + ocrId;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<ReceiptOcrRow>(url, request);
}

export function createOcrWithValues(
  values: Partial<ReceiptOcrRow>
): Promise<ReceiptOcrRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.ocr;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<ReceiptOcrRow>(url, request);
}
