import { UserRow } from "./user.type";

export type EmailTemplateRow = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  content: string;
  user_id: string;
  users: UserRow;
  company_id: string;
  subject: string;
  type: string;
};

export function isEmailTemplateRow(obj: any): boolean {
  // First, check if the object is defined and is indeed an object
  if (!obj || typeof obj !== "object" || Array.isArray(obj)) return false;

  // Check if the 'subject' key exists in the object
  if ("subject" in obj) {
    // Additional check: ensure the 'subject' is a non-empty string
    return true;
  }

  // Return false if the 'subject' key doesn't exist or doesn't meet the criteria
  return false;
}

export type CreateEmailTemplateRow = {
  name: string;
  content: string;
  company_id: string;
  subject: string;
};

export type UpdateEmailTemplateRow = {
  id: string;
  name?: string;
  content?: string;
  updated_at?: string;
  user_id?: string;
  company_id?: string;
};
