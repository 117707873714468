/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import { Card, Metric, Text } from "@tremor/react";
import { useEffect, useState } from "react";
import { UsageRow } from "../../api/usage.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { getMonthStartAndEndDate } from "../../utils/parseTimeStamp";
import { useCompanyContext } from "../company/companyContext";
import DownloadButton from "../downloadCsv/DownloadButton";
import SelectMonth from "../forms/selectMonth";
import { XMarkIcon } from "../icons/icons";
import { useBillingContext } from "./billingContext";
import { useCustomerContext } from "./customerContex";
import ReimbursementDailyUsageChart from "./reimbursementDailyUsageChart";
import SmsDailyUsageChart from "./smsDailyUsageChart";
import UsageOverview from "./usageOverview";
import { mapDailyRollup } from "./mapDailyRollup";

function BillingOverview({ setIndex }: { setIndex: (value: number) => void }) {
  const { dailyUsage, monthlyUsage } = useBillingContext();
  const [selectedUsage, setSelectedUsage] = useState<UsageRow>();
  const [download, setDownload] = useState(false);

  const { getCustomerUsageDailyRollup, dailyRollupByPeriod } =
    useCustomerContext();

  const { customer } = useCustomerContext();
  const { selectedCompany } = useCompanyContext();

  useEffect(() => {
    const now = new Date();
    if (dailyUsage && !selectedUsage) {
      const found = dailyUsage.find(
        (u) => u.month === now.getMonth() + 1 && u.year === now.getFullYear()
      );
      if (found) {
        setSelectedUsage(found);
      }
    }
  }, [dailyUsage]);

  const autoRechargeEnabled = customer && customer?.min_balance > 0;

  useEffect(() => {
    if (selectedUsage && selectedCompany?.id) {
      const { startDate, endDate } = getMonthStartAndEndDate({
        month: selectedUsage.month,
        year: selectedUsage.year,
      });
      getCustomerUsageDailyRollup({
        companyId: selectedCompany?.id,
        startDate,
        endDate,
      });
    }
  }, [selectedUsage, selectedCompany]);

  useEffect(() => {
    if (download) {
      setDownload(false);
    }
  }, [download]);

  return (
    <div className="grid grid-cols-1 gap-10 w-full">
      <div className="grid grid-cols-2 gap-4 max-w-2xl">
        <Card className="max-w-xs flex flex-col gap-4">
          <Text>Plenti Balance</Text>
          <Metric
            color={
              !customer?.balance || customer.balance <= 0 ? "red" : "green"
            }
          >
            {customer?.balance || customer?.balance === 0
              ? parseCentsToDollars(customer?.balance)
              : "N/A"}
          </Metric>
          <Button color="green" variant="outlined" onClick={() => setIndex(3)}>
            Add Funds
          </Button>
        </Card>
        <Card className="max-w-xs">
          <Text>Auto Recharge</Text>
          <div className="flex items-center space-x-3 mt-2">
            {autoRechargeEnabled ? (
              <CheckIcon className="w-5 h-5 bg-green-500 text-white rounded-full p-1" />
            ) : (
              <XMarkIcon className="w-5 h-5 bg-red-500 text-white rounded-full p-1" />
            )}
            <label htmlFor="switch" className="text-sm text-gray-500">
              {autoRechargeEnabled ? "Enabled" : "Disabled"}
              <span
                className="font-medium text-blue-500 hover:underline cursor-pointer"
                onClick={() => {
                  console.log("edit auto recharge");
                  setIndex(1);
                }}
                role="button"
              >
                {" "}
                Edit auto recharge
              </span>
            </label>
          </div>
        </Card>
      </div>
      <div className="max-w-2xl flex flex-row justify-between">
        {monthlyUsage && (
          <div>
            <SelectMonth
              months={monthlyUsage?.map((u) => {
                return {
                  id: u.id,
                  month: u.month,
                  year: u.year,
                };
              })}
              selectedMonth={selectedUsage}
              setSelectedItem={(item) => {
                const found = monthlyUsage?.find((u) => u.id === item.id);
                if (found) {
                  setSelectedUsage(found);
                }
              }}
            />
          </div>
        )}
        {selectedUsage && dailyRollupByPeriod && (
          <DownloadButton
            downloadData={{
              headers: [
                {
                  label: "Day",
                  key: "day",
                },
                {
                  label: "Beginning Balance",
                  key: "beginning_balance",
                },
                {
                  label: "Ending Balance",
                  key: "ending_balance",
                },
                {
                  label: "SMS Contribution",
                  key: "sms_contribution",
                },
                {
                  label: "Reimbursement Contribution",
                  key: "reimbursement_contribution",
                },
                {
                  label: "Payment Contribution",
                  key: "payment_contribution",
                },
                {
                  label: "SMS Count",
                  key: "sms_count",
                },
                {
                  label: "Reimbursement Count",
                  key: "reimbursement_count",
                },
              ],
              csvData: mapDailyRollup(dailyRollupByPeriod),
              fileName: "usage.csv",
            }}
            download={download}
            setDownload={(value) => setDownload(value)}
          />
        )}
      </div>
      <div className="max-w-2xl">
        <UsageOverview selectedUsage={selectedUsage} />
      </div>
      <div className="max-w-2xl">
        <SmsDailyUsageChart selectedUsage={selectedUsage} />
      </div>
      <div className="max-w-2xl">
        <ReimbursementDailyUsageChart selectedUsage={selectedUsage} />
      </div>
    </div>
  );
}

export default BillingOverview;
