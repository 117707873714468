/* eslint-disable react-hooks/exhaustive-deps */
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useState } from "react";

import ValueWithLabel from "../forms/valueWithLabel";
import { useCampaignContext } from "./campaignContext";

import { useLocation, useNavigate } from "react-router-dom";
import { PromptRow } from "../../api/prompt.type";
import InfoPopover from "../InfoPopover/infoPopover";
import { PlusIcon } from "../icons/icons";
import { usePromptContext } from "../prompt/promptContext";

function CampaignPromptContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { activeCampaign, associatedCompany, updateCampaign } =
    useCampaignContext();
  const { prompts } = usePromptContext();

  const [selectedPrompt, setSelectedPrompt] = useState<PromptRow>();

  function handleSubmit() {
    updateCampaign({
      id: activeCampaign?.id as string,
      system_prompt_id: selectedPrompt?.id as string,
    });
    setSelectedPrompt(undefined);
  }

  const prompt = activeCampaign?.system_prompt;

  return (
    <div className="w-full">
      {activeCampaign && (
        <Fragment>
          <div className="relative flex justify-between">
            <div className="relative">
              <Typography variant="h6">Campaign Prompt</Typography>
              <div className="absolute top-[-12px] right-[-15px]">
                <InfoPopover>
                  <p className="text-slate-700">
                    This is the prompt that informs the AI agent how to respond
                    to campaign specific user questions.
                  </p>
                </InfoPopover>
              </div>
            </div>
            <Menu placement="bottom">
              <div className="flex flex-row gap-2">
                <MenuHandler>
                  <Button
                    className="flex items-center gap-2 p-1"
                    variant="outlined"
                    color="gray"
                    size="sm"
                  >
                    <PlusIcon className="h=4 w-4" />
                    {prompt ? "Change" : "Add"}
                  </Button>
                </MenuHandler>

                <Button
                  className="flex items-center gap-2 p-1"
                  variant="outlined"
                  color="gray"
                  size="sm"
                  onClick={() =>
                    navigate(`/prompt/${prompt?.id}`, {
                      state: {
                        name: "Campaign",
                        href: location.pathname,
                        active: false,
                      },
                    })
                  }
                >
                  <PencilIcon className="h=4 w-4" />
                  edit
                </Button>
              </div>
              <MenuList>
                {prompts
                  ?.filter(
                    (p) =>
                      p.company_id === associatedCompany?.id &&
                      p.type !== "company" &&
                      p.id !== prompt?.id
                  )
                  .map((prompt) => {
                    return (
                      <MenuItem
                        onClick={() => setSelectedPrompt(prompt)}
                        key={prompt?.id}
                      >
                        {prompt?.name}
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </Menu>
          </div>
          <Divider className="my-1" />
          <div className="my-4 grid w-full  gap-10">
            {(prompt?.id || selectedPrompt?.id) && (
              <div className="flex flex-col gap-6">
                <div className="grid grid-cols-2 items-end ">
                  <ValueWithLabel
                    label="Prompt name"
                    value={selectedPrompt?.name || prompt?.name || ""}
                  />
                </div>
                <ValueWithLabel
                  label="Prompt text"
                  value={
                    selectedPrompt?.text
                      ? `${selectedPrompt?.text.substring(0, 500)}...`
                      : prompt && prompt.text
                      ? `${prompt.text.substring(0, 500)}...`
                      : ""
                  }
                />
              </div>
            )}
          </div>

          {selectedPrompt?.id && (
            <div className="mt-6 flex justify-end gap-2">
              <Button
                size="sm"
                variant="outlined"
                color="gray"
                onClick={() => setSelectedPrompt(undefined)}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                color="green"
                fullWidth={false}
                disabled={!selectedPrompt?.id}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default CampaignPromptContainer;
