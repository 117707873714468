import { Typography } from "@material-tailwind/react";
import { AdminGptContextWrapper } from "./adminGptContext";
import ChatTestingContainer from "./chatTestingContainer";

function ChatTesting() {
  return (
    <AdminGptContextWrapper>
      <Typography variant="h2" className="mb-10">
        Chat Testing
      </Typography>
      <ChatTestingContainer />
    </AdminGptContextWrapper>
  );
}

export default ChatTesting;
