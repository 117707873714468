/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  MarkerType,
  NodeChange,
  NodeOrigin,
  ReactFlowProvider,
  addEdge,
  applyNodeChanges,
  updateEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";

import "reactflow/dist/base.css";
import CampaignSelectNode from "../../reactFlow/CampaignSelectNode";
import NameNode from "../../reactFlow/NameNode";
import { useLoadCampaignsTransitions } from "../../reactFlow/hooks/LoadCampaignTransitions";
import { useScreenSize } from "../../reactFlow/hooks/ScreenSizeContext";
import { createNodesAndEdges } from "./mapTransitionsToNode";

import { CampaignConversationMetrics } from "../../../api/conversation.type";
import { useCompanyContext } from "../../company/companyContext";
import { useCampaignJourneyContext } from "../../reactFlow/hooks/CampaignJourneyContext";
import { useCampaignTransitionContext } from "../../reactFlow/hooks/CampaignTransitionFlowContext";
import { useLoadCampaignJourneys } from "../../reactFlow/hooks/LoadCampaignJourney";
import { mapCampaignMetricsToPositions } from "./mapCampaignMetricsToPositions";

const nodeTypes = {
  custom: NameNode,
  campaignSelect: CampaignSelectNode,
};

const nodeOrigin: NodeOrigin = [0.5, 0.5];

const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges();

const _JourneysFlow = ({
  campaignMetrics,
}: {
  campaignMetrics: CampaignConversationMetrics[] | undefined;
}) => {
  const edgeUpdateSuccessful = useRef(true);

  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  const screenSize = useScreenSize();

  const { campaignTransitions } = useLoadCampaignsTransitions();

  const { updateInitialPosition, updatePosition } = useCampaignJourneyContext();

  const { createTransition, removeTransition } = useCampaignTransitionContext();

  const { selectedCompany } = useCompanyContext();

  const { campaignJourneys } = useLoadCampaignJourneys({
    companyId: selectedCompany?.id as string,
  });

  const [rfInstance, setRfInstance] = useState(null);

  const onConnect = useCallback((params: Edge | Connection) => {
    createTransition({
      toCampaign: params.target as string,
      fromCampaign: params.source as string,
      triggerType: params.sourceHandle as string,
    });

    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      removeTransition(oldEdge.id);
      createTransition({
        toCampaign: newConnection.target as string,
        fromCampaign: newConnection.source as string,
        triggerType: newConnection.sourceHandle as string,
      });

      setEdges((els) => updateEdge(oldEdge, newConnection, els));
    },
    []
  );

  const onEdgeUpdateEnd = useCallback((_: any, edge: { id: string }) => {
    if (!edgeUpdateSuccessful.current) {
      removeTransition(edge.id);
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  useEffect(() => {
    if (campaignMetrics && campaignMetrics.length > 0) {
      const mapped = mapCampaignMetricsToPositions(
        campaignMetrics,
        campaignJourneys
      );

      setNodes(mapped);
    }

    if (campaignTransitions) {
      const updatedEdges = campaignTransitions.map((transition) => {
        return {
          id: transition.id,
          source: transition.from_campaign,
          label: transition.trigger_type,
          target: transition.to_campaign,
          animated: true,
          sourceHandle: transition.trigger_type,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        };
      });
      setEdges(updatedEdges);
    }
  }, [campaignMetrics, campaignTransitions, campaignJourneys]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      const activeNode: NodeChange = changes[0];

      // Use a ref to store the timeout ID

      setNodes((oldNodes) => {
        // Clear any existing timeout to ensure only the latest change is applied
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        // Set a new timeout to delay the update
        timeoutRef.current = setTimeout(() => {
          if (
            activeNode &&
            "dragging" in activeNode &&
            activeNode.dragging === false
          ) {
            const node = oldNodes.find((n) => n.id === activeNode.id);

            if (node) {
              updatePosition({
                campaign_id: activeNode.id as string,
                x_position: Number(node.position.x.toFixed(0)),
                y_position: Number(node.position.y.toFixed(0)),
              });
            }
          }
        }, 500);

        return applyNodeChanges(changes, oldNodes);
      });
    },
    [setNodes]
  );

  useEffect(() => {
    // This sets the initial position of the nodes in the db
    if (campaignJourneys && campaignJourneys.length === 0 && rfInstance) {
      // @ts-ignore
      const nodes = rfInstance.getNodes();

      updateInitialPosition(nodes);
    }
  }, [campaignJourneys, rfInstance]);

  return (
    <div
      style={{
        width: screenSize.width - 350,
        height: screenSize.height - 300,
        marginLeft: 5,
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        snapGrid={[15, 15]}
        fitViewOptions={{ padding: 2 }}
        nodeOrigin={nodeOrigin}
        // @ts-ignore
        onInit={setRfInstance}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

function JourneysFlow({
  campaignMetrics,
}: {
  campaignMetrics: CampaignConversationMetrics[] | undefined;
}) {
  return (
    <ReactFlowProvider>
      <_JourneysFlow campaignMetrics={campaignMetrics} />
    </ReactFlowProvider>
  );
}

export default JourneysFlow;
