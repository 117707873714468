import LocationCard from "./LocationCard";
import LocationList from "./LocationList";

function LocationContainer() {
  return (
    <div className="p-5">
      <LocationCard />
      <LocationList />
    </div>
  );
}

export default LocationContainer;
