/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";

import { LineItemRow } from "../../api/lineItem.type";
import GenericSelect from "../forms/GenericSelect";
import { useLineItemContext } from "./LineItemContext";
import { useOcrContext } from "./ocrContext";

function LineItemsList({ lineItems }: { lineItems: LineItemRow[] }) {
  const { updateLineItem, products } = useLineItemContext();
  const { useGetOcrById } = useOcrContext();
  const [isEditingId, setIsEditingId] = useState("");
  const [selected, setSelected] = useState<string | null>(null);

  function mapOcrToRow(lineItem: LineItemRow): {
    id: string;
    confidence: number;
    description: string;
    total: number;
    product: string;
    confirmed: boolean | null;
  } {
    const values = {
      id: lineItem.id,
      confidence: lineItem.confidence,
      description: lineItem.description,
      total: lineItem.totalAmount,
      product: lineItem.product ? lineItem.product.name : "N/A",
      confirmed: lineItem.is_product_confirmed,
    };

    return values;
  }

  function handleUpdateLineItem(id: string, values: Partial<LineItemRow>) {
    updateLineItem({
      id,
      values,
    });

    setTimeout(() => {
      useGetOcrById.mutate(lineItems[0].receipt_ocr_id);
      setIsEditingId("");
    }, 1000);
  }

  function handleSetSelected(value: string, lineItemId: string) {
    const foundProduct = products?.find((product) => product.name === value);
    setSelected(value);
    if (foundProduct) {
      handleUpdateLineItem(lineItemId, {
        product_id: foundProduct.id,
        is_product_confirmed: true,
      });
    }
  }

  return (
    <Fragment>
      {lineItems &&
        lineItems
          .map((item) => mapOcrToRow(item))
          .sort((a, b) => b.confidence - a.confidence)
          .map(
            (
              { confidence, description, total, product, confirmed, id },
              index
            ) => {
              console.log("confirmed", confirmed);
              const isLast = index === lineItems.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {confidence}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {description}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {total}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div
                      className="flex flex-col"
                      onClick={() => {
                        setIsEditingId(id);
                      }}
                    >
                      {isEditingId === id ? (
                        <GenericSelect
                          values={
                            products?.map((product) => product.name) || []
                          }
                          setSelected={(value) => handleSetSelected(value, id)}
                        />
                      ) : (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {product}
                        </Typography>
                      )}
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-row gap-1">
                      <Button
                        onClick={() =>
                          handleUpdateLineItem(id, {
                            is_product_confirmed:
                              confirmed === true ? null : true,
                          })
                        }
                        value="Yes"
                        color="green"
                        size="sm"
                        variant={
                          confirmed === null
                            ? "outlined"
                            : confirmed
                            ? "filled"
                            : "outlined"
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() =>
                          handleUpdateLineItem(id, {
                            is_product_confirmed:
                              confirmed === false ? null : false,
                          })
                        }
                        size="sm"
                        value="No"
                        color="red"
                        variant={
                          confirmed === null
                            ? "outlined"
                            : confirmed === false
                            ? "filled"
                            : "outlined"
                        }
                      >
                        No
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default LineItemsList;
