import mixpanel from "mixpanel-browser";

function isProduction() {
  return process.env.NODE_ENV === "production";
}

export const initMixpanel = () => {
  if (isProduction()) {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (token) {
      mixpanel.init(token, {
        track_pageview: true,
        persistence: "localStorage",
      });
    }
  }
};

export const identifyMixpanelUser = (
  id: string,
  type: string,
  email: string
) => {
  // Set this to a unique identifier for the user performing the event.
  if (isProduction()) {
    mixpanel.identify(id);
    mixpanel.people.set_once({ type });
    mixpanel.people.set_once({ $email: email });
  }
};

export const mixpanelTrackEvent = (event: string, properties?: any) => {
  // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
  if (isProduction()) {
    mixpanel.track(event, properties);
  }
};
