import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { MergeTagRow } from "./mergeTag.type";

export function getMergeTags(): Promise<MergeTagRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.mergeTag;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<MergeTagRow[]>(url, request);
}
