/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../utils/stringManipulation";
import DateDetailsDetails from "../forms/createAtDetails";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import { useCompanyContext } from "./companyContext";
import { useUserContext } from "../user/userContext";

type CreateUser = {
  first_name: string;
  last_name: string;
  email: string;
};

function CompanyUsersList() {
  const { user } = useUserContext();
  const { selectedCompany, getCompanyUsers, companyUsers, addCompanyUser } =
    useCompanyContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
  });

  function onSubmit(data: CreateUser) {
    addCompanyUser({
      company_id: selectedCompany?.id as string,
      ...data,
    });
    reset();
  }

  useEffect(() => {
    if (selectedCompany) {
      getCompanyUsers(selectedCompany?.id);
    }
  }, []);

  if (!selectedCompany) {
    return <div>Error: No Company</div>;
  }

  return (
    <div className="w-full">
      <div className="my-4 max-w-2xl gap-10">
        <div className="flex flex-col">
          <Typography variant="h6" className="mb-4">
            Add User
          </Typography>
          {register && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-4">
                <PlentiInput
                  register={register}
                  name="first_name"
                  label="First name"
                  errors={errors}
                />
                <PlentiInput
                  register={register}
                  name="last_name"
                  label="Last name"
                  errors={errors}
                />
                <PlentiInput
                  register={() =>
                    register("email", {
                      required: "Email is required",
                      pattern: {
                        value: emailRegex,
                        message: "Invalid Email",
                      },
                    })
                  }
                  name="email"
                  label="Email"
                  errors={errors}
                />
              </div>
              <div className="mt-6 flex justify-end gap-2">
                <Button
                  size="sm"
                  variant="outlined"
                  color="gray"
                  onClick={() => reset()}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="green"
                  fullWidth={false}
                  disabled={!isDirty}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </div>

        <div className="flex w-full flex-col gap-10">
          <Divider className="my-1" />
          {companyUsers?.map((userRow) => {
            const { users, created_at } = userRow;

            return (
              <div className="grid grid-cols-2 gap-2">
                <ValueWithLabel label="First Name" value={users?.first_name} />
                <ValueWithLabel label="Last Name" value={users?.last_name} />
                <ValueWithLabel label="Email" value={users?.email} />
                <DateDetailsDetails date={created_at} title="Created" />
                {user?.role === "admin" && (
                  <div className="col-span-2">
                    <ValueWithLabel label="ID" value={userRow.id} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CompanyUsersList;
