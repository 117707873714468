import { Button, Typography } from "@material-tailwind/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCompanyContext } from "../company/companyContext";
import { PlentiInput } from "../forms/plentiInput";

type CreateCompany = {
  name: string;
  slug: string;
  subdomain: string;
};

const formValues = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Slug",
    name: "slug",
  },
  {
    label: "Subdomain",
    name: "subdomain",
  },
  {
    label: "Product name",
    name: "product",
  },
];

function AdminNewCompany() {
  const { useCreateNewCompany } = useCompanyContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateCompany>();
  const onSubmit: SubmitHandler<CreateCompany> = (data: CreateCompany) => {
    const values = {
      ...data,
    };
    useCreateNewCompany.mutate(values);
    reset();
  };
  return (
    <div>
      <Typography variant="h6">Create a new company</Typography>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-md">
        {formValues.map((formValue, index) => (
          <PlentiInput
            key={index}
            register={register}
            errors={errors}
            label={formValue.label}
            name={formValue.name}
          />
        ))}
        <Button type="submit">Create</Button>
      </form>
    </div>
  );
}

export default AdminNewCompany;
