/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  DonutChart,
  Metric,
  Select,
  SelectItem,
  Text,
  Title,
} from "@tremor/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import { useCampaignContext } from "../campaign/campaignContext";

import { CampaignRow } from "../../api/campaign.type";
import { getMessagesByCampaign } from "../../api/messageAnalytics";
import { getSms } from "../../api/sms.api";
import { SmsMessage } from "../../api/sms.type";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";

export type MessageByTag = {
  name: string;
  count: number;
};

function MessageChart() {
  const [campaignsWithAll, setCampaignsWithAll] = useState<
    CampaignRow[] | null
  >();
  const useGetMessagesByCampaign = useMutation(getMessagesByCampaign, {
    onSuccess: async (res) => {
      setMessages(res);
    },
  });

  const useGetAllMessages = useMutation(getSms, {
    onSuccess: async (res) => {
      setMessages(res);
    },
  });

  const [messages, setMessages] = useState<SmsMessage[] | undefined>();

  const { campaigns } = useCampaignContext();
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignRow | null>(
    null
  );

  const [messagesByTag, setMessagesByTag] = useState<MessageByTag[]>([]);

  const valueFormatter = (number: number) =>
    `${Intl.NumberFormat("us").format(number).toString()}`;

  function mapMessagesByTag(messages: SmsMessage[]): MessageByTag[] {
    const tags: MessageByTag[] = [];
    messages.forEach((message) => {
      if (message.tag) {
        message.tag.forEach((item) => {
          const tag = tags.find(
            (tag) => tag.name === snakeCaseToTitleCase(item)
          );
          if (tag) {
            tag.count += 1;
          } else {
            tags.push({
              name: snakeCaseToTitleCase(item),
              count: 1,
            });
          }
        });
      }
    });
    return tags;
  }

  useEffect(() => {
    if (messages) {
      setMessagesByTag(mapMessagesByTag(messages));
    }
  }, [messages]);

  useEffect(() => {
    if (campaigns) {
      useGetMessagesByCampaign.mutate(campaigns[0].id);
    }
  }, [campaigns]);

  useEffect(() => {
    if (selectedCampaign) {
      useGetMessagesByCampaign.mutate(selectedCampaign.id);
    } else {
      useGetAllMessages.mutate();
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (campaigns) {
      // @ts-ignore
      setCampaignsWithAll([{ id: "all", name: "All" }, ...campaigns]);
    }
  }, [campaigns]);

  return (
    <div>
      <div className="mb-4 max-w-sm space-y-6">
        {campaigns && (
          <Select
            value={selectedCampaign?.id ? selectedCampaign.id : "all"}
            defaultValue="all"
          >
            {campaignsWithAll ? (
              campaignsWithAll.map((campaign) => {
                return (
                  <SelectItem
                    key={campaign.id}
                    onClick={() => {
                      if (campaign.id === "all") {
                        useGetAllMessages.mutate();
                      } else {
                        setSelectedCampaign(campaign);
                      }
                    }}
                    value={campaign.id}
                  >
                    {campaign.name}
                  </SelectItem>
                );
              })
            ) : (
              <p>No Campaigns</p>
            )}
          </Select>
        )}
      </div>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <Card className="mx-auto max-w-xs">
          <Text>Total User Messages</Text>
          <Metric>{messages?.length}</Metric>
        </Card>
      </div>
      <Card className="max-w-lg">
        <Title>Message tags {`(messages can have multiple tags)`}</Title>
        {messagesByTag && (
          <DonutChart
            // @ts-ignore
            onClick={(e) => {
              const element = e.target as HTMLElement;
              const name = element.getAttribute("name");
              console.log(name);
            }}
            className="mt-6"
            data={messagesByTag}
            category="count"
            index="name"
            valueFormatter={valueFormatter}
            colors={["blue", "pink", "red", "rose", "cyan", "amber"]}
          />
        )}
      </Card>
    </div>
  );
}

export default MessageChart;
