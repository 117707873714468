import { useQuery } from "react-query";
import { getTestContexts } from "../../../api/testContext.api";

export const useGetTestContext = () => {
  const { isLoading, data, error } = useQuery({
    queryFn: () => getTestContexts(),
    queryKey: ["testContext"],

    initialData: [],
    enabled: true,
  });

  return {
    isLoading,
    data,
    error,
  };
};
