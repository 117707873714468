/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { CreateTestLeadMutation } from "../demo/demoContext.types";

import { useSearchParams } from "react-router-dom";
import { getCompanyConfigById } from "../../api/company.api";
import { CompanyConfigRow } from "../../api/company.type";
import {
  createTestLead,
  getLeadById,
  getTestLeadsByCampaign,
} from "../../api/lead.api";
import { LeadRow } from "../../api/lead.type";
import { ReimbursementRow } from "../../api/reimbursement.type";
import {
  createTestSms,
  getNewSmsMessagesByLeadForDemo,
  getSmsMessagesByLeadForDemo,
} from "../../api/sms.api";
import { SmsMessage } from "../../api/sms.type";
import { getSmsContentByCampaignId } from "../../api/smsContent.api";
import { SmsContentRow } from "../../api/smsContent.type";
import { useCampaignContext } from "../campaign/campaignContext";
import { useFileUploadContext } from "../forms/fileUploadContext";
import { ChatUserType } from "../../api/leadContext.types";

export const DemoContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  let [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId");

  const { setFile, setFilePath } = useFileUploadContext();

  const [lead, setLead] = useState<LeadRow>();

  const { activeCampaign, associatedCompany } = useCampaignContext();
  const [leadReimbursement] = useState<ReimbursementRow>();

  const [loading, setLoading] = useState(false);

  const [companyConfig, setCompanyConfig] = useState<CompanyConfigRow>();

  const [messages, setMessages] = useState<SmsMessage[]>([]);
  const [initialSmsContent, setInitialSmsContent] = useState<SmsMessage[]>([
    {
      role: ChatUserType.assistant,
      content: `Welcome to ${
        associatedCompany?.name
          ? associatedCompany?.name
          : "our awesome campaign!"
      }!`,
      media_url:
        "https://d3k81ch9hvuctc.cloudfront.net/company/U9JegE/images/3e4a59bc-8ece-414f-a215-0b277c1152ed.gif",
      created_at: new Date().toISOString(),
      generated_by: "openai",
    } as SmsMessage,
  ]);
  const [activeSmsContent, setActiveSmsContent] = useState<SmsContentRow>();

  const [testLeadsByCampaign, setTestLeadsByCampaign] = useState<LeadRow[]>([]);

  const { data: newMessages } = useQuery({
    queryKey: "newMessagesByLead",
    queryFn: () =>
      getNewSmsMessagesByLeadForDemo({
        leadId: lead?.id || "",
        messages: messages,
      }),
    enabled: loading,
    refetchInterval: 3000,
  });

  const useGetTestLeadsByCampaign = useMutation(getTestLeadsByCampaign, {
    onSuccess: async (res) => {
      setTestLeadsByCampaign(res);
    },
  });

  const useGetSmsContentByCampaignId = useMutation(getSmsContentByCampaignId, {
    onSuccess: async (res) => {
      setActiveSmsContent(res.sms_content);
    },
  });

  const useGetChatMutation = useMutation(getSmsMessagesByLeadForDemo, {
    onSuccess: async (res) => {
      setMessages(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useSendTestSms = useMutation(createTestSms, {
    onSuccess: async (res) => {},
    onError(error: Error) {
      console.log(error);
    },
  });

  const handleSendTestSms = (message: string, mediaUrl?: string) => {
    setLoading(true);
    setFile(null);
    setFilePath("");

    if (lead) {
      setMessages((prevState) => [
        ...prevState,
        {
          id: "",
          lead_id: lead?.id || "",
          created_at: new Date().toISOString(),
          media_url: mediaUrl || "",
          content: message,
          role: "user",
          twilio_account_sid: "",
          twilio_sid: "",
          tag: [],
          company_id: associatedCompany?.id || "",
          campaign_id: activeCampaign?.id || "",
          generated_by: "user",
        } as SmsMessage,
      ]);

      useSendTestSms.mutate({
        leadId: lead?.id || "",
        message,
        source: "plenti_demo",
        campaignId: activeCampaign?.id || "",
        companyId: associatedCompany?.id || "",
        ...(mediaUrl && { mediaUrl }),
      });
    }
  };

  const useGetCompanyConfig = useMutation(getCompanyConfigById, {
    onSuccess: async (res) => {
      setCompanyConfig(res);
    },
  });

  const useGetLeadById = useMutation(getLeadById, {
    onSuccess: async (res) => {
      setLead(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useCreateNewTestLead = useMutation(createTestLead, {
    onSuccess: async (res) => {
      setLead(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (activeSmsContent) {
      const initialSmsContent = [
        {
          role: ChatUserType.assistant,
          content: activeSmsContent?.content,
          media_url: activeSmsContent?.media_url || "",
          created_at: new Date().toISOString(),
        },
      ] as SmsMessage[];
      setInitialSmsContent(initialSmsContent);
    }
  }, [activeSmsContent]);

  useEffect(() => {
    if (!lead && associatedCompany && !leadId && activeCampaign) {
      useCreateNewTestLead.mutate({
        linked_number: "",
        company_id: associatedCompany.id,
        campaign_id: activeCampaign.id,
      });
    }
  }, [associatedCompany, activeCampaign]);

  useEffect(() => {
    if (leadId && !lead) {
      useGetLeadById.mutate(leadId);
    }
  }, [leadId]);

  useEffect(() => {
    if (associatedCompany) {
      useGetCompanyConfig.mutate(associatedCompany.id);
    }
  }, [associatedCompany]);

  useEffect(() => {
    if (activeCampaign && activeCampaign.id) {
      useGetTestLeadsByCampaign.mutate(activeCampaign.id);
      if (!activeSmsContent) {
        useGetSmsContentByCampaignId.mutate(activeCampaign.id);
      }
    }
  }, [activeCampaign]);

  useEffect(() => {
    if (lead) {
      useGetChatMutation.mutate({
        leadId: lead?.id || "",
      });
    }
  }, [lead]);

  // function handleMessageReady(args: { leadId: string }, leadId?: string) {
  //   if (args.leadId && args.leadId === leadId) {
  //     useGetChatMutation.mutate({
  //       leadId: lead?.id || "",
  //     });
  //     setLoading(false);
  //   }
  // }

  // useEffect(() => {
  //   socket.connect();
  //   socket.on("messageReady", (args) => {
  //     handleMessageReady(args, lead?.id);
  //   });
  //   socket.on("connect", () => {
  //     console.log("connected");
  //   });

  //   return () => {
  //     socket.off("connect");
  //   };
  // }, [lead]);

  useEffect(() => {
    if (newMessages) {
      setMessages(newMessages);
      setLoading(false);
    }
  }, [newMessages]);

  const value = {
    messages,
    handleSendTestSms,
    loading,
    companyConfig,
    useCreateNewTestLead,
    initialSmsContent,
    lead,
    leadReimbursement,
    testLeadsByCampaign,
  };

  return <DemoContext.Provider value={value}>{children}</DemoContext.Provider>;
};

export const DemoContext = createContext({
  messages: [] as SmsMessage[],
  handleSendTestSms: (message: string, mediaUrl?: string) => {},
  loading: false,
  companyConfig: {} as CompanyConfigRow | undefined,
  useCreateNewTestLead: {} as CreateTestLeadMutation,
  initialSmsContent: [] as SmsMessage[],
  lead: {} as LeadRow | undefined,
  leadReimbursement: {} as ReimbursementRow | undefined,
  testLeadsByCampaign: [] as LeadRow[] | undefined,
});

export const useDemoContext = () => useContext(DemoContext);
