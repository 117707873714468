import { Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

function SignupFooterLink() {
  const navigate = useNavigate();

  return (
    <Typography variant="small" color="gray">
      Don't have an account?{" "}
      <span
        style={{
          color: "#3B82F6",
          cursor: "pointer",
        }}
        onClick={() => navigate("/register")}
      >
        Sign up
      </span>
    </Typography>
  );
}

export default SignupFooterLink;
