import { MessageContextWrapper } from "../components/lead/messageContext";
import { ReimbursementContextWrapper } from "../components/reimbursement/reimbursementContext";
import AdminViewReimbursement from "../components/reimbursement/viewReimbursementAdmin";
import { useUserContext } from "../components/user/userContext";
import ViewReimbursementContainer from "../components/reimbursement/viewReimbursement";
import { useParams } from "react-router-dom";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewReimbursement = () => {
  mixpanelTrackEvent("View reimbursement page");

  const params = useParams();
  const { user } = useUserContext();

  return (
    <MessageContextWrapper>
      <ReimbursementContextWrapper>
        {user && user?.type === "admin" && (
          <AdminViewReimbursement id={params.id} />
        )}
        {user && user?.type === "standard" && (
          <ViewReimbursementContainer id={params.id} />
        )}
      </ReimbursementContextWrapper>
    </MessageContextWrapper>
  );
};

export default ViewReimbursement;
