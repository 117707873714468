import { FilterValue, TableHeadItem } from "./tableWithSearch";

function getNestedValue(obj: any, path: string) {
  if (!obj) {
    return null;
  }
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

export function getUniqueValuesForMultipleColumns(
  columns: TableHeadItem[],
  responses: any[] | undefined
) {
  if (!columns || columns.length === 0) {
    return {};
  }
  const uniqueValuesMap = {} as { [key: string]: string[] };

  if (!responses || responses.length === 0) {
    return uniqueValuesMap;
  }

  responses.forEach((response) => {
    if (!response) {
      return;
    }

    columns.forEach(({ label, columnName }) => {
      if (!columnName || !label) {
        return;
      }
      const value = getNestedValue(response, columnName);

      const labelValues = uniqueValuesMap[label];

      if (!labelValues) {
        uniqueValuesMap[label] = ["All"] as string[];
        return;
      }
      const foundLabel = labelValues.find((item: string) => item === value);
      if (value && !foundLabel) {
        (uniqueValuesMap[label] as string[]).push(value);
      }
    });
  });

  return uniqueValuesMap as { [key: string]: string[] };
}

export function handleFilterValue(
  tableHead: TableHeadItem[],
  value: FilterValue,
  responses: any[] | undefined,
  setFilteredResponse: any,
  setResponseFilterFunction: any,
  baseFilter: any
) {
  if (value.value && responses) {
    if (value.value === "All") {
      setResponseFilterFunction(baseFilter);
      setFilteredResponse(responses);
    } else {
      const column = tableHead.find((item) => item.label === value.column);
      if (!column || !column.columnName) {
        return;
      }

      setResponseFilterFunction(
        () => (response: any, index: number) =>
          getNestedValue(response, column?.columnName as string) === value.value
      );
      setFilteredResponse(
        responses.filter(
          (item) =>
            getNestedValue(item, column?.columnName as string) === value.value
        )
      );
    }
  }
}

export function setHeadFilters(
  responses: any[] | undefined,
  filteredResponse: any[] | undefined,
  setFilteredResponse: any,
  setTableHead: any,
  TABLE_HEAD: TableHeadItem[]
) {
  if (responses && responses.length > 0) {
    const uniqueValuesMap = getUniqueValuesForMultipleColumns(
      TABLE_HEAD,
      responses
    );

    const newTableHead = TABLE_HEAD.map((item) => {
      if (item.columnName && uniqueValuesMap[item.label]) {
        return {
          ...item,
          filterValues: uniqueValuesMap[item.label],
        };
      }
      return item;
    });

    setTableHead(newTableHead);
  }

  if (responses && (!filteredResponse || filteredResponse.length === 0)) {
    setFilteredResponse(responses);
  }
}
