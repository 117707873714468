import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  EmailDnsRow,
  EmailDnsRowCreate,
  SendGridValidationResponse,
} from "./emailDns.type";

export function getEmailDns(): Promise<EmailDnsRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.email + "/dns";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<EmailDnsRow[]>(url, request);
}

export function createEmailDns(data: EmailDnsRowCreate): Promise<EmailDnsRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.email + "/dns";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(data),
  });

  return fetchData<EmailDnsRow>(url, request);
}

export function validateEmailDns(
  sgId: string
): Promise<SendGridValidationResponse> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.email +
    "/dns/validate/" +
    sgId;

  const request = setHeaderOptions({
    method: "PUT",
  });

  return fetchData<SendGridValidationResponse>(url, request);
}

export function deleteEmailDns(sgId: string): Promise<EmailDnsRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.email + "/dns/" + sgId;

  const request = setHeaderOptions({
    method: "DELETE",
  });

  return fetchData<EmailDnsRow>(url, request);
}
