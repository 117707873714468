/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  getRecurringPatternByCampaign,
  updateRecurringPatternByCampaign,
} from "../../../api/recurringPattern.api";
import { RecurringPatternRow } from "../../../api/recurringPattern.type";
import { useAlertContext } from "../../alert/alertContext";
import { useCampaignContentContext } from "../../campaignSmsContent/campaignSmsContentContext";
import { useCampaignContext } from "../campaignContext";

export const FollowUpContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const { activeCampaign } = useCampaignContext();
  const { campaignSmsContent, setGenerating, handleCallAiGenerateContent } =
    useCampaignContentContext();
  const [startingDay, setStartingDay] = useState<string>("");
  const [selectedMax, setSelectedMax] = useState<string>("");
  const [selectedIncreasing, setSelectedIncreasing] = useState<string>("2");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [recurringPattern, setRecurringPattern] =
    useState<RecurringPatternRow>();

  const useGetRecurringPattern = useMutation(getRecurringPatternByCampaign, {
    onSuccess: async (res) => {
      setRecurringPattern(res);
    },
  });

  const useUpdateRecurringPattern = useMutation(
    updateRecurringPatternByCampaign,
    {
      onSuccess: async (res) => {
        setRecurringPattern(res);
        setAlertProps({
          message: "Follow-up frequency updated",
          color: "green",
        });
      },
      onError: async (err: any) => {
        setAlertProps({
          message: "Error updating follow-up frequency" + err.message,
          color: "red",
        });
      },
    }
  );

  function handleUpdateRecurringPattern() {
    useUpdateRecurringPattern.mutate({
      campaignId: activeCampaign?.id as string,
      values: {
        id: recurringPattern?.id as string,
        separator: Number(startingDay),
        max: selectedMax === "Unlimited" ? 99 : Number(selectedMax),
        increasing_by: Number(selectedIncreasing),
      },
    });
  }

  useEffect(() => {
    if (activeCampaign && activeCampaign.id) {
      useGetRecurringPattern.mutate(activeCampaign.id);
      setIsActive(activeCampaign.follow_up_disabled ? false : true);
    }
  }, [activeCampaign, activeCampaign?.follow_up_disabled]);

  useEffect(() => {
    if (recurringPattern) {
      setStartingDay(String(recurringPattern.separator));
      setSelectedMax(
        recurringPattern.max === 99 ? "Unlimited" : String(recurringPattern.max)
      );
      setSelectedIncreasing(String(recurringPattern.increasing_by));
    }
  }, [recurringPattern]);

  useEffect(() => {
    if (recurringPattern) {
      const { separator, max, increasing_by } = recurringPattern;

      if (
        Number(separator) !== Number(startingDay) ||
        Number(max) !== Number(selectedMax) ||
        Number(increasing_by) !== Number(selectedIncreasing)
      ) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [startingDay, selectedMax, selectedIncreasing, recurringPattern]);

  const campaignSpecificContent = campaignSmsContent?.filter((c) => {
    const isDefault = c?.is_default;
    const matchedCompany = c.company_id === activeCampaign?.company_id;
    const matchedCampaign = c.campaign_id === activeCampaign?.id;
    return matchedCompany && !isDefault && matchedCampaign;
  });

  function handleAiGenerateContentSubmit() {
    setGenerating(true);
    handleUpdateRecurringPattern();
    handleCallAiGenerateContent(
      activeCampaign?.id as string,
      Number(selectedMax) -
        (campaignSpecificContent ? campaignSpecificContent?.length : 0) || 0
    );
  }

  const value = {
    isEdit,
    setIsEdit,
    startingDay,
    setStartingDay,
    selectedMax,
    setSelectedMax,
    selectedIncreasing,
    setSelectedIncreasing,
    isActive,
    setIsActive,
    handleUpdateRecurringPattern,
    handleAiGenerateContentSubmit,
  };

  return (
    <FollowUpContext.Provider value={value}>
      {props.children}
    </FollowUpContext.Provider>
  );
};

export const FollowUpContext = createContext({
  isEdit: false as boolean,
  isActive: true as boolean,
  setIsEdit: {} as React.Dispatch<React.SetStateAction<boolean>>,
  startingDay: "2" as string,
  setStartingDay: {} as React.Dispatch<React.SetStateAction<string>>,
  selectedMax: "7" as string,
  setSelectedMax: {} as React.Dispatch<React.SetStateAction<string>>,
  selectedIncreasing: "2" as string,
  setSelectedIncreasing: {} as React.Dispatch<React.SetStateAction<string>>,
  setIsActive: {} as React.Dispatch<React.SetStateAction<boolean>>,
  handleUpdateRecurringPattern: {} as () => void,
  handleAiGenerateContentSubmit: {} as () => void,
});

export const useFollowUpContext = () => useContext(FollowUpContext);
