function CheezyPoofs() {
  return (
    <div
      className="relative h-[700px] w-[350px] bg-cover bg-center bg-no-repeat px-6 py-2"
      style={{ backgroundImage: "url(/iphone.jpg)" }}
    >
      <div
        className="absolute top-[3%] left-[6%] h-[94%] w-[87%] overflow-auto bg-white shadow-2xl"
        style={{ borderRadius: "40px" }}
      >
        <video width="304" height="697" controls autoPlay muted>
          <source src="/cheezypoof.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default CheezyPoofs;
