import { useQuery } from "react-query";
import { getPayments } from "../../../api/payment.api";

export const useLoadPayments = () => {
  const { data: payments, isLoading } = useQuery({
    queryKey: "payments",
    queryFn: () => getPayments(),
    refetchInterval: 3000,
  });

  return { payments, isLoading };
};
