import CheezyPoofs from "../cheezyPoofs/cheezyPoofs";
import LoginLayout from "../layout/LoginLayout";
import SendResetPasswordEmail from "./SendResetPasswordEmail";

const SendPasswordResetPage = () => {
  return (
    <LoginLayout>
      <SendResetPasswordEmail />
      <div className="hidden md:block">
        <CheezyPoofs />
      </div>
    </LoginLayout>
  );
};

export default SendPasswordResetPage;
