/* eslint-disable react-hooks/exhaustive-deps */
import { Chip } from "@material-tailwind/react";
import { ExtractionSchemaRow } from "../../api/extractionSchema.type";
import CampaignSchemaAddNewHeader from "./CampaignSchemaAddNewHeader";
import ExtractionSchemaList from "./ExtractionSchemaList";
import { DeleteIcon } from "../icons/icons";

function ExtractionSchemaCampaignList({
  setSelectedSchema,
  schema,
}: {
  schema: ExtractionSchemaRow[] | undefined;
  setSelectedSchema?: (schema: ExtractionSchemaRow) => void;
}) {
  return (
    <div className="max-w-2xl">
      <CampaignSchemaAddNewHeader />
      <ExtractionSchemaList
        schema={schema}
        setSelectedSchema={setSelectedSchema}
      >
        <Chip
          color="red"
          value="Remove"
          className="text-center"
          icon={<DeleteIcon className="h-4 w-4" />}
        />
      </ExtractionSchemaList>
    </div>
  );
}

export default ExtractionSchemaCampaignList;
