import { Button, Typography } from "@material-tailwind/react";
import { DatePicker } from "@tremor/react";
import { Fragment, useState } from "react";

import { LinkToId } from "../table/linkToId";

import { ReceiptOcrRow } from "../../api/receiptOcr.type";
import { SmsMessage } from "../../api/sms.type";
import {
  convertDateToRawDate,
  formatTimeStampToHumanReadableShortDateTime,
} from "../../utils/parseTimeStamp";

export type OcrTableListProps = {
  ocrs: ReceiptOcrRow[] | undefined;
  handleContentClick?: (id: string) => void;
  filterFunction?: (ocr: ReceiptOcrRow, index: number) => boolean;
  sortFunction?: (a: ReceiptOcrRow, b: ReceiptOcrRow) => number;
  handleUpdateOcr?: (values: Partial<ReceiptOcrRow>) => void;
};

function OcrTableList({
  ocrs,
  filterFunction,
  sortFunction,
  handleUpdateOcr,
}: OcrTableListProps) {
  const [ocrDate, setOcrDate] = useState<Date | null>(null);
  function handleDateSubmit(id: string, ocrDate: Date | null) {
    if (ocrDate) {
      handleUpdateOcr &&
        handleUpdateOcr({
          id,
          raw_date: convertDateToRawDate(ocrDate),
        });
    }
  }

  function mapOcrToRow(ocr: ReceiptOcrRow): {
    id: string;
    createdAt: string;
    rawData: string;
    date: string | null;
    time: string;
    total: string;
    lineItems: any;
    smsMessage: SmsMessage | string;
  } {
    const values = {
      id: ocr.id,
      createdAt: ocr.created_at
        ? formatTimeStampToHumanReadableShortDateTime(ocr.created_at)
        : "N/A",
      rawData: ocr.raw_data ? ocr.raw_data : "N/A",
      date: ocr.raw_date ? ocr.raw_date : null,
      time: ocr.raw_time ? ocr.raw_time : "N/A",
      total: ocr.raw_total_amount ? ocr.raw_total_amount : "N/A",
      lineItems: ocr.line_items ? ocr.line_items.length : "N/A",
      smsMessage: ocr.sms_message ? ocr.sms_message : "N/A",
    };

    return values;
  }

  return (
    <Fragment>
      {ocrs &&
        ocrs
          .sort((a, b) => (sortFunction ? sortFunction(a, b) : 0))
          .filter((ocr, index) =>
            filterFunction ? filterFunction(ocr, index) : true
          )
          .map((item) => mapOcrToRow(item))
          .map(
            (
              { id, createdAt, lineItems, date, time, total, smsMessage },
              index
            ) => {
              const isLast = index === ocrs.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {total}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {createdAt}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      {date ? (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {date}
                        </Typography>
                      ) : (
                        <div>
                          <DatePicker
                            className="mb-2 max-w-sm overflow-visible"
                            onValueChange={(value) =>
                              setOcrDate(value ? value : null)
                            }
                          />
                          <Button
                            size="sm"
                            color="green"
                            fullWidth={false}
                            onClick={() => handleDateSubmit(id, ocrDate)}
                          >
                            Submit
                          </Button>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {lineItems}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <LinkToId type="Ocr" id={id} baseUrl="/ocr/" />
                  </td>
                  <td className={classes}>
                    {typeof smsMessage === "string" ? (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {smsMessage}
                      </Typography>
                    ) : (
                      <LinkToId
                        type="Chat"
                        id={smsMessage?.lead_id}
                        baseUrl="/lead/"
                      />
                    )}
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default OcrTableList;
