import { useParams } from "react-router-dom";
import AddApiCallAgent from "./ApiCallAgent";
import DataAnalysisAgent from "./DataAnalysisAgent";
import FileUploadAgent from "./FileUploadAgent";

function AddAgentTypeContainer() {
  const params = useParams();

  const { type } = params;

  switch (type) {
    case "api-call":
      return <AddApiCallAgent />;
    case "free-text":
      return <div>Free text agent</div>;
    case "file-search":
      return <FileUploadAgent />;
    case "data-analysis":
      return <DataAnalysisAgent />;
    default:
      return <div>No agent by that type</div>;
  }
}

export default AddAgentTypeContainer;
