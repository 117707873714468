import { useParams } from "react-router-dom";
import ViewImageContainer from "../components/images/ViewImageContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";
import { ImageMetadataContextWrapper } from "../components/feedback/hooks/ImageMetadataContext";

const ViewImage = () => {
  mixpanelTrackEvent("View image page");

  const params = useParams();
  return (
    <ImageMetadataContextWrapper>
      <ViewImageContainer id={params.id} />
    </ImageMetadataContextWrapper>
  );
};

export default ViewImage;
