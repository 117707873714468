/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@tremor/react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import LineItemsList from "../ocr/viewOcrLineItems";
import TableWithSearch from "../table/tableWithSearch";

import { ReceiptOcrRow } from "@/api/receiptOcr.type";
import ViewOcrDetails from "./ViewOcrDetails";
import { LineItemContextWrapper } from "./LineItemContext";

const TABLE_HEAD = [
  { label: "Confidence" },
  { label: "Description" },
  { label: "Total" },
  { label: "Product" },
  { label: "Confirmed" },
];

function ViewOcrContainer({
  activeOcr,
  useRunPurchaseCheck,
  purchaseCheck,
}: {
  activeOcr: ReceiptOcrRow | undefined;
  useRunPurchaseCheck?: any;
  purchaseCheck?: any;
}) {
  const breadCrumbsConfig = [
    {
      name: "Ocr list",
      href: "/ocr",
      active: false,
    },
    {
      name: "View",
      href: `/ocr/${activeOcr?.id}`,
      active: true,
    },
  ];

  console.log("activeOcr", activeOcr);

  return (
    <LineItemContextWrapper>
      <div className="p-5">
        {activeOcr && (
          <div>
            <BreadcrumbsFromConfig config={breadCrumbsConfig} />
            <div className="flex gap-2">
              <div className="mt-4 flex w-9/12 flex-col gap-4 rounded-lg  p-5">
                {useRunPurchaseCheck && purchaseCheck && (
                  <div>
                    <Button
                      onClick={() =>
                        useRunPurchaseCheck.mutate({
                          ocrId: activeOcr?.id || "",
                          campaignId: activeOcr?.sms_message?.campaign_id || "",
                        })
                      }
                      size="md"
                      disabled={purchaseCheck ? true : false}
                    >
                      Run AI Purchase Check
                    </Button>
                    {purchaseCheck && <p>{purchaseCheck}</p>}
                  </div>
                )}
                <ViewOcrDetails />

                <div>
                  {activeOcr &&
                    activeOcr.line_items &&
                    activeOcr.line_items.length > 0 && (
                      <TableWithSearch
                        tableHead={TABLE_HEAD}
                        rowCount={
                          activeOcr?.line_item ? activeOcr?.line_item.length : 0
                        }
                        rowsPerPage={10}
                      >
                        <LineItemsList lineItems={activeOcr.line_item} />
                      </TableWithSearch>
                    )}
                </div>
              </div>
              <div>
                {activeOcr && activeOcr.sms_message && (
                  <img
                    src={
                      activeOcr?.sms_message?.media_url
                        ? activeOcr.sms_message.media_url
                        : ""
                    }
                    className="max-w-md"
                    alt="receipt "
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </LineItemContextWrapper>
  );
}

export default ViewOcrContainer;
