import { createContext, useContext } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import {
  createEmailDns,
  deleteEmailDns,
  validateEmailDns,
} from "../../../api/emailDns.api";
import { useAlertContext } from "../../alert/alertContext";
import {
  EmailDnsRow,
  EmailDnsRowCreate,
  SendGridValidationResponse,
} from "../../../api/emailDns.type";
import { useLoadEmailDns } from "./useLoadEmailDns";

export const EmailDnsContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const queryClient = useQueryClient();

  const { refetch } = useLoadEmailDns();

  const { mutate: addEmailDns, isLoading } = useMutation(createEmailDns, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("emailDns");
      refetch();
      setAlertProps({
        message: "Email DNS added",
        color: "green",
      });
    },
    onError: async (err: any) => {
      console.log(err);
      setAlertProps({
        message: `Adding email DNS failed: ${err.message}`,
        color: "red",
      });
    },
  });

  const {
    mutate: validateDns,
    isLoading: validateLoading,
    data: validationResponse,
  } = useMutation(validateEmailDns, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("emailDns");

      if (res.valid) {
        setAlertProps({
          message: "Email DNS validated",
          color: "green",
        });
      } else {
        setAlertProps({
          message: `Email DNS validation failed: ${res.validation_results?.mail_cname?.reason}`,
          color: "red",
        });
      }
    },
    onError: async (err: any) => {
      console.log(err);
      setAlertProps({
        message: `Validating email DNS failed: ${err.message}`,
        color: "red",
      });
    },
  });

  const { mutate: deleteDns, isLoading: deleteLoading } = useMutation(
    deleteEmailDns,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("emailDns");
        setAlertProps({
          message: "Email DNS deleted",
          color: "green",
        });
      },
      onError: async (err: any) => {
        console.log(err);
        setAlertProps({
          message: `Deleting email DNS failed: ${err.message}`,
          color: "red",
        });
      },
    }
  );

  const value = {
    addEmailDns,
    loading: isLoading || validateLoading || deleteLoading,
    validateDns,
    validationResponse,
    deleteDns,
  };

  return (
    <EmailDnsContext.Provider value={value}>
      {props.children}
    </EmailDnsContext.Provider>
  );
};

export const EmailDnsContext = createContext({
  addEmailDns: {} as UseMutateFunction<
    EmailDnsRow,
    any,
    EmailDnsRowCreate,
    unknown
  >,
  validateDns: {} as UseMutateFunction<
    SendGridValidationResponse,
    any,
    string,
    unknown
  >,
  deleteDns: {} as UseMutateFunction<EmailDnsRow, any, string, unknown>,
  loading: false,
  validationResponse: {} as SendGridValidationResponse | undefined,
});

export const useEmailDnsContext = () => useContext(EmailDnsContext);
