import { Fragment } from "react";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useAdminGptContext } from "./adminGptContext";

function ChatTestingResponse() {
  const { chatResponse, loading } = useAdminGptContext();

  return (
    <div className="rounded-lg bg-gray-100 col-span-2 p-3 ">
      {chatResponse ? (
        <pre className="w-full whitespace-pre-wrap">
          {JSON.stringify(chatResponse, null, 2)}
        </pre>
      ) : (
        <Fragment>
          {loading && <PlentiLogoLoading className="h-12 w-12 mx-auto" />}
        </Fragment>
      )}
    </div>
  );
}

export default ChatTestingResponse;
