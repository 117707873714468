/* eslint-disable react-hooks/exhaustive-deps */
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useAlertContext } from "../alert/alertContext";

import {
  createSystemPrompt,
  deletePromptById,
  getPromptById,
  getPrompts,
  runAiUpdateSystemPrompt,
  updateSystemPrompt,
} from "../../api/prompt.api";
import { PromptRow } from "../../api/prompt.type";
import {
  CreatePromptMutation,
  DeletePromptMutation,
  GetPromptByIdMutation,
  RunAiUpdatePrompt,
  UpdatePromptMutation,
} from "./promptContext.types";

export const PromptContextWrapper = ({
  id,
  children,
}: {
  id?: string;
  children: React.ReactNode;
}) => {
  const [activePrompt, setActivePrompt] = useState<PromptRow>();
  const [promptToDelete, setPromptToDelete] =
    useState<Partial<PromptRow> | null>(null);

  const session = useSession();
  const { setAlertProps } = useAlertContext();

  const queryClient = useQueryClient();
  const { data: prompts } = useQuery({
    queryKey: "prompt",
    queryFn: () => getPrompts(),
    enabled: !!session,
  });

  const { mutate: createPrompt, isLoading: isCreateLoading } = useMutation(
    createSystemPrompt,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("prompt");
        setAlertProps({
          message: "Prompt created successfully",
          color: "green",
        });
      },
      onError(error: Error) {
        console.log(error);
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  const { mutate: updatePrompt, isLoading: isUpdateLoading } = useMutation(
    updateSystemPrompt,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("prompt");
        setActivePrompt(res);
        setAlertProps({
          message: "Prompt updated successfully",
          color: "green",
        });
      },
      onError(error: Error) {
        console.log(error);
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  const { mutate: runAiUpdate, isLoading: isAiLoading } = useMutation(
    runAiUpdateSystemPrompt,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("prompt");
        useGetPromptById.mutate({
          id: res.prompt.id,
        });
        setAlertProps({
          message: "Prompt updated successfully",
          color: "green",
        });
      },
      onError(error: Error) {
        console.log(error);
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  const useGetPromptById = useMutation(getPromptById, {
    onSuccess: async (res) => {
      setActivePrompt(res);
    },
  });

  const { mutate: deletePrompt, isLoading: isDeleteLoading } = useMutation(
    deletePromptById,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("prompt");

        setAlertProps({
          message: "Prompt deleted successfully",
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
      onSettled() {
        setPromptToDelete(null);
      },
    }
  );

  useEffect(() => {
    if (id) {
      useGetPromptById.mutate({
        id,
      });
    }
  }, [id]);

  const value = {
    prompts,
    createPrompt,
    updatePrompt,
    useGetPromptById,
    activePrompt,
    promptToDelete,
    setPromptToDelete,
    deletePrompt,
    runAiUpdate,
    isLoading:
      isCreateLoading || isUpdateLoading || isDeleteLoading || isAiLoading,
  };

  return (
    <PromptContext.Provider value={value}>{children}</PromptContext.Provider>
  );
};

export const PromptContext = createContext({
  prompts: [] as PromptRow[] | undefined,
  createPrompt: {} as CreatePromptMutation,
  updatePrompt: {} as UpdatePromptMutation,
  useGetPromptById: {} as GetPromptByIdMutation,
  activePrompt: {} as PromptRow | undefined,
  promptToDelete: {} as Partial<PromptRow> | null,
  setPromptToDelete: {} as React.Dispatch<
    React.SetStateAction<Partial<PromptRow> | null>
  >,
  deletePrompt: {} as DeletePromptMutation,
  runAiUpdate: {} as RunAiUpdatePrompt,
  isLoading: false,
});

export const usePromptContext = () => useContext(PromptContext);
