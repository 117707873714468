/* eslint-disable react-hooks/exhaustive-deps */
import {
  Bold,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  Title,
} from "@tremor/react";
import { UsageRow } from "../../api/usage.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";

function UsageOverview({
  selectedUsage,
}: {
  selectedUsage: UsageRow | undefined;
}) {
  function getTotal() {
    if (selectedUsage) {
      return parseCentsToDollars(
        selectedUsage.sms_balance + selectedUsage.reimbursement_balance
      );
    } else {
      return "N/A";
    }
  }

  return (
    <Card>
      <div className="flex flex-row justify-between">
        <Title>Usage Overview</Title>
      </div>
      <Table className="mt-5">
        <TableBody>
          <TableRow color="black">
            <TableCell>
              <Bold>Total</Bold>
            </TableCell>
            <TableCell className="text-right">
              <Text>
                <Bold>{getTotal()}</Bold>
              </Text>
            </TableCell>
          </TableRow>

          <TableRow color="black">
            <TableCell>Sales Tax</TableCell>
            <TableCell className="text-right">
              <Text>TBD</Text>
            </TableCell>
          </TableRow>
          <TableRow color="black">
            <TableCell>Messaging</TableCell>
            <TableCell className="text-right">
              <Text>
                {selectedUsage?.sms_balance
                  ? parseCentsToDollars(selectedUsage?.sms_balance)
                  : "$0"}
              </Text>
            </TableCell>
          </TableRow>
          <TableRow color="black">
            <TableCell>Reimbursements</TableCell>
            <TableCell className="text-right">
              <Text>
                {selectedUsage?.reimbursement_balance
                  ? parseCentsToDollars(selectedUsage?.reimbursement_balance)
                  : "$0"}
              </Text>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

export default UsageOverview;
