/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from "@material-tailwind/react";
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { EditIcon } from "../icons/icons";
import { useUserContext } from "../user/userContext";
import { useForm } from "react-hook-form";
import { PlentiInput } from "../forms/plentiInput";
import { useNavigate } from "react-router-dom";

export function getErrorFromHash(hash: string): Record<string, string> {
  const params = new URLSearchParams(hash.slice(1)); // remove the initial '#'
  const error: Record<string, string> = {};

  error["error"] = params.get("error") || "";
  error["error_code"] = params.get("error_code") || "";
  error["error_description"] = decodeURIComponent(
    params.get("error_description") || ""
  );

  return error;
}

const AccountContainer = () => {
  const session = useSession();
  const navigate = useNavigate();
  const { user, updateUser, setIsEdit, isEdit } = useUserContext();

  const [firstName, setFirstName] = useState(user?.first_name);
  const [lastName, setLastName] = useState(user?.last_name);

  const supabase = useSupabaseClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      password: "",
    },
  });

  useEffect(() => {
    const error = getErrorFromHash(window.location.hash);
    if (error?.error_code) {
      console.log(error);
      navigate(`/${window.location.hash}`);
    }
  }, []);

  async function onSubmit() {
    const { data, error } = await supabase.auth.updateUser({
      password: getValues("password"),
    });

    if (data) alert("Password updated successfully!");
    if (error) alert("There was an error updating your password.");
    reset();
  }

  return (
    <div className="p-5">
      <div className="border-slate-200  flex justify-between border-b-2">
        <Typography variant="h5" className="capitalize text-slate-500">
          Account Details
        </Typography>
        <button onClick={() => setIsEdit(!isEdit)} className="text-primary-600">
          <EditIcon className="h-4 w-4" />
        </button>
      </div>
      <div className="mt-4 mb-4 flex flex-col gap-2">
        <p className="text-slate-500 text-xs">Email</p>
        <p className="text-slate-700">{session?.user?.email}</p>
        <p className="text-slate-500 text-xs">Company</p>
        <p className="text-slate-700">
          {user?.users_companies[0]?.company_id?.name || "Not listed"}
        </p>
        <p className="text-slate-500 text-xs">First name</p>
        {isEdit ? (
          <input
            type="text"
            name="firstName"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={firstName}
            className="text-md leading-5.6 ease-soft block w-full max-w-md rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-primary-500 focus:outline-none"
          />
        ) : (
          <p className="text-slate-700">{user?.first_name || "Not listed"}</p>
        )}
        <p className="text-slate-500 text-xs">Last name</p>
        {isEdit ? (
          <input
            type="text"
            name="lastName"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={lastName}
            className=" text-md leading-5.6 ease-soft block w-full max-w-md rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-primary-500 focus:outline-none"
          />
        ) : (
          <p className="text-slate-700">{user?.last_name || "Not listed"}</p>
        )}
        {isEdit && (
          <ButtonSecondary
            onClick={() => {
              if (!firstName || !lastName) return;
              updateUser({
                id: user?.id,
                first_name: firstName,
                last_name: lastName,
              });
            }}
            size="lg"
          >
            Submit
          </ButtonSecondary>
        )}
      </div>

      <Typography variant="h5" className="capitalize text-slate-500">
        Update Password
      </Typography>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 w-72">
          <PlentiInput
            register={register}
            errors={errors}
            label="Password"
            type="password"
            name="password"
          />
          <Button color="blue-gray" type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccountContainer;
