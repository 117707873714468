/* eslint-disable react-hooks/exhaustive-deps */
import { BarChart, Card, Subtitle, Title } from "@tremor/react";
import { FC, Fragment, useEffect, useState } from "react";

import { DailyUsageRow } from "../../../../api/dailyUsage.type";
import { calcPercentage } from "../../../../utils/calculateAverage";
import { centsToUsDollars } from "../../../../utils/currencyManipulation";
import { numericMonthYearToString } from "../../../../utils/parseTimeStamp";
import PercentCard from "../../../analyticsComponents/PercentCard";
import { MetricCard } from "../../../analyticsComponents/metricCard";
import DailyUsageCustomTooltip, {
  TooltipDailyCount,
  TooltipDailyCurrency,
} from "../../../billing/dailyUsageToolTip";
import DownloadButton from "../../../downloadCsv/DownloadButton";
import SelectMonth, { Months } from "../../../forms/selectMonth";
import { DataReimbursements } from "../types/data.types";
import { mapReimbursementDailyToCsv } from "../utils/mapReimbursementDailyToCsv";

interface Props {
  totals?: DataReimbursements;
  dailyUsage?: Partial<DailyUsageRow>[];
  leadCount?: number;
}

const BaseReimbursementChart: FC<Props> = ({
  totals,
  dailyUsage,
  leadCount,
}) => {
  const percentPaid = totals
    ? Math.round((totals?.paid / totals?.total) * 100)
    : 0;
  const [months, setMonths] = useState<Months[]>();
  const [selectedMonth, setSelectedMonth] = useState<Months>();
  const [download, setDownload] = useState<boolean>(false);

  const percentReimbursed =
    totals && leadCount && calcPercentage(totals?.total, leadCount);

  function mapDailyRTotalToChartData(usage: Partial<DailyUsageRow>): {
    Total: number;
    day: number;
  } {
    if (!usage.reimbursement_balance) {
      return {
        Total: 0,
        day: usage.day || 0,
      };
    }
    return {
      Total: usage.reimbursement_balance,
      day: usage.day || 0,
    };
  }

  function mapDailyRCountToChartData(usage: Partial<DailyUsageRow>): {
    Count: number;
    day: number;
  } {
    if (!usage.reimbursement_count) {
      return {
        Count: 0,
        day: usage.day || 0,
      };
    }
    return {
      Count: usage.reimbursement_count,
      day: usage.day || 0,
    };
  }

  function filterByMonth(usage: Partial<DailyUsageRow>) {
    return usage.month === selectedMonth?.month;
  }

  useEffect(() => {
    const now = new Date();
    if (months && !selectedMonth) {
      const found = months.find(
        (u) => u.month === now.getMonth() + 1 && u.year === now.getFullYear()
      );
      if (found) {
        setSelectedMonth(found);
      }
    }
  }, [dailyUsage, months]);

  useEffect(() => {
    if (dailyUsage) {
      const months: Months[] = [];

      for (let i = 0; i < dailyUsage.length; i++) {
        const { month, year } = dailyUsage[i];
        if (!month || !year) continue;
        const found = months.find((m) => m.month === month && m.year === year);
        if (!found) {
          months.push({ month, year, id: `${month}-${year}` });
        }
      }

      setMonths(months);
    }
  }, [dailyUsage]);

  return (
    <div>
      <div className="grid gap-4 grid-cols-2 mb-4">
        <div className="grid grid-cols-2 gap-4">
          <MetricCard title="Created" value={totals?.total} />
          <MetricCard title="Paid" value={totals?.paid} />
          <div className="col-span-2">
            <MetricCard title="Paid Amount" value={totals?.paidAmount} />
          </div>
        </div>
        <PercentCard title="Paid %" value={percentPaid} />
      </div>
      <div className="grid gap-4 md:grid-cols-2 ">
        <div className="flex flex-col gap-4">
          <MetricCard title="Total Leads" value={leadCount || 0} />
          <MetricCard title="Total Reimbursements" value={totals?.total} />
        </div>
        <PercentCard title="Reimbursed %" value={percentReimbursed} />
      </div>
      {dailyUsage && months && (
        <div className="flex justify-between mt-4">
          <div className="max-w-xs ">
            <SelectMonth
              months={months}
              selectedMonth={selectedMonth}
              setSelectedItem={setSelectedMonth}
            />
          </div>
          <DownloadButton
            downloadData={mapReimbursementDailyToCsv(
              dailyUsage,
              selectedMonth?.month,
              selectedMonth?.year
            )}
            download={download}
            setDownload={setDownload}
          />
        </div>
      )}
      <Card className="mt-4">
        <Title>Daily reimbursements</Title>
        <Subtitle>
          {selectedMonth &&
            numericMonthYearToString(
              selectedMonth?.month,
              selectedMonth?.year
            )}{" "}
        </Subtitle>
        {dailyUsage && (
          <Fragment>
            <BarChart
              className="mt-6"
              data={dailyUsage
                .filter(filterByMonth)
                .map(mapDailyRTotalToChartData)}
              index="day"
              categories={["Total"]}
              valueFormatter={centsToUsDollars}
              colors={["purple"]}
              showLegend={true}
              customTooltip={({ payload, active }) => (
                <DailyUsageCustomTooltip payload={payload} active={active}>
                  <TooltipDailyCurrency selectedMonth={selectedMonth} />
                </DailyUsageCustomTooltip>
              )}
            />
            <BarChart
              className="mt-6"
              data={dailyUsage
                .filter(filterByMonth)
                .map(mapDailyRCountToChartData)}
              index="day"
              categories={["Count"]}
              colors={["teal"]}
              showLegend={true}
              customTooltip={({ payload, active }) => (
                <DailyUsageCustomTooltip payload={payload} active={active}>
                  <TooltipDailyCount selectedMonth={selectedMonth} />
                </DailyUsageCustomTooltip>
              )}
            />
          </Fragment>
        )}
      </Card>
    </div>
  );
};

export default BaseReimbursementChart;
