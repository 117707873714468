export function snakeCaseToTitleCase(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split("_");
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return titleCaseWords.join(" ");
}

export function camelCaseToTitleCase(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split(/(?=[A-Z])/);
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return titleCaseWords.join(" ");
}

export function camelCaseToHyphens(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split(/(?=[A-Z])/);
  const hyphenWords = words.map((word) => word.toLowerCase());
  return hyphenWords.join("-");
}

export function titleCaseToCamelCase(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split(" ");
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return camelCaseWords.join("");
}

export function toLowerCaseAndReplaceSpacesWithHyphens(input: string): string {
  if (!input) {
    return "";
  }
  // Convert all characters to lowercase
  const lowerCaseString = input.toLowerCase();

  // Replace all spaces with hyphens
  const refactoredString = lowerCaseString.replace(/ /g, "-");

  return refactoredString;
}

export const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export function getFileNameFromPath(path: string): string {
  // Handle both Unix-like and Windows paths by splitting on both / and \
  const segments = path.split(/[/\\]/);
  return segments.pop() || ""; // Return the last segment or an empty string if not found
}

export function extractPlaceholders(url: string): string[] {
  const regex = /{{([^}]+)}}/g;
  let match;
  const placeholders: string[] = [];

  while ((match = regex.exec(url)) !== null) {
    placeholders.push(match[1]);
  }

  return placeholders;
}
