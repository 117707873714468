import { Button, Typography } from "@material-tailwind/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PlentiInput } from "../forms/plentiInput";
import { useProductContext } from "./ProductContextWrapper";
import PlentiCurrencyInput from "../forms/plentiCurrencyInput";
import { useState } from "react";
import { useCompanyContext } from "../company/companyContext";
import { useUserContext } from "../user/userContext";
import SelectCompany from "../company/selectCompany";
import { useNavigate } from "react-router-dom";

type Inputs = {
  name: "";
};

function AddProductForm() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { selectedCompany } = useCompanyContext();
  const { createProduct } = useProductContext();
  const [amount, setAmount] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const values = {
      company_id: selectedCompany?.id,
      name: data.name,
      retail_price: amount ? Math.round(Number(amount) * 100) : 0,
    };
    console.log(values);
    createProduct(values);
    reset();
    navigate("/product");
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-3 gap-5">
        <div className="flex flex-col gap-2">
          {user?.type === "admin" && <SelectCompany />}
          <PlentiInput
            register={register}
            errors={errors}
            label="Name"
            name="name"
          />
          <Typography variant="small" className="mt-2">
            Retail Price
          </Typography>
          <PlentiCurrencyInput amount={amount} setAmount={setAmount} />
        </div>
      </div>

      <div className="mt-4 col-span-2">
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default AddProductForm;
