/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AddButton from "../buttons/addButton";
import { useCampaignContentContext } from "../campaignSmsContent/campaignSmsContentContext";
import { useCompanyContext } from "../company/companyContext";
import GenericDialog from "../dialog/genericDialog";
import { useLoadLeadCountsByCampaign } from "../lead/hooks/useLoadLeadCountByCampaign";
import { PlentiLink } from "../link/linkToInternal";
import { SegmentContextWrapper } from "../segments/hooks/SegmentContext";
import AddSegmentsContainer from "./AddSegmentsContainer";
import { useCampaignContext } from "./campaignContext";

function CampaignLeadsContainer() {
  const location = useLocation();
  const { activeCampaign, associatedCompany } = useCampaignContext();
  const { setSelectedCompany } = useCompanyContext();

  const [open, setOpen] = useState<boolean>(false);
  const {
    campaignSmsContent,
    getCampaignDefaultSmsContent,
    campaignDefaultSmsContent,
    smsContent,
  } = useCampaignContentContext();

  useEffect(() => {
    if (activeCampaign) {
      getCampaignDefaultSmsContent(activeCampaign.id);
    }
  }, [activeCampaign, campaignSmsContent]);

  const [isAddSegment, setIsAddSegment] = useState<boolean>(false);

  const { data } = useLoadLeadCountsByCampaign({
    campaignId: activeCampaign?.id as string,
  });

  useEffect(() => {
    if (associatedCompany) {
      setSelectedCompany(associatedCompany);
    }
  }, [associatedCompany]);

  useEffect(() => {
    if (isAddSegment) {
      setOpen(true);
    }
  }, [isAddSegment]);

  if (!activeCampaign) {
    return <div>Error: No Campaign</div>;
  }

  return (
    <div className="w-full">
      <GenericDialog
        open={open}
        setOpen={setOpen}
        title={`Add Segment(s) to campaign ${activeCampaign?.name}`}
      >
        <SegmentContextWrapper>
          <AddSegmentsContainer
            campaignId={activeCampaign?.id as string}
            campaignDefaultSmsContent={campaignDefaultSmsContent}
            smsContent={smsContent}
            setOpen={setOpen}
          />
        </SegmentContextWrapper>
      </GenericDialog>
      <div className="flex justify-between">
        <Typography variant="h6">Leads</Typography>
        <AddButton
          className="flex items-center gap-2 p-1"
          variant="outlined"
          color="gray"
          size="sm"
          iconClass="h-4 w-4"
          onClick={() => {
            setOpen(true);
            setIsAddSegment(true);
          }}
        >
          Add Segment
        </AddButton>
      </div>

      <Divider className="my-1" />
      <div className="my-4 grid w-full gap-10">
        <div className="flex w-full flex-col gap-6">
          <div className="grid grid-cols-4 items-end">
            <div>
              <p className="text-xs text-gray-500">Lead count </p>
              <p>{data?.total}</p>
            </div>
            <PlentiLink
              text="Leads List"
              to={`/leads?campaignId=${activeCampaign.id}`}
              state={{
                name: "campaign",
                href: location.pathname,
                active: false,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignLeadsContainer;
