/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { UpdateLineItem } from "./ocrContext.types";

import { useSession } from "@supabase/auth-helpers-react";
import { updateLineItemById } from "../../api/lineItem.api";
import { getProducts } from "../../api/product.api";
import { ProductRow } from "../../api/product.type";

export const LineItemContextWrapper = ({
  id,
  children,
}: {
  id?: string | undefined | string[];
  children: React.ReactNode;
}) => {
  const session = useSession();

  const queryClient = useQueryClient();

  const { data: products } = useQuery({
    queryKey: "products",
    queryFn: () => getProducts(),
    enabled: !!session,
  });

  const { mutate: updateLineItem } = useMutation(updateLineItemById, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("ocr");
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const value = {
    updateLineItem,
    products,
  };

  return (
    <LineItemContext.Provider value={value}>
      {children}
    </LineItemContext.Provider>
  );
};

export const LineItemContext = createContext({
  updateLineItem: {} as UpdateLineItem,
  products: [] as ProductRow[] | undefined,
});

export const useLineItemContext = () => useContext(LineItemContext);
