import ProductContainer from "../components/product/ProductContainer";
import { ProductContextWrapper } from "../components/product/ProductContextWrapper";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Product = () => {
  mixpanelTrackEvent("Product page");

  return (
    <ProductContextWrapper>
      <ProductContainer />
    </ProductContextWrapper>
  );
};

export default Product;
