import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  CreateEmailTemplateRow,
  EmailTemplateRow,
  UpdateEmailTemplateRow,
} from "./emailTemplate.type";

export function getEmailTemplates(): Promise<EmailTemplateRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.emailTemplate;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<EmailTemplateRow[]>(url, request);
}

export function createTemplate(
  template: CreateEmailTemplateRow
): Promise<EmailTemplateRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.emailTemplate;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(template),
  });

  return fetchData<EmailTemplateRow>(url, request);
}

export function getEmailTemplateById(id: string): Promise<EmailTemplateRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.emailTemplate + `/${id}`;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<EmailTemplateRow>(url, request);
}

export function updateEmailTemplate({
  id,
  template,
}: {
  id: string;
  template: UpdateEmailTemplateRow;
}): Promise<EmailTemplateRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.emailTemplate + `/${id}`;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(template),
  });

  return fetchData<EmailTemplateRow>(url, request);
}
