/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useAlertContext } from "../alert/alertContext";
import {
  CreateCampaignSmsContentMutation,
  DeleteCampaignSmsContentMutation,
  UpdateDefaultCampaignSmsContentMutation,
  UpdateSmContentMutation,
} from "../campaignSmsContent/campaignSmsContentContent.types";

import {
  aiGenerateCampaignSmsContent,
  createCampaignSmsContent,
  deleteCampaignSmsContent,
  getCampaignSmsContent,
  updateCampaignSmsContent,
  updateDefaultCampaignSmsContent,
} from "../../api/campaignSmsContent.api";
import { CampaignSmsContentRow } from "../../api/campaignSmsContent.type";
import { getSmsContent } from "../../api/smsContent.api";
import { SmsContentRow } from "../../api/smsContent.type";
import { useUserContext } from "../user/userContext";
import { getEmailTemplates } from "../../api/emailTemplate.api";
import { EmailTemplateRow } from "../../api/emailTemplate.type";
import { useCampaignContext } from "../campaign/campaignContext";

export const CampaignContentContextWrapper = (props: any) => {
  const queryClient = useQueryClient();
  const { setAlertProps } = useAlertContext();
  const [generating, setGenerating] = useState<boolean>(false);
  const { user } = useUserContext();

  const [expectedNewValues, setExpectedNewValues] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [campaignDefaultSmsContent, setCampaignDefaultSmsContent] =
    useState<CampaignSmsContentRow>();

  const [selectedContent, setSelectedContent] = useState<
    SmsContentRow | EmailTemplateRow
  >();

  const { activeCampaign } = useCampaignContext();

  const [campaignSpecificContent, setCampaignSpecificContent] =
    useState<CampaignSmsContentRow[]>();

  const { data: campaignSmsContent, refetch } = useQuery({
    queryKey: "campaignSmsContent",
    queryFn: () => getCampaignSmsContent(),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (campaignSmsContent && campaignSmsContent.length > 0) {
      const specificContent = campaignSmsContent
        ?.filter((c) => {
          const isPaused = c?.sms_content?.type === "campaign_paused";
          const isDefault = c?.is_default;

          const matchedCampaign = c.campaign_id === activeCampaign?.id;

          return matchedCampaign && !isDefault && !isPaused;
        })
        .sort((a, b) => a.send_order - b.send_order || 0);

      setCampaignSpecificContent(specificContent);
    }
  }, [campaignSmsContent, activeCampaign]);

  const { data: smsContent } = useQuery({
    queryKey: "smsContent",
    queryFn: () => getSmsContent(),
    enabled: !!user,
  });

  const { data: emailContent } = useQuery({
    queryKey: "emailContent",
    queryFn: () => getEmailTemplates(),
    enabled: !!user,
  });

  const useAiGenerateContent = useMutation(aiGenerateCampaignSmsContent, {
    onSuccess: async (res) => {
      setGenerating(true);
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
      setGenerating(false);
    },
  });

  const { mutate: updateDefault, isLoading: defaultLoading } = useMutation(
    updateDefaultCampaignSmsContent,
    {
      onSuccess: async (res) => {
        setCampaignDefaultSmsContent(res);
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaignSmsContent");
        setAlertProps({
          message: `Message content updated`,
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  const { mutate: updateContent, isLoading: updateContentLoading } =
    useMutation(updateCampaignSmsContent, {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaignSmsContent");
        setAlertProps({
          message: `Message content order updated`,
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    });

  const useCreateCampaignSmsContent = useMutation(createCampaignSmsContent, {
    onSuccess: async (_res) => {
      queryClient.invalidateQueries("campaignSmsContent");
      queryClient.invalidateQueries("campaigns");
      setAlertProps({
        message: `Message content added`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const useDeleteCampaignSmsContent = useMutation(deleteCampaignSmsContent, {
    onSuccess: async (_res) => {
      queryClient.invalidateQueries("campaignSmsContent");
      queryClient.invalidateQueries("campaigns");
      setAlertProps({
        message: `Message content removed`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function handleCallAiGenerateContent(campaignId: string, count: number) {
    const currentCount = campaignSmsContent?.length || 0;
    setExpectedNewValues(count + currentCount);
    useAiGenerateContent.mutate({
      campaignId: campaignId,
      count,
    });
  }

  function getCampaignDefaultSmsContent(campaignId: string) {
    if (campaignSmsContent) {
      const defaultSmsContent = campaignSmsContent.find(
        (c) => c.campaign_id === campaignId && c.is_default
      );
      setCampaignDefaultSmsContent(defaultSmsContent);
    }
  }

  useEffect(() => {
    if (activeCampaign) {
      getCampaignDefaultSmsContent(activeCampaign.id);
    }
  }, [activeCampaign, campaignSmsContent]);

  useEffect(() => {
    if (generating) {
      refetch();
      setTimeout(() => {
        if (expectedNewValues !== campaignSmsContent?.length) {
          setCounter(counter + 1);
        } else {
          setGenerating(false);
          setCounter(0);
        }
      }, 5000);
    }
  }, [generating, counter]);

  const value = {
    selectedContent,
    setSelectedContent,
    emailContent,
    campaignSmsContent,
    getCampaignDefaultSmsContent,
    campaignSpecificContent,
    campaignDefaultSmsContent,
    updateDefault,
    useCreateCampaignSmsContent,
    useDeleteCampaignSmsContent,
    handleCallAiGenerateContent,
    smsContent,
    generating,
    setGenerating,
    loading: defaultLoading || updateContentLoading,
    updateContent,
  };

  return (
    <CampaignContentContext.Provider value={value}>
      {props.children}
    </CampaignContentContext.Provider>
  );
};

export const CampaignContentContext = createContext({
  selectedContent: {} as SmsContentRow | EmailTemplateRow | undefined,
  setSelectedContent: (
    _content: SmsContentRow | EmailTemplateRow | undefined
  ) => {},
  emailContent: [] as EmailTemplateRow[] | undefined,
  campaignSmsContent: [] as CampaignSmsContentRow[] | undefined,
  getCampaignDefaultSmsContent: (_campaignId: string) => {},
  campaignSpecificContent: [] as CampaignSmsContentRow[] | undefined,
  campaignDefaultSmsContent: {} as CampaignSmsContentRow | undefined,
  updateDefault: {} as UpdateDefaultCampaignSmsContentMutation,
  useCreateCampaignSmsContent: {} as CreateCampaignSmsContentMutation,
  useDeleteCampaignSmsContent: {} as DeleteCampaignSmsContentMutation,
  handleCallAiGenerateContent: (_campaignId: string, _count: number) => {},
  smsContent: [] as SmsContentRow[] | undefined,
  generating: false,
  setGenerating: (_generating: boolean) => {},
  updateContent: {} as UpdateSmContentMutation,
});

export const useCampaignContentContext = () =>
  useContext(CampaignContentContext);
