import { AiAgentRow } from "./aiAgent.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";

export function getAiAgents(): Promise<AiAgentRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.aiAgent + "/plenti";

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<AiAgentRow[]>(url, request);
}

export function getAiAgentBySlug(slug: string): Promise<AiAgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.aiAgent + `/slug/${slug}`;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<AiAgentRow>(url, request);
}
