import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment } from "react";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useAdminGptContext } from "./adminGptContext";

function GptLocationTestingResponse() {
  const { locationMessage, loading } = useAdminGptContext();

  return (
    <div className="rounded-lg bg-gray-100 col-span-2 ">
      <div className="flex flex-col p-5">{locationMessage?.response}</div>
      {locationMessage && <Divider className="p-2" />}
      {locationMessage && locationMessage?.intervalMessages?.length > 0 ? (
        locationMessage?.intervalMessages.map((item) => {
          const { role, content, tool_calls, tool_call_id } = item;

          return (
            <div className="flex flex-col p-5">
              <Typography variant="small">Role: {role}</Typography>
              <Typography variant="small">Content: {content}</Typography>
              <Typography>{tool_call_id}</Typography>
              {tool_calls &&
                tool_calls.map((tool_call) => {
                  const { id, type, function: functionName } = tool_call;
                  return (
                    <div className="flex flex-col p-5">
                      <Typography variant="lead">Tool:</Typography>
                      <Typography variant="small">{id}</Typography>
                      <Typography variant="small">Type: {type}</Typography>
                      <Typography variant="small">
                        Name: {functionName.name}
                      </Typography>
                      <Typography variant="small">
                        Arguments {functionName.arguments}
                      </Typography>
                    </div>
                  );
                })}
              <Divider className="p-2" />
            </div>
          );
        })
      ) : (
        <Fragment>
          {loading && <PlentiLogoLoading className="h-12 w-12 mx-auto" />}
        </Fragment>
      )}
    </div>
  );
}

export default GptLocationTestingResponse;
