import { Badge, Tab, Tabs, TabsHeader } from "@material-tailwind/react";

export type TabConfig = {
  label: string;
  value: string;
  explanation?: string;
  badgeValue?: number;
};

function PlentiTabs({
  activeTab,
  setActiveTab,
  tabConfig,
}: {
  activeTab: TabConfig;
  setActiveTab: (value: TabConfig) => void;
  tabConfig: TabConfig[];
}) {
  return (
    <div className="w-full flex">
      <Tabs value={activeTab} className="w-full">
        <TabsHeader
          className="rounded-none border-b border-primary-200 bg-transparent p-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-primary-300 shadow-none rounded-none ",
          }}
        >
          {tabConfig.map((tab: TabConfig) => (
            <Tab
              key={tab.value}
              value={tab.value}
              onClick={() => setActiveTab(tab)}
              className={
                activeTab.value === tab.value
                  ? "text-primary-300 w-60 mt-2"
                  : "w-60 mt-2"
              }
            >
              {tab.badgeValue ? (
                <Badge content={tab.badgeValue} color="purple">
                  {tab.label}
                </Badge>
              ) : (
                tab.label
              )}
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
    </div>
  );
}

export default PlentiTabs;
