import { IconButton, Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { EditIcon } from "../icons/icons";
import { LinkToId } from "../table/linkToId";

import { CompanyRow } from "../../api/company.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";

function CompanyTableList({
  companies,
  handleContentClick,
}: {
  companies: CompanyRow[] | undefined;
  handleContentClick: (id: string) => void;
}) {
  function mapCompanyToRow(company: CompanyRow): {
    id: string;
    name: string;
    slug: string;
    createdAt: string;
  } {
    const values = {
      id: company.id,
      name: company?.name ? company?.name : "N/A",
      createdAt: company.created_at
        ? formatTimeStampToHumanReadableShortDateTime(company.created_at)
        : "N/A",
      slug: company?.slug ? company?.slug : "N/A",
    };

    return values;
  }
  return (
    <Fragment>
      {companies &&
        companies
          .map((item) => mapCompanyToRow(item))
          .map(({ id, name, createdAt, slug }, index) => {
            const isLast = index === companies.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={name} className="even:bg-blue-gray-50/50">
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {name}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {createdAt}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {slug}
                  </Typography>
                </td>
                <td className={classes}>
                  <LinkToId type="demo" id={slug} baseUrl="/demo/" />
                </td>
                <td className={classes}>
                  <LinkToId type="try" id={slug} baseUrl="/try/" />
                </td>

                <td className={classes}>
                  <IconButton
                    onClick={() => handleContentClick(id)}
                    variant="text"
                    color="blue-gray"
                  >
                    <EditIcon className="h-4 w-4" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default CompanyTableList;
