import { Button, IconButton, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";

import { useCompanyContext } from "../company/companyContext";
import { findCompanyById } from "../company/companyHelpers";
import ConfirmDialog from "../dialog/confirmDialog";
import { DeleteIcon, EditIcon } from "../icons/icons";

import { SubmitHandler, useForm } from "react-hook-form";
import { CompanyRow } from "../../api/company.type";
import { ProductRow } from "../../api/product.type";
import {
  parseCentsToDollars,
  parseCentsToDollarsNoDollarSign,
} from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import PlentiCurrencyInput from "../forms/plentiCurrencyInput";
import { PlentiInput } from "../forms/plentiInput";
import { useProductContext } from "./ProductContextWrapper";

function ProductTableList({
  products,
  filterFunction,
}: {
  products: ProductRow[] | undefined;
  filterFunction?: (product: ProductRow, index: number) => boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { companies } = useCompanyContext();
  const { setProductToDelete, productToDelete, deleteProduct, updateProduct } =
    useProductContext();

  const [isEditId, setIsEditId] = useState("");
  const [amount, setAmount] = useState("");

  function handleDeleteClick(product: Partial<ProductRow>) {
    setProductToDelete(product);
    setIsOpen(true);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  function handleSetEdit(id: string) {
    setIsEditId(id);

    const foundProduct = products?.find((product) => product.id === id);

    const price = foundProduct?.retail_price;
    const name = foundProduct?.name ? foundProduct?.name : "";
    setAmount(
      price && price > 0
        ? parseCentsToDollarsNoDollarSign(price.toString())
        : "0.00"
    );
    setValue("name", name);
  }

  const onSubmit: SubmitHandler<{
    name: string;
  }> = (data: { name: string }) => {
    const values = {
      name: data.name,
      retail_price: Math.round(Number(amount) * 100),
    };
    updateProduct({ id: isEditId, ...values });
    setIsEditId("");
    reset();
  };

  function mapProductsToRow(product: ProductRow): {
    id: string;
    name: string;
    createdAt: string;
    description: string;
    price: string;
    company: CompanyRow | null | undefined;
  } {
    const values = {
      id: product.id,
      name: product?.name ? product?.name : "N/A",
      createdAt: product.created_at
        ? formatTimeStampToHumanReadableShortDateTime(product.created_at)
        : "N/A",
      description: product.description ? product.description : "N/A",
      price: product?.retail_price
        ? parseCentsToDollars(product?.retail_price)
        : "N/A",
      company:
        product.company_id && companies && companies.length > 0
          ? findCompanyById(companies, product.company_id)
          : null,
    };

    return values;
  }

  return (
    <Fragment>
      <ConfirmDialog
        isOpen={isOpen}
        handleOpen={() => setIsOpen(!isOpen)}
        title="Confirm delete product"
        body={`Delete product: ${productToDelete?.name}?`}
        handleConfirm={() => {
          deleteProduct(productToDelete?.id ? productToDelete.id : "");
          setIsOpen(!isOpen);
        }}
      />
      {products &&
        products.length > 0 &&
        products
          .filter((lead, index) =>
            filterFunction ? filterFunction(lead, index) : true
          )
          .map((item) => mapProductsToRow(item))
          .map((product, index) => {
            const { id, name, createdAt, price, company } = product;
            const isLast = index === products.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className={classes}>
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {id}
                    </Typography>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {createdAt}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  {company?.company_config &&
                  company?.company_config[0] &&
                  company?.company_config[0].logo_image_url ? (
                    <img
                      height={50}
                      width={50}
                      src={company.company_config[0].logo_image_url}
                      alt="logo"
                    />
                  ) : (
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {company?.name || "N/A"}
                    </Typography>
                  )}
                </td>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      {isEditId && id === isEditId ? (
                        <PlentiInput
                          register={register}
                          errors={errors}
                          label="Name"
                          name="name"
                          required={false}
                        />
                      ) : (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {name}
                        </Typography>
                      )}
                    </div>
                  </div>
                </td>

                <td className={classes}>
                  {isEditId && id === isEditId ? (
                    <PlentiCurrencyInput
                      amount={amount}
                      setAmount={setAmount}
                    />
                  ) : (
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {price}
                    </Typography>
                  )}
                </td>

                <td className={classes}>
                  {isEditId && id === isEditId ? (
                    <div className="flex flex-col gap-1">
                      <Button
                        size="sm"
                        color="green"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                      <Button
                        size="sm"
                        color="red"
                        onClick={() => setIsEditId("")}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <IconButton
                      onClick={() => handleSetEdit(id)}
                      variant="text"
                      color="blue-gray"
                    >
                      <EditIcon className="h-4 w-4" />
                    </IconButton>
                  )}
                </td>
                <td className={classes}>
                  <IconButton
                    onClick={() => handleDeleteClick(product)}
                    variant="text"
                    color="red"
                  >
                    <DeleteIcon className="h-4 w-4" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default ProductTableList;
