/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { Button, Typography } from "@material-tailwind/react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";

import { Divider } from "@tremor/react";
import { SubmitHandler, useForm } from "react-hook-form";
import SelectCompany from "../company/selectCompany";
import { PlentiInput } from "../forms/plentiInput";

import { useEffect } from "react";
import {
  AiAgentContextWrapper,
  useAiAgentContext,
} from "./hooks/AiAgentContext";
import {
  FileUploadContextWrapper,
  useFileUploadContext,
} from "../forms/fileUploadContext";
import FileUploadInput from "../forms/fileUploadInput";

type DataAnalysisAgentProps = {
  name: string;
  description: string;
};

function _DataAnalysisAgent() {
  const fileTypes = [".csv", ".xls", ".xlsx", ".pptx", ".json", ".md"];

  const {
    handleOpenFileSelect,
    files,
    filePaths,
    hiddenFileInput,
    handleMultipleChange,
    uploadMultiple,
  } = useFileUploadContext();

  const { createAgent } = useAiAgentContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const onSubmit: SubmitHandler<DataAnalysisAgentProps> = (
    data: DataAnalysisAgentProps
  ) => {
    createAgent({
      name: data.name,
      description: data.description,
      type: "data_analysis",
      files: filePaths,
    });
    reset();
  };

  useEffect(() => {
    if (files && files.length !== filePaths.length) {
      uploadMultiple({ files });
    }
  }, [files]);

  return (
    <div className="p-5 max-w-2xl">
      <div className="flex justify-between">
        <BreadcrumbsFromConfig
          config={[
            {
              name: "Add Agent",
              href: "/agent/add",
              active: false,
            },
            {
              name: "Data Analysis Agent",
              href: "/agent/add/data-analysis",
              active: true,
            },
          ]}
        />
        {/* <LearnMoreButton
          onClick={() => {
            window.open("https://youtu.be/QE7ie0nWj6I", "_blank");
          }}
          text=" Tutorial"
          variant="text"
        /> */}
      </div>
      <div className="mt-4 grid-cols-2 grid gap-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-md">
            <div className="flex justify-between">
              <Typography variant="h6">Add Data Analysis Agent</Typography>
            </div>

            <Divider className="my-1" />
          </div>
          <div className="flex flex-col gap-4">
            <PlentiInput
              register={register}
              errors={errors}
              label="Name"
              name="name"
            />
            <PlentiInput
              register={register}
              errors={errors}
              label="Description"
              name="description"
            />
            {/* {!watch("is_ai_generated") && (
            <PlentiInput
              register={register}
              errors={errors}
              label="Media URL"
              name="media_url"
              required={false}
            />
          )}

          <Textarea
            {...register("content")}
            label={
              !watch("is_ai_generated") ? "Content" : "Context passed to AI"
            }
            className="focus:border-t-0 focus:ring-0"
          /> */}
            <SelectCompany />
            <Button
              color={"deep-purple"}
              onClick={(e) => {
                e.preventDefault();
                handleOpenFileSelect(e);
              }}
            >
              Upload files
            </Button>
            <Typography variant="small">
              File types allowed: {fileTypes.join(", ")}
            </Typography>
            <div className="place-self-end">
              <Button
                size="sm"
                color="green"
                fullWidth={false}
                disabled={!isDirty}
                type="submit"
                className="mt-4 "
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
        <div className="p-5">
          <ul>
            {files &&
              files?.map((file, index) => <li key={index}>{file.name}</li>)}
          </ul>
        </div>

        <FileUploadInput
          handleChange={handleMultipleChange}
          hiddenFileInput={hiddenFileInput}
          accept=".csv, .xls, .xlsx, .pptx, .json, .md"
          multiple={true}
        />
      </div>
    </div>
  );
}

const DataAnalysisAgent = () => {
  return (
    <AiAgentContextWrapper>
      <FileUploadContextWrapper>
        <_DataAnalysisAgent />
      </FileUploadContextWrapper>
    </AiAgentContextWrapper>
  );
};

export default DataAnalysisAgent;
