import AuthSignUpSignIn from ".";
import CheezyPoofs from "../cheezyPoofs/cheezyPoofs";
import LoginLayout from "../layout/LoginLayout";
import SignupQuestions from "./SignupQuestions";
import { useAuthContext } from "./authContext";

const SignUp = () => {
  const { signupUser } = useAuthContext();

  return (
    <LoginLayout>
      {!signupUser && <AuthSignUpSignIn viewType="Sign up" />}
      {signupUser && <SignupQuestions />}
      <div className="hidden md:block">
        <CheezyPoofs />
      </div>
    </LoginLayout>
  );
};

export default SignUp;
