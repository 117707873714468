import { CampaignSmsContentRow } from "../../api/campaignSmsContent.type";
import { SmsContentRow } from "../../api/smsContent.type";
import EmailHeader from "../emailTemplates/EmailHeaderDisplay";
import ChatForm from "../lead/chatForm";
import ChatHistoryItem from "../lead/chatItem";

export function DisplayEmailContent({
  content,
  fromEmail,
}: {
  content: string;
  fromEmail?: string;
}) {
  return (
    <div key={content}>
      <EmailHeader
        fromName={`Mike Jones ${fromEmail ? `<${fromEmail}>` : ""}`}
        toName="John Smith"
        avatarUrl=""
        timestamp={new Date().toISOString()}
      />

      <table width="100%">
        <div
          style={{ padding: "10px" }}
          dangerouslySetInnerHTML={{
            __html: content || "",
          }}
        ></div>{" "}
      </table>
    </div>
  );
}

export function DisplaySmsContent({ content }: { content: SmsContentRow[] }) {
  return (
    <ChatForm
      activeLead={null}
      messages={content.map((c) => ({
        content: c.content,
        role: "assistant",
        created_at: new Date().toISOString(),
        media_url: c.media_url,
      }))}
      loading={false}
      handleSubmitMessage={() => console.log()}
    />
  );
}

function DisplayEmailOrSmsContent({
  content,
}: {
  content?: CampaignSmsContentRow[];
}) {
  if (content && content[0].sms_content) {
    return (
      <DisplaySmsContent
        content={content.filter((c) => c.sms_content).map((c) => c.sms_content)}
      />
    );
  }
  return (
    <div>
      {!content && (
        <div className="p-10">
          No content. Add content to this campaign and it will be displayed
          here.
        </div>
      )}
      {content?.map((c) => {
        console.log(c.sms_content);
        if (c.email_template) {
          return <DisplayEmailContent content={c.email_template.content} />;
        } else {
          return (
            <ChatHistoryItem
              message={{
                content: c.sms_content?.content,
                role: "assistant",
                created_at: new Date().toISOString(),
                media_url: c.sms_content?.media_url,
              }}
              messagesEndRef={null}
            />
          );
        }
      })}
    </div>
  );
}

export default DisplayEmailOrSmsContent;
