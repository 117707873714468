import { CampaignJourneyRow } from "../../../api/campaignJourney.type";
import { CampaignConversationMetrics } from "../../../api/conversation.type";

export function mapCampaignMetricsToPositions(
  campaignMetrics: CampaignConversationMetrics[],
  campaignJourneys: CampaignJourneyRow[] | undefined
) {
  const updatedNodes = campaignMetrics.map(
    (cm: CampaignConversationMetrics, index: number) => {
      let position;

      if (campaignJourneys && campaignJourneys.length > 0) {
        const found = campaignJourneys.find(
          (cj) => cj.campaign_id === cm.campaignId
        );

        position = {
          x: found?.x_position || 0,
          y: found?.y_position || 0,
        };
      } else {
        position = {
          x: index * (100 + index),
          y: index * (100 + index),
        };
      }

      return {
        id: cm.campaignId,
        type: "custom",
        data: { name: cm.campaignName },
        position,
        label: cm.campaignName,
        metrics: cm.metrics,
      };
    }
  );

  return updatedNodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      label: node.data.name,
      metrics: node.metrics,
      id: node.id,
    },
  }));
}
