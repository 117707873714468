import { useState } from "react";
import { ExtractionSchemaRow } from "../../api/extractionSchema.type";
import EditButton from "../buttons/editButton";
import DateDetailsDetails from "../forms/createAtDetails";
import ValueWithLabel from "../forms/valueWithLabel";
import EditSchema from "./EditSchema";
import ViewSchema from "./ViewSchema";

function EditExtractionSchema({
  extractionSchema,
}: {
  extractionSchema: ExtractionSchemaRow;
}) {
  const [isEdit, setIsEdit] = useState(false);

  const { schema, created_at, id, name } = extractionSchema;

  return (
    <div className="my-4 grid max-w-2xl gap-10">
      <div className="flex w-full flex-col gap-6">
        <div className="flex flex-row justify-between">
          <DateDetailsDetails date={created_at} title="Created" />

          <ValueWithLabel label="Id" value={id} />
        </div>
        <div className="flex justify-between">
          {!isEdit ? <ValueWithLabel label="Name" value={name} /> : <div></div>}
          <EditButton
            iconClass="h-4 w-4"
            text="Edit"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          />
        </div>
        <div className="flex flex-row justify-between gap-2">
          {!isEdit ? (
            <ViewSchema schema={schema} />
          ) : (
            <EditSchema
              schema={schema}
              setIsEdit={setIsEdit}
              name={name}
              id={id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditExtractionSchema;
