/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";

import { useSmsContentContext } from "../smsContent/smsContentContext";
import SmsContentCampaigns from "./SmsContentCampaigns";
import SmsContentDetails from "./SmsContentDetails";
import SmsContentStats from "./SmsContentStats";
import SmsContentCost from "./SmsContentCost";

function ViewSmsContentById({ id }: { id: string | undefined | string[] }) {
  const { useGetSmsContentById } = useSmsContentContext();

  useEffect(() => {
    if (id && typeof id === "string") {
      useGetSmsContentById.mutate(id);
    }
  }, [id]);

  const breadCrumbsConfig = [
    {
      name: "Edit",
      href: `/company/${id}`,
      active: true,
    },
  ];

  return (
    <div className=" max-w-2xl flex flex-col gap-5 m-2 p-5">
      <BreadcrumbsFromConfig config={breadCrumbsConfig} />
      <div>
        <SmsContentDetails />
        <SmsContentCost />
        <SmsContentCampaigns />
        <SmsContentStats />
      </div>
    </div>
  );
}

export default ViewSmsContentById;
