/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { createNewSession, getSessionById } from "../../../api/session.api";
import { SessionRow } from "../../../api/session.type";

export const SessionContextWrapper = (props: any) => {
  const [sessionId, setSessionId] = useState<string>("");

  const { mutate: createSession } = useMutation(createNewSession, {
    onSuccess: async (res) => {
      setSessionId(res.id);
      localStorage.setItem("session_id", res.id);
    },
    onError(error: Error) {},
  });

  const { mutate: getSession, data: session } = useMutation(getSessionById, {
    onSuccess: async (res) => {},
    onError(error: Error) {},
  });

  useEffect(() => {
    if (!sessionId) {
      const foundSessionId = localStorage.getItem("session_id");
      if (foundSessionId) {
        setSessionId(foundSessionId);
      } else {
        createSession();
      }
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      getSession(sessionId);
    }
  }, [sessionId]);

  const value = { session };

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  );
};

export const SessionContext = createContext({
  session: {} as SessionRow | undefined,
});

export const useSessionContext = () => useContext(SessionContext);
