import { Button } from "@material-tailwind/react";
import { Fragment } from "react";

function PlentiUpdateOrCancelButtons({
  isEdit,
  setIsEdit,
  disabled,
  handleCancel,
  handleSubmit,
}: {
  isEdit: boolean;
  setIsEdit?: (isEdit: boolean) => void;
  disabled?: boolean;
  handleCancel?: () => void;
  handleSubmit?: () => void;
}) {
  return (
    <Fragment>
      {isEdit && (
        <div className="mt-6 flex justify-end gap-2">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => {
              if (setIsEdit) {
                setIsEdit(false);
              }
              if (handleCancel) {
                handleCancel();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            disabled={disabled || false}
            type="submit"
            onClick={() => {
              if (handleSubmit) {
                handleSubmit();
              }
            }}
          >
            Update
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export default PlentiUpdateOrCancelButtons;
