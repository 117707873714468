import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  CompanyConfigRow,
  CompanyForPaths,
  CompanyInsert,
  CompanyRow,
  CompanyUpdate,
} from "../api/company.type";

export function getCompanies(): Promise<CompanyRow[]> {
  const url = process.env.REACT_APP_API_URL + "/company";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CompanyRow[]>(url, request);
}

export function getCompanyByUserId(userId: string): Promise<CompanyRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.company + "/user/" + userId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyRow[]>(url, request);
}

export function getCompaniesForPaths(): Promise<CompanyForPaths[]> {
  const url = process.env.REACT_APP_API_URL + "/company/public";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyForPaths[]>(url, request);
}

export function updateCompany(
  updateCompany: CompanyUpdate
): Promise<CompanyRow[]> {
  const url = process.env.REACT_APP_API_URL + "/company/update";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(updateCompany),
  };

  return fetchData<CompanyRow[]>(url, request);
}

export function createCompany(
  company: CompanyInsert | null
): Promise<CompanyRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.company + "/create";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(company),
  });

  return fetchData<CompanyRow[]>(url, request);
}

export function signupCreateCompany({
  name,
  product,
  userId,
  logo_link_url,
}: {
  name?: string;
  product?: string;
  userId?: string;
  logo_link_url?: string;
}): Promise<CompanyRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.company + "/signup/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ name, product, userId, logo_link_url }),
  };

  return fetchData<CompanyRow[]>(url, request);
}

export function getCompanyConfigById(
  companyId: string
): Promise<CompanyConfigRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyConfig +
    "/" +
    companyId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyConfigRow>(url, request);
}

export function updateCompanyConfigApiCall({
  id,
  values,
}: {
  id: string;
  values: Partial<CompanyConfigRow>;
}): Promise<CompanyConfigRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.companyConfig + "/" + id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ values }),
  };

  return fetchData<CompanyConfigRow>(url, request);
}

export function getCompanyById(id: string) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.company + "/" + id;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyRow>(url, request);
}
