import { useTryContext } from "../try/tryContext";
import { Link } from "react-router-dom";
import { defaultGray } from "./trySubtitle";

function TryPolicy() {
  const { companyConfig, company, campaign } = useTryContext();
  const paragraphFontWeight = campaign?.paragraph_font_weight || 400;
  return (
    <p
      className="mx-auto max-w-[300px] text-xs"
      style={{
        fontSize: "9px",
        lineHeight: "12px",
        color: companyConfig?.disclaimer_color || defaultGray,
        fontWeight: paragraphFontWeight,
      }}
    >
      By providing your phone number or submitting this form, you agree to
      receive recurring automated marketing text messages (e.g. promos, cart
      reminders) from Plenti AI and {company?.name} at the number provided.
      Consent is not a condition to obtain goods or services. Msg & data rates
      may apply. Msg frequency varies. Reply HELP for help and STOP to cancel.
      You also agree to our{" "}
      <Link
        to={
          companyConfig?.privacy_policy_link
            ? companyConfig.privacy_policy_link
            : "/legal/privacy-policy"
        }
      >
        Privacy Policy
      </Link>{" "}
      &{" "}
      <Link
        to={
          companyConfig?.terms_of_service_link
            ? companyConfig.terms_of_service_link
            : "/legal/terms"
        }
      >
        {" "}
        Terms.
      </Link>
    </p>
  );
}

export default TryPolicy;
