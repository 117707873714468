/* eslint-disable react-hooks/exhaustive-deps */

import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { secondsToTimeFormat } from "../../utils/parseTimeStamp";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { useSmsContentContext } from "./smsContentContext";
import MessageResponseIntervalBarChart from "./MessageResponseIntervalBarChart";

function SmsContentStats() {
  const { contentStats } = useSmsContentContext();

  if (!contentStats) {
    return (
      <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
        <div className="w-full">
          <div className="flex justify-between">
            <Typography variant="h6">Message Statistics</Typography>
          </div>

          <Divider className="my-1" />
          <div className="my-4 grid w-full gap-10">
            <div className="flex w-full flex-col gap-6">
              <div>Not enough data</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { stats, data } = contentStats;

  if (!stats || !data) {
    return (
      <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
        <div className="w-full">
          <div className="flex justify-between">
            <Typography variant="h6">Message Statistics</Typography>
          </div>

          <Divider className="my-1" />
          <div className="my-4 grid w-full gap-10">
            <div className="flex w-full flex-col gap-6">
              <div>Not enough data</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { count, average, min, max, standardDeviation } = stats;

  return (
    <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
      <div className="w-full">
        <div className="flex justify-between">
          <Typography variant="h6">Message Statistics</Typography>
        </div>

        <Divider className="my-1" />
        <div className="my-4 grid grid-cols-2 w-full gap-10">
          <div>
            <PlentiFormLabel>Total Delivered</PlentiFormLabel>
            <Typography>{count}</Typography>
          </div>
          <div>
            <PlentiFormLabel>Average response interval</PlentiFormLabel>
            <Typography>
              {average ? secondsToTimeFormat(average) : "N/A"}
            </Typography>
          </div>
          <div>
            <PlentiFormLabel>Min response interval</PlentiFormLabel>
            <Typography>{min ? secondsToTimeFormat(min) : "N/A"}</Typography>
          </div>
          <div>
            <PlentiFormLabel>Max response interval</PlentiFormLabel>
            <Typography>{max ? secondsToTimeFormat(max) : "N/A"}</Typography>
          </div>
          <div>
            <PlentiFormLabel>Standard Deviation</PlentiFormLabel>
            <Typography>
              {standardDeviation
                ? secondsToTimeFormat(standardDeviation)
                : "N/A"}
            </Typography>
          </div>
        </div>
        <MessageResponseIntervalBarChart
          data={data}
          standardDeviation={standardDeviation || 0}
          average={average || 0}
        />
      </div>
    </div>
  );
}

export default SmsContentStats;
