import {
  Button,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { useState } from "react";

import ConfirmPayoutDetails from "../reimbursement/confirmPayoutDetails";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import { UseUpdateReimbursementMutation } from "../reimbursement/reimbursementContext.types";

function ReimbursementActionButtons({
  id,
  updateReimbursement,
}: {
  id: string;
  updateReimbursement: UseUpdateReimbursementMutation;
}) {
  const { activeReimbursement, createNewReimbursement } =
    useReimbursementContext();
  const [openPopover, setOpenPopover] = useState(false);
  return (
    <div className="flex gap-2">
      <Popover placement="left" open={openPopover} handler={setOpenPopover}>
        <PopoverHandler>
          <Button
            disabled={
              !activeReimbursement?.reimbursement_amount ||
              !activeReimbursement?.payment_account_type
            }
            onClick={() => setOpenPopover(true)}
            size="sm"
            color="green"
            fullWidth={false}
          >
            Send Payout
          </Button>
        </PopoverHandler>
        <PopoverContent className="min-w-fit">
          <ConfirmPayoutDetails setOpenPopover={setOpenPopover} />
        </PopoverContent>
      </Popover>
      <Button
        onClick={() =>
          updateReimbursement({
            id,
            status: "waiting",
          })
        }
        size="sm"
        color="blue"
        fullWidth={false}
      >
        Mark Waiting
      </Button>
      <Button
        onClick={() =>
          updateReimbursement({
            id,
            status: "invalid",
          })
        }
        size="sm"
        color="red"
        fullWidth={false}
      >
        Mark Invalid
      </Button>
      <Button
        onClick={() =>
          updateReimbursement({
            id,
            status: "duplicate",
          })
        }
        size="sm"
        color="yellow"
        fullWidth={false}
      >
        Mark Duplicate
      </Button>
      <Button
        disabled={!activeReimbursement?.reimbursement_amount}
        onClick={() =>
          updateReimbursement({
            id,
            status: "paid",
            payment_sent: true,
          })
        }
        size="sm"
        color="purple"
        fullWidth={false}
      >
        Mark Paid Manually
      </Button>
      <Button
        onClick={() =>
          createNewReimbursement({
            status: "pending",
            lead_id: activeReimbursement?.lead_id,
            ...(activeReimbursement?.type && {
              type: activeReimbursement?.type,
            }),
            ...(activeReimbursement?.payment_account_type && {
              payment_account_type: activeReimbursement?.payment_account_type,
            }),
            ...(activeReimbursement?.payment_account_identifier && {
              payment_account_identifier:
                activeReimbursement?.payment_account_identifier,
            }),
            ...(activeReimbursement?.image_url && {
              image_url: activeReimbursement?.image_url,
            }),
            ...(activeReimbursement?.location_id && {
              location_id: activeReimbursement?.location_id,
            }),
          })
        }
        size="sm"
        color="orange"
        fullWidth={false}
      >
        Create Copy
      </Button>
    </div>
  );
}

export default ReimbursementActionButtons;
