import CompanyContainer from "../components/company/companyContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Company = () => {
  mixpanelTrackEvent("Company page");

  return <CompanyContainer />;
};

export default Company;
