/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Typography } from "@material-tailwind/react";
import { Divider, NumberInput, TextInput } from "@tremor/react";
import { useEffect, useState } from "react";

import EditButton from "../buttons/editButton";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import SetAccountType from "../reimbursement/setAccountType";

import {
  parseCentsToDollars,
  parseDollarsToCents,
} from "../../utils/currencyManipulation";

function PayoutDetails() {
  const { updateReimbursement, activeReimbursement } =
    useReimbursementContext();
  const [isEdit, setIsEdit] = useState(false);
  const [updatedReimbursementAmount, setUpdatedReimbursementAmount] = useState(
    parseCentsToDollars(activeReimbursement?.reimbursement_amount as number) ||
      parseCentsToDollars(activeReimbursement?.payment_amount as number)
  );
  const [updatedPaymentAccountType, setPaymentAccountType] = useState(
    activeReimbursement?.payment_account_type
  );
  const [updatedPayoutAccountIdentifier, setPayoutAccountIdentifier] = useState(
    (activeReimbursement?.payment_account_identifier as string) || ""
  );

  const handleUpdatePaymentAccountType = (value: string) => {
    setPaymentAccountType(value);
    setIsEdit(true);
  };

  function handleUpdateReimbursement() {
    let parsedReimbursementAmount;

    if (updatedReimbursementAmount) {
      parsedReimbursementAmount = parseDollarsToCents(
        updatedReimbursementAmount
      );
    }

    updateReimbursement({
      id: activeReimbursement?.id as string,
      ...(parsedReimbursementAmount && {
        reimbursement_amount: parsedReimbursementAmount,
      }),
      ...(updatedPaymentAccountType && {
        payment_account_type: updatedPaymentAccountType,
      }),
      ...(updatedPayoutAccountIdentifier && {
        payment_account_identifier: updatedPayoutAccountIdentifier,
      }),
    });
    setIsEdit(false);
  }

  useEffect(() => {
    if (updatedPaymentAccountType) {
      if (updatedPaymentAccountType === "Venmo") {
        setPayoutAccountIdentifier(activeReimbursement?.lead?.phone as string);
      } else {
        setPayoutAccountIdentifier(
          (activeReimbursement?.payment_account_identifier as string) || ""
        );
      }
    }
  }, [updatedPaymentAccountType]);

  return (
    <div className="w-full">
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Payout</Typography>
          <EditButton
            iconClass="h-4 w-4"
            text="Edit payout"
            onClick={() => {
              setPaymentAccountType(activeReimbursement?.payment_account_type);
              setIsEdit(!isEdit);
            }}
          />
        </div>
        <Divider className="my-1" />

        <div className="my-4 grid w-full grid-cols-2 gap-10">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-xs text-gray-500">
                AI determined payout amount
              </p>
              <p className="text-slate-700">
                {parseCentsToDollars(
                  activeReimbursement?.payment_amount as number
                )}
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <SetAccountType
                updatedPaymentAccountType={updatedPaymentAccountType as string}
                setPaymentAccountType={(value) =>
                  handleUpdatePaymentAccountType(value)
                }
              />
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-xs text-gray-500">Actual payout amount</p>
              {!isEdit ? (
                <p className="text-slate-700">
                  {parseCentsToDollars(
                    activeReimbursement?.reimbursement_amount as number
                  )}
                </p>
              ) : (
                <NumberInput
                  value={updatedReimbursementAmount}
                  placeholder={updatedReimbursementAmount}
                  onChange={(e) => {
                    setUpdatedReimbursementAmount(e.target.value);
                  }}
                  enableStepper={false}
                />
              )}
            </div>

            <div>
              <p className="text-xs text-gray-500">Payout account handle</p>
              {!isEdit ? (
                <p className="text-slate-700">
                  {activeReimbursement?.payment_account_identifier || "N/A"}
                </p>
              ) : (
                <TextInput
                  placeholder="N/A"
                  value={updatedPayoutAccountIdentifier}
                  onChange={(e) => setPayoutAccountIdentifier(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>

        {isEdit && (
          <div className="mt-6 flex justify-end gap-2">
            <Button
              size="sm"
              variant="outlined"
              color="gray"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="green"
              fullWidth={false}
              disabled={!isEdit}
              onClick={handleUpdateReimbursement}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PayoutDetails;
