import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { useAlertContext } from "../alert/alertContext";
import {
  UseCreateCampaignMutation,
  UseCreateTestWebhookMutation,
  UseDuplicateCampaignMutation,
  UseGetCampaignMutation,
  UseUpdateCampaignMutation,
} from "../campaign/campaignContext.types";
import { useCompanyContext } from "../company/companyContext";
import { findCompanyById } from "../company/companyHelpers";

import { useNavigate } from "react-router-dom";
import {
  createCampaign,
  createDraftCampaign,
  duplicateCampaign,
  getCampaignById,
  updateCampaignApiCall,
} from "../../api/campaign.api";
import { CampaignDraftType, CampaignRow } from "../../api/campaign.type";
import { CompanyRow } from "../../api/company.type";
import { createWebhookTest } from "../../api/lead.api";
import { useUserContext } from "../user/userContext";
import { useLoadCampaigns } from "./hooks/useLoadCampaigns";
import { useLoadTemplateCampaigns } from "./hooks/useLoadTemplateCampaigns";

export const CampaignContextWrapper = (props: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUserContext();
  const [activeCampaign, setActiveCampaign] = useState<CampaignRow | null>(
    null
  );

  const { companies, selectedCompany } = useCompanyContext();
  const [associatedCompany, setAssociatedCompany] = useState<CompanyRow>();

  const { setAlertProps } = useAlertContext();
  const { campaigns } = useLoadCampaigns();
  const { templateCampaigns } = useLoadTemplateCampaigns();

  const { mutate: getCampaign } = useMutation(getCampaignById, {
    onSuccess: async (res) => {
      setActiveCampaign(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: createDraft, isLoading: draftLoading } = useMutation(
    createDraftCampaign,
    {
      onSuccess: async (res) => {
        setAlertProps({
          message: `${res.name} campaign updated`,
          color: "green",
        });
        queryClient.invalidateQueries("campaigns");
        navigate(`/campaign/${res.id}`);
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  const handleCreateDraft = async ({ type }: { type: CampaignDraftType }) => {
    if (user?.type === "admin" && !selectedCompany) {
      setAlertProps({
        message: `Please select a company`,
        color: "yellow",
      });
      return;
    }
    createDraft({
      companyId: selectedCompany?.id,
      type,
    });
  };

  const { isLoading, mutate: updateCampaign } = useMutation(
    updateCampaignApiCall,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("campaigns");
        setActiveCampaign(res);
        setAlertProps({
          message: `${res.name} campaign updated`,
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  const useCreateCampaign = useMutation(createCampaign, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("campaign");
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useTestCampaignWebhook = useMutation(createWebhookTest, {
    onSuccess: async (res) => {
      console.log(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useDuplicateCampaign = useMutation(duplicateCampaign, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("campaign");
      setAlertProps({
        message: `${res.name} campaign duplicated`,
        color: "green",
      });
      navigate(`/campaign/${res.id}`);
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (activeCampaign) {
      if (companies && activeCampaign?.company_id) {
        setAssociatedCompany(
          findCompanyById(companies, activeCampaign?.company_id)
        );
      }
    }
  }, [activeCampaign, activeCampaign?.system_prompt, companies]);

  useEffect(() => {
    if (user && user.type === "standard") {
      setAssociatedCompany(user.users_companies[0].company_id);
    }
  }, [user]);

  const value = {
    campaigns,
    loading: isLoading || draftLoading,
    updateCampaign,
    useCreateCampaign,
    useTestCampaignWebhook,
    activeCampaign,
    getCampaign,
    associatedCompany,
    useDuplicateCampaign,
    handleCreateDraft,
    templateCampaigns,
  };

  return (
    <CampaignContext.Provider value={value}>
      {props.children}
    </CampaignContext.Provider>
  );
};

export const CampaignContext = createContext({
  campaigns: [] as CampaignRow[] | undefined,
  templateCampaigns: [] as CampaignRow[] | undefined,
  loading: false,
  updateCampaign: {} as UseUpdateCampaignMutation,
  useCreateCampaign: {} as UseCreateCampaignMutation,
  useTestCampaignWebhook: {} as UseCreateTestWebhookMutation,
  activeCampaign: {} as CampaignRow | null,
  getCampaign: {} as UseGetCampaignMutation,
  associatedCompany: {} as CompanyRow | undefined,
  useDuplicateCampaign: {} as UseDuplicateCampaignMutation,
  handleCreateDraft: {} as ({
    type,
  }: {
    type: CampaignDraftType;
  }) => Promise<void>,
});

export const useCampaignContext = () => useContext(CampaignContext);
