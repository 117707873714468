/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import { useState } from "react";
import EditButton from "../buttons/editButton";
import {
  List,
  ShareIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
} from "../icons/icons";
import CampaignTypeDetails, { ReimbursementType } from "./CampaignTypeDetails";
import { useReimbursementContext } from "./reimbursementContext";
import GenericSelect from "../forms/GenericSelect";

const reimbursementType: ReimbursementType[] = [
  {
    name: "Product",
    type: "product_purchase",
    color: "amber",
    icon: <ShoppingCartIcon className="h-6 w-6" />,
    explanation: "Reimbursement for a product purchase",
  },
  {
    name: "Loyalty",
    type: "loyalty",
    color: "blue",
    icon: <List className="h-6 w-6" />,
    explanation:
      "Most loyalty campaigns require multiple purchases. Check to see if the lead has made the required purchases.",
  },
  {
    name: "Merchandising",
    type: "merchandising",
    color: "green",
    icon: <ShoppingBagIcon className="h-6 w-6" />,
    explanation:
      "Merchandising campaigns require the lead to take a picture of a product in a store and answer several questions.",
  },
  {
    name: "Referral",
    type: "referral",
    color: "purple",
    icon: <ShareIcon className="h-6 w-6" />,
    explanation:
      "Leads are rewarded for referring friends and family to the campaign. This a reimbursement for a lead that referred someone. We need to verify the referred person lead was reimbursed.",
  },
];

function ViewReimbursementType() {
  const { activeReimbursement, updateReimbursement } =
    useReimbursementContext();
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState<string>("");

  function handleSelected(value: string) {
    updateReimbursement({
      id: activeReimbursement?.id as string,
      type: value,
    });
    setSelected(value);
    setIsEdit(false);
  }

  return (
    <div className="w-full">
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Type</Typography>
          <EditButton
            iconClass="h-4 w-4"
            text="Edit Type"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          />
        </div>
        <Divider className="my-2" />
        <div className="flex justify-between">
          <div className="flex flex-col mb-2">
            <div className="flex flex-row gap-4 ">
              {!isEdit ? (
                activeReimbursement &&
                reimbursementType
                  .filter((t) => {
                    return t.type === activeReimbursement?.type;
                  })
                  .map((t) => {
                    return (
                      <CampaignTypeDetails
                        reimbursementType={t}
                        activeReimbursement={activeReimbursement}
                      />
                    );
                  })
              ) : (
                <GenericSelect
                  values={reimbursementType.map((t) => {
                    return t.type;
                  })}
                  selected={selected}
                  setSelected={handleSelected}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewReimbursementType;
