import React from "react";

import AuthSignUpSignIn from ".";
import CheezyPoofs from "../cheezyPoofs/cheezyPoofs";
import LoginLayout from "../layout/LoginLayout";

const LoginPage: React.FC = () => {
  return (
    <LoginLayout>
      <AuthSignUpSignIn />
      <div className="hidden md:block ">
        <CheezyPoofs />
      </div>
    </LoginLayout>
  );
};

export default LoginPage;
