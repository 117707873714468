/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation } from "react-query";
import { CampaignRow } from "../../api/campaign.type";
import { getCompanyConfigById } from "../../api/company.api";
import { CompanyConfigRow, CompanyRow } from "../../api/company.type";

export const TryContextWrapper = ({
  company,
  children,
  forDemo = false,
  demoCampaign,
}: {
  company?: CompanyRow;
  children: ReactElement;
  demoCampaign?: CampaignRow | null;
  forDemo?: boolean;
}) => {
  const [campaign, setCampaign] = useState<CampaignRow>();
  const [companyConfig, setCompanyConfig] = useState<CompanyConfigRow>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const useGetCompanyConfig = useMutation(getCompanyConfigById, {
    onSuccess: async (res) => {
      setCompanyConfig(res);
    },
  });

  useEffect(() => {
    if (company) {
      useGetCompanyConfig.mutate(company.id);
    }
  }, [company]);

  const value = {
    companyConfig,
    campaign,
    company,
    setIsSubmitting,
    isSubmitting,
    forDemo,
  };

  useEffect(() => {
    if (demoCampaign) {
      setCampaign(demoCampaign);
    }
  }, [demoCampaign]);

  return <TryContext.Provider value={value}>{children}</TryContext.Provider>;
};

export const TryContext = createContext({
  companyConfig: {} as CompanyConfigRow | undefined,
  company: {} as CompanyRow | undefined,
  campaign: {} as CampaignRow | undefined,
  setIsSubmitting: {} as React.Dispatch<React.SetStateAction<boolean>>,
  isSubmitting: false as boolean,
  forDemo: false as boolean,
});

export const useTryContext = () => useContext(TryContext);
