/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";

import {
  AddBulkLeads,
  UseGetLead,
  UseUpdateLead,
} from "../../api/leadContext.types";

import {
  addBulkLeads,
  getLeadById,
  searchLeads,
  updateLead,
} from "../../api/lead.api";
import { LeadWithMessageCount, LeadWithRelations } from "../../api/lead.type";
import { createLeadCampaign } from "../../api/leadCampaign.api";
import { LeadCampaignRow } from "../../api/leadCampaign.type";
import { useAlertContext } from "../alert/alertContext";

export const LeadContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const [activeLead, setActiveLead] = useState<LeadWithRelations>();
  const [searchValue, setSearchValue] = useState("");
  const [searchLeadList, setSearchLeadList] = useState<LeadWithMessageCount[]>(
    []
  );
  const [searchMessage, setSearchMessage] = useState<string>("");

  const { isLoading, mutate } = useMutation(searchLeads, {
    onSuccess: (data) => {
      setSearchLeadList(data.data);
    },
    onError: (error: any) => {
      setSearchMessage("No leads found matching that search criteria.");
    },
  });

  const useGetLeadById = useMutation(getLeadById, {
    onSuccess: (data) => {
      setActiveLead(data);
    },
  });

  const { mutate: updateLeadWithValues } = useMutation(updateLead, {
    onSuccess: async (res) => {
      useGetLeadById.mutate(res.id);
      setAlertProps({
        message: `Lead ${res.phone} updated`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: confirmBulkData } = useMutation(addBulkLeads, {
    onSuccess: async (res) => {
      setAlertProps({
        message: `Successfully added leads. This may take a couple min to update.`,
        color: "green",
      });
    },
    onError: async (err: any) => {
      setAlertProps({
        color: "red",
        message: err.message,
      });
    },
  });

  const { mutate: addLeadToCampaign } = useMutation(createLeadCampaign, {
    onSuccess: async (res) => {
      setAlertProps({
        message: `Successfully add lead to campaign.`,
        color: "green",
      });

      useGetLeadById.mutate(res.lead_id);
    },
    onError: async (err: any) => {
      setAlertProps({
        color: "red",
        message: err.message,
      });
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (searchValue) {
        mutate(searchValue);
      }
    }, 2000);

    if (!searchValue) {
      setSearchLeadList([]);
      setSearchMessage("");
    }
  }, [searchValue]);

  const value = {
    useGetLeadById,
    activeLead,
    searchLeadList,
    setSearchValue,
    searchValue,
    isLoading,
    searchMessage,
    updateLeadWithValues,
    confirmBulkData,
    addLeadToCampaign,
  };

  return (
    <LeadContext.Provider value={value}>{props.children}</LeadContext.Provider>
  );
};

export const LeadContext = createContext({
  useGetLeadById: {} as UseGetLead,
  activeLead: {} as LeadWithRelations | undefined,
  setSearchValue: {} as any,
  searchValue: "" as string,
  searchLeadList: [] as LeadWithMessageCount[],
  isLoading: false as boolean,
  searchMessage: "" as string,
  updateLeadWithValues: {} as UseUpdateLead,
  confirmBulkData: {} as AddBulkLeads,
  addLeadToCampaign: {} as UseMutateFunction<
    LeadCampaignRow,
    any,
    {
      leadId: string;
      campaignId: string;
    },
    unknown
  >,
});

export const useLeadContext = () => useContext(LeadContext);
