import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

function CampaignSchemaAddNewHeader() {
  return (
    <div>
      <div className="flex flex-row justify-between gap-2 ">
        <Typography variant="h6">Campaign's data to extract</Typography>
      </div>
      <Divider className="my-1" />
    </div>
  );
}

export default CampaignSchemaAddNewHeader;
