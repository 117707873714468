import { useNavigate } from "react-router-dom";

import { useCompanyContext } from "../company/companyContext";
import CompanyTableList from "../company/companyTableList";
import TableWithSearch from "../table/tableWithSearch";

function AdminCompanyView() {
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { label: "Name" },
    { label: "Created at" },
    { label: "Slug" },
    { label: "Demo Link" },
    { label: "Try link" },
    { label: "Edit" },
  ];
  const { companies } = useCompanyContext();

  function handleAddClick() {
    navigate(`/company/add`);
  }

  function handleContentClick(id: string) {
    navigate(`/company/${id}`);
  }

  return (
    <div>
      <TableWithSearch
        tableHead={TABLE_HEAD}
        type="company"
        rowCount={companies ? companies?.length : 0}
        handleAddClick={handleAddClick}
        rowsPerPage={10}
      >
        <CompanyTableList
          companies={companies}
          handleContentClick={handleContentClick}
        />
      </TableWithSearch>
    </div>
  );
}

export default AdminCompanyView;
