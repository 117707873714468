/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { useFabrkCampaignContext } from "./FabrkCampaignContext";
import {
  createTestSms,
  getNewSmsMessagesByLeadForDemo,
  getSmsMessagesByLead,
} from "../../../api/sms.api";
import { useAlertContext } from "../../alert/alertContext";
import { SmsMessage } from "../../../api/sms.type";

export const FabrkMessageContextWrapper = ({
  leadId,
  children,
}: {
  leadId?: string;
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();

  const [filteredMessages, setFilteredMessages] = useState<SmsMessage[]>([]);

  const [newThread, setNewThread] = useState(false);
  const [userMessages, setUserMessages] = useState<SmsMessage[]>([]);
  const [assistantMessages, setAssistantMessages] = useState<SmsMessage[]>([]);

  const [loading, setLoading] = useState(false);

  const { activeLeadCampaign } = useFabrkCampaignContext();
  const [messages, setMessages] = useState<SmsMessage[]>([]);

  const { mutate: createMessage } = useMutation(createTestSms, {
    onMutate: async (variables) => {
      const newMessage = {
        id: "0",
        leadId: variables.leadId,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        content: variables.message,
        role: "user",
        thread_id: variables.threadId,
      } as SmsMessage;
      setMessages([...messages, newMessage]);
    },
    onSuccess: async (res) => {
      setNewThread(false);
    },
    onError(error: Error) {},
  });

  const { data: newMessages } = useQuery({
    queryKey: "newMessagesByLead",
    queryFn: () =>
      getNewSmsMessagesByLeadForDemo({
        leadId: leadId || "",
        messages: messages,
      }),
    enabled: loading,
    refetchInterval: 3000,
  });

  function handleCreateMessage(content: string) {
    setLoading(true);
    if (!activeLeadCampaign) {
      setAlertProps({
        message: "No active campaign",
        color: "red",
      });
      setLoading(false);
      return;
    }

    const assistantMessages = messages.filter(
      (msg) => msg.role === "assistant"
    );

    const threadId =
      !newThread && assistantMessages.length > 0
        ? assistantMessages[assistantMessages.length - 1]?.thread_id
        : undefined;

    createMessage({
      campaignId: activeLeadCampaign.leadCampaign.campaign_id,
      companyId: activeLeadCampaign.leadCampaign.company_id,
      leadId: leadId as string,
      message: content,
      source: "fabrk",
      ...(threadId && { threadId }),
    });
  }

  const { mutate: getMessages } = useMutation(getSmsMessagesByLead, {
    onSuccess: async (res) => {
      setMessages(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (leadId) {
      getMessages({ leadId });
    }
  }, [leadId]);

  useEffect(() => {
    if (newMessages) {
      setMessages(newMessages);
      setLoading(false);
    }
  }, [newMessages]);

  useEffect(() => {
    if (messages) {
      setUserMessages(messages.filter((msg) => msg.role === "user"));
      setAssistantMessages(messages.filter((msg) => msg.role === "assistant"));
      if (!filteredMessages || filteredMessages.length === 0) {
        setFilteredMessages(messages);
      }
    }
  }, [messages]);

  const value = {
    messages,
    handleCreateMessage,
    loading,
    setMessages,
    setNewThread,
    userMessages,
    assistantMessages,
    setFilteredMessages,
    filteredMessages,
  };

  return (
    <FabrkMessageContext.Provider value={value}>
      {children}
    </FabrkMessageContext.Provider>
  );
};

export const FabrkMessageContext = createContext({
  userMessages: [] as SmsMessage[] | undefined,
  assistantMessages: [] as SmsMessage[] | undefined,
  messages: {} as SmsMessage[] | undefined,
  handleCreateMessage: {} as (content: string) => void,
  loading: false,
  setMessages: {} as React.Dispatch<React.SetStateAction<SmsMessage[]>>,
  setNewThread: {} as React.Dispatch<React.SetStateAction<boolean>>,
  setFilteredMessages: {} as React.Dispatch<React.SetStateAction<SmsMessage[]>>,
  filteredMessages: [] as SmsMessage[] | undefined,
});

export const useFabrkMessageContext = () => useContext(FabrkMessageContext);
