import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  CampaignSmsContentRow,
  UpdateCampaignSmsContent,
} from "../api/campaignSmsContent.type";

export function getCampaignSmsContent(): Promise<CampaignSmsContentRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaignSmsContent;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CampaignSmsContentRow[]>(url, request);
}

export function updateDefaultCampaignSmsContent({
  smsContentId,
  campaignId,
  emailTemplateId,
  companyId,
  isAiGenerated,
}: {
  smsContentId?: string;
  campaignId: string;
  emailTemplateId?: string;
  companyId: string;
  isAiGenerated?: boolean;
}): Promise<CampaignSmsContentRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignSmsContent +
    "/default";

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify({
      ...(smsContentId && { smsContentId }),
      ...(emailTemplateId && { emailTemplateId }),
      ...(isAiGenerated && { isAiGenerated }),
      campaignId,
      companyId,
    }),
  });

  return fetchData<CampaignSmsContentRow>(url, request);
}

export function updateCampaignSmsContent(
  rows: UpdateCampaignSmsContent[]
): Promise<CampaignSmsContentRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.campaignSmsContent + "/";

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(rows),
  });

  return fetchData<CampaignSmsContentRow[]>(url, request);
}

export function createCampaignSmsContent(
  values: Partial<CampaignSmsContentRow>
): Promise<CampaignSmsContentRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaignSmsContent;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({
      ...values,
    }),
  });

  return fetchData<CampaignSmsContentRow>(url, request);
}

export function aiGenerateCampaignSmsContent({
  campaignId,
  count,
}: {
  campaignId: string;
  count: number;
}): Promise<string> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignSmsContent +
    "/generate/" +
    campaignId;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({
      count,
    }),
  });

  return fetchData<string>(url, request);
}

export function deleteCampaignSmsContent({
  campaignSmsContentId,
}: {
  campaignSmsContentId: string;
}): Promise<CampaignSmsContentRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignSmsContent +
    "/" +
    campaignSmsContentId;

  const request = setHeaderOptions({
    method: "DELETE",
  });

  return fetchData<CampaignSmsContentRow>(url, request);
}
