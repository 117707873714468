/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  createCampaignExtractionSchema,
  createExtractionSchema,
  deleteCampaignExtractionSchema,
  getExtractionSchemaCampaigns,
  getExtractionSchemas,
} from "../../api/extractionSchema.api";
import {
  ExtractionSchemaCampaignRow,
  ExtractionSchemaRow,
} from "../../api/extractionSchema.type";
import { useAlertContext } from "../alert/alertContext";
import {
  CreateCampaignExtractionSchema,
  CreateSchema,
  DeleteCampaignExtractionSchema,
} from "./ExtractionSchema.type";

export const ExtractionSchemaContextWrapper = ({
  campaignId,
  children,
}: {
  campaignId?: string;
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();
  const { data: schema, refetch } = useQuery({
    queryKey: "extractionSchema",
    queryFn: () => getExtractionSchemas(),
  });

  const [extractionSchemaCampaigns, setExtractionSchemaCampaigns] =
    useState<ExtractionSchemaCampaignRow[]>();

  const { mutate: addSchemaToCampaign } = useMutation(
    createCampaignExtractionSchema,
    {
      onSuccess: (data) => {
        getCampaignSchema(campaignId as string);
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error fetching the schema: " + error.message,
          color: "red",
        });
      },
    }
  );

  const { mutate: getCampaignSchema } = useMutation(
    getExtractionSchemaCampaigns,
    {
      onSuccess: (data) => {
        setExtractionSchemaCampaigns(data);
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error fetching the schema: " + error.message,
          color: "red",
        });
      },
    }
  );

  const { mutate: removeFromCampaign } = useMutation(
    deleteCampaignExtractionSchema,
    {
      onSuccess: (data) => {
        getCampaignSchema(campaignId as string);
        setAlertProps({
          message: "Schema removed successfully!",
          color: "green",
        });
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error deleting the schema: " + error.message,
          color: "red",
        });
      },
    }
  );

  const { mutate: createSchema } = useMutation(createExtractionSchema, {
    onSuccess: () => {
      refetch();
      setAlertProps({
        message: "Schema created successfully!",
        color: "green",
      });

      if (campaignId) {
        getCampaignSchema(campaignId);
      }
    },
    onError: (error: any) => {
      setAlertProps({
        message: "There was an error creating the schema: " + error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (campaignId) {
      getCampaignSchema(campaignId);
    }
  }, [campaignId]);

  const value = {
    schema,
    createSchema,
    extractionSchemaCampaigns,
    addSchemaToCampaign,
    removeFromCampaign,
  };

  return (
    <ExtractionSchemaContext.Provider value={value}>
      {children}
    </ExtractionSchemaContext.Provider>
  );
};

export const ExtractionSchemaContext = createContext({
  schema: {} as ExtractionSchemaRow[] | undefined,
  createSchema: {} as CreateSchema,
  extractionSchemaCampaigns: {} as ExtractionSchemaCampaignRow[] | undefined,
  addSchemaToCampaign: {} as CreateCampaignExtractionSchema,
  removeFromCampaign: {} as DeleteCampaignExtractionSchema,
});

export const useExtractionSchemaContext = () =>
  useContext(ExtractionSchemaContext);
