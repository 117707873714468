import { Card, CardBody, Typography } from "@material-tailwind/react";
import { RocketIcon } from "../icons/icons";

function AgentCard() {
  const muscleEmoji = String.fromCodePoint(0x1f4aa);

  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <RocketIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Plenti AI Agents
        </Typography>
        <Typography>
          Plenti AI Agents give your marketing outreach superpowers{" "}
          {muscleEmoji}. Agents let you feel like you're everywhere at once, and
          they never get tire of giving your customers a great experience.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default AgentCard;
