import React from "react";
import ReactDOM from "react-dom/client";

import { ThemeProvider } from "@material-tailwind/react";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AlertContextWrapper } from "./components/alert/alertContext";
import { AuthContextWrapper } from "./components/auth/authContext";
import { CompanyContextWrapper } from "./components/company/companyContext";
import { MessageContextWrapper } from "./components/lead/messageContext";
import { SmsContentContextWrapper } from "./components/smsContent/smsContentContext";
import { UserContextWrapper } from "./components/user/userContext";
import reportWebVitals from "./reportWebVitals";

const theme = {
  select: {
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-0",
        },
      },
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

const url = process.env.REACT_APP_SUPABASE_URL as string;
const key = process.env.REACT_APP_SUPABASE_ANON_KEY as string;

const supabase = createClient(url, key);

root.render(
  <React.StrictMode>
    <ThemeProvider value={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <SessionContextProvider supabaseClient={supabase}>
            <AuthContextWrapper>
              <UserContextWrapper>
                <AlertContextWrapper>
                  <CompanyContextWrapper>
                    <MessageContextWrapper>
                      <SmsContentContextWrapper>
                        <App />

                        <link
                          rel="preconnect"
                          href="https://fonts.googleapis.com"
                        />
                        <link
                          rel="preconnect"
                          href="https://fonts.gstatic.com"
                        />
                        <link
                          href="https://fonts.googleapis.com/css2?family=Gothic+A1:wght@800&display=swap"
                          rel="stylesheet"
                        />
                      </SmsContentContextWrapper>
                    </MessageContextWrapper>
                  </CompanyContextWrapper>
                </AlertContextWrapper>
              </UserContextWrapper>
            </AuthContextWrapper>
          </SessionContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
