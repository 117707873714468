import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import ConversationsContainer from "../components/lead/conversationsContainer";
import { LeadContextWrapper } from "../components/lead/leadContext";
import { MessageOverviewContextWrapper } from "../components/lead/messageOverviewContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Leads = () => {
  mixpanelTrackEvent("Leads page");

  return (
    <CampaignContextWrapper>
      <LeadContextWrapper>
        <MessageOverviewContextWrapper>
          <ConversationsContainer />
        </MessageOverviewContextWrapper>
      </LeadContextWrapper>
    </CampaignContextWrapper>
  );
};

export default Leads;
