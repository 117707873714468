/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddAiCampaignFunctionMutation,
  GetCampaignAiFunctionsByCampaignMutation,
  InformLeadsOfNewAgentMutation,
  UpdateCampaignAiFunctionMutation,
} from "@/components/campaignAiFunction/campaignAiFunctionContext.type";
import {
  addAiCampaignFunction,
  getCampaignAiFunctionsByCampaign,
  informLeadsOfNewAgent,
  updateAiCampaignFunction,
} from "../../api/campaignAiFunction.api";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { CampaignAiFunctionRow } from "../../api/campaignAiFunction.type";
import { useAlertContext } from "../alert/alertContext";
import { useCampaignContext } from "../campaign/campaignContext";

export const CampaignAiFunctionContextWrapper = (props: any) => {
  const { activeCampaign } = useCampaignContext();
  const { setAlertProps } = useAlertContext();
  const [campaignAiFunctions, setCampaignAiFunctions] = useState<any[]>([]);

  const useGetCampaignAiFunctionByCampaign = useMutation(
    getCampaignAiFunctionsByCampaign,
    {
      onSuccess: async (res) => {
        setCampaignAiFunctions(res);
      },
      onError: async (err: any) => {
        console.log(err);
      },
    }
  );

  const { mutate: updateCampaignAiFunction, isLoading: updateLoading } =
    useMutation(updateAiCampaignFunction, {
      onSuccess: async (res) => {
        useGetCampaignAiFunctionByCampaign.mutate(activeCampaign?.id as string);
        setAlertProps({
          message: "AI agent updated",
          color: "green",
        });
      },
      onError: async (err: any) => {
        console.log(err);
        setAlertProps({
          message: `AI agent update failed ${err.message}`,
          color: "red",
        });
      },
    });

  const { mutate: addFunction, isLoading: addLoading } = useMutation(
    addAiCampaignFunction,
    {
      onSuccess: async (res) => {
        setCampaignAiFunctions(res);
        setAlertProps({
          message: "AI agent added",
          color: "green",
        });
      },
      onError: async (err: any) => {
        console.log(err);
        setAlertProps({
          message: `Adding AI agent failed ${err.message}`,
          color: "red",
        });
      },
    }
  );

  const { mutate: informLeads } = useMutation(informLeadsOfNewAgent);

  useEffect(() => {
    if (activeCampaign && campaignAiFunctions?.length === 0) {
      useGetCampaignAiFunctionByCampaign.mutate(activeCampaign?.id);
    }
  }, [activeCampaign]);

  const value = {
    useGetCampaignAiFunctionByCampaign,
    campaignAiFunctions,
    addFunction,
    loading: updateLoading || addLoading,
    updateCampaignAiFunction,
    informLeads,
  };

  return (
    <CampaignAiFunctionContext.Provider value={value}>
      {props.children}
    </CampaignAiFunctionContext.Provider>
  );
};

export const CampaignAiFunctionContext = createContext({
  useGetCampaignAiFunctionByCampaign:
    {} as GetCampaignAiFunctionsByCampaignMutation,
  campaignAiFunctions: [] as CampaignAiFunctionRow[],
  addFunction: {} as AddAiCampaignFunctionMutation,
  updateCampaignAiFunction: {} as UpdateCampaignAiFunctionMutation,
  informLeads: {} as InformLeadsOfNewAgentMutation,
});

export const useCampaignAiFunctionContext = () =>
  useContext(CampaignAiFunctionContext);
