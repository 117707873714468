import { useState } from "react";
import GenericDialog from "../../dialog/genericDialog";
import AiAddSmsContentContainer from "./aiAddSmsContent";
import { useFollowUpContext } from "./followUpMessageContext";
import SelectFollowUp from "./selectFollowupMax";

const followUpMax = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "Unlimited",
];

const startingDayOptions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const increasing = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

function FollowUpSelect() {
  const {
    startingDay,
    setStartingDay,
    selectedMax,
    setSelectedMax,
    selectedIncreasing,
    setSelectedIncreasing,
    handleAiGenerateContentSubmit,
  } = useFollowUpContext();

  const [open, setOpen] = useState(false);
  return (
    <div className="my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-2 place-items-center  ">
      <SelectFollowUp
        value={startingDay}
        handleSelected={setStartingDay}
        label="Starting on day:"
        options={startingDayOptions}
      />

      <SelectFollowUp
        value={selectedIncreasing}
        handleSelected={setSelectedIncreasing}
        label="Increasing by"
        options={increasing}
      />

      <SelectFollowUp
        value={selectedMax}
        handleSelected={setSelectedMax}
        label="Max"
        options={followUpMax}
      />
      <GenericDialog title="Add Message Content?" open={open} setOpen={setOpen}>
        <AiAddSmsContentContainer
          handleSubmit={handleAiGenerateContentSubmit}
          handleNo={() => setOpen(false)}
        />
      </GenericDialog>
    </div>
  );
}

export default FollowUpSelect;
