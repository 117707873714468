import { Checkbox, Typography } from "@material-tailwind/react";
import { useState } from "react";
import GenericSelect from "../forms/GenericSelect";
import { useLocationContext } from "./locationContext";

function ChainDisplay({
  data,
  setSelectedChain,
  selectedChain,
  setChainConfirmed,
}: {
  data: any;
  setSelectedChain: any;
  selectedChain: any;
  setChainConfirmed: any;
}) {
  const isChainColumn = data["chain"] ? true : false;
  const [isOneChain, setIsOneChain] = useState<boolean | null>(null);

  const { chains } = useLocationContext();

  return (
    <div className="flex flex-col gap-5">
      <div>
        <Typography variant="h5">Chain</Typography>

        <Typography variant="paragraph">
          {isChainColumn
            ? "It looks like there is a chain column. This will be used to link the data to the correct chain."
            : "No chain column found. Is all the data in this file for one chain?"}
        </Typography>
      </div>
      <div>
        <Checkbox
          color="green"
          checked={!!isOneChain}
          crossOrigin={true}
          onChange={() => {
            if (isOneChain === null) {
              setIsOneChain(true);
            } else {
              setIsOneChain(null);
              setChainConfirmed(false);
            }
          }}
          label="Yes"
        />
        <Checkbox
          color="green"
          checked={isOneChain === false}
          crossOrigin={true}
          onChange={() => {
            if (isOneChain === null) {
              setIsOneChain(false);
              setChainConfirmed(true);
            } else {
              setIsOneChain(null);
              setChainConfirmed(false);
            }
          }}
          label="No"
        />
      </div>
      {isOneChain && (
        <div>
          <Typography variant="paragraph">
            Which chain is this data for?
          </Typography>
          <GenericSelect
            values={chains?.map((c) => c.name)}
            setSelected={setSelectedChain}
            selected={selectedChain}
          />
        </div>
      )}
    </div>
  );
}

export default ChainDisplay;
