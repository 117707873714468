import BillingContainer from "../components/billing/billingContainer";
import { BillingContextWrapper } from "../components/billing/billingContext";
import { CustomerContextWrapper } from "../components/billing/customerContex";
import { PaymentContextWrapper } from "../components/billing/hooks/PaymentContext";
import { StripeContextWrapper } from "../components/stripe/stripeContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Billing = () => {
  mixpanelTrackEvent("Billing page");

  return (
    <CustomerContextWrapper>
      <PaymentContextWrapper>
        <StripeContextWrapper>
          <BillingContextWrapper>
            <BillingContainer />
          </BillingContextWrapper>
        </StripeContextWrapper>
      </PaymentContextWrapper>
    </CustomerContextWrapper>
  );
};

export default Billing;
