import { useTryContext } from "../try/tryContext";

function TryTitle() {
  const { companyConfig, campaign, forDemo } = useTryContext();
  const font = campaign?.title_font?.tailwind_name || "sans";
  const fontWeight = campaign?.title_font_weight || 900;
  return (
    <h1
      className={
        forDemo
          ? `mx-auto max-w-[400px] p-2 text-center text-4xl font-extrabold font-${font}`
          : `mx-auto max-w-[400px] p-2 text-center text-4xl font-extrabold md:max-w-[700px] md:text-6xl font-${font}`
      }
      style={{
        color: companyConfig?.color_two || "#000",
        fontStyle: "normal !important",
        fontWeight,
      }}
    >
      {campaign && campaign.status !== "active"
        ? "This campaign has been paused"
        : campaign?.title
        ? campaign.title
        : `Get Free ${companyConfig?.product_name || "product"}, On Us`}
    </h1>
  );
}

export default TryTitle;
