/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Chip,
  Typography,
} from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateCompanyAiFunction } from "../../api/companyAiFunction.api";
import { useAlertContext } from "../alert/alertContext";
import { useCompanyContext } from "../company/companyContext";
import { DollarIcon } from "../icons/icons";
import { AgentRow, agentList } from "./agentList";
import { useLoadCompanyAiFunction } from "./hooks/useLoadCompanyAiFunction";
import { useNavigate } from "react-router-dom";

type AgentAccordionProps = AgentRow & {
  isOpen: boolean;
  handleOpen: () => void;
};

function AgentAccordion() {
  const { setAlertProps } = useAlertContext();
  const navigate = useNavigate();
  const { selectedCompany } = useCompanyContext();
  const { data, refetch } = useLoadCompanyAiFunction({
    companyId: selectedCompany?.id as string,
  });
  const [merchStatus, setMerchStatus] = useState<string>("disabled");

  const [list, setList] = useState<AgentAccordionProps[]>();

  const useUpdateCompanyAiFunction = useMutation(updateCompanyAiFunction, {
    onSuccess: async (res) => {
      setMerchStatus(res.is_active ? "enabled" : "disabled");
      setAlertProps({
        message: `Agent status updated`,
        color: "green",
      });
      refetch();
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function mapList(agent: AgentRow, index: number): AgentAccordionProps {
    return {
      ...agent,
      isOpen: isOpen[`${index}`] as unknown as boolean,
      handleOpen: () => {
        setIsOpen((prevState) => {
          return { ...prevState, [`${index}`]: !prevState[`${index}`] };
        });
      },
    };
  }

  const initialStates = agentList.map((agent, index) => {
    return { [`${index}`]: true };
  });
  const [isOpen, setIsOpen] = React.useState(initialStates);

  useEffect(() => {
    if (agentList) {
      setList(agentList.map(mapList));
    }
  }, [agentList]);

  return (
    <Fragment>
      {list?.map((agent, index) => {
        const isMerch = agent.name === "Merchandising Agent";
        const merchColor = merchStatus === "enabled" ? "green" : "pink";

        const color =
          agent.status === "enabled"
            ? "green"
            : agent.status === "disabled"
            ? "pink"
            : "yellow";
        return (
          // @ts-ignore
          <Accordion open={isOpen[index]}>
            <AccordionHeader onClick={agent.handleOpen}>
              <div className="grid grid-cols-2 w-full">
                <Typography variant="h4"> {agent.name}</Typography>
                <div className="flex flex-row gap-2">
                  <button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (
                        agent.name === "Merchandising Agent" &&
                        data &&
                        data?.length > 0
                      ) {
                        const currentStatus = data[0].is_active;

                        useUpdateCompanyAiFunction.mutate({
                          id: data[0].id,
                          values: {
                            is_active: !currentStatus,
                          },
                        });
                      }
                    }}
                  >
                    <Chip
                      color={isMerch ? merchColor : color}
                      value={isMerch ? merchStatus : agent.status}
                      className="w-36 text-center"
                    />
                  </button>
                  {agent.name === "Data Extraction Agent" && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (agent.name === "Data Extraction Agent") {
                          navigate(`/agent/extraction`);
                        }
                      }}
                    >
                      <Chip
                        color="deep-purple"
                        value="Details"
                        className="w-36 text-center"
                      />
                    </button>
                  )}
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody>
              <div className="grid grid-cols-3 gap-5">
                <Typography className="col-span-2">
                  {agent.description}
                </Typography>
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <DollarIcon className="h-6 w-6" />
                    <Typography>Cost:</Typography>
                  </div>
                  <div>
                    <Typography>{agent.cost}</Typography>
                  </div>
                </div>
              </div>
            </AccordionBody>
          </Accordion>
        );
      })}
    </Fragment>
  );
}

export default AgentAccordion;
