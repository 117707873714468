import LocationContainer from "../components/location/locationContainer";
import { LocationContextWrapper } from "../components/location/locationContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Locations = () => {
  mixpanelTrackEvent("Locations page");

  return (
    <LocationContextWrapper>
      <LocationContainer />
    </LocationContextWrapper>
  );
};

export default Locations;
