import { Bars3Icon } from "@heroicons/react/24/solid";
import { useState } from "react";
import FabrkThreadMenu from "./FabrkThreadMenu";

function FabrkRightMenuContainer() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="h-full fixed top-0 right-0 z-50">
      <button
        className="lg:hidden p-4 text-white absolute top-4 left-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bars3Icon className="h-6 w-6" />
      </button>
      <FabrkThreadMenu
        toggleMenu={() => {
          setIsOpen(!isOpen);
        }}
      />
    </div>
  );
}

export default FabrkRightMenuContainer;
