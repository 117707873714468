import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

function CsvHeadersDisplay({ data }: { data: any }) {
  return (
    <div className="flex flex-col gap-5">
      <div>
        <Typography variant="h5">CSV Headers</Typography>

        <Typography variant="paragraph">
          This is how the CSV data will map to the database
        </Typography>
      </div>
      <Divider className="my-1" />
      <ul>
        <li className="grid grid-cols-3 font-bold underline justify-items-center">
          <span> Your CSV Header</span> ➡️ <span>Database Column</span>
        </li>
      </ul>
      <ul>
        {Object.keys(data).map((key, i) => (
          <li key={i} className="grid grid-cols-3 justify-items-center">
            <span>{key}</span> ➡️ <span>{data[key]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CsvHeadersDisplay;
