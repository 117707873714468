import { Card, CardBody, Typography } from "@material-tailwind/react";
import { GlobeAlt } from "../icons/icons";

function CompanyCard() {
  return (
    <Card className="w-1/2">
      <CardBody>
        <GlobeAlt className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Company
        </Typography>
        <Typography>
          Add users to your company and/or update the high level style settings
          of your company's Try page.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default CompanyCard;
