import { AdminCustomerContextWrapper } from "../components/admin/adminCustomerContext";
import AnalyticsContainer from "../components/analytics/analyticsContainer";
import { AnalyticsContextWrapper } from "../components/analytics/analyticsContext";
import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Analytics = () => {
  mixpanelTrackEvent("Analytics page");

  return (
    <CampaignContextWrapper>
      <AnalyticsContextWrapper>
        <AdminCustomerContextWrapper>
          <AnalyticsContainer />
        </AdminCustomerContextWrapper>
      </AnalyticsContextWrapper>
    </CampaignContextWrapper>
  );
};

export default Analytics;
