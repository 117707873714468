import { Button, Typography } from "@material-tailwind/react";
import { Divider, TextInput } from "@tremor/react";
import { Fragment, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import EditButton from "../buttons/editButton";
import { CampaignContextWrapper } from "../campaign/campaignContext";
import { CheckCircle, XMarkIcon } from "../icons/icons";
import {
  DuplicationContextWrapper,
  useDuplicationContext,
} from "../reimbursement/duplicationContext";
import ReimbursementAdminTableList from "../reimbursement/reimbursementAdminTableList";
import ReimbursementByLeadItem from "../reimbursement/reimbursementByLeadItem";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";

function BaseDuplicationChecks() {
  const {
    potentialDuplicateReimbursements,
    locationAndDayCheck,
    dateTimeTotalCheck,
    activeReimbursement,
    updateReimbursement,
  } = useReimbursementContext();
  const { reimbursementsByLead, reimbursementEmbeddingMatches } =
    useDuplicationContext();

  const [isEdit, setIsEdit] = useState(false);
  const [receiptIdentifier, setReceiptIdentifier] = useState(
    activeReimbursement?.receipt_identifier || ""
  );

  useEffect(() => {
    if (activeReimbursement) {
      setReceiptIdentifier(activeReimbursement?.receipt_identifier || "");
    }
  }, [activeReimbursement]);
  function handleUpdateReimbursement() {
    if (receiptIdentifier) {
      updateReimbursement({
        id: activeReimbursement?.id as string,
        receipt_identifier_updated_at: new Date().toISOString(),
        ...(receiptIdentifier && {
          receipt_identifier: receiptIdentifier,
        }),
      });
      setIsEdit(false);
    }
  }

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Typography variant="h6">Duplicate Checks</Typography>
        <EditButton
          iconClass="h-4 w-4"
          text="Edit receipt id"
          onClick={() => {
            setIsEdit(!isEdit);
          }}
        />
      </div>
      <Divider className="my-1" />
      <div className="my-4 grid w-full grid-cols-2 gap-10">
        <div>
          <p className="text-xs text-gray-500">Receipt Identifier</p>
          {!isEdit ? (
            <p className="text-slate-700">
              {activeReimbursement?.receipt_identifier || "N/A"}
            </p>
          ) : (
            <TextInput
              placeholder="N/A"
              value={receiptIdentifier}
              onChange={(e) => setReceiptIdentifier(e.target.value)}
            />
          )}
        </div>
        <div>
          <p className="text-xs text-gray-500">Receipt Identifier Updated At</p>
          <p className="text-slate-700">
            {activeReimbursement?.receipt_identifier_updated_at
              ? formatTimeStampToHumanReadableShortDateTime(
                  activeReimbursement?.receipt_identifier_updated_at
                )
              : "N/A"}
          </p>
        </div>
      </div>
      {isEdit && (
        <div className="mt-6 flex justify-end gap-2">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            disabled={!isEdit}
            onClick={handleUpdateReimbursement}
          >
            Update
          </Button>
        </div>
      )}

      {reimbursementEmbeddingMatches &&
      reimbursementEmbeddingMatches.length > 1 ? (
        <div>
          <p className="text-center">Receipt Identifier Matches</p>
          {reimbursementEmbeddingMatches.map((match, index) => {
            const { similarity, reimbursement_id, receipt_identifier } = match;
            if (similarity === 1) {
              return null;
            }
            return (
              <div className="mt-4 grid w-full grid-cols-3" key={index}>
                <p className="text-xs text-gray-500">
                  Similarity: {Math.floor(similarity * 100)}
                </p>
                <p className="text-xs text-gray-500">
                  Receipt Identifier: {receipt_identifier}
                </p>
                <Link
                  className="text-xs text-blue-700"
                  color="green"
                  to={`/reimbursement/${reimbursement_id}`}
                >
                  Link
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-center">No matches</p>
      )}

      {potentialDuplicateReimbursements &&
      potentialDuplicateReimbursements.length > 0 ? (
        <div className="mt-4 ">
          <p className="text-center">Potential duplicate!</p>
          <table className="my-2 bg-blue-gray-50/50">
            <ReimbursementAdminTableList
              reimbursements={potentialDuplicateReimbursements}
            />
          </table>
        </div>
      ) : (
        <div className="mt-4">
          <div className="my-4 grid w-full grid-cols-2 gap-10">
            <div>
              <div className="flow-row flex gap-2">
                <p className="text-xs text-gray-500">Location and date </p>
                {locationAndDayCheck ? (
                  <CheckCircle color="green" className="h-4 w-4" />
                ) : (
                  // eslint-disable-next-line react/jsx-no-undef
                  <XMarkIcon className="h-4 w-4" />
                )}
              </div>
              <div className="flow-row flex gap-2">
                <p className="text-xs text-gray-500">
                  Day, time and total check{" "}
                </p>
                {dateTimeTotalCheck ? (
                  <CheckCircle color="green" className="h-4 w-4" />
                ) : (
                  <XMarkIcon className="h-4 w-4" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <p className="text-xs text-gray-500">Past reimbursements</p>
        {reimbursementsByLead?.length > 0 ? (
          <Fragment>
            {reimbursementsByLead
              .filter((r) => r.id !== activeReimbursement?.id)
              .map((reimbursement) => (
                <ReimbursementByLeadItem
                  key={reimbursement.id}
                  reimbursement={reimbursement}
                />
              ))}
          </Fragment>
        ) : (
          <p className="text-slate-700">None</p>
        )}
      </div>
    </div>
  );
}

function DuplicationChecks() {
  return (
    <CampaignContextWrapper>
      <DuplicationContextWrapper>
        <BaseDuplicationChecks />
      </DuplicationContextWrapper>
    </CampaignContextWrapper>
  );
}

export default DuplicationChecks;
