import AgentsContainer from "../components/agents/agentContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

function Agents() {
  mixpanelTrackEvent("Agents page");

  return <AgentsContainer />;
}

export default Agents;
