import { FlowRow } from "@/api/flow.type";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { yearMonthDayToMonthDayYear } from "../../utils/parseTimeStamp";

function CreatedFlowCard({ flow }: { flow: FlowRow }) {
  const { name, created_at, start_time, sms_content } = flow;

  return (
    <Card className="mt-6 w-96  shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:shadow-none active:shadow-none hover:cursor-pointer">
      <CardHeader floated={false} shadow={false}>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          {name}
        </Typography>
      </CardHeader>
      <CardBody className="flex flex-col gap-4">
        <div>
          <Typography variant="small">
            Created: {yearMonthDayToMonthDayYear(created_at)}
          </Typography>
          <Typography variant="small">
            Start date: {yearMonthDayToMonthDayYear(start_time)}
          </Typography>
        </div>
        <div>
          <Typography variant="h6">Message</Typography>
          <Typography variant="small">Name: {sms_content?.name}</Typography>
          <Typography variant="small">
            Content: {sms_content?.content}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
}

export default CreatedFlowCard;
