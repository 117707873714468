import { Divider } from "@tremor/react";
import React from "react";
import { secondsToTimeFormat } from "../../utils/parseTimeStamp";

export const TotalsTooltip = ({
  category,
  idx,
}: {
  category?: any;
  idx?: number;
}) => {
  const { payload } = category;
  return (
    <div className="space-y-1" key={idx}>
      <p className="text-tremor-content">
        {payload.companyName} - {payload.Name}
      </p>
      <p className="font-medium text-tremor-content-emphasis">
        Average: {secondsToTimeFormat(category.value)}
      </p>
      <p className="font-medium text-tremor-content-emphasis">
        Count: {payload.Count}
      </p>
      <Divider className="my-1" />
      <p className="text-tremor-content">{payload.content}</p>
    </div>
  );
};

const ContentTotalsCustomTooltip = ({
  payload,
  active,
  children,
}: {
  payload: any;
  active: any;
  children: any;
}) => {
  if (!active || !payload) return null;
  return (
    <div className="w-full rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      {payload
        .filter((c: any) => c.name === "Average")
        .map((category: any, idx: number) => (
          <div key={idx} className="flex flex-1 space-x-2.5">
            <div
              className={`w-1 flex flex-col bg-${category.color}-500 rounded`}
            />
            {React.Children.map(children, (child) =>
              React.cloneElement(child, { category, idx })
            )}
          </div>
        ))}
    </div>
  );
};

export default ContentTotalsCustomTooltip;
