import { useNavigate } from "react-router-dom";
import AddButton from "../buttons/addButton";
import FeedbackCard from "./FeedbackCard";

function FeedbackHeader() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-end">
        <FeedbackCard />
        <div>
          <AddButton
            iconClass="h-4 w-4"
            onClick={() => {
              navigate("/agent/extraction/add");
            }}
            color="green"
            size="lg"
            variant="outlined"
          >
            Add new data schema
          </AddButton>
        </div>
      </div>
    </div>
  );
}

export default FeedbackHeader;
