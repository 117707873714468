/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useCompanyContext } from "../company/companyContext";
import { getUniqueValuesForTableHeadFilter } from "../company/companyHelpers";
import { usePromptContext } from "./promptContext";
import PromptTableList from "./PromptList";
import TableWithSearch, {
  FilterValue,
  TableHeadItem,
} from "../table/tableWithSearch";

import { PromptRow } from "../../api/prompt.type";
import { handlePageNav } from "../table/handlePagination";

const TABLE_HEAD = [
  { label: "Name" },
  { label: "Created at" },
  { label: "Company" },
  { label: "Type" },
  { label: "Campaign" },
  { label: "Version" },
  { label: "Edit" },
  { label: "Delete" },
];

function PromptContainer() {
  const navigate = useNavigate();
  const { prompts } = usePromptContext();
  const { companies } = useCompanyContext();
  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  const [filteredPrompts, setFilteredPrompts] = useState<PromptRow[]>(
    prompts ? prompts : []
  );

  function handleAddClick() {
    navigate(`/prompt/add`);
  }

  function handleCampaignClick(promptId: string) {
    navigate(`/prompt/${promptId}`);
  }

  function baseFilter() {
    return (prompt: PromptRow, index: number) => index < 10;
  }

  const [promptFilterFunction, setPromptFilterFunction] =
    useState<(prompt: PromptRow, index: number) => boolean>(baseFilter);

  useEffect(() => {
    if (prompts && prompts?.length > 0) {
      setFilteredPrompts(prompts);

      const companyIds = getUniqueValuesForTableHeadFilter(prompts, companies);
      const newTableHead = TABLE_HEAD.map((item) => {
        if (item.label === "Company") {
          return {
            ...item,
            filterValues: companyIds,
          };
        }
        return item;
      });
      setTableHead(newTableHead);
    }
  }, [prompts, companies]);

  function handleFilterValue(value: FilterValue) {
    if (value.value && prompts) {
      if (value.value === "All") {
        setPromptFilterFunction(baseFilter);
        setFilteredPrompts(prompts);
      } else {
        if (!companies || companies.length === 0) {
          return;
        }
        const foundCompany = companies.find(
          (company) => company.name === value.value
        );

        setPromptFilterFunction(
          () => (prompt: PromptRow, index: number) =>
            prompt.company_id === foundCompany?.id
        );
        setFilteredPrompts(
          prompts.filter((item) => item.company_id === foundCompany?.id)
        );
      }
    }
  }

  return (
    <div className="flex flex-col gap-4 m-2">
      {prompts && (
        <TableWithSearch
          tableHead={tableHead}
          type="prompt"
          rowCount={filteredPrompts ? filteredPrompts?.length : 0}
          handleAddClick={handleAddClick}
          handleFilterValue={handleFilterValue}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setPromptFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setPromptFilterFunction,
              advancePage: false,
            })
          }
        >
          <PromptTableList
            prompts={prompts}
            handleContentClick={handleCampaignClick}
            filterFunction={promptFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default PromptContainer;
