import { Textarea } from "@material-tailwind/react";

import { ErrorHelperText } from "./errorHelperText";
import { PlentiInputProps } from "./plentiInput";

export const PlentiTextArea = ({
  register,
  errors,
  label,
  name,
  required = true,
  ...props
}: PlentiInputProps) => {
  return (
    <div className="mb-2">
      <Textarea
        label={label}
        variant="outlined"
        {...register(name, { required })}
        className="focus:border-t-0 focus:ring-0"
        {...props}
      />
      {errors[name] && <ErrorHelperText text={`${label} is required`} />}
    </div>
  );
};
