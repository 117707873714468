/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Textarea, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useCompanyContext } from "../company/companyContext";
import SelectCompany from "../company/selectCompany";
import { useAdminGptContext } from "./adminGptContext";
import GptLocationTestingResponse from "./gptLocationTestingResponse";

type GptLocationTestingProps = {
  message: string;
};

function GptLocationTesting() {
  const { selectedCompany } = useCompanyContext();
  const { useGetLocationMatch, setLoading } = useAdminGptContext();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const onSubmit: SubmitHandler<GptLocationTestingProps> = (
    data: GptLocationTestingProps
  ) => {
    useGetLocationMatch.mutate({
      companyId: selectedCompany?.id as string,
      message: data.message,
    });

    setLoading(true);
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <Typography variant="h6">Location</Typography>
        </div>
        <Divider className="my-1" />
        <div className="my-4 grid grid-cols-2 w-full gap-10">
          <div className="flex flex-col gap-2">
            <SelectCompany />
            <div>
              <Textarea
                {...register("message")}
                label="Address or zip"
                className="focus:border-t-0 focus:ring-0"
              />
            </div>
          </div>
          <GptLocationTestingResponse />
        </div>

        <div className="mt-6 flex gap-2">
          <Button size="sm" color="green" fullWidth={false} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default GptLocationTesting;
