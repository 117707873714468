import { Typography } from "@material-tailwind/react";
import { SmsMessage } from "../../../api/sms.type";

function UserMessageChatItem({
  message,
  index,
}: {
  message: SmsMessage;
  index: number;
}) {
  return (
    <div key={index} className="flex justify-end mr-10">
      {" "}
      <div
        className="bg-gray-900 text-white p-2 rounded-lg max-w-lg"
        style={{ border: "2px solid rgba(62, 73, 174, 0.5)" }}
      >
        <Typography
          variant="paragraph"
          style={{
            fontFamily: "dm-mono-regular",
          }}
        >
          {message.content}
        </Typography>
      </div>
    </div>
  );
}

export default UserMessageChatItem;
