import { Option, Select } from "@material-tailwind/react";
import { useCompanyContext } from "../company/companyContext";
import { useUserContext } from "../user/userContext";

function SelectCompany() {
  const { companies, selectedCompany, setSelectedCompany } =
    useCompanyContext();
  const { user } = useUserContext();

  return (
    <div>
      {user?.type === "admin" && companies && companies.length > 0 && (
        <Select
          label="Select Company"
          value={selectedCompany?.id}
          onChange={(e: any) => {
            const found = companies?.find((c) => c.id === e);
            if (found) {
              setSelectedCompany(found);
            }
          }}
        >
          {companies &&
            companies?.map((company) => {
              return (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              );
            })}
        </Select>
      )}
    </div>
  );
}

export default SelectCompany;
