/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createTemplate,
  getEmailTemplateById,
  getEmailTemplates,
  updateEmailTemplate,
} from "../../api/emailTemplate.api";
import { EmailTemplateRow } from "../../api/emailTemplate.type";
import { useAlertContext } from "../alert/alertContext";
import {
  CreateEmailTemplate,
  UpdateEmailTemplate,
} from "./emailTemplateContext.types";

export const EmailTemplateContextWrapper = ({
  id,
  children,
}: {
  id?: string | undefined;
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();
  const queryClient = useQueryClient();

  const { data: templates, isLoading: getTemplatesLoading } = useQuery({
    queryKey: "emailTemplates",
    queryFn: getEmailTemplates,
  });

  const {
    data: activeTemplate,
    mutate: get,
    isLoading: getTemplateLoading,
  } = useMutation(getEmailTemplateById);

  const { mutate: create, isLoading } = useMutation(createTemplate, {
    onSuccess: (data) => {
      get(data.id);
      setAlertProps({
        message: "Email template created",
        color: "green",
      });
      queryClient.invalidateQueries("emailTemplates");
    },
    onError: (error: any) => {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    updateEmailTemplate,
    {
      onSuccess: (data) => {
        get(data.id);
        setAlertProps({
          message: "Email template updated",
          color: "green",
        });
        queryClient.invalidateQueries("emailTemplates");
      },
      onError: (error: any) => {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    }
  );

  useEffect(() => {
    if (id) {
      get(id);
    }
  }, [id]);

  const value = {
    loading:
      isLoading || getTemplatesLoading || getTemplateLoading || isUpdateLoading,
    create,
    activeTemplate,
    templates,
    update,
  };

  return (
    <EmailTemplateContext.Provider value={value}>
      {children}
    </EmailTemplateContext.Provider>
  );
};

export const EmailTemplateContext = createContext({
  loading: false,
  create: {} as CreateEmailTemplate,
  activeTemplate: {} as EmailTemplateRow | undefined,
  templates: [] as EmailTemplateRow[] | undefined,
  update: {} as UpdateEmailTemplate,
});

export const useEmailTemplateContext = () => useContext(EmailTemplateContext);
