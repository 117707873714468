import { Step, Stepper, Typography } from "@material-tailwind/react";
import { useState } from "react";
import RegisterForm from "./RegisterForm";

// {
//   title: "What's your company's name?",
//   subtitle: "This will be used to create your company's Plenti account",
// },

// {
//   title: "What type of product do you sell?",
//   subtitle:
//     "If you sell multiple products, pick your most popular one. You can update your products later.",
// },

function SignupQuestions() {
  const steps = [
    {
      title: "Create Your Account",
      subtitle: "",
    },
    {
      title: "Company Details",
      subtitle:
        "This will be used to create your company's Plenti account. If you don't have a website, you can use your LinkedIn profile.",
    },
    {
      title: "Create Your Account",
      subtitle: "Create your Plenti account with an email and password.",
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);

  return (
    <div className=" m-10 p-8 rounded-lg shadow-md w-96 h-[650px]">
      <Typography variant="h3" className="my-4">
        {steps[currentStep].title}
      </Typography>
      <Typography variant="small" color="gray" className="mb-4">
        {steps[currentStep].subtitle}
      </Typography>
      {currentStep === 0 && (
        <Typography variant="small" color="gray" className="mb-4">
          Help us personalize your experience.
        </Typography>
      )}
      <Stepper
        activeStep={currentStep}
        className="mb-10"
        activeLineClassName="bg-primary-400"
      >
        {steps.map((step, index) => {
          return (
            <Step
              activeClassName="bg-primary-400"
              completedClassName="bg-primary-400"
              key={index}
              onClick={() => setCurrentStep(index)}
            >
              {index + 1}
            </Step>
          );
        })}
      </Stepper>

      <RegisterForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </div>
  );
}

export default SignupQuestions;
