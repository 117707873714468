import { useEffect } from "react";

function GoogleTagManager() {
  const id = "GTM-MDT3KVBM";

  useEffect(() => {
    // Inject the GTM script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
    document.head.appendChild(script);

    if (window) {
      // Setup the data layer and the gtag function
      script.addEventListener("load", () => {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).gtag = function () {
          (window as any).dataLayer.push(arguments);
        };
        (window as any).gtag("js", new Date());
        (window as any).gtag("config", id);
      });
    }
    return () => {
      // Remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  // The component does not render anything to the DOM directly
  return null;
}

export default GoogleTagManager;
