import {
  Button,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { formatTimeStampToHumanReadableDateTimeSeconds } from "../../utils/parseTimeStamp";
import { useCampaignContext } from "../campaign/campaignContext";
import { useDemoContext } from "../demo/demoContext";
import { UserIcon } from "../icons/icons";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

function LeadCampaignList() {
  const { testLeadsByCampaign } = useDemoContext();
  const { activeCampaign } = useCampaignContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover placement="bottom-end">
      <PopoverHandler onClick={() => setIsOpen(!isOpen)}>
        <Button color="blue-gray" className="flex justify-between gap-2">
          Example Leads
          {isOpen ? (
            <ChevronDownIcon className="w-4 h-4" />
          ) : (
            <ChevronUpIcon className="w-4 h-4" />
          )}
        </Button>
      </PopoverHandler>
      <PopoverContent className="w-fit">
        <div className="mb-4 flex items-center gap-4 border-b border-blue-gray-50 pb-4">
          <UserIcon className="h-6 w-6" />
          <div>
            <Typography variant="h6" color="blue-gray">
              Example leads for this campaign
            </Typography>
          </div>
        </div>
        <List className="p-0">
          {testLeadsByCampaign?.map((lead) => {
            const reimbursementStatus =
              lead?.reimbursement && lead?.reimbursement[0]
                ? lead?.reimbursement[0].status
                : "No reimbursement";

            return (
              <Link
                reloadDocument
                to={`/campaign/${activeCampaign?.id}/demo?leadId=${lead.id}`}
                className="text-initial font-medium text-blue-gray-500"
                key={lead.id}
              >
                <ListItem>
                  {formatTimeStampToHumanReadableDateTimeSeconds(
                    lead.created_at
                  )}{" "}
                  | {lead.phone} | {reimbursementStatus}
                </ListItem>
              </Link>
            );
          })}
        </List>
      </PopoverContent>
    </Popover>
  );
}

export default LeadCampaignList;
