import AddProductContainer from "../components/product/AddProductContainer";
import { ProductContextWrapper } from "../components/product/ProductContextWrapper";
import { mixpanelTrackEvent } from "../utils/mixpanel";

function AddProduct() {
  mixpanelTrackEvent("Add product");

  return (
    <ProductContextWrapper>
      <AddProductContainer />
    </ProductContextWrapper>
  );
}

export default AddProduct;
