import { Card, CardBody, Typography } from "@material-tailwind/react";
import { AgentTypes } from "../../api/aiAgent.type";
import { useNavigate } from "react-router-dom";
import { camelCaseToHyphens } from "../../utils/stringManipulation";

function AddAgentCard({
  title,
  description,
  icon,
  type,
}: {
  title: string;
  description: string;
  icon: JSX.Element;
  type: AgentTypes;
}) {
  const navigate = useNavigate();
  return (
    <Card
      className="mt-6 w-96  shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:shadow-none active:shadow-none hover:cursor-pointer"
      onClick={() => {
        navigate(`/agent/add/${camelCaseToHyphens(type)}`);
      }}
    >
      <CardBody>
        <div>
          <span className="bg-gray-100 rounded-full p-2 inline-flex items-center justify-center">
            {icon}
          </span>
          <Typography variant="h5" color="blue-gray" className="mb-2">
            {title}{" "}
          </Typography>
          <Typography>{description}</Typography>
        </div>
      </CardBody>
    </Card>
  );
}

export default AddAgentCard;
