import { SearchSelect, SearchSelectItem } from "@tremor/react";

function GenericSelect({
  values,
  setSelected,
  selected,
}: {
  values: string[] | undefined;
  setSelected: (s: any) => void;
  selected?: any;
}) {
  return (
    <div>
      {values && values.length > 0 && (
        <SearchSelect
          value={selected}
          onChange={(e: any) => {
            if (e === "") {
              setSelected(null);
              return;
            }
            setSelected(e);
          }}
          onValueChange={(e: any) => {
            if (e === "") {
              setSelected(null);
              return;
            }
          }}
        >
          {values &&
            values?.map((s) => {
              return (
                <SearchSelectItem key={s} value={s}>
                  {s}
                </SearchSelectItem>
              );
            })}
        </SearchSelect>
      )}
    </div>
  );
}

export default GenericSelect;
