import { Typography } from "@material-tailwind/react";

function AdminHeader() {
  return (
    <div className="flex flex-col">
      <Typography variant="h2" className="mb-4 text-slate-500">
        Admin
      </Typography>
      <Typography variant="paragraph" className="max-w-2xl">
        Admin controls, settings and analytics.
      </Typography>
    </div>
  );
}

export default AdminHeader;
