import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { FlowInsert, FlowRow } from "./flow.type";

export function getFlows(): Promise<FlowRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.flow;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<FlowRow[]>(url, request);
}

export function createNewFlow(values: FlowInsert) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.flow;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<FlowRow>(url, request);
}
