import {
  IconButton,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { SmallInfoCircle } from "../icons/icons";
import { useState } from "react";

function InfoPopover({
  onOpenHandler,
  children,
}: {
  onOpenHandler?: () => void;
  children?: any;
}) {
  const [openPopover, setOpenPopover] = useState(false);

  return (
    <Popover placement="right" open={openPopover} handler={setOpenPopover}>
      <PopoverHandler>
        <IconButton className="h-4 w-4" color="blue">
          <SmallInfoCircle
            className="h4 text-blue h-4 w-4"
            onClick={() => {
              setOpenPopover(true);
              if (onOpenHandler) {
                onOpenHandler();
              }
            }}
          />
        </IconButton>
      </PopoverHandler>
      <PopoverContent className="min-w-[300px] max-w-xl">
        {children}
      </PopoverContent>
    </Popover>
  );
}

export default InfoPopover;
