import { useNavigate } from "react-router-dom";
import { ImageMetadataRow } from "../../api/imageMetadata.type";
import GalleryImage from "./GalleryImage";

function GridGallery({ images }: { images: ImageMetadataRow[] }) {
  const navigate = useNavigate();

  function handleClick(url: string) {
    const row = images.find((image) => image.media_url === url);
    navigate(`/image/${row?.id}`);
  }

  return (
    <div className="grid grid-flow-row gap-4 grid-cols-4 auto-rows-max sm:grid-cols-2 md:grid-cols-4 ">
      {images.map((item) => {
        return (
          <GalleryImage
            key={item.id}
            image={item.media_url}
            handleClick={handleClick}
          />
        );
      })}
    </div>
  );
}

export default GridGallery;
