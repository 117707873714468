/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { locationMatchByCompany } from "../../api/location.api";
import { LocationMatchResponse } from "../../api/location.type";
import { testChat } from "../../api/openAi.api";
import { ChatResponse } from "../../api/openAi.type";
import { setTestContext } from "../../api/testContext.api";
import { useAlertContext } from "../alert/alertContext";
import {
  CreateTestContext,
  GetChatTestResponse,
  GetLocationMatchMutation,
} from "./adminGptContext.types";
import { getTestLeadsByCampaign } from "../../api/lead.api";
import { CampaignRow } from "../../api/campaign.type";
import { LeadRow } from "../../api/lead.type";
import { CombinedValues } from "./chatTestingConfig";
import { io } from "socket.io-client";

export const AdminGptContextWrapper = (props: any) => {
  const socket = io(process.env.REACT_APP_API_URL as string, {
    autoConnect: false,
    transports: ["websocket"],
    upgrade: false,
    withCredentials: true,
  });

  const queryClient = useQueryClient();
  const { setAlertProps } = useAlertContext();
  const [locationMessage, setLocationMessage] =
    useState<LocationMatchResponse>();
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState<LeadRow[]>();
  const [isReceipt, setIsReceipt] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<CombinedValues>({
    type: "",
    issues: "",
    message: "",
    name: "",
  });
  const [selectedLead, setSelectedLead] = useState<LeadRow | null>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignRow>();
  const [chatResponse, setChatResponse] = useState<ChatResponse>();

  const useGetTestLeadsByCampaign = useMutation(getTestLeadsByCampaign, {
    onSuccess: async (res) => {
      setLeads(res);
    },
  });

  useEffect(() => {
    if (selectedCampaign) {
      useGetTestLeadsByCampaign.mutate(selectedCampaign.id);
    }
  }, [selectedCampaign]);

  const useCreateTestContext = useMutation(setTestContext, {
    onSuccess: async (res) => {
      setAlertProps({
        color: "green",
        message: "Test context saved",
      });
      queryClient.invalidateQueries("testContext");
    },
    onError(error: Error) {
      setAlertProps({
        color: "red",
        message: error.message,
      });
    },
    onSettled() {
      setLoading(false);
    },
  });

  const useSendTestChat = useMutation(testChat, {
    onSuccess: async (res) => {
      setChatResponse(res);
    },
    onError(error: Error) {
      setAlertProps({
        color: "red",
        message: error.message,
      });
    },
    onSettled() {
      setLoading(false);
    },
  });

  const useGetLocationMatch = useMutation(locationMatchByCompany, {
    onSuccess: async (res) => {},
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        color: "red",
        message: error.message,
      });
    },
  });

  useEffect(() => {
    socket.connect();

    socket.on("locationReady", (args) => {
      setLocationMessage(args.data);
      setLoading(false);
    });

    socket.on("connect", () => {
      console.log("connected");
    });

    return () => {
      socket.off("connect");
    };
  }, []);

  const value = {
    locationMessage,
    useGetLocationMatch,
    loading,
    setLoading,
    useSendTestChat,
    chatResponse,
    useCreateTestContext,
    selectedCampaign,
    setSelectedCampaign,
    leads,
    selectedLead,
    setSelectedLead,
    defaultValues,
    setDefaultValues,
    isReceipt,
    setIsReceipt,
    setChatResponse,
  };

  return (
    <AdminGptContext.Provider value={value}>
      {props.children}
    </AdminGptContext.Provider>
  );
};

export const AdminGptContext = createContext({
  locationMessage: {} as LocationMatchResponse | undefined,
  useGetLocationMatch: {} as GetLocationMatchMutation,
  loading: false,
  setLoading: (loading: boolean) => {},
  useSendTestChat: {} as GetChatTestResponse,
  chatResponse: {} as ChatResponse | undefined,
  useCreateTestContext: {} as CreateTestContext,
  selectedCampaign: {} as CampaignRow | undefined,
  setSelectedCampaign: (campaign: CampaignRow | undefined) => {},
  leads: [] as LeadRow[] | undefined,
  selectedLead: {} as LeadRow | null,
  setSelectedLead: (lead: LeadRow | null) => {},
  defaultValues: {} as CombinedValues,
  setDefaultValues: (values: CombinedValues) => {},
  isReceipt: false,
  setIsReceipt: (isReceipt: boolean) => {},
  setChatResponse: (chatResponse: ChatResponse | undefined) => {},
});

export const useAdminGptContext = () => useContext(AdminGptContext);
