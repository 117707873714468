import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";

export default function Toggle({
  control,
  name,
  trueLabel,
  falseLabel,
}: {
  control: any;
  name: string;
  trueLabel: string;
  falseLabel: string;
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Switch.Group>
            <div className="flex flex-row items-center gap-2">
              <Switch.Label passive>
                {field.value ? trueLabel : falseLabel}
              </Switch.Label>
              <Switch
                className={`${
                  field.value ? "bg-green-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
                onChange={field.onChange}
                value={field.value}
              >
                <span
                  className={`${
                    field.value ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          </Switch.Group>
        );
      }}
    />
  );
}
