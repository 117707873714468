import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { PromptInsert, PromptRow, PromptUpdate } from "../api/prompt.type";
import { ApiEndpoints } from "./apiEndpoints";

export function getPrompts(): Promise<PromptRow[]> {
  const url = process.env.REACT_APP_API_URL + "/prompt";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<PromptRow[]>(url, request);
}

export function getPromptById({ id }: { id: string }): Promise<PromptRow> {
  const url = process.env.REACT_APP_API_URL + `/prompt/${id}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<PromptRow>(url, request);
}

export function createSystemPrompt(
  prompt: Partial<PromptInsert>
): Promise<PromptRow[]> {
  const url = process.env.REACT_APP_API_URL + "/prompt/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ prompt }),
  };

  return fetchData<PromptRow[]>(url, request);
}

export function updateSystemPrompt(
  prompt: Partial<PromptUpdate>
): Promise<PromptRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.prompt + "/" + prompt.id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify({ prompt }),
  });

  return fetchData<PromptRow>(url, request);
}

export function deletePromptById({ id }: { id: string }): Promise<PromptRow[]> {
  const url = process.env.REACT_APP_API_URL + `/prompt/${id}`;

  const request = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<PromptRow[]>(url, request);
}

export function runAiUpdateSystemPrompt(id: string): Promise<{
  prompt: PromptRow;
  responses: any;
}> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.prompt + "/ai-update/" + id;

  const request = setHeaderOptions({
    method: "PUT",
  });

  return fetchData<{
    prompt: PromptRow;
    responses: any;
  }>(url, request);
}
