import { Fragment } from "react";
import { ReimbursementRow } from "../../api/reimbursement.type";
import DuplicationChecks from "../reimbursement/duplicationChecks";
import OcrDetails from "../reimbursement/ocrDetails";
import PayoutDetails from "../reimbursement/payoutDetails";
import ReimbursementAdminTableList from "../reimbursement/reimbursementAdminTableList";
import ReimbursementLocationContainer from "../reimbursement/reimbursementLocationContainer";
import EditReimbursementCampaign from "./editReimbursementCampaign";
import ViewReimbursementLead from "./ViewReimbursementLead";
import ViewReimbursementType from "./ViewReimbursementType";
import ReimbursementNotes from "./ReimbursementNotes";

function ViewReimbursementDetailsAdmin({
  activeReimbursement,
}: {
  activeReimbursement: ReimbursementRow;
}) {
  return (
    <Fragment>
      {activeReimbursement && activeReimbursement.id && (
        <div className="flex flex-col gap-10">
          <table className="w-full table-auto rounded-lg bg-blue-gray-50/50 text-left ">
            {activeReimbursement && (
              <ReimbursementAdminTableList
                reimbursements={[activeReimbursement]}
              />
            )}
          </table>
          <ViewReimbursementType />
          <ViewReimbursementLead />
          <EditReimbursementCampaign />
          <DuplicationChecks />

          <ReimbursementLocationContainer />

          <OcrDetails />

          <PayoutDetails />
          <ReimbursementNotes />
        </div>
      )}
    </Fragment>
  );
}

export default ViewReimbursementDetailsAdmin;
