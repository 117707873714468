import { Card, Metric, ProgressCircle, Text } from "@tremor/react";

function PercentCard({ value, title }: { value?: number; title: string }) {
  return (
    <Card className="max-w-lg">
      <Text>{title}</Text>
      <ProgressCircle value={value} size="xl">
        <span className="h-32 w-32 rounded-full bg-primary-500 flex items-center justify-center  text-primary-300 font-bold bg-opacity-50">
          <Metric className="text-primary-300">{value}% </Metric>
        </span>
      </ProgressCircle>
    </Card>
  );
}

export default PercentCard;
