import { useQuery } from "react-query";
import { getReimbursementsByCohort } from "../../../../api/leadAnalytics";
import { calculateAverage } from "../../../../utils/calculateAverage";
import { mapReimbursementCountToCsvData } from "../../mapReimbursementCountToCsvData";
import { mapReimbursementCounts } from "../utils/mapReimbursementCounts";

export const useLoadReimbursementsByCohort = (params: Params) => {
  const { isLoading, data, error, isFetching } = useQuery({
    queryFn: () => getReimbursementsByCohort(params),
    queryKey: ["query-reimbursements-by-cohort", params],
    select: (res) => {
      const arrLead = res
        ? res
            ?.filter((item) => item.lead_count_reimbursed)
            .map((item) => item.lead_count_reimbursed / item.lead_count_signup)
        : [0];

      const average = calculateAverage(arrLead);

      return {
        average,
        paid: res?.reduce((a, b) => a + b.lead_count_reimbursed, 0),
        downloadData: mapReimbursementCountToCsvData(res),
        chart: res?.map((item) => mapReimbursementCounts(item, average)),
      };
    },
    initialData: [],
    enabled: !!params.campaignId && !!params.startDate && !!params.endDate,
  });

  return {
    isLoading,
    data,
    error,
    isFetching,
  };
};

interface Params {
  startDate: string;
  endDate: string;
  campaignId: string;
}
