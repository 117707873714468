import { Typography } from "@material-tailwind/react";
import { ButtonPrimary } from "../buttons/ButtonPrimary";

function LoyaltyAgentDialog({
  handleSubmit,
}: {
  handleSubmit: (value: boolean) => void;
}) {
  return (
    <div>
      <Typography color="gray">
        This program is triggered after a lead has reimbursed. Would you like to
        make this retroactive and send a text message to all previous leads, or
        just inform new leads moving forward?
      </Typography>

      <div className="flex gap-2 mt-5">
        <ButtonPrimary onClick={() => handleSubmit(false)}>
          Just leads moving forward
        </ButtonPrimary>
        <ButtonPrimary onClick={() => handleSubmit(true)}>
          Leads moving forward and leads who previously reimbursed
        </ButtonPrimary>
      </div>
    </div>
  );
}

export default LoyaltyAgentDialog;
