/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useEffect, useState } from "react";

import EditButton from "../buttons/editButton";
import { CheckCircle } from "../icons/icons";
import { useLocationContext } from "../location/locationContext";
import LocationActionButtons from "../reimbursement/locationActionButtons";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import Scroll from "../search/scroll";
import SearchList from "../search/searchList";

import { LocationRow } from "../../api/location.type";
import { formatPhoneNumber } from "../../utils/parsePhoneNumbers";

function ReimbursementLocationContainer() {
  const {
    activeLocation,
    useGetAllLocations,
    companyLocations: locations,
    searchLocation,
  } = useLocationContext();
  const [showSearch, setSearchShow] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  const [value, setValue] = useState("");
  const [selected, setSelected] = useState<LocationRow | null>(null);
  const {
    activeReimbursement,
    updateReimbursement,
    useUpdateOcrById,
    reimbursementOcr,
  } = useReimbursementContext();

  const filteredLocations = locations || ["No locations found"];

  function handleMarkCorrect() {
    updateReimbursement({
      id: activeReimbursement?.id || "",
      location_verified: true,
    });
  }

  useEffect(() => {
    if (activeReimbursement?.company_id) {
      useGetAllLocations.mutate(activeReimbursement?.company_id);
    }
  }, [activeReimbursement?.company_id]);

  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);

    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSelected(null);
      setSearchShow(true);

      // Clear the previous timeout
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      // Set a new timeout
      setDebounceTimeout(
        setTimeout(() => {
          setDebounceTimeout(null);
          setTimeout(() => {
            searchLocation(e.target.value);
          }, 1000);
        }, 1000)
      );
    }
  };

  function searchList() {
    if (showSearch) {
      return (
        <Scroll>
          <SearchList
            items={filteredLocations}
            handleSelect={(value) => {
              setSelected(value);
              setValue(value.name);
            }}
          />
        </Scroll>
      );
    }
  }

  function handleSubmit() {
    setOpenPopover(false);
    updateReimbursement({
      id: activeReimbursement?.id || "",
      location_id: selected?.id || "",
      ...(selected?.chain_id && { chain_id: selected?.chain_id }),
      location_verified: true,
    });

    if (reimbursementOcr) {
      useUpdateOcrById.mutate({
        ocrId: reimbursementOcr?.id as string,
        values: {
          id: reimbursementOcr?.id as string,
          location_id: selected?.id || "",
          ...(selected?.chain_id && { chain_id: selected?.chain_id }),
        },
      });
    }
  }

  const classes = "p-2";
  return (
    <Fragment>
      <div className="flex justify-between">
        <Typography variant="h6">Location</Typography>
        <EditButton
          iconClass="h-4 w-4"
          text="Edit location"
          onClick={() => {
            setOpenPopover(!openPopover);
            setSearchShow(!showSearch);
          }}
        />
      </div>
      <Divider className="my-1" />
      {activeLocation && !openPopover ? (
        <div className="my-4 grid w-full grid-cols-3 gap-10">
          <div className="flex items-center gap-3">
            <div className="flex max-w-xs flex-col">
              <p className="text-xs text-gray-500"> Name</p>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {activeLocation?.name}
              </Typography>
              <p className="text-xs text-gray-500">Phone</p>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {activeLocation?.phone
                  ? formatPhoneNumber(activeLocation?.phone)
                  : "No phone number"}
              </Typography>
            </div>
          </div>

          <div>
            <p className="text-xs text-gray-500">Address</p>
            <div className="flex items-center gap-3">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {activeLocation?.full_address}
              </Typography>
            </div>
          </div>
          {!activeReimbursement?.location_verified ? (
            <LocationActionButtons
              classes={classes}
              id={activeLocation?.id || ""}
              handleMarkCorrect={handleMarkCorrect}
            />
          ) : (
            <div className="flex flex-row items-center gap-1 p-2 align-middle ">
              <CheckCircle className="h-6 w-6 text-green-500" />
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                Location verified
              </Typography>
            </div>
          )}
        </div>
      ) : (
        <div className="flex gap-2">
          <Popover
            placement="bottom"
            open={openPopover}
            handler={setOpenPopover}
          >
            <PopoverHandler>
              <Button
                size="sm"
                color="green"
                fullWidth={false}
                onClick={() => setOpenPopover(true)}
              >
                Set location
              </Button>
            </PopoverHandler>
            <PopoverContent className="min-w-fit ">
              <Typography variant="h6" color="blue-gray" className="mb-6">
                Search Location
              </Typography>
              {selected && (
                <div className="flex flex-row items-center gap-3 mb-6">
                  <Typography variant="small" color="blue-gray">
                    {selected.name}
                  </Typography>
                  <Typography variant="small" color="blue-gray">
                    {selected.full_address}
                  </Typography>
                </div>
              )}
              <div className="flex gap-2">
                {/* @ts-ignore */}
                <Input
                  onChange={handleChange}
                  placeholder="Search locations"
                  value={value}
                  label="Locations"
                  className="focus:border-t-0 focus:ring-0"
                />

                <Button
                  variant="gradient"
                  disabled={!selected}
                  onClick={handleSubmit}
                >
                  Assign Location
                </Button>
              </div>
              {searchList()}
            </PopoverContent>
          </Popover>
        </div>
      )}
    </Fragment>
  );
}

export default ReimbursementLocationContainer;
