import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { StripePaymentIntent, StripePaymentMethod } from "../api/stripe.type";

export function getPaymentMethods(): Promise<StripePaymentMethod[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.stripe + "/payment-methods";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData(url, request);
}

export function getSetupIntent() {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.stripe + "/setup-intent";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<StripePaymentIntent>(url, request);
}

export function getPaymentIntent({
  payment_method,
  amount,
}: {
  payment_method: string;
  amount: number;
}) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.stripe + "/payment-intent";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ payment_method, amount }),
  });

  return fetchData<StripePaymentIntent>(url, request);
}

export function confirmPaymentIntentByIdAndMethod({
  payment_intent,
  payment_method,
}: {
  payment_intent: string;
  payment_method: string;
}) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.stripe + "/confirm-payment";

  const request = setHeaderOptions({
    method: "POST",

    body: JSON.stringify({ payment_intent, payment_method }),
  });

  return fetchData(url, request);
}
