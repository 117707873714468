import { useAlertContext } from "../alert/alertContext";
import { createNewFlow, getFlows } from "../../api/flow.api";
import { createContext, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { CreateFlowMutation } from "../flow/flowContext.types";
import { useUserContext } from "../user/userContext";
import { FlowRow } from "@/api/flow.type";

export const FlowContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const { user } = useUserContext();

  const { data: flows } = useQuery({
    queryKey: "flows",
    queryFn: () => getFlows(),
    enabled: !!user,
  });

  const useCreateNewFlow = useMutation(createNewFlow, {
    onSuccess: async (res) => {
      setAlertProps({
        message: `${res.name} campaign updated`,
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const value = {
    useCreateNewFlow,
    flows,
  };

  return (
    <FlowContext.Provider value={value}>{props.children}</FlowContext.Provider>
  );
};

export const FlowContext = createContext({
  useCreateNewFlow: {} as CreateFlowMutation,
  flows: [] as FlowRow[] | undefined,
});

export const useFlowContext = () => useContext(FlowContext);
