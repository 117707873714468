import { Button, Checkbox, Input } from "@material-tailwind/react";
import { useMemo } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  Schema,
  availableQuestionTypes,
} from "../../api/extractionSchema.type";
import DeleteButton from "../buttons/DeleteButton";
import AddButton from "../buttons/addButton";
import GenericSelect from "../forms/GenericSelect";
import { PlentiInput } from "../forms/plentiInput";
import { useExtractionSchemaContext } from "./ExtractionSchemaContext";

function EditSchema({
  schema,
  setIsEdit,
  name,
  id,
}: {
  schema: Schema[];
  setIsEdit: (value: boolean) => void;
  name?: string;
  id?: string;
}) {
  const { createSchema } = useExtractionSchemaContext();

  const activeSchema = useMemo(() => {
    return schema;
  }, [schema]);

  const defaultValues = activeSchema.map((item) => {
    return {
      id: item.id,
      //@ts-ignore
      type: item.type === "string" ? "text" : item.type,
      question: item.question,
      required: item.required,
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,

    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: name,
      id,
      questions: defaultValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  function handleSetSelectedType(id: number, value: string) {
    setValue(`questions.${id}.type`, value);
  }
  const onSubmit: SubmitHandler<any> = (data: any) => {
    const values = {
      name: data.name,
      schema: data.questions.map((item: Schema, index: number) => {
        return {
          id: item.id ? item.id : index + 1,
          type: item.type,
          question: item.question,
          required: item.required,
        };
      }),
      id,
    };
    createSchema(values);
    setIsEdit(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-5 p-5 border border-blue-gray-100 rounded-lg   ">
        <div className="flex justify-between">
          <div>
            <PlentiInput
              register={register}
              errors={errors}
              label="Name"
              name="name"
              required={false}
            />
          </div>
        </div>

        <div>
          {fields?.map((item, index) => {
            return (
              <div key={item.id} className="grid grid-cols-4">
                <Controller
                  render={({ field }) => {
                    return (
                      <div className="mb-2">
                        <GenericSelect
                          values={availableQuestionTypes}
                          setSelected={(value) =>
                            handleSetSelectedType(index, value)
                          }
                          selected={field.value}
                        />
                      </div>
                    );
                  }}
                  name={`questions.${index}.type`}
                  control={control}
                />

                <Controller
                  render={({ field }) => (
                    <div className="mb-2">
                      <Checkbox
                        label="Required"
                        crossOrigin={true}
                        {...field}
                        checked={field.value ? true : false}
                        value={field.value ? "true" : "false"}
                      />
                    </div>
                  )}
                  name={`questions.${index}.required`}
                  control={control}
                />
                <div className={"col-span-3"}>
                  <Controller
                    render={({ field }) => (
                      <div className="mb-2">
                        <Input
                          {...field}
                          label="Question"
                          variant="outlined"
                          crossOrigin={true}
                          className="focus:border-t-0 focus:ring-0"
                        />
                      </div>
                    )}
                    name={`questions.${index}.question`}
                    control={control}
                  />
                </div>
                <DeleteButton onClick={() => remove(index)} />
              </div>
            );
          })}
          <AddButton
            iconClass="h-4 w-4"
            color="green"
            onClick={() => {
              append({
                id: "",
                type: "text",
                question: "",
                required: false,
              });
            }}
          >
            Add question
          </AddButton>
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-2">
        <Button
          size="sm"
          variant="outlined"
          color="gray"
          onClick={() => setIsEdit(false)}
        >
          Cancel
        </Button>
        <Button size="sm" color="green" fullWidth={false} type="submit">
          {id ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
}

export default EditSchema;
