import { useTryContext } from "../try/tryContext";

type StepConfig = {
  step: number;
  title: string;
  subtitle: string;
};

function TrySteps() {
  const { companyConfig, campaign, forDemo } = useTryContext();

  const subtitle = campaign?.subtitle_font?.tailwind_name || "sans";
  const paragraphFont = campaign?.paragraph_font?.tailwind_name || "sans";

  const subtitleFontWeight = campaign?.subtitle_font_weight || 400;
  const paragraphFontWeight = campaign?.paragraph_font_weight || 400;

  const stepOneSubtitle = campaign?.step_one_text
    ? campaign?.step_one_text
    : `Purchase a pack of ${companyConfig?.product_name} at your local retailer.`;

  const stepsConfig = [
    {
      step: 1,
      title: `Purchase`,
      subtitle: stepOneSubtitle,
    },
    {
      step: 2,
      title: "Send your receipt",
      subtitle: "Upload a photo of your receipt.",
    },
    {
      step: 3,
      title: "Pay Day",
      subtitle: "We’ll send you cash back via Venmo or Paypal",
    },
  ];

  return (
    <div
      className={
        forDemo
          ? "mt-10 flex flex-col content-start justify-center gap-20 pb-36"
          : "mt-10 flex flex-col content-start justify-center gap-20 pb-36 md:flex-row md:justify-around "
      }
    >
      {companyConfig &&
        stepsConfig.map((step: StepConfig, index: any) => {
          const {
            step_one_image_url,
            step_two_image_url,
            step_three_image_url,
          } = companyConfig;

          const allThreeImages =
            step_one_image_url && step_two_image_url && step_three_image_url;

          const imageSrc =
            allThreeImages && index === 0
              ? step_one_image_url
              : allThreeImages && index === 1
              ? step_two_image_url
              : allThreeImages && index === 2
              ? step_three_image_url
              : null;

          return (
            <div
              key={index}
              className="justify-top flex min-w-[250px] flex-col gap-4"
            >
              {imageSrc ? (
                <img
                  width={forDemo ? 120 : 120}
                  height={forDemo ? 120 : 120}
                  src={imageSrc}
                  alt="Step"
                  style={{
                    margin: "0 auto",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    width: "120px",
                    height: "120px",
                    margin: "0 auto",
                    borderRadius: "50%",
                    backgroundColor:
                      companyConfig.step_background_color || "#333",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      fontSize: "60px",
                      color: "white",
                      fontWeight: 900,
                      fontFamily: "Gothic A1, sans-serif",
                    }}
                  >
                    {step.step}
                  </span>
                </div>
              )}
              <div className="flex flex-col justify-center gap-4 text-center">
                <h2
                  className={`mx-auto max-w-[300px] font-${subtitle}`}
                  style={{
                    fontWeight: subtitleFontWeight,
                  }}
                >
                  {" "}
                  {step.title}
                </h2>
                <p
                  className={`mx-auto max-w-[150px] font-${paragraphFont}`}
                  style={{
                    fontWeight: paragraphFontWeight,
                  }}
                >
                  {step.subtitle}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TrySteps;
