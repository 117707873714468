import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CampaignTransitionRow } from "./campaignTransition.type";

export function getCampaignTransitions(): Promise<CampaignTransitionRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaignTransition;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CampaignTransitionRow[]>(url, request);
}

export function getCampaignTransitionByCampaignId(campaignId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignTransition +
    "/campaign/" +
    campaignId;

  const request = setHeaderOptions({
    method: "GET",
  });
  return fetchData<CampaignTransitionRow[]>(url, request);
}

export function createCampaignTransition({
  toCampaign,
  fromCampaign,
  triggerType,
}: {
  toCampaign: string;
  fromCampaign: string;
  triggerType: string;
}): Promise<CampaignTransitionRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.campaignTransition;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ toCampaign, fromCampaign, triggerType }),
  });
  return fetchData<CampaignTransitionRow>(url, request);
}

export function removeCampaignTransition(
  id: string
): Promise<CampaignTransitionRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.campaignTransition + "/" + id;

  const request = setHeaderOptions({
    method: "DELETE",
  });
  return fetchData<CampaignTransitionRow>(url, request);
}
