import { Card, CardBody, Typography } from "@material-tailwind/react";
import { UserIcon } from "../icons/icons";

function LeadBulkCard() {
  return (
    <Card className="mt-6 w-2/3">
      <CardBody>
        <UserIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Bulk Upload Leads
        </Typography>
        <Typography>
          You can upload a cvs file with a list of users/leads. Please make sure
          the csv has the following required columns: name, email, phone and
          address columns. The rest of the columns are optional. Bulk upload
          uses AI to assist in the upload.
        </Typography>
      </CardBody>
    </Card>
  );
}

export default LeadBulkCard;
