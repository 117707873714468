import { UseMutateFunction, UseMutationResult } from "react-query";
import { PromptInsert, PromptRow, PromptUpdate } from "../../api/prompt.type";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";

export const emptyCampaignPrompt: Partial<PromptInsert> = {
  name: "",
  type: "",
  summary: "",
  promotion_end_date: "",
  retailers: "",
  products: "",
  redemption_amount: "",
  promotion_details: "",
  redeem_requirements: "",
  redeem_requirements_details: "",
  promotion_exceptions: "",
  discount_code: "",
  feedback_question: "",
  not_qualify: "",
  loyalty_context: "",
  referral_context: "",
  image_context: "",
  other: "",
};

export const emptyCompanyPrompt: Partial<PromptInsert> = {
  name: "",
  type: "",
  ai_name: "",
  ai_bio: "",
  company_description: "",
  products: "",
  product_notables: "",
  website_url: "",
  e_comm_url: "",
  support_email: "",
  store_locator_link: "",
  other: "",
};

const filteredOutPromptKeys = [
  "id",
  "name",
  "created_at",
  "updated_at",
  "type",
];

const textAreaKeys = [
  "summary",
  "promotion_details",
  "redeem_requirements_details",
  "promotion_exceptions",
  "feedback_question",
  "not_qualify",
  "company_description",
  "products",
  "product_notables",
  "image_context",
  "other",
  "text",
];

export const promptToForm = (
  activePrompt: PromptUpdate,
  emptyPrompt: Partial<PromptInsert>
) => {
  return Object.keys(emptyPrompt)
    .filter((p) => !filteredOutPromptKeys.includes(p))
    .sort((a, b) => {
      if (b === "text") return -1;
      return textAreaKeys.includes(a) && !textAreaKeys.includes(b) ? -1 : 1;
    })
    .map((key) => ({
      label: snakeCaseToTitleCase(key),
      name: key,
      value: activePrompt[key as keyof PromptUpdate]
        ? activePrompt[key as keyof PromptUpdate]
        : "N/A",
      isTextArea: textAreaKeys.includes(key),
    }));
};

export type UpdatePromptMutation = UseMutateFunction<
  PromptRow,
  Error,
  Partial<PromptUpdate>,
  unknown
>;

export type GetPromptByIdMutation = UseMutationResult<
  PromptRow,
  unknown,
  {
    id: string;
  },
  unknown
>;

export type CreatePromptMutation = UseMutateFunction<
  PromptRow[],
  Error,
  Partial<PromptInsert>,
  unknown
>;

export type DeletePromptMutation = UseMutateFunction<
  PromptRow[],
  Error,
  {
    id: string;
  },
  unknown
>;

export type RunAiUpdatePrompt = UseMutateFunction<
  {
    prompt: PromptRow;
    responses: any;
  },
  Error,
  string,
  unknown
>;
