/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { CampaignRow } from "../../api/campaign.type";
import { LeadWithRelations } from "../../api/lead.type";
import { LeadCampaignRow } from "../../api/leadCampaign.type";
import { centsToUsDollars } from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import { useLoadCampaigns } from "../campaign/hooks/useLoadCampaigns";
import SelectCampaign from "../campaign/selectCampaign";
import GenericDialog from "../dialog/genericDialog";
import GenericSelect from "../forms/GenericSelect";
import ValueWithLabel from "../forms/valueWithLabel";
import { GlobalIcon, UserCircle } from "../icons/icons";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import { LinkToId } from "../table/linkToId";
import { useLeadContext } from "./leadContext";

function LeadDetailsAdmin({ lead }: { lead?: LeadWithRelations }) {
  const { campaigns } = useLoadCampaigns();
  const { updateLeadWithValues, addLeadToCampaign, useGetLeadById } =
    useLeadContext();

  const { createNewReimbursement } = useReimbursementContext();

  const [addCampaignOpen, setAddCampaignOpen] = useState<boolean>(false);
  const [addReimbursementOpen, setAddReimbursementOpen] =
    useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignRow>();

  const [selectedLeadCampaign, setSelectedLeadCampaign] =
    useState<LeadCampaignRow>();

  useEffect(() => {
    if (selectedCampaign && lead) {
      addLeadToCampaign({
        leadId: lead.id,
        campaignId: selectedCampaign.id,
      });
      setAddCampaignOpen(false);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedLeadCampaign && lead) {
      const foundCampaign = campaigns?.find(
        (c) => c.id === selectedLeadCampaign.campaign_id
      );

      const values = {
        lead_id: lead.id,
        campaign_id: selectedLeadCampaign.campaign_id,
        company_id: foundCampaign?.company_id,
        status: "pending",
      };

      createNewReimbursement(values);
      setAddReimbursementOpen(false);
      useGetLeadById.mutate(lead.id);
    }
  }, [selectedLeadCampaign]);

  if (!lead) {
    return <div>Lead not found</div>;
  }

  const {
    id,
    first_name,
    last_name,
    email,
    phone,
    city,
    state,
    zip,
    payment_account_type,
    payment_account_identifier,
    source,
    consent_to_sms_date,
    lead_campaign,
    reimbursement,
    sms_mode,
  } = lead;

  const leadCampaigns = lead?.lead_campaign || [];
  const leadCampaignsSortedByDate =
    leadCampaigns?.sort((a, b) => {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    }) || [];

  function toggleLeadMode() {
    if (lead) {
      const updatedLead = {
        id: lead?.id,
        sms_mode: lead?.sms_mode === "manual" ? null : "manual",
      };
      updateLeadWithValues(updatedLead);
    }
  }

  return (
    <div className="flex flex-col gap-10">
      <div>
        <Typography variant="h6">Lead Details</Typography>
        <Divider className="my-1" />
        <div className="my-4 grid grid-cols-4 w-full ">
          <div className="col-span-2">
            <ValueWithLabel label="ID" value={id} />
          </div>
        </div>
        <div className="my-4 grid grid-cols-4 w-full ">
          <div>
            <ValueWithLabel label="First name" value={first_name || "N/A"} />
          </div>
          <div>
            <ValueWithLabel label="Last name" value={last_name || "N/A"} />
          </div>
        </div>
        <div className="my-4 grid grid-cols-4 w-full ">
          <div>
            <ValueWithLabel label="Email" value={email || "N/A"} />
          </div>
          <div>
            <ValueWithLabel
              label="Phone"
              value={phone ? formatPhoneNumber(phone) : "N/A"}
            />
          </div>
          <div className="flex flex-row gap-5">
            <ValueWithLabel
              label="Mode"
              value={sms_mode ? snakeCaseToTitleCase(sms_mode) : "AI"}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                toggleLeadMode();
              }}
              color="blue-gray"
              size="sm"
              className="flex flex-row items-center justify-center gap-2"
            >
              {sms_mode && sms_mode === "manual" ? (
                <UserCircle className="h-5 w-5" />
              ) : (
                <GlobalIcon className="h-5 w-5" />
              )}
              Toggle mode
            </Button>
          </div>
        </div>
        <div className="my-4 grid grid-cols-4 w-full ">
          <div>
            <ValueWithLabel label="City" value={city || "N/A"} />
          </div>
          <div>
            <ValueWithLabel label="State" value={state || "N/A"} />
          </div>
          <div>
            <ValueWithLabel label="Zip" value={zip || "N/A"} />
          </div>
        </div>
      </div>
      <div>
        <Typography variant="h6">Payout Details</Typography>
        <Divider className="my-1" />
        <div className="my-4 grid grid-cols-4 w-full ">
          <div>
            <ValueWithLabel
              label="Payout account"
              value={payment_account_type || "N/A"}
            />
          </div>
          <div>
            <ValueWithLabel
              label="Handle"
              value={payment_account_identifier || "N/A"}
            />
          </div>
        </div>
      </div>
      <div>
        <Typography variant="h6">Source</Typography>
        <Divider className="my-1" />
        <div className="my-4 grid grid-cols-4 w-full ">
          <div>
            <ValueWithLabel
              label="Sign up date"
              value={formatTimeStampToHumanReadableShortDateTime(
                leadCampaignsSortedByDate[0].created_at
              )}
            />
          </div>
          <div>
            <ValueWithLabel
              label="Source"
              value={source === "try" ? "Try page" : source}
            />
          </div>
        </div>
        <div className="my-4 grid grid-cols-4 w-full ">
          <div>
            <ValueWithLabel
              label="Campaign"
              value={leadCampaignsSortedByDate[0].campaign?.name}
            />
          </div>
          <div>
            <ValueWithLabel
              label="Sms consent sign up date"
              value={formatTimeStampToHumanReadableShortDateTime(
                consent_to_sms_date
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-row justify-between">
          <Typography variant="h6">Campaigns</Typography>
          <button onClick={() => setAddCampaignOpen(!addCampaignOpen)}>
            <PlusCircleIcon className="h-6 w-6" />
          </button>
        </div>
        <Divider className="my-1" />
        {lead_campaign?.map((lc) => {
          return (
            <div className="my-4 grid grid-cols-4 w-full ">
              <div className="col-span-2">
                <ValueWithLabel label="Campaign" value={lc.campaign?.name} />
              </div>
              <div>
                <ValueWithLabel
                  label="Sign up date"
                  value={formatTimeStampToHumanReadableShortDateTime(
                    lc.created_at
                  )}
                />
              </div>
              <div>
                <ValueWithLabel
                  label="Status"
                  value={lc.status ? snakeCaseToTitleCase(lc.status) : "N/A"}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="flex flex-row justify-between">
          <Typography variant="h6">Reimbursements</Typography>
          <button
            onClick={() => setAddReimbursementOpen(!addReimbursementOpen)}
          >
            <PlusCircleIcon className="h-6 w-6" />
          </button>
        </div>
        <Divider className="my-1" />
        {reimbursement?.map((r) => {
          return (
            <div className="p-4 border-b border-blue-gray-50">
              <div className="my-4 grid grid-cols-4 w-full ">
                <div>
                  <ValueWithLabel
                    label="Campaign"
                    value={r.campaign?.name || "N/A"}
                  />
                </div>
                <div>
                  <ValueWithLabel
                    label="Amount"
                    value={
                      r.reimbursement_amount
                        ? centsToUsDollars(r.reimbursement_amount)
                        : r.payment_amount
                        ? centsToUsDollars(r.payment_amount)
                        : "N/A"
                    }
                  />
                </div>
                <div>
                  <ValueWithLabel
                    label="Status"
                    value={snakeCaseToTitleCase(r.status)}
                  />
                </div>
              </div>
              <div className="my-4 grid grid-cols-4 w-full ">
                <div>
                  <ValueWithLabel
                    label="Type"
                    value={r.type ? snakeCaseToTitleCase(r.type) : "N/A"}
                  />
                </div>
                <div>
                  <ValueWithLabel
                    label="Total Receipt Amount"
                    value={
                      r.receipt_total ? `$${String(r.receipt_total)}` : "N/A"
                    }
                  />
                </div>
                <div>
                  <LinkToId
                    type={"Reimbursement"}
                    id={r.id}
                    baseUrl={"/reimbursement/"}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <GenericDialog
        open={addCampaignOpen}
        setOpen={setAddCampaignOpen}
        title={`Add this lead to a campaign`}
      >
        <SelectCampaign
          campaigns={campaigns}
          setSelectedCampaign={setSelectedCampaign}
          selectedCampaign={selectedCampaign}
        />
      </GenericDialog>
      <GenericDialog
        open={addReimbursementOpen}
        setOpen={setAddReimbursementOpen}
        title={"Create a reimbursement for this lead"}
      >
        <GenericSelect
          values={leadCampaigns.map((lc) => lc.campaign?.name)}
          setSelected={(s) =>
            setSelectedLeadCampaign(
              leadCampaigns.find((lc) => lc.campaign?.name === s)
            )
          }
          selected={selectedLeadCampaign?.campaign?.name}
        />
      </GenericDialog>
    </div>
  );
}

export default LeadDetailsAdmin;
