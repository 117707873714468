import ReimbursementContainer from "../components/reimbursement/reimbursementContainer";
import { ReimbursementContextWrapper } from "../components/reimbursement/reimbursementContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Reimbursements = () => {
  mixpanelTrackEvent("Reimbursement page");

  return (
    <ReimbursementContextWrapper>
      <ReimbursementContainer />
    </ReimbursementContextWrapper>
  );
};

export default Reimbursements;
