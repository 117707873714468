import { UserPlusIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { useState } from "react";

import DownloadButton from "../downloadCsv/DownloadButton";
import { ColumnFilter } from "../table/columnFilter";
import TableFooter from "../table/tableFooter";

export type TabsType = {
  label: string;
  value: string;
};

export type TableHeadItem = {
  label: string;
  columnName?: string;
  filterValues?: string[];
};

export type FilterValue = {
  value: string;
  column: string;
};

export type CsvDownloadHeaders = {
  label: string;
  key: string;
};

export type DownloadData = {
  headers: CsvDownloadHeaders[];
  csvData: any[];
  fileName: string;
};

export type TableWithSearchProps = {
  tableHead: TableHeadItem[];
  type?: string;
  children: React.ReactNode;
  rowCount?: number | null;
  handleAddClick?: () => void;
  rowsPerPage: number;
  currentPage?: number;
  tabs?: TabsType[];
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  handleTabChange?: (e: any) => void;
  tabValue?: string;
  handleFilterValue?: (value: FilterValue) => void;
  search?: React.ReactNode;
  downloadData?: DownloadData;
  additionalButtons?: React.ReactNode;
  isLoading?: boolean;
};

export default function TableWithSearch({
  tabs,
  tableHead,
  children,
  rowCount,
  type,
  handleAddClick,
  handleNextPage,
  handlePreviousPage,
  rowsPerPage = 10,
  currentPage = 0,
  handleTabChange,
  tabValue,
  handleFilterValue,
  search,
  downloadData,
  additionalButtons,
  isLoading,
}: TableWithSearchProps) {
  const [download, setDownload] = useState(false);
  return (
    <div
      className="flex flex-col gap-4"
      style={{
        maxWidth: "100%",
      }}
    >
      <Card className="h-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" className="capitalize text-slate-500">
                {type}
              </Typography>

              {rowCount && rowCount > 0 && (
                <Typography color="gray" className="mt-1 font-normal">
                  Total count: {rowCount}
                </Typography>
              )}
            </div>

            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {handleAddClick && (
                <Button
                  className="flex items-center gap-3"
                  color="blue"
                  size="sm"
                  onClick={handleAddClick}
                >
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add{" "}
                  {type}
                </Button>
              )}
              {downloadData && downloadData?.csvData?.length > 0 && (
                <DownloadButton
                  downloadData={downloadData}
                  download={download}
                  setDownload={setDownload}
                />
              )}
              {additionalButtons && additionalButtons}
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            {tabs && (
              <Tabs value={tabValue} className="w-full md:w-max">
                <TabsHeader>
                  {tabs.map(({ label, value }: TabsType) => (
                    <Tab
                      key={value}
                      value={value}
                      onClick={() => handleTabChange && handleTabChange(value)}
                    >
                      {label}
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            )}
            {search}
          </div>
        </CardHeader>
        <CardBody className="px-0">
          <table className="mt-4 max-w-full min-w-full table-auto text-left">
            <thead>
              <tr>
                {tableHead.map((head, index) => (
                  <th
                    key={head.label}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head.label}{" "}
                      {index !== tableHead.length - 1 && head.filterValues && (
                        <ColumnFilter
                          head={head}
                          handleFilterValue={handleFilterValue}
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </CardBody>
        {rowCount && rowCount > 9 && (
          <TableFooter
            rowCount={rowCount}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            handleNextPage={handleNextPage && handleNextPage}
            handlePreviousPage={handlePreviousPage && handlePreviousPage}
            isLoading={isLoading}
          />
        )}
      </Card>
    </div>
  );
}
