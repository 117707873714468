/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Textarea, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useState } from "react";

import EditButton from "../buttons/editButton";
import { useReimbursementContext } from "./reimbursementContext";

function ReimbursementNotes() {
  const { activeReimbursement, updateReimbursement } =
    useReimbursementContext();
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState(activeReimbursement?.notes as string);

  function handleUpdateReimbursement() {
    updateReimbursement({
      id: activeReimbursement?.id as string,
      ...(note && {
        notes: note,
      }),
      updated_at: new Date().toISOString(),
    });
    setIsEdit(false);
  }

  return (
    <div className="w-full">
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Notes</Typography>
          <EditButton
            iconClass="h-4 w-4"
            text="Edit"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          />
        </div>
        <Divider className="my-1" />

        {!isEdit ? (
          <p className="text-slate-700">
            {activeReimbursement?.notes || "N/A"}
          </p>
        ) : (
          <div className="mb-2">
            <Textarea
              label="Notes"
              variant="outlined"
              value={note}
              className="focus:border-t-0 focus:ring-0"
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        )}

        {isEdit && (
          <div className="mt-6 flex justify-end gap-2">
            <Button
              size="sm"
              variant="outlined"
              color="gray"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="green"
              fullWidth={false}
              disabled={!isEdit}
              onClick={handleUpdateReimbursement}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReimbursementNotes;
