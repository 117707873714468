import { useSession } from "@supabase/auth-helpers-react";
import { Fragment } from "react";
import LoginPage from "../components/auth/LoginPage";
import { mixpanelTrackEvent } from "../utils/mixpanel";
import Analytics from "./analytics";

export default function Root() {
  const session = useSession();
  mixpanelTrackEvent("Sign in page");

  return <Fragment>{!session ? <LoginPage /> : <Analytics />}</Fragment>;
}
