import { Card, CardBody, Typography } from "@material-tailwind/react";
import { ChartPieIcon } from "../icons/icons";

function SegmentsHeaderCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <ChartPieIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Segments
        </Typography>
        <Typography>
          We have beta launched Segments! Segments allow you to group your
          customers based on their contact info, reimbursements, or other
          attributes. You can then use these segments to create targeted
          campaigns and flows.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default SegmentsHeaderCard;
