import { Button } from "@material-tailwind/react";
import { DeliveryMethods } from "../../api/campaign.type";
import InfoPopover from "../InfoPopover/infoPopover";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { MailIcon, PhoneIcon } from "../icons/icons";

function EditCampaignDeliveryType({
  selectedDeliveryType,
  setSelectedDeliveryType,
}: {
  selectedDeliveryType: DeliveryMethods | undefined;
  setSelectedDeliveryType: (value: DeliveryMethods) => void;
}) {
  return (
    <div className="relative w-fit ">
      <PlentiFormLabel>Delivery Method</PlentiFormLabel>
      <div className="flex flex-row gap-2">
        <Button
          className="flex flex-row gap-2 justify-center items-center"
          variant={selectedDeliveryType === "email_sms" ? "filled" : "outlined"}
          color={selectedDeliveryType === "email_sms" ? "purple" : "gray"}
          onClick={() => setSelectedDeliveryType("email_sms")}
        >
          <PhoneIcon className="w-6 h-6" /> or <MailIcon className="w-6 h-6" />
        </Button>

        <Button
          className="flex flex-row gap-2"
          variant={selectedDeliveryType === "sms" ? "filled" : "outlined"}
          color={selectedDeliveryType === "sms" ? "purple" : "gray"}
          onClick={() => setSelectedDeliveryType("sms")}
        >
          <PhoneIcon className="w-6 h-6" />
        </Button>

        <Button
          className="flex flex-row gap-2"
          variant={selectedDeliveryType === "email" ? "filled" : "outlined"}
          color={selectedDeliveryType === "email" ? "purple" : "gray"}
          onClick={() => setSelectedDeliveryType("email")}
        >
          <MailIcon className="w-6 h-6" />
        </Button>
      </div>

      <div className="absolute top-[-12px] right-[-15px]">
        <InfoPopover>
          <p className="text-slate-700">
            {selectedDeliveryType === "email_sms"
              ? "This campaign will be delivered via SMS/text message or email. You can set a backup message content if the user does not have an email or phone number."
              : selectedDeliveryType === "email"
              ? "This campaign will be delivered via email. If the user does not have an email, the campaign message will not be delivered."
              : "This campaign will be delivered via SMS/text message. If the user does not have a phone number, the campaign message will not be delivered"}
          </p>
        </InfoPopover>
      </div>
    </div>
  );
}

export default EditCampaignDeliveryType;
