/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  UseAddContentMutation,
  UseGetSmsContentByIdMutation,
  UseUpdateContentMutation,
} from "../smsContent/smsContext.types";

import {
  createSmsContent,
  getSmsContent,
  getSmsContentById,
  updateSmsContentById,
} from "../../api/smsContent.api";
import { SmsContentRow } from "../../api/smsContent.type";
import { getSmsContentAverageResponse } from "../../api/smsContentMetadata.api";
import { GetStatsResponse } from "../../api/smsContentMetadata.type";
import { useAlertContext } from "../alert/alertContext";
import { useUserContext } from "../user/userContext";

export const SmsContentContextWrapper = (props: any) => {
  const { user } = useUserContext();

  const queryClient = useQueryClient();
  const { data: smsContents } = useQuery({
    queryKey: "smsContent",
    queryFn: () => getSmsContent(),
    enabled: !!user,
  });

  const { setAlertProps } = useAlertContext();

  const [activeSmsContent, setActiveSmsContent] = useState<SmsContentRow>();

  const useGetSmsContentById = useMutation(getSmsContentById, {
    onSuccess: async (res) => {
      setActiveSmsContent(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: getAverageResponsePerSmsContent, data: contentStats } =
    useMutation(getSmsContentAverageResponse, {
      onSuccess: async (res) => {},
      onError(error: Error) {
        console.log(error);
      },
    });

  const { mutate: updateContent } = useMutation(updateSmsContentById, {
    onSuccess: async (res) => {
      setActiveSmsContent(res);
      queryClient.invalidateQueries("smsContent");
      queryClient.invalidateQueries("campaignSmsContent");
      setAlertProps({
        message: `SMS Content Updated Successfully`,
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: addContent } = useMutation(createSmsContent, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("smsContent");
      queryClient.invalidateQueries("campaignSmsContent");

      setAlertProps({
        message: `SMS Content Added Successfully`,
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (activeSmsContent) {
      getAverageResponsePerSmsContent(activeSmsContent.id);
    }
  }, [activeSmsContent]);

  const value = {
    smsContents,
    useGetSmsContentById,
    activeSmsContent,
    updateContent,
    addContent,
    contentStats,
  };

  return (
    <SmsContentContext.Provider value={value}>
      {props.children}
    </SmsContentContext.Provider>
  );
};

export const SmsContentContext = createContext({
  contentStats: {} as GetStatsResponse | undefined,
  smsContents: [] as SmsContentRow[] | undefined,
  useGetSmsContentById: {} as UseGetSmsContentByIdMutation,
  activeSmsContent: {} as SmsContentRow | undefined,
  updateContent: {} as UseUpdateContentMutation,
  addContent: {} as UseAddContentMutation,
});

export const useSmsContentContext = () => useContext(SmsContentContext);
