/* eslint-disable react/jsx-no-undef */
import { Select, SelectItem } from "@tremor/react";
import { numericMonthYearToString } from "../../utils/parseTimeStamp";

export type Months = {
  month: number;
  year: number;
  id: string;
};

export type SelectMonthProps = {
  months: Months[];
  setSelectedItem: (value: Months) => void;
  selectedMonth: any;
};

function SelectMonth({
  months,
  setSelectedItem,
  selectedMonth,
}: SelectMonthProps) {
  return (
    <Select
      value={selectedMonth?.id || ""}
      onChange={(value: any) => {
        const found = months?.find((m) => m.id === value);
        if (found) {
          setSelectedItem(found);
        }
      }}
      className="focus:border-t-0 focus:ring-0"
    >
      {months.map((item) => {
        const { month, year, id } = item;

        return (
          <SelectItem key={id} value={id}>
            {numericMonthYearToString(month, year)}
          </SelectItem>
        );
      })}
    </Select>
  );
}

export default SelectMonth;
