import { UserCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { formatFullDateToShortDate } from "../../utils/parseTimeStamp";

// Define the props interface for type safety
interface EmailHeaderProps {
  fromName: string;
  toName: string;
  timestamp: string; // You could use Date type if you handle the date formatting inside the component
  avatarUrl: string; // URL to the sender's avatar image
}

const EmailHeader: React.FC<EmailHeaderProps> = ({
  fromName,
  toName,
  timestamp,
  avatarUrl,
}) => {
  const headerStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f2f2f2",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
  };

  const textStyle = {
    margin: "0 5px",
  };

  return (
    <div style={headerStyle}>
      <UserCircleIcon color="orange" className="h-16 w-16" />
      <div style={{ flex: 1 }}>
        <div style={textStyle}>
          <strong>From:</strong> {fromName}
        </div>
        <div style={textStyle}>
          <strong>To:</strong> {toName}
        </div>
      </div>
      <div style={textStyle}>{formatFullDateToShortDate(timestamp)}</div>
    </div>
  );
};

export default EmailHeader;
