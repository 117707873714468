import { Button } from "@material-tailwind/react";

import { PencilIcon } from "../icons/icons";

function EditButton({
  text,
  onClick,
  iconClass,
  ...otherProps
}: {
  text: string;
  onClick: () => void;
  iconClass: string;
  otherProps?: any;
}) {
  return (
    <Button
      className="flex items-center gap-2 p-1"
      onClick={onClick}
      {...otherProps}
      variant="outlined"
      color="gray"
      size="sm"
    >
      <PencilIcon className={iconClass} />
      {text}
    </Button>
  );
}

export default EditButton;
