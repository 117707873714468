import { useEffect, useState } from "react";

import { AssistantMessage } from "../lead/assistantMessage";

import { SmsMessage } from "../../api/sms.type";
import { formatTimeStampToHumanReadableDateTimeSeconds } from "../../utils/parseTimeStamp";
import ImageCarousel from "../images/ImageCarousel";
import { ChatUserType } from "../../api/leadContext.types";

function UserMessage({
  content,
  date,
  messagesEndRef,
  children,
}: {
  content: string;
  date: string;
  messagesEndRef: any;
  children?: any;
}) {
  return (
    <div className="mx-3 mb-6 flex flex-wrap justify-end text-right">
      <div className="flex-0 w-auto max-w-full px-3">
        <div className="shadow-soft-xl relative flex min-w-0 flex-col break-words rounded-2xl border-0  bg-clip-border">
          <div className="flex-auto px-4 py-2">
            <p>{content}</p>
            {children}
            <div className="flex items-center justify-end text-sm leading-normal opacity-60">
              <small>
                {formatTimeStampToHumanReadableDateTimeSeconds(date)}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div style={{ float: "left", clear: "both" }} ref={messagesEndRef}></div>
    </div>
  );
}

export function ChatHistoryItem({
  message,
  messagesEndRef,
}: {
  message: Partial<SmsMessage>;
  messagesEndRef: any;
}) {
  const { role, content, created_at, media_url, image_metadata } = message;

  const anyMetadataUrl =
    image_metadata &&
    image_metadata?.length > 0 &&
    image_metadata?.find((im) => im.media_url)
      ? true
      : false;

  const line =
    role === ChatUserType.assistant ||
    role === ChatUserType.system ||
    role === ChatUserType.function ||
    role === ChatUserType.tool ? (
      <AssistantMessage
        role={role}
        content={content as string}
        date={created_at as string}
      >
        {media_url && <img src={media_url} alt="Temp" />}
      </AssistantMessage>
    ) : role === ChatUserType.user ? (
      <UserMessage
        content={content as string}
        date={created_at as string}
        messagesEndRef={messagesEndRef}
      >
        {anyMetadataUrl && image_metadata && image_metadata?.length > 1 ? (
          <ImageCarousel images={image_metadata} />
        ) : (
          media_url && <img src={media_url} alt="Temp" />
        )}
      </UserMessage>
    ) : (
      <></>
    );
  return line;
}

export function LoadingMessage() {
  const [loadingEllipsis, setLoadingElipsis] = useState("...");
  useEffect(() => {
    let count = 1;
    const maxDots = 4;

    function getNextEllipsis(): string {
      const ellipsis = ".".repeat(count);
      return ellipsis + " ".repeat(maxDots - count);
    }

    setInterval(() => {
      setLoadingElipsis(getNextEllipsis());
      if (count === maxDots) {
        count = 1;
      } else {
        count++;
      }
    }, 1000);
  }, []);
  return (
    <div className="-mx-3 mb-6 flex flex-wrap justify-start">
      <div className="flex-0 w-auto max-w-full px-3">
        <div className="shadow-soft-xl relative flex min-w-0 flex-col break-words rounded-2xl border-0 bg-white bg-clip-border">
          <div className="flex-auto px-4 py-2">
            <p className="mb-1">{"Typing" + loadingEllipsis}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatHistoryItem;
