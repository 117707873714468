import { SmsContentContextWrapper } from "../smsContent/smsContentContext";
import { CampaignContextWrapper } from "../campaign/campaignContext";
import AddFlowForm from "../flow/addFlowForm";
import AddFlowHeader from "./addFlowHeader";
import { SegmentContextWrapper } from "../segments/hooks/SegmentContext";

function AddFlowContainer() {
  return (
    <CampaignContextWrapper>
      <SmsContentContextWrapper>
        <SegmentContextWrapper>
          <div className="w-full flex flex-col p-5 gap-10">
            <AddFlowHeader />
            <AddFlowForm />
          </div>
        </SegmentContextWrapper>
      </SmsContentContextWrapper>
    </CampaignContextWrapper>
  );
}

export default AddFlowContainer;
