import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { EmailTemplateRow } from "../../api/emailTemplate.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { useNavigate } from "react-router-dom";

export type MappedEmailTemplate = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  name: string;
};

function AdminEmailTableList({
  emailTemplates,
}: {
  emailTemplates: EmailTemplateRow[] | undefined;
}) {
  const navigate = useNavigate();
  function mapPromptsToRow(
    emailTemplate: EmailTemplateRow
  ): MappedEmailTemplate {
    const values = {
      id: emailTemplate.id,
      createdAt: formatTimeStampToHumanReadableShortDateTime(
        emailTemplate.created_at
      ),
      updatedAt: formatTimeStampToHumanReadableShortDateTime(
        emailTemplate.updated_at
      ),
      createdBy: emailTemplate.users ? emailTemplate.users.email : "",
      name: emailTemplate.name,
    };

    return values;
  }

  return (
    <Fragment>
      {emailTemplates &&
        emailTemplates
          .map((item) => mapPromptsToRow(item))
          .map((emailTemplate, index) => {
            const { createdAt, createdBy, id, name, updatedAt } = emailTemplate;
            const isLast = index === emailTemplates.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr
                key={index}
                className="even:bg-blue-gray-50/50"
                onClick={() => navigate(`/content/email/${id}`)}
              >
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {id}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {name}
                    </Typography>
                  </div>
                </td>

                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {createdAt}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {updatedAt}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {createdBy}
                  </Typography>
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default AdminEmailTableList;
