/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import CompanyCard from "./CompanyCard";
import { useCompanyContext } from "./companyContext";
import PlentiTabs from "../tab/plentiTab";
import EditCompany from "./editCompany";
import CompanyDetailsContainer from "./companyDetailsContainer";
import CompanyUsersList from "./CompanyUserList";
import IntegrationsContainer from "./IntegrationsContainer";
import { CampaignContextWrapper } from "../campaign/campaignContext";

const tabs = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Integrations",
    value: "integrations",
  },
  {
    label: "Try Page Details",
    value: "edit",
  },
  {
    label: "Users",
    value: "users",
  },
];

function StandardCompanyContainer({ id }: { id?: string }) {
  const { useGetCompanyConfig, useGetCompanyById } = useCompanyContext();

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const { value } = activeTab;

  useEffect(() => {
    if (id && typeof id === "string") {
      useGetCompanyById.mutate(id);
      useGetCompanyConfig.mutate(id);
    }
  }, [id]);
  return (
    <CampaignContextWrapper>
      <div className="w-full flex flex-col gap-10 p-5">
        <div className="flex justify-between">
          <CompanyCard />
        </div>
        <PlentiTabs
          tabConfig={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {value === "overview" && <CompanyDetailsContainer />}
        {value === "integrations" && <IntegrationsContainer />}
        {value === "edit" && <EditCompany id={id} />}
        {value === "users" && <CompanyUsersList />}
      </div>
    </CampaignContextWrapper>
  );
}

export default StandardCompanyContainer;
