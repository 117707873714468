/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useNavigate } from "react-router-dom";
import LearnMoreButton from "../link/LearnMoreButton";
import { LinkToId } from "../table/linkToId";

function IntegrationsContainer() {
  const navigate = useNavigate();
  return (
    <div className="max-w-2xl">
      <div className="flex justify-between">
        <Typography variant="h6">Shopify</Typography>
        <Button
          className="flex items-center gap-2 p-1"
          onClick={() => {
            navigate("/integrations/shopify");
          }}
          variant="text"
          color="gray"
          size="sm"
        >
          Details
        </Button>
      </div>
      <Divider className="my-1" />
      <div className="my-4 grid w-full gap-10">
        <div className="flex flex-row justify-between">
          <LinkToId
            type="Link to Plenti's Shopify app"
            id={"https://apps.shopify.com/plenti-ai-1"}
            baseUrl={""}
          />
          <LearnMoreButton
            variant="text"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=3bulq8Xlx5s",
                "_blank"
              )
            }
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Typography variant="h6">Email</Typography>
        <Button
          className="flex items-center gap-2 p-1"
          onClick={() => {
            navigate("/integrations/email");
          }}
          variant="text"
          color="gray"
          size="sm"
        >
          Details
        </Button>
      </div>
      <Divider className="my-1" />
      <div className="my-4 grid w-full gap-10">
        <div className="flex flex-row justify-end">
          <LearnMoreButton
            variant="text"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=AZrbrnbqjM4",
                "_blank"
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default IntegrationsContainer;
