import { useQuery } from "react-query";
import { getCampaigns } from "../../../api/campaign.api";

export const useLoadCampaigns = () => {
  const { data: campaigns, isLoading } = useQuery({
    queryKey: "campaigns",
    queryFn: () => getCampaigns(),
  });

  return { campaigns, isLoading };
};
