import { ChatMessage } from "./leadContext.types";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { ChatResponse } from "./openAi.type";

export function testChat({
  campaignId,
  messages,
  leadId,
  companyId,
}: {
  campaignId: string;
  messages: ChatMessage[];
  leadId: string;
  companyId: string;
}): Promise<ChatResponse> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.openai + "/chat-test";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ campaignId, messages, leadId, companyId }),
  });

  return fetchData<ChatResponse>(url, request);
}
