import { Carousel, IconButton } from "@material-tailwind/react";
import { ImageMetadataRow } from "../../api/imageMetadata.type";
import { CarouselLeftArrow, CarouselRightArrow } from "../icons/icons";
import { Fragment } from "react";

function ImageCarousel({
  images,
  setActiveImage,
}: {
  images: ImageMetadataRow[];
  setActiveImage?: (image: ImageMetadataRow) => void;
}) {
  return (
    <Carousel
      className="rounded-xl"
      prevArrow={({ handlePrev }) => {
        return (
          <Fragment>
            {images.length > 1 ? (
              <IconButton
                variant="text"
                color="orange"
                size="lg"
                onClick={handlePrev}
                className="!absolute top-2/4 left-4 -translate-y-2/4 shadow-soft-xl"
              >
                <CarouselLeftArrow className="h-12 h-12" />
              </IconButton>
            ) : null}
          </Fragment>
        );
      }}
      nextArrow={({ handleNext }) => {
        return (
          <Fragment>
            {images.length > 1 ? (
              <IconButton
                variant="text"
                color="orange"
                size="lg"
                onClick={handleNext}
                className="!absolute top-2/4 !right-4 -translate-y-2/4 shadow-soft-xl"
              >
                <CarouselRightArrow className="h-12 w-12" />
              </IconButton>
            ) : null}
          </Fragment>
        );
      }}
      navigation={({ length, activeIndex }) => {
        const activeImage = images[activeIndex];

        if (setActiveImage) {
          setActiveImage(activeImage);
        }
        if (length < 2) return null;
        if (length > 10) return null;
        return (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <div
                className="h-6 w-6 bg-black"
                style={{
                  borderRadius: "50%",
                  border: "2px solid white",
                }}
              />
            ))}
          </div>
        );
      }}
    >
      {images?.map((image: ImageMetadataRow, index: number) => (
        <img
          key={index}
          src={image.media_url}
          alt="Temp"
          className="w-full object-cover"
        />
      ))}
    </Carousel>
  );
}

export default ImageCarousel;
