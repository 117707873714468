/* eslint-disable react-hooks/exhaustive-deps */
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useAlertContext } from "../alert/alertContext";
import { ButtonPrimary } from "../buttons/ButtonPrimary";
import { useStripeContext } from "./stripeContext";

const SetupForm = ({
  setIsAddPayment,
}: {
  setIsAddPayment: (isAddPayment: boolean) => void;
}) => {
  const { useGetSetupIntent, setupClientSecret, getPaymentAccounts } =
    useStripeContext();
  const { setAlertProps } = useAlertContext();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const handleError = (error: any) => {
    setLoading(false);
    setAlertProps({
      message: error.message,
      color: "red",
    });
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("Stripe.js hasn't yet loaded.");
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret: setupClientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url:
          process.env.REACT_APP_ENV === "development"
            ? "http://localhost:3000/billing"
            : "https://app.plentai.com/billing",
      },
    });

    if (error) {
      console.log(error);
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      getPaymentAccounts();
      setAlertProps({
        message: "Payment method added successfully",
        color: "green",
      });
      setIsAddPayment(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    useGetSetupIntent.mutate();
  }, []);

  return (
    <form>
      <PaymentElement />
      <div className="mt-5">
        <ButtonPrimary
          disabled={loading}
          onClick={() => handleSubmit()}
          size="md"
        >
          Submit
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default SetupForm;
