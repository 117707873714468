export type AgentRow = {
  name: string;
  status: string;
  description: string;
  cost: string;
};

export const agentList = [
  {
    name: "Reimbursement Agent",
    status: "enabled",
    description:
      "The reimbursement agents powers the ability to reimburse users for in-store purchases by parsing their receipts and verifying their purchases. This agent is the core of the app. It powers the ability to pull information from the receipt and to run our fraud algorithms.",
    cost: "%1 of reimbursement amount plus the cost of moving money",
  },
  {
    name: "Location Agent",
    status: "enabled",
    description:
      "The location agent powers the ability to direct users to the nearest location to purchase the product. The user can enter a zip code or address and the agent will query the database for the nearest location where your product is sold.",
    cost: "Included",
  },
  {
    name: "Data Extraction Agent",
    status: "enabled",
    description:
      "The data extraction agent powers the ability to extract data from users. You can create custom forms and the agent will prompt the user to fill out the form. ",
    cost: "Included",
  },

  {
    name: "Merchandising Agent",
    status: "enabled",
    description:
      "Imagine if your customers were also your merchandisers! This agent rewards customers by fixing in-store product displays, checking for out of stocks, confirming pricing and ensure sale tags are up!  This agent is enabled by default. Click here to toggle it on/off.",
    cost: "$4 per user/merchandising task completed",
  },
  {
    name: "Loyalty Program Agent",
    status: "enabled",
    description:
      "Getting new customers is nice. But getting long term customers is even better. This agent powers the ability to create a loyalty program for your customers. They can upload receipts on an ongoing basis and build up points that can be redeemed. This agent is disabled by default. If you would like to enable it please contact us.",
    cost: "$1 per user/receipt uploaded",
  },
  {
    name: "Influencer Agent",
    status: "Coming soon!",
    description:
      "Incentivize influencers to promote your product! This agent incentivizes your customers to be ambassadors while they are shopping in store!  Customers share your product on social media and we reward them based on the number of followers they have. This agent is coming soon!",
    cost: "Coming soon!",
  },
];
