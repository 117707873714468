import ContentContainer from "../components/smsContent/ContentContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const SmsContent = () => {
  mixpanelTrackEvent("Sms content page");

  return <ContentContainer />;
};

export default SmsContent;
