/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useTryContext } from "../try/tryContext";

function TryHeroImage() {
  const { companyConfig, campaign, forDemo } = useTryContext();
  return (
    <div
      className={
        forDemo
          ? "my-auto justify-center py-10"
          : "my-auto justify-center py-10 md:min-w-[700px]"
      }
    >
      <img
        width={forDemo ? 300 : 700}
        height={forDemo ? 300 : 700}
        src={
          campaign && campaign?.hero_image_url
            ? campaign?.hero_image_url
            : companyConfig?.hero_image_url || "#"
        }
        alt="hero "
      />
    </div>
  );
}

export default TryHeroImage;
