import { ExtractionResponseRow } from "../../../api/extractionResponse.type";
import { formatFullDateToShortDate } from "../../../utils/parseTimeStamp";
import { CsvData } from "../../lead/mapLeadToCvsData";

export const mapResponsesToCsvData = (
  responses: ExtractionResponseRow[] | undefined
): {
  csvData: CsvData[];
  headers: any[];
} => {
  if (!responses) return { csvData: [], headers: [] };
  const csvData = responses.map((response) => {
    return {
      id: response.id,
      createdAt: formatFullDateToShortDate(response.created_at),
      campaignId: response.campaign_id,
      question_id: response.question_id,
      question_name: response.question_name,
      question_type: response.question_type,
      question_answer: response.question_answer,
      lead_id: response.lead_id,
    };
  });

  const headers = [
    { label: "ID", key: "id" },
    { label: "Created At", key: "createdAt" },
    { label: "Campaign ID", key: "campaignId" },
    { label: "QuestionID", key: "question_id" },
    { label: "Question Name", key: "question_name" },
    { label: "Question Type", key: "question_type" },
    { label: "Question Answer", key: "question_answer" },
    { label: "Lead ID", key: "lead_id" },
  ];

  return {
    csvData,
    headers,
  };
};
