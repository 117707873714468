/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import { Switch } from "@headlessui/react";
import PlentiUpdateOrCancelButtons from "../../forms/PlentiUpdateOrCancelButtons";
import { useCampaignContext } from "../campaignContext";
import FollowUpExample from "./followUpExample";
import {
  FollowUpContextWrapper,
  useFollowUpContext,
} from "./followUpMessageContext";
import FollowUpSelect from "./followUpSelect";

function _CampaignFollowupContainer() {
  const { isEdit, setIsEdit, isActive, handleUpdateRecurringPattern } =
    useFollowUpContext();
  const { updateCampaign, activeCampaign } = useCampaignContext();
  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Typography variant="h6">Follow-up frequency</Typography>
      </div>

      <Divider className="my-1" />
      <div className="flex justify-between mt-5">
        <p>Schedule follow-up messages</p>
        <Switch.Group>
          <div className="flex flex-col items-center">
            <Switch.Label passive>
              Follow up messages {isActive ? "enabled" : "disabled"}
            </Switch.Label>
            <Switch
              className={`${
                isActive ? "bg-green-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
              checked={activeCampaign?.follow_up_disabled ? false : true}
              onChange={() => {
                updateCampaign({
                  id: activeCampaign?.id as string,
                  follow_up_disabled: !activeCampaign?.follow_up_disabled,
                });
              }}
            >
              <span
                className={`${
                  isActive ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        </Switch.Group>
      </div>
      {isActive && <FollowUpSelect />}
      {isActive && <FollowUpExample />}

      {isEdit && (
        <PlentiUpdateOrCancelButtons
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          handleSubmit={handleUpdateRecurringPattern}
        />
      )}
    </div>
  );
}

function CampaignFollowupContainer() {
  return (
    <FollowUpContextWrapper>
      <_CampaignFollowupContainer />
    </FollowUpContextWrapper>
  );
}

export default CampaignFollowupContainer;
