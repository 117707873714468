import { SearchSelect, SearchSelectItem } from "@tremor/react";

import { CampaignRow } from "../../api/campaign.type";

function SelectCampaign({
  campaigns,
  setSelectedCampaign,
  selectedCampaign,
}: {
  campaigns: CampaignRow[] | undefined;
  setSelectedCampaign: (campaign: CampaignRow | undefined) => void;
  selectedCampaign?: CampaignRow;
}) {
  return (
    <div>
      {campaigns && campaigns.length > 0 && (
        <SearchSelect
          value={selectedCampaign?.id || ""}
          onChange={(e: any) => {
            const found = campaigns?.find((c: CampaignRow) => c.id === e);
            if (found) {
              setSelectedCampaign(found);
            }
          }}
          enableClear
          onValueChange={(e: any) => {
            if (e === "") {
              setSelectedCampaign(undefined);
            }
          }}
        >
          {campaigns &&
            campaigns?.map((campaign: CampaignRow) => {
              return (
                <SearchSelectItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </SearchSelectItem>
              );
            })}
        </SearchSelect>
      )}
    </div>
  );
}

export default SelectCampaign;
