import FlowHeaderCard from "./FlowHeaderCard";

function FlowHeader() {
  return (
    <div className="flex flex-col">
      <FlowHeaderCard />
    </div>
  );
}

export default FlowHeader;
