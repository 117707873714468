import { StarIcon } from "@heroicons/react/24/solid";
import { SmsContentRow } from "../../api/smsContent.type";
import { EmailTemplateRow } from "../../api/emailTemplate.type";

function ContentMenuItem({
  content,
}: {
  content: SmsContentRow | EmailTemplateRow;
}) {
  return (
    <div className="flex flex-row items-center">
      {content?.type === "ai_generated" ? (
        <div className="flex items-center gap-2">
          <StarIcon className="w-4 w-4" />
          <span>AI Generated {"- "}</span>
        </div>
      ) : (
        ""
      )}
      {content?.name?.slice(0, 200) || "Untitled"}
    </div>
  );
}

export default ContentMenuItem;
