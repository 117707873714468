import { Card, CardBody, Typography } from "@material-tailwind/react";
import { ChartIcon } from "../icons/icons";

function AnalyticsCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <ChartIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Analytics
        </Typography>
        <Typography>
          Get insights into your marketing outreach{" "}
          {String.fromCodePoint(0x1f4c8)}. Analytics let you see how your
          customers are engaging with your brand.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default AnalyticsCard;
