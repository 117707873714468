import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CompanyUsersRow, InsertCompanyUser } from "./companyUser";

export function getAllCompanyUsers(
  companyId: string
): Promise<CompanyUsersRow[]> {
  let url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.usersCompanies +
    "/" +
    companyId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CompanyUsersRow[]>(url, request);
}

export function createCompanyUser(
  data: InsertCompanyUser
): Promise<CompanyUsersRow> {
  let url = process.env.REACT_APP_API_URL + ApiEndpoints.usersCompanies;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(data),
  });

  return fetchData<CompanyUsersRow>(url, request);
}
