import { CampaignRow } from "../../api/campaign.type";
import { CompanyRow } from "../../api/company.type";
import { TryContextWrapper } from "../try/tryContext";

function PhoneDisplayLayout({
  children,
  company,
  campaign,
}: {
  children: React.ReactNode;
  company?: CompanyRow;
  campaign?: CampaignRow;
}) {
  return (
    <TryContextWrapper company={company} forDemo={true} demoCampaign={campaign}>
      <div
        className="relative h-[700px] w-[350px] bg-cover bg-center bg-no-repeat px-6 py-2"
        style={{ backgroundImage: "url(/images/iphone.jpg)" }}
      >
        <div
          className="absolute top-[3%] left-[6%] h-[94%] w-[88%] overflow-auto bg-white"
          style={{ borderRadius: "40px" }}
        >
          {children}
        </div>
      </div>
    </TryContextWrapper>
  );
}

export default PhoneDisplayLayout;
