import OcrContainer from "../components/ocr/ocrContainer";
import { OcrContextWrapper } from "../components/ocr/ocrContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Ocr = () => {
  mixpanelTrackEvent("Ocr page");

  return (
    <OcrContextWrapper>
      <OcrContainer />
    </OcrContextWrapper>
  );
};

export default Ocr;
