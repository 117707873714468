/* eslint-disable react-hooks/exhaustive-deps */
import { Textarea, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import EditButton from "../buttons/editButton";
import { useCampaignContext } from "../campaign/campaignContext";
import SelectFont from "../font/selectFont";
import SelectFontWeight from "../font/selectFontWeight";
import PlentiUpdateOrCancelButtons from "../forms/PlentiUpdateOrCancelButtons";
import FileUploadComponentWithContext from "../forms/fileUploadComponentWithContext";
import { getDirtyValues } from "../forms/getDirtyValues";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import ViewImage from "../table/viewImage";

type CampaignTryDetailsFormProps = {
  tag_line: string;
  step_one_text: string;
  hero_image_url: string;
  title: string;
};

function CampaignTryDetailsContainer() {
  const { activeCampaign, updateCampaign, loading } = useCampaignContext();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTitleFont, setSelectedTitleFont] = useState<string>("");
  const [selectedSubtitleFont, setSelectedSubtitleFont] = useState<string>("");
  const [selectedParagraphFont, setSelectedParagraphFont] =
    useState<string>("");

  const [selectedSubtitleFontWeight, setSelectedSubtitleFontWeight] =
    useState<number>(0);
  const [selectedParagraphFontWeight, setSelectedParagraphFontWeight] =
    useState<number>(0);
  const [titleFontWeight, setTitleFontWeight] = useState<number>(0);

  const campaign = useMemo(() => {
    return activeCampaign;
  }, [activeCampaign]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      tag_line: campaign?.tag_line as string,
      step_one_text: campaign?.step_one_text as string,
      hero_image_url: campaign?.hero_image_url as string,
      title: campaign?.title as string,
    },
  });

  const onSubmit: SubmitHandler<CampaignTryDetailsFormProps> = (
    data: CampaignTryDetailsFormProps
  ) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);

    const values = {
      id: activeCampaign?.id,
      ...dirtyValues,
      ...(selectedTitleFont && { title_font_id: selectedTitleFont }),
      ...(selectedSubtitleFont && { subtitle_font_id: selectedSubtitleFont }),
      ...(selectedParagraphFont && {
        paragraph_font_id: selectedParagraphFont,
      }),
      ...(selectedSubtitleFontWeight && {
        subtitle_font_weight: selectedSubtitleFontWeight,
      }),
      ...(selectedParagraphFontWeight && {
        paragraph_font_weight: selectedParagraphFontWeight,
      }),
      ...(titleFontWeight && { title_font_weight: titleFontWeight }),
    };

    updateCampaign(values);
    setIsEdit(false);
  };

  useEffect(() => {
    reset({
      tag_line: activeCampaign?.tag_line,
      step_one_text: activeCampaign?.step_one_text,
      hero_image_url: activeCampaign?.hero_image_url,
      title: activeCampaign?.title,
    });
  }, [activeCampaign]);

  if (!activeCampaign) {
    return <div>Error: No Campaign</div>;
  }

  const {
    tag_line,
    step_one_text,
    hero_image_url,
    title,
    title_font,
    subtitle_font,
    paragraph_font,
  } = activeCampaign;

  return (
    <div className="w-full" id="try-details">
      {activeCampaign && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <Typography variant="h6">Try Page Details</Typography>
            <EditButton
              iconClass="h-4 w-4"
              text="Edit"
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            />
          </div>
          <Divider className="my-1" />
          <div className="my-4 grid grid-cols-3 w-full gap-10">
            <div className="flex flex-col gap-6 col-span-3">
              {isEdit ? (
                <FileUploadComponentWithContext
                  imgUrl={hero_image_url}
                  handleFilePath={(path) => {
                    updateCampaign({
                      id: activeCampaign?.id,
                      hero_image_url: path,
                    });
                    setIsEdit(false);
                  }}
                />
              ) : (
                <div>
                  {loading ? (
                    <PlentiLogoLoading className="h-12 w-12 mx-auto" />
                  ) : (
                    <ViewImage title="Hero Image" src={hero_image_url} />
                  )}
                </div>
              )}
            </div>
            <div className="col-span-2">
              {isEdit ? (
                <Textarea
                  {...register("title")}
                  label="Title"
                  className="focus:border-t-0 focus:ring-0"
                />
              ) : (
                <ValueWithLabel
                  label="Title"
                  value={title || ""}
                  font={(title_font?.tailwind_name as string) || ""}
                />
              )}
            </div>
            {isEdit && (
              <div className="col-span-1">
                <div className="flex flex-col gap-2">
                  <SelectFont
                    handleSelected={(selected) =>
                      setSelectedTitleFont(selected.id)
                    }
                    startingFont={campaign?.title_font}
                  />
                  <SelectFontWeight
                    handleSelected={(selected) =>
                      setTitleFontWeight(Number(selected))
                    }
                    startingWeight={campaign?.title_font_weight}
                  />
                </div>
              </div>
            )}
            <div className="col-span-2">
              {isEdit ? (
                <Textarea
                  {...register("tag_line")}
                  label="Tag line"
                  className="focus:border-t-0 focus:ring-0"
                />
              ) : (
                <ValueWithLabel
                  label="Tag line"
                  value={tag_line || ""}
                  font={(paragraph_font?.tailwind_name as string) || ""}
                />
              )}
            </div>
            {isEdit && (
              <div className="col-span-1">
                <div className="flex flex-col gap-2">
                  <SelectFont
                    handleSelected={(selected) =>
                      setSelectedParagraphFont(selected.id)
                    }
                    startingFont={campaign?.paragraph_font}
                  />
                  <SelectFontWeight
                    handleSelected={(selected) =>
                      setSelectedParagraphFontWeight(Number(selected))
                    }
                    startingWeight={campaign?.paragraph_font_weight}
                  />
                </div>
              </div>
            )}

            <div className="col-span-2">
              {isEdit ? (
                <PlentiInput
                  register={register}
                  name="step_one_text"
                  label="Step one text"
                  errors={errors}
                  required={false}
                />
              ) : (
                <ValueWithLabel
                  label="Step one text"
                  value={step_one_text || ""}
                  font={(subtitle_font?.tailwind_name as string) || ""}
                />
              )}
            </div>
            {isEdit && (
              <div className="col-span-1">
                <div className="flex flex-col gap-2">
                  <SelectFont
                    handleSelected={(selected) =>
                      setSelectedSubtitleFont(selected.id)
                    }
                    startingFont={campaign?.subtitle_font}
                  />
                  <SelectFontWeight
                    handleSelected={(selected) =>
                      setSelectedSubtitleFontWeight(Number(selected))
                    }
                    startingWeight={campaign?.subtitle_font_weight}
                  />
                </div>
              </div>
            )}
          </div>

          {isEdit && (
            <PlentiUpdateOrCancelButtons
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          )}
        </form>
      )}
    </div>
  );
}

export default CampaignTryDetailsContainer;
