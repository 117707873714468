export const exampleMergeTags = {
  abandonedCheckoutUrl: "https://www.example.com/checkout/abandoned",
  first_name: "John",
  last_name: "Doe",
};

export const parseTextWithMergeTags = (text: string) => {
  if (!text) {
    return "";
  }

  const mergeTags = Object.keys(exampleMergeTags);

  if (!mergeTags.length) {
    return text;
  }

  const regex = new RegExp(`{{(${mergeTags.join("|")})}}`, "g");

  return text.replace(regex, (match) => {
    const key = match.replace(/{{|}}/g, "");
    return exampleMergeTags[key as keyof typeof exampleMergeTags];
  });
};
