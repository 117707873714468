/* eslint-disable react/jsx-pascal-case */
import BulkUploadForm from "../forms/BulkUploadForm";
import { FileUploadContextWrapper } from "../forms/fileUploadContext";
import CsvDataReview from "../location/CsvDataReview";
import LeadBulkCard from "./LeadBulkCard";
import { LeadContextWrapper, useLeadContext } from "./leadContext";

function _LeadBulkContainer() {
  const { confirmBulkData } = useLeadContext();

  function handleConfirmation(values: any) {
    confirmBulkData(values);
  }

  return (
    <FileUploadContextWrapper>
      <div className="p-5">
        <LeadBulkCard />

        <BulkUploadForm tableName="leads">
          <CsvDataReview
            additionalCheck={true}
            handleConfirmation={handleConfirmation}
          >
            <div className="flex flex-row gap-5 items-start"></div>
          </CsvDataReview>
        </BulkUploadForm>
      </div>
    </FileUploadContextWrapper>
  );
}

function LeadBulkContainer() {
  return (
    <LeadContextWrapper>
      <FileUploadContextWrapper>
        <_LeadBulkContainer />
      </FileUploadContextWrapper>
    </LeadContextWrapper>
  );
}

export default LeadBulkContainer;
