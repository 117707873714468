/* eslint-disable react/jsx-pascal-case */
import { Button, Input } from "@material-tailwind/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useCompanyContext } from "../company/companyContext";
import EmailDisplay from "../emailTemplates/EmailDisplay";
import {
  EmailTemplateContextWrapper,
  useEmailTemplateContext,
} from "../emailTemplates/EmailTemplateContext";
import TextEditor from "../emailTemplates/TextEditor";
import { SaveIcon } from "../icons/icons";

function _ViewAdminEmail() {
  const { create, activeTemplate, update } = useEmailTemplateContext();

  const { selectedCompanyConfig } = useCompanyContext();

  const [html, setHtml] = useState("");
  const [name, setName] = useState("");
  const [subject] = useState("");

  function handleSaveEmail() {
    if (activeTemplate && (name || html)) {
      update({
        id: activeTemplate.id,
        template: {
          name: name,
          id: activeTemplate.id,
          content: html,
          updated_at: new Date().toISOString(),
          company_id: activeTemplate.company_id,
        },
      });
    } else if (html && name) {
      create({
        content: html,
        name,
        company_id: selectedCompanyConfig?.company_id || "",
        subject,
      });
    }
  }
  return (
    <div className="p-5 max-h-screen">
      <div className="grid grid-cols-2 gap-5 max-h-screen ">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between gap-5">
            <div className="max-w-lg flex flex-row gap-5">
              <Link className="text-primary-400" to="/content?tab=email">
                Email list
              </Link>
              <Input
                placeholder={activeTemplate?.name}
                className="focus:border-t-0 focus:ring-0"
                value={name}
                onChange={(e) => setName(e.target.value)}
                crossOrigin={true}
                label={activeTemplate?.name || "Email name"}
              />
            </div>

            <Button
              color="green"
              size="md"
              onClick={handleSaveEmail}
              disabled={!html && !name}
              className="flex flex-row gap-2 items-center"
            >
              <SaveIcon className="h-4 w-4" />
              Save
            </Button>
          </div>
          <TextEditor
            setHtml={setHtml}
            initialValue={activeTemplate?.content}
            // mergeTags={
            //   [
            //     { tag: "first_name" },
            //     { tag: "last_name" },
            //     { tag: "email" },
            //     { tag: "phone" },
            //     { tag: "company_name" },
            //   ] as MergeTagRow[]
            // }
          />
        </div>
        <EmailDisplay
          html={html ? html : activeTemplate?.content}
          logoUrl={
            selectedCompanyConfig?.logo_image_url ||
            "https://sdknkezpybxgthpealqu.supabase.co/storage/v1/object/public/logos/Logo_color%20(1).png"
          }
        />
      </div>
    </div>
  );
}

const ViewAdminEmail = ({ id }: { id: string | undefined }) => {
  return (
    <EmailTemplateContextWrapper id={id}>
      <_ViewAdminEmail />
    </EmailTemplateContextWrapper>
  );
};

export default ViewAdminEmail;
