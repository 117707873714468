/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { RocketLaunchIcon } from "@heroicons/react/24/solid";
import { PromptUpdate } from "../../api/prompt.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import EditButton from "../buttons/editButton";
import DateDetailsDetails from "../forms/createAtDetails";
import { getDirtyValues } from "../forms/getDirtyValues";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useUserContext } from "../user/userContext";
import { usePromptContext } from "./promptContext";
import Toggle from "../forms/toggle";

function PromptOverview() {
  const { updatePrompt, activePrompt, runAiUpdate, isLoading } =
    usePromptContext();
  const { user } = useUserContext();

  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty, errors },
    control,
  } = useForm({
    defaultValues: activePrompt as unknown as PromptUpdate,
  });

  const onSubmit: SubmitHandler<PromptUpdate> = (data: PromptUpdate) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);

    updatePrompt({
      ...dirtyValues,
      id: activePrompt?.id as string,
    });
    setIsEdit(false);
  };

  useEffect(() => {
    reset(activePrompt);
  }, [activePrompt]);

  if (!activePrompt) {
    return <div>Error: No Prompt</div>;
  }

  const handleRunAiUpdate = () => {
    runAiUpdate(activePrompt.id);
  };

  const {
    id,
    created_at,
    name,
    updated_at,
    columns_verified_at,
    use_free_text,
  } = activePrompt;

  return (
    <div className="w-full">
      {isLoading ? (
        <PlentiLogoLoading className="h-24 w-24 mx-auto" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <Typography variant="h6">Prompt Overview</Typography>
            <div className="flex flex-row gap-2">
              <EditButton
                iconClass="h-4 w-4"
                text="Edit"
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              />

              {user?.type === "admin" && (
                <Button
                  variant="outlined"
                  className="flex items-center gap-2 p-2"
                  onClick={handleRunAiUpdate}
                  ripple={true}
                >
                  <RocketLaunchIcon className="w-4 h-4" />
                  Run AI update
                </Button>
              )}
            </div>
          </div>

          <Divider className="my-1" />

          <div className="my-4 grid w-full gap-10">
            <div className="flex w-full flex-col gap-6">
              <div className="grid grid-cols-3 gap-2">
                {!isEdit ? (
                  <ValueWithLabel label="Name" value={name} />
                ) : (
                  <PlentiInput
                    register={register}
                    name="name"
                    label="Name"
                    errors={errors}
                  />
                )}
                <ValueWithLabel label="Id" value={id} />
              </div>
              <div className="grid grid-cols-3 gap-2">
                <DateDetailsDetails date={created_at} title="Created" />
                <DateDetailsDetails date={updated_at} title="Updated" />
                {isEdit ? (
                  <div>
                    <p className="text-xs text-gray-500">Use Free Text</p>

                    <Toggle
                      control={control}
                      name="use_free_text"
                      trueLabel="Yes"
                      falseLabel="No"
                    />
                  </div>
                ) : (
                  <ValueWithLabel
                    label="Use Free Text"
                    value={use_free_text ? "Yes" : "No"}
                  />
                )}
                {user?.type === "admin" && (
                  <div>
                    {!isEdit ? (
                      <ValueWithLabel
                        label="Columns validated date"
                        value={
                          columns_verified_at
                            ? formatTimeStampToHumanReadableShortDateTime(
                                columns_verified_at
                              )
                            : "Not validated"
                        }
                      />
                    ) : (
                      <div className="max-w-sm">
                        <Button
                          variant="outlined"
                          color="indigo"
                          size="sm"
                          onClick={(e: any) => {
                            e.preventDefault();
                            updatePrompt({
                              columns_verified_at: new Date().toISOString(),
                              id: activePrompt?.id as string,
                            });
                            setIsEdit(false);
                          }}
                        >
                          Set validated
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {isEdit && (
            <div className="mt-6 flex justify-end gap-2">
              <Button
                size="sm"
                variant="outlined"
                color="gray"
                onClick={() => setIsEdit(false)}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                color="green"
                fullWidth={false}
                disabled={!isEdit || !isDirty}
                type="submit"
              >
                Update
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
}

export default PromptOverview;
