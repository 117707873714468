import PlentiLoadingIndicator from "../../loadingIndicator/PlentiLogoLoading";
import { useLoadConversationMetrics } from "../hooks/useLoadConversationMetrics";
import JourneysFlow from "./JourneysFlow";

function JourneysContainer() {
  const { conversationMetrics, isLoading } = useLoadConversationMetrics();

  return (
    <div>
      {isLoading ? (
        <PlentiLoadingIndicator className="h-24 w-24 mx-auto" />
      ) : (
        <JourneysFlow campaignMetrics={conversationMetrics} />
      )}
    </div>
  );
}

export default JourneysContainer;
