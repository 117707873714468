import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  FilteredImagesResponse,
  ImageMetadataFilterValues,
  ImageMetadataRow,
} from "./imageMetadata.type";

export function getImageMetadata(): Promise<ImageMetadataRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.imageMetadata;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ImageMetadataRow[]>(url, request);
}

export function getImageMetadataByFilter(values?: ImageMetadataFilterValues) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.imageMetadata + "/filter";

  const request = setHeaderOptions({
    method: "POST",
    ...(values && {
      body: JSON.stringify(values),
    }),
  });

  return fetchData<FilteredImagesResponse>(url, request);
}

export function getImageMetadataById(id: string): Promise<ImageMetadataRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.imageMetadata + "/" + id;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ImageMetadataRow>(url, request);
}
