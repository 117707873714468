import { Typography } from "@material-tailwind/react";
import { ExtractionSchemaCampaignRow } from "../../../api/extractionSchema.type";

function DataExtractionDetails({
  dataExtraction,
}: {
  dataExtraction?: ExtractionSchemaCampaignRow[] | null;
}) {
  return (
    <div>
      {dataExtraction?.map((extraction, idx) => {
        return (
          <div key={idx} className="flex flex-col gap-5">
            <div>
              <Typography variant="small">Name:</Typography>
              <Typography className="font-bold">
                {extraction.extraction_schema.name}
              </Typography>
            </div>
            <div>
              <Typography variant="small">Questions:</Typography>

              {extraction.extraction_schema.schema.map((field, index) => {
                return (
                  <div key={index}>
                    <Typography variant="small">Type: {field.type}</Typography>
                    <Typography className="font-bold">
                      {field.question}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DataExtractionDetails;
