import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { PayoutResponse } from "../api/payout.types";

export function createPayout(reimbursementId: string): Promise<PayoutResponse> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.payout;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ reimbursementId }),
  });

  return fetchData<PayoutResponse>(url, request);
}
