import { Typography } from "@material-tailwind/react";
import CreatedFlowCard from "./createdFlowCard";
import { useFlowContext } from "./flowContext";

function FlowCreatedCards() {
  const { flows } = useFlowContext();

  return (
    <div className="flex flex-col gap-5">
      <Typography variant="h4" className="mb-4 text-slate-500">
        Text message
      </Typography>
      <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
        {flows && flows.length > 0 ? (
          flows?.map((flow, index) => {
            return (
              <div key={index} className="p-2">
                <CreatedFlowCard flow={flow} />
              </div>
            );
          })
        ) : (
          <div>No flows created</div>
        )}
      </div>
    </div>
  );
}

export default FlowCreatedCards;
