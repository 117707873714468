import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  CountAndHead,
  DailyLeadCampaignsByCampaign,
  DailyLeadCampaignsByCompany,
  LeadCampaignRow,
  LeadCampaignsByCompany,
  LeadCampaignsWithCampaignAgentAndData,
} from "../api/leadCampaign.type";

export function getLeadCampaignsByCompany(
  companyId: string
): Promise<LeadCampaignsByCompany[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadCampaign +
    "/company/" +
    companyId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadCampaignsByCompany[]>(url, request);
}

export function getLeadCampaignsNeedFollowUpCount(): Promise<CountAndHead> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadCampaign +
    "/no-schedule-count";

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<CountAndHead>(url, request);
}

export function getLeadCampaignsReadyForScheduleCount(): Promise<CountAndHead> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadCampaign +
    "/ready-for-schedule-count";

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<CountAndHead>(url, request);
}

export function getDailyLeadCountByCompany(
  companyId: string
): Promise<DailyLeadCampaignsByCompany[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadCampaign +
    "/company/daily-count/" +
    companyId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<DailyLeadCampaignsByCompany[]>(url, request);
}

export function getDailyLeadCountByCampaign(
  campaignId: string
): Promise<DailyLeadCampaignsByCampaign[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadCampaign +
    "/campaign/daily-count/" +
    campaignId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<DailyLeadCampaignsByCampaign[]>(url, request);
}

export function getLeadCampaignsByLeadId(
  leadId: string
): Promise<LeadCampaignsWithCampaignAgentAndData[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadCampaign +
    "/lead/" +
    leadId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadCampaignsWithCampaignAgentAndData[]>(url, request);
}

export function createLeadCampaign({
  leadId,
  campaignId,
}: {
  leadId: string;
  campaignId: string;
}): Promise<LeadCampaignRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.leadCampaign;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ campaignId, leadId }),
  });

  return fetchData<LeadCampaignRow>(url, request);
}
