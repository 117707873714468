/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";
import LeadTableList from "./leadTableList";
import { mapLeadToCsvData } from "./mapLeadToCvsData";

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useSearchParams } from "react-router-dom";
import { LeadWithMessageCount } from "../../api/lead.type";
import { convertDateToRawDate } from "../../utils/parseTimeStamp";
import InfoPopover from "../InfoPopover/infoPopover";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useLoadLeads } from "./hooks/useLoadLeads";
import { useLeadContext } from "./leadContext";

const LeadTable: React.FC = () => {
  const TABLE_HEAD = [
    { label: "Phone/email" },
    { label: "Created at" },
    { label: "Campaign" },
    { label: "User count" },
    { label: "Assistant count" },
    { label: "Lead info" },
    { label: "See chat" },
  ];

  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId");

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);
  const {
    setSearchValue,
    searchLeadList,
    searchValue,
    isLoading,
    searchMessage,
  } = useLeadContext();
  const { leads, count, isFetching, setCurrentPage, currentPage } =
    useLoadLeads();

  const rowsPerPage = 10;
  const [tabValue, setTabValue] = useState("userMessage");

  function baseSort() {
    return (a: LeadWithMessageCount, b: LeadWithMessageCount) =>
      new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
  }

  const [leadSortFunction] = useState(baseSort);

  function handleTabChange(value: string) {
    if (value === tabValue) {
      setTabValue("");
    } else {
      setTabValue(value);
    }
  }

  function getUniqueValuesForTableHeadFilter(head: "campaign_id") {
    const uniqueValues = ["All"] as string[];

    if (!leads) {
      return uniqueValues;
    }

    for (let i = 0; i < leads.length; i++) {
      const f = leads[i];

      if (f[head] && !uniqueValues.find((item) => item === f[head])) {
        uniqueValues.push(f[head]);
      }
    }
    return uniqueValues;
  }

  useEffect(() => {
    if (leads && leads.length > 0) {
      const campaignIds = getUniqueValuesForTableHeadFilter("campaign_id");
      const newTableHead = TABLE_HEAD.map((item) => {
        if (item.label === "Campaign") {
          return {
            ...item,
            filterValues: campaignIds,
          };
        }
        return item;
      });
      setTableHead(newTableHead);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leads]);

  const downloadData = mapLeadToCsvData(leads);
  return (
    <div>
      {leads?.length === 0 && <Typography>No leads found</Typography>}

      {leads && leads.length > 0 && (
        <TableWithSearch
          isLoading={isFetching}
          downloadData={{
            headers: downloadData.headers,
            csvData: downloadData.csvData,
            fileName: `leads-${convertDateToRawDate(new Date())}.csv`,
          }}
          tableHead={tableHead}
          type={
            campaignId &&
            leads?.length > 0 &&
            leads[0].campaign &&
            leads[0].campaign.name
              ? `Leads for ${leads[0].campaign.name}`
              : "lead"
          }
          rowCount={count ? count : 0}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleNextPage={() => setCurrentPage(currentPage + 1)}
          handlePreviousPage={() => setCurrentPage(currentPage - 1)}
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          search={
            <div className="w-full md:w-72 relative">
              <div className="absolute top-[-12px] right-[-15px]">
                <InfoPopover>
                  <p className="text-slate-700">
                    Full text search on phone, email, and lead id. Will match
                    exact phone number, email, or lead id.
                  </p>
                </InfoPopover>
              </div>
              {/* @ts-ignore */}

              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                onChange={(e) => setSearchValue(e.target.value)}
                className="focus:border-t-0 focus:ring-0"
              />
              {searchMessage && <Typography>{searchMessage}</Typography>}
            </div>
          }
        >
          {isLoading ? (
            <PlentiLogoLoading className="h-12 w-12 mx-auto" />
          ) : (
            <LeadTableList
              leads={searchValue ? searchLeadList : leads}
              sortFunction={leadSortFunction}
            />
          )}
        </TableWithSearch>
      )}
    </div>
  );
};

export default LeadTable;
