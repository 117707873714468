import { Elements } from "@stripe/react-stripe-js";
import { Button, Callout } from "@tremor/react";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { SmallInfoCircle } from "../icons/icons";
import SetupForm from "../stripe/setupForm";
import { stripePromise, useStripeContext } from "../stripe/stripeContext";
import { useCustomerContext } from "./customerContex";
import PaymentMethodList from "./paymentMethodList";

function PaymentMethods({
  isAddPayment,
  setIsAddPayment,
  setIndex,
}: {
  setIsAddPayment: (isAddPayment: boolean) => void;
  isAddPayment?: boolean;
  setIndex: (value: number) => void;
}) {
  const { customer } = useCustomerContext();

  const { paymentMethods, setupOptions } = useStripeContext();
  return (
    <div className="flex flex-col gap-10">
      {paymentMethods && paymentMethods.length > 0 && (
        <div className="flex flex-col gap-5">
          {((customer && !customer.balance) ||
            (customer && customer?.balance < 10000)) && (
            <Callout title="Add Funds" icon={SmallInfoCircle} color="red">
              <div className="flex flex-row justify-between">
                {`You have ${
                  customer && customer?.balance
                    ? parseCentsToDollars(customer?.balance)
                    : "$0.00"
                } in your account. Add funds to avoid service interruption.`}
                <Button color="red" size="sm" onClick={() => setIndex(3)}>
                  Add Funds
                </Button>
              </div>
            </Callout>
          )}
          <PaymentMethodList />
        </div>
      )}
      {(isAddPayment || !paymentMethods || paymentMethods.length === 0) && (
        <div className="w-96 mx-auto">
          {stripePromise && (
            <Elements stripe={stripePromise} options={setupOptions}>
              <SetupForm setIsAddPayment={setIsAddPayment} />
            </Elements>
          )}
        </div>
      )}
    </div>
  );
}

export default PaymentMethods;
