/* eslint-disable react/jsx-pascal-case */
import { Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { EmailDnsRowCreate } from "../../api/emailDns.type";
import { DisplayEmailContent } from "../campaignSmsContent/DisplayEmailOrSmsContent";
import { PlentiInput } from "../forms/plentiInput";
import Toggle from "../forms/toggle";
import PhoneDisplayLayout from "../layout/PhoneDisplayLayout";
import { useEmailDnsContext } from "./hooks/EmailDnsContext";

function AddEmailDns({ setOpen }: { setOpen: (value: boolean) => void }) {
  const { addEmailDns } = useEmailDnsContext();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      domain: "",
      subdomain: "",
      aiAutoReply: true,
    },
  });

  const onSubmit = (data: EmailDnsRowCreate) => {
    addEmailDns(data);
    reset();
    setOpen(false);
  };

  return (
    <div className="w-full ">
      <div className="my-4 flex flex-col w-full ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 ">
              <PlentiInput
                register={register}
                errors={errors}
                label="Domain"
                name="domain"
              />
              <PlentiInput
                register={register}
                errors={errors}
                label="Subdomain"
                name="subdomain"
                required={false}
              />
              <div>
                <p className="text-xs text-gray-500">AI Auto Reply</p>

                <Toggle
                  control={control}
                  name="aiAutoReply"
                  trueLabel="Yes"
                  falseLabel="No"
                />
              </div>

              <div className="mt-6 flex justify-end gap-2">
                <Button
                  size="sm"
                  variant="outlined"
                  color="gray"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="green"
                  fullWidth={false}
                  disabled={!watch("domain")}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="m-auto h-36">
          <PhoneDisplayLayout>
            <DisplayEmailContent
              content={"Hi John, This is a test email"}
              fromEmail={`mj@${
                watch("subdomain") ? watch("subdomain") + "." : ""
              }${watch("domain")}`}
            />
          </PhoneDisplayLayout>
        </div>
      </div>
    </div>
  );
}

export default AddEmailDns;
