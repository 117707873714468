import { OcrContextWrapper, useOcrContext } from "../components/ocr/ocrContext";
import ViewOcrContainer from "../components/ocr/viewOcr";
import { useParams } from "react-router-dom";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewOcrWithContext = () => {
  mixpanelTrackEvent("View ocr page");

  const { activeOcr, useRunPurchaseCheck, purchaseCheck } = useOcrContext();
  return (
    <ViewOcrContainer
      activeOcr={activeOcr}
      useRunPurchaseCheck={useRunPurchaseCheck}
      purchaseCheck={purchaseCheck}
    />
  );
};

const ViewOcr = () => {
  const params = useParams();
  return (
    <OcrContextWrapper id={params.id}>
      <ViewOcrWithContext />
    </OcrContextWrapper>
  );
};

export default ViewOcr;
