/* eslint-disable react-hooks/exhaustive-deps */
import { Option, Select, Typography } from "@material-tailwind/react";

function SelectFollowUp({
  value,
  handleSelected,
  options,
  label,
}: {
  handleSelected: (max: string) => void;
  value: string;
  options: string[];
  label: string;
}) {
  return (
    <Select
      label={label}
      value={value}
      onChange={(e: any) => {
        handleSelected(e);
      }}
    >
      {options.map((item) => {
        return (
          <Option key={item} value={item}>
            <Typography>{item}</Typography>
          </Option>
        );
      })}
    </Select>
  );
}

export default SelectFollowUp;
