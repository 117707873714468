import { BrowserSvg } from "../../icons/browserSvg";
import { MailIcon, PhoneIcon } from "../../icons/icons";

export const medium = [
  {
    name: "Browser",
    icon: <BrowserSvg className="h-6 w-6" />,
    slug: "browser",
  },
  { name: "Email", icon: <MailIcon className="h-6 w-6" />, slug: "email" },
  { name: "Phone", icon: <PhoneIcon className="h-6 w-6" />, slug: "phone" },
];
