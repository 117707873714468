import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  UseCreateFeedbackMutation,
  UseUpdateFeedbackMutation,
} from "../feedback/feedbackContext.types";

import {
  createFeedback,
  getFeedback,
  updateFeedback,
} from "../../api/feedback.api";
import { FeedbackRow } from "../../api/feedback.type";

export const FeedbackContextWrapper = (props: any) => {
  const session = useSession();

  const [createdFeedback, setCreatedFeedback] = useState<FeedbackRow>();
  const [feedbackErrorMessage, setErrorMessage] = useState<string>("");

  const { data: feedback } = useQuery({
    queryKey: "feedback",
    queryFn: () => getFeedback(),
    enabled: !!session,
  });

  const useCreateFeedback = useMutation(createFeedback, {
    onSuccess: async (res) => {
      setCreatedFeedback(res);
    },
    onError(error: Error) {
      setErrorMessage(error.message);
    },
  });

  const useUpdateFeedback = useMutation(updateFeedback, {
    onSuccess: async (res) => {
      setCreatedFeedback(res);
    },
    onError(error: Error) {
      setErrorMessage(error.message);
    },
  });

  const value = {
    feedback,
    useCreateFeedback,
    createdFeedback,
    useUpdateFeedback,
    feedbackErrorMessage,
  };

  return (
    <FeedbackContext.Provider value={value}>
      {props.children}
    </FeedbackContext.Provider>
  );
};

export const FeedbackContext = createContext({
  feedback: [] as FeedbackRow[] | undefined,
  useCreateFeedback: {} as UseCreateFeedbackMutation,
  createdFeedback: {} as FeedbackRow | undefined,
  useUpdateFeedback: {} as UseUpdateFeedbackMutation,
  feedbackErrorMessage: "" as string,
});

export const useFeedbackContext = () => useContext(FeedbackContext);
