import {
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Fragment } from "react";

import { EditIcon, EllipsisVertical } from "../icons/icons";
import { useSmsContentContext } from "../smsContent/smsContentContext";

import { CampaignRow } from "../../api/campaign.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { toLowerCaseAndReplaceSpacesWithHyphens } from "../../utils/stringManipulation";
import { useCampaignContext } from "./campaignContext";
import { useUserContext } from "../user/userContext";
import { CopyToClipboardIconButton } from "../hooks/copyToClipboard";
import { useNavigate } from "react-router-dom";
import { CompanyRow } from "../../api/company.type";

export function getWebhookLink(companyId: string, campaignId: string) {
  return `${process.env.REACT_APP_API_URL}/lead/klaviyo/${campaignId}`;
}

export function getDemoLink(companySlug: string, campaignName: string) {
  return `/demo/${companySlug}/${toLowerCaseAndReplaceSpacesWithHyphens(
    campaignName
  )}`;
}

function CampaignTableList({
  campaign,
  handleContentClick,
  filterFunction,
}: {
  campaign: CampaignRow[] | undefined;
  handleContentClick: (id: string) => void;
  filterFunction?: (campaign: CampaignRow, index: number) => boolean;
}) {
  const navigate = useNavigate();
  const { updateCampaign, useDuplicateCampaign } = useCampaignContext();

  const { user } = useUserContext();

  const { smsContents } = useSmsContentContext();

  function mapCampaignToRow(campaign: CampaignRow): {
    id: string;
    name: string;
    createdAt: string;
    status: string;
    companyName: string;
    demoLink: string;
    promptLink: string | null;
    webhookLink: string;
    tryLink: string;
    smsId: string | null;
    company: CompanyRow | undefined;
    type: string;
  } {
    const smsContent = smsContents?.find(
      (content) => content.campaign_id === campaign?.id
    );

    const values = {
      id: campaign.id,
      name: campaign?.name ? campaign?.name : "N/A",
      createdAt: campaign.created_at
        ? formatTimeStampToHumanReadableShortDateTime(campaign.created_at)
        : "N/A",
      goal: campaign.goal ? campaign.goal : "N/A",
      companyName: campaign?.company?.name ? campaign?.company?.name : "N/A",
      demoLink:
        campaign.company && campaign?.company?.slug
          ? getDemoLink(campaign?.company?.slug, campaign.name)
          : "N/A",
      promptLink: campaign?.system_prompt_id ? campaign.system_prompt_id : null,
      webhookLink:
        campaign && campaign.company && campaign.company.id
          ? getWebhookLink(campaign.company.id, campaign.id)
          : "N/A",
      tryLink: campaign.id
        ? `/try/${campaign.company?.slug}?campaignId=${campaign.id}`
        : "N/A",
      smsId: smsContent && smsContent.id ? smsContent.id : null,
      status: campaign.status ? campaign.status : "N/A",
      company: campaign.company,
      type: campaign.type ? campaign.type : "N/A",
    };

    return values;
  }
  return (
    <Fragment>
      {campaign &&
        campaign.length > 0 &&
        campaign
          .filter((item, index) => filterFunction?.(item, index) ?? true)
          .map((item) => mapCampaignToRow(item))
          .map(
            (
              { id, name, createdAt, status, companyName, company, type },
              index
            ) => {
              const isLast = index === campaign.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={id} className="even:bg-blue-gray-50/50">
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {name}
                        </Typography>
                        {user && user.type === "admin" && (
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {companyName}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {createdAt}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    {company?.company_config &&
                    company?.company_config[0] &&
                    company?.company_config[0].logo_image_url ? (
                      <img
                        height={50}
                        width={50}
                        src={company.company_config[0].logo_image_url}
                        alt="logo"
                      />
                    ) : (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {company?.name || "N/A"}
                      </Typography>
                    )}
                  </td>
                  <td className={classes}>
                    <div className="flex max-w-fit flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {status === "active" ? (
                          <Chip color="green" value="Active" />
                        ) : status === "draft" ? (
                          <Chip color="brown" value="Draft" />
                        ) : (
                          <Chip color="yellow" value="Paused" />
                        )}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal capitalize"
                      >
                        {type}
                      </Typography>
                    </div>
                  </td>

                  <td className={classes}>
                    <IconButton
                      onClick={() => handleContentClick(id)}
                      variant="text"
                      color="blue-gray"
                    >
                      <EditIcon className="h-4 w-4" />
                    </IconButton>
                  </td>
                  <td className={classes}>
                    <Menu placement="top">
                      <MenuHandler>
                        <IconButton variant="text" color="blue-gray">
                          <EllipsisVertical className="h-4 w-4" />
                        </IconButton>
                      </MenuHandler>
                      <MenuList>
                        {company?.company_config &&
                          company?.company_config[0] && (
                            <MenuItem>
                              <div className="flex flex-row items-start gap-2">
                                <p> Try Link </p>
                                <CopyToClipboardIconButton
                                  text={`https://${company?.company_config[0]?.subdomain}.plentiai/try/${company?.slug}?campaignId=${id}`}
                                  className={""}
                                />
                              </div>
                            </MenuItem>
                          )}
                        <MenuItem
                          onClick={() => {
                            navigate(`/campaign/${id}/demo`);
                          }}
                        >
                          Demo page
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            useDuplicateCampaign.mutate(id);
                          }}
                        >
                          Duplicate
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            updateCampaign({
                              id: id,
                              status: status === "active" ? "paused" : "active",
                            });
                          }}
                        >
                          {status === "active" ? "Pause" : "Activate"}
                        </MenuItem>
                        <MenuItem disabled={true}>Delete</MenuItem>
                      </MenuList>
                    </Menu>
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default CampaignTableList;
