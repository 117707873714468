/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";

import { useAlertContext } from "../alert/alertContext";
import { GetChatMutation } from "../lead/messageContext.types";
import { useUserContext } from "../user/userContext";

import { getLeadById, updateLead } from "../../api/lead.api";
import { LeadWithRelations } from "../../api/lead.type";
import { UseGetLead } from "../../api/leadContext.types";
import { getSmsMessagesByLead, sendManualSms } from "../../api/sms.api";
import { SmsMessage } from "../../api/sms.type";

export const MessageContextWrapper = (props: any) => {
  const { user } = useUserContext();
  let [searchParams] = useSearchParams();

  const { setAlertProps } = useAlertContext();
  const [activeLead, setActiveLead] = useState<LeadWithRelations | null>(null);
  const [loading, setLoading] = useState(false);

  const useGetChatMutation = useMutation(getSmsMessagesByLead, {
    onSuccess: async (res) => {
      setMessages(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useUpdateLead = useMutation(updateLead, {
    onSuccess: async (res) => {
      setActiveLead(res);
      setAlertProps({
        message: `Lead ${res.phone} updated`,
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function toggleLeadMode() {
    if (activeLead) {
      const updatedLead = {
        id: activeLead?.id,
        sms_mode: activeLead?.sms_mode === "manual" ? null : "manual",
      };
      useUpdateLead.mutate(updatedLead);
    }
  }

  const useGetLeadById = useMutation(getLeadById, {
    onSuccess: (data) => {
      setActiveLead(data);
    },
  });

  const useSendManualSms = useMutation(sendManualSms, {
    onSuccess: async (res) => {
      useGetChatMutation.mutate({
        leadId: activeLead?.id || "",
      });
    },
    onError(error: Error) {
      console.log(error);
    },
    onSettled() {
      setLoading(false);
    },
  });

  const [messages, setMessages] = useState<SmsMessage[]>([]);

  function handleSubmitMessage(message: string) {
    let campaignId = "";
    let companyId = "";
    messages.forEach((message) => {
      if (message.campaign_id) {
        campaignId = message.campaign_id;
      }
      if (message.company_id) {
        companyId = message.company_id;
      }
    });

    // This set the message to the UI so the user sees their message displayed
    setLoading(true);
    setMessages((prevState) => [
      ...prevState,
      {
        id: "",
        lead_id: activeLead?.id || "",
        created_at: new Date().toISOString(),
        media_url: "",
        content: message,
        role: "assistant",
        twilio_account_sid: "",
        twilio_sid: "",
        tag: [],
        campaign_id: "",
        company_id: "",
        generated_by: "assistant",
      },
    ]);
    useSendManualSms.mutate({
      leadId: activeLead?.id || "",
      message,
      phone: activeLead?.phone || "",
      user:
        user?.first_name && user?.last_name
          ? `${user.first_name} ${user.last_name}`
          : "",
      campaignId,
      companyId,
    });
  }

  const leadId = searchParams.get("leadId");

  useEffect(() => {
    if (leadId && !activeLead) {
      useGetLeadById.mutate(leadId as string);
    }
  }, [leadId]);

  useEffect(() => {
    if (activeLead && activeLead.id) {
      useGetChatMutation.mutate({
        leadId: activeLead.id,
      });
    }
  }, [activeLead]);

  const value = {
    messages,
    useGetChatMutation,
    activeLead,
    setActiveLead,
    handleSubmitMessage,
    toggleLeadMode,
    loading,

    useGetLeadById,
  };

  return (
    <MessageContext.Provider value={value}>
      {props.children}
    </MessageContext.Provider>
  );
};

export const MessageContext = createContext({
  messages: [] as SmsMessage[] | undefined,
  useGetChatMutation: {} as GetChatMutation,
  activeLead: null as LeadWithRelations | null,
  setActiveLead: {} as React.Dispatch<
    React.SetStateAction<LeadWithRelations | null>
  >,
  handleSubmitMessage: {} as (message: string) => void,
  loading: false as boolean,

  toggleLeadMode: {} as () => void,
  useGetLeadById: {} as UseGetLead,
});

export const useMessageContext = () => useContext(MessageContext);
