/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Metric, Text } from "@tremor/react";
import { useMutation } from "react-query";
import { getSmsCounts } from "../../api/sms.api";
import { useEffect, useState } from "react";
import {
  getLeadCampaignsNeedFollowUpCount,
  getLeadCampaignsReadyForScheduleCount,
} from "../../api/leadCampaign.api";
import { getActiveSchedules } from "../../api/schedule.api";
import { CountAndHead } from "../../api/leadCampaign.type";

function AdminFollowUpSchedule() {
  const [activeTab, setActiveTab] = useState("follow_up");
  const [messageCounts, setMessageCounts] = useState(0);
  const [leadCampaignCounts, setLeadCampaignCounts] = useState<CountAndHead>({
    count: 0,
    head: [],
  });

  const [readyForScheduleCounts, setReadyForScheduleCounts] =
    useState<CountAndHead>({
      count: 0,
      head: [],
    });

  const [activeSchedules, setActiveSchedules] = useState<CountAndHead>({
    count: 0,
    head: [],
  });

  const useGetMessageCounts = useMutation(getSmsCounts, {
    onSuccess: (data) => {
      setMessageCounts(data);
    },
  });

  const useGetLeadCampaignsCounts = useMutation(
    getLeadCampaignsNeedFollowUpCount,
    {
      onSuccess: (data) => {
        setLeadCampaignCounts(data);
      },
    }
  );

  const useGetReadyForScheduleCounts = useMutation(
    getLeadCampaignsReadyForScheduleCount,
    {
      onSuccess: (data) => {
        setReadyForScheduleCounts(data);
      },
    }
  );

  const useGetActiveSchedules = useMutation(getActiveSchedules, {
    onSuccess: (data) => {
      setActiveSchedules(data);
    },
  });

  useEffect(() => {
    useGetMessageCounts.mutate();
    useGetLeadCampaignsCounts.mutate();
    useGetReadyForScheduleCounts.mutate();
    useGetActiveSchedules.mutate();
  }, []);

  return (
    <div>
      <h1>Follow up schedule</h1>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <Card className="mx-auto">
          <Text>Messages not added to count</Text>
          <Metric>{messageCounts}</Metric>
        </Card>
        <Card
          className={
            activeTab === "follow_up"
              ? "mx-auto cursor-pointer bg-blue-100"
              : "bg-gray-100 mx-auto cursor-pointer"
          }
          onClick={() => setActiveTab("follow_up")}
          color={activeTab === "follow_up" ? "blue" : "green"}
        >
          <Text>Active lead campaigns that need the next follow up date</Text>
          <Metric>{leadCampaignCounts.count}</Metric>
        </Card>
        <Card
          className={
            activeTab === "schedule_ready"
              ? "mx-auto cursor-pointer bg-green-100"
              : "mx-auto cursor-pointer bg-gray-100"
          }
          onClick={() => setActiveTab("schedule_ready")}
        >
          <Text>Lead campaigns that are ready for a schedule to generate</Text>
          <Metric>{readyForScheduleCounts.count}</Metric>
        </Card>
        <Card
          className={
            activeTab === "active_schedules"
              ? "mx-auto cursor-pointer bg-red-100"
              : "mx-auto cursor-pointer bg-gray-100"
          }
          onClick={() => setActiveTab("active_schedules")}
        >
          <Text>Active schedules</Text>
          <Metric>{activeSchedules.count}</Metric>
        </Card>
      </div>
      {activeTab === "follow_up" && leadCampaignCounts.head.length > 0 && (
        <div>
          <p>Need next follow up date</p>
          <pre>{JSON.stringify(leadCampaignCounts.head, null, 2)}</pre>
        </div>
      )}
      {activeTab === "schedule_ready" && leadCampaignCounts.head.length > 0 && (
        <div>
          <p>Schedule Ready</p>
          <pre>{JSON.stringify(readyForScheduleCounts.head, null, 2)}</pre>
        </div>
      )}

      {activeTab === "active_schedules" &&
        leadCampaignCounts.head.length > 0 && (
          <div>
            <p>Active Schedules</p>
            <pre>{JSON.stringify(activeSchedules.head, null, 2)}</pre>
          </div>
        )}
    </div>
  );
}

export default AdminFollowUpSchedule;
