import { Typography } from "@material-tailwind/react";
import { useState } from "react";

import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import ReimbursementTableList from "../reimbursement/reimbursementTableList";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch from "../table/tableWithSearch";

import { ReimbursementRow } from "../../api/reimbursement.type";
import { CampaignContextWrapper } from "../campaign/campaignContext";
import { getTotalReimbursementAmount } from "./reimbursementHelpers";

const TABLE_HEAD = [
  { label: "Created at" },
  { label: "Campaign Name" },
  { label: "Status" },
  { label: "Amount" },
  { label: "View" },
];

function ReimbursementsDashboard() {
  const { reimbursements } = useReimbursementContext();

  const [reimbursementFilterFunction, setReimbursementFilterFunction] =
    useState<(reimbursement: ReimbursementRow, index: number) => boolean>(
      baseFilter
    );
  const [reimbursementSortFunction] = useState(baseSort);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  function baseSort() {
    return (a: ReimbursementRow, b: ReimbursementRow) =>
      new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
  }
  function baseFilter() {
    return (reimbursement: ReimbursementRow, index: number) => index < 10;
  }

  // const downloadData = mapReimbursementToCsvData(reimbursement);
  // const [download, setDownload] = useState(false);

  return (
    <div className="m-2">
      {/* <div className="mb-4 w-full space-y-6">
        <div className="mb-8 flex items-center justify-between ">
          <div>
            <Button
              className="flex items-center gap-3 bg-primary-500 hover:bg-primary-600"
              size="sm"
              onClick={() => {
                setDownload(true);
              }}
            >
              <DownLoadFileIcon className="h-4 w-4" /> Download CSV
            </Button>
            {download && (
              <CSVDownload
                data={dataCohort?.downloadData.csvData || []}
                headers={dataCohort?.downloadData.headers}
                filename="reimbursement-analytics.csv"
              />
            )}
          </div>
        </div>
      </div> */}
      <CampaignContextWrapper>
        {reimbursements && (
          <TableWithSearch
            tableHead={TABLE_HEAD}
            type="reimbursement"
            rowCount={reimbursements ? reimbursements?.length : 0}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            handleNextPage={() =>
              handlePageNav({
                currentPage,
                pageSetFunction: setCurrentPage,
                rowsPerPage,
                setFilterFunction: setReimbursementFilterFunction,
                advancePage: true,
              })
            }
            handlePreviousPage={() =>
              handlePageNav({
                currentPage,
                pageSetFunction: setCurrentPage,
                rowsPerPage,
                setFilterFunction: setReimbursementFilterFunction,
                advancePage: false,
              })
            }
            search={
              <Typography color="gray" className="mt-1 font-normal">
                Total reimbursed amount:{" "}
                {reimbursements && reimbursements.length > 0
                  ? getTotalReimbursementAmount(reimbursements)
                  : 0}
              </Typography>
            }
          >
            <ReimbursementTableList
              reimbursements={reimbursements}
              filterFunction={reimbursementFilterFunction}
              sortFunction={reimbursementSortFunction}
            />
          </TableWithSearch>
        )}
      </CampaignContextWrapper>
    </div>
  );
}

export default ReimbursementsDashboard;
