import {
  DailyUsageRow,
  ReimbursementDailyUsageRow,
} from "../../../../api/dailyUsage.type";

function convertToDailyUsageRow(
  reimbursementRow: ReimbursementDailyUsageRow
): Partial<DailyUsageRow> {
  const date = new Date(reimbursementRow.date);
  return {
    reimbursement_balance: reimbursementRow.total_cost,
    reimbursement_count: reimbursementRow.daily_count,
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

function sortDailyReimbursementUsage(
  a: Partial<DailyUsageRow>,
  b: Partial<DailyUsageRow>
) {
  if (!a.year || !a.month || !a.day || !b.year || !b.month || !b.day) {
    return 1;
  }
  const dateA = new Date(a.year, a.month, a.day);
  const dateB = new Date(b.year, b.month, b.day);
  return dateA.getTime() - dateB.getTime();
}

export function mapReimbursementDailyUsageToDaily(
  reimbursementDailyUsage: ReimbursementDailyUsageRow[]
): Partial<DailyUsageRow>[] {
  return reimbursementDailyUsage
    .map(convertToDailyUsageRow)
    .sort(sortDailyReimbursementUsage);
}
