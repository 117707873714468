import { Card, Metric, Text } from "@tremor/react";
import { FC } from "react";

export const MetricCard: FC<Props> = ({ title, value }) => {
  return (
    <Card>
      <Text>{title}</Text>
      <Metric className="text-primary-300">{value}</Metric>
    </Card>
  );
};

interface Props {
  title: string;
  value?: string | number;
}
