/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { useCompanyContext } from "../company/companyContext";

import { CompanyRow } from "../../api/company.type";
import CompanyDefaultTryPageDetails from "../company/companyDefaultTryPageDetails";
import CompanyDetailsContainer from "../company/companyDetailsContainer";
import { TryContextWrapper } from "../try/tryContext";
import TryHeader from "../try/tryHeader";
import TryHeaderBody from "../try/tryHeaderBody";
import TrySteps from "../try/trySteps";
import { useUserContext } from "../user/userContext";

function EditCompany({ id }: { id: string | undefined | string[] }) {
  const { selectedCompany, useGetCompanyById, useGetCompanyConfig } =
    useCompanyContext();

  const { user } = useUserContext();

  const breadCrumbsConfig = [
    {
      name: "Company",
      href: "/company",
      active: false,
    },
    {
      name: "Edit",
      href: `/company/${id}`,
      active: true,
    },
  ];

  useEffect(() => {
    if (!selectedCompany && id && typeof id === "string") {
      useGetCompanyById.mutate(id);
      useGetCompanyConfig.mutate(id);
    }
  }, [id]);

  return (
    <div className="p-5">
      {user?.type === "admin" && (
        <BreadcrumbsFromConfig config={breadCrumbsConfig} />
      )}
      <div className="mt-10 grid grid-cols-3 gap-10">
        <div className="col-span-2 flex flex-col gap-10">
          {user?.type === "admin" && <CompanyDetailsContainer />}
          <CompanyDefaultTryPageDetails />
        </div>

        <div>
          {selectedCompany && (
            <TryContextWrapper
              company={selectedCompany as CompanyRow}
              forDemo={true}
            >
              <div
                className="relative h-[700px] w-[350px] bg-cover bg-center bg-no-repeat px-6 py-2"
                style={{ backgroundImage: "url(/images/iphone.jpg)" }}
              >
                <div
                  className="absolute top-[3%] left-[6%] h-[94%] w-[88%] overflow-auto bg-white"
                  style={{ borderRadius: "40px" }}
                >
                  <TryHeader>
                    <TryHeaderBody />
                  </TryHeader>
                  <TrySteps />
                </div>
              </div>
            </TryContextWrapper>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditCompany;
