import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { ReimbursementUsageRow, UsageRow } from "./usage.type";

export function getUsage(): Promise<UsageRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.usage;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData(url, request);
}

export function getSmsUsage() {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.smsUsage;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData(url, request);
}

export function getReimbursementUsage() {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.reimbursementUsage;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementUsageRow[]>(url, request);
}
