/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";
import { useFeedbackContext } from "./feedbackContext";
import FeedbackTableList from "./feedbackTableList";

import { FeedbackRow } from "../../api/feedback.type";
import {
  handleFilterValue,
  setHeadFilters,
} from "../table/setTableHeadWithFilters";
import { Chip, Typography } from "@material-tailwind/react";
import { calculateNPS } from "./npsCalculation";

function FeedbackList() {
  const { feedback } = useFeedbackContext();
  const [filteredFeedback, setFilteredFeedback] = useState<FeedbackRow[]>([]);
  const TABLE_HEAD = [
    { label: "Id / Created at" },
    { label: "NPS" },
    { label: "Text" },
    { label: "Chat link" },
  ];
  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [npsScore, setNpsScore] = useState<number | null>(null);

  function baseFilter() {
    return (feedback: FeedbackRow, index: number) => index < 10;
  }
  const [feedbackFilterFunction, setFeedbackFilterFunction] =
    useState<(lead: FeedbackRow, index: number) => boolean>(baseFilter);

  useEffect(() => {
    setHeadFilters(
      feedback,
      filteredFeedback,
      setFilteredFeedback,
      setTableHead,
      TABLE_HEAD
    );
  }, [feedback]);

  useEffect(() => {
    if (feedback && feedback.length > 0) {
      const npsScores = feedback
        .filter((item) => item?.nps !== null)
        .map((item) => item?.nps);

      const averageNps = calculateNPS(npsScores);

      setNpsScore(averageNps);
    }
  }, [feedback]);

  return (
    <div className="flex flex-col gap-4">
      {filteredFeedback && filteredFeedback.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          type="feedback"
          rowCount={filteredFeedback ? filteredFeedback?.length : 0}
          rowsPerPage={10}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              feedback,
              setFilteredFeedback,
              setFeedbackFilterFunction,
              baseFilter
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setFeedbackFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setFeedbackFilterFunction,
              advancePage: false,
            })
          }
          additionalButtons={
            <div className="flex flex-row gap-2">
              <Typography>NPS Calculation</Typography>
              <Chip value={npsScore} color="blue" />
            </div>
          }
        >
          <FeedbackTableList
            feedback={feedback}
            filterFunction={feedbackFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default FeedbackList;
