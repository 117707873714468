import { PlentiInput } from "../forms/plentiInput";

function EmailPasswordInputs({
  register,
  errors,
}: {
  register: any;
  errors: any;
}) {
  return (
    <div className="flex flex-col ">
      <PlentiInput
        register={register}
        errors={errors}
        label="Email"
        name="email"
      />
      <PlentiInput
        register={register}
        errors={errors}
        label="Password"
        type="password"
        name="password"
      />
      <PlentiInput
        register={register}
        errors={errors}
        label="Confirm Password"
        type="password"
        name="repeatPassword"
      />
    </div>
  );
}

export default EmailPasswordInputs;
