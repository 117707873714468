import { useEffect } from "react";

const useOutsideMouseMove = (
  setSelectedMenu: (menu: { type: string; name: string }) => void
) => {
  useEffect(() => {
    const handleMouseMove = (event: { clientX: number; clientY: number }) => {
      const fabrkMenu = document.getElementById("fabrkMenu");
      const secondMenu = document.getElementById("secondMenu");

      if (fabrkMenu && secondMenu) {
        const fabrkMenuRect = fabrkMenu.getBoundingClientRect();
        const secondMenuRect = secondMenu.getBoundingClientRect();

        if (
          !(
            fabrkMenuRect.left <= event.clientX &&
            event.clientX <= fabrkMenuRect.right &&
            fabrkMenuRect.top <= event.clientY &&
            event.clientY <= fabrkMenuRect.bottom
          ) &&
          !(
            secondMenuRect.left <= event.clientX &&
            event.clientX <= secondMenuRect.right &&
            secondMenuRect.top <= event.clientY &&
            event.clientY <= secondMenuRect.bottom
          )
        ) {
          setSelectedMenu({
            type: "",
            name: "",
          });
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [setSelectedMenu]);
};

export default useOutsideMouseMove;
