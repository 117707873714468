import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { SessionRow } from "./session.type";

export function createNewSession(): Promise<SessionRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.session;

  const request = setHeaderOptions({
    method: "POST",
  });

  return fetchData<SessionRow>(url, request);
}

export function getSessionById(sessionId: string): Promise<SessionRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.session + "/" + sessionId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<SessionRow>(url, request);
}
