/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useCompanyContext } from "../company/companyContext";
import { getUniqueValuesForTableHeadFilter } from "../company/companyHelpers";
import ReimbursementAdminTableList from "../reimbursement/reimbursementAdminTableList";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, {
  FilterValue,
  TableHeadItem,
} from "../table/tableWithSearch";

import { ReimbursementRow } from "../../api/reimbursement.type";

const TABLE_HEAD = [
  { label: "Created at" },
  { label: "Company" },
  { label: "Campaign" },
  { label: "Status " },
  { label: "Amount / Handle / Phone" },
];

function ReimbursementsAdminDashboard() {
  const { reimbursements } = useReimbursementContext();
  const { companies } = useCompanyContext();
  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);

  const [reimbursementFilterFunction, setReimbursementFilterFunction] =
    useState<(reimbursement: ReimbursementRow, index: number) => boolean>(
      baseFilter
    );
  const [reimbursementSortFunction] = useState(baseSort);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [filteredList, setFilteredList] = useState<ReimbursementRow[]>([]);

  function baseSort() {
    return (a: ReimbursementRow, b: ReimbursementRow) =>
      new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf();
  }

  const isPending = (reimbursement: ReimbursementRow) => {
    return (
      !reimbursement.payment_sent &&
      reimbursement.status !== "invalid" &&
      reimbursement.status !== "duplicate" &&
      reimbursement.status !== "waiting"
    );
  };

  function filterForPending(reimbursements: ReimbursementRow[]) {
    return reimbursements.filter((r) => {
      return isPending(r);
    });
  }

  function baseFilter() {
    return (reimbursement: ReimbursementRow, index: number) => index < 10;
  }

  useEffect(() => {
    if (reimbursements && reimbursements.length > 0) {
      setFilteredList(filterForPending(reimbursements));
    }
  }, [reimbursements]);

  useEffect(() => {
    if (reimbursements) {
      setFilteredList(filterForPending(reimbursements));
      const companyIds = getUniqueValuesForTableHeadFilter(
        reimbursements.map((r) => {
          return { ...r.campaign };
        }),
        companies
      );
      const newTableHead = TABLE_HEAD.map((item) => {
        if (item.label === "Company") {
          return {
            ...item,
            filterValues: companyIds,
          };
        }
        return item;
      });
      setTableHead(newTableHead);
    }
  }, [reimbursements, companies]);

  function handleFilterValue(value: FilterValue) {
    if (value.value && reimbursements && reimbursements.length > 0) {
      if (value.value === "All") {
        setReimbursementFilterFunction(baseFilter);
        setFilteredList(filterForPending(reimbursements));
      } else {
        if (!companies || companies.length === 0) {
          return;
        }
        const foundCompany = companies.find(
          (company: { name: string }) => company.name === value.value
        );

        setReimbursementFilterFunction(
          () => (reimbursement: ReimbursementRow, index: number) =>
            reimbursement.campaign?.company_id === foundCompany?.id
        );
        setFilteredList(
          reimbursements.filter(
            (item) =>
              item.campaign?.company_id === foundCompany?.id && isPending(item)
          )
        );
      }
    }
  }

  return (
    <div className="m-2">
      {reimbursements && reimbursements.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          type="reimbursement"
          rowCount={filteredList ? filteredList?.length : 0}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleFilterValue={handleFilterValue}
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setReimbursementFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setReimbursementFilterFunction,
              advancePage: false,
            })
          }
        >
          <ReimbursementAdminTableList
            reimbursements={filteredList}
            filterFunction={reimbursementFilterFunction}
            sortFunction={reimbursementSortFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ReimbursementsAdminDashboard;
