import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  GetStatsResponse,
  SmsContentMetadataRow,
  SmsContentMetadataTotals,
} from "./smsContentMetadata.type";

export function getSmsContentMetadata(): Promise<SmsContentMetadataRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.smsContentMetadata;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<SmsContentMetadataRow[]>(url, request);
}

export function getSmsContentMetadataTotals(): Promise<
  SmsContentMetadataTotals[]
> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.smsContentMetadata + "/totals";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<SmsContentMetadataTotals[]>(url, request);
}

export function getSmsContentAverageResponse(smsContentId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.smsContentMetadata +
    "/stats/" +
    smsContentId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<GetStatsResponse>(url, request);
}
