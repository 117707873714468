import { useLoadDailyUsage } from "../../../billing/hooks/useLoadDailyUsage";
import { useLoadLeads } from "../../../lead/hooks/useLoadLeads";
import BaseReimbursementChart from "../components/BaseReimbursementChart";

import { useLoadReimbursements } from "../hooks/useLoadReimbursements";

export const ReimbursementChartByMonth = () => {
  const { count } = useLoadLeads();

  const { data } = useLoadReimbursements();

  const { data: dailyUsage } = useLoadDailyUsage();

  return (
    <BaseReimbursementChart
      totals={data}
      dailyUsage={dailyUsage}
      leadCount={count}
    />
  );
};
