import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import CampaignPrebuiltCards from "../components/campaign/campaignPrebuiltCards";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const AddCampaign = () => {
  mixpanelTrackEvent("Add campaign");

  return (
    <CampaignContextWrapper>
      <CampaignPrebuiltCards />
    </CampaignContextWrapper>
  );
};

export default AddCampaign;
