import { Input } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { useCompanyContext } from "../company/companyContext";

import { CompanyInsert } from "../../api/company.type";

function AddCompanyContainer() {
  const navigate = useNavigate();
  const { useCreateNewCompany } = useCompanyContext();

  const [newCompany, setNewCompany] = useState<CompanyInsert>({
    name: "",
    slug: "",
  });

  function onChange(e: any, key: string) {
    e.preventDefault();
    setNewCompany((prevState) => {
      return {
        ...prevState,
        [key]: e.target.value,
      };
    });
  }

  return (
    <div>
      <BreadcrumbsFromConfig
        config={[
          {
            name: "Company",
            href: "/company",
            active: false,
          },
          {
            name: "Add",
            href: "/company/add",
            active: true,
          },
        ]}
      />
      <div className="mt-4 flex w-3/6 flex-col gap-4 rounded-lg bg-gray-100 p-5">
        <div>
          <p className="text-xs text-gray-500">Name</p>
          {/* @ts-ignore */}
          <Input
            variant="static"
            value={newCompany?.name}
            onChange={(e) => onChange(e, "name")}
            className="focus:border-t-0 focus:ring-0"
          />
        </div>
        <div>
          <p className="text-xs text-gray-500">Slug</p>
          {/* @ts-ignore */}

          <Input
            variant="static"
            value={newCompany?.slug}
            onChange={(e) => onChange(e, "slug")}
            className="focus:border-t-0 focus:ring-0"
          />
        </div>
        <div className="max-w-lg">
          <ButtonSecondary
            onClick={() => {
              if (newCompany) {
                useCreateNewCompany.mutate(newCompany);
                navigate("/company");
              }
            }}
            size="lg"
          >
            Submit
          </ButtonSecondary>
        </div>
      </div>
    </div>
  );
}

export default AddCompanyContainer;
