export function calculateNPS(scores: number[]): number {
  if (!scores || scores.length === 0) {
    return 0;
  }
  const totalResponses = scores.length;
  let promotersCount = 0;
  let detractorsCount = 0;

  scores.forEach((score) => {
    if (score >= 9) promotersCount++;
    else if (score <= 6) detractorsCount++;
    // Passives (7-8) are not directly used in NPS calculation
  });

  const promotersPercentage = (promotersCount / totalResponses) * 100;
  const detractorsPercentage = (detractorsCount / totalResponses) * 100;

  const nps = promotersPercentage - detractorsPercentage;
  return Math.round(nps); // NPS is usually rounded to the nearest whole number
}
