import { Metric, Divider, Text, Card, Title, DonutChart } from "@tremor/react";
import { Fragment } from "react";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { useAdminCustomerContext } from "../admin/adminCustomerContext";
import { extrapolateYearlyRevenue } from "../../utils/parseTimeStamp";
import { getReimbursementRevenue, getSmsRevenue } from "./analyticsHelpers";

function AdminAnalytics() {
  const { customers, dailyUsage, reimbursementUsage } =
    useAdminCustomerContext();

  const smsRevenue = getSmsRevenue(dailyUsage);
  const reimbursementRevenue = getReimbursementRevenue(reimbursementUsage);

  const totalRevenue = smsRevenue + reimbursementRevenue;

  const startDate = "2023-11-08";
  const estimatedYearlyRevenue = extrapolateYearlyRevenue(
    totalRevenue,
    startDate
  );

  return (
    <div>
      <Fragment>
        <h2 className="mb-4 text-slate-500">Company KPIs</h2>
        <div className="grid grid-cols-4 gap-4">
          <Card className="mx-auto max-w-xs">
            <Text>Customers</Text>
            <Metric>{customers?.length}</Metric>
          </Card>
        </div>
        <Divider />
        <div className="grid grid-cols-4 gap-4">
          <Card className="mx-auto max-w-xs">
            <Text>Total Revenue</Text>
            <Metric>{parseCentsToDollars(totalRevenue)}</Metric>
          </Card>
          <Card className="mx-auto max-w-xs">
            <Text>Total Sms Revenue</Text>
            <Metric>{parseCentsToDollars(smsRevenue)}</Metric>
          </Card>
          <Card className="mx-auto max-w-xs">
            <Text>Total Reimbursement Revenue</Text>
            <Metric>{parseCentsToDollars(reimbursementRevenue)}</Metric>
          </Card>
        </div>
        <Divider />
        <div className="grid grid-cols-4 gap-4">
          <Card className="mx-auto max-w-xs">
            <Text>Estimated Yearly Revenue</Text>
            <Metric>{parseCentsToDollars(estimatedYearlyRevenue)}</Metric>
          </Card>
          <Card className="max-w-lg">
            <Title>Reimbursement vs Sms Revenue</Title>

            <DonutChart
              className="mt-6"
              data={[
                {
                  name: "SMS",
                  count: smsRevenue,
                },
                {
                  name: "Reimbursement",
                  count: reimbursementRevenue,
                },
              ]}
              category="count"
              index="name"
              valueFormatter={(value) => parseCentsToDollars(value)}
              colors={["blue", "pink"]}
            />
          </Card>
        </div>
        <Divider />
      </Fragment>
    </div>
  );
}

export default AdminAnalytics;
