import { MultiSelect, MultiSelectItem } from "@tremor/react";

import { Fragment } from "react";
import { SegmentsRow } from "../../api/segments.type";
import { Typography } from "@material-tailwind/react";

function MultiSegmentSelect({
  segments,
  setSelectedSegments,
  selectedSegments,
}: {
  segments: SegmentsRow[] | undefined;
  setSelectedSegments: (campaign: SegmentsRow[]) => void;
  selectedSegments: SegmentsRow[];
}) {
  return (
    <div>
      {segments && segments.length > 0 && (
        <div>
          <Typography variant="h5" className="mb-4 text-slate-500">
            Segments -{" "}
            {selectedSegments.find((s) => s.id === "all")
              ? selectedSegments.length - 1
              : selectedSegments.length}{" "}
            selected
          </Typography>
          <MultiSelect
            placeholder="Select Segments"
            value={selectedSegments.map((segment) => segment.id)}
            onValueChange={(selectedIds: any) => {
              if (selectedIds.includes("all")) {
                if (segments?.length === selectedSegments.length) {
                  setSelectedSegments([]);
                  return;
                } else {
                  setSelectedSegments(
                    //@ts-ignore
                    segments.concat({
                      id: "all",
                    }) || []
                  );
                  return;
                }
              } else if (selectedSegments.length >= segments?.length) {
                setSelectedSegments([]);
                return;
              } else {
                // Map the selected IDs back to the campaign objects
                const updatedSelectedCampaigns = segments?.filter(
                  (c: SegmentsRow) => selectedIds.includes(c.id)
                );

                setSelectedSegments(updatedSelectedCampaigns || []);
              }
            }}
          >
            <Fragment>
              {<MultiSelectItem value="all">All</MultiSelectItem>}
              {segments &&
                segments?.map((segment: SegmentsRow) => {
                  return (
                    <MultiSelectItem key={segment.id} value={segment.id}>
                      {segment.name}
                    </MultiSelectItem>
                  );
                })}
            </Fragment>
          </MultiSelect>
        </div>
      )}
    </div>
  );
}

export default MultiSegmentSelect;
