import { useQuery } from "react-query";
import { getTotalReimbursementAmount } from "../../../reimbursement/reimbursementHelpers";
import { getReimbursementsByCampaign } from "../../../../api/reimbursement.api";

export const useLoadReimbursements = (params?: Params) => {
  const { isLoading, data, error } = useQuery({
    queryFn: () =>
      getReimbursementsByCampaign(
        params?.campaignId ? params?.campaignId : "all"
      ),
    queryKey: ["query-reimbursements", "all", params?.campaignId],
    select: (res) => {
      return {
        total: res?.length || 0,
        paid: res?.filter((r) => r.status === "paid").length || 0,
        paidAmount: res ? getTotalReimbursementAmount(res) : 0,
      };
    },
    initialData: [],
    enabled: true,
  });

  return {
    isLoading,
    data,
    error,
  };
};

interface Params {
  campaignId: string;
}
