import { Chip, Typography } from "@material-tailwind/react";
import { Fragment } from "react";

import { LinkToId } from "../table/linkToId";

import { FeedbackRow } from "../../api/feedback.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";

function FeedbackTableList({
  feedback,
  filterFunction,
}: {
  feedback: FeedbackRow[] | undefined;
  filterFunction?: (lead: FeedbackRow, index: number) => boolean;
}) {
  function mapFeedbackToRow(feedback: FeedbackRow): {
    id: string;
    freeText: string;
    createdAt: string;
    campaignId: string;
    messageId: string;
    nps: number | null;
    leadId: string | null;
  } {
    const values = {
      id: feedback.id,
      freeText: feedback?.free_text ? feedback?.free_text : "N/A",
      createdAt: feedback.created_at
        ? formatTimeStampToHumanReadableShortDateTime(feedback.created_at)
        : "N/A",
      campaignId: feedback.campaign_id ? feedback.campaign_id : "N/A",
      nps: feedback?.nps ? feedback?.nps : null,
      messageId: feedback?.message_id ? feedback.message_id : "N/A",
      leadId: feedback?.lead_id ? feedback.lead_id : "N/A",
    };

    return values;
  }
  return (
    <Fragment>
      {feedback &&
        feedback
          .filter((feedback, index) =>
            filterFunction ? filterFunction(feedback, index) : true
          )
          .map((item) => mapFeedbackToRow(item))
          .map(({ id, freeText, createdAt, nps, leadId }, index) => {
            const isLast = index === feedback.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={id}>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {id}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal opacity-70"
                      >
                        {createdAt}
                      </Typography>
                    </div>
                  </div>
                </td>

                <td className={classes}>
                  <div className="flex items-center gap-3">
                    {nps && (
                      <Chip
                        className="w-5 p-1 items-center justify-center"
                        value={nps}
                        color={
                          nps && nps > 8
                            ? "green"
                            : nps && nps < 7
                            ? "red"
                            : "blue-gray"
                        }
                      />
                    )}
                  </div>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {freeText}
                  </Typography>
                </td>
                <td className={classes}>
                  <LinkToId type="chat" id={leadId} baseUrl="/lead/chat/" />
                </td>
              </tr>
            );
          })}
    </Fragment>
  );
}

export default FeedbackTableList;
