/* eslint-disable react-hooks/exhaustive-deps */
import { BarChart, Card, Subtitle, Title } from "@tremor/react";
import { DailyUsageRow } from "../../api/dailyUsage.type";
import { UsageRow } from "../../api/usage.type";
import { centsToUsDollars } from "../../utils/currencyManipulation";
import { numericMonthYearToString } from "../../utils/parseTimeStamp";
import { useBillingContext } from "./billingContext";
import DailyUsageCustomTooltip, {
  TooltipDailyCurrency,
} from "./dailyUsageToolTip";

function SmsDailyUsageChart({
  selectedUsage,
}: {
  selectedUsage: UsageRow | undefined;
}) {
  const { dailyUsage } = useBillingContext();

  function mapToChartData(usage: DailyUsageRow) {
    return {
      "Sms Total": usage.sms_balance,
      day: usage.day,
    };
  }
  function filterByMonth(usage: DailyUsageRow) {
    return usage.month === selectedUsage?.month;
  }

  const data =
    dailyUsage
      ?.filter((item) => filterByMonth(item))
      .map((item) => mapToChartData(item)) || [];

  return (
    <Card>
      <Title>Sms Daily Usage</Title>
      {selectedUsage && (
        <Subtitle>
          {numericMonthYearToString(selectedUsage?.month, selectedUsage?.year)}
        </Subtitle>
      )}

      {data && (
        <BarChart
          className="mt-6"
          data={data}
          index="day"
          categories={["Sms Total"]}
          colors={["blue"]}
          valueFormatter={centsToUsDollars}
          customTooltip={({ payload, active }) => (
            <DailyUsageCustomTooltip payload={payload} active={active}>
              <TooltipDailyCurrency selectedMonth={selectedUsage} />
            </DailyUsageCustomTooltip>
          )}
        />
      )}
    </Card>
  );
}

export default SmsDailyUsageChart;
