import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

// import { useEffect, useState } from "react";
import { QueueListIcon, StarIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { SmsContentRow } from "../../api/smsContent.type";
import ContentMenuItem from "../campaignSmsContent/ContentMenuItem";
import { useCampaignContentContext } from "../campaignSmsContent/campaignSmsContentContext";
import GenericDialog from "../dialog/genericDialog";
import { PlusIcon } from "../icons/icons";
import AddSmsContentContainer from "../smsContent/addSmsContent";
import { useCampaignContext } from "./campaignContext";

function ChooseExisting({
  smsContent,
  handleSubmit,
}: {
  smsContent: SmsContentRow[] | undefined;
  handleSubmit: (selectedSmsContent: SmsContentRow) => void;
}) {
  return (
    <Fragment>
      {smsContent?.map((content) => {
        return (
          <MenuItem onClick={() => handleSubmit(content)} key={content?.id}>
            <ContentMenuItem content={content} />
          </MenuItem>
        );
      })}
    </Fragment>
  );
}

function CampaignSmsContentAddButton() {
  const {
    useCreateCampaignSmsContent,
    handleCallAiGenerateContent,
    smsContent,
  } = useCampaignContentContext();
  const { activeCampaign } = useCampaignContext();
  const [openAddExisting, setOpenExisting] = useState(false);

  const [openAddNew, setOpenNew] = useState(false);

  function handleSubmit(selectedSmsContent: SmsContentRow) {
    useCreateCampaignSmsContent.mutate({
      campaign_id: activeCampaign?.id as string,
      sms_content_id: selectedSmsContent?.id as string,
      is_default: false,
    });
    setOpenExisting(false);
  }

  return (
    <Menu placement="bottom">
      <MenuHandler>
        <Button
          className="flex items-center gap-2 p-1"
          variant="outlined"
          color="gray"
          size="sm"
        >
          <PlusIcon className="h=4 w-4" />
          Add
        </Button>
      </MenuHandler>
      <MenuList>
        <MenuItem
          onClick={() => {
            handleCallAiGenerateContent(activeCampaign?.id as string, 1);
          }}
          className="flex flex-row gap-1 items-center content-center "
        >
          <StarIcon className="w-4 w-4" color="orange" /> AI create new
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenNew(true);
          }}
          className="flex flex-row gap-1 items-center"
        >
          <PlusIcon className="w-4 w-4" /> Add new
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            setOpenExisting(true);
          }}
          className="flex flex-row gap-1 items-center"
        >
          <QueueListIcon className="w-4 w-4" /> Choose from existing
        </MenuItem>
      </MenuList>
      <GenericDialog
        title="Add an existing message to this campaign"
        open={openAddExisting}
        setOpen={setOpenExisting}
      >
        <ChooseExisting smsContent={smsContent} handleSubmit={handleSubmit} />
      </GenericDialog>
      <GenericDialog
        title="Add SMS Content"
        open={openAddNew}
        setOpen={setOpenNew}
      >
        <AddSmsContentContainer
          campaignId={activeCampaign?.id as string}
          isDefault={false}
        />
      </GenericDialog>
    </Menu>
  );
}

export default CampaignSmsContentAddButton;
