import { useState } from "react";
import PlentiTabs from "../tab/plentiTab";
import { AdminCustomerContextWrapper } from "./adminCustomerContext";
import AdminFonts from "./adminFonts";
import AdminHeader from "./adminHeader";
import AdminNewCompany from "./adminNewCompany";
import AdminStripe from "./adminStripe";
import AdminGptTesting from "./gptTesting";
import AdminFollowUpSchedule from "./adminFollowUpSchedule";
import ChatTesting from "./chatTesting";
import Email from "../emailTemplates/EmailListContainer";
import { useSearchParams } from "react-router-dom";

const tabs = [
  {
    label: "Chat testing",
    value: "chat-testing",
  },
  {
    label: "Follow up schedule",
    value: "follow-up",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "New company",
    value: "new-company",
  },
  {
    label: "GPT",
    value: "gpt",
  },
  {
    label: "Stripe",
    value: "stripe",
  },
  {
    label: "Fonts",
    value: "fonts",
  },
];

function AdminContainer() {
  let [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [activeTab, setActiveTab] = useState(
    tab && tabs.find((t) => t.value === tab)
      ? tabs.find((t) => t.value === tab)
      : tabs[0]
  );

  if (!activeTab) {
    return null;
  }

  const { value } = activeTab;

  return (
    <div>
      <AdminCustomerContextWrapper>
        <div className="max-w-screen-2xl flex flex-col p-5 gap-10">
          <AdminHeader />
          <PlentiTabs
            tabConfig={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {value === "chat-testing" && <ChatTesting />}
          {value === "follow-up" && <AdminFollowUpSchedule />}
          {value === "email" && <Email />}
          {value === "fonts" && <AdminFonts />}
          {value === "new-company" && <AdminNewCompany />}
          {value === "stripe" && <AdminStripe />}
          {value === "gpt" && <AdminGptTesting />}
        </div>
      </AdminCustomerContextWrapper>
    </div>
  );
}

export default AdminContainer;
