/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { TestContextRow } from "../../api/testContext.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { useLoadCampaigns } from "../campaign/hooks/useLoadCampaigns";
import { useCompanyContext } from "../company/companyContext";
import { useAdminGptContext } from "./adminGptContext";
import { useGetTestContext } from "./hooks/useGetTestContext";

function SavedTestContextList() {
  const { data: testContext } = useGetTestContext();
  const {
    setSelectedCampaign,
    setSelectedLead,
    leads,
    setDefaultValues,
    setIsReceipt,
  } = useAdminGptContext();
  const { companies, setSelectedCompany } = useCompanyContext();
  const { campaigns } = useLoadCampaigns();
  const [leadId, setLeadId] = useState<string>("");

  function handleOnClick(context: TestContextRow) {
    const foundCompany =
      context.company_id && companies?.find((c) => c.id === context.company_id);
    if (foundCompany) {
      setSelectedCompany(foundCompany);
    }
    const foundCampaign = campaigns?.find((c) => c.id === context.campaign_id);
    if (foundCampaign) {
      setSelectedCampaign(foundCampaign);
    }

    setLeadId(context.lead_id);

    setDefaultValues({
      type: context.image_type,
      issues:
        context.image_issues && context.image_issues.length > 0
          ? context.image_issues[0]
          : "",
      message:
        context.messages && context.messages.length > 0
          ? context.messages[0]
          : "",
      name: context.name,
    });
    setIsReceipt(context.is_receipt);
  }

  // Leads here are filtered by company in the context component. So we to look for the lead when the correct leads (company specific) are passed in.
  useEffect(() => {
    if (leadId) {
      const foundLead = leads?.find((l) => l.id === leadId);
      if (foundLead) {
        setSelectedLead(foundLead);
      }
    }
  }, [leadId, leads]);

  return (
    <div>
      {testContext?.map((context) => {
        const { email, created_at, name } = context;
        return (
          <div key={context.id} className="p-1">
            <Card
              className="p-2 sm:grid-cols-1 grid grid-cols-2 gap-1 cursor-pointer hover:bg-gray-100 "
              onClick={() => handleOnClick(context)}
            >
              <Typography className="break-words" variant="small">
                {formatTimeStampToHumanReadableShortDateTime(created_at)}
              </Typography>
              <Typography className="break-words" variant="small">
                {email}
              </Typography>

              <Typography className="break-words" variant="small">
                {name}
              </Typography>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default SavedTestContextList;
