/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment } from "react";

import { useCampaignContext } from "./campaignContext";

import InfoPopover from "../InfoPopover/infoPopover";
import CampaignTransitionFlow from "../reactFlow/CampaignTransitionFlow";
import { CampaignTransitionContextWrapper } from "../reactFlow/hooks/CampaignTransitionFlowContext";

function _CampaignFlowContainer() {
  const { activeCampaign } = useCampaignContext();

  return (
    <div className="w-full">
      {activeCampaign && (
        <Fragment>
          <div className="relative flex justify-between">
            <div className="relative">
              <Typography variant="h6">Transitions</Typography>
              <div className="absolute top-[-12px] right-[-15px]">
                <InfoPopover>
                  <p className="text-slate-700">
                    Define how your leads will transition from one campaign to
                    the next.
                  </p>
                </InfoPopover>
              </div>
            </div>
          </div>
          <Divider className="my-1" />
          <div className="min-w-96 min-h-96">
            {activeCampaign?.id && (
              <CampaignTransitionFlow
                campaignName={activeCampaign?.name}
                id={activeCampaign?.id}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

const CampaignFlowContainer = ({ campaignId }: { campaignId: string }) => {
  return (
    <CampaignTransitionContextWrapper campaignId={campaignId}>
      <_CampaignFlowContainer />
    </CampaignTransitionContextWrapper>
  );
};

export default CampaignFlowContainer;
