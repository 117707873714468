import { Typography } from "@material-tailwind/react";
import TableWithSearch from "../table/tableWithSearch";
import { useAdminCustomerContext } from "./adminCustomerContext";
import AdminCustomerTableList from "./adminCustomerTableList";

const tableHead = [
  {
    label: "Name",
  },
  {
    label: "Created At",
  },
  {
    label: "Stripe ID",
  },
  {
    label: "Current Balance",
  },
  {
    label: "Min Balance",
  },
  {
    label: "Max Balance",
  },
  {
    label: "Recharge",
  },
];

function AdminStripe() {
  const { customers } = useAdminCustomerContext();

  return (
    <div>
      <Typography variant="h2">Stripe admin controls</Typography>
      <TableWithSearch
        tableHead={tableHead}
        type={"Customer Details"}
        rowsPerPage={10}
      >
        <AdminCustomerTableList customers={customers} />
      </TableWithSearch>
    </div>
  );
}

export default AdminStripe;
