import { useFollowUpContext } from "./followUpMessageContext";

function FollowUpExample() {
  const { startingDay, selectedMax, selectedIncreasing } = useFollowUpContext();
  const firstAdd = Number(startingDay) + Number(selectedIncreasing);
  const secondAdd = Number(startingDay) + Number(selectedIncreasing) * 2;
  const dayTwo = Number(startingDay) + firstAdd;

  const dayThree = dayTwo + secondAdd;
  return (
    <div className="my-4 text-center mx-auto w-fit gap-10 bg-gray-100 rounded-xl p-2">
      <p>
        {`Send a follow up message on  `}
        <span className="font-bold">day {startingDay}</span>
      </p>
      <p>
        {`and then (${firstAdd} days later) on `}
        <span className="font-bold">day {dayTwo} </span>
      </p>
      <p>
        {`and then (${secondAdd} days later) on `}
        <span className="font-bold">day {dayThree} </span>
      </p>
      <p> {`and so on for a total of ${selectedMax}`}</p>
    </div>
  );
}

export default FollowUpExample;
