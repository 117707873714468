import { Button } from "@material-tailwind/react";
import { useFabrkMessageContext } from "../hooks/FabrkMessageContext";
import AssistantMessageChatItem from "./AssistantMessageChatItem";
import MessageLoadingSkeleton from "./MessageLoadingSkeleton";
import UserMessageChatItem from "./UserMessageChatItem";
import { useEffect, useState } from "react";

function MessageViewContainer() {
  const { messages, setMessages, loading } = useFabrkMessageContext();

  const [hoveredBadge, setHoveredBadge] = useState("");
  const [clickedBadge, setClickedBadge] = useState("");
  const [activeMessageId, setActiveMessageId] = useState<string>("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      console.log("handleClickOutside", event.target);
      if ((event.target as HTMLElement).closest(".badge-button")) {
        return;
      }
      setActiveMessageId("");
      setClickedBadge("");
      setHoveredBadge("");
    };

    if (activeMessageId) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [activeMessageId]);

  const handleClick = (
    badgeType: string,
    messageId: string,
    event: React.MouseEvent
  ) => {
    // event.stopPropagation(); // Prevent the click event from propagating to the window

    if (activeMessageId === messageId) {
      setClickedBadge(badgeType);
    } else {
      setActiveMessageId(messageId);
      setClickedBadge(badgeType);
    }
  };

  return (
    <div>
      <header className="flex justify-end items-center p-6 ">
        <Button variant="text" color="orange" onClick={() => setMessages([])}>
          New Thread
        </Button>
      </header>
      <main className="flex flex-col gap-10 px-6 min-h-screen mt-20 max-w-2xl mx-auto">
        {messages?.map((message, index) => {
          if (message.role === "user") {
            return <UserMessageChatItem message={message} index={index} />;
          }
          if (message.role === "assistant") {
            return (
              <AssistantMessageChatItem
                message={message}
                index={index}
                messages={messages}
                handleClick={handleClick}
                clickedBadge={clickedBadge}
                setHoveredBadge={setHoveredBadge}
                hoveredBadge={hoveredBadge}
                activeMessageId={activeMessageId}
                setActiveMessageId={setActiveMessageId}
              />
            );
          }
          return null;
        })}
        {loading && <MessageLoadingSkeleton />}
      </main>
    </div>
  );
}

export default MessageViewContainer;
