import AddAgentContainer from "../components/agents/AddAgentContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const AddAgent = () => {
  mixpanelTrackEvent("Add agent");

  return <AddAgentContainer />;
};

export default AddAgent;
