/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";
import ReactFlow, {
  Controls,
  MarkerType,
  NodeOrigin,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";

import "reactflow/dist/base.css";

import { useCampaignContext } from "../campaign/campaignContext";
import CampaignSelectNode from "./CampaignSelectNode";
import NameNode from "./NameNode";
import { useCampaignTransitionContext } from "./hooks/CampaignTransitionFlowContext";
import { CampaignRow } from "../../api/campaign.type";

const nodeTypes = {
  custom: NameNode,
  campaignSelect: CampaignSelectNode,
};

const nodeOrigin: NodeOrigin = [0.5, 0.5];

const initNodes = [
  {
    id: "1",
    type: "custom",
    data: { name: "Campaign name" },
    position: { x: 0, y: 50 },
  },
  {
    id: "2",
    type: "campaignSelect",
    data: {
      name: "reimbursed",
    },

    position: { x: -200, y: 200 },
  },
  {
    id: "3",
    type: "campaignSelect",
    data: {
      name: "stale",
    },
    position: { x: 200, y: 200 },
  },
];

const initEdges = [
  {
    id: "e1-2",
    source: "1",
    label: "reimbursed",
    sourceHandle: "reimbursed",
    target: "2",
    data: {
      name: "Reimbursed",
    },
  },
  {
    id: "e1-3",
    source: "1",
    label: "stale",
    target: "3",
    sourceHandle: "stale",
    data: {
      name: "stale",
    },
  },
];

const CampaignTransitionFlow = ({
  campaignName,
  id,
}: {
  campaignName: string;
  id: string;
}) => {
  const { campaignTransitions, createTransition, removeTransition } =
    useCampaignTransitionContext();
  const { campaigns } = useCampaignContext();
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  useEffect(() => {
    if (campaignName) {
      setNodes((nodes) => {
        const initNodes = nodes.map((node) => {
          if (node.id === "1") {
            return {
              ...node,
              data: { ...node.data, name: campaignName },
            };
          }
          if (node.type === "campaignSelect") {
            const foundTransition = campaignTransitions?.find(
              (ct) => ct.trigger_type === node.data.name
            );
            return {
              ...node,
              data: {
                ...node.data,
                campaigns: campaigns,
                selectedCampaign: foundTransition
                  ? {
                      id: foundTransition.to_campaign,
                    }
                  : undefined,
                setSelectedCampaign: (campaign: CampaignRow | undefined) => {
                  if (campaign) {
                    createTransition({
                      fromCampaign: id,
                      toCampaign: campaign?.id,
                      triggerType: node.data.name,
                    });
                  } else {
                    if (foundTransition) {
                      removeTransition(foundTransition.id);
                      return undefined;
                    }
                  }
                },
              },
            };
          }
          return node;
        });

        return initNodes;
      });

      if (campaignTransitions && campaignTransitions.length > 0) {
        const updatedEdges = campaignTransitions.map((transition) => {
          return {
            id: transition.id,
            source: transition.from_campaign,
            label: transition.trigger_type,
            target: transition.to_campaign,
            animated: true,

            sourceHandle: transition.trigger_type,
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
          };
        });
        setEdges(updatedEdges);
      }
    }
  }, [campaignName, campaignTransitions]);

  return (
    <div style={{ width: 800, height: 400, marginLeft: 5 }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={{ padding: 2 }}
        nodeOrigin={nodeOrigin}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default CampaignTransitionFlow;
