import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CustomerRow, CustomerUpdate } from "./customer.type";

export function getCustomers(): Promise<CustomerRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.customer;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CustomerRow[]>(url, request);
}

export function getCustomer(): Promise<CustomerRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.customer + "/customer";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CustomerRow>(url, request);
}

export function updateCustomer(values: CustomerUpdate) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.customer;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<CustomerRow>(url, request);
}

export function rechargeCustomer(customer_id: string) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.customer + "/trigger-customer";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ customer_id }),
  });

  return fetchData(url, request);
}
