import { BarChart } from "@tremor/react";
import { SmsContentMetadataRow } from "../../api/smsContentMetadata.type";
import { secondsToTimeFormat } from "../../utils/parseTimeStamp";
import { countDeviations } from "../../utils/calculateAverage";

const MessageResponseIntervalBarChart = ({
  data,
  average,
  standardDeviation,
}: {
  data: SmsContentMetadataRow[];
  average: number;
  standardDeviation: number;
}) => {
  const deviationCounts = countDeviations(
    data.map((i) => i.first_response_interval),
    {
      average,
      standardDeviation,
    }
  );

  return (
    <BarChart
      className="w-full "
      data={deviationCounts
        .filter((i) => i.count > 0)
        .map((i) => {
          const minInfinity =
            i.range.min === -Infinity || i.range.min < 0 ? 0 : i.range.min;
          const maxInfinity = i.range.max === Infinity;

          return {
            range: `${secondsToTimeFormat(minInfinity)} to ${
              maxInfinity ? "Infinity" : secondsToTimeFormat(i.range.max)
            }`,
            count: i.count,
          };
        })}
      index="range"
      categories={["count"]}
      colors={["purple"]}
      yAxisWidth={100}
      showAnimation={true}
    />
  );
};

export default MessageResponseIntervalBarChart;
