/* eslint-disable eqeqeq */
import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Fragment } from "react";

import { useCompanyContext } from "../company/companyContext";
import { findCompanyById } from "../company/companyHelpers";

import {
  CheckBadgeIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { CompanyRow } from "../../api/company.type";
import { EmailDnsRow } from "../../api/emailDns.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { CopyToClipboardIconButton } from "../hooks/copyToClipboard";
import {
  EllipsisVertical,
  RefreshIcon,
  SmallCheckMark,
  XMarkIcon,
} from "../icons/icons";
import PlentiLoadingIndicator from "../loadingIndicator/PlentiLogoLoading";
import EmailDnsValidationResponse from "./EmailDnsValidationResponse";
import { useEmailDnsContext } from "./hooks/EmailDnsContext";

function EmailDnsTableList({
  dnsRows,
  filterFunction,
}: {
  dnsRows: EmailDnsRow[] | undefined;
  filterFunction?: (prompt: EmailDnsRow, index: number) => boolean;
}) {
  const { companies } = useCompanyContext();

  const { validationResponse, validateDns, deleteDns, loading } =
    useEmailDnsContext();

  function mapCampaignToRow(emailDns: EmailDnsRow): {
    id: string;
    createdAt: string;
    company: CompanyRow | null | undefined;
    host: string;
    subdomain: string | null;
    status: boolean;
    value: string;
    type: string;
    sgId: string;
    itemType: string;
    aiAutoReply: boolean;
  } {
    const values = {
      id: emailDns.id,
      createdAt: emailDns.created_at
        ? formatTimeStampToHumanReadableShortDateTime(emailDns.created_at)
        : "N/A",

      company:
        emailDns.company_id && companies && companies.length > 0
          ? findCompanyById(companies, emailDns.company_id)
          : null,
      host: emailDns.host ? emailDns.host : "N/A",
      subdomain: emailDns.subdomain ? emailDns.subdomain : null,
      status: emailDns.valid,
      type: emailDns.type ? "CNAME" : "N/A",
      value: emailDns.data ? emailDns.data : "N/A",
      sgId: emailDns.sg_id ? emailDns.sg_id : "N/A",
      itemType: emailDns.type,
      aiAutoReply: emailDns.aiAutoReply,
    };

    return values;
  }

  const txtRecord = {
    id: "1",
    createdAt:
      dnsRows && dnsRows[0].created_at
        ? formatTimeStampToHumanReadableShortDateTime(dnsRows[0].created_at)
        : "N/A",
    company:
      dnsRows && dnsRows[0].company_id
        ? findCompanyById(companies, dnsRows[0].company_id)
        : null,
    host: `_dmarc.${dnsRows && dnsRows[0].domain ? dnsRows[0].domain : "N/A"}`,
    subdomain: "subdomain",
    status: dnsRows && dnsRows[0].valid ? dnsRows[0].valid : false,
    type: "TXT",
    value: "v=DMARC1; p=none;",
    sgId: dnsRows && dnsRows[0].sg_id ? dnsRows[0].sg_id : "N/A",
    itemType: "TXT",
    aiAutoReply:
      dnsRows && dnsRows[0].aiAutoReply ? dnsRows[0].aiAutoReply : false,
  };

  return (
    <Fragment>
      {dnsRows &&
        dnsRows
          .filter((lead, index) =>
            filterFunction ? filterFunction(lead, index) : true
          )
          .map((item) => mapCampaignToRow(item))
          .concat(txtRecord)
          .map(
            (
              {
                id,
                createdAt,
                company,
                host,
                value,
                status,
                type,
                sgId,
                itemType,
                aiAutoReply,
              },
              index
            ) => {
              const isLast = index === dnsRows.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={id} className="even:bg-blue-gray-50/50">
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {createdAt}
                        </Typography>
                      </div>
                    </div>
                  </td>

                  <td className={classes}>
                    {company?.company_config &&
                    company?.company_config[0] &&
                    company?.company_config[0].logo_image_url ? (
                      <img
                        height={50}
                        width={50}
                        src={company.company_config[0].logo_image_url}
                        alt="logo"
                      />
                    ) : (
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {company?.name || "N/A"}
                      </Typography>
                    )}
                  </td>
                  <td className={classes}>
                    {status ? (
                      <CheckBadgeIcon className="h-6 w-6" color="green" />
                    ) : (
                      "Pending"
                    )}
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {type}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-row gap-1">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {host}
                      </Typography>
                      <CopyToClipboardIconButton text={host} />
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-row gap-1">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {value}
                      </Typography>
                      <CopyToClipboardIconButton text={value} />
                    </div>
                  </td>

                  <td className={classes}>
                    {status && (
                      <CheckBadgeIcon className="h-6 w-6" color="green" />
                    )}
                    {!status && <XCircleIcon className="h-6 w-6" color="red" />}

                    {validationResponse && validationResponse.id == sgId ? (
                      <EmailDnsValidationResponse
                        validationResponse={
                          validationResponse.validation_results
                            ? validationResponse?.validation_results[
                                itemType as keyof typeof validationResponse.validation_results
                              ]
                            : null
                        }
                      />
                    ) : null}
                  </td>
                  <td className={classes}>
                    <div className="flex flex-row gap-1">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {aiAutoReply ? (
                          <SmallCheckMark className="h-6 w-6" color="green" />
                        ) : (
                          <XMarkIcon className="h-6 w-6" color="red" />
                        )}
                      </Typography>
                    </div>
                  </td>

                  <td className={classes}>
                    {loading && !status ? (
                      <PlentiLoadingIndicator className="h-6 w-6" />
                    ) : (
                      <Menu placement="top">
                        <MenuHandler>
                          <IconButton variant="text" color="blue-gray">
                            <EllipsisVertical className="h-4 w-4" />
                          </IconButton>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem
                            className="flex flex-row items-center gap-1"
                            disabled={status}
                            onClick={() => {
                              validateDns(sgId);
                            }}
                          >
                            <RefreshIcon className="h-4 w-4" /> Check validation
                          </MenuItem>

                          <MenuItem
                            className="flex flex-row items-center gap-1"
                            onClick={() => {
                              deleteDns(sgId);
                            }}
                          >
                            <TrashIcon className="h-4 w-4" /> Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </td>
                </tr>
              );
            }
          )}
    </Fragment>
  );
}

export default EmailDnsTableList;
