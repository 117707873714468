import CheezyPoofs from "../cheezyPoofs/cheezyPoofs";
import LoginLayout from "../layout/LoginLayout";
import { PreUserContextWrapper } from "./PreUserContext";
import SignupQuestions from "./SignupQuestions";

const Register = () => {
  return (
    <PreUserContextWrapper>
      <LoginLayout>
        <SignupQuestions />
        <div className="hidden md:block">
          <CheezyPoofs />
        </div>
      </LoginLayout>
    </PreUserContextWrapper>
  );
};

export default Register;
