import { useSearchParams } from "react-router-dom";
import AddExtractionSchemaCampaignContainer from "../components/agents/AddExtractionSchemaCampaign";
import { ExtractionSchemaContextWrapper } from "../components/agents/ExtractionSchemaContext";
import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

function AddExtractionSchemaCampaign() {
  mixpanelTrackEvent("Add extraction schema campaign page visited");

  let [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaignId");

  return (
    <CampaignContextWrapper>
      <ExtractionSchemaContextWrapper campaignId={campaignId || ""}>
        <AddExtractionSchemaCampaignContainer />
      </ExtractionSchemaContextWrapper>
    </CampaignContextWrapper>
  );
}

export default AddExtractionSchemaCampaign;
