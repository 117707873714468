import {
  CampaignAiFunctionRow,
  CampaignAiFunctionRowUpdate,
} from "../api/campaignAiFunction.type";
import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";

export function getCampaignAiFunctionsByCampaign(
  campaignId: string
): Promise<CampaignAiFunctionRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignAiFunction +
    "/campaign/" +
    campaignId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<CampaignAiFunctionRow[]>(url, request);
}

export function addAiCampaignFunction({
  campaignId,
  aiFunctionId,
}: {
  campaignId: string;
  aiFunctionId: string;
}): Promise<CampaignAiFunctionRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignAiFunction +
    "/campaign/" +
    campaignId +
    "/ai-function/" +
    aiFunctionId;

  const request = setHeaderOptions({ method: "PUT" });

  return fetchData<CampaignAiFunctionRow[]>(url, request);
}

export function informLeadsOfNewAgent({
  campaignId,
  aiFunctionId,
}: {
  campaignId: string;
  aiFunctionId: string;
}): Promise<string> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignAiFunction +
    "/inform/campaign/" +
    campaignId +
    "/ai-function/" +
    aiFunctionId;

  const request = setHeaderOptions({ method: "PUT" });

  return fetchData<string>(url, request);
}

export function updateAiCampaignFunction({
  campaignIdFunctionId,
  values,
}: {
  campaignIdFunctionId: string;
  values: CampaignAiFunctionRowUpdate;
}): Promise<CampaignAiFunctionRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.campaignAiFunction +
    "/" +
    campaignIdFunctionId;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<CampaignAiFunctionRow>(url, request);
}
