import ChatForm from "./chatForm";
import { useMessageOverviewContext } from "./messageOverviewContext";

function ConversationFormContainer() {
  const { activeConversation } = useMessageOverviewContext();

  if (!activeConversation) {
    return null;
  }

  return (
    <div>
      <ChatForm
        messages={activeConversation}
        loading={false}
        // @ts-ignore
        activeLead={activeConversation[0].lead || null}
      />
    </div>
  );
}

export default ConversationFormContainer;
