/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import { useCampaignContext } from "../campaign/campaignContext";
import { useCompanyContext } from "../company/companyContext";
import { CopyToClipboardIconButton } from "../hooks/copyToClipboard";
import { PlentiLink } from "../link/linkToInternal";
import { phoneInput } from "../forms/phoneInput";

function CampaignLinksContainer() {
  const { activeCampaign } = useCampaignContext();
  const { selectedCompanyConfig, selectedCompany } = useCompanyContext();

  if (!activeCampaign) {
    return <div>Error: No Campaign</div>;
  }

  const { id } = activeCampaign;

  const html = phoneInput({
    campaignId: id,
    companyId: activeCampaign?.company_id as string,
    companyName: selectedCompany?.name as string,
  });

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Typography variant="h6">Links</Typography>
      </div>

      <Divider className="my-1" />
      <div className="my-4 grid w-full gap-10">
        <div className="flex w-full flex-col gap-6">
          <div className="grid grid-cols-4 items-end">
            <PlentiLink text="Demo" to={`/campaign/${id}/demo`} />
            <a
              className="text-primary-500"
              href={`https://${selectedCompanyConfig?.subdomain}.plentiai.com/try/${selectedCompany?.slug}?campaignId=${id}`}
              target="_blank"
            >
              Try link
            </a>
            <div className="flex flex-row gap-2">
              <Typography variant="small" className="text-primary-500">
                Embeddable Phone Input
              </Typography>
              <CopyToClipboardIconButton text={html} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignLinksContainer;
