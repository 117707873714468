import { Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";

import { LinkToId } from "../table/linkToId";
import SkeletonTableList from "../table/skeletonTableList";

import { LeadWithMessageCount } from "../../api/lead.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { useLoadLeads } from "./hooks/useLoadLeads";

export type LeadTableListProps = {
  leads: LeadWithMessageCount[] | undefined;
  filterFunction?: (lead: LeadWithMessageCount, index: number) => boolean;
  sortFunction?: (a: LeadWithMessageCount, b: LeadWithMessageCount) => number;
};

function LeadTableList({ leads, sortFunction }: LeadTableListProps) {
  const [columnCount] = useState(10);
  const { isLoading } = useLoadLeads();
  function mapLeadToRow(lead: LeadWithMessageCount): {
    id: string;
    createdAt: string;
    phone: string;
    email: string;
    isTest: string;
    userMessageCount: number;
    assistantMessageCount: number;
    campaignId: string;
    campaignName: string;
  } {
    const values = {
      id: lead.id,
      phone: lead?.phone ? lead?.phone : "N/A",
      createdAt: lead.created_at
        ? formatTimeStampToHumanReadableShortDateTime(lead.created_at)
        : "N/A",
      email: lead.email ? lead.email : "N/A",
      isTest: lead.is_test ? "Yes" : "No",
      campaignId: lead.campaign_id ? lead.campaign_id : "N/A",
      userMessageCount: lead.user_message_count,
      assistantMessageCount: lead.assistant_message_count,
      campaignName:
        lead.campaign && lead.campaign.name ? lead.campaign.name : "N/A",
    };

    return values;
  }

  return (
    <Fragment>
      {leads && leads.length > 0 && !isLoading ? (
        leads
          .sort((a, b) => (sortFunction ? sortFunction(a, b) : 0))
          .map((item) => mapLeadToRow(item))
          .map(
            (
              {
                id,
                email,
                phone,
                createdAt,
                userMessageCount,
                assistantMessageCount,
                campaignName,
              },
              index
            ) => {
              const isLast = index === leads.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={index} className="even:bg-blue-gray-50/50">
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {phone}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {email}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {id}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {createdAt}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {campaignName}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {userMessageCount}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {assistantMessageCount}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <LinkToId type="lead" id={id} baseUrl="/lead/" />
                  </td>
                  <td className={classes}>
                    <LinkToId type="chat" id={id} baseUrl="/lead/chat/" />
                  </td>
                </tr>
              );
            }
          )
      ) : (
        <SkeletonTableList columnCount={columnCount} />
      )}
    </Fragment>
  );
}

export default LeadTableList;
