import { createContext, useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addSegmentsToCampaign,
  createSegment,
  deleteSegment,
  getSegments,
  runSegment,
} from "../../../api/segments.api";
import { AddSegmentToCampaign, PartialSegment } from "./SegmentContext.types";
import { SegmentRunResponse, SegmentsRow } from "../../../api/segments.type";
import { useAlertContext } from "../../alert/alertContext";

export const SegmentContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const queryClient = useQueryClient();

  const [segmentResponse, setSegmentResponse] = useState<SegmentRunResponse>();
  const [activeSegment, setActiveSegment] = useState<PartialSegment>();

  const { mutate, isLoading: createSegmentLoading } = useMutation(
    createSegment,
    {
      onSuccess: (res) => {
        setSegmentResponse(res);
        queryClient.invalidateQueries("segments");
      },
    }
  );

  function createSegmentFromQuery(query: string) {
    setSegmentResponse(undefined);
    setActiveSegment(undefined);
    mutate(query);
  }

  const {
    data: segments,
    isLoading: segmentLoading,
    refetch,
  } = useQuery({
    queryKey: "segments",
    queryFn: () => getSegments(),
  });

  const { mutate: runSegmentQuery, isLoading: runSegmentLoading } = useMutation(
    runSegment,
    {
      onSuccess: (res) => {
        setSegmentResponse(res);
      },
    }
  );

  const { mutate: handleRemoveSegment, isLoading: removeSegmentLoading } =
    useMutation(deleteSegment, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("segments");
        refetch();
        setActiveSegment(undefined);
        setSegmentResponse(undefined);
        setAlertProps({
          message: `Segment removed`,
          color: "green",
        });
      },
    });

  function handleRunSegment(segment: PartialSegment) {
    setActiveSegment(segment);
    runSegmentQuery(segment.id);
  }

  const { mutate: addSegments, isLoading: addSegmentsLoading } = useMutation(
    addSegmentsToCampaign,
    {
      onSuccess: (res) => {
        setSegmentResponse(res);
        queryClient.invalidateQueries("campaigns");
        setAlertProps({
          message: `Segments added to campaign`,
          color: "green",
        });
      },
    }
  );

  const value = {
    segmentResponse,
    handleRunSegment,
    loading:
      segmentLoading ||
      runSegmentLoading ||
      createSegmentLoading ||
      removeSegmentLoading ||
      addSegmentsLoading,
    activeSegment,
    setActiveSegment,
    segments,
    createSegmentFromQuery,
    handleRemoveSegment,
    addSegments,
    setSegmentResponse,
  };

  return (
    <SegmentContext.Provider value={value}>
      {props.children}
    </SegmentContext.Provider>
  );
};

export const SegmentContext = createContext({
  segmentResponse: {} as SegmentRunResponse | undefined,
  handleRunSegment: (segment: PartialSegment) => {},
  loading: false,
  activeSegment: {} as PartialSegment | undefined,
  setActiveSegment: (segment: PartialSegment | undefined) => {},
  segments: [] as SegmentsRow[] | undefined,
  createSegmentFromQuery: (query: string) => {},
  handleRemoveSegment: (segmentId: string) => {},
  addSegments: {} as AddSegmentToCampaign,
  setSegmentResponse: (segmentResponse: SegmentRunResponse | undefined) => {},
});

export const useSegmentContext = () => useContext(SegmentContext);
