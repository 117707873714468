import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  GetReimbursementOcr,
  ReimbursementOcrRow,
} from "../api/receiptOcr.type";
import {
  CreateReimbursement,
  ReimbursementEmbeddingMatch,
  ReimbursementRow,
  ReimbursementUpdate,
} from "../api/reimbursement.type";

export function getReimbursements(): Promise<ReimbursementRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.reimbursement;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementRow[]>(url, request);
}

export function getReimbursementsByCampaign(
  campaignId: string
): Promise<ReimbursementRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/campaign/" +
    campaignId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementRow[]>(url, request);
}

export function updateReimbursements(
  reimbursement: ReimbursementUpdate
): Promise<ReimbursementRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/" +
    reimbursement.id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(reimbursement),
  });

  return fetchData<ReimbursementRow>(url, request);
}

export function getReimbursementOcr({
  reimbursementId,
}: GetReimbursementOcr): Promise<ReimbursementOcrRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.ocr + "/ocr-reimbursement";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ reimbursementId }),
  };

  return fetchData<ReimbursementOcrRow[]>(url, request);
}

export function getReimbursementById({ id }: { id: string }) {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.reimbursement + "/" + id;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementRow>(url, request);
}

export function getReimbursementByOcrId(ocrId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/ocr/" +
    ocrId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementRow>(url, request);
}

export function getTotalByCampaignAndDates({
  campaignId,
  startDate,
  stopDate,
}: {
  campaignId: string;
  startDate: string;
  stopDate: string;
}) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/total-by-campaign";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ campaignId, startDate, stopDate }),
  });

  return fetchData<number>(url, request);
}

export function findPotentialDuplicates(reimbursementId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/duplicate/" +
    reimbursementId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementRow[]>(url, request);
}

export function deleteReimbursement(reimbursementId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/" +
    reimbursementId;

  const request = setHeaderOptions({
    method: "DELETE",
  });

  return fetchData<ReimbursementRow>(url, request);
}

export function getReimbursementsByLead(leadId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursement +
    "/lead/" +
    leadId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementRow[]>(url, request);
}

export function getReimbursementIdentifierMatched(reimbursementId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.reimbursementEmbedding +
    "/match/" +
    reimbursementId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementEmbeddingMatch[]>(url, request);
}

export function createReimbursement(reimbursement: CreateReimbursement) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.reimbursement;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(reimbursement),
  });

  return fetchData<ReimbursementRow>(url, request);
}
