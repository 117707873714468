/* eslint-disable react/jsx-pascal-case */
import { useParams } from "react-router-dom";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import ExtractionAgentDetailsContainer from "./ExtractionAgentDetailsContainer";
import { useAgentContext } from "./AgentContext";
import {
  ExtractionSchemaContextWrapper,
  useExtractionSchemaContext,
} from "./ExtractionSchemaContext";
import ExtractionSchemaList from "./ExtractionSchemaListContainer";

function _ViewAgentContainer() {
  const { slug } = useParams();

  const { agent } = useAgentContext();
  const { schema } = useExtractionSchemaContext();

  const breadCrumbsConfig = [
    {
      name: "Agents",
      href: "/agents",
      active: false,
    },
    {
      name: "Edit",
      href: `/agent/${slug}`,
      active: true,
    },
  ];

  return (
    <div className="p-5 flex flex-col gap-5">
      <BreadcrumbsFromConfig config={breadCrumbsConfig} />
      {agent.slug === "extraction" && (
        <>
          <ExtractionAgentDetailsContainer />
          <ExtractionSchemaList schema={schema} />
        </>
      )}
    </div>
  );
}

const ViewAgentContainer = () => {
  return (
    <ExtractionSchemaContextWrapper>
      <_ViewAgentContainer />
    </ExtractionSchemaContextWrapper>
  );
};

export default ViewAgentContainer;
