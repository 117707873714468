import LayoutBase from "./LayoutBase";

function LoginLayout({ children }: { children: React.ReactNode }) {
  return (
    <LayoutBase>
      <div className="m-auto w-full">
        <div className="grid md:grid-cols-2 grid-cols-1 justify-items-center items-center gap-10 justify-between">
          {children}
        </div>
      </div>
    </LayoutBase>
  );
}

export default LoginLayout;
