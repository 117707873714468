import { useParams } from "react-router-dom";
import ViewAdminEmail from "../components/admin/ViewAdminEmail";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewEmail = () => {
  mixpanelTrackEvent("View admin email page");

  const params = useParams();
  return <ViewAdminEmail id={params.id} />;
};

export default ViewEmail;
