/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import BreadcrumbsFromConfig from "../components/breadCrumbs/breadCrumbs";
import ChatForm from "../components/lead/chatForm";
import { LeadContextWrapper } from "../components/lead/leadContext";
import { useMessageContext } from "../components/lead/messageContext";
import { useParams } from "react-router-dom";
import { useUserContext } from "../components/user/userContext";
import { ButtonSecondary } from "../components/buttons/ButtonPrimary";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewLeadChat = () => {
  mixpanelTrackEvent("View lead chat page");

  const { id: leadId } = useParams<{ id: string }>();
  const { user } = useUserContext();

  const { activeLead, handleSubmitMessage, messages, loading, useGetLeadById } =
    useMessageContext();

  const [showSystemMessages, setShowSystemMessages] = useState<boolean>(false);

  useEffect(() => {
    if (leadId) {
      useGetLeadById.mutate(leadId as string);
    }
  }, [leadId]);

  const breadCrumbsConfig = [
    {
      name: "Leads",
      href: "/leads",
      active: false,
    },
    {
      name: "Lead",
      href: `/lead/${leadId}`,
      active: false,
    },
    {
      name: `Chat`,
      href: `/lead/chat/${leadId}`,
      active: true,
    },
  ];

  return (
    <div className="p-5">
      <LeadContextWrapper>
        <BreadcrumbsFromConfig config={breadCrumbsConfig} />
        {user?.type === "admin" && (
          <div
            className="flex flex-row gap-2"
            style={{ float: "right", margin: "4px" }}
          >
            <ButtonSecondary
              onClick={() => setShowSystemMessages(!showSystemMessages)}
            >
              {showSystemMessages ? "Hide" : "Show"} system messages
            </ButtonSecondary>
          </div>
        )}
        <div className="rounded-lg bg-gray-200 mt-2">
          <ChatForm
            activeLead={activeLead}
            handleSubmitMessage={handleSubmitMessage}
            messages={messages}
            loading={loading}
            showSystemMessages={showSystemMessages}
          />
        </div>
      </LeadContextWrapper>
    </div>
  );
};

export default ViewLeadChat;
