/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  getExtractionResponses,
  getExtractionResponsesByCampaign,
} from "../../api/extractionResponse.api";
import { ExtractionResponseRow } from "../../api/extractionResponse.type";

export const ExtractionResponseContextWrapper = ({
  campaignId,
  children,
}: {
  children: React.ReactNode;
  campaignId?: string;
}) => {
  const { data: responses } = useQuery({
    queryKey: "extractionResponse",
    queryFn: () => getExtractionResponses(),
  });

  const [campaignResponses, setCampaignResponses] =
    useState<ExtractionResponseRow[]>();

  const { mutate: getCampaignResponses } = useMutation(
    getExtractionResponsesByCampaign,
    {
      onSuccess: (data) => {
        setCampaignResponses(data);
      },
      onError: (error: any) => {},
    }
  );

  useEffect(() => {
    if (campaignId) {
      getCampaignResponses(campaignId);
    }
  }, [campaignId]);

  const value = {
    responses,
    campaignResponses,
  };

  return (
    <ExtractionResponseContext.Provider value={value}>
      {children}
    </ExtractionResponseContext.Provider>
  );
};

export const ExtractionResponseContext = createContext({
  responses: [] as ExtractionResponseRow[] | undefined,
  campaignResponses: [] as ExtractionResponseRow[] | undefined,
});

export const useExtractionResponseContext = () =>
  useContext(ExtractionResponseContext);
