import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { CompanyLocationUpdate } from "../api/companyLocation.type";
import {
  BulkConfirmationRequest,
  ChainRow,
  LocationCount,
  LocationInsert,
  LocationMatchResponse,
  LocationRow,
  LocationRowWithCompanyLocation,
} from "../api/location.type";

export function getLocations(): Promise<LocationRowWithCompanyLocation[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.location;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<LocationRowWithCompanyLocation[]>(url, request);
}

export function getChains(): Promise<ChainRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.chain;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ChainRow[]>(url, request);
}

export function getLocationCounts({
  campaign_id,
}: {
  campaign_id: string;
}): Promise<LocationCount[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.location +
    "/unique-per-campaign/" +
    campaign_id;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<LocationCount[]>(url, request);
}

export function getLocationById(id: string): Promise<LocationRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.location + "/" + id;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<LocationRow>(url, request);
}

export function searchForLocation(query: string): Promise<LocationRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.location + "/search/" + query;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<LocationRow[]>(url, request);
}

export function getLocationByCompany(
  companyId: string
): Promise<LocationRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyLocation +
    "/company/" +
    companyId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<LocationRow[]>(url, request);
}

export function createLocation(values: LocationInsert): Promise<LocationRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.location;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<LocationRow>(url, request);
}

export function updateCompanyLocation({
  companyLocationId,
  values,
}: {
  companyLocationId: string;
  values: CompanyLocationUpdate;
}): Promise<LocationRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyLocation +
    "/" +
    companyLocationId;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<LocationRow>(url, request);
}

export function addBulkLocations(data: BulkConfirmationRequest) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.location + "/bulk";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(data),
  });

  return fetchData<LocationRow[]>(url, request);
}

export function locationMatchByCompany(data: {
  companyId: string;
  message: string;
}): Promise<LocationMatchResponse> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.location + "/company-match";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(data),
  });

  return fetchData<LocationMatchResponse>(url, request);
}
