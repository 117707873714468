/* eslint-disable react-hooks/exhaustive-deps */
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useForm } from "react-hook-form";
import { PlentiInput } from "../forms/plentiInput";
import { useEffect, useState } from "react";
import { useAuthContext } from "../auth/authContext";
import { useAlertContext } from "../alert/alertContext";

export const ResetPassword = () => {
  const { handleLogout } = useAuthContext();
  const { setAlertProps } = useAlertContext();

  const supabase = useSupabaseClient();

  const [errorLink, setErrorLink] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      setErrorLink(hashParams.get("error_description") || "");
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      password: "",
    },
  });

  async function onSubmit() {
    const { error } = await supabase.auth.updateUser({
      password: getValues("password"),
    });
    reset();

    if (error) {
      setAlertProps({
        message:
          "There was an error updating your password. " + error.message || "",
        color: "red",
      });

      return;
    }
    setAlertProps({
      message: "Password updated successfully!",
      color: "green",
    });
    handleLogout();
  }

  if (errorLink) {
    return (
      <div className="flex justify-center items-center h-screen flex-col">
        <p>{errorLink}</p>
        <div>
          <a
            href="/"
            style={{
              color: "#3B82F6",
            }}
          >
            Sign in
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <Typography variant="h5" className="capitalize text-primary-500">
          Update Password
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5 w-72">
            <PlentiInput
              register={register}
              errors={errors}
              label="Password"
              type="password"
              name="password"
            />
            <Button color="blue-gray" type="submit" ripple={true}>
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
