import { SmsMessage } from "../../../api/sms.type";

export const findMessagesBetweenUserAndAssistant = (
  messages: SmsMessage[],
  assistantMessage: SmsMessage
): {
  filteredMessages: SmsMessage[];
  lastUserMessage: SmsMessage | undefined;
} => {
  // Convert timestamps to Date objects for comparison
  const assistantMessageTime = new Date(assistantMessage.created_at);

  // Find the last user message before the specified assistant message
  const lastUserMessage = messages
    .filter(
      (msg) =>
        msg.role === "user" && new Date(msg.created_at) < assistantMessageTime
    )
    .pop();

  if (!lastUserMessage) {
    return { filteredMessages: [], lastUserMessage: undefined };
  }

  // Convert lastUserMessage timestamp to Date object
  const lastUserMessageTime = new Date(lastUserMessage.created_at);

  // Filter messages to find those between the last user message and the specified assistant message
  const filteredMessages = messages.filter((msg) => {
    const msgTime = new Date(msg.created_at);
    return msgTime > lastUserMessageTime && msgTime <= assistantMessageTime;
  });

  return { filteredMessages, lastUserMessage };
};
