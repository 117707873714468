import { Checkbox, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { XMarkIcon } from "../../icons/icons";
import { agents } from "../config/fabrkAgents";
import { useFabrkCampaignContext } from "../hooks/FabrkCampaignContext";
import DataExtractionMenuItem from "./DataExtractionMenuItem";
import FabrkMenuDivider from "./FabrkMenuDivider";
import MenuMediumList from "./MenuMediumList";
import MenuModelList from "./MenuModelList";

function FabrkMenu({
  onSelect,
  isOpen,
  toggleMenu,
  selectedMenu,
}: {
  onSelect: (type: string, name: string) => void;
  isOpen: boolean;
  toggleMenu: () => void;
  selectedMenu: { type: string; name: string };
}) {
  const { activeLeadCampaign } = useFabrkCampaignContext();

  const [selectedAgents, setSelectedAgents] = useState<{
    [key: string]: boolean;
  }>({});

  const handleCheckboxChange = (agentName: string) => {
    setSelectedAgents((prevSelectedAgents) => ({
      ...prevSelectedAgents,
      [agentName]: !prevSelectedAgents[agentName],
    }));
  };

  function handleSelect(type: string, name: string) {
    if (selectedMenu.type === type && selectedMenu.name === name) {
      onSelect("", "");
    } else if (selectedMenu.type === "context" && type === "context") {
      onSelect("", "");
    } else {
      onSelect(type, name);
    }
  }

  useEffect(() => {
    if (activeLeadCampaign && agents) {
      setSelectedAgents(
        agents?.reduce((acc, agent) => {
          if (
            activeLeadCampaign?.agents?.find(
              (a) =>
                a.ai_function.slug === agent.slug ||
                a.ai_function.type === agent.slug
            )
          ) {
            acc[agent.name] = true;
          } else {
            acc[agent.name] = false;
          }
          return acc;
        }, {} as { [key: string]: boolean })
      );
    }
  }, [activeLeadCampaign]);

  return (
    <aside
      id="fabrkMenu"
      className={`w-64 bg-[#17170F] p-6 lg:block ${
        isOpen ? "block" : "hidden"
      }`}
      style={{
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "5px",
        height: "100vh",
      }}
    >
      <button
        className="lg:hidden absolute top-4 right-4 text-white"
        onClick={toggleMenu}
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
      <div
        style={{
          position: "absolute",
          top: "15px",
          bottom: "15px",
          right: "0",
          width: "1px",
          backgroundColor: "rgba(62, 73, 174, 0.5)",
          margin: "5px 0",
        }}
      ></div>
      <nav className="space-y-4">
        <div className="m-auto">
          <div className="p-2 rounded-full m-auto flex flex-row items-center">
            <img
              className="h-12 w-12 text-white rounded-full"
              src="https://ilmvwgbjkutsjcydbiez.supabase.co/storage/v1/object/public/test-images/white_text_black_background_logo.png"
              alt="Fabrk AI Logo"
            />
            <Typography>Fabrk AI</Typography>
          </div>
        </div>
        <div
          className="flex flex-col"
          onClick={() => {
            handleSelect("context", "");
          }}
        >
          <Typography className="font-bold">Context</Typography>
          <FabrkMenuDivider />
          <Typography className="text-sm">
            {activeLeadCampaign?.systemPrompt?.text &&
            activeLeadCampaign.systemPrompt.text.length > 180
              ? `${activeLeadCampaign.systemPrompt.text.slice(0, 180)}...`
              : activeLeadCampaign?.systemPrompt?.text}
          </Typography>
        </div>
        <div className="flex flex-col">
          <Typography className="font-bold">Agents</Typography>
          <FabrkMenuDivider />
          <div className=" items-center ">
            {agents.map((agent) => {
              return (
                <div
                  key={agent.name}
                  className="grid grid-cols-4  items-center cursor-pointer"
                  onClick={() => {
                    handleSelect("agents", agent.name);
                  }}
                >
                  {agent.icon}
                  <Typography className="col-span-2">{agent.name}</Typography>
                  {selectedAgents && selectedAgents[agent?.name] && (
                    <Checkbox
                      className="bg-gray-800 p-1"
                      labelProps={{ style: { padding: "1px" } }}
                      containerProps={{
                        className: "p-1",
                      }}
                      crossOrigin={"true"}
                      color="orange"
                      checked={selectedAgents[agent.name] || false}
                      onChange={() => handleCheckboxChange(agent.name)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div onClick={() => handleSelect("Data Extraction", "")}>
          <Typography className="font-bold">Data Extraction</Typography>
          <FabrkMenuDivider />
          <DataExtractionMenuItem
            dataExtraction={activeLeadCampaign?.dataExtraction}
          />
        </div>
        <MenuMediumList handleSelect={handleSelect} />
        <MenuModelList handleSelect={handleSelect} />
      </nav>
    </aside>
  );
}

export default FabrkMenu;
