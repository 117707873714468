import { useQuery } from "react-query";
import { getTotalByCampaignAndDates } from "../../../../api/reimbursement.api";
import { parseCentsToDollars } from "../../../../utils/currencyManipulation";

export const useLoadTotalByCampaignAndDates = (params: Params) => {
  const { isLoading, data, error } = useQuery({
    queryFn: () => getTotalByCampaignAndDates(params),
    queryKey: ["query-total-by-campaign", params],
    select: (res) => {
      return res ? parseCentsToDollars(res) : 0;
    },
    initialData: 0,
    enabled: !!params.campaignId,
  });

  return {
    isLoading,
    data,
    error,
  };
};

interface Params {
  campaignId: string;
  startDate: string;
  stopDate: string;
}
