import { Button, Textarea, Typography } from "@material-tailwind/react";

import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { useCompanyContext } from "../company/companyContext";
import { useSmsContentContext } from "../smsContent/smsContentContext";

import { Divider } from "@tremor/react";
import { SubmitHandler, useForm } from "react-hook-form";
import SelectCompany from "../company/selectCompany";
import { PlentiInput } from "../forms/plentiInput";
import Toggle from "../forms/toggle";
import LearnMoreButton from "../link/LearnMoreButton";

export type SmsContentFormProps = {
  name: string;
  content: string;
  media_url: string;
  is_ai_generated: boolean;
};

function AddSmsContentContainer({
  campaignId,
  isDefault,
}: {
  campaignId?: string;
  isDefault?: boolean;
}) {
  const { selectedCompany } = useCompanyContext();
  const { addContent } = useSmsContentContext();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      name: "",
      content: "",
      media_url: "",
      is_ai_generated: false,
    },
  });

  const onSubmit: SubmitHandler<SmsContentFormProps> = (
    data: SmsContentFormProps
  ) => {
    addContent({
      ...data,
      company_id: selectedCompany?.id,
      ...(campaignId && { campaign_id: campaignId }),
      default: isDefault ? true : false,
      ...(data.is_ai_generated && { type: "ai_generated" }),
    });
    reset();
  };

  return (
    <div className="p-5">
      <div className="flex justify-between">
        <BreadcrumbsFromConfig
          config={[
            {
              name: "Content List",
              href: "/content",
              active: false,
            },
            {
              name: "Add",
              href: "/content/sms/add",
              active: true,
            },
          ]}
        />
        <LearnMoreButton
          onClick={() => {
            window.open("https://youtu.be/QE7ie0nWj6I", "_blank");
          }}
          text=" Tutorial"
          variant="text"
        />
      </div>
      <div className="mt-4 flex  flex-col max-w-md gap-4 rounded-lg ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-md">
            <div className="flex justify-between">
              <Typography variant="h6">Add SMS Content</Typography>

              <div>
                <p className="text-xs text-gray-500">AI Generated Reply</p>

                <Toggle
                  control={control}
                  name="is_ai_generated"
                  trueLabel="Yes"
                  falseLabel="No"
                />
              </div>
            </div>

            <Divider className="my-1" />
          </div>
          <PlentiInput
            register={register}
            errors={errors}
            label="Name"
            name="name"
          />
          {!watch("is_ai_generated") && (
            <PlentiInput
              register={register}
              errors={errors}
              label="Media URL"
              name="media_url"
              required={false}
            />
          )}

          <Textarea
            {...register("content")}
            label={
              !watch("is_ai_generated") ? "Content" : "Context passed to AI"
            }
            className="focus:border-t-0 focus:ring-0"
          />
          <SelectCompany />
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            disabled={!isDirty}
            type="submit"
            className="mt-4"
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}

export default AddSmsContentContainer;
