/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";

const GalleryImage = ({
  image,
  handleClick,
}: {
  image: string;
  handleClick: (url: string) => void;
}) => {
  const [imageOrientation, setImageOrientation] = useState("cols-span-2");
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.target as HTMLImageElement;

    const orientation =
      naturalWidth > naturalHeight ? "cols-span-2" : "row-span-2";
    setImageOrientation(orientation);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`max-h-md max-w-md gallery-item ${imageOrientation}`}
      onClick={() => handleClick(image)}
      ref={imgRef}
    >
      {isVisible && (
        <img
          ref={imgRef}
          className="rounded-lg object-center "
          src={image}
          alt="gallery-photo"
          onLoad={(event) => handleImageLoad(event)}
        />
      )}
    </div>
  );
};

export default GalleryImage;
