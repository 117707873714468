/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import DateDetailsDetails from "../forms/createAtDetails";
import ValueWithLabel from "../forms/valueWithLabel";
import { useAgentContext } from "./AgentContext";

function ExtractionAgentDetailsContainer() {
  const { agent } = useAgentContext();

  const { id, created_at, name } = agent;

  return (
    <div className="max-w-2xl">
      <div className="flex justify-between">
        <Typography variant="h6">
          {snakeCaseToTitleCase(name)} details
        </Typography>
      </div>

      <Divider className="my-1" />
      <div className="my-4 grid w-full gap-10">
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-row justify-between gap-2">
            <ValueWithLabel label="Name" value={name} />
          </div>
          <div className="flex flex-row justify-between">
            <DateDetailsDetails date={created_at} title="Created" />

            <ValueWithLabel label="Id" value={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtractionAgentDetailsContainer;
