/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useCompanyContext } from "../company/companyContext";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";

import { EmailDnsRow } from "../../api/emailDns.type";
import GenericDialog from "../dialog/genericDialog";
import LearnMoreButton from "../link/LearnMoreButton";
import {
  handleFilterValue,
  setHeadFilters,
} from "../table/setTableHeadWithFilters";
import AddEmailDns from "./AddEmailDns";
import EmailDnsTableList from "./EmailDnsTableList";
import {
  EmailDnsContextWrapper,
  useEmailDnsContext,
} from "./hooks/EmailDnsContext";
import { useLoadEmailDns } from "./hooks/useLoadEmailDns";

const TABLE_HEAD = [
  { label: "Created time" },
  { label: "Company", columnName: "company.name" },
  { label: "Status" },
  { label: "Type" },
  { label: "Host" },
  { label: "Value" },
  { label: "Validation" },
  { label: "AI auto reply" },
  { label: "Actions" },
];

function EmailDnsTable() {
  const { emailDns } = useLoadEmailDns();
  const { companies } = useCompanyContext();
  const { loading } = useEmailDnsContext();

  const [isAdd, setIsAdd] = useState(false);

  const [open, setOpen] = useState(false);

  const [filteredContents, setFilteredContents] = useState<EmailDnsRow[]>(
    emailDns ? emailDns : []
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  function baseFilter() {
    return (prompt: EmailDnsRow, index: number) => true;
  }

  const [contentFilterFunction, setContentFilterFunction] =
    useState<(prompt: EmailDnsRow, index: number) => boolean>(baseFilter);

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);

  useEffect(() => {
    setHeadFilters(
      emailDns,
      filteredContents,
      setFilteredContents,
      setTableHead,
      TABLE_HEAD
    );
  }, [emailDns, companies]);

  useEffect(() => {
    if (isAdd) {
      setOpen(true);
    }
  }, [isAdd]);

  useEffect(() => {
    if (!loading) {
      if (emailDns?.length === 0) {
        setIsAdd(true);
      }
    }
  }, [emailDns, loading]);

  return (
    <div className="flex flex-col gap-4 m-2 p-5">
      <GenericDialog title="Add Email DNS" open={open} setOpen={setOpen}>
        <EmailDnsContextWrapper>
          <AddEmailDns setOpen={setOpen} />
        </EmailDnsContextWrapper>
      </GenericDialog>
      <TableWithSearch
        tableHead={tableHead}
        type="Email DNS"
        currentPage={currentPage}
        handleAddClick={() => setIsAdd(!isAdd)}
        additionalButtons={
          <LearnMoreButton
            variant="outlined"
            onClick={() =>
              window.open("https://youtu.be/SMy12RrLc9o", "_blank")
            }
          />
        }
        handleFilterValue={(value) =>
          handleFilterValue(
            tableHead,
            value,
            emailDns,
            setFilteredContents,
            setContentFilterFunction,
            baseFilter
          )
        }
        handleNextPage={() => {
          handlePageNav({
            currentPage,
            pageSetFunction: setCurrentPage,
            rowsPerPage,
            setFilterFunction: setContentFilterFunction,
            advancePage: true,
          });
        }}
        handlePreviousPage={() =>
          handlePageNav({
            currentPage,
            pageSetFunction: setCurrentPage,
            rowsPerPage,
            setFilterFunction: setContentFilterFunction,
            advancePage: false,
          })
        }
        rowsPerPage={10}
      >
        {emailDns && emailDns.length > 0 && (
          <EmailDnsTableList
            dnsRows={emailDns}
            filterFunction={contentFilterFunction}
          />
        )}
      </TableWithSearch>
    </div>
  );
}

export default EmailDnsTable;
