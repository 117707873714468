import { Button, Checkbox, Option, Select } from "@material-tailwind/react";
import { DatePicker } from "@tremor/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CampaignRow } from "../../api/campaign.type";
import { SegmentsRow } from "../../api/segments.type";
import MultiCampaignSelect from "../campaign/MultiCampaignSelect";
import { useCampaignContext } from "../campaign/campaignContext";
import { useCompanyContext } from "../company/companyContext";
import { PlentiInput } from "../forms/plentiInput";
import MultiSegmentSelect from "../segments/MultiSegmentSelect";
import { useSegmentContext } from "../segments/hooks/SegmentContext";
import { useSmsContentContext } from "../smsContent/smsContentContext";
import { useFlowContext } from "./flowContext";
import { leadAndCampaignSegments } from "../segments/filterSegments";

type Inputs = {
  name: "";
};

function AddFlowForm() {
  const { useCreateNewFlow } = useFlowContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const { campaigns } = useCampaignContext();
  const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignRow[]>([]);
  const [selectedSegments, setSelectedSegments] = useState<SegmentsRow[]>([]);
  const { segments } = useSegmentContext();

  const [filteredSegments, setFilteredSegments] = useState<SegmentsRow[]>([]);

  const { smsContents } = useSmsContentContext();
  const { selectedCompany } = useCompanyContext();
  const [selectedSmsContentId, setSelectedSmsContentId] = useState<string>("");
  const [include_follow_up, setIncludeFollowUp] = useState<boolean>(true);
  const [date, setDate] = useState<Date | null>(null);

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const values = {
      name: data.name,
      start_time: date ? date.toISOString() : "",
      include_follow_up: include_follow_up,
      sms_content_id: selectedSmsContentId,
      campaigns: selectedCampaigns.map((campaign) => campaign.id),
      segments: selectedSegments.map((segment) => segment.id),
      company_id: selectedCompany?.id as string,
    };

    useCreateNewFlow.mutate(values);
    reset();
    if (campaigns) {
      setSelectedCampaigns(campaigns);
    }
    setSelectedSmsContentId("");
  };

  useEffect(() => {
    if (segments) {
      const filteredSegments = leadAndCampaignSegments(segments);
      setFilteredSegments(filteredSegments);
    }
  }, [segments]);
  console.log(filteredSegments);

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-5">
          <div className="flex flex-col gap-2">
            <PlentiInput
              register={register}
              errors={errors}
              label="Name"
              name="name"
            />
            <Select
              label="Message Content"
              value={selectedSmsContentId ? selectedSmsContentId : "Select One"}
              onChange={(value) => {
                setSelectedSmsContentId(value as string);
              }}
            >
              {smsContents &&
                smsContents?.map((content, index) => (
                  <Option key={index} value={content.id} className="capitalize">
                    {content.name} - {`${content.content.substring(0, 50)}...`}
                  </Option>
                ))}
            </Select>
            <DatePicker
              onValueChange={(value) => setDate(value ? value : null)}
            />
            {/* @ts-ignore */}
            <Checkbox
              label="Include AI follow up"
              color="teal"
              checked={include_follow_up}
              onChange={() => setIncludeFollowUp(!include_follow_up)}
            />
          </div>

          <MultiCampaignSelect
            campaigns={campaigns}
            selectedCampaigns={selectedCampaigns}
            setSelectedCampaigns={(selected) => setSelectedCampaigns(selected)}
          />

          <MultiSegmentSelect
            segments={filteredSegments}
            selectedSegments={selectedSegments}
            setSelectedSegments={(selected) => setSelectedSegments(selected)}
          />
        </div>

        <div className="mt-4 col-span-2">
          <Button
            disabled={!date || !selectedSmsContentId}
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddFlowForm;
