import { CampaignRow } from "../../api/campaign.type";
import { CompanyConfigRow, CompanyRow } from "../../api/company.type";

export const defaultGray = "#757575";

function TrySubtitle({
  company,
  companyConfig,
  campaign,
}: {
  company?: CompanyRow;
  companyConfig?: CompanyConfigRow;
  campaign?: CampaignRow;
}) {
  const font = campaign?.subtitle_font?.tailwind_name || "sans";
  const fontWeight = campaign?.subtitle_font_weight || 400;

  return (
    <div>
      {campaign && campaign?.status !== "active" ? (
        <p
          className="mx-auto max-w-[450px] text-center text-lg"
          style={{
            color: companyConfig?.subtitle_color || defaultGray,
            fontWeight,
          }}
        >
          Check back soon for more offers!
        </p>
      ) : campaign?.tag_line ? (
        <p
          className={`mx-auto max-w-[450px] text-center text-lg  font-${font}`}
          style={{
            color: companyConfig?.subtitle_color || defaultGray,
            fontWeight,
          }}
        >
          {campaign.tag_line}
        </p>
      ) : (
        <p
          className="mx-auto max-w-[450px] text-center text-lg"
          style={{
            color: companyConfig?.subtitle_color || defaultGray,
            fontWeight,
          }}
        >
          {`Enter your phone number, purchase ${company?.name} ${
            companyConfig?.product_name || "product"
          }, and we’ll Venmo you the cost of one pack.`}
        </p>
      )}
    </div>
  );
}

export default TrySubtitle;
