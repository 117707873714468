import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

function AiAddSmsContentContainer({
  handleSubmit,
  handleNo,
}: {
  handleSubmit: () => void;
  handleNo: () => void;
}) {
  return (
    <div>
      <div className="mt-4 flex flex-col max-w-md gap-4 rounded-lg p-5">
        <div className="max-w-md">
          <div className="flex justify-between">
            <Typography variant="h6">
              You need more follow up messages to reach your selected maximum.
              Do you want Plenti to generate them for you?
            </Typography>
          </div>

          <Divider />
        </div>

        <div className="flex justify-between">
          <Typography variant="paragraph">
            You can always edit them later. Depending on the number of messages
            it can take up to a minute to generate.
          </Typography>
        </div>

        <div className="flex flex-row gap-2">
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            onClick={handleSubmit}
            className="mt-4"
          >
            Yes, generate messages
          </Button>
          <Button
            size="sm"
            color="red"
            fullWidth={false}
            onClick={handleNo}
            className="mt-4"
          >
            No, i'll write them myself
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AiAddSmsContentContainer;
