/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Checkbox } from "@material-tailwind/react";
import { Button, TextInput } from "@tremor/react";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";

import { useTryContext } from "../try/tryContext";
import { defaultGray } from "./trySubtitle";

function TryPhoneInput() {
  const { companyConfig } = useTryContext();
  const [value, setValue] = useState<string | undefined>();
  const [consented, setConsented] = useState(true);

  return (
    <div className="mx-auto mt-10 flex gap-5">
      <div className="flex flex-col items-end">
        <PhoneInput
          placeholder="Phone number"
          value={value}
          onChange={(value) => setValue(value || "")}
          defaultCountry="US"
          inputComponent={TextInput}
        />
        {/* @ts-ignore */}
        <Checkbox
          defaultChecked
          color="gray"
          label={
            <p
              className="text-xs"
              style={{
                fontSize: "9px",
                lineHeight: "12px",
                color: companyConfig?.disclaimer_color || defaultGray,
              }}
            >
              I consent to receive text messages
            </p>
          }
          value={consented ? "checked" : ""}
          onClick={() => setConsented(!consented)}
        />
      </div>
      <div>
        <Button
          /* @ts-ignore */
          color="black"
        >
          Send
        </Button>
      </div>
    </div>
  );
}

export default TryPhoneInput;
