/* eslint-disable react-hooks/exhaustive-deps */

import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useSmsContentContext } from "./smsContentContext";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { calculateTwilioSmsCost, containsEmoji } from "./smsCostCalc";

function SmsContentCost() {
  const { activeSmsContent } = useSmsContentContext();

  if (!activeSmsContent) {
    return (
      <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
        <div className="w-full">
          <div className="flex justify-between">
            <Typography variant="h6">Message Cost</Typography>
          </div>

          <Divider className="my-1" />
          <div className="my-4 grid w-full gap-10">
            <div className="flex w-full flex-col gap-6">
              <div>Not enough data</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const cost = calculateTwilioSmsCost({
    text: activeSmsContent?.content,
    includesImage: activeSmsContent?.media_url ? true : false,
  });

  return (
    <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
      <div className="w-full">
        <div className="flex justify-between">
          <Typography variant="h6">Estimated Message Cost</Typography>
        </div>

        <Divider className="my-1 mb-5" />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <PlentiFormLabel>Include Media?</PlentiFormLabel>
            <Typography>{activeSmsContent.media_url ? "Yes" : "No"}</Typography>
          </div>
          <div>
            <PlentiFormLabel>Include Emoji?</PlentiFormLabel>
            <Typography>
              {containsEmoji(activeSmsContent.content) ? "Yes" : "No"}
            </Typography>
          </div>
          <div>
            <PlentiFormLabel>Estimated Segments</PlentiFormLabel>
            <Typography>{cost.segments}</Typography>
          </div>
          <div>
            <PlentiFormLabel>Estimated Cost</PlentiFormLabel>
            <Typography>${cost.totalCost}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmsContentCost;
