/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useCompanyContext } from "../company/companyContext";
import { getUniqueValuesForTableHeadFilter } from "../company/companyHelpers";
import ProductTableList from "./ProductList";
import TableWithSearch, {
  FilterValue,
  TableHeadItem,
} from "../table/tableWithSearch";

import { handlePageNav } from "../table/handlePagination";
import { ProductRow } from "../../api/product.type";
import { useProductContext } from "./ProductContextWrapper";
import { useUserContext } from "../user/userContext";

const TABLE_HEAD = [
  { label: "ID/Create At" },
  { label: "Company" },
  { label: "Name" },
  { label: "Price" },
  { label: "Edit" },
  { label: "Delete" },
];

function ProductTable() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { products } = useProductContext();
  const { companies } = useCompanyContext();
  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  const [filteredProducts, setFilteredProducts] = useState<ProductRow[]>(
    products ? products : []
  );

  function handleAddClick() {
    navigate(`/product/add`);
  }

  function baseFilter() {
    return (product: ProductRow, index: number) => index < 10;
  }

  const [productFilterFunction, setProductFilterFunction] =
    useState<(product: ProductRow, index: number) => boolean>(baseFilter);

  useEffect(() => {
    if (products && products?.length > 0 && user?.type === "admin") {
      setFilteredProducts(products);

      const companyIds = getUniqueValuesForTableHeadFilter(products, companies);
      const newTableHead = TABLE_HEAD.map((item) => {
        if (item.label === "Company") {
          return {
            ...item,
            filterValues: companyIds,
          };
        }
        return item;
      });
      setTableHead(newTableHead);
    }
  }, [products, companies]);

  function handleFilterValue(value: FilterValue) {
    if (value.value && products) {
      if (value.value === "All") {
        setProductFilterFunction(baseFilter);
        setFilteredProducts(products);
      } else {
        if (!companies || companies.length === 0) {
          return;
        }
        const foundCompany = companies.find(
          (company) => company.name === value.value
        );

        setProductFilterFunction(
          () => (product: ProductRow, index: number) =>
            product.company_id === foundCompany?.id
        );
        setFilteredProducts(
          products.filter((item) => item.company_id === foundCompany?.id)
        );
      }
    }
  }

  return (
    <div className="flex flex-col gap-4 m-2">
      {!products ||
        (products.length === 0 && <div>No products added yet.</div>)}
      {products && products.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          type="product"
          rowCount={
            filteredProducts && filteredProducts.length > 0
              ? filteredProducts?.length
              : products?.length
              ? products?.length
              : 0
          }
          handleAddClick={handleAddClick}
          handleFilterValue={
            user?.type === "admin" ? handleFilterValue : undefined
          }
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setProductFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setProductFilterFunction,
              advancePage: false,
            })
          }
        >
          <ProductTableList
            products={products}
            filterFunction={
              user?.type === "admin" ? productFilterFunction : undefined
            }
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ProductTable;
