import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { CampaignRow } from "../../api/campaign.type";
import { useLoadCampaigns } from "../campaign/hooks/useLoadCampaigns";
import GenericSelect from "../forms/GenericSelect";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { useImageMetadataContext } from "./hooks/ImageMetadataContext";
import { useLoadImageMetadata } from "./hooks/useLoadImageMetadata";

function ImageFilter() {
  const { imageMetadata } = useLoadImageMetadata();
  const { campaigns } = useLoadCampaigns();
  const {
    selectedCampaign,
    selectedType,
    setSelectedCampaign,
    setSelectedType,
    filteredImages,
  } = useImageMetadataContext();

  const [typeFilterValues, setTypeFilterValues] = useState<string[]>();
  const [campaignsFilterValues, setCampaignFilterValues] = useState<string[]>();

  function setCampaignId(campaignName: string) {
    if (!campaignName) {
      setSelectedCampaign("");
      return;
    }
    if (campaigns) {
      const campaign = campaigns.find((c) => c.name === campaignName);
      if (campaign) {
        setSelectedCampaign(campaign.id);
      }
    }
  }

  useEffect(() => {
    if (imageMetadata && imageMetadata.length > 0) {
      const types = imageMetadata
        .filter((image) => image.type)
        .map((image) => image.type);
      const uniqueTypes = new Set(types);
      if (uniqueTypes.size > 0) {
        setTypeFilterValues(Array.from(uniqueTypes));
      }

      if (campaigns && campaigns.length > 0) {
        const campaignNames = imageMetadata
          .filter((image) => image.campaign_id)
          .map(
            (image) =>
              campaigns.find((c: CampaignRow) => c.id === image.campaign_id)
                ?.name || ""
          );
        const uniqueCampaigns = new Set(campaignNames);
        if (uniqueCampaigns.size > 0) {
          // @ts-ignore
          setCampaignFilterValues(Array.from(uniqueCampaigns));
        }
      }
    }
  }, [imageMetadata, campaigns]);

  return (
    <div className="grid grid-cols-3 gap-4 ">
      <div className="grid grid-cols-2 max-w-xs">
        <Typography color="gray">Total images:</Typography>
        <Typography color="gray">{imageMetadata?.length}</Typography>
        {(selectedCampaign || selectedType) && (
          <>
            <Typography color="gray">Filtered images:</Typography>
            <Typography color="gray">
              {filteredImages?.data?.length || 0}
            </Typography>
          </>
        )}
      </div>

      <div className="max-w-sm">
        <PlentiFormLabel>Type</PlentiFormLabel>
        {typeFilterValues && typeFilterValues.length > 0 && (
          <GenericSelect
            values={typeFilterValues}
            setSelected={setSelectedType}
            selected={selectedType}
          />
        )}
      </div>
      <div className="max-w-sm">
        <PlentiFormLabel>Campaigns</PlentiFormLabel>

        {campaignsFilterValues && campaignsFilterValues.length > 0 && (
          <GenericSelect
            values={campaignsFilterValues}
            setSelected={setCampaignId}
            selected={campaigns?.find((i) => i.id === selectedCampaign)?.name}
          />
        )}
      </div>
    </div>
  );
}

export default ImageFilter;
