import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react';
import { Fragment } from 'react';

export type ConfirmDialogProps = {
  isOpen: boolean;
  handleOpen: () => void;
  title: string;
  handleConfirm: () => void;
  body?: string;
};

export default function ConfirmDialog({
  isOpen,
  handleOpen,
  title,
  handleConfirm,
  body,
}: ConfirmDialogProps) {
  return (
    <Fragment>
      <Dialog open={isOpen} handler={handleOpen}>
        <DialogHeader>{title}</DialogHeader>
        {body && <DialogBody divider>{body}</DialogBody>}
        <DialogFooter>
          <Button
            variant='text'
            color='red'
            onClick={handleOpen}
            className='mr-1'
          >
            <span>Cancel</span>
          </Button>
          <Button variant='gradient' color='green' onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
}
