import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { FeedbackInsert, FeedbackRow } from "../api/feedback.type";

export function getFeedback(): Promise<FeedbackRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.feedback;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<FeedbackRow[]>(url, request);
}

export function createFeedback(feedback: FeedbackInsert): Promise<FeedbackRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.feedback;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(feedback),
  };

  return fetchData<FeedbackRow>(url, request);
}

export function updateFeedback({
  id,
  feedback,
}: {
  id: string;
  feedback: FeedbackInsert;
}): Promise<FeedbackRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.feedback + "/" + id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(feedback),
  };

  return fetchData<FeedbackRow>(url, request);
}
