import { Button } from "@material-tailwind/react";
import { RightArrow } from "../icons/icons";

function LearnMoreButton({
  onClick,
  text = "Learn More",
  ...props
}: {
  onClick?: () => void;
  text?: string;
  [key: string]: any;
}) {
  return (
    <Button
      size="sm"
      className="flex items-center gap-2"
      onClick={onClick}
      {...props}
    >
      {text}
      <RightArrow className="h-4 w-4" />
    </Button>
  );
}

export default LearnMoreButton;
