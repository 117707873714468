import { IconButton, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";

import { DollarIcon } from "../icons/icons";

import { CustomerRow } from "../../api/customer.type";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import GenericDialog from "../dialog/genericDialog";
import TriggerRechargeForm from "./triggerRechargeForm";

export type MappedCustomer = {
  id: string;
  createdAt: string;
  stripeId: string;
  companyName: string;
  currentBalance: string;
  minBalance: string;
  maxBalance: string;
};

function AdminCustomerTableList({
  customers,
}: {
  customers: CustomerRow[] | undefined;
}) {
  const [open, setOpen] = useState(false);
  function mapPromptsToRow(customer: CustomerRow): MappedCustomer {
    const values = {
      id: customer.id,
      companyName: customer?.company?.name ? customer?.company?.name : "N/A",
      createdAt: customer.created_at
        ? formatTimeStampToHumanReadableShortDateTime(customer.created_at)
        : "N/A",
      stripeId: customer.stripe_customer_id
        ? customer.stripe_customer_id
        : "N/A",
      currentBalance: customer.balance
        ? parseCentsToDollars(customer.balance)
        : "N/A",
      minBalance: customer.min_balance
        ? parseCentsToDollars(customer.min_balance)
        : "N/A",
      maxBalance: customer.max_balance
        ? parseCentsToDollars(customer.max_balance)
        : "N/A",
    };

    return values;
  }

  const [customerToRecharge, setCustomerToRecharge] = useState<CustomerRow>();

  function handleOpenDialog(customer: MappedCustomer) {
    const customerToRecharge = customers?.find((c) => c.id === customer.id);
    if (customerToRecharge) {
      setCustomerToRecharge(customerToRecharge);
      setOpen(true);
    }
  }

  return (
    <Fragment>
      {customers &&
        customers
          .map((item) => mapPromptsToRow(item))
          .map((customer, index) => {
            const {
              createdAt,
              companyName,
              stripeId,
              currentBalance,
              minBalance,
              maxBalance,
            } = customer;
            const isLast = index === customers.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr key={index} className="even:bg-blue-gray-50/50">
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {companyName}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {createdAt}
                    </Typography>
                  </div>
                </td>

                <td className={classes}>
                  {" "}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {stripeId}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {currentBalance}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {minBalance}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {maxBalance}
                  </Typography>
                </td>
                <td className={classes}>
                  <IconButton
                    onClick={() => {
                      handleOpenDialog(customer);
                    }}
                    variant="text"
                    color="blue-gray"
                  >
                    <DollarIcon className="h-4 w-4 mx-auto" />
                  </IconButton>
                </td>
              </tr>
            );
          })}
      {customerToRecharge && (
        <GenericDialog title="Trigger Recharge" open={open} setOpen={setOpen}>
          <TriggerRechargeForm
            customer={customerToRecharge}
            setOpen={setOpen}
          />
        </GenericDialog>
      )}
    </Fragment>
  );
}

export default AdminCustomerTableList;
