import { Typography } from "@material-tailwind/react";
import { GlobalIcon, MailIcon, MegaphoneIcon } from "../icons/icons";
import FlowCard from "./flowCard";
import { Divider } from "@tremor/react";

const smsCards = [
  {
    title: "One-off announcements",
    description:
      "This flow allows you to send a message to all of your customers at once.",
    icon: <GlobalIcon className="h-6 w-6 text-white" />,
    type: "all",
  },
  {
    title: "Campaign specific announcements",
    description:
      "This flow allows you to send a message to all of your customers in a given campaign.",
    icon: <MegaphoneIcon className="h-6 w-6 text-white" />,
    type: "campaign",
  },
];

const emailCards = [
  {
    title: "Coming soon",
    description: "Email flows coming soon!",
    icon: <MailIcon className="h-6 w-6 text-white" />,
  },
];

function FlowPrebuiltCards() {
  return (
    <div className="flex flex-col gap-5">
      <Typography variant="h4" className="mb-4 text-slate-500">
        Text message
      </Typography>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-4">
        {smsCards.map(({ title, description, icon, type }, index) => {
          return (
            <div key={index} className="p-2">
              <FlowCard
                title={title}
                description={description}
                icon={icon}
                type={type}
              />
            </div>
          );
        })}
      </div>
      <Divider />
      <Typography variant="h4" className="mb-4 text-slate-500">
        Email
      </Typography>
      <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
        {emailCards.map(({ title, description, icon }, index) => {
          return (
            <div key={index} className="p-2">
              <FlowCard title={title} description={description} icon={icon} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FlowPrebuiltCards;
