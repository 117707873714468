import { useQuery } from "react-query";
import { getAiAgents } from "../../../api/aiAgents.api";
import { useUserContext } from "../../user/userContext";

export function useLoadAiFunction() {
  const { user } = useUserContext();
  const {
    data: agents,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "aiAgents",
    queryFn: () => getAiAgents(),
    enabled: !!user,
  });

  return {
    isLoading,
    agents,
    error,
    refetch,
  };
}
