import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { DailyUsageRow, ReimbursementDailyUsageRow } from "./dailyUsage.type";

export function getDailyUsages(): Promise<DailyUsageRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.dailyUsage;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<DailyUsageRow[]>(url, request);
}

export function getDailyReimbursementUsageByCampaign(
  campaignId: string
): Promise<ReimbursementDailyUsageRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.dailyUsage +
    "/reimbursement/" +
    campaignId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ReimbursementDailyUsageRow[]>(url, request);
}
