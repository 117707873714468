import { Card, CardBody, Typography } from "@material-tailwind/react";
import { MegaphoneIcon } from "../icons/icons";

function CampaignCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <MegaphoneIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Campaigns
        </Typography>
        <Typography>
          Campaigns allow you to create a specific goal and nudge customers
          towards the goal - like driving reimbursements for an in-store BOGO
          offer.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default CampaignCard;
