/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  GetPaymentMethods,
  UseGetPaymentIntent,
} from "../stripe/stripeContext.types";

import { loadStripe } from "@stripe/stripe-js";
import {
  confirmPaymentIntentByIdAndMethod,
  getPaymentIntent,
  getPaymentMethods,
  getSetupIntent,
} from "../../api/stripe.api";
import { StripePaymentMethod } from "../../api/stripe.type";
import { calculateFee } from "../../utils/currencyManipulation";
import { useAlertContext } from "../alert/alertContext";
import { useUserContext } from "../user/userContext";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const env = process.env.REACT_APP_ENV;

// export const stripePromise =
//   env !== "development" && loadStripe(stripePublicKey as string);
export const stripePromise = loadStripe(stripePublicKey as string);

export const StripeContextWrapper = (props: any) => {
  const queryClient = useQueryClient();

  const { user } = useUserContext();
  const { setAlertProps } = useAlertContext();
  const [amount, setAmount] = useState<string>("500");

  const [paymentMethods, setPaymentMethods] = useState<StripePaymentMethod[]>(
    []
  );
  const [setupClientSecret, setSetupClientSecret] = useState<string>("");

  const [paymentIntent, setPaymentIntent] = useState<string>("");

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<StripePaymentMethod>();

  const { mutate: getPaymentAccounts } = useMutation(getPaymentMethods, {
    onSuccess: (res) => {
      setPaymentMethods(res);
    },
    onError: (error: Error) => {},
  });

  const useGetSetupIntent = useMutation(getSetupIntent, {
    onSuccess: (res) => {
      setSetupClientSecret(res.clientSecret);
    },
    onError: (error: Error) => {},
  });

  const useGetPaymentIntent = useMutation(getPaymentIntent, {
    onSuccess: (res) => {
      setPaymentIntent(res.clientSecret);
    },
    onError: (error: any) => {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate, isLoading } = useMutation(confirmPaymentIntentByIdAndMethod, {
    onSuccess: (res) => {
      setAlertProps({
        message: "Payment successful",
        color: "green",
      });
      setSelectedPaymentMethod(undefined);
      queryClient.invalidateQueries("payments");
    },
    onError: (error: Error) => {
      setAlertProps({
        message: error.message,
        color: "red",
      });
      setSelectedPaymentMethod(undefined);
    },
  });

  function confirmPaymentIntent() {
    mutate({
      payment_method: selectedPaymentMethod?.id as string,
      payment_intent: paymentIntent,
    });
  }

  const setupOptions = {
    mode: "setup",
    currency: "usd",
  };

  const amountNumber = Number(amount);
  const amountWithFee = calculateFee(amountNumber, selectedPaymentMethod?.type);

  useEffect(() => {
    if (selectedPaymentMethod && amountNumber > 0) {
      useGetPaymentIntent.mutate({
        payment_method: selectedPaymentMethod.id,
        amount: Number(amountWithFee),
      });
    }
  }, [selectedPaymentMethod, amount]);

  useEffect(() => {
    if (user) {
      getPaymentAccounts();
    }
  }, [user]);

  const value = {
    getPaymentAccounts,
    paymentMethods,
    useGetPaymentIntent,
    useGetSetupIntent,
    setupClientSecret,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    setupOptions,
    amount,
    setAmount,
    amountWithFee,
    confirmPaymentIntent,
    paymentClientSecret: paymentIntent,
    isLoading,
  };

  return (
    <StripeContext.Provider value={value}>
      {props.children}
    </StripeContext.Provider>
  );
};

export const StripeContext = createContext({
  getPaymentAccounts: {} as GetPaymentMethods,
  paymentMethods: [] as StripePaymentMethod[] | undefined,
  useGetPaymentIntent: {} as UseGetPaymentIntent,
  useGetSetupIntent: {} as any,
  setupClientSecret: "",
  setSelectedPaymentMethod: (method: StripePaymentMethod) => {},
  selectedPaymentMethod: {} as StripePaymentMethod | undefined,
  setupOptions: {} as any,
  amount: "",
  setAmount: (amount: string) => {},
  amountWithFee: "",
  confirmPaymentIntent: () => {},
  paymentClientSecret: "",
  isLoading: false as boolean,
});

export const useStripeContext = () => useContext(StripeContext);
