import { LeadRow } from "../../api/lead.type";
import { formatFullDateToShortDate } from "../../utils/parseTimeStamp";

export type CsvData = {
  id: string;
  createdAt: string;
  campaignId: string;
};

export const mapLeadToCsvData = (
  leads: LeadRow[] | undefined
): {
  csvData: CsvData[];
  headers: any[];
} => {
  if (!leads) return { csvData: [], headers: [] };
  const csvData = leads.map((lead) => {
    return {
      id: lead.id,
      createdAt: formatFullDateToShortDate(lead.created_at),
      campaignId: lead.campaign_id,
    };
  });

  const headers = [
    { label: "ID", key: "id" },
    { label: "Created At", key: "createdAt" },
    { label: "Campaign ID", key: "campaignId" },
  ];

  return {
    csvData,
    headers,
  };
};
