import { useParams } from "react-router-dom";
import StandardCompanyContainer from "../components/company/StandardCompanyContainer";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const ViewCompany = () => {
  mixpanelTrackEvent("View company page");

  const params = useParams();
  return <StandardCompanyContainer id={params.id} />;
};

export default ViewCompany;
