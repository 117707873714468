import { useQuery } from "react-query";
import { getDailyReimbursementUsageByCampaign } from "../../../../api/dailyUsage.api";
import { mapReimbursementDailyUsageToDaily } from "../utils/mapReimbursementUsageToDaily";

export const useLoadReimbursementByCampaign = (params: Params) => {
  const { isLoading, data, error } = useQuery({
    queryFn: () => getDailyReimbursementUsageByCampaign(params.campaignId),
    select: (res) => {
      return mapReimbursementDailyUsageToDaily(res);
    },
    queryKey: ["daily-by-campaign", params.campaignId],
    enabled: params.campaignId !== "",
  });

  return {
    isLoading,
    data,
    error,
  };
};

interface Params {
  campaignId: string;
}
