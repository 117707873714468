import LocationBulkContainer from "../components/location/LocationBulkContainer";
import { LocationContextWrapper } from "../components/location/locationContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const LocationsBulkUpload = () => {
  mixpanelTrackEvent("Location bulk upload page");

  return (
    <LocationContextWrapper>
      <LocationBulkContainer />
    </LocationContextWrapper>
  );
};

export default LocationsBulkUpload;
