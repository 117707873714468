import { ArrowDownOnSquareStackIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { CampaignAiFunctionRow } from "../../../api/campaignAiFunction.type";
import { agents } from "../config/fabrkAgents";
import { medium } from "../config/fabrkMediums";
import { models } from "../config/fabrkModels";
import { useFabrkCampaignContext } from "../hooks/FabrkCampaignContext";
import FabrkAgentMenuDetails from "./FabrkAgentMenuDetails";
import FabrkMenuDivider from "./FabrkMenuDivider";
import DataExtractionDetails from "./DataExtractionDetails";

function FabrkConfigMenu({
  type,
  selected,
}: {
  type: string;
  selected: string;
}) {
  const { activeLeadCampaign } = useFabrkCampaignContext();

  const getItems = () => {
    switch (type) {
      case "agents":
        return agents.filter((agent) => agent.name === selected);
      case "medium":
        return medium.filter((med) => med.name === selected);
      case "models":
        return models.filter((model) => model.provider === selected);
      case "Data Extraction":
        return [
          {
            name: "Data Extraction",
            icon: <ArrowDownOnSquareStackIcon className="h-6 w-6" />,
            slug: "data-extraction",
          },
        ];
      default:
        return [];
    }
  };

  const items = getItems();

  return (
    <aside
      id="secondMenu"
      className="w-64 bg-gray-900 p-6 block z-10"
      style={{
        position: "absolute",
        paddingTop: "5px",
        paddingBottom: "5px",
        top: "0",
        left: "16rem",
        height: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "15px",
          bottom: "15px",
          right: "0",
          width: "1px",
          backgroundColor: "rgba(62, 73, 174, 0.5)",
          margin: "5px 0",
        }}
      ></div>
      <nav className="space-y-4">
        {items.map((item, index) => {
          let details: CampaignAiFunctionRow[] | undefined;
          let extraction;

          if (type === "agents") {
            details = activeLeadCampaign?.agents?.filter(
              (agent) =>
                agent.ai_function.name === item.slug ||
                agent.ai_function.type === item.slug
            );
          }

          if (type === "Data Extraction") {
            extraction = activeLeadCampaign?.dataExtraction;
          }

          return (
            <div key={index} style={{ marginTop: `100px` }}>
              <div className="w-full flex flex-col gap-5">
                <div className="flex flex-row gap-2 w-full">
                  {item.icon}
                  {/* @ts-ignore */}
                  <Typography>{item.name || item.provider}</Typography>
                </div>
                <FabrkMenuDivider />
                {details && (
                  <FabrkAgentMenuDetails
                    details={details}
                    activeLeadCampaign={activeLeadCampaign}
                  />
                )}
                {extraction && (
                  <DataExtractionDetails dataExtraction={extraction} />
                )}
              </div>
            </div>
          );
        })}
      </nav>
    </aside>
  );
}

export default FabrkConfigMenu;
