import FeedbackContainer from "../components/feedback/feedbackContainer";
import { FeedbackContextWrapper } from "../components/feedback/feedbackContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Feedback = () => {
  mixpanelTrackEvent("Feedback page");

  return (
    <FeedbackContextWrapper>
      <FeedbackContainer />
    </FeedbackContextWrapper>
  );
};

export default Feedback;
