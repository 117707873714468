import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  SmsContentInsert,
  SmsContentRow,
  SmsContentUpdate,
} from "../api/smsContent.type";
import { CampaignSmsContentRow } from "./campaignSmsContent.type";

export function getSmsContent(): Promise<SmsContentRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.smsContent;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<SmsContentRow[]>(url, request);
}

export function getSmsContentById(id: string): Promise<SmsContentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.smsContent + "/" + id;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<SmsContentRow>(url, request);
}

export function getSmsContentByCompanyId(companyId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.smsContent +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };
  return fetchData<SmsContentRow[]>(url, request);
}

export function getSmsContentByCampaignId(campaignId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.smsContent +
    "/campaign/" +
    campaignId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CampaignSmsContentRow>(url, request);
}

export function updateSmsContentById({
  values,
  id,
}: {
  values: SmsContentUpdate;
  id: string;
}): Promise<SmsContentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.smsContent + "/" + id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
    url,
  };

  return fetchData<SmsContentRow>(url, request);
}

export function createSmsContent(
  values: SmsContentInsert
): Promise<SmsContentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.smsContent + "/create";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<SmsContentRow>(url, request);
}
