import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CountAndHead } from "./leadCampaign.type";

export function getActiveSchedules(): Promise<CountAndHead> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.schedule + "/active";

  const request = setHeaderOptions({ method: "GET" });
  return fetchData<CountAndHead>(url, request);
}
