/* eslint-disable react-hooks/exhaustive-deps */
import { UserCircleIcon } from "@heroicons/react/24/solid";
import {
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { formatPhoneNumber } from "react-phone-number-input";
import { formatFullDateToShortDate } from "../../utils/parseTimeStamp";
import { useMessageOverviewContext } from "./messageOverviewContext";
import { useEffect, useRef } from "react";

const colors = [
  "red",
  "blue",
  "purple",
  "orange",
  "yellow",
  "blue-gray",
  "green",
  "cyan",
];

function ConversationList() {
  const {
    recentConvos,
    setActiveConversation,
    activeConversation,
    userMessageReads,
    setPage,
    totalConversations,
  } = useMessageOverviewContext();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = scrollRef.current;
    if (div) {
      const handleScroll = () => {
        // Check if the user has scrolled to the bottom
        const bottom = div.scrollHeight - div.scrollTop === div.clientHeight;

        //@ts-ignore
        if (bottom && totalConversations > recentConvos?.length) {
          setPage((prevState: number) => prevState + 1);
        }
      };

      // Add event listener for scroll
      div.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener
      return () => {
        div.removeEventListener("scroll", handleScroll);
      };
    }
  }, [recentConvos]);

  return (
    <div
      ref={scrollRef}
      style={{
        height: "600px",
        overflow: "scroll",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <List>
        {recentConvos &&
          recentConvos.map((convo, index) => {
            const messages = convo.messages;

            const consistentColor = colors[index % colors.length];

            const found =
              userMessageReads &&
              userMessageReads?.find((read) => read.lead_id === convo.leadId);

            const isRead = found ? found.read : false;

            if (!messages[0]) {
              return null;
            }

            return (
              <ListItem
                className="grid grid-cols-4 items-start"
                onClick={() => {
                  setActiveConversation(messages);
                }}
                selected={
                  activeConversation &&
                  activeConversation[0].id === messages[0].id
                }
              >
                <ListItemPrefix className="hidden lg:block">
                  <UserCircleIcon
                    color={consistentColor}
                    className="h-16 w-16"
                  />
                </ListItemPrefix>
                <div className="col-span-3 lg:col-span-2">
                  <div className="flex flex-row justify-between">
                    <Typography
                      variant={!isRead ? "h6" : "small"}
                      color="blue-gray"
                    >
                      {convo.phone && formatPhoneNumber(convo.phone)}
                    </Typography>
                  </div>
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal"
                  >
                    {messages[0].content.substring(0, 30)}...
                  </Typography>
                </div>
                <Typography className="col-start-4 self-start text-right hidden lg:block">
                  {formatFullDateToShortDate(messages[0].created_at)}
                </Typography>
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}

export default ConversationList;
