/* eslint-disable react/jsx-pascal-case */
import { Checkbox } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { ChainRow } from "../../api/location.type";
import BulkUploadForm from "../forms/BulkUploadForm";
import {
  FileUploadContextWrapper,
  useFileUploadContext,
} from "../forms/fileUploadContext";
import ChainDisplay from "./ChainDisplay";
import CsvDataReview from "./CsvDataReview";
import LocationBulkCard from "./LocationBulkCard";
import { useLocationContext } from "./locationContext";

function _LocationBulkContainer() {
  const [chainConfirmed, setChainConfirmed] = useState<boolean>(false);

  const [selectedChain, setSelectedChain] = useState<string>("");

  const { chains, confirmBulkData } = useLocationContext();
  const { csvUploadResponse } = useFileUploadContext();

  useEffect(() => {
    if (selectedChain) {
      setChainConfirmed(true);
    } else {
      setChainConfirmed(false);
    }
  }, [selectedChain]);

  function handleConfirmation(values: {
    path: string;
    data: any;
    headers: any;
    chain?: ChainRow;
  }) {
    const foundChain = chains?.find((c) => c.name === selectedChain);

    confirmBulkData({
      ...(foundChain && { chainId: foundChain.id }),
      ...values,
    });
  }

  return (
    <div className="p-5">
      <LocationBulkCard />

      <BulkUploadForm tableName="location">
        <CsvDataReview
          handleConfirmation={handleConfirmation}
          additionalCheck={chainConfirmed}
        >
          <div className="flex flex-row gap-5 items-start">
            <Checkbox
              color="green"
              crossOrigin={true}
              checked={chainConfirmed}
              onChange={() => setChainConfirmed((prevState) => !prevState)}
            />
            <ChainDisplay
              data={csvUploadResponse?.json?.headers}
              setSelectedChain={setSelectedChain}
              selectedChain={selectedChain}
              setChainConfirmed={setChainConfirmed}
            />
          </div>
        </CsvDataReview>
      </BulkUploadForm>
    </div>
  );
}

function LocationBulkContainer() {
  return (
    <FileUploadContextWrapper>
      <_LocationBulkContainer />
    </FileUploadContextWrapper>
  );
}

export default LocationBulkContainer;
