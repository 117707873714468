import { Button, Card, Input, Typography } from "@material-tailwind/react";
import { Dispatch, SetStateAction, useState } from "react";
import { ButtonPrimary, ButtonSecondary } from "../buttons/ButtonPrimary";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useSegmentContext } from "./hooks/SegmentContext";
import { TabConfig } from "../tab/plentiTab";
import SegmentResponseTable from "./SegmentResponseTable";

function CreateSegment({
  setActiveTab,
}: {
  setActiveTab: Dispatch<SetStateAction<TabConfig>>;
}) {
  const {
    createSegmentFromQuery,
    segmentResponse,
    loading,
    handleRunSegment,
    activeSegment,
    handleRemoveSegment,
  } = useSegmentContext();
  const [query, setQuery] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        createSegmentFromQuery(query);
      }}
    >
      <div className="flex flex-col gap-5">
        <div className="mx-auto w-[650px] flex flex-row gap-3">
          <Input
            type="text"
            label="Create a new segment"
            variant="outlined"
            className="focus:border-t-3 border-t-1 focus:ring-0"
            crossOrigin={"true"}
            onChange={(e) => setQuery(e.target.value)}
          />
          <ButtonPrimary size="md" type="submit">
            Submit
          </ButtonPrimary>
        </div>

        {loading && <PlentiLogoLoading className="h-24 w-24 mx-auto" />}
        {segmentResponse && !activeSegment && (
          <Card className="mt-6 w-2/3 p-5 mx-auto">
            {segmentResponse?.runResponse?.response ? (
              <Typography variant="h4" className="my-4">
                {segmentResponse?.runResponse?.response}{" "}
              </Typography>
            ) : (
              <Typography variant="h4" className="my-4">
                No response. Please check the query and try again.
              </Typography>
            )}

            {segmentResponse.segment && !activeSegment && (
              <div className="flex flex-row gap-2">
                <ButtonSecondary
                  onClick={() =>
                    setActiveTab({
                      label: "Your segments",
                      value: "your",
                    })
                  }
                >
                  Go to segment list
                </ButtonSecondary>
                {segmentResponse?.segment && (
                  <ButtonSecondary
                    onClick={() => {
                      if (segmentResponse?.segment) {
                        handleRunSegment(segmentResponse?.segment);
                      }
                    }}
                  >
                    Run segment
                  </ButtonSecondary>
                )}
                <Typography>{segmentResponse.segment.name}</Typography>
              </div>
            )}
          </Card>
        )}
        {segmentResponse && activeSegment && (
          <div className="w-full">
            {!loading && (
              <div className="mt-6 mx-auto w-56 gap-2 flex flex-row justify-around">
                <Button
                  variant="filled"
                  color="cyan"
                  onClick={() => {
                    setActiveTab({
                      label: "Your segments",
                      value: "your",
                    });
                  }}
                >
                  Go to list{" "}
                </Button>
                {segmentResponse?.segment && (
                  <Button
                    variant="filled"
                    color="red"
                    onClick={() => {
                      if (segmentResponse?.segment?.id) {
                        handleRemoveSegment(segmentResponse?.segment?.id);
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}
            <SegmentResponseTable
              segmentResponse={segmentResponse}
              loading={loading}
            />
          </div>
        )}
      </div>
    </form>
  );
}

export default CreateSegment;

// 8c9a9a4e-f6a3-4b75-a9f4-7c63e981b81a
// 41209d10-e249-42fd-84f9-1c15929b3d5b
