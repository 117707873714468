import CampaignContainer from "../components/campaign/campaignContainer";
import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import { SmsContentContextWrapper } from "../components/smsContent/smsContentContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Campaign = () => {
  mixpanelTrackEvent("Campaign page");

  return (
    <CampaignContextWrapper>
      <SmsContentContextWrapper>
        <CampaignContainer />
      </SmsContentContextWrapper>
    </CampaignContextWrapper>
  );
};

export default Campaign;
