import {
  ArrowDownOnSquareStackIcon,
  CalculatorIcon,
  DocumentChartBarIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Badge, Typography } from "@material-tailwind/react";

import { SmsMessage } from "../../../api/sms.type";
import { SmsMetadataRow } from "../../../api/smsMetadata.type";
import { useFabrkCampaignContext } from "../hooks/FabrkCampaignContext";
import { findMessagesBetweenUserAndAssistant } from "./findInterveningMessages";

function AssistantMessageChatItem({
  message,
  index,
  messages,
  handleClick,
  clickedBadge,
  setHoveredBadge,
  hoveredBadge,
  activeMessageId,
  setActiveMessageId,
}: {
  message: SmsMessage;
  index: number;
  messages: SmsMessage[];
  handleClick: (
    badgeType: string,
    messageId: string,
    event: React.MouseEvent
  ) => void;
  clickedBadge: string;
  setHoveredBadge: React.Dispatch<React.SetStateAction<string>>;
  hoveredBadge: string;
  activeMessageId: string;
  setActiveMessageId: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { activeLeadCampaign } = useFabrkCampaignContext();
  const response = findMessagesBetweenUserAndAssistant(messages, message);

  const systemMessages =
    response &&
    (response.filteredMessages.filter(
      (msg) => msg.role === "system"
    ) as unknown as SmsMetadataRow[]);
  const functionMessages =
    response &&
    (response.filteredMessages.filter(
      (msg) => msg.role === "function"
    ) as unknown as SmsMetadataRow[]);
  const toolMessages =
    response &&
    (response.filteredMessages.filter(
      (msg) => msg.role === "tool"
    ) as unknown as SmsMetadataRow[]);

  const extractionResponses =
    activeLeadCampaign &&
    activeLeadCampaign?.dataExtractionResponses?.filter(
      (item) =>
        response.lastUserMessage &&
        response.lastUserMessage.id === item.sms_message_id
    );

  const handleMouseEnter = (badgeType: string, messageId: string) => {
    if (!clickedBadge) {
      setHoveredBadge(badgeType);
      setActiveMessageId(messageId);
    }
  };

  const handleMouseLeave = () => {
    if (!clickedBadge) {
      setHoveredBadge("");
    }
  };

  return (
    <div key={index} className="flex justify-start relative">
      {" "}
      <div className="text-white p-2 max-w-lg">
        <Typography
          variant="paragraph"
          style={{
            fontFamily: "dm-mono-regular",
          }}
        >
          {message.content}
        </Typography>
      </div>
      <div className="absolute right-0 top-0 flex flex-col space-y-1">
        {systemMessages.length > 0 && (
          <Badge content={systemMessages.length} color="orange">
            <button
              className={`p-2 badge-button rounded-full ${
                clickedBadge === "system" && message.id === activeMessageId
                  ? "bg-gray-600"
                  : "bg-gray-800 hover:bg-gray-600"
              }`}
              onMouseEnter={() => handleMouseEnter("system", message.id)}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleClick("system", message.id, e)}
            >
              <DocumentChartBarIcon className="h-4 w-4 text-white" />
            </button>
          </Badge>
        )}
        {functionMessages.length > 0 && (
          <Badge content={functionMessages.length} color="orange">
            <button
              className={`p-2 badge-button rounded-full ${
                clickedBadge === "function" && message.id === activeMessageId
                  ? "bg-gray-600"
                  : "bg-gray-800 hover:bg-gray-600"
              }`}
              onMouseEnter={() => handleMouseEnter("function", message.id)}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleClick("function", message.id, e)}
            >
              <UserGroupIcon className="h-4 w-4 text-white" />
            </button>
          </Badge>
        )}
        {toolMessages.length > 0 && (
          <Badge content={toolMessages.length} color="orange">
            <button
              className={`p-2 badge-button rounded-full ${
                clickedBadge === "tool" && message.id === activeMessageId
                  ? "bg-gray-600"
                  : "bg-gray-800 hover:bg-gray-600"
              }`}
              onMouseEnter={() => handleMouseEnter("tool", message.id)}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleClick("tool", message.id, e)}
            >
              <CalculatorIcon className="h-4 w-4 text-white" />
            </button>
          </Badge>
        )}
        {extractionResponses && extractionResponses?.length > 0 && (
          <Badge content={extractionResponses.length} color="orange">
            <button
              className={`p-2 badge-button rounded-full ${
                clickedBadge === "tool" && message.id === activeMessageId
                  ? "bg-gray-600"
                  : "bg-gray-800 hover:bg-gray-600"
              }`}
              onMouseEnter={() => handleMouseEnter("response", message.id)}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleClick("response", message.id, e)}
            >
              <ArrowDownOnSquareStackIcon className="h-4 w-4 text-white" />
            </button>
          </Badge>
        )}
      </div>
      {(hoveredBadge || clickedBadge) && activeMessageId === message.id && (
        <div
          className={`absolute bg-gray-800 text-white p-4 rounded-lg shadow-lg
        ${
          clickedBadge && activeMessageId === message.id
            ? "top-0 left-0 w-full"
            : "left-full ml-2 min-w-[200px]"
        }
        lg:left-full lg:ml-2 lg:min-w-[200px] lg:top-auto md:w-auto z-10 `}
          onMouseEnter={() => handleMouseEnter(hoveredBadge, message.id)}
          onMouseLeave={handleMouseLeave}
        >
          {hoveredBadge === "response" && (
            <div className="min-w-md">
              <Typography className="font-bold">Extracted Data</Typography>
              {extractionResponses?.map((msg, idx) => (
                <div key={idx}>
                  <Typography>{msg.question_name}</Typography>
                  <Typography className="font-bold">
                    {msg.question_answer}
                  </Typography>
                </div>
              ))}
            </div>
          )}
          {hoveredBadge === "system" && (
            <div className="min-w-md">
              <Typography className="font-bold">
                Context used to craft this response:
              </Typography>
              {systemMessages.map((msg, idx) => (
                <div key={idx}>
                  <Typography>{msg.content}</Typography>
                </div>
              ))}
            </div>
          )}
          {hoveredBadge === "function" && (
            <div className="min-w-md">
              <Typography className="font-bold">
                Available agents for this response:
              </Typography>
              {functionMessages.map((msg, idx) => (
                <div key={idx}>
                  {" "}
                  <Typography>{msg.content}</Typography>
                </div>
              ))}
            </div>
          )}
          {hoveredBadge === "tool" && (
            <div>
              <Typography className="font-bold min-w-lg">Tool Calls</Typography>

              {toolMessages.map((msg, idx) => (
                <div key={idx}>
                  <Typography variant="small">Tool Name:</Typography>
                  {msg.function_call_name && (
                    <Typography className="font-bold">
                      {msg.function_call_name}
                    </Typography>
                  )}
                  <Typography variant="small">Parameters:</Typography>
                  <Typography className="font-bold">
                    {msg.function_call_value}
                  </Typography>
                  <Typography variant="small">Response:</Typography>
                  {msg.function_call_response && (
                    <Typography className="font-bold">
                      {msg.function_call_response}
                    </Typography>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AssistantMessageChatItem;
