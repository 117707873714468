export enum ApiEndpoints {
  ocr = "/ocr",
  sms = "/sms",
  reimbursement = "/reimbursement",
  lead = "/lead",
  prompt = "/prompt",
  campaign = "/campaign",
  user = "/user",
  company = "/company",
  smsContent = "/sms-content",
  feedback = "/feedback",
  companyConfig = "/company-config",
  location = "/location",
  product = "/product",
  stripe = "/stripe",
  leadCampaign = "/lead-campaign",
  companyLocation = "/company-location",
  smsMetadata = "/sms-metadata",
  payout = "/payout",
  chain = "/chain",
  campaignSmsContent = "/campaign-sms-content",
  schedule = "/schedule",
  campaignAiFunction = "/campaign-ai-function",
  companyAiFunction = "/company-ai-function",
  storage = "/storage",
  font = "/font",
  flow = "/flow",
  customer = "/customer",
  usage = "/usage",
  smsUsage = "/sms-usage",
  reimbursementUsage = "/reimbursement-usage",
  dailyUsage = "/daily-usage",
  reimbursementEmbedding = "/reimbursement-embedding",
  recurringPattern = "/recurring-pattern",
  openai = "/openai",
  testContext = "/test-context",
  conversation = "/conversation",
  smsContentMetadata = "/sms-content-metadata",
  aiAgent = "/ai-agent",
  campaignTransition = "/campaign-transition",
  segment = "/segment",
  emailTemplate = "/email-template",
  lineItem = "/line-item",
  extractionSchema = "/extraction-schema",
  extractionSchemaCampaign = "/extraction-schema-campaign",
  extractionResponses = "/extraction-responses",
  usersCompanies = "/users-companies",
  preUser = "/pre-user",
  customerBalance = "/customer-balance",
  payment = "/payment",
  campaignJourney = "/campaign-journey",
  userSmsRead = "/user-sms-read",
  mergeTag = "/merge-tag",
  email = "/email",
  imageMetadata = "/image-metadata",
  session = "/session",
}
