/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  UseGetOcrByIdMutation,
  UseRunPurchaseCheck,
} from "../ocr/ocrContext.types";

import { getOcr, getOcrById, runPurchaseCheck } from "../../api/receiptOcr.api";
import { ReceiptOcrRow } from "../../api/receiptOcr.type";

export const OcrContextWrapper = ({
  id,
  children,
}: {
  id?: string | undefined | string[];
  children: React.ReactNode;
}) => {
  const session = useSession();
  const [activeOcr, setActiveOcr] = useState<ReceiptOcrRow>();

  const [purchaseCheck, setPurchaseCheck] = useState<string>("");

  const { data: ocrs } = useQuery({
    queryKey: "ocr",
    queryFn: () => getOcr(),
    enabled: !!session,
  });

  const useGetOcrById = useMutation(getOcrById, {
    onSuccess: async (res) => {
      setActiveOcr(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const useRunPurchaseCheck = useMutation(runPurchaseCheck, {
    onSuccess: async (res) => {
      setPurchaseCheck(
        `Purchase check result: Product: ${res.productName}, Price: ${res.price}`
      );
    },
  });

  useEffect(() => {
    if (id && typeof id === "string") {
      useGetOcrById.mutate(id);
    }
  }, [id]);

  const value = {
    ocrs,
    activeOcr,
    setActiveOcr,
    useGetOcrById,
    useRunPurchaseCheck,
    purchaseCheck,
  };

  return <OcrContext.Provider value={value}>{children}</OcrContext.Provider>;
};

export const OcrContext = createContext({
  ocrs: [] as ReceiptOcrRow[] | undefined,
  activeOcr: {} as ReceiptOcrRow | undefined,
  setActiveOcr: (ocr: ReceiptOcrRow) => {},
  useGetOcrById: {} as UseGetOcrByIdMutation,
  useRunPurchaseCheck: {} as UseRunPurchaseCheck,
  purchaseCheck: "",
});

export const useOcrContext = () => useContext(OcrContext);
