/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useEffect } from "react";

import { useCampaignContentContext } from "../campaignSmsContent/campaignSmsContentContext";
import { useCampaignContext } from "./campaignContext";

import InfoPopover from "../InfoPopover/infoPopover";
import SmsContentList from "../campaign/smsContentList";
import CampaignSmsContentAddButton from "./campaignSmsContentAddButton";
import LearnMoreButton from "../link/LearnMoreButton";

function CampaignSmsContentListContainer() {
  const { activeCampaign } = useCampaignContext();

  const {
    campaignSmsContent,
    getCampaignDefaultSmsContent,
    campaignSpecificContent,
  } = useCampaignContentContext();

  useEffect(() => {
    if (activeCampaign) {
      getCampaignDefaultSmsContent(activeCampaign.id);
    }
  }, [activeCampaign, campaignSmsContent]);

  return (
    <div className="w-full" id="content-list">
      {activeCampaign && (
        <Fragment>
          <div className="flex flex-row gap-2 justify-between">
            <div className=" flex flex-row gap-2">
              <div className="relative">
                <Typography variant="h6">Follow-up Messages</Typography>

                <div className="absolute top-[-12px] right-[-15px]">
                  <InfoPopover>
                    <p className="text-slate-700">
                      These are the messages that will be sent as follow up for
                      this campaign if the user hasn't responded. They will be
                      sent at random.
                    </p>
                  </InfoPopover>
                </div>
              </div>

              <LearnMoreButton
                onClick={() => {
                  window.open("https://youtu.be/QE7ie0nWj6I", "_blank");
                }}
                text="Tutorial"
                variant="text"
              />
            </div>
            <CampaignSmsContentAddButton />
          </div>
          <Divider className="my-1" />

          <SmsContentList campaignSpecificContent={campaignSpecificContent} />
        </Fragment>
      )}
    </div>
  );
}

export default CampaignSmsContentListContainer;
