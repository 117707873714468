import { Card, CardBody, Typography } from "@material-tailwind/react";
import { UserIcon } from "../icons/icons";

function ConversationCard() {
  return (
    <Card className="mt-6 w-1/2">
      <CardBody>
        <UserIcon className="mb-4 h-12 w-12 text-gray-900" />
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Conversations
        </Typography>
        <Typography>
          {" "}
          View your customer's conversations with your brand.
        </Typography>
      </CardBody>
      {/* <CardFooter className="pt-0">
        <LearnMoreButton
          onClick={() => window.open("https://www.google.com", "_blank")}
        />
      </CardFooter> */}
    </Card>
  );
}

export default ConversationCard;
