import { useNavigate } from "react-router-dom";
import AddButton from "../buttons/addButton";
import CampaignCard from "./CampaignsCard";

function CampaignHeader() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-end ">
        <CampaignCard />
        <div>
          <AddButton
            iconClass="h-4 w-4"
            onClick={() => {
              navigate("/campaign/add");
            }}
            color="green"
            size="lg"
            variant="outlined"
          >
            Add new campaign
          </AddButton>
        </div>
      </div>
    </div>
  );
}

export default CampaignHeader;
