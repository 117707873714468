import PromptContainer from "../components/prompt/PromptTable";
import { PromptContextWrapper } from "../components/prompt/promptContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

const Prompt = () => {
  mixpanelTrackEvent("Prompt page");

  return (
    <PromptContextWrapper>
      <PromptContainer />
    </PromptContextWrapper>
  );
};

export default Prompt;
