/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCompanyContext } from "../company/companyContext";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";
import { useSmsContentContext } from "./smsContentContext";
import SmsContentTableList from "./smsContentTableList";

import { SmsContentRow } from "../../api/smsContent.type";
import {
  handleFilterValue,
  setHeadFilters,
} from "../table/setTableHeadWithFilters";

const TABLE_HEAD = [
  { label: "Created time" },
  { label: "Name" },
  { label: "Company", columnName: "company.name" },
  { label: "Campaign" },
  { label: "Content" },
  { label: "Media" },
  { label: "Edit" },
];

function SmsContentTable() {
  const navigate = useNavigate();
  const { smsContents } = useSmsContentContext();
  const { companies } = useCompanyContext();
  const [filteredContents, setFilteredContents] = useState<SmsContentRow[]>(
    smsContents ? smsContents : []
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  function baseFilter() {
    return (prompt: SmsContentRow, index: number) => true;
  }

  const [contentFilterFunction, setContentFilterFunction] =
    useState<(prompt: SmsContentRow, index: number) => boolean>(baseFilter);

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);

  function handleContentClick(contentId: string) {
    navigate(`/content/sms/${contentId}`, {
      state: {
        name: "Sms Content",
        href: "/content",
        active: false,
      },
    });
  }

  function handleAddClick() {
    navigate(`/content/sms/add`, {
      state: {
        name: "Sms Content",
        href: "/content",
        active: false,
      },
    });
  }

  useEffect(() => {
    setHeadFilters(
      smsContents,
      filteredContents,
      setFilteredContents,
      setTableHead,
      TABLE_HEAD
    );
  }, [smsContents, companies]);

  return (
    <div className="flex flex-col gap-4 m-2 p-5">
      {smsContents && (
        <TableWithSearch
          tableHead={tableHead}
          type="Sms Content"
          rowCount={filteredContents ? filteredContents?.length : 0}
          currentPage={currentPage}
          handleAddClick={handleAddClick}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              smsContents,
              setFilteredContents,
              setContentFilterFunction,
              baseFilter
            )
          }
          handleNextPage={() => {
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setContentFilterFunction,
              advancePage: true,
            });
          }}
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setContentFilterFunction,
              advancePage: false,
            })
          }
          rowsPerPage={10}
        >
          <SmsContentTableList
            content={smsContents}
            handleContentClick={handleContentClick}
            filterFunction={contentFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default SmsContentTable;
