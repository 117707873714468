import { BarChart, Card } from "@tremor/react";
import { Fragment } from "react";
import { SmsContentMetadataTotals } from "../../api/smsContentMetadata.type";
import { secondsToTimeFormat } from "../../utils/parseTimeStamp";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import ContentTotalsCustomTooltip, {
  TotalsTooltip,
} from "./ContentTotalsToolTip";
import NavToAddButton from "./NavToAddButton";
import { useLoadSmsContentMetadataTotals } from "./hooks/useLoadSmsContentMetadataTotals";
import { useNavigate } from "react-router-dom";

function mapTotalsToChartData(totals: SmsContentMetadataTotals) {
  return {
    Name: totals.name,
    Count: totals.count,
    Average: totals.average,
    companyName: totals.companyName,
    companyId: totals.companyId,
    content: totals.content,
  };
}

function SmsContentOverview() {
  const navigate = useNavigate();
  const { isLoading, contentTotals } = useLoadSmsContentMetadataTotals();

  function ascending(a: SmsContentMetadataTotals, b: SmsContentMetadataTotals) {
    if (!a || !b || !a.average || !b.average) {
      return 0;
    }
    return a.average > b.average ? 1 : -1;
  }

  return (
    <div>
      {isLoading ? (
        <PlentiLogoLoading className="h-24 w-24 mx-auto" />
      ) : (
        <Fragment>
          {contentTotals && contentTotals.length > 0 ? (
            <Card className="mx-auto p-0">
              <div className="p-6">
                {contentTotals && (
                  <BarChart
                    onValueChange={(data: any) => {
                      console.log(data);
                      if (data?.smsContentId) {
                        navigate(`/content/sms/${data?.smsContentId}`);
                      }
                    }}
                    className="w-full "
                    data={contentTotals
                      ?.sort(ascending)
                      .map(mapTotalsToChartData)}
                    index="Name"
                    categories={["Average"]}
                    colors={["purple", "blue"]}
                    yAxisWidth={100}
                    showAnimation={true}
                    valueFormatter={secondsToTimeFormat}
                    customTooltip={({ payload, active }) => (
                      <ContentTotalsCustomTooltip
                        payload={payload}
                        active={active}
                      >
                        <TotalsTooltip />
                      </ContentTotalsCustomTooltip>
                    )}
                  />
                )}
              </div>
            </Card>
          ) : (
            <NavToAddButton />
          )}
        </Fragment>
      )}
    </div>
  );
}

export default SmsContentOverview;
