/* eslint-disable react-hooks/exhaustive-deps */
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef } from "react";
import { MergeTagRow } from "../../api/mergeTag.type";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";

export default function TextEditor({
  initialValue,
  setHtml,
  mergeTags,
}: {
  initialValue?: string;
  setHtml: (s: string) => void;
  mergeTags?: MergeTagRow[];
}) {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const editorRef = useRef<Editor | undefined>();

  const handleChange = (e: any) => {
    setHtml(e.target.getContent());
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    if (initialValue && editorRef.current) {
      if (editorRef.current) {
        //@ts-ignore
        editorRef.current.setContent(initialValue);
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [editorRef, initialValue]);

  return (
    <div>
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        // @ts-ignore
        onInit={(evt, editor) => {
          // @ts-ignore
          editorRef.current = editor;
        }}
        onChange={handleChange}
        init={{
          height: screenHeight - 200,
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount mergetags",
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat | template | mergetags ",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          mergetags_list: mergeTags?.map((item) => {
            return {
              title: snakeCaseToTitleCase(item.tag),
              value: item.tag,
            };
          }),
        }}
      />
    </div>
  );
}
