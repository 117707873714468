import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { getFonts } from "../../api/font.api";
import { FontRow } from "../../api/font.type";

export const FontContextWrapper = (props: any) => {
  const { data: fonts } = useQuery({
    queryKey: "fonts",
    queryFn: () => getFonts(),
  });

  const value = { fonts };

  return (
    <FontContext.Provider value={value}>{props.children}</FontContext.Provider>
  );
};

export const FontContext = createContext({
  fonts: [] as FontRow[] | undefined,
});

export const useFontContext = () => useContext(FontContext);
