/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";

import ChatTestingConfig from "./chatTestingConfig";
import ChatTestingResponse from "./chatTestingResponse";
import SavedTestContextList from "./chatTestingSavedList";

function ChatTestingContainer() {
  return (
    <div className="w-full ">
      <div className="float-right"></div>
      <div className="my-4 grid grid-cols-4 w-full gap-10">
        <Typography variant="h6">Saved Context</Typography>
        <Typography variant="h6">Config</Typography>
        <Typography variant="h6">Response</Typography>
      </div>
      <Divider className="my-1" />
      <div className="my-4 grid grid-cols-4 w-full gap-10">
        <SavedTestContextList />
        <ChatTestingConfig />
        <ChatTestingResponse />
      </div>
    </div>
  );
}

export default ChatTestingContainer;
