/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { RocketLaunchIcon } from "@heroicons/react/24/solid";
import { PromptUpdate } from "../../api/prompt.type";
import EditButton from "../buttons/editButton";
import { getDirtyValues } from "../forms/getDirtyValues";
import { PlentiTextArea } from "../forms/plentiTextArea";
import ValueWithLabel from "../forms/valueWithLabel";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useUserContext } from "../user/userContext";
import CampaignPromptDetails from "./CampaignPromptDetails";
import CompanyPromptDetails from "./CompanyPromptDetails";
import { usePromptContext } from "./promptContext";

function PromptColumnContainer() {
  const { updatePrompt, activePrompt, runAiUpdate, isLoading } =
    usePromptContext();
  const { user } = useUserContext();

  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty, errors },
  } = useForm({
    defaultValues: activePrompt as unknown as PromptUpdate,
  });

  const onSubmit: SubmitHandler<PromptUpdate> = (data: PromptUpdate) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);

    updatePrompt({
      ...dirtyValues,
      id: activePrompt?.id as string,
    });
    setIsEdit(false);
  };

  useEffect(() => {
    reset(activePrompt);
  }, [activePrompt]);

  if (!activePrompt) {
    return <div>Error: No Prompt</div>;
  }

  const handleRunAiUpdate = () => {
    runAiUpdate(activePrompt.id);
  };

  return (
    <div className="w-full">
      {isLoading ? (
        <PlentiLogoLoading className="h-24 w-24 mx-auto" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <Typography variant="h6">Prompt Details</Typography>
            <div className="flex flex-row gap-2">
              <EditButton
                iconClass="h-4 w-4"
                text="Edit"
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              />

              {user?.type === "admin" && (
                <Button
                  variant="outlined"
                  className="flex items-center gap-2 p-2"
                  onClick={handleRunAiUpdate}
                  ripple={true}
                >
                  <RocketLaunchIcon className="w-4 h-4" />
                  Run AI update
                </Button>
              )}
            </div>
          </div>

          <Divider className="my-1" />

          {activePrompt?.use_free_text && (
            <div className="grid grid-cols-3 gap-5">
              <div className="my-4 col-span-2  w-full gap-10">
                {!isEdit ? (
                  <ValueWithLabel
                    label={"Free Text"}
                    value={activePrompt.text}
                  />
                ) : (
                  <PlentiTextArea
                    key={"text"}
                    register={register}
                    errors={errors}
                    label={"Free Text"}
                    name={"text"}
                    required={false}
                  />
                )}
              </div>
              <div className="p-6 bg-white shadow-md rounded-lg mb-10">
                <Typography variant="lead" color="gray">
                  Campaign Prompt
                </Typography>
                <Typography variant="small" color="gray" className="mb-2">
                  This is how the prompt is presented to the AI
                </Typography>
                <div className="flex flex-col gap-2">
                  <Typography variant="small" color="gray">
                    {activePrompt.text}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {activePrompt &&
            activePrompt.type === "campaign" &&
            !activePrompt.use_free_text && (
              <CampaignPromptDetails
                activePrompt={activePrompt}
                isEdit={isEdit}
                register={register}
                errors={errors}
              />
            )}

          {activePrompt &&
            activePrompt.type === "company" &&
            !activePrompt.use_free_text && (
              <CompanyPromptDetails
                activePrompt={activePrompt}
                isEdit={isEdit}
                register={register}
                errors={errors}
              />
            )}

          {isEdit && (
            <div className="mt-6 flex justify-end gap-2">
              <Button
                size="sm"
                variant="outlined"
                color="gray"
                onClick={() => setIsEdit(false)}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                color="green"
                fullWidth={false}
                disabled={!isEdit || !isDirty}
                type="submit"
              >
                Update
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
}

export default PromptColumnContainer;
