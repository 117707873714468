/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Chip, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  CampaignDraftType,
  CampaignTypeWithLabel,
  CampaignUpdate,
  DeliveryMethods,
  campaignTypes,
  mapLabelToType,
  mapTypeToLabel,
} from "../../api/campaign.type";
import InfoPopover from "../InfoPopover/infoPopover";
import { useAlertContext } from "../alert/alertContext";
import EditButton from "../buttons/editButton";
import { useCampaignContext } from "../campaign/campaignContext";
import { useCampaignContentContext } from "../campaignSmsContent/campaignSmsContentContext";
import GenericSelect from "../forms/GenericSelect";
import PlentiUpdateOrCancelButtons from "../forms/PlentiUpdateOrCancelButtons";
import DateDetailsDetails from "../forms/createAtDetails";
import { getDirtyValues } from "../forms/getDirtyValues";
import { PlentiInput } from "../forms/plentiInput";
import Toggle from "../forms/toggle";
import ValueWithLabel from "../forms/valueWithLabel";
import LearnMoreButton from "../link/LearnMoreButton";
import CampaignDeliveryTypeIcons from "./CampaignDeliveryTypeIcons";
import EditCampaignDeliveryType from "./EditCampaignDeliveryType";

type CampaignDetailsFormProps = {
  name: string;
  goal: string;
  status: boolean;
  reimbursement_criteria: string;
  type: string;
  multiple_company_reimbursements_allowed: boolean;
  shopify_default: boolean;
  default: boolean;
  delivery_method: string;
};

function CampaignDetailsContainer() {
  const { activeCampaign, updateCampaign } = useCampaignContext();
  const { setAlertProps } = useAlertContext();

  const { campaignDefaultSmsContent } = useCampaignContentContext();
  const [isEdit, setIsEdit] = useState(false);

  const [selectedType, setSelectedType] = useState<CampaignTypeWithLabel>();

  const campaign = useMemo(() => {
    return activeCampaign;
  }, [activeCampaign]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: {
      goal: campaign?.goal as string,
      name: campaign?.name as string,
      status: campaign?.status === "active" ? true : false,
      reimbursement_criteria: campaign?.reimbursement_criteria as string,
      type: campaign?.type as string,
      multiple_company_reimbursements_allowed:
        campaign?.multiple_company_reimbursements_allowed as boolean,
      shopify_default: campaign?.shopify_default as boolean,
      default: campaign?.default as boolean,
      delivery_method: campaign?.delivery_method as string,
    },
  });

  const onSubmit: SubmitHandler<CampaignDetailsFormProps> = (
    data: CampaignDetailsFormProps
  ) => {
    const isStatusDirty = control.getFieldState("status");
    const status = data.status ? "active" : "paused";

    if (
      !campaignDefaultSmsContent &&
      status === "active" &&
      isStatusDirty?.isDirty
    ) {
      setAlertProps({
        message: `Please set a message before activating the campaign`,
        color: "yellow",
      });
    } else {
      console.log("data", data);
      console.log("dirtyFields", dirtyFields);
      const dirtyValues = getDirtyValues(dirtyFields, data);

      delete dirtyValues.status;

      // @ts-ignore
      const values: Partial<CampaignUpdate> = {
        id: activeCampaign?.id,
        ...dirtyValues,
        ...(isStatusDirty?.isDirty && { status }),
        ...(selectedType && { type: selectedType.value }),
      };

      updateCampaign(values);
      setIsEdit(false);
      reset();
    }
  };

  useEffect(() => {
    reset({
      goal: activeCampaign?.goal,
      name: activeCampaign?.name,
      status: activeCampaign?.status === "active" ? true : false,
      reimbursement_criteria: activeCampaign?.reimbursement_criteria,
      type: activeCampaign?.type,
      multiple_company_reimbursements_allowed:
        activeCampaign?.multiple_company_reimbursements_allowed,
      shopify_default: activeCampaign?.shopify_default,
      default: activeCampaign?.default,
      delivery_method: activeCampaign?.delivery_method,
    });
  }, [activeCampaign]);

  useEffect(() => {
    const isDirty = control.getFieldState("status");
    if (!campaignDefaultSmsContent && watch("status") && isDirty?.isDirty) {
      setAlertProps({
        message: `Please set a message before activating the campaign`,
        color: "yellow",
      });
    }
  }, [watch("status")]);

  if (!activeCampaign) {
    return <div>Error: No Campaign</div>;
  }

  const {
    id,
    created_at,
    name,
    goal,
    status,
    updated_at,
    reimbursement_criteria,
    type,
    multiple_company_reimbursements_allowed,
    shopify_default,
    delivery_method,
  } = activeCampaign;

  return (
    <div className="w-full" id="details">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <div className="flex flex-row gap-2">
            <Typography variant="h6">Campaign Details</Typography>
            <LearnMoreButton
              variant="text"
              onClick={() =>
                window.open("https://youtu.be/w6-7kuBs4LQ", "_blank")
              }
            />
          </div>
          <EditButton
            iconClass="h-4 w-4"
            text="Edit"
            onClick={() => {
              setIsEdit(!isEdit);
              if (!isEdit) {
                reset({
                  goal: activeCampaign?.goal,
                  name: activeCampaign?.name,
                  status: activeCampaign?.status === "active" ? true : false,
                  reimbursement_criteria:
                    activeCampaign?.reimbursement_criteria,
                  type: activeCampaign?.type,
                  multiple_company_reimbursements_allowed:
                    activeCampaign?.multiple_company_reimbursements_allowed,
                  shopify_default: activeCampaign?.shopify_default,
                  default: activeCampaign?.default,
                  delivery_method: activeCampaign?.delivery_method,
                });
              }
            }}
          />
        </div>

        <Divider className="my-1" />
        <div className="my-4 grid w-full gap-10">
          <div className="flex w-full flex-col gap-6">
            <div className="flex flex-row justify-between gap-2">
              {!isEdit ? (
                <ValueWithLabel label="Name" value={name} />
              ) : (
                <PlentiInput
                  register={register}
                  name="name"
                  label="Name"
                  errors={errors}
                />
              )}
              {isEdit ? (
                <Toggle
                  control={control}
                  name="status"
                  trueLabel="Active"
                  falseLabel="Paused"
                />
              ) : (
                <Fragment>
                  {status === "active" ? (
                    <Chip color="green" value="Active" />
                  ) : status === "draft" ? (
                    <Chip color="brown" value="Draft" />
                  ) : (
                    <Chip color="yellow" value="Paused" />
                  )}
                </Fragment>
              )}
            </div>
            <div className="grid grid-cols-2">
              <DateDetailsDetails date={created_at} title="Created" />
              <DateDetailsDetails date={updated_at} title="Updated" />
            </div>

            {isEdit ? (
              <EditCampaignDeliveryType
                setSelectedDeliveryType={(deliveryType) => {
                  setValue("delivery_method", deliveryType, {
                    shouldDirty: true,
                  });
                }}
                selectedDeliveryType={
                  watch("delivery_method") as DeliveryMethods
                }
              />
            ) : (
              <div className="grid grid-cols-2">
                <CampaignDeliveryTypeIcons deliveryType={delivery_method} />
                <ValueWithLabel label="Id" value={id} />
              </div>
            )}

            <div className="grid grid-cols-3 gap-2">
              {!isEdit ? (
                <ValueWithLabel label="Goal" value={goal} />
              ) : (
                <PlentiInput
                  register={register}
                  name="goal"
                  label="Goal"
                  errors={errors}
                  required={false}
                />
              )}
              {!isEdit ? (
                <ValueWithLabel
                  label="Reimbursement Criteria"
                  value={reimbursement_criteria || "N/A"}
                />
              ) : (
                <PlentiInput
                  register={register}
                  name="reimbursement_criteria"
                  label="Reimbursement Criteria"
                  errors={errors}
                  required={false}
                />
              )}
            </div>
            <div className="grid grid-cols-3 gap-2">
              {!isEdit ? (
                <ValueWithLabel
                  label="Type"
                  value={mapTypeToLabel(type as CampaignDraftType)}
                />
              ) : (
                <GenericSelect
                  values={campaignTypes.map((i) => i.label)}
                  selected={selectedType?.label}
                  setSelected={(value) => {
                    const updatedType = mapLabelToType(value);

                    setSelectedType(updatedType);
                  }}
                />
              )}
              {isEdit ? (
                <div>
                  <p className="text-xs text-gray-500">
                    Allow multiple reimbursements
                  </p>

                  <Toggle
                    control={control}
                    name="multiple_company_reimbursements_allowed"
                    trueLabel="Yes"
                    falseLabel="No"
                  />
                </div>
              ) : (
                <ValueWithLabel
                  label="Allow multiple reimbursements"
                  value={multiple_company_reimbursements_allowed ? "Yes" : "No"}
                />
              )}
            </div>
            <div className="grid grid-cols-3 gap-2">
              {!isEdit ? (
                <div className="relative w-fit">
                  <ValueWithLabel
                    label="Default"
                    value={activeCampaign.default ? "Yes" : "No"}
                  />
                  <div className="absolute top-[-12px] right-[-15px]">
                    <InfoPopover>
                      <p className="text-slate-700">
                        The default campaign is the campaign that will be used
                        if a customer does not opt in to a specific campaign.
                      </p>
                    </InfoPopover>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-xs text-gray-500">Default</p>

                  <Toggle
                    control={control}
                    name="default"
                    trueLabel="Yes"
                    falseLabel="No"
                  />
                </div>
              )}
              {isEdit ? (
                <div>
                  <p className="text-xs text-gray-500">Shopify Default</p>

                  <Toggle
                    control={control}
                    name="shopify_default"
                    trueLabel="Yes"
                    falseLabel="No"
                  />
                </div>
              ) : (
                <div className="relative w-fit">
                  <ValueWithLabel
                    label="Shopify Default"
                    value={shopify_default ? "Yes" : "No"}
                  />
                  <div className="absolute top-[-12px] right-[-15px]">
                    <InfoPopover>
                      <p className="text-slate-700">
                        If you have linked your Shopify store, this campaign
                        will be the default campaign when customers opt in to
                        receive marketing messages.
                      </p>
                    </InfoPopover>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <PlentiUpdateOrCancelButtons isEdit={isEdit} setIsEdit={setIsEdit} />
      </form>
    </div>
  );
}

export default CampaignDetailsContainer;
