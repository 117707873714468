import AddProductForm from "./AddProductForm";
import AddProductHeader from "./AddProductHeader";

function AddProductContainer() {
  return (
    <div className="w-full flex flex-col p-5 gap-10">
      <AddProductHeader />
      <AddProductForm />
    </div>
  );
}

export default AddProductContainer;
