/* eslint-disable react-hooks/exhaustive-deps */
import { BarChart, Card, Subtitle, Title } from "@tremor/react";
import { DailyUsageRow } from "../../api/dailyUsage.type";
import { UsageRow } from "../../api/usage.type";
import { centsToUsDollars } from "../../utils/currencyManipulation";
import { numericMonthYearToString } from "../../utils/parseTimeStamp";
import { useBillingContext } from "./billingContext";
import DailyUsageCustomTooltip, {
  TooltipDailyCurrency,
} from "./dailyUsageToolTip";

function ReimbursementDailyUsageChart({
  selectedUsage,
}: {
  selectedUsage: UsageRow | undefined;
}) {
  const { dailyUsage } = useBillingContext();

  function mapToChartData(usage: DailyUsageRow) {
    return {
      "Reimbursement Total": usage.reimbursement_balance,
      day: usage.day,
    };
  }
  function filterByMonth(usage: DailyUsageRow) {
    return usage.month === selectedUsage?.month;
  }

  return (
    <Card>
      <Title>Reimbursement Daily Usage </Title>
      {selectedUsage && (
        <Subtitle>
          {numericMonthYearToString(selectedUsage?.month, selectedUsage?.year)}
        </Subtitle>
      )}

      <BarChart
        className="mt-6"
        data={dailyUsage?.filter(filterByMonth).map(mapToChartData) || []}
        index="day"
        categories={["Reimbursement Total"]}
        colors={["green"]}
        valueFormatter={centsToUsDollars}
        customTooltip={({ payload, active }) => (
          <DailyUsageCustomTooltip payload={payload} active={active}>
            <TooltipDailyCurrency selectedMonth={selectedUsage} />
          </DailyUsageCustomTooltip>
        )}
      />
    </Card>
  );
}

export default ReimbursementDailyUsageChart;
