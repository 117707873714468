import React, { useState } from "react";
import { SaveIcon } from "../../icons/icons";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { useFabrkMessageContext } from "../hooks/FabrkMessageContext";

function FabrkInput({ view }: { view: string }) {
  const { handleCreateMessage } = useFabrkMessageContext();
  const [inputValue, setInputValue] = useState("");
  const [borderColor, setBorderColor] = useState("rgba(62, 73, 174, 0.2)");

  const handleMouseEnter = () => setBorderColor("rgba(62, 73, 174, 0.5)");
  const handleMouseLeave = () => setBorderColor("rgba(62, 73, 174, 0.2)");
  const handleFocus = () => setBorderColor("rgba(62, 73, 174, 0.5)");
  const handleBlur = () => setBorderColor("rgba(62, 73, 174, 0.2)");

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    handleCreateMessage(inputValue);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={
        view === "message"
          ? "fixed bottom-4 left-1/3 transform-translate-x-1/2 w-full max-w-2xl px-4"
          : "w-full max-w-2xl px-4"
      }
    >
      <div
        className="items-center grid grid-rows-[1fr_auto] grid-cols-3 w-full outline-none focus:outline-none font-sans flex items-center  bg-background  shadow-sm rounded-md px-md pt-md pb-sm"
        style={{ border: `2px solid ${borderColor}` }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="flex flex-row items-center col-start-1 col-end-4 pb-sm overflow-auto max-h-[45vh] bg-gray-900  outline-none w-full font-sans resize-none   text-textMain rounded-t-md"
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <textarea
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Send it..."
            className="outline-none border-none w-full font-sans resize-none bg-gray-900 text-textMain rounded-t-md focus:outline-none focus:border-none"
            autoComplete="off"
            autoFocus={false}
            style={{
              height: "56px",
              border: "none",
              outline: "none",
              boxShadow: "none",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit(e as any);
              }
            }}
          ></textarea>
          <button
            type="submit"
            className="m-1 hover:bg-gray-800 bg-gray-900  font-sans  outline-none outline-transparent transition duration-300 font-sans  items-center relative  justify-center text-center items-center rounded-full cursor-pointer opacity-50 p inline-flex text-sm aspect-square h-8"
          >
            <div className="flex items-center leading-none justify-center gap-xs ">
              <ArrowSmallRightIcon className="h-4 w-4" />
            </div>
          </button>
        </div>

        <div className="bg-background p-1 flex items-center space-x-2 justify-self-end rounded-full col-start-3 row-start-2 ">
          <span className="inline-flex">
            <div className="gap-1 flex flex-row items-center cursor-pointer">
              <SaveIcon className="h-4 w-4" />
            </div>
          </span>
        </div>
      </div>
    </form>
  );
}

export default FabrkInput;
