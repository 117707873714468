/* eslint-disable react/jsx-pascal-case */
import { Option, Select } from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";

import { useCompanyContext } from "../company/companyContext";
import {
  LocationContextWrapper,
  useLocationContext,
} from "../location/locationContext";
import { PlentiInput } from "../forms/plentiInput";
import { useUserContext } from "../user/userContext";

import { CompanyRow } from "../../api/company.type";
import { ChainRow } from "../../api/location.type";

type NewType = {
  city: "";
  state: "";
  zip: "";
  address_line_one: "";
  address_line_two: "";
  name: "";
  exampleRequired: string;
};

type Inputs = NewType;

const formValues = [
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Address",
    name: "address_line_one",
  },
  {
    label: "City",
    name: "city",
  },
  {
    label: "State",
    name: "state",
  },
  {
    label: "Zip",
    name: "zip",
  },
];

function _AddLocationDialogBody({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const { createNewLocation } = useLocationContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const { user } = useUserContext();
  const { chains } = useLocationContext();
  const { companies, selectedCompany: company } = useCompanyContext();

  const [phone, setPhone] = useState("");
  const [selectedChain, setSelectedChain] = useState<ChainRow>();
  const [selectedCompany, setSelectedCompany] = useState<CompanyRow>(
    company as CompanyRow
  );

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const values = {
      ...data,
      phone: phone,
      chain_id: selectedChain?.id,
      company_id: selectedCompany?.id,
      full_address: `${data.address_line_one}, ${data.city} ${data.state} ${data.zip}`,
    };
    createNewLocation.mutate(values);
    setOpen(false);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formValues.map((formValue, index) => (
          <PlentiInput
            key={index}
            register={register}
            errors={errors}
            label={formValue.label}
            name={formValue.name}
          />
        ))}
        <div className="mb-2">
          <PhoneInput
            placeholder="Phone number"
            value={phone}
            onChange={(value) => {
              setPhone(value || "");
            }}
            defaultCountry="US"
            style={{ borderRadius: "3px" }}
            numberInputProps={{
              className: "rounded-md px-4 focus:outline-none",
            }}
          />
        </div>
        {chains && chains.length > 0 && (
          <div className="mb-2">
            <Select
              label="Chain"
              value={selectedChain ? selectedChain.id : undefined}
              onChange={(value) => {
                if (value) {
                  setSelectedChain(chains.find((chain) => chain.id === value));
                }
              }}
            >
              {chains.map((chain, index) => (
                <Option key={index} value={chain.id}>
                  {chain.name}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {user?.type === "admin" && companies && companies.length > 0 && (
          <div className="mb-2">
            <Select
              label="Company"
              value={selectedCompany ? selectedCompany.id : undefined}
              onChange={(value) => {
                if (value) {
                  const found = companies.find(
                    (company) => company.id === value
                  );
                  if (found) {
                    setSelectedCompany(found);
                  }
                }
              }}
            >
              {companies.map((company, index) => (
                <Option key={index} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <div className="mt-4">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
}

const AddLocationDialogBody = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  return (
    <LocationContextWrapper>
      <_AddLocationDialogBody setOpen={setOpen} />
    </LocationContextWrapper>
  );
};

export default AddLocationDialogBody;
