/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useMemo, useState } from "react";

import { Textarea, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  camelCaseToTitleCase,
  titleCaseToCamelCase,
} from "../../utils/stringManipulation";
import EditButton from "../buttons/editButton";
import GenericSelect from "../forms/GenericSelect";
import PlentiUpdateOrCancelButtons from "../forms/PlentiUpdateOrCancelButtons";
import FileUploadComponentWithContext from "../forms/fileUploadComponentWithContext";
import { PlentiInput } from "../forms/plentiInput";
import ValueWithLabel from "../forms/valueWithLabel";
import { parseTextWithMergeTags } from "../mergeTag/exampleMergeTags";
import { useLoadMergeTags } from "../mergeTag/hooks/useLoadMergeTags";
import ViewImage from "../table/viewImage";
import { useSmsContentContext } from "./smsContentContext";

type SmsContentFormProps = {
  name: string;
  content: string;
  media_url: string;
};

function SmsContentDetails() {
  const { activeSmsContent, updateContent } = useSmsContentContext();

  const { mergeTags } = useLoadMergeTags();

  const [selectedMergeTag, setSelectedMergeTag] = useState<string>("");

  const [isEdit, setIsEdit] = useState(false);

  const smsMessage = useMemo(() => {
    return activeSmsContent;
  }, [activeSmsContent]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: smsMessage?.name as string,
      content: smsMessage?.content as string,
      media_url: smsMessage?.media_url as string,
    },
  });

  const onSubmit: SubmitHandler<SmsContentFormProps> = (
    data: SmsContentFormProps
  ) => {
    updateContent({
      id: smsMessage?.id as string,
      values: data,
    });

    setIsEdit(false);
  };

  useEffect(() => {
    reset({
      name: smsMessage?.name as string,
      content: smsMessage?.content as string,
      media_url: smsMessage?.media_url as string,
    });
  }, [smsMessage]);

  useEffect(() => {
    if (selectedMergeTag) {
      setValue(
        "content",
        `${smsMessage?.content} {{${titleCaseToCamelCase(selectedMergeTag)}}}`
      );
    }
  }, [selectedMergeTag]);

  if (!smsMessage) {
    return <div>Error: No smsMessage</div>;
  }

  const { name, content, media_url } = smsMessage;

  return (
    <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
      <div className="w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <Typography variant="h6">Overview</Typography>
            <EditButton
              iconClass="h-4 w-4"
              text="Edit"
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            />
          </div>

          <Divider className="my-1" />
          <div className="my-4 grid w-full gap-10">
            <div className="flex w-full flex-col gap-6">
              <div className="grid grid-cols-2 gap-4">
                <Fragment>
                  {isEdit ? (
                    <div>
                      <PlentiInput
                        register={register}
                        errors={errors}
                        label="Name"
                        name={"name"}
                      />
                      <GenericSelect
                        values={mergeTags?.map((tag) =>
                          camelCaseToTitleCase(tag.tag)
                        )}
                        setSelected={setSelectedMergeTag}
                        selected={selectedMergeTag}
                      />
                    </div>
                  ) : (
                    <ValueWithLabel label="Name" value={name || ""} />
                  )}
                </Fragment>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Fragment>
                  {isEdit ? (
                    <Textarea
                      {...register("content")}
                      label="Content"
                      className="focus:border-t-0 focus:ring-0"
                    />
                  ) : (
                    <div className="flex flex-row items-start gap-5 ">
                      <ValueWithLabel
                        label="Content"
                        value={parseTextWithMergeTags(content) || ""}
                      />
                    </div>
                  )}
                </Fragment>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <Fragment>
                  {isEdit ? (
                    <FileUploadComponentWithContext
                      imgUrl={media_url}
                      handleFilePath={(path) => {
                        updateContent({
                          id: smsMessage?.id as string,
                          values: {
                            media_url: path,
                          },
                        });
                        setIsEdit(false);
                      }}
                    />
                  ) : (
                    <ViewImage title="Media" src={media_url} />
                  )}
                </Fragment>
              </div>
            </div>
          </div>

          {isEdit && (
            <PlentiUpdateOrCancelButtons
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              handleCancel={() => {
                setIsEdit(false);
                reset();
                setSelectedMergeTag("");
              }}
            />
          )}
        </form>
      </div>
    </div>
  );
}

export default SmsContentDetails;
