import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { ErrorHelperText } from "../forms/errorHelperText";
import { PlentiInput } from "../forms/plentiInput";
import GoogleSignIn from "./GoogleSignIn";
import { useAuthContext } from "./authContext";
import SignupFooterLink from "./SignupFooterLink";
import LoginFooterLink from "./LoginFooterLink";

export interface AuthProps {
  viewType?: "Login" | "Sign up";
}

function AuthSignUpSignIn({ viewType = "Login" }: AuthProps) {
  const navigate = useNavigate();
  const [actionLabel, setActionLabel] = useState<string>(viewType);

  const { signIn, loginError, signUp } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(data: any) {
    if (actionLabel === "Login") {
      signIn(data);
      return;
    } else {
      signUp(data);
    }
  }

  useEffect(() => {
    setActionLabel(
      viewType === "Sign up" ? "Create your Plenti Account" : viewType
    );
  }, [viewType]);

  return (
    <div className="bg-white m-10 p-8 h-fit rounded-lg shadow-md w-96  ">
      <Typography variant="h4" className="my-4 tracking-wider">
        {actionLabel}
      </Typography>
      {actionLabel !== "Reset Password" && (
        <div className="mx-auto">
          <GoogleSignIn viewType={viewType} />
        </div>
      )}
      <Divider className="p-2" />
      {loginError && (
        <div className="w-72 mb-5">
          <ErrorHelperText text={loginError} />
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <PlentiInput
            register={register}
            errors={errors}
            label="Email"
            name="email"
          />

          <PlentiInput
            register={register}
            errors={errors}
            label="Password"
            type="password"
            name="password"
          />

          {viewType === "Sign up" && (
            <PlentiInput
              register={register}
              errors={errors}
              label="Confirm Password"
              type="password"
              name="repeatPassword"
            />
          )}

          <ButtonSecondary type="submit">{actionLabel}</ButtonSecondary>

          <Typography variant="small" color="gray">
            Forgot password?{" "}
            <span
              style={{
                color: "#3B82F6",
                cursor: "pointer",
              }}
              onClick={() => navigate("/send-password-reset-email")}
            >
              Reset
            </span>
          </Typography>

          {viewType === "Sign up" ? <LoginFooterLink /> : <SignupFooterLink />}
        </div>
      </form>
    </div>
  );
}

export default AuthSignUpSignIn;
