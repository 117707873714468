import { Typography, Checkbox } from "@material-tailwind/react";
import { models } from "../config/fabrkModels";
import FabrkMenuDivider from "./FabrkMenuDivider";

function MenuModelList({
  handleSelect,
}: {
  handleSelect: (type: string, name: string) => void;
}) {
  return (
    <div>
      <Typography className="font-bold">Model</Typography>
      <FabrkMenuDivider />
      <div className="flex flex-col gap-1">
        {models.map((model, index) => {
          return (
            <div
              key={index}
              className="grid grid-cols-4 items-center cursor-pointer"
              onClick={() => {
                handleSelect("models", model.provider);
              }}
            >
              {model?.icon}
              <Typography className="col-span-2">{model?.provider}</Typography>
              {model.slug === "openai" && (
                <Checkbox
                  crossOrigin={"true"}
                  className="bg-gray-800 p-1"
                  labelProps={{ style: { padding: "1px" } }}
                  containerProps={{
                    className: "p-1",
                  }}
                  color="orange"
                  checked={true}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MenuModelList;
