import { useQuery } from "react-query";
import { getCampaignTransitions } from "../../../api/campaignTransition.api";

export const useLoadCampaignsTransitions = () => {
  const { data: campaignTransitions, isLoading } = useQuery({
    queryKey: "campaignTransitions",
    queryFn: () => getCampaignTransitions(),
  });

  return { campaignTransitions, isLoading };
};
