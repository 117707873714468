/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Input, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useState } from "react";

import { CampaignRow } from "../../api/campaign.type";
import EditButton from "../buttons/editButton";
import { useLoadCampaigns } from "../campaign/hooks/useLoadCampaigns";
import SelectCampaign from "../campaign/selectCampaign";
import { useReimbursementContext } from "./reimbursementContext";
import { useCampaignContext } from "../campaign/campaignContext";

function EditReimbursementCampaign() {
  const { updateReimbursement, activeReimbursement } =
    useReimbursementContext();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignRow>();
  const [reimbursementCriteria, setReimbursementCriteria] =
    useState<string>("");
  const { updateCampaign } = useCampaignContext();

  const { campaigns } = useLoadCampaigns();
  const campaign = campaigns?.find(
    (c) => c.id === activeReimbursement?.campaign_id
  );

  function handleUpdateReimbursement() {
    if (selectedCampaign) {
      updateReimbursement({
        id: activeReimbursement?.id as string,
        campaign_id: selectedCampaign?.id as string,
        company_id: selectedCampaign?.company_id as string,
      });
    }

    if (reimbursementCriteria && campaign?.id) {
      updateCampaign({
        id: campaign?.id as string,
        reimbursement_criteria: reimbursementCriteria,
      });
    }
    setSelectedCampaign(undefined);
    setIsEdit(false);
  }

  return (
    <div className="w-full">
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Campaign</Typography>
          <EditButton
            iconClass="h-4 w-4"
            text="Edit campaign"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          />
        </div>
        <Divider className="my-2" />
        <div className="flex flex-col gap-6 mb-2">
          <div>
            {!isEdit ? (
              <p className="text-slate-700">
                {activeReimbursement?.campaign?.name}
              </p>
            ) : (
              <SelectCampaign
                campaigns={campaigns}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
              />
            )}
          </div>
          <div>
            {!isEdit ? (
              <div>
                <p className="text-xs text-gray-500">Reimbursement Criteria</p>
                <p className="text-slate-700">
                  {campaign?.reimbursement_criteria}
                </p>
              </div>
            ) : (
              <Input
                label="Reimbursement Criteria"
                variant="outlined"
                onChange={(e) => {
                  setReimbursementCriteria(e.target.value);
                }}
                name="reimbursement_criteria"
                className="focus:border-t-0 focus:ring-0"
                crossOrigin={true}
              />
            )}
          </div>
        </div>
        {isEdit && (
          <div className="mt-6 flex justify-end gap-2">
            <Button
              size="sm"
              variant="outlined"
              color="gray"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="green"
              fullWidth={false}
              disabled={!isEdit}
              onClick={handleUpdateReimbursement}
            >
              Update
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditReimbursementCampaign;
