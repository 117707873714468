import { useQuery } from "react-query";
import { getTemplateCampaigns } from "../../../api/campaign.api";

export const useLoadTemplateCampaigns = () => {
  const { data: templateCampaigns, isLoading } = useQuery({
    queryKey: "templateCampaigns",
    queryFn: () => getTemplateCampaigns(),
  });

  return { templateCampaigns, isLoading };
};
