import AdminDemoContainer from "../components/demo/adminDemoContainer";
import { useParams } from "react-router-dom";
import { CampaignContextWrapper } from "../components/campaign/campaignContext";
import DemoContainer from "../components/demo/demoContatiner";
import { DemoContextWrapper } from "../components/demo/demoContext";
import { OcrContextWrapper } from "../components/ocr/ocrContext";
import { useUserContext } from "../components/user/userContext";
import { ReimbursementContextWrapper } from "../components/reimbursement/reimbursementContext";
import { LocationContextWrapper } from "../components/location/locationContext";
import { mixpanelTrackEvent } from "../utils/mixpanel";

function CampaignDemo() {
  mixpanelTrackEvent("Demo page");

  const params = useParams();
  const { user } = useUserContext();

  return (
    <CampaignContextWrapper>
      <LocationContextWrapper>
        <ReimbursementContextWrapper>
          <DemoContextWrapper>
            <OcrContextWrapper>
              {user?.type === "standard" ? (
                <DemoContainer campaignId={params.id as string} />
              ) : (
                <AdminDemoContainer campaignId={params.id as string} />
              )}
            </OcrContextWrapper>
          </DemoContextWrapper>
        </ReimbursementContextWrapper>
      </LocationContextWrapper>
    </CampaignContextWrapper>
  );
}

export default CampaignDemo;
