/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useMemo } from "react";
import { TestContextInsert } from "../../api/testContext.type";
import { useLoadCampaigns } from "../campaign/hooks/useLoadCampaigns";
import SelectCampaign from "../campaign/selectCampaign";
import SelectCompany from "../company/selectCompany";
import { PlentiInput } from "../forms/plentiInput";
import { ChatUserType } from "../../api/leadContext.types";
import SelectLead from "../lead/selectLead";
import { useAdminGptContext } from "./adminGptContext";

type ImageValues = {
  type: string;
  issues: string;
};

type MessageValues = {
  message: string;
  name: string;
};

export type CombinedValues = ImageValues & MessageValues;

const imageValues = [
  {
    label: "Type",
    name: "type",
  },
  {
    label: "Issues",
    name: "issues",
  },
];

function ChatTestingConfig() {
  const {
    useCreateTestContext,
    selectedCampaign,
    setSelectedCampaign,
    leads,
    selectedLead,
    setSelectedLead,
    defaultValues,
    isReceipt,
    setIsReceipt,
    setChatResponse,
  } = useAdminGptContext();

  const { campaigns } = useLoadCampaigns();
  const { useSendTestChat, setLoading } = useAdminGptContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<CombinedValues>({
    defaultValues: {
      type: "",
      issues: "",
      message: "",
      name: "",
    },
  });

  useMemo(() => {
    setValue("type", defaultValues?.type);
    setValue("issues", defaultValues?.issues);
    setValue("message", defaultValues?.message);
    setValue("name", defaultValues?.name);
  }, [defaultValues, defaultValues?.type, defaultValues?.issues]);

  const onSubmit: SubmitHandler<CombinedValues> = (data: CombinedValues) => {
    setChatResponse(undefined);
    if (selectedCampaign && selectedLead) {
      const messages = [
        {
          role: ChatUserType.system,
          content: `Image metadata: type: ${data.type}, is receipt: ${isReceipt}, image issues: ${data.issues}}`,
        },
        {
          role: ChatUserType.user,
          content: `${data.message}`,
        },
      ];

      setLoading(true);

      useSendTestChat.mutate({
        campaignId: selectedCampaign?.id,
        leadId: selectedLead?.id,
        messages,
        companyId: selectedCampaign?.company_id as string,
      });
    }
  };

  function handleSaveContext() {
    const formValues = getValues();
    const values: TestContextInsert = {
      campaign_id: selectedCampaign?.id as string,
      lead_id: selectedLead?.id,
      image_type: formValues.type || null,
      messages: formValues.message ? [formValues.message] : null,
      is_receipt: isReceipt,
      image_issues: formValues.issues ? [formValues.issues] : null,
      name: formValues.name,
    };

    useCreateTestContext.mutate(values);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2">
        <SelectCompany />
        <SelectCampaign
          campaigns={campaigns}
          setSelectedCampaign={setSelectedCampaign}
          selectedCampaign={selectedCampaign}
        />
        <SelectLead
          selectedLead={selectedLead}
          leads={leads}
          setSelectedLead={setSelectedLead}
        />
        <Typography variant="small" className="mt-2">
          Image
        </Typography>
        {/* @ts-ignore */}
        <Checkbox
          checked={isReceipt}
          label="Is receipt"
          onChange={() => setIsReceipt(!isReceipt)}
        />
        {imageValues.map((formValue, index) => (
          <PlentiInput
            key={index}
            register={register}
            errors={errors}
            label={formValue.label}
            name={formValue.name}
            required={false}
          />
        ))}

        <Textarea
          {...register("message")}
          label="Message"
          className="focus:border-t-0 focus:ring-0"
        />
      </div>
      <div className="mt-6 flex flex-col gap-2">
        <Button size="sm" color="green" fullWidth={false} type="submit">
          Submit
        </Button>
        <Divider />
        <PlentiInput
          register={register}
          errors={errors}
          label="Save name"
          name="name"
          required={false}
        />
        <Button size="sm" color="blue" onClick={handleSaveContext}>
          Save
        </Button>
      </div>
    </form>
  );
}

export default ChatTestingConfig;
