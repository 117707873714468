/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import BreadcrumbsFromConfig from "../breadCrumbs/breadCrumbs";
import { ButtonSecondary } from "../buttons/ButtonPrimary";
import { useCampaignContext } from "../campaign/campaignContext";
import { useDemoContext } from "../demo/demoContext";
import LeadCampaignList from "../demo/leadCampaignList";
import ChatForm from "../lead/chatForm";
import PlentiLogoLoading from "../loadingIndicator/PlentiLogoLoading";
import { useReimbursementContext } from "../reimbursement/reimbursementContext";
import { useUserContext } from "../user/userContext";
import { FileUploadContextWrapper } from "../forms/fileUploadContext";

function DemoContainer({ campaignId }: { campaignId: string }) {
  const { user } = useUserContext();
  const { getCampaign, associatedCompany } = useCampaignContext();

  const { setActiveReimbursement } = useReimbursementContext();
  const {
    useCreateNewTestLead,
    initialSmsContent,
    handleSendTestSms,
    messages,
    loading,
    lead,
  } = useDemoContext();

  const [showSystemMessages, setShowSystemMessages] = useState<boolean>(false);

  useEffect(() => {
    if (campaignId) {
      getCampaign(campaignId);
    }
  }, [campaignId]);

  const breadCrumbsConfig = [
    {
      name: "Campaigns",
      href: "/campaign",
      active: false,
    },
    {
      name: "View",
      href: `/campaign/${campaignId}`,
      active: false,
    },
    {
      name: "Demo",
      href: `/campaign/${campaignId}/demo`,
      active: true,
    },
  ];

  return (
    <div className="flex flex-col gap-4 p-5">
      <div className="flex justify-between">
        <BreadcrumbsFromConfig config={breadCrumbsConfig} />

        <LeadCampaignList />
      </div>

      {!lead && <PlentiLogoLoading className="h-24 w-24 mx-auto" />}
      {lead && (
        <div className="h-screen max-w-2xl mx-auto mt-2 w-full">
          <div className="rounded-lg bg-gray-100">
            <div
              className="flex flex-row gap-2"
              style={{ float: "right", margin: "4px" }}
            >
              {user?.type === "admin" && (
                <ButtonSecondary
                  onClick={() => setShowSystemMessages(!showSystemMessages)}
                >
                  {showSystemMessages ? "Hide" : "Show"} system messages
                </ButtonSecondary>
              )}
              <ButtonSecondary
                onClick={() => {
                  useCreateNewTestLead.mutate({
                    linked_number: "",
                    company_id: associatedCompany?.id,
                    campaign_id: campaignId,
                  });
                  setActiveReimbursement(undefined);
                }}
              >
                Reset
              </ButtonSecondary>
            </div>
            <div className="w-full">
              <FileUploadContextWrapper>
                <ChatForm
                  handleSubmitMessage={handleSendTestSms}
                  messages={initialSmsContent.concat(messages)}
                  loading={loading}
                  showSystemMessages={showSystemMessages}
                  activeLead={null}
                />
              </FileUploadContextWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DemoContainer;
