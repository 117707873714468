import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { filterAgents } from "../campaignAiFunction/agentFilter";
import ValueWithLabel from "../forms/valueWithLabel";
import CampaignExtractionAgentList from "./CampaignExtractionAgentList";
import { Switch } from "@headlessui/react";
import { useCampaignAiFunctionContext } from "../campaignAiFunction/campaignAiFunctionContext";
import { useState } from "react";
import { useAlertContext } from "../alert/alertContext";
import { useCampaignContext } from "./campaignContext";

const defaultEnabledAgents = [
  "User details agent",
  "Reimbursement agent",
  "Location agent",
];

function CampaignAgentList() {
  const { activeCampaign } = useCampaignContext();

  const [open, setOpen] = useState(1);
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  const { setAlertProps } = useAlertContext();

  const { campaignAiFunctions, updateCampaignAiFunction } =
    useCampaignAiFunctionContext();

  return (
    <div>
      {campaignAiFunctions &&
        campaignAiFunctions
          .filter((cf) => filterAgents(cf.ai_function))
          .map((cf, index) => {
            const isDefaultEnabled = defaultEnabledAgents.find((agent) => {
              return agent === cf.ai_function?.name;
            });

            if (!cf || !cf.ai_function) return null;
            const { ai_function, id } = cf;
            return (
              <div key={index}>
                <div className="flex flex-col gap-2">
                  <Accordion open={open === index + 1} className="w-full gap-2">
                    <AccordionHeader
                      onClick={() => {
                        if (open === index + 1) {
                          handleOpen(0);
                        } else {
                          handleOpen(index + 1);
                        }
                      }}
                      className="w-full"
                    >
                      <div className="grid grid-cols-2 gap-2 w-full">
                        <div>
                          <p className="text-sm text-slate-700">
                            {ai_function.name}
                          </p>
                        </div>
                        <div className="flex flex-row gap-2 place-self-end">
                          <Switch
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isDefaultEnabled) {
                                setAlertProps({
                                  message:
                                    "This agent is required for this campaign and cannot be disabled",
                                  color: "yellow",
                                });
                                return;
                              }
                              updateCampaignAiFunction({
                                campaignIdFunctionId: id,
                                values: {
                                  is_active: !cf.is_active,
                                },
                              });
                            }}
                            className={`${
                              cf.is_active ? "bg-green-600" : "bg-gray-200"
                            } relative inline-flex h-4 w-10 items-center rounded-full`}
                            checked={cf.is_active}
                          >
                            <span
                              className={`${
                                cf.is_active ? "translate-x-6" : "translate-x-1"
                              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                          <p className="text-slate-700 text-sm">
                            {cf.is_active
                              ? `${isDefaultEnabled ? "Default" : ""}   Enabled`
                              : "Disabled"}
                          </p>
                        </div>
                      </div>
                    </AccordionHeader>

                    <AccordionBody>
                      <ValueWithLabel
                        label="Description"
                        value={ai_function?.description || ""}
                      />
                      {ai_function?.slug === "extraction" && (
                        <CampaignExtractionAgentList
                          campaignId={activeCampaign?.id as string}
                        />
                      )}
                    </AccordionBody>
                  </Accordion>
                </div>
              </div>
            );
          })}
    </div>
  );
}

export default CampaignAgentList;
