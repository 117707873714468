import { Link, useLocation } from "react-router-dom";
const NavButton = ({
  path,
  icon,
  label,
}: {
  path: string;
  icon: React.ReactNode;
  label: string;
}) => {
  const location = useLocation();
  const active = location.pathname === path;
  return (
    <div className="h-full basis-0 md:h-fit relative ">
      {/* {path === "/segments" && (
        <div className="absolute top-[1px] left-[100px]">
          <Chip
            value="Beta"
            variant="ghost"
            size="sm"
            color="light-green"
            icon={<StarBox className="w-4 h-4" />}
          />
        </div>
      )} */}
      <Link
        to={path}
        className={`${
          active
            ? "bg-primary-500 text-white md:border-t-0"
            : "border-primary-500"
        } relative items-center flex flex-row gap-3 p-3 hover:bg-primary-500 hover:text-white md:flex-row md:justify-start md:border-0`}
      >
        {icon}

        <span
          className={`text-xs 
            md:text-sm`}
        >
          {label}
        </span>
      </Link>
    </div>
  );
};

export default NavButton;
