import { StarIcon } from "@heroicons/react/24/solid";
import { SmsContentRow } from "../../api/smsContent.type";

function SmsContentListItem({
  sms_content,
  index,
  send_order,
  orderUpdated,
}: {
  sms_content: SmsContentRow;
  index: any;
  send_order: any;
  orderUpdated: any;
}) {
  return (
    <div className="flex flex-row items-center gap-2">
      {sms_content?.type === "ai_generated" ? (
        <div className="flex items-center gap-2">
          <StarIcon className="w-4 w-4" color="orange" />
        </div>
      ) : (
        ""
      )}
      {orderUpdated ? index + 1 : send_order}
      {")"} {sms_content.name} - {sms_content.content.substring(0, 40)}...
    </div>
  );
}

export default SmsContentListItem;
