/* eslint-disable react-hooks/exhaustive-deps */

import { Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { LinkToId } from "../table/linkToId";
import { useSmsContentContext } from "./smsContentContext";
import PlentiFormLabel from "../forms/PlentiFormLabel";

function SmsContentCampaigns() {
  const { activeSmsContent } = useSmsContentContext();

  if (!activeSmsContent) {
    return null;
  }

  const { campaign_sms_content } = activeSmsContent;

  return (
    <div className="max-w-2xl flex flex-col gap-5 m-2 p-5">
      <div className="w-full">
        <div className="flex justify-between">
          <Typography variant="h6">Campaigns</Typography>
        </div>

        <Divider className="my-1" />
        <div className="my-4 grid w-full gap-10">
          <div className="flex w-full flex-col gap-6">
            {campaign_sms_content && campaign_sms_content.length > 0 ? (
              <div>
                {campaign_sms_content.map((csc) => {
                  const { id, campaign } = csc;

                  return (
                    <div key={id} className="flex flex-col gap-6">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <PlentiFormLabel>Campaign name</PlentiFormLabel>
                          <Typography>{campaign?.name}</Typography>
                        </div>
                        <div>
                          <LinkToId
                            type="Link to campaign"
                            id={campaign?.id as string}
                            baseUrl="/campaign/"
                            state={{
                              name: "Sms Content",
                              href: `/content/sms/${activeSmsContent.id}`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No campaigns found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmsContentCampaigns;
