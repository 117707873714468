import { SearchSelect, SearchSelectItem } from "@tremor/react";
import { SmsContentRow } from "../../api/smsContent.type";

function SelectSmsContent({
  smsContent,
  selectedSmsContent,
  setSelectedSmsContent,
}: {
  smsContent: SmsContentRow[] | undefined;
  selectedSmsContent: SmsContentRow;
  setSelectedSmsContent: (smsContent: SmsContentRow) => void;
}) {
  return (
    <div>
      {smsContent && smsContent.length > 0 && (
        <SearchSelect
          value={selectedSmsContent?.id || ""}
          onChange={(e: any) => {
            const found = smsContent?.find((c) => c.id === e);
            if (found) {
              setSelectedSmsContent(found);
            }
          }}
          enableClear
          onValueChange={(e: any) => {
            if (e === "") {
              setSelectedSmsContent({} as SmsContentRow);
            }
          }}
        >
          {smsContent &&
            smsContent?.map((content) => {
              return (
                <SearchSelectItem key={content.id} value={content.id}>
                  {content.name} - {content.content.substring(0, 40)}...
                </SearchSelectItem>
              );
            })}
        </SearchSelect>
      )}
    </div>
  );
}

export default SelectSmsContent;
