import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { SmsMessage } from "../../../api/sms.type";
import { CopyToClipboardIcon, XMarkIcon } from "../../icons/icons";
import { useFabrkMessageContext } from "../hooks/FabrkMessageContext";
import GenericDialog from "../../dialog/genericDialog";

function FabrkThreadMenu({ toggleMenu }: { toggleMenu: () => void }) {
  const [open, setOpen] = useState(false);

  const {
    setNewThread,
    setMessages,
    userMessages,
    filteredMessages,
    setFilteredMessages,
    messages,
  } = useFabrkMessageContext();

  const uniqueThreads = userMessages?.reduce((acc, msg) => {
    if (msg.thread_id && !acc[msg.thread_id]) {
      acc[msg.thread_id] = true;
    }
    return acc;
  }, {} as any);

  return (
    <aside
      id="fabrkMenu"
      className="w-64 bg-[#17170F] p-6 block"
      style={{
        position: "relative",
        paddingTop: "5px",
        paddingBottom: "5px",
        height: "100vh",
      }}
    >
      <button
        className="lg:hidden absolute top-4 right-4 text-white"
        onClick={toggleMenu}
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
      <div
        style={{
          position: "absolute",
          top: "15px",
          bottom: "15px",
          right: "0",
          width: "1px",
          backgroundColor: "rgba(62, 73, 174, 0.5)",
          margin: "5px 0",
        }}
      ></div>
      <nav className="flex flex-col  h-full">
        <header className="flex justify-center items-center p-6 ">
          <Button variant="text" color="orange" onClick={() => setOpen(!open)}>
            New Thread
          </Button>
          {/* <Typography className="dark:text-white text-gray-800 font-bold">
            dark test
          </Typography> */}
        </header>
        {uniqueThreads &&
          Object.keys(uniqueThreads)
            .sort((a, b) => {
              const messageA: SmsMessage | undefined = userMessages?.find(
                (msg) => msg.thread_id === a
              );
              const messageB: SmsMessage | undefined = userMessages?.find(
                (msg) => msg.thread_id === b
              );

              const aTime = messageA
                ? new Date(messageA?.created_at).getTime()
                : 0;

              const bTime = messageB
                ? new Date(messageB?.created_at).getTime()
                : 0;

              return bTime - aTime;
            })
            .map((threadId, index) => {
              const message: SmsMessage | undefined = userMessages?.find(
                (msg) => msg.thread_id === threadId
              );

              return (
                <button
                  key={index}
                  className={`p-2 badge-button flex justify-left rounded-md ${
                    filteredMessages &&
                    filteredMessages.length > 0 &&
                    filteredMessages[0].thread_id === threadId
                      ? "bg-gray-800"
                      : "bg-gray-900 hover:bg-gray-800"
                  }`}
                  onClick={() => {
                    setFilteredMessages(
                      messages?.filter((msg) => msg.thread_id === threadId) ||
                        []
                    );
                  }}
                >
                  <Typography className="dark:text-white " variant="small">
                    {message && message?.content.length > 25
                      ? message?.content.slice(0, 25) + "..."
                      : message?.content}
                  </Typography>
                </button>
              );
            })}
      </nav>
      <GenericDialog title="New Thread" open={open} setOpen={setOpen}>
        <div className="p-5 flex flex-col gap-4">
          <button
            className="p-2 badge-button rounded-md dark:bg-gray-800 hover:bg-gray-600 bg-gray-400 flex flex-row items-center space-x-2"
            onClick={() => {
              setNewThread(true);
              setOpen(false);
              setMessages([]);
            }}
          >
            <CopyToClipboardIcon className="h-4 w-4 dark:text-white text-grey-900" />
            <Typography className="dark:text-white">
              Use existing agents and context
            </Typography>
          </button>
          <button
            className="p-2 badge-button rounded-md dark:bg-gray-800 hover:bg-gray-600 bg-gray-400 flex flex-row items-center space-x-2"
            onClick={() => {
              setOpen(false);
            }}
          >
            <PlusCircleIcon className="h-4 w-4 dark:text-white text-grey-900" />
            <Typography className="dark:text-white">Blank slate</Typography>
          </button>
        </div>
      </GenericDialog>
    </aside>
  );
}

export default FabrkThreadMenu;
