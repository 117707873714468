import { Switch } from "@headlessui/react";
import { Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";

import SkeletonTableList from "../table/skeletonTableList";

import { LocationRowWithCompanyLocation } from "../../api/location.type";
import { useLocationContext } from "../location/locationContext";

export type LocationTableListProps = {
  locations: LocationRowWithCompanyLocation[] | undefined;
  filterFunction?: (
    location: LocationRowWithCompanyLocation,
    index: number
  ) => boolean;
  sortFunction?: (
    a: LocationRowWithCompanyLocation,
    b: LocationRowWithCompanyLocation
  ) => number;
};

function LocationTableList({
  locations,
  filterFunction,
  sortFunction,
}: LocationTableListProps) {
  const [columnCount] = useState(6);
  const { useUpdateCompanyLocation } = useLocationContext();

  function mapLocationToRow(location: LocationRowWithCompanyLocation): {
    id: string;
    name: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    chain: string;
    isActive: boolean;
    companyLocationId?: string;
  } {
    const values = {
      id: location.id,
      name: location?.name ? location?.name : "N/A",
      phone: location?.phone ? location?.phone : "N/A",
      address: location?.address_line_one ? location?.address_line_one : "N/A",
      city: location?.city ? location?.city : "N/A",
      state: location?.state ? location?.state : "N/A",
      zip: location?.zip ? location?.zip : "N/A",
      chain: location?.chain ? location?.chain?.name : "N/A",
      isActive:
        location && location?.company_location && location?.company_location[0]
          ? location?.company_location[0].is_active
          : false,
      companyLocationId:
        location?.company_location && location?.company_location[0]
          ? location?.company_location[0].id
          : undefined,
    };

    return values;
  }

  return (
    <Fragment>
      {locations && locations.length > 0 ? (
        locations
          .sort((a, b) => (sortFunction ? sortFunction(a, b) : 0))
          .filter((location, index) =>
            filterFunction ? filterFunction(location, index) : true
          )
          .map((item) => mapLocationToRow(item))
          .map(
            (
              {
                id,
                name,
                phone,
                city,
                state,
                zip,
                chain,
                address,
                isActive,
                companyLocationId,
              },
              index
            ) => {
              const isLast = index === locations.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={index} className="even:bg-blue-gray-50/50">
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {name}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {chain}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {address}, {city}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {state}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {zip}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {phone}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Switch
                        className={`${
                          isActive ? "bg-green-600" : "bg-gray-200"
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                        checked={isActive}
                        onChange={() => {
                          useUpdateCompanyLocation.mutateAsync({
                            companyLocationId: companyLocationId as string,
                            values: {
                              is_active: !isActive,
                            },
                          });
                        }}
                      >
                        <span
                          className={`${
                            isActive ? "translate-x-6" : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                    </div>
                  </td>
                </tr>
              );
            }
          )
      ) : (
        <SkeletonTableList columnCount={columnCount} />
      )}
    </Fragment>
  );
}

export default LocationTableList;
