/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  createCampaignJourney,
  updateCampaignJourney,
} from "../../../api/campaignJourney.api";
import { useCompanyContext } from "../../company/companyContext";
import { UpdateCampaignJourney } from "./CampaignJourney.types";

import { Node } from "reactflow";

export const CampaignJourneyContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();

  const { selectedCompany } = useCompanyContext();

  const { mutate: createJourney } = useMutation(createCampaignJourney, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("campaignJourneys");
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  const { mutate: updatePosition } = useMutation(updateCampaignJourney, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("campaignJourneys");
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  function updateInitialPosition(rfNodes: Node[]) {
    rfNodes.forEach((node) => {
      createJourney({
        company_id: selectedCompany?.id as string,
        campaign_id: node.id as string,
        x_position: node.position.x,
        y_position: node.position.y,
      });
    });
  }

  const value = {
    updatePosition,
    updateInitialPosition,
  };

  return (
    <CampaignJourneyContext.Provider value={value}>
      {children}
    </CampaignJourneyContext.Provider>
  );
};

export const CampaignJourneyContext = createContext({
  updatePosition: {} as UpdateCampaignJourney,
  updateInitialPosition: (rfNodes: Node[]) => {},
});

export const useCampaignJourneyContext = () =>
  useContext(CampaignJourneyContext);
