import { CampaignContextWrapper } from "../../../campaign/campaignContext";
import { useSetCampaignAndDate } from "../../setCampaignAndDate/useSetCampaignAndDate";
import ReimbursementCohortChart from "../components/ReimbursementCohortChart";
import { useLoadReimbursements } from "../hooks/useLoadReimbursements";
import { useLoadReimbursementsByCohort } from "../hooks/useLoadReimbursementsByCohort";
import { useLoadTotalByCampaignAndDates } from "../hooks/useLoadTotalByCampaignAndDates";

export const ReimbursementChartByCohort = () => {
  const { date, selectedCampaign } = useSetCampaignAndDate();

  const startDate = date?.from?.toISOString() || "";
  const endDate = date?.to?.toISOString() || "";
  const campaignId = selectedCampaign?.id || "";

  const { data } = useLoadReimbursements({
    campaignId: selectedCampaign?.id || "",
  });

  const { data: dataCohort } = useLoadReimbursementsByCohort({
    startDate,
    endDate,
    campaignId,
  });

  const { data: dataTotal } = useLoadTotalByCampaignAndDates({
    startDate,
    stopDate: endDate,
    campaignId,
  });

  return (
    <CampaignContextWrapper>
      <div className="flex flex-col gap-4">
        <ReimbursementCohortChart
          data={data}
          dataTotal={dataTotal}
          dataCohort={dataCohort}
          selectedCampaign={selectedCampaign}
        />
      </div>
    </CampaignContextWrapper>
  );
};
