import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";

import { getUserByEmail, updateUserById } from "../../api/user.api";
import { UserRow } from "../../api/user.type";
import { identifyMixpanelUser, initMixpanel } from "../../utils/mixpanel";

export const UserContextWrapper = (props: any) => {
  const [user, setUser] = useState<UserRow>();
  const [isEdit, setIsEdit] = useState(false);

  initMixpanel();

  const session = useSession();

  const { mutate: updateUser } = useMutation(updateUserById, {
    onSuccess: async (res) => {
      setUser(res);
    },
    onError(error: Error) {
      console.log(error);
    },
    onSettled() {
      setIsEdit(false);
    },
  });

  const useGetUserByEmail = useMutation(getUserByEmail, {
    onSuccess: async (res) => {
      setUser(res);
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (session) {
      useGetUserByEmail.mutate({ email: session?.user?.email || "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (user) {
      identifyMixpanelUser(user.id, user.type, user.email);
    }
  }, [user]);

  const value = { user, updateUser, isEdit, setIsEdit, setUser };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export const UserContext = createContext({
  user: {} as UserRow | undefined,
  updateUser: {} as UseMutateFunction<
    UserRow,
    Error,
    Partial<UserRow>,
    unknown
  >,
  isEdit: false,
  setIsEdit: {} as React.Dispatch<React.SetStateAction<boolean>>,
  setUser: {} as React.Dispatch<React.SetStateAction<UserRow | undefined>>,
});

export const useUserContext = () => useContext(UserContext);
